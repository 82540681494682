<div class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header">Key generated</h2>
  </header>

  <fieldset class="modal-content__body">
    <ng-container *ngIf="key">
      <p class="modal-content__message mrgn-b_sm">
        API key for <b>{{ model.name }}</b> has been generated:
      </p>
      <label
        class="textarea modal-content__item modal-content__item_full-width"
      >
        <textarea
          [(ngModel)]="key"
          name="key"
          class="form-block__element"
          readonly
          rows="10"
        >
        </textarea>
      </label>
    </ng-container>
    <p class="text-center modal-content__message loader-text" *ngIf="!key">
      <app-loader></app-loader>
    </p>
  </fieldset>

  <div class="buttons-block modal-content__footer">
    <button
      type="button"
      (click)="cancel()"
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">Close</span>
    </button>

    <button
      [appCopyToClipboard]="key"
      [disabled]="!key"
      class="btn action-btn action-btn_success"
    >
      <span class="action-btn__icon"><app-svg [href]="'check'"></app-svg></span>
      <span class="action-btn__title">Copy to clipboard</span>
    </button>
  </div>
</div>
