<div *ngIf="!serverErrorOccurred">
  <div class="overlay" *ngIf="showProgressBar && bgJobModel">
    <div class="progress-wrapper">
      <div
        class="header-percentage"
        *ngIf="!(bgJobModel?.step === 'done' && !bgJobModel?.success)"
      >
        {{ bgJobModel?.progress }}%
      </div>
      <div
        class="header-percentage-warning"
        *ngIf="bgJobModel?.step === 'done' && !bgJobModel?.success"
      >
        Warning
      </div>
      <div class="sub-header" *ngIf="!(bgJobModel?.step === 'done')">
        {{ getMessage() }}
      </div>
      <!-- On success -->
      <div *ngIf="bgJobModel?.step === 'done' && bgJobModel?.success">
        <div class="new-sub-header" (click)="emitEvent()">
          <div class="goto">
            <div>Go To Forecast</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path
                d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div
        class="progress"
        *ngIf="!(bgJobModel?.step === 'done' && !bgJobModel?.success)"
      >
        <progressbar
          [max]="100"
          type="info"
          class="loader__progressbar"
          [value]="bgJobModel?.progress"
        >
        </progressbar>
      </div>

      <!-- Warning -->

      <div
        class="progress"
        *ngIf="bgJobModel?.step === 'done' && !bgJobModel?.success"
      >
        <progressbar
          [max]="100"
          type="info"
          class="loader__progressbar"
          style="background-color: #b40909"
        >
        </progressbar>
      </div>

      <button
        id="commonProgressBarCancelBtnId"
        class="cancel-button"
        (click)="cancelBgJob()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>

<div *ngIf="serverErrorOccurred">
  <div class="progress-wrapper">
    <div class="header-percentage" style="font-weight: bold">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.802"
        height="19.802"
        viewBox="0 0 19.802 19.802"
      >
        <path
          id="Path_605"
          data-name="Path 605"
          d="M10.911,14.871h1.98v1.98h-1.98Zm0-7.921h1.98v5.941h-1.98ZM11.891,2A9.9,9.9,0,1,0,21.8,11.9,9.9,9.9,0,0,0,11.891,2Zm.01,17.822A7.921,7.921,0,1,1,19.822,11.9,7.919,7.919,0,0,1,11.9,19.822Z"
          transform="translate(-2 -2)"
          fill="#b40909"
        />
      </svg>
    </div>
    <div class="new-sub-header" (click)="backToForecast()">
      <div class="goto">
        <div>Back to Forecast</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path
            d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
          />
        </svg>
      </div>
    </div>
    <div class="progress">
      <progressbar
        [max]="100"
        type="info"
        class="loader__progressbar"
        style="background-color: #b40909"
      >
      </progressbar>
    </div>
    <button class="cancel-button" (click)="cancelBgJob()">Cancel</button>
  </div>
</div>
