<section class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header">Add tag</h2>
  </header>
  <ul class="modal-content__list modal-content__body">
    <li class="modal-content__item modal-content__item_full-width">
      <label
        *ngFor="let tag of tags; let _index = index"
        class="custom-radiobutton modal-content__message mrgn-b_sm edit-list__description flex"
      >
        {{ tag.name }}
        <input
          class="custom-radiobutton__input"
          type="radio"
          name="tag"
          [value]="tag.type"
          [(ngModel)]="currentTag"
        />
        <span class="custom-radiobutton__check"></span>
      </label>
    </li>
  </ul>
  <div class="buttons-block modal-content__footer">
    <button
      class="btn action-btn action-btn_default"
      type="button"
      (click)="cancel()"
    >
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">Close</span>
    </button>
    <button
      class="btn action-btn action-btn_success"
      type="button"
      (click)="submit(currentTag)"
    >
      <span class="action-btn__icon"><app-svg [href]="'check'"></app-svg></span>
      <span class="action-btn__title">{{ 'buttons.save' | translate }}</span>
    </button>
  </div>
</section>
