export class ColorsConstants {
  public static CHARTS = {
    background: '#DDDDDD',
    small: '#9A0325',
    fhi: '#0094FF',
    fhim: '#D46B08',
    fhir: '#48FE9B',
    fhie: '#017D80',
    budget_optimal: '#9A0325',
    budget_adjusted: '#D46B08',
    fhi_present: '#739E73',
    fhi_expected: '#337ab7',
    budgetAmount: '#1990ff',
    fundsNeeded: '#15bcbe',
    selectedAssets: '#2f54eb',
    portfolioCurrent: '#D46B08',
    portfolioFunded: '#D46B08',
    scopeCurrent: '#722ed1',
    scopeFunded: '#722ed1',
  };

  public static SIMULATIONS = [
    '#1890FF',
    '#17BCBE',
    '#93A8FB',
    '#D46B08',
    '#722ED1',
    '#33D173',
    '#017D80',
  ];
  public static COMPARISONS = [
    '#1890FF',
    '#17BCBE',
    '#93A8FB',
    '#D46B08',
    '#722ED1',
    '#33D173',
    '#017D80',
  ];

  public static DEFAULT = {
    green: '#739E73',
    orange: '#F0A654',
    red: '#9A0325',
  };

  public static UFA_COLORSCHEMES_TEXT = [
    { background: '#F2BBBC', text: '#444' },
    { background: '#F9E5D0', text: '#444' },
    { background: '#FDF2D0', text: '#444' },
    { background: '#DCE9D5', text: '#444' },
    { background: '#D3E0E2', text: '#444' },
    { background: '#CCDAF5', text: '#444' },
    { background: '#D8D3E7', text: '#444' },
    { background: '#F3F3F3', text: '#444' },

    { background: '#EB989A', text: '#444' },
    { background: '#F2CCA2', text: '#444' },
    { background: '#FBE5A3', text: '#444' },
    { background: '#CEDBCE', text: '#444' },
    { background: '#A8C3C8', text: '#444' },
    { background: '#C9D9E7', text: '#444' },
    { background: '#B2A8D3', text: '#444' },
    { background: '#D9D9D9', text: '#444' },

    { background: '#E47779', text: '#FFF' },
    { background: '#EDB476', text: '#FFF' },
    { background: '#FAD978', text: '#FFF' },
    { background: '#9DC284', text: '#FFF' },
    { background: '#7FA4AE', text: '#FFF' },
    { background: '#769EE5', text: '#FFF' },
    { background: '#8B7EBE', text: '#FFF' },
    { background: '#CCCCCC', text: '#FFF' },

    { background: '#E16668', text: '#FFF' },
    { background: '#DB944B', text: '#FFF' },
    { background: '#EBC251', text: '#FFF' },
    { background: '#77A55A', text: '#FFF' },
    { background: '#52808C', text: '#FFF' },
    { background: '#4979D1', text: '#FFF' },
    { background: '#6351A2', text: '#FFF' },
    { background: '#B7B7B7', text: '#FFF' },
  ];
}
