import { BehaviorSubject, firstValueFrom, Subject, takeUntil } from 'rxjs';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDragEnterEvent,
} from 'ag-grid-community';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MAX_PIN_SIZE, MOVE_PARENT_WARNING } from './asset-hierarchy.config';

import { AssetsService } from 'src/app/core/services/assets.service';
import { CommonUtil } from '@core';
import { Configs } from 'src/app/core/constants/configs.constants';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { CustomTooltipComponent } from '../../directives/ag-grid-directive/custom-tooltip/custom-tooltip.component';
import { ErrorMessagesConstants } from 'src/app/core/constants/error_messages.constants';
import { SESSION_KEY } from 'src/app/core/constants';
import { icons } from 'src/app/core/constants/icons.constants';

@Component({
  selector: 'app-asset-hierarchy',
  templateUrl: './asset-hierarchy.component.html',
  styleUrls: ['./asset-hierarchy.component.less'],
})
export class AssetHierarchyComponent implements OnChanges, OnInit, OnDestroy {
  pinningDisabled = new BehaviorSubject<boolean>(false);

  @Input() rowData: any[] = [];

  @Input() public isLoading: boolean;
  @Input() public setAssetHierarchyModeWithDragAndDrop: boolean = false;
  @Input() public isRowDragManaged: boolean = false;
  @Input() public autoGroupColumnDefInput: ColDef = {};
  @Input() public searchText: string = '';
  @Input() public isTreeData: boolean = false;
  @Input() public isPinningEnabled: boolean = false;
  @Input() public isSetParent: boolean = false;
  @Input() functions: { [key: string]: (arg: any) => any } = {};
  @Output() selectedIdsChange = new EventEmitter<number[]>();
  @Output() toggleParent = new EventEmitter<boolean>();
  @Output() gridReady = new EventEmitter();
  @Output() customSorting = new EventEmitter();
  @Output() selectedParents = new EventEmitter<any[]>();
  @Input()
  public set colDefsInput(colDefs: ColDef[]) {
    const isPinnedColDef: ColDef = colDefs.find(
      (col) => col.field === 'pinned'
    );
    if (isPinnedColDef) {
      isPinnedColDef.cellRendererParams = {
        ...(isPinnedColDef.cellRendererParams ?? {}),
        onPinChange: this.onPinChange.bind(this),
        disabled$: this.pinningDisabled
          .asObservable()
          .pipe(takeUntil(this.ngSub)),
      };
    }
    this._colDefs = colDefs;
  }

  private _colDefs: ColDef[];

  public get colDefsInput(): ColDef[] {
    return this._colDefs;
  }

  public autoGroupColumnDef: ColDef = {};
  public indicesMode: string = Configs.indicesModes[0].key;
  private gridApi!: GridApi;
  private originalRowIDs = new Set();
  public groupDefaultExpanded = -1;
  public icons: {
    [key: string]: ((...args: any[]) => any) | string;
  } = icons;
  public loadingMessage: string = 'Loading data, please wait...';
  public colDefs: ColDef[] = [];
  public gridOptions: GridOptions = {};
  public isAnimateRows: boolean = false;
  private ngSub = new Subject<void>();
  private MAX_LEVELS: number = 5;
  private MAX_CHILDREN: number = 10;
  public pageNum: number;
  public extractedUrlPart: string;
  public tooltipShowDelay = 0;
  public toggleButtonClickedSubject: boolean = false;
  defaultColDef = {
    comparator: () => 0,
    resizable: true,
    tooltipComponent: CustomTooltipComponent,
    cellStyle: (params) => {
      const { level } = params.node;
      const indent = 15;
      return {
        paddingLeft: (level + 1) * indent + 'px',
      };
    },
  };

  postSortRows = (params) => {
    const rowNodes = params.nodes;
    if (!rowNodes.length || !this.isPinningEnabled) return;
    // here we put Ireland rows on top while preserving the sort order
    const pinnedPaths = this.rowData
      .filter((node) => node.pinned)
      .reduce(
        (pinnedSet, node) => (pinnedSet.add(node.data_path[0]), pinnedSet),
        new Set()
      );
    if (!pinnedPaths.size) {
      return;
    }
    // Array to hold the ordered nodes
    const sortedNodes = [];

    // First, push pinned nodes to the sortedNodes array
    for (let i = 0; i < rowNodes.length; i++) {
      const isPinned = pinnedPaths.has(rowNodes[i].data?.data_path[0]);
      if (isPinned) {
        sortedNodes.push(rowNodes[i]);
      }
    }

    // Then, push unpinned nodes to the sortedNodes array
    for (let i = 0; i < rowNodes.length; i++) {
      const isPinned = pinnedPaths.has(rowNodes[i].data?.data_path[0]);
      if (!isPinned) {
        sortedNodes.push(rowNodes[i]);
      }
    }

    // Update the rowNodes array to reflect the new order
    for (let i = 0; i < rowNodes.length; i++) {
      rowNodes[i] = sortedNodes[i];
    }
  };

  constructor(
    public _assetService: AssetsService,
    public _notificationService: CustomNotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.setAssetHierarchyModeWithDragAndDrop ||
      changes.searchText ||
      changes.autoGroupColumnDefInput ||
      (changes.colDefsInput && this.colDefsInput)
    ) {
      this.updateColumnDefs();
    }
    if (
      !CommonUtil.deepEqual(
        changes.rowData?.previousValue,
        changes.rowData?.currentValue
      ) ||
      (this.isPinningEnabled && changes.rowData?.currentValue)
    ) {
      this.setRowData(changes.rowData?.currentValue);
    }
  }

  setRowData(data: any[]) {
    // Initialize the set of original row IDs from the provided data
    this.originalRowIDs = new Set((data ?? []).map((node) => node.id));
    // Retrieve pinned nodes from session storage
    const pinnedNodes = this.getPinnedNodes();
    // Filter out pinned nodes that are not present in the original row data
    const pinnedAssetsFromPrevPage = pinnedNodes.filter(
      (node) => !this.originalRowIDs.has(node.id)
    );
    // Merge the pinned assets from the previous page with the current data
    data = [...pinnedAssetsFromPrevPage, ...data];

    // Create a set of IDs for nodes that are pinned and should be persisted
    const persistedPinnedIds = new Set(
      pinnedNodes.filter((node) => node.pinned).map((node) => node.id)
    );
    this.pinningDisabled.next(persistedPinnedIds.size >= MAX_PIN_SIZE);
    // Update `pinned` status in the merged data if it is in the persisted pinned IDs set
    data = data.map((node) => ({
      ...node,
      pinned: persistedPinnedIds.has(node.id) || node.pinned,
    }));
    // Set the updated data to the component's internal property
    this.rowData = data ?? [];
  }

  ngOnInit(): void {
    this._assetService.toggleButtonClickedSubject$.subscribe((value) => {
      this.toggleButtonClickedSubject = value;
    });
    const baseGridOptions = {
      rowSelection: 'multiple' as const, // Enable multiple row selection
      suppressRowClickSelection: true, // Prevent row selection when the row is clicked
      suppressContextMenu: true, // Disable the context menu
      suppressGroupRowsSticky: true,
      treeData: this.isTreeData,
      overlayNoRowsTemplate: `<span></span>`,
      suppressAggFuncInHeader: true, // Suppress the default group column
      suppressAggAtRootLevel: true, // Suppress the default group column at root level
      context: {
        handleComparisonToggle: this.handleComparisonToggle.bind(this),
        items: this.rowData,
        ...this.functions,
      },
      autoSizeStrategy: {
        type: 'fitGridWidth' as const,
      },
    };

    if (this.isTreeData) {
      this.gridOptions = {
        ...baseGridOptions,
        getDataPath: (data) => data.data_path, // 'dataPath' is an array representing the path
        autoGroupColumnDef: this.autoGroupColumnDef,
        onDragStarted: function (event) {
          event.api.setGridOption('enableCellTextSelection', false);
        },
        onDragStopped: function (event) {
          event.api.setGridOption('enableCellTextSelection', true);
        },
      };
    } else {
      this.gridOptions = {
        ...baseGridOptions,
      };
    }

    if (this.colDefsInput) {
      this.updateColumnDefs();
    }
  }

  emitSortChanged(): void {
    this.customSorting.emit(this.gridApi.getState().sort?.sortModel ?? []);
  }

  // Retrieve all pinned nodes from session storage
  getPinnedNodes(): any[] {
    return this.isPinningEnabled
      ? JSON.parse(sessionStorage.getItem(SESSION_KEY.assetPinnedNodes) || '[]')
      : [];
  }

  sortTreeData() {
    const pinnedPaths = this.rowData
      .filter((node) => node.pinned)
      .reduce(
        (pinnedSet, node) => (pinnedSet.add(node.data_path[0]), pinnedSet),
        new Set()
      );
    this.rowData.sort((a, b) => {
      // Check if both nodes are pinned
      const aIsPinned = pinnedPaths.has(a.data_path[0]);
      const bIsPinned = pinnedPaths.has(b.data_path[0]);
      return Number(bIsPinned) - Number(aIsPinned);
    });
  }

  onGridReady(gridApi: GridReadyEvent['api']) {
    this.gridApi = gridApi;
    this.gridReady.emit(gridApi);
  }

  updateColumnDefs() {
    this.autoGroupColumnDef = this.autoGroupColumnDefInput;
    // Refresh the grid to apply the new column definitions
    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.colDefsInput);
      this.gridApi.setAutoGroupColumnDef(this.autoGroupColumnDef);
    }
  }

  public onRowDragEnter(params: RowDragEnterEvent) {
    if (params.node.childrenAfterGroup.length > 0) {
      this._notificationService.info(MOVE_PARENT_WARNING, 3000);
    }
  }

  public dragAndDropValidations(
    targetNode,
    allDraggedNodes,
    draggedNode?
  ): boolean {
    const targetData = targetNode.data;
    // Check if the target node is a parent
    if (!targetData.is_parent) {
      this._notificationService.error(
        ErrorMessagesConstants.SET_ASSET_AS_PARENT
      );
      return true;
    } else if (targetNode.level === this.MAX_LEVELS - 1) {
      // Check if the target node has completed it's levels of hierarchy
      this._notificationService.error(
        ErrorMessagesConstants.MAX_LEVELS_REACHED
      );
      return true;
    } else if (
      targetNode.childrenAfterGroup.length + allDraggedNodes.length >
      this.MAX_CHILDREN
    ) {
      this._notificationService.error(
        ErrorMessagesConstants.MAX_CHILDREN_REACHED
      );
      return true;
    } else if (this.isSelectionParentOfTarget(draggedNode, targetNode)) {
      this._notificationService.error(
        ErrorMessagesConstants.INVALID_PARENT_MOVE,
        3000
      );
      return true;
    }
    return false;
  }

  public isSelectionParentOfTarget(
    selectedNode: any,
    targetNode: any
  ): boolean {
    if (
      targetNode?.data?.data_path &&
      Array.isArray(targetNode.data.data_path)
    ) {
      return targetNode.data.data_path.some(
        (path: any) => path === selectedNode.key
      );
    }

    return false;
  }

  checkHierarchyDepthAfterDragDrop(
    targetNodePath: string[],
    draggedNode: any
  ): boolean {
    const newDraggedNodePath = [
      ...targetNodePath,
      draggedNode.data.data_path.slice(-1)[0],
    ];

    if (newDraggedNodePath.length > this.MAX_LEVELS) {
      return false;
    }

    if (draggedNode.childrenAfterGroup) {
      for (const childNode of draggedNode.childrenAfterGroup) {
        if (
          !this.checkHierarchyDepthAfterDragDrop(newDraggedNodePath, childNode)
        ) {
          return false;
        }
      }
    }

    return true;
  }

  getOriginalDraggedNodes(nodes) {
    const originalDraggedNodes = [];
    for (const node of nodes) {
      originalDraggedNodes.push(node); // Add the current node
      if (node.childrenAfterGroup && node.childrenAfterGroup.length > 0) {
        // Recursively collect children nodes
        originalDraggedNodes.push(
          ...this.getOriginalDraggedNodes(node.childrenAfterGroup)
        );
      }
    }
    return originalDraggedNodes;
  }
  public onRowDragEnd(event: any) {
    const draggedNode = event.node;
    const targetNode = event.overNode;
    // Check if the dragged node and target node are not the same
    if (targetNode && draggedNode !== targetNode) {
      const targetData = targetNode.data;
      const draggedNodes = event.nodes;
      const updatedRows: any[] = [];
      const allOrphanDraggedNodes = draggedNodes.filter((nodeDragged) => {
        if (!nodeDragged.parent.selected) {
          return nodeDragged;
        }
      });
      const allDraggedNodesIds = allOrphanDraggedNodes.map((nodeDragged) => {
        return nodeDragged.data.id;
      });
      let pinnedNodes = this.getPinnedNodes();
      const originalDraggedNodes = this.getOriginalDraggedNodes(draggedNodes);
      const targetDataId = targetData.id;
      // Function to check the validations for the drag and drop
      if (
        this.dragAndDropValidations(
          targetNode,
          allOrphanDraggedNodes,
          draggedNode
        )
      )
        return;

      if (
        !this.checkHierarchyDepthAfterDragDrop(
          targetData.data_path,
          draggedNode
        )
      ) {
        if (allOrphanDraggedNodes.length > 1) {
          this._notificationService.error(
            ErrorMessagesConstants.ALL_ROWS_CANNOT_BE_MOVED
          );
        } else {
          this._notificationService.error(
            ErrorMessagesConstants.HIERARCHY_CANNOT_BE_MOVED
          );
        }
        return;
      }

      allOrphanDraggedNodes.forEach((nodeDragged) => {
        this.moveToPath(targetData.data_path, nodeDragged, updatedRows);
      });

      this.isAnimateRows = true;
      this.isRowDragManaged = true;
      this._assetService
        .updateOnDragDrop(targetData.id, {
          asset: {
            asset_child_ids: allDraggedNodesIds,
            is_expended: true,
            is_external_hierarchy_change: true,
          },
        })
        .subscribe({
          next: () => {
            const isTargetNodePinned = pinnedNodes.some(
              (node) => node.id === targetDataId
            );

            if (isTargetNodePinned) {
              originalDraggedNodes.forEach((dragNode) => {
                const pinnedNode = pinnedNodes.find(
                  (node) => node.id === dragNode.data.id
                );

                if (pinnedNode) {
                  // If the dragged node is already pinned, unpin it and update data
                  dragNode.data.pinned = false;
                  pinnedNode.pinned = false;
                  pinnedNode.data_path = dragNode.data.data_path;
                } else {
                  // If the dragged node is not pinned, add it
                  pinnedNodes.push(dragNode.data);
                }
              });
            } else {
              originalDraggedNodes.forEach((dragNode) => {
                // Unpin the dragged node
                dragNode.data.pinned = false;

                // Remove the dragged node from pinnedNodes if it exists
                pinnedNodes = pinnedNodes.filter(
                  (node) => !node.data_path.includes(dragNode.data.id)
                );
              });
            }

            sessionStorage.setItem(
              SESSION_KEY.assetPinnedNodes,
              JSON.stringify(pinnedNodes)
            );

            this.gridApi.applyTransaction({
              update: updatedRows,
            });
            this.rowData = this.rowData.slice();
          },
        });
    }
    this.isAnimateRows = false;
    this.isRowDragManaged = false;
  }

  /**
   * Handles the pin change event.
   * @param event The event object containing the row data and pinned state.
   */
  public onPinChange(assetId: number, isPinned: boolean): void {
    let pinnedNodes = [];
    this.savePinnedNodes(assetId, isPinned).then((pinnedNodesWithHierarchy) => {
      pinnedNodes = pinnedNodesWithHierarchy;
    });
    this.sortTreeData();
    this.gridApi.refreshCells({
      rowNodes: pinnedNodes,
      force: true, // Ensure cell refreshes
      columns: ['pinned'], // Refresh only the pinned column
    });
  }

  async savePinnedNodes(assetId: number, isPinned: boolean): Promise<any[]> {
    let pinnedNodesWithHierarchy = [];
    try {
      const parentPinnedNodes = this.rowData.filter((node) => node.pinned);
      if (isPinned) {
        pinnedNodesWithHierarchy = this.getPinnedNodes();
        const assets = await firstValueFrom(
          this._assetService.getAssetsWithDescendants(assetId)
        );
        assets.forEach((asset) => {
          if (asset.id === assetId) {
            asset.pinned = true;
          }
        });
        pinnedNodesWithHierarchy.push(...assets);
      } else {
        parentPinnedNodes.forEach((node) => {
          const pinnedNodes = this.rowData.filter(
            (otherNode) =>
              otherNode.data_path.length > 1 &&
              otherNode.data_path[0] === node.data_path[0]
          );
          pinnedNodesWithHierarchy.push(node, ...pinnedNodes);
        });
      }

      this.pinningDisabled.next(parentPinnedNodes.length >= MAX_PIN_SIZE);
      sessionStorage.setItem(
        SESSION_KEY.assetPinnedNodes,
        JSON.stringify(pinnedNodesWithHierarchy)
      );
      const pinnedNodeIds = new Set(
        pinnedNodesWithHierarchy.map((node) => node.id)
      );
      // removing unpinned nodes that aren't in originalRowIDs and persist pinned nodes even from previous page
      const removedPinnedNodes = this.rowData.filter(
        (node) =>
          !this.originalRowIDs.has(node.data_path[0]) &&
          !pinnedNodeIds.has(node.data_path[0])
      );
      const validNodes = this.rowData.filter(
        (node) =>
          this.originalRowIDs.has(node.data_path[0]) ||
          pinnedNodeIds.has(node.data_path[0])
      );

      this.gridApi.applyTransaction({
        remove: removedPinnedNodes,
      });
      // updating the row data as we don't want change detection to trigger; we can have better way
      this.rowData.splice(0, this.rowData.length, ...validNodes);
    } catch (error) {
      this._notificationService.apiError(error);
    }
    return pinnedNodesWithHierarchy;
  }

  /**
   * Updates the grid with the provided data.
   * @param data The data to update the grid with.
   * @param itemId
   * @param isParent
   * @param hasChildren
   */
  public handleComparisonToggle(
    itemId: number,
    isParent: boolean,
    hasChildren: boolean
  ) {
    this._assetService.toggleButtonClickedSubject$.subscribe((value) => {
      this.toggleButtonClickedSubject = value;
    });
    const item = this.rowData.find((i) => i.id === itemId);
    if (item) {
      item.is_parent = isParent;
      let isNodePinned = false;
      let pinnedNodes = this.getPinnedNodes();
      pinnedNodes.forEach((node) => {
        if (node.id === item.id) {
          isNodePinned = true;
          node.is_parent = item.is_parent;
        }
      });

      if (isNodePinned && !isParent) {
        pinnedNodes = pinnedNodes.filter((node) => {
          return !(
            node.data_path.includes(itemId) &&
            node.data_path.length > item.data_path.length
          );
        });
      }
      sessionStorage.setItem(
        SESSION_KEY.assetPinnedNodes,
        JSON.stringify(pinnedNodes)
      );
      // Apply the changes to the grid
      this.gridApi.applyTransaction({ update: [item] });
      if (hasChildren && !this.toggleButtonClickedSubject) {
        this.toggleParent.emit(isParent);
      }
    }
  }

  /**
   * Recursively updates the data path of a dragged node and its children to reflect its new position in the hierarchy.
   * @param targetNodePath - The path of the target node where the dragged node is being moved.
   * @param draggedNode - The node that is being dragged.
   * @param allUpdatedNodes - An array to collect all nodes that have been updated.
   */
  public moveToPath(
    targetNodePath: string[],
    draggedNode: any,
    allUpdatedNodes: any[]
  ) {
    // Get the current path of the dragged node
    const previousdraggedNodePath = draggedNode.data.data_path;

    // Get the name of the dragged node (last element in the current path)
    const draggedNodeName =
      previousdraggedNodePath[previousdraggedNodePath.length - 1];

    // Create a new path by copying the target node's path
    const newdraggedNodePath = targetNodePath.slice();

    // Add the dragged node's name to the new path
    newdraggedNodePath.push(draggedNodeName);

    // Update the dragged node's path to the new path
    draggedNode.data.data_path = newdraggedNodePath;

    // Add the updated dragged node to the list of all updated nodes
    allUpdatedNodes.push(draggedNode.data);

    // If the dragged node has children, recursively update their paths
    if (draggedNode.childrenAfterGroup) {
      draggedNode.childrenAfterGroup.forEach((childNode) => {
        this.moveToPath(newdraggedNodePath, childNode, allUpdatedNodes);
      });
    }
  }

  showRowSelectionMsg(): void {
    this._notificationService.info(
      'Selecting a parent asset includes all children; individual unselecting isn’t allowed.',
      5000
    );
  }

  ngOnDestroy(): void {
    this.ngSub.next();
    this.ngSub.complete();
  }
}
