<ag-grid-angular
  [ngStyle]="{ height: gridHeight() + 'px' }"
  [class]="className"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [rowDragMultiRow]="rowDragMultiRow"
  [rowDragManaged]="rowDragManaged"
  [animateRows]="animateRows"
  [suppressMoveWhenRowDragging]="suppressMoveWhenRowDragging"
  [icons]="icons"
  [rowHeight]="rowHeight"
  [tooltipShowDelay]="tooltipShowDelay"
  [groupDisplayType]="groupDisplayType"
  [gridOptions]="gridOptions"
  [defaultColDef]="defaultColDef"
  [groupDefaultExpanded]="groupDefaultExpanded"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [postSortRows]="postSortRows"
  (selectionChanged)="onSelectionChanged(); selectionChanged.emit($event)"
  (gridReady)="onGridReady($event)"
  (rowDragEnter)="rowDragEnter.emit($event)"
  (sortChanged)="sortChanged.emit($event)"
  (rowDragEnd)="rowDragEnd.emit($event)"
  (rowSelected)="onRowSelected($event)"
  [components]="components"
  [context]="context"
  [rowClassRules]="rowClassRules"
  [treeData]="treeData"
  [getDataPath]="getDataPath"
  [grandTotalRow]="grandTotalRow"
  [suppressStickyTotalRow]="suppressStickyTotalRow"
  [processUnpinnedColumns]="processUnpinnedColumns"
  [isRowSelectable]="isRowSelectable"
>
</ag-grid-angular>
