<section class="modal-content__wrapper">
  <header class="modal-content__header">{{ title }} Link</header>

  <form class="modal-content__body" #actionForm="ngForm">
    <ul class="modal-content__list">
      <li class="modal-content__item modal-content__item_full-width">
        <label for="description" class="modal-content__label"
          >Description</label
        >
        <textarea
          class="form-block__element"
          [ngClass]="{ 'form-block_error': hasError(_comment) }"
          [(ngModel)]="model.description"
          required
          rows="8"
          placeholder="Description"
          id="description"
          name="description"
          #_comment="ngModel"
          [customMaxlength]="255"
        ></textarea>
        <p class="error" *ngIf="hasError(_comment)">
          <ng-container *ngIf="_comment.errors.maxlength">
            {{ 'validation.maxlength' | translate }}
          </ng-container>
          <ng-container *ngIf="_comment.errors.required">
            {{ 'validation.required' | translate }}
          </ng-container>
        </p>
      </li>
      <li class="modal-content__item modal-content__item_full-width">
        <label for="uri" class="modal-content__label">Link</label>

        <input
          class="form-block__element"
          [ngClass]="{ 'form-block_error': hasError(_uri) }"
          [(ngModel)]="model.uri"
          required
          placeholder="URI"
          id="uri"
          name="uri"
          #_uri="ngModel"
          [customMaxlength]="255"
        />
        <p class="error" *ngIf="hasError(_uri)">
          <ng-container *ngIf="_uri.errors.maxlength">
            {{ 'validation.maxlength' | translate }}
          </ng-container>
          <ng-container *ngIf="_uri.errors.required">
            {{ 'validation.required' | translate }}
          </ng-container>
        </p>
      </li>
    </ul>
  </form>

  <footer class="buttons-block modal-content__footer">
    <button
      type="button"
      (click)="cancel()"
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">{{ 'buttons.cancel' | translate }}</span>
    </button>

    <button
      (click)="submit()"
      [disabled]="actionForm.invalid"
      type="button"
      class="btn action-btn action-btn_success"
    >
      <span class="action-btn__icon"><app-svg [href]="'check'"></app-svg></span>
      <span class="action-btn__title">Submit</span>
    </button>
  </footer>
</section>
