import { DefaultModel } from './default.model';
import { DefaultDTO } from '../dto/default.dto';
import { AssetTypeModel } from './asset-type.model';
import { ProjectAssetExpensesModel } from './project-asset-expenses-model';
import { HelperService } from '../services/helper.service';
import { ProjectAssetDTO } from '../dto/project-asset.dto';
import { IAssetComment } from './asset.model';

export class ProjectAssetModel extends DefaultModel<DefaultDTO> {
  id: number;
  name: string;
  title?: string;
  asset_number: number;
  asset_cost: number;
  location_modifier: number;
  regional_value?: number;
  expenses_general_sum: number;
  expenses_general: ProjectAssetExpensesModel;
  expenses_udf_sum?: number;
  expenses_udf?: any;
  selected: boolean;
  asset_type: AssetTypeModel;
  assets_repository_id: number;
  quantity: number;
  fhi: number;
  location: Location;
  origin_entity?: {
    asset_id?: number;
    complete?: boolean;
    archived?: boolean;
    existent?: boolean;
  };
  apiErrors?: any;
  cii_amount: number;
  replacement_cost: number;
  asset_cost_initial: number;
  editAssetCost: boolean;
  editInvestmentMode: boolean;
  generation: number;
  comment: IAssetComment;
  committed_on: number;
  lte_type: string;

  constructor(data?) {
    super();
    if (data) {
      Object.assign(this, data);
    }
    this.asset_cost = parseFloat(data.asset_cost);
    this.quantity = parseFloat(data.quantity);
    this.expenses_general_sum = data.expenses_general_sum
      ? parseFloat(data.expenses_general_sum)
      : 0;
    this.regional_value = data.regional_value
      ? parseFloat(data.regional_value)
      : 0;

    this.expenses_udf_sum = data.expenses_udf_sum
      ? parseFloat(data.expenses_udf_sum)
      : 0;
    this.location_modifier = HelperService.convertFloatToPercent(
      data.location_modifier
    );
    this.apiErrors = {};
  }

  public dto(): ProjectAssetDTO {
    return new ProjectAssetDTO(this);
  }

  public get entity_name(): string {
    return 'asset';
  }

  public toString(): string {
    return this.name || 'Generic asset';
  }
}

export interface CustomizedAsset {
  key: string;
  value: number;
}
