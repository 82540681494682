import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { BaseStoreService } from './base-store.service';
import { BuildingModel } from '../models/building.model';
import { DefaultListModel } from '../models/default-list.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BuildingsService<M> extends BaseStoreService<M> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, BuildingModel);
    this.resourceName = 'v1/facilities/buildings';
  }

  getBuildingTotals(requestParams?): Observable<any> {
    return this._requestService.get(
      `/${this.resourceName}/totals`,
      requestParams
    );
  }

  getEntityUsers(id, entityType: string): Observable<any> {
    // entityType: 'assessments', 'attachments', 'projects'
    return this._requestService.get(
      `/${this.resourceName}/${id}/${entityType}/users`
    );
  }

  getBuildingAttachments(id): string {
    return `${this.resourceName}/${id}/attachments`;
  }

  getBuildingAssessments(id): string {
    return `${this.resourceName}/${id}/assessments`;
  }

  getBuildingDocuments(id): string {
    return `${this.resourceName}/${id}/documents`;
  }

  getBuildingProjects(id): string {
    return `${this.resourceName}/${id}/projects`;
  }

  getBuildingLinks(id): string {
    return `${this.resourceName}/${id}/links`;
  }

  updateBuildingAssociations(building) {
    return this._requestService.put(
      `/${this.resourceName}/${building.id}/associations`,
      { building }
    );
  }

  getBuildingResponsibleUsers(
    buildingId: number,
    params
  ): Observable<DefaultListModel<M>> {
    return this.getItems(
      params,
      `${this.resourceName}/${buildingId}/responsible_users`
    );
  }

  deleteBuildingResponsibleUser(
    buildingId: number,
    userId: number
  ): Observable<DefaultListModel<M>> {
    return this.delete(
      userId,
      `${this.resourceName}/${buildingId}/responsible_users`
    );
  }

  getResponsibleUsers(buildingId: number): Observable<DefaultListModel<M>> {
    return this._requestService
      .get(`/${this.resourceName}/${buildingId}/responsible_users/users`)
      .pipe(map((list) => ({ list })));
  }
}
