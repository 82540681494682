<div class="btn-group">
  <button
    id="indices_{{item.name}}_btn_id"
    *ngFor="let item of modes"
    type="button"
    [ngClass]="{ active: item.key === mode }"
    (click)="indicesMode = item.key"
    class="btn btn-group__btn"
  >
    {{ item.name }}
  </button>
</div>
