import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { DefaultListModel } from '../models/default-list.model';
import { environment } from '../../../environments/environment';
import { AreaServeModel } from '../models/area-serve.model';

@Injectable()
export class AreaServeRisksService extends BaseStoreService<AreaServeModel> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, AreaServeModel);
    this.resourceName = 'v2/client/area_served';
  }

  public getItems(
    params?: any,
    orgId?: string | number
  ): Observable<DefaultListModel<AreaServeModel>> {
    const headers = orgId
      ? { [environment.tokens.org_header]: orgId }
      : undefined;
    return super.getItems(params, this.resourceName, headers);
  }
}
