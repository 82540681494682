import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesConstants } from '../../core/constants/routes.constants';
import { UserCurrentService } from '../../core/services/user-current.service';
import { Observable } from 'rxjs';
import { OrganizationCurrentService } from '../../core/services/organization-current.service';
import { RoutingService } from '../../core/services/routing.service';
import { RoleType } from '../../core/constants/types.constants';
import { Settings } from 'luxon';

@Injectable()
export class TenantGuard {
  constructor(
    private router: Router,
    private _userCurrent: UserCurrentService,
    private _organizationCurrent: OrganizationCurrentService,
    private _routingService: RoutingService
  ) {}

  canActivate(): Observable<boolean> {
    this._organizationCurrent.clean();
    this._organizationCurrent.cleanTenant();
    return new Observable<boolean>((observer) => {
      this._userCurrent.get().subscribe(
        (user) => {
          Settings.defaultZone = user.organization?.time_zone || user.time_zone;
          if (!user.is_tenant) {
            if (!this._organizationCurrent.organization) {
              this._organizationCurrent.get().subscribe((organization) => {
                Settings.defaultZone = organization.time_zone;
                this.router.navigate([
                  RoutesConstants.ORGANIZATION.HOME(
                    this._organizationCurrent.organization.id_hash
                  ),
                ]);
              });
            } else {
              Settings.defaultZone =
                this._organizationCurrent.organization.time_zone;
              this.router.navigate([
                RoutesConstants.ORGANIZATION.HOME(
                  this._organizationCurrent.organization.id_hash
                ),
              ]);
            }
          }
          this._routingService.routeType = 'tenant' as RoleType;
          observer.next(user.is_tenant);
          observer.complete();
        },
        () => {
          observer.next(false);
          observer.complete();
        }
      );
    });
  }
}
