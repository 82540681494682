import {
  AlloctedByOption,
  EnergySavingOption,
} from 'src/app/core/constants/enums';
import { Component, Input } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';

import { AutoUnsubscribeService } from '../../../core/services/auto-unsubscribe.service';
import { BaseReactiveForm } from '../../components/base-reactive-form';
import { BgJobService } from '../../../core/services/bg-worker.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ClientAssetTypesService } from 'src/app/core/services/client-asset-types.service';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { DateTime } from 'luxon';
import { ErrorModel } from '../../../core/models/error.model';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { ForecastService } from '../../../core/services/forecast.service';
import { HelperService } from 'src/app/core/services/helper.service';
import { ProjectModel } from '../../../core/models/project.model';
import { ProjectsService } from '../../../core/services/projects.service';
import { TranslateService } from '@ngx-translate/core';
import { UserCurrentService } from '../../../core/services/user-current.service';
import { dateRangeValidator } from '../../validators/date-range.validator';
import { takeUntil } from 'rxjs';

@Component({
  templateUrl: './add-to-project-modal.component.html',
  styleUrls: ['./add-to-project-modal.component.less'],
})
export class AddToProjectModalComponent extends BaseReactiveForm<ProjectModel> {
  @Input() public header: string = 'Create a Project';
  @Input() public submitText: string = 'Submit';
  @Input() public organization_hash: string;
  @Input() public cancelText: string = 'Cancel';
  @Input() public budgetingData: any = null;
  @Input() public forecastData: any = null;
  @Input() public selectedAssetIDs = [];
  @Input() public existedProject: ProjectModel;
  @Input() public createProject: boolean;
  @Input() public newProjectFromForecast: boolean;
  @Input() public forecast: boolean;
  @Input() public periodList: string[] = [];
  @Input() public isPublic: boolean;
  @Input() public period: number;
  @Input() public cmmsStartDate: string;
  @Input() public projectPriorityOptions: any = [];
  @Input() public projectTypeOptions: any = [];

  public projects: ProjectModel[] = [];
  public data: any;
  public apiErrors: any[] = [];
  public message: string = '';
  public endPeriods: string[] = [];
  private needsConfirmation: boolean;
  public characterCount: number = 0;
  public descriptionLength: number = 100;
  public maxProjectYear: Date = new Date(9999, 11, 31, 23, 59, 59);
  public body = document.querySelector('body');
  public leafUrl: string = '../../../../assets/img/noun-leaf.svg';
  public energySavingOptions: { name: string; value: string }[] = [
    { name: EnergySavingOption.None, value: null },
    { name: EnergySavingOption.Low, value: 'low' },
    { name: EnergySavingOption.Medium, value: 'medium' },
    { name: EnergySavingOption.High, value: 'high' },
  ];

  public alloctedByOptions: { name: string; value: string }[] = [
    { name: AlloctedByOption.None, value: null },
    { name: AlloctedByOption.Quarterly, value: 'quarterly' },
    { name: AlloctedByOption.Annually, value: 'annually' },
  ];
  private get generateRequestUrl(): string {
    switch (true) {
      // case !!this.budgetingData:
      //   return `/projects/from_budget/${this.budgetingData.id}`;
      case !!this.forecastData && ForecastService.isOld:
        return `from_forecast/${this.forecastData.id}/`;
      case !!this.forecastData &&
        !ForecastService.isOld &&
        !!this.selectedAssetIDs.length:
        if (this.form.value.projectId) {
          return `${this.form.value.projectId}/from_master_forecast/${this.forecastData.id}`;
        }
        return `from_master_forecast/${this.forecastData.id}/`;
      case !this.form.value.projectId:
        return undefined;
      default:
        return `${this.form.value.projectId}`;
    }
  }

  public get projectName() {
    if (this.form.value.projectId) {
      return this.projects.find((el) => el.id === this.form.value.projectId)
        ?.name;
    }
    return this.form.value.name || this.existedProject?.name;
  }
  public next: boolean = false;
  public timeout: any;
  public items: any = [];

  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService,
    protected _destroy: AutoUnsubscribeService,
    protected _userCurrent: UserCurrentService,
    protected _bgJobService: BgJobService<ErrorModel>,
    private _projectsService: ProjectsService<ProjectModel>,
    private _translateService: TranslateService,
    protected _featureFlagsService: FeatureFlagsService,
    protected _clientAssetTypesService: ClientAssetTypesService
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }

  ngOnInit() {
    let forecast_id = null;
    forecast_id = this.forecastData?.id;
    if (
      this._featureFlagsService.isFeatureEnabled('OR_12876_project_attributes')
    ) {
      this._clientAssetTypesService.getProjectAttributes().subscribe({
        next: (data) => {
          this.items = data;
        },
        error: (err) => this._notificationService.apiError(err),
      });
    }
    if (!this.newProjectFromForecast && !this.createProject) {
      if (!this.existedProject) {
        this.isLoading = true;
        this._projectsService.getAllowedProjects(forecast_id).subscribe({
          next: (res) => {
            this.projects = res.list;
            this.isLoading = false;
          },
          error: (err) => {
            this.isLoading = false;
            this._notificationService.apiError(err);
          },
        });
      } else {
        this.projects = [this.existedProject];
      }
    }
    this.setupForm();
    this.addProjectClassToBody();
    this.updateProjectDateRange();
  }

  public submit(): void {
    this.apiErrors = [];

    if (this.isFormValid) {
      this.isLoading = true;
      const params: any = {};
      const selected_tag_ids = this.form.value.tag_ids.splice(
        0,
        this.items.length
      );
      const body: any = {
        project: {
          asset_ids: this.selectedAssetIDs.length
            ? this.selectedAssetIDs
            : null,
          forecast_id: this.forecastData?.id,
          name: this.form.value.name,
          period: this.newProjectFromForecast
            ? this.form.value.createNewProject.period
            : this.period || this.form.value.period,
          description: this.form.value.description,
          public: this.form.value.public,
          is_occupied: this.form.value.is_occupied,
          project_date_from: DateTime.fromISO(
            this.form.value.projectDate.project_date_from
          )
            .startOf('day')
            .toISO(),
          project_date_to: DateTime.fromISO(
            this.form.value.projectDate.project_date_to
          )
            .endOf('day')
            .toISO(),
          project_type: this.form.value.project_type,
          project_risk: this.form.value.project_risk,
          energy_saving: this.form.value.energy_saving,
          allocated_by: this.form.value.allocated_by,
          tag_ids: selected_tag_ids,
        },
      };

      if (this.newProjectFromForecast) {
        body.project.end_period =
          this.form.value.createNewProject.end_period +
          this.form.value.createNewProject.period;
      }

      if (this.needsConfirmation) {
        params.force = this.needsConfirmation;
      }

      if (!this.form.value.projectId) {
        body.project.public = this.form.value.public;
      }

      this.needsConfirmation = false;

      if (
        this.forecastData &&
        !ForecastService.isOld &&
        !this.selectedAssetIDs.length
      ) {
        // async project creation
        this._projectsService
          .createProjectFromForecast(this.forecastData.id, body, params)
          .pipe(takeUntil(this._destroy))
          .subscribe({
            next: (res) => this.checkProgress(res.job_uuid),
            error: (err) => {
              this.setErrorToControl(err.error.errors, this.organization_hash);
              this.isLoading = false;
            },
          });
      } else {
        let request;
        if (this.form.value.projectId) {
          const model = new ProjectModel({
            id: this.form.value.projectId,
            description: this.form.value.description,
            public: this.form.value.public,
            is_occupied: this.form.value.is_occupied,
            project_date_from: DateTime.fromISO(
              this.form.value.projectDate.project_date_from
            )
              .startOf('day')
              .toISO(),
            project_date_to: DateTime.fromISO(
              this.form.value.projectDate.project_date_to
            )
              .endOf('day')
              .toISO(),
            project_type: this.form.value.project_type,
            project_risk: this.form.value.project_risk,
            energy_saving: this.form.value.energy_saving,
            allocated_by: this.form.value.allocated_by,
            tag_ids: selected_tag_ids,
          });
          this._projectsService.update(model, 'v1/projects').subscribe({
            next: () => {},
            error: (err) => {
              this._notificationService.apiError(err);
            },
          });
          request = this._projectsService.addToProject(
            this.generateRequestUrl,
            body,
            params
          );
        } else {
          request = this._projectsService.createProject(
            this.generateRequestUrl,
            body,
            params
          );
        }

        request.pipe(takeUntil(this._destroy)).subscribe({
          next: (res) => this.afterSuccessSubmit(res),
          error: (err) => {
            this.setErrorToControl(err.error.errors, this.organization_hash);
            this.isLoading = false;
          },
        });
      }
    }
  }

  protected onUnhandledError(apiErrors, orgId) {
    this.needsConfirmation = true;
    this.apiErrors = this._notificationService.parseErrorsWithCodes(
      apiErrors,
      orgId
    );
    const needToBeFixed: boolean = this.apiErrors.some(
      (el) => el.needToBeFixed
    );
    if (!needToBeFixed) {
      this.apiErrors.push({ message: 'Do you want to proceed?' });
      this.submitText = this._translateService.instant('buttons.continue');
    }
  }

  protected setupForm() {
    const tag_ids: FormControl[] = Array(9)
      .fill(null)
      .map((val) => new FormControl(val));
    this.form = this.formBuilder.group({
      createNewProject: this.formBuilder.group({
        oneYearProject: [0, Validators.required],
        period: [0, Validators.required],
        end_period: [
          this.periodList.length >= 5 ? 4 : this.periodList.length - 1,
          Validators.required,
        ],
      }),
      name: [
        this.existedProject?.name,
        [
          Validators.required,
          Validators.maxLength(80),
          Validators.pattern(
            /^(?!.*([\_\s\-\(\)\[\]\{\}\.,])\1)[a-zA-Z0-9\-\(\)\[\]\{\}\s\.\_]+$/
          ),
        ],
      ],
      period: [0, Validators.required],
      projectId: [
        this.existedProject?.id,
        !this.createProject ? [Validators.required] : [],
      ],
      public: [!!this.isPublic],
      projectDate: this.formBuilder.group(
        {
          project_date_from: [null],
          project_date_to: [null],
        },
        { validator: dateRangeValidator(this.cmmsStartDate) }
      ),
      description: [
        this.existedProject?.description,
        [Validators.maxLength(this.descriptionLength)],
      ],
      is_occupied: [this.existedProject?.is_occupied || false],
      tag_ids: this.formBuilder.array(tag_ids),
      project_type: [this.existedProject?.project_type.id || null],
      project_risk: [this.existedProject?.project_risk.id || null],
      energy_saving: [this.existedProject?.energy_saving || null],
      allocated_by: [this.existedProject?.allocated_by || 'annually'],
    });

    if (this.newProjectFromForecast && this.periodList.length) {
      this.endPeriods = this.periodList.slice(
        this.form.value.createNewProject.period,
        5
      );
    }

    this.onFormChange();
  }

  private checkProgress(uuid) {
    this._bgJobService
      .checkBgJobStatus(uuid, this.organization_hash)
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: (res) => {
          switch (true) {
            case this._bgJobService.hasSuccessStatus(res):
              this.afterSuccessSubmit(res.details);
              break;

            case this._bgJobService.hasErrorStatus(res):
              this.setErrorToControl(
                res.issues.errors.map((err) => err.details),
                this.organization_hash
              );
              this.isLoading = false;
              break;

            case this._bgJobService.hasInProgressStatus(res):
              setTimeout(() => this.checkProgress(uuid), 2000);
              break;

            case this._bgJobService.hasWarningStatus(res):
              this.afterSuccessSubmit(
                res.details,
                res.issues.warnings.map((err) => err.details)
              );
              break;

            default:
              this.isLoading = false;
              this._notificationService.error(
                'Something went wrong. The technical support team has been notified about the error'
              );
          }
        },
        error: (err) => {
          if (
            err.status == '401' &&
            this._featureFlagsService.isFeatureEnabled('OR_12510_Auth0_Login')
          ) {
            setTimeout(() => this.checkProgress(uuid), 1000);
          } else {
            this._notificationService.apiError(err);
          }
        },
      });
  }

  private createProjectLink() {
    return this.data
      ? `/${this.organization_hash}/projects/${
          this.data.projectId || this.data.id || this.data.project_id
        }`
      : '';
  }

  private onFormChange() {
    this.form.get('projectId').valueChanges.subscribe((projectId) => {
      if (!projectId) {
        this.form.get('name').enable();
      } else {
        const selectedProject = this.projects.find(
          (project) => project.id === projectId
        );
        if (
          this._featureFlagsService.isFeatureEnabled(
            'OR_12876_project_attributes'
          )
        ) {
          const tagIdsFormArray = this.form.get('tag_ids') as FormArray;

          selectedProject.tag_ids.forEach((value, index) => {
            tagIdsFormArray.at(index).patchValue(value);
          });
        }

        this.form.patchValue({
          public: selectedProject.public,
          description: selectedProject.description,
          is_occupied: selectedProject.is_occupied,
          projectDate: {
            project_date_from: selectedProject.project_date_from,
            project_date_to: selectedProject.project_date_to,
          },
          project_type: [selectedProject.project_type.id || null],
          project_risk: [selectedProject.project_risk.id || null],
          energy_saving: [selectedProject.energy_saving],
          allocated_by: [selectedProject.allocated_by],
        });
        if (!HelperService.isExist(this.period)) {
          this.periodList = this.projects
            .find((project) => project.id === projectId)
            .fiscal_years.map((period) =>
              HelperService.getPeriodDuration(period)
            );
        }

        this.form.get('name').disable();
        this.form.markAsDirty();
      }
    });

    if (this.existedProject) {
      this.updateValueFromController('projectId');
    }
  }

  private afterSuccessSubmit(data, warnings?): void {
    this.data = data;
    if (warnings) {
      this.data.warnings = warnings;
    }
    this.message = `Assets have been successfully added to project <a href=${this.createProjectLink()} class="link">${
      this.projectName
    }</a>.`;
    this.isLoading = false;
    this.cancelText = 'Ok';
  }

  public createPeriodList(): void {
    this.endPeriods = this.periodList.slice(
      this.form.value.createNewProject.period,
      this.form.value.createNewProject.period + 5
    );
    this.setValue(this.endPeriods.length - 1, 'createNewProject.end_period');
  }

  // Update Character counter for description field
  updateCharacterCount(): void {
    this.characterCount = this.form.get('description').value.length;
  }

  addProjectClassToBody() {
    this.body.classList.add('projectbuilder-modal-open');
  }

  removeProjectClassFromBody() {
    this.body.classList.remove('projectbuilder-modal-open');
  }

  ngOnDestroy() {
    this.removeProjectClassFromBody();
  }

  checkSingleDateRange(): void {
    const projectDateFrom = this.form.get(
      'projectDate.project_date_from'
    ).value;
    const projectDateTo = this.form.get('projectDate.project_date_to').value;

    if (!projectDateTo) {
      this.form.patchValue({
        projectDate: {
          project_date_to: projectDateFrom,
        },
      });
    }
  }

  clearSingleDateRange(): void {
    this.form.patchValue({
      projectDate: {
        project_date_to: null,
        project_date_from: null,
      },
    });
  }

  public updateProjectDateRange() {
    let dateUpdated = false;

    this.form
      .get('projectDate.project_date_from')
      .valueChanges.subscribe(() => {
        if (!dateUpdated) {
          dateUpdated = true;
          this.form.get('projectDate.project_date_to').updateValueAndValidity();
          dateUpdated = false;
        }
      });

    this.form.get('projectDate.project_date_to').valueChanges.subscribe(() => {
      if (!dateUpdated) {
        dateUpdated = true;
        this.form.get('projectDate.project_date_from').updateValueAndValidity();
        dateUpdated = false;
      }
    });
  }

  public searchProjectName(name) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this._projectsService.verifyProjectName(name).subscribe({
        next: () => {},
        error: (e) => {
          this.form.get('name').setErrors({ api: e.error.errors[0].message });
        },
      });
    }, 1000);
  }
  public handleNext() {
    if (this.isFormValid) {
      this._projectsService.verifyProjectName(this.form.value.name).subscribe({
        next: () => {
          this.next = true;
        },
        error: (e) => {
          this.form.get('name').setErrors({ api: e.error.errors[0].message });
        },
      });
    }
    return false;
  }

  public addNoneOption(tags: any[]): any[] {
    const valuesWithNone = [{ id: null, name: 'None' }];
    return valuesWithNone.concat(tags);
  }

  getErrorText(field: string): string {
    const ERROR_MESSAGES = {
      INVALID_FORMAT: 'Invalid Format',
    };
    const control = this.form.get(field);
    if (!control) {
      return '';
    }
    if (field === 'name' && control.hasError('pattern')) {
      return ERROR_MESSAGES.INVALID_FORMAT;
    }
    return super.getErrorText(field);
  }
}
