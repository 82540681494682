<div class="breadcrumbs-bar"></div>

<form
  #userForm="ngForm"
  (ngSubmit)="saveAction()"
  (keydown.enter)="$event.preventDefault()"
  appInvalidControlScroll
  novalidate
>
  <div class="content-loading" *ngIf="!model">
    <app-loader></app-loader>
  </div>
  <article class="board mrgn-b" *ngIf="model">
    <header class="board__header">
      <h2 class="board__title">User edit</h2>
    </header>
    <div class="board_title">
      <div
        class="avatar-edit avatar-edit_padding fixed-width fixed-width-300 flex-column"
      >
        <label class="text-center flex-shrink">{{ 'photo' | translate }}</label>

        <div class="avatar-edit__container flex-grow">
          <figure
            *ngIf="!cropper_avatar.original"
            class="avatar-edit__container"
          >
            <img
              *ngIf="model?.picture"
              src="{{ model?.get_avatar() }}"
              alt="Avatar"
              class="edit-logo-holder__img"
            />
          </figure>
        </div>
        <button
          type="button"
          class="gallery-button-holder"
          *ngIf="!cropper_avatar.original && model?.picture"
          title="Delete Logo"
          (click)="removeUserPicture()"
        >
          <app-svg [href]="'trash'" iconClass="icon icon_xxs"></app-svg>
        </button>
        <app-cropper
          *ngIf="!model?.picture"
          [cropHeight]="300"
          [cropWidth]="300"
          [previewWidth]="300"
          [previewHeight]="300"
          [cropHolder]="cropper_avatar"
          [fullSize]="true"
          [ngStyle]="{
            height: '100%',
            display: 'flex',
            width: '100%',
            textAlign: 'center'
          }"
          [ngClass]="{ hidden: model?.picture || isCreate }"
          (onCropApply)="updateUserPicture()"
          (onCropCancel)="cancelUserAvatar()"
        >
        </app-cropper>

        <span class="avatar-error caption" *ngIf="cropper_avatar.error">
          {{ 'validation.image_format' | translate }}
        </span>
      </div>
      <ul class="board__description edit-list_sm edit-list_border-left">
        <li class="edit-list__row">
          <label for="first_name" class="xs-24 edit-list__title"
            >{{ 'labels.user.first_name' | translate
            }}<span class="asterisk">*</span></label
          >

          <div class="edit-list__description">
            <input
              [(ngModel)]="model.first_name"
              [customMaxlength]="25"
              [ngClass]="{ 'form-block_error': hasError(_first_name) }"
              class="form-block__element form-block__element_wide"
              required
              #_first_name="ngModel"
              name="first_name"
              id="first_name"
              type="text"
              [placeholder]="'labels.user.first_name' | translate"
              [pattern]="regex.specialSymbols"
            />
            <p class="error" *ngIf="hasError(_first_name)">
              <ng-container *ngIf="_first_name.errors.required">
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container *ngIf="_first_name.errors.pattern">
                {{ 'validation.start_with_special_char' | translate }}
              </ng-container>
              <ng-container *ngIf="_first_name.errors.maxlength">
                {{ 'validation.maxlength' | translate }}
              </ng-container>
            </p>
          </div>
        </li>

        <li class="edit-list__row">
          <label for="last_name" class="xs-24 edit-list__title"
            >{{ 'labels.user.last_name' | translate
            }}<span class="asterisk">*</span></label
          >

          <div class="edit-list__description">
            <input
              [(ngModel)]="model.last_name"
              [customMaxlength]="25"
              [ngClass]="{ 'form-block_error': hasError(_last_name) }"
              class="form-block__element form-block__element_wide"
              required
              #_last_name="ngModel"
              name="last_name"
              id="last_name"
              type="text"
              placeholder="Last Name"
              [pattern]="regex.specialSymbols"
            />
            <p class="error" *ngIf="hasError(_last_name)">
              <ng-container *ngIf="_last_name.errors.required">
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container *ngIf="_last_name.errors.maxlength">
                {{ 'validation.maxlength' | translate }}
              </ng-container>
              <ng-container *ngIf="_last_name.errors.pattern">
                {{ 'validation.start_with_special_char' | translate }}
              </ng-container>
            </p>
          </div>
        </li>

        <li class="edit-list__row">
          <label for="position" class="xs-24 edit-list__title">{{
            'labels.user.position' | translate
          }}</label>

          <div class="edit-list__description">
            <input
              [(ngModel)]="model.position"
              #_position="ngModel"
              [customMaxlength]="30"
              [ngClass]="{ 'form-block_error': hasError(_position) }"
              class="form-block__element form-block__element_wide"
              type="text"
              name="position"
              id="position"
              placeholder="Position"
              [pattern]="regex.specialSymbols"
            />
            <p class="error" *ngIf="hasError(_position)">
              <ng-container *ngIf="_position.errors.maxlength">
                {{ 'validation.maxlength' | translate }}
              </ng-container>
              <ng-container *ngIf="_position.errors.pattern">
                {{ 'validation.start_with_special_char' | translate }}
              </ng-container>
            </p>
          </div>
        </li>

        <li class="edit-list__row">
          <label for="bio" class="xs-24 edit-list__title">{{
            'labels.user.bio' | translate
          }}</label>

          <div class="edit-list__description">
            <textarea
              [(ngModel)]="model.bio"
              [ngClass]="{ 'form-block_error': hasError(_bio) }"
              #_bio="ngModel"
              [customMaxlength]="250"
              class="form-block__element form-block__element_wide"
              type="text"
              name="bio"
              id="bio"
              rows="8"
              placeholder="{{ 'labels.user.bio' | translate }}"
            ></textarea>

            <p class="error" *ngIf="hasError(_bio) && _bio.errors.maxlength">
              {{ 'validation.maxlength' | translate }}
            </p>
          </div>
        </li>

        <li class="edit-list__row">
          <label for="email" class="xs-24 edit-list__title"
            >{{ 'email' | translate }}<span class="asterisk">*</span></label
          >

          <div class="edit-list__description">
            <input
              [(ngModel)]="model.email"
              [disabled]="true"
              [ngClass]="{ 'form-block_error': hasError(_email) }"
              class="form-block__element form-block__element_wide"
              required
              [customMaxlength]="40"
              [pattern]="regex.email"
              #_email="ngModel"
              name="email"
              id="email"
              type="email"
              [placeholder]="'email' | translate"
              appTrimOnBlur
            />

            <p class="error" *ngIf="hasError(_email)">
              <ng-container *ngIf="_email.errors.required">
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container *ngIf="_email.errors.pattern">
                {{ 'validation.bad_email' | translate }}
              </ng-container>
              <ng-container *ngIf="_email.errors.maxlength">
                {{ 'validation.maxlength' | translate }}
              </ng-container>
              <ng-container *ngIf="_email.errors.api">
                {{
                  _email.errors.api || 'validation.unique_field'
                    | translate
                      : {
                          type_name: 'User',
                          field_name: 'email'
                        }
                }}
              </ng-container>
            </p>
          </div>
        </li>

        <li class="edit-list__row">
          <label for="phone" class="xs-24 edit-list__title"
            >{{ 'labels.user.phone' | translate
            }}<span class="asterisk">*</span></label
          >

          <div class="edit-list__description">
            <input
              [(ngModel)]="model.phone"
              [ngClass]="{ 'form-block_error': hasError(_phone) }"
              class="form-block__element form-block__element_wide"
              required
              #_phone="ngModel"
              name="phone"
              id="phone"
              type="tel"
              placeholder="{{ 'labels.user.phone' | translate }}"
              [customMaxlength]="50"
            />
            <p class="error" *ngIf="hasError(_phone)">
              <ng-container *ngIf="_phone.errors.required">
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container *ngIf="_phone.errors.maxlength">
                {{ 'validation.phone_number' | translate }}
              </ng-container>
            </p>
          </div>
        </li>

        <li class="edit-list__row">
          <label for="country" class="xs-24 edit-list__title">{{
            'labels.user.country' | translate
          }}</label>

          <div class="edit-list__description">
            <app-select
              name="country"
              id="country"
              required
              [(ngModel)]="model.address.country"
              (ngModelChange)="model.address.state = ''"
              optKey="value"
              [placeholder]="'placeholders.select_country' | translate"
              [values]="countries"
              widthClass="multiselect_xl"
            >
            </app-select>
          </div>
        </li>

        <li class="edit-list__row">
          <label for="state" class="xs-24 edit-list__title">{{
            'labels.user.state' | translate
          }}</label>
          <div class="edit-list__description">
            <app-select
              name="state"
              id="state"
              [ngClass]="{ 'form-block_error': hasError(_state) }"
              [(ngModel)]="model.address.state"
              [required]="model.address.country === 'US'"
              #_state="ngModel"
              [disabled]="model.address.country !== 'US'"
              optKey="key"
              placeholder="Select state"
              [values]="states"
              widthClass="multiselect_xl"
            >
            </app-select>

            <p class="error" *ngIf="hasError(_state) && _state.errors.required">
              {{ 'validation.state_required' | translate }}
            </p>
          </div>
        </li>

        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'labels.user.city' | translate }}<span class="asterisk">*</span>
          </h3>

          <div class="edit-list__description">
            <label class="form-block">
              <input
                name="city"
                id="city"
                type="text"
                class="form-block__element"
                [ngClass]="{ 'form-block_error': hasError(_city) }"
                [(ngModel)]="model.address.city"
                placeholder="{{ 'labels.user.city' | translate }}"
                required
                [customMaxlength]="40"
                #_city="ngModel"
              />
            </label>

            <p class="error error_inline" *ngIf="hasError(_city)">
              <ng-container *ngIf="_city.errors.required">
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container *ngIf="_city.errors.maxlength">
                {{ 'validation.maxlength' | translate }}
              </ng-container>
            </p>
          </div>
        </li>

        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'address' | translate }}<span class="asterisk">*</span>
          </h3>

          <div class="edit-list__description">
            <label class="form-block">
              <input
                name="address1"
                type="text"
                [ngClass]="{ 'form-block_error': hasError(_address1) }"
                [(ngModel)]="model.address.address1"
                class="form-block__element"
                placeholder="{{ 'address' | translate }}"
                required
                [customMaxlength]="150"
                #_address1="ngModel"
              />
            </label>
            <p class="error error_inline" *ngIf="hasError(_address1)">
              <ng-container *ngIf="_address1.errors.required">
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container *ngIf="_address1.errors.maxlength">
                {{ 'validation.maxlength' | translate }}
              </ng-container>
            </p>
          </div>
        </li>

        <li class="edit-list__row">
          <label for="zipcode" class="xs-24 edit-list__title"
            >Zip/Postal Code<span class="asterisk">*</span></label
          >

          <div class="edit-list__description">
            <input
              name="zipcode"
              id="zipcode"
              type="text"
              class="form-block__element form-block__element_wide"
              [ngClass]="{ 'form-block_error': hasError(_zipcode) }"
              [(ngModel)]="model.address.zipcode"
              [placeholder]="'zip' | translate"
              required
              [customMaxlength]="10"
              #_zipcode="ngModel"
            />
            <p class="error" *ngIf="hasError(_zipcode)">
              <ng-container *ngIf="_zipcode.errors.required">
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container *ngIf="_zipcode.errors.maxlength">
                {{ 'validation.zip_code' | translate }}
              </ng-container>
            </p>
          </div>
        </li>

        <li class="edit-list__row">
          <label for="role" class="xs-24 edit-list__title"
            >{{ 'labels.user.role' | translate
            }}<span class="asterisk">*</span></label
          >
          <div
            class="edit-list__description"
            *ngIf="rolesEditAllowed && !isCurrent"
          >
            <app-select
              name="role"
              id="role"
              [(ngModel)]="model.role_name"
              optKey="key"
              optValue="title"
              [showPlaceholder]="false"
              [values]="model?.roles_available"
              widthClass="multiselect_xl"
              (ngModelChange)="filterOrganizationsByRole()"
            >
            </app-select>
          </div>
          <p
            class="edit-list__value text-semibold"
            *ngIf="!rolesEditAllowed || isCurrent"
          >
            {{ model?.role_name ? roles[model?.role_name].name : '' }}
          </p>
        </li>

        <!--FHI Engineer Organizations-->
        <li
          class="edit-list__row"
          *ngIf="
            model?.role_name == 'tenant_engineer' ||
            model.role_name == 'tenant_sale' ||
            model.role_name == 'partner_admin' ||
            model.role_name == 'partner_engineer'
          "
        >
          <h2 class="xs-24 edit-list__title">
            {{ getOrganizationLabelText(model) | translate
            }}<span class="asterisk">*</span>
          </h2>

          <div class="edit-list__description">
            <p *ngIf="!rolesEditAllowed || isCurrent">
              <ng-container
                *ngFor="let id of model?.organization_ids; let _index = index"
              >
                {{ organizations_obj[id]?.name
                }}{{
                  _index != model?.organization_ids.length - 1 ? ', ' : ' '
                }}
              </ng-container>
            </p>
            <div class="form-block__element_wide">
              <app-multiselect
                *ngIf="rolesEditAllowed && !isCurrent"
                name="organization_ids"
                class="form-block"
                [required]="
                  model?.role_name == 'tenant_engineer' ||
                  model.role_name === 'tenant_sale' ||
                  model.role_name == 'partner_admin' ||
                  model.role_name == 'partner_engineer'
                "
                [id]="'class'"
                [(ngModel)]="model.organization_ids"
                [isDisabled]="false"
                [key]="'id'"
                [as]="'name'"
                [placeholder]="'Select Organizations'"
                [values]="test_organizations"
                [topDirection]="true"
              >
              </app-multiselect>
            </div>
          </div>
        </li>
        <!--END FHI Engineer Organizations-->
        <!--Signature-->
        <ng-container *appPermission="'ca_cu_ce'">
          <li class="edit-list__row" *ngIf="isCurrent">
            <h3 class="xs-24 edit-list__title">Signature</h3>

            <div class="edit-list__description">
              <div class="avatar-edit">
                <p
                  class="signature-no"
                  *ngIf="
                    !model?.signature &&
                    !cropper_signature.original &&
                    !cropper_signature.error
                  "
                >
                  {{ 'no_file_selected' | translate }}
                </p>

                <p
                  class="field-hint"
                  *ngIf="
                    !model?.signature &&
                    !cropper_signature.original &&
                    !cropper_signature.error
                  "
                >
                  <i>{{ 'signature_tip' | translate }}</i>
                </p>

                <p
                  class="field-hint signature-error caption"
                  *ngIf="cropper_signature.error"
                >
                  {{ 'validation.image_format' | translate }}
                </p>

                <figure>
                  <img
                    alt=""
                    width="300px"
                    height="150px"
                    [src]="getUserSignatureUrl()"
                    *ngIf="
                      model?.signature &&
                      !cropper_signature.original &&
                      !cropper_signature.error
                    "
                  />
                </figure>

                <app-cropper
                  *ngIf="!model.signature"
                  [cropWidth]="300"
                  [cropHeight]="150"
                  [previewWidth]="300"
                  [previewHeight]="150"
                  [cropHolder]="cropper_signature"
                  [rounded]="false"
                  (onCropCancel)="cancelUserSignature()"
                  (onCropApply)="updateUserSignature()"
                >
                </app-cropper>

                <button
                  (click)="removeUserSignature()"
                  *ngIf="
                    model?.signature &&
                    !cropper_signature.original &&
                    !cropper_signature.error
                  "
                  class="btn action-btn action-btn_success mrgn-t"
                >
                  Reset
                </button>
              </div>
            </div>
          </li>
        </ng-container>
        <!--END Signature-->
        <!-- SSO login -->
      </ul>
    </div>
  </article>

  <section class="content mrgn-b" *ngIf="showDefaultFacilitySection">
    <header class="header">
      <h2 class="header__title">
        {{ 'titles.default_facilities' | translate }}
      </h2>
    </header>

    <div class="content-details_sm">
      <ul *ngIf="model?.facilities_attributes?.length">
        <li
          class="board mrgn-b"
          *ngFor="let facility of model.facilities_attributes; let _i = index"
        >
          <header class="board__header">
            <h3 class="board__title">
              {{ 'location' | translate }} {{ _i + 1 }}
            </h3>
            <div class="board__header-actions">
              <button
                type="button"
                (click)="deleteFacility(_i)"
                class="btn btn_iconed btn_iconed_white btn_iconed-small"
              >
                <app-svg [href]="'trash'"></app-svg>
              </button>
            </div>
          </header>
          <ul class="edit-list_sm">
            <li class="edit-list__row">
              <h3 class="edit-list__title xs-25">
                {{ 'location' | translate }}<span class="asterisk">*</span>
              </h3>
              <div class="edit-list__description">
                <app-multiselect
                  [(ngModel)]="facility.location_id"
                  [key]="'id'"
                  [as]="'name'"
                  [placeholder]="'All Locations'"
                  fieldName="Location"
                  [id]="'location' + _i"
                  [singleValueSelected]="true"
                  [returnSingleValueAsNumber]="true"
                  [topDirection]="_i === model.facilities_attributes.length - 1"
                  [ngClass]="{ 'form-block_error': hasError(_locations) }"
                  [resetField]="'building'"
                  #_locations="ngModel"
                  [required]
                  class="form-block el-block"
                  [values]="locations"
                  (ngModelChange)="loadBuildings($event, facility)"
                  [name]="'facilities_attributes.' + _i + '.location'"
                >
                </app-multiselect>
                <p
                  class="error"
                  *ngIf="hasError(_locations) && _locations.errors.required"
                >
                  {{ 'validation.required' | translate }}
                </p>
              </div>
            </li>
            <li class="edit-list__row">
              <h3 class="edit-list__title xs-25">
                {{ 'buildings' | translate }}<span class="asterisk">*</span>
              </h3>
              <div class="edit-list__description">
                <app-multiselect
                  [(ngModel)]="facility.building_ids"
                  [key]="'id'"
                  [as]="'name'"
                  [isDisabled]="!facility.location_id"
                  [placeholder]="'All Buildings'"
                  [id]="'building' + _i"
                  [values]="buildingDropdowns[facility.location_id] || []"
                  [ngClass]="{ 'form-block_error': hasError(_buildingIds) }"
                  [resetField]="'building'"
                  [showSelectAll]="true"
                  [topDirection]="_i === model.facilities_attributes.length - 1"
                  #_buildingIds="ngModel"
                  required
                  class="form-block el-block"
                  [name]="'facilities_attributes.' + _i"
                >
                </app-multiselect>
                <p class="error" *ngIf="hasError(_buildingIds)">
                  <ng-container *ngIf="_buildingIds.errors.required">{{
                    'validation.required' | translate
                  }}</ng-container>
                  <ng-container *ngIf="_buildingIds.errors.api">{{
                    _buildingIds.errors.api
                  }}</ng-container>
                </p>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <button
        type="button"
        class="btn-add-attribute btn-add-attribute_blue"
        (click)="addLocation()"
        [disabled]="!model.facilities_attributes"
      >
        <app-svg [href]="'plus'"></app-svg>
        <span>Add Location</span>
      </button>
    </div>
  </section>

  <div class="flex">
    <button
      type="button"
      (click)="cancel()"
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">{{ 'buttons.cancel' | translate }}</span>
    </button>
    <button class="btn action-btn action-btn_success">
      <span class="action-btn__icon"><app-svg [href]="'check'"></app-svg></span>
      <span class="action-btn__title">{{ 'buttons.save' | translate }}</span>
    </button>
  </div>
</form>
