import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseReactiveForm } from '../base-reactive-form';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';

@Component({
  selector: 'app-editable-select',
  templateUrl: './editable-select.component.html',
})
export class EditableSelectComponent extends BaseReactiveForm<string> {
  @Input() chosenOptionId: number | string;
  @Input() chosenOptionName: string;
  @Input() set apiError(val) {
    this.setErrorToControl(val);
  }
  @Input() set disabled(v: boolean) {
    this._disabled = v;
    if (!v) {
      this.editing = false;
    }
  }

  @Input() key: string = 'id';
  @Input() required: boolean = true;
  @Input() selectOptions: any[] = [];
  @Input() showPlaceholder: boolean = true;
  @Input() value: string = 'name';
  @Output() updateValue = new EventEmitter();

  public editing: boolean;
  private _disabled: boolean;
  private initialForm: any;

  public get disabled(): boolean {
    return this._disabled;
  }

  constructor(
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected formBuilder: FormBuilder,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }

  ngOnInit(): void {
    this.setupForm();
    this.initialForm = this.form.value;
  }

  ngOnChanges(): void {
    this.setupForm();
    this.initialForm = this.form.value;
  }

  public emitSaveValue() {
    if (this.isFormValid) {
      this.editing = false;
      if (this.initialForm.value !== this.form.value.value) {
        this.initialForm = this.form.value;
        this.updateValue.emit(this.form.value.value);
      }
    }
  }

  public stopEditingIfEsc() {
    this.editing = false;
    this.resetForm(this.initialForm);
  }

  protected setupForm() {
    this.form = this.formBuilder.group({
      value: [this.chosenOptionId, this.required ? Validators.required : null],
    });
  }
}
