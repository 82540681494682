import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { BaseChartComponent } from '../base-chart.component';

@Component({
  selector: 'app-chart-star',
  templateUrl: './chart-star.component.html',
  styleUrls: ['./chart-star.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ChartStarComponent extends BaseChartComponent {
  @ViewChild('elStarChart', { static: true }) elStarChart: ElementRef;
  @Input() public chartTitle: string;
  @Input() declare randomColors?: boolean;
  @Input() declare filename: string;
  @Input()
  protected set chartValues(value) {
    this._chartValues = value;
    if (this.helper.isExist(value)) {
      this.updateCharts();
    }
  }

  protected get chartValues(): any {
    return this._chartValues || {};
  }

  @Input() public chartType?: string = 'star';
  private pixelRatio?: number = 2;
  private oldChartValues;
  public canvasSettings: any;

  ngOnInit() {
    this.canvasId = 'starCanvas';
  }
  /**
   * default settings:
   * type: bar
   * colors: #337ab7
   * paddings: 10px
   * **/

  private prepareValuesAndCreateChart(): void {
    if (typeof this.chartValues !== 'undefined') {
      this.data = {
        labels: this.getLabelsFromDataSets(),
        datasets: [
          {
            label: this.chartTitle,
            data: this.getValuesFromDataSets(),
          },
        ],
      };
      this.createCharts();
    }
  }

  private createCharts(): void {
    if (this.elStarChart?.nativeElement) {
      this.drawTableOnCanvas(this.elStarChart.nativeElement);
      this.isCreated = true;
    }
  }

  protected updateCharts(): void {
    if (!this.isCreated || this.chartValues !== this.oldChartValues) {
      this.prepareValuesAndCreateChart();
    }
  }

  public drawTableOnCanvas(canvasElement) {
    const scaleIndex = this.pixelRatio;
    const ctxWidth = 460 * this.pixelRatio;
    const ctxPadding = 20 * scaleIndex;
    const fontSize = 18 * scaleIndex;
    const cellHeight = 2 * ctxPadding + fontSize;
    const ctxHeight = 560 * this.pixelRatio;
    this.elStarChart.nativeElement.height = ctxHeight;
    this.elStarChart.nativeElement.width = ctxWidth;

    this.canvasSettings = {
      scaleIndex,
      ctxWidth,
      ctxHeight,
      ctxPadding,
      fontSize,
      cellHeight,
      cellWidth: ctxWidth - 2 * ctxPadding,
      strokeStyle: '#8597A6',
      fillStyle: '#444444',
      lineWidth: scaleIndex,
    };

    const ctx = canvasElement.getContext('2d');
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(
      0,
      0,
      this.canvasSettings.ctxWidth,
      this.canvasSettings.ctxHeight
    );
    ctx.font = `500 ${this.canvasSettings.fontSize}px Roboto`;
    ctx.fillStyle = this.canvasSettings.fillStyle;
    this.drawTitle(ctx);
    // draw table
    const cellData = this.data.datasets[0].data;
    const cellLabels = this.data.labels;
    const positionX = ctxPadding;
    this.drawTableHeading(
      ctx,
      positionX,
      cellHeight * 1.5,
      'Asset Score',
      'Q-ty',
      true
    );
    const startTablePositionY = cellHeight * 2.5;
    cellData.forEach((item, index) => {
      const numberToFill = parseInt(cellLabels[index].replace('score', ''));
      const positionY = startTablePositionY + index * cellHeight - 1;
      this.drawCell(ctx, positionX, positionY, item);
      this.fillStars(
        ctx,
        positionX + ctxPadding + fontSize / 2,
        positionY + ctxPadding + fontSize / 2,
        numberToFill,
        5
      );
    });
    // draw black line under the table
    ctx.beginPath();
    ctx.moveTo(
      this.canvasSettings.ctxPadding,
      startTablePositionY + cellData.length * cellHeight - 1
    );
    ctx.lineTo(
      this.canvasSettings.ctxPadding + this.canvasSettings.cellWidth,
      startTablePositionY + cellData.length * cellHeight - 1
    );
    ctx.strokeStyle = '#000000';
    ctx.stroke();
    //
    this.drawTableHeading(
      ctx,
      this.canvasSettings.ctxPadding / 2,
      startTablePositionY + cellData.length * cellHeight - 1,
      'TOTAL ASSETS SURVEYED',
      this.chartValues.total,
      false,
      true
    );
  }

  public drawTitle(ctx) {
    // draw title
    ctx.strokeStyle = this.canvasSettings.strokeStyle;
    ctx.fillStyle = this.canvasSettings.fillStyle;
    ctx.textAlign = 'left';
    ctx.fillText(
      this.chartTitle,
      this.canvasSettings.ctxPadding,
      this.canvasSettings.cellHeight
    );
  }

  public drawTableHeading(
    ctx,
    positionX,
    positionY,
    textLeft,
    textRight,
    border: boolean,
    boldFont?: boolean
  ) {
    ctx.strokeStyle = this.canvasSettings.strokeStyle;
    border &&
      ctx.strokeRect(
        positionX,
        positionY,
        this.canvasSettings.cellWidth,
        this.canvasSettings.cellHeight
      );
    ctx.fillStyle = this.canvasSettings.fillStyle;
    ctx.textAlign = 'left';
    if (boldFont) {
      ctx.font = `bold ${this.canvasSettings.fontSize}px Roboto`;
    }
    ctx.fillText(
      textLeft,
      positionX + this.canvasSettings.ctxPadding,
      positionY + this.canvasSettings.ctxPadding + this.canvasSettings.fontSize
    );
    ctx.textAlign = 'right';
    ctx.fillText(
      textRight,
      this.canvasSettings.cellWidth,
      positionY + this.canvasSettings.ctxPadding + this.canvasSettings.fontSize,
      10 * this.canvasSettings.fontSize
    );
    ctx.font = `500 ${this.canvasSettings.fontSize}px Roboto`;
  }

  public drawCell(ctx, positionX, positionY, data) {
    ctx.textAlign = 'left';
    ctx.strokeStyle = this.canvasSettings.strokeStyle;
    ctx.strokeRect(
      positionX,
      positionY,
      this.canvasSettings.cellWidth,
      this.canvasSettings.cellHeight
    );
    ctx.textAlign = 'right';
    ctx.fillStyle = this.canvasSettings.fillStyle;
    ctx.fillText(
      data,
      this.canvasSettings.cellWidth,
      positionY + this.canvasSettings.ctxPadding + this.canvasSettings.fontSize,
      10 * this.canvasSettings.fontSize
    );
  }

  public fillStars(
    ctx,
    startPositionX,
    startPositionY,
    numberToFill,
    totalNumber
  ) {
    const innerRadius = this.canvasSettings.fontSize / 3.5;
    const outerRadius = this.canvasSettings.fontSize / 1.5;
    let filled = true;
    for (let i = 0; i < totalNumber; i++) {
      filled = filled && i < numberToFill;
      this.drawStar(
        ctx,
        startPositionX + i * this.canvasSettings.ctxPadding * 2,
        startPositionY,
        5,
        outerRadius,
        innerRadius,
        filled
      );
    }
  }

  public drawStar(ctx, cx, cy, spikes, outerRadius, innerRadius, filled) {
    const step = Math.PI / spikes;
    let x = cx;
    let y = cy;
    ctx.beginPath();
    ctx.strokeStyle = this.canvasSettings.strokeStyle;
    if (filled) {
      ctx.strokeStyle = '#FFB800';
    }
    ctx.moveTo(cx, cy - outerRadius);
    let rot = (Math.PI / 2) * 3;
    for (let i = 0; i < spikes; i++) {
      x = cx + Math.cos(rot) * outerRadius;
      y = cy + Math.sin(rot) * outerRadius;
      ctx.lineTo(x, y);
      rot += step;
      x = cx + Math.cos(rot) * innerRadius;
      y = cy + Math.sin(rot) * innerRadius;
      ctx.lineTo(x, y);
      rot += step;
    }
    ctx.lineTo(cx, cy - outerRadius);
    ctx.closePath();
    ctx.lineWidth = this.canvasSettings.scaleIndex;
    ctx.stroke();
    if (filled) {
      ctx.fillStyle = '#FFB800';
      ctx.fill();
    }
  }
}
