<form class="searching-form">
  <input
    id="searchingFormInputId"
    (keydown.enter)="search()"
    (keyup)="searchIfChanged()"
    [(ngModel)]="keyword"
    [ngModelOptions]="{ standalone: true }"
    [disabled]="disabled"
    type="search"
    class="searching-form__input"
    placeholder="{{ placeholder }}"
    aria-controls="dt_basic"
  />
  <button
    class="searching-form__btn"
    *ngIf="!ifSearch()"
    (click)="search()"
    [disabled]="disabled"
    type="button"
    id="searchingFormBtnId"
  >
    <app-svg [href]="'search'" iconClass="icon icon_s"></app-svg>
  </button>
  <button
    class="searching-form__btn"
    *ngIf="ifSearch()"
    (click)="clearSearch()"
    type="button"
  >
    <app-svg [href]="'times'"></app-svg>
  </button>
</form>
