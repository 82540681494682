import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Configs } from '../../../core/constants/configs.constants';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-file-upload',
  template:
    '<ng-content></ng-content>' +
    '<input #importFile accept="{{accept}}" type="file" (change)="upload($event)" [ngStyle]="{display: \'none\'}">',
})
export class FileUploadComponent implements OnDestroy {
  @ViewChild('importFile', { static: true }) importFile: ElementRef;

  @Input() public accept: string;
  @Input() public formats: string[] = [];

  @Output() public onUpload: EventEmitter<File> = new EventEmitter<File>();

  protected subscriptions: Subscription = new Subscription();

  private modalRef: BsModalRef;

  @HostListener('click') onClick() {
    this.importFile.nativeElement.click();
  }

  constructor(private modalService: BsModalService) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  public upload(event): void {
    let enabled = !this.formats.length;
    if (this.formats.length) {
      this.formats.forEach((format) => {
        if (event.target.files && event.target.files[0].name.match(format)) {
          enabled = true;
        }
      });
    }
    if (!enabled) {
      this.importFile.nativeElement.value = '';
      this.modalRef = this.modalService.show(ConfirmationModalComponent, {
        class: Configs.MODAL_CLASS,
        animated: true,
        initialState: {
          header: 'Error',
          message: `File format error! Accepted types: ${this.formats.join(', ')}`,
          hideSubmit: true,
          cancelText: 'Close',
        },
      });
      return;
    }
    const file = event.target.files[0];
    this.onUpload.emit(file);

    this.importFile.nativeElement.value = '';
  }
}
