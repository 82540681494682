import {
  EventEmitter,
  Injectable,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { BaseFormComponent } from './base-form.component';
import { Configs } from '../../core/constants/configs.constants';
import { CustomNotificationService } from '../../core/services/custom-notification.service';
import { DateTime } from 'luxon';
import { DefaultFilterModel } from '../../core/models/default-filter.model';
import { EventService } from '../../core/services/event.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { FilterService } from '../../core/services/filter.service';
import { HelperService } from '../../core/services/helper.service';
import { LocationService } from '../../core/services/location.service';
import { NgForm } from '@angular/forms';
import { OrganizationCurrentService } from '../../core/services/organization-current.service';
import { OrganizationModel } from '../../core/models/organization.model';

@Injectable()
export class BaseFiltersComponent extends BaseFormComponent {
  get allowedFilters() {
    return this.allowedFiltersList;
  }
  public _featureFlagService: FeatureFlagsService = inject(FeatureFlagsService);

  constructor(
    protected _locationService: LocationService,
    protected _filterService: FilterService,
    protected activeRoute: ActivatedRoute,
    protected _organizationCurrent: OrganizationCurrentService,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
  ) {
    super(_notificationService, _eventService);
  }

  static metaData = {
    inputs: [
      'disableFilters',
      'organization',
      'filterType',
      'isOpened',
      'disableApplyButton',
      'disableResetButton',
      'forceUpdateBatchId',
      'hideApplyButton',
      'hideResetButton',
      'hideUnlockButton',
      'resetButtonText',
      'applyButtonText',
      'saveToCookies',
      'allowedFiltersList',
      'indicesMode',
      'readyFilterSet',
      'filterSetOnNothingSelected',
    ],
    outputs: ['onApplyFilters', 'onResetFilters', 'onUnlockFilters'],
  };
  @Input() public disableFilters: boolean;
  @Input() public organization: OrganizationModel;
  @Input() public filterType: string;
  @Input() public isOpened: boolean;
  @Input() public disableApplyButton: boolean;
  @Input() public disableResetButton: boolean;
  @Input() public forceUpdateBatchId: boolean;
  @Input() public hideApplyButton: boolean;
  @Input() public hideResetButton: boolean;
  @Input() public hideUnlockButton: boolean = true;
  @Input() public resetButtonText: string = 'Reset';
  @Input() public applyButtonText: string = 'Apply';
  @Input() public saveToCookies: boolean = true;
  @Input() public allowedFiltersList;
  @Input() public indicesMode?: string = 'da';
  // use if need to set ready filter set without cookies
  @Input() public set readyFilterSet(filters: { [key: string]: any }) {
    if (filters) {
      if (this.filterDropdownsReady) {
        this.restoreDropdownDependencyAndApplyFilters(filters);
        return;
      }

      setTimeout(() => (this.readyFilterSet = filters), 300);
    }
  }
  @Input() public filterSetOnNothingSelected: { [key: string]: any } = {}; // use if need to set filter options if other is not picked

  @Output() onApplyFilters = new EventEmitter<DefaultFilterModel>();
  @Output() onResetFilters = new EventEmitter<DefaultFilterModel>();
  @Output() onUnlockFilters = new EventEmitter<DefaultFilterModel>();

  @ViewChild('filtersForm', { static: true }) declare tdForm: NgForm;

  selectedFiltersCount: number = 0;
  public filterDropdowns: any = [];
  public filterDropdownsReady: boolean;
  page: number;
  selectLimits: any = {};
  filter: DefaultFilterModel = {};
  datepickers: {
    lifetimeFrom: { maxDate: 'lifetimeTo' };
    lifetimeTo: { minDate: 'lifetimeFrom' };
  };

  hasError($el): boolean {
    return $el.invalid && (!$el.pristine || this.hasSubmitted);
  }

  validateMax(pairField: string, maxLimit: number = 99999999999.99): number {
    return this.filter?.[pairField] ?? maxLimit;
  }

  validateMin(pairField: string, minLimit: number = 0): number {
    return this.filter?.[pairField] ?? minLimit;
  }

  minStopDate(param: string = 'lifetimeFrom') {
    return this.filter[param]
      ? DateTime.fromISO(this.filter[param]).toISO()
      : null;
  }

  maxStopDate(param: string = 'lifetimeTo', restrictFutureDate?: boolean) {
    if (restrictFutureDate) {
      return DateTime.fromISO(this.filter[param]).toISO();
    }
    return this.filter[param]
      ? DateTime.fromISO(this.filter[param]).toISO()
      : null;
  }

  public updateFieldLimits(prop) {
    const propDate = DateTime.fromISO(this.filter[prop]).toFormat('x');
    if (this.datepickers[prop].maxDate) {
      const maxDate = DateTime.fromISO(
        this.filter[this.datepickers[prop].maxDate]
      ).toFormat('x');
      if (this.filter[prop] && propDate > maxDate) {
        this.filter[prop] = this.filter[this.datepickers[prop].maxDate];
      }
    }
    if (this.datepickers[prop].minDate) {
      const minDate = DateTime.fromISO(
        this.filter[this.datepickers[prop].minDate]
      ).toFormat('x');
      if (this.filter[prop] && propDate < minDate) {
        this.filter[prop] = this.filter[this.datepickers[prop].minDate];
      }
    }
  }

  updateSelectLimit(prop): void {
    const toName = this.selectLimits[prop],
      fromValue = this.filter[prop],
      toValue = this.filter[toName];
    if (toName) {
      if (!HelperService.isExist(fromValue)) {
        this.filterDropdowns[toName] = this.filterDropdowns[prop];
      } else {
        this.filterDropdowns[toName] = [...this.filterDropdowns[prop]].splice(
          this.filterDropdowns[prop].indexOf(parseInt(fromValue))
        );
        if (fromValue > toValue) {
          this.filter[toName] = fromValue;
        }
      }
    }
  }

  applyFilters(onDestroy?, isReset?, resetPage?: boolean): void {
    if (Object.keys(this.filter).length || resetPage) {
      this._filterService.setOpened(this.filterType, !isReset);
    }
    this.page = resetPage ? 1 : this.page;
    if (!onDestroy) {
      if (!isReset) {
        this.saveFilters();
      }
    }
  }

  resetFilters(): void {
    this.clearFilters();
    this.deleteFilters();
    this.checkSelectedFilters();
    this.submitFilters(true);
  }

  clearFilters() {
    this.filter = {};
  }

  resetFilterField(field: string) {
    delete this.filter[field];
  }

  loadFilters(): any {
    return JSON.parse(
      localStorage.getItem(
        `${this.organization?.id_hash || 'tenant'}_filters_${this.filterType}`
      ) || '{}'
    );
  }

  saveFilters() {
    localStorage.setItem(
      `${this.organization?.id_hash || 'tenant'}_filters_${this.filterType}`,
      JSON.stringify(this.filter)
    );
  }

  deleteFilters() {
    localStorage.removeItem(
      `${this.organization?.id_hash || 'tenant'}_filters_${this.filterType}`
    );
  }

  public submitFilters(emitReset?: boolean) {
    if (!this.tdForm || !this.tdForm.valid) {
      return;
    }
    this.checkSelectedFilters();
    this.hasSubmitted = true;
    this.saveFilters();
    this._filterService.allowedFilters = this.allowedFilters;
    const filterDTO = this._filterService.prepareFilterDTO(
      this.filter,
      this.filterType,
      this.indicesMode
    );
    this._filterService
      .updateFiltersBatchIdInStore(
        filterDTO,
        this.filterType,
        this.forceUpdateBatchId,
        !this.organization
      )
      .subscribe({
        next: () => {
          this.markAsUntouched();
          if (emitReset) {
            this.onResetFilters.emit(filterDTO);
          } else {
            this.onApplyFilters.emit(filterDTO);
          }
        },
        error: (err) => this._notificationService.apiError(err),
      });
  }

  filterFromQuery(): void {
    const $stateParams = this.activeRoute.snapshot.queryParams;
    this.isOpened =
      $stateParams.zone || $stateParams.location || $stateParams.asset_type;
    if (!this.isOpened) {
      this.applyFilters();
      return;
    }
    if ($stateParams.zone) {
      this.filter.zone = [+$stateParams.zone];
    } else if ($stateParams.asset_type) {
      this.filter.types = [];
      this.filter.types.push(+$stateParams.asset_type);
    } else if ($stateParams.location) {
      Configs.FACILITIES.forEach((facility) => {
        if (HelperService.isExist($stateParams[facility])) {
          this.filter[facility] = [+$stateParams[facility]];
        }
      });
    }
    this.restoreDropdownDependencyAndApplyFilters();
  }

  filterFromCookies() {
    const filtersCookie = this.loadFilters() || {};
    this.clearFilters();
    this.isOpened = filtersCookie && !!Object.keys(filtersCookie).length;
    this._filterService.setOpened(this.filterType, this.isOpened);
    const filterKeys = Object.keys(filtersCookie);
    filterKeys.forEach((key) => {
      if (
        HelperService.isExist(filtersCookie[key]) &&
        this.allowedFilters[key]
      ) {
        switch (key) {
          case 'importFrom':
          case 'installedAtFrom':
          case 'lifetimeFrom':
          case 'lifetimeTo':
          case 'importTo':
          case 'installedAtTo':
          case 'dueDateTo':
          case 'dueDateFrom':
            this.filter[key] = DateTime.fromISO(filtersCookie[key]).toISO();
            break;
          case 'totalRiskTo':
          case 'totalRiskFrom':
          case 'fhieFrom':
          case 'fhieTo':
          case 'fhiFrom':
          case 'fhiTo':
            this.filter[key] = +filtersCookie[key];
            break;
          case 'location':
            Configs.FACILITIES.forEach((facility) => {
              if (HelperService.isExist(filtersCookie[facility])) {
                this.filter[facility] = filtersCookie[facility];
              }
            });
            break;
          default:
            this.filter[key] = filtersCookie[key];
        }
      }
    });

    this.fillFiltersIfOptionIsNotPicked();

    this.restoreDropdownDependencyAndApplyFilters();
  }
  public restoreDropdownDependencyAndApplyFilters(_filter?) {
    this.isLoading = false;
    this.checkSelectedFilters();
    this.submitFilters();
  }

  public onFiltersChanges(name, params?): void {
    this.updateSelectLimit(name);
    this.prepareLifeTimeFilterRange(name);
    this.setDropdownDependency(
      name,
      this.filterDropdowns,
      this.filter,
      params
    ).subscribe((data) => (this.filterDropdowns = data));
    this.checkSelectedFilters();
    // if there is no apply button - save filters to cookies right on changes
    if (this.hideApplyButton && !this.isLoading) {
      this.saveFilters();
    }
  }

  public setDropdownDependency(
    name: string,
    filterDropdowns,
    filter: DefaultFilterModel,
    params?
  ) {
    if (!filterDropdowns[name]) {
      return of(filterDropdowns);
    }

    let selectedIds;
    if (typeof filter[name] === 'string' || typeof filter[name] === 'number') {
      selectedIds = [filter[name]];
    } else {
      selectedIds = filter[name];
    }
    const selectedItems = this._filterService.filterByIds(
      filterDropdowns[name],
      selectedIds
    );
    const dName = this._filterService.dependencyList[name];

    this._filterService.resetDependencyList(
      name,
      filter,
      filterDropdowns,
      true
    );

    if (!selectedItems || !dName) {
      return of(filterDropdowns);
    }
    // HACK: use observable to wait for multiselect directives finish model config
    return this.getLocationDropdownDependency(dName, selectedIds, params, name);
  }

  protected getLocationDropdownDependency(dName, selectedIds, params, name) {
    if (this._featureFlagService.isFeatureEnabled('OR_13306_relevant_location_building') && dName == 'building' && this.filterType.includes('forecastAssetList')) {
      let forecastId = this.filterType.split('_')[1];
      return new Observable((observe) => {
        this._locationService
          .getSubLevelByParentLocationForeForecast(
            dName,
            name,
            selectedIds,
            params,
            forecastId
          )
          .subscribe({
            next: (res) => {
              this.filterDropdowns[dName] = res.list;
              observe.next(this.filterDropdowns);
              observe.complete();
            },
            error: (err) => this._notificationService.apiError(err),
          });
      });
    } else {
      return new Observable((observe) => {
        this._locationService
          .getSubLevelByParentLocation(dName, name, selectedIds, params)
          .subscribe({
            next: (res) => {
              this.filterDropdowns[dName] = res.list;
              observe.next(this.filterDropdowns);
              observe.complete();
            },
            error: (err) => this._notificationService.apiError(err),
          });
      });
    }
  }

  prepareLifeTimeFilterRange(prop) {
    const timeFromFilters = [
      'lifetimeFrom',
      'dueDateFrom',
      'dateFrom',
      'installedAtFrom',
      'dateRangeFrom',
    ];
    const timeToFilters = [
      'lifetimeTo',
      'dueDateTo',
      'dateTo',
      'installedAtTo',
      'rangeTo',
      'importTo',
      'dateRangeTo',
    ];
    if (timeFromFilters.some((item) => item === prop)) {
      this.setTimeTo(prop, true);
    }
    if (timeToFilters.some((item) => item === prop)) {
      this.setTimeTo(prop, false);
    }
  }

  protected setTimeTo(prop, dayStart: boolean) {
    if (this.filter[prop]) {
      if (dayStart) {
        this.filter[prop] = DateTime.fromISO(this.filter[prop])
          .startOf('day')
          .toISO();
      } else {
        this.filter[prop] = DateTime.fromISO(this.filter[prop])
          .endOf('day')
          .toISO();
      }
    }
  }

  checkSelectedFilters() {
    let number = 0;
    if (this.filter) {
      const filterKeys = Object.keys(this.filter);
      filterKeys.forEach((key) => {
        if (
          this.filterHasValues(this.filter[key]) &&
          this.allowedFilters[key]
        ) {
          number++;
        }
      });
    }
    this.selectedFiltersCount = number;
  }

  private filterHasValues(item) {
    if (Array.isArray(item)) {
      return item.length;
    }
    return HelperService.isExist(item);
  }

  protected requestData(servicesIndex?): Observable<any> {
    const requestsList: any[] = this.createRequestToServices(servicesIndex);
    if (requestsList.length) {
      return forkJoin(requestsList);
    }
    return new Observable((observer) => {
      observer.next(null);
      observer.complete();
    });
  }

  protected createRequestToServices(_servicesIndex?: any) {
    return [];
  }

  unlockFilters() {
    this.hideApplyButton = false;
    this.hideUnlockButton = true;
    this.hideResetButton = false;
    this.disableFilters = false;
    this.onUnlockFilters.emit();
  }

  protected restoreFilters() {
    const $stateParams = this.activeRoute.snapshot.queryParams;
    if ($stateParams.zone || $stateParams.location || $stateParams.asset_type) {
      this.filterFromQuery();
    } else {
      switch (true) {
        case this.saveToCookies:
          this.filterFromCookies();
          break;
        case !!this.readyFilterSet:
          this.restoreDropdownDependencyAndApplyFilters(this.readyFilterSet);
          break;
        default:
          this.fillFiltersIfOptionIsNotPicked();
          this.restoreDropdownDependencyAndApplyFilters();
      }
    }
  }

  protected fillFiltersIfOptionIsNotPicked() {
    Object.keys(this.filterSetOnNothingSelected || {}).forEach(
      (key) => (this.filter[key] ??= this.filterSetOnNothingSelected[key])
    );
  }
}
