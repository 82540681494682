import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Configs } from '../constants/configs.constants';

@Injectable()
export class EventService {
  private listeners: any;
  private eventsSubject: any;

  constructor() {
    this.listeners = {};
    this.eventsSubject = new Subject();
    this.eventsSubject.asObservable().subscribe(({ name, args }) => {
      if (this.listeners[name]) {
        for (const listener of this.listeners[name]) {
          listener(...args);
        }
      }
    });
  }

  // old - multiple subscribes duplication in asset list page
  allOnEvent(name, listener) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }
    this.listeners[name].push(listener);
  }

  // new logics - to avoid listeners duplication
  // to be done refactor on() property - for now it works only with 1 listener for name, shold made logics more correct

  on(name, listener) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
      this.listeners[name].push(listener);
    } else {
      if (
        name !== Configs.EVENTS.FACILITIES_COLLAPSE &&
        name !== Configs.EVENTS.FACILIIES_UPDATE_ACTION &&
        name !== Configs.EVENTS.FACILITIES_EXPAND &&
        name !== Configs.EVENTS.ASSET_IMPORTED &&
        name !== 'projects.resetAll'
      ) {
        this.listeners[name] = this.listeners[name].filter((item) => {
          if (item.toString() !== listener.toString()) {
            return item;
          }
        });
      }
      this.listeners[name].push(listener);
    }
    return () => {
      const listenerIndex = this.listeners[name].findIndex(listener);
      this.listeners[name].splice(listenerIndex, 1);
    };
  }

  broadcast(name, ...args) {
    this.eventsSubject.next({
      name,
      args,
    });
  }
}
