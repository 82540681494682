import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  AliasModel,
  AssetTypeExtendedModel,
} from '../models/asset-type-extended.model';
import { DefaultListModel } from '../models/default-list.model';
import { DefaultIdNameModel } from '../models/default-id-name.model';
import { ClassificationModel } from '../models/classification.model';
import { environment } from '../../../environments/environment';
import { AssetTypeModel } from '../models/asset-type.model';
import { IAreaServedModel } from '../models/asset.model';
import { UniversalAssetTypesService } from './universal-asset-types.service';

@Injectable()
export class ClientAssetTypesService extends UniversalAssetTypesService {
  constructor(protected _requestService: RequestService) {
    super(_requestService);
    this.resourceName = 'v2/client/asset_types';
  }

  public getProjectAttributes(): Observable<any>{
    return this._requestService.get(`/v1/project_attributes/categories`);
  }

  public updateProjectAttributes(body): Observable<any>{
    const url = `/v1/project_attributes/categories`
    return this._requestService.post(url, body, this.headers, false);
  }
    
  public getItems(
    params?,
    orgId?: string
  ): Observable<DefaultListModel<AssetTypeExtendedModel>> {
    const headers = orgId
      ? { [environment.tokens.org_header]: orgId }
      : undefined;
    return this._requestService
      .get(`/${this.resourceName}`, params, headers, true)
      .pipe(
        map((data) => ({
          params: super.headersToParamsBag(data.headers),
          list: data.body.map((item) => new AssetTypeExtendedModel(item)),
        })),
        catchError((error) => throwError(() => error))
      );
  }

  public getById(
    id: string | number,
    orgId?: string
  ): Observable<AssetTypeExtendedModel> {
    const headers = orgId
      ? { [environment.tokens.org_header]: orgId }
      : undefined;
    return super.getById(id, this.resourceName, null, headers);
  }

  public getItemsWithShortDataScope(): Observable<DefaultIdNameModel[]> {
    return this._requestService.get(`/${this.resourceName}/in_use`);
  }

  public getItemsWithDefaultTemplates(): Observable<AssetTypeModel[]> {
    return this._requestService.get(
      `/${this.resourceName}/in_use/with_default_templates`
    );
  }

  public getItemsByClass(params?): Observable<Array<AssetTypeModel>> {
    return this._requestService.get(
      `/${this.resourceName}/with_assets_count`,
      params
    );
  }

  public putAssetTypeAliases(
    assetTypeId: number,
    aliases: AliasModel[]
  ): Observable<any> {
    const url = `/${this.resourceName}/${assetTypeId}/aliases`;
    return this._requestService.put(url, {
      assets_repository_aliases_attributes: aliases,
    });
  }

  public editMultipleCPCs(
    assetTypesArray: Array<AssetTypeExtendedModel>
  ): Observable<any> {
    return this._requestService.put(`/${this.resourceName}/edit_multiple`, {
      asset_types: assetTypesArray,
    });
  }

  public editAllCPCs(data: any): Observable<any> {
    return this._requestService.put(`/${this.resourceName}/mass_update`, data);
  }

  public getCustomProjectCosts(): Observable<any> {
    return this._requestService.get(`/${this.resourceName}/project_udfs/count`);
  }

  public getUsedClassifications(): Observable<Array<ClassificationModel>> {
    return this._requestService.get(`/${this.resourceName}/classes/in_use`);
  }

  public getUsedRepositoryGroups(): Observable<Array<DefaultIdNameModel>> {
    return this._requestService.get(`/${this.resourceName}/groups/in_use`);
  }

  public getUsedRepositorySubgroups(): Observable<Array<DefaultIdNameModel>> {
    return this._requestService.get(`/${this.resourceName}/subgroups/in_use`);
  }

  public getUsedRepositoryTrades(): Observable<DefaultIdNameModel[]> {
    return this._requestService.get(`/${this.resourceName}/trades/in_use`);
  }

  public getAreaServed(): Observable<Array<IAreaServedModel>> {
    return this._requestService.get(
      `/${this.resourceName}/assets_areas_served`
    );
  }
}
