<section class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header">{{ header }}</h2>
  </header>

  <div class="modal-content__body">
    <p
      class="modal-content__message mrgn-b_sm"
      *ngFor="let message of messagesArr"
    >
      {{ message }}
    </p>
    <ul
      class="auto-scrollable content-details_sm mrgn-b_sm"
      *ngIf="assetTypes.length"
    >
      <li *ngFor="let value of assetTypes">
        <a
          [routerLink]="getAssetTypeDetails(value.id)"
          class="text-bold link"
          target="_blank"
        >
          {{ value.name }}
        </a>
      </li>
    </ul>
    <p *ngIf="assetsNumber">
      This score is currently set for
      <a [href]="scopeLink(scopeId)" target="_blank" class="link text-bold"
        >{{ assetsNumber }} asset(s)</a
      >.
    </p>
  </div>

  <footer class="buttons-block modal-content__footer">
    <button
      type="button"
      (click)="cancel()"
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">{{ cancelText }}</span>
    </button>

    <button (click)="submit()" class="btn action-btn action-btn_danger">
      <span class="action-btn__icon"><app-svg [href]="'check'"></app-svg></span>
      <span class="action-btn__title">{{ submitText }}</span>
    </button>
  </footer>
</section>
