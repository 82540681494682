import { Component, Input } from '@angular/core';
import { BaseModalComponent } from '../../../../shared/modals/base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FileImportModel } from '../../../../core/models/file-import.model';
import { CustomNotificationService } from '../../../../core/services/custom-notification.service';
import { EventService } from '../../../../core/services/event.service';
import { TranslateService } from '@ngx-translate/core';
import { UploadResourceService } from '../../../../core/services/upload-resource.service';

@Component({
  selector: 'app-file-import-edit',
  templateUrl: './ftp-create-modal.component.html',
  styleUrls: [],
})
export class FtpCreateModalComponent extends BaseModalComponent {
  @Input() canCreateSystemFtp: boolean;
  @Input() modalType: string = 'create';
  systemFtp: boolean;
  header: string = 'Create new integration';
  model: FileImportModel;
  ftpCreds: any = ['host', 'port', 'user_name', 'password'];
  constructor(
    protected modalInstance: BsModalRef,
    private _translateService: TranslateService,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected _uploadResourceService: UploadResourceService
  ) {
    super(modalInstance, _notificationService, _eventService);
  }

  ngOnInit() {
    this.systemFtp = !!this.canCreateSystemFtp;
    this.isLoading = false;
  }

  setType() {
    this.modalInstance.hide();
    this.onResult.next(this.systemFtp);
  }

  saveAsTxt() {
    let credsTxt = '';
    this.ftpCreds.forEach((item) => {
      credsTxt += `${this._translateService.instant(item)}: ${this.model[item]}\n`;
    });
    this._uploadResourceService.exportedDataToDownload(
      credsTxt,
      'creds',
      'text/txt',
      'txt'
    );
  }
}
