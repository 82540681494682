@if (includeChildAssets) {
  <p class="content-info">
    {{ "pagination_caption_including_children" | translate: { total: total } }}
  </p>
} @else {
  <p class="content-info">
    {{
      "pagination_caption" | translate: { start: start, end: end, total: total }
    }}
  </p>
}
