<div *ngIf="!lineChartPeriod || isLoading" class="content-details text-center">
  <app-loader></app-loader>
</div>

<section *ngIf="lineChartPeriod && !isLoading" class="position-relative">
  <div class="graph-toolbar flex">
    <div class="xs-49 graph-toolbar__container">
      <h2 class="title_sm graph-toolbar__title">Indexes Changing</h2>
      <ul class="graph-toolbar__list">
        <li
          *ngIf="lineChartValues.fhi"
          class="graph-toolbar__item graph-toolbar__item_viking"
        >
          FHI
        </li>
        <li
          *ngIf="lineChartValues.fhim"
          class="graph-toolbar__item graph-toolbar__item_cooper"
        >
          FHIM
        </li>
        <li
          *ngIf="lineChartValues.fhir"
          class="graph-toolbar__item graph-toolbar__item_yellow-green"
        >
          FHIR
        </li>
        <li
          *ngIf="lineChartValues.fhie"
          class="graph-toolbar__item graph-toolbar__item_astral"
        >
          FHIE
        </li>
      </ul>
    </div>

    <div *ngIf="type != graphTypes.dashboard" class="btn-group">
      <button
        id="calendar_btn_id"
        type="button"
        class="btn btn-group__btn"
        [ngClass]="{ active: lineChartPeriod === 'calendar' }"
        (click)="updateLineChartPeriod('calendar')"
      >
        Calendar
      </button>

      <button
        id="last_year_btn_id"
        type="button"
        class="btn btn-group__btn"
        [ngClass]="{ active: lineChartPeriod === 'rolling' }"
        (click)="updateLineChartPeriod('rolling')"
      >
        Last Year
      </button>

      <button
        id="lifetime_btn_id"
        type="button"
        class="btn btn-group__btn"
        [ngClass]="{ active: lineChartPeriod === 'lifetime' }"
        (click)="updateLineChartPeriod('lifetime')"
      >
        Lifetime
      </button>
    </div>
  </div>

  <div class="graph-holder" id="graph_holder_div_id">
    <app-chart-line
      [chartValues]="lineChartValues"
      [chartPeriod]="lineChartPeriod"
      [type]="type"
      [installDate]="installDate"
      [organization]="organization"
    ></app-chart-line>
  </div>
</section>
