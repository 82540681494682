import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pagination-caption',
  templateUrl: './pagination-caption.component.html',
})
export class PaginationCaptionComponent {
  @Input() start: number;
  @Input() end: number;
  @Input() total: number;
  @Input() includeChildAssets: boolean = false;
}
