import { DefaultDTO } from './default.dto';

export class IntegrationApiDTO extends DefaultDTO {
  name: string;
  callback_url: string;
  emails: string;
  url: string;
  from_date?: string;
  to_date?: string;
  skip_ae_validation: boolean;

  constructor(data) {
    super();
    this.name = data.name;
    this.callback_url = data.webhook;
    this.emails = data.emails.join(', ');
    this.url = data.url;
    this.from_date = data.from_date;
    this.to_date = data.to_date;
    this.skip_ae_validation = data.skip_ae_validation;
  }
}
