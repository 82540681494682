import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../base-modal.component';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { UserStoreService } from 'src/app/core/services/user.store';
import { firstValueFrom } from 'rxjs';
import { secrets } from 'src/secrets';

@Component({
  selector: 'app-qlik-preview-modal',
  templateUrl: './qlik-preview-modal.component.html',
  styleUrls: ['./qlik-preview-modal.component.less'],
})
export class QlikPreviewModalComponent
  extends BaseModalComponent
  implements OnInit
{
  @Input() public sheetId: string;
  @Input() public submitText: string = 'Ok';
  @Input() public qlikAppId: string;

  constructor(
    public activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected _userService: UserStoreService
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  public ngOnInit(): void {
    window.getAccessToken = this.getAccessToken.bind(this);
    this.loadQlikScript();
  }

  async getAccessToken() {
    const response = await firstValueFrom(this._userService.getQlikTicket());
    return response;
  }

  loadQlikScript() {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/@qlik/embed-web-components';
    script.type = 'application/javascript';
    script.crossOrigin = 'anonymous';
    script.dataset.host = secrets.QLIK.host;
    script.dataset.clientId = secrets.QLIK.clientId;
    script.dataset.getAccessToken = 'getAccessToken';
    script.dataset.authType = 'Oauth2';
    script.dataset.accessTokenStorage = 'session';
    document.body.appendChild(script);
  }
}
