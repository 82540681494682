<div class="field-edit">
  <div
    *ngIf="!editing && !onlyEdit"
    class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap"
  >
    <p>
      <ng-container *ngIf="!(value || '').length; else elseBlock">
        -</ng-container
      ><ng-template #elseBlock>{{ value }}</ng-template>
    </p>
    <button
      class="btn btn_iconed btn_iconed_white"
      type="button"
      title="Edit"
      (click)="editing = true"
    >
      <app-svg [href]="'pencil'"></app-svg>
    </button>
  </div>
  <form
    #textEditForm="ngForm"
    (keyup.enter)="emitSaveValue()"
    (keyup.escape)="stopEditingIfEsc()"
    [hidden]="!editing && !onlyEdit"
  >
    <div
      class="field-edit__container"
      [ngClass]="{ 'form-block_error bg-transparent': hasError(_value) }"
    >
      <button
        *ngIf="!onlyEdit"
        class="btn field-edit__btn"
        title="Cancel"
        type="button"
        (click)="stopEditingIfEsc()"
      >
        <app-svg [href]="'times'" iconClass="icon_xxs"></app-svg>
      </button>
      <input
        [(ngModel)]="value"
        [name]="inputName"
        #_value="ngModel"
        type="text"
        [required]="notEmpty"
        placeholder="Enter value or formula"
        (blur)="emitSaveValueIfOnlyEdit()"
        class="field-edit__input text-left"
        [pattern]="regex[validationPattern]"
        [maxlength]="maxLength"
      />
      <button
        *ngIf="!onlyEdit"
        class="btn field-edit__btn btn_iconed_success"
        title="Save value"
        type="submit"
        (click)="emitSaveValue()"
      >
        <app-svg [href]="'check'"></app-svg>
      </button>
    </div>
    <p class="error" *ngIf="hasError(_value)">
      <ng-container *ngIf="_value.errors.maxlength">{{
        'validation.maxlength' | translate
      }}</ng-container>
      <ng-container *ngIf="_value.errors.pattern">{{
        'udf_formula_tooltip' | translate
      }}</ng-container>
      <ng-container *ngIf="_value.errors.required">{{
        'validation.required' | translate
      }}</ng-container>
      <ng-container *ngIf="_value.errors.api">{{
        _value.errors.api
      }}</ng-container>
    </p>
  </form>
</div>
