import { BaseStoreService } from './base-store.service';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { AEModel } from '../models/ae.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AEService<M> extends BaseStoreService<M> {
  protected resourceName = `processor-api/getAllMappings`;

  constructor(protected _requestService: RequestService) {
    super(_requestService, AEModel);
  }

  public editDate(value): Observable<M> {
    const url = `/processor-api/putMapping`;
    return this._requestService.put(url, value, this.headers);
  }
}
