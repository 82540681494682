import { AuthGuard } from './auth.guard';
import { PublicRouteGuard } from './public.guard';
import { TenantGuard } from './tenant.guard';
import { TenantOrganizationGuard } from './tenant-organization.guard';
import { ProfileGuard } from './profile.guard';
import { ClientOrganizationGuard } from './client-organization.guard';
import { CustomGuard } from './custom.guard';
import { AnalyticsGuard } from './analytics.guard';
import { FeatureGuard } from './feature.guard';
import { WorkOrderGuard } from './work-order.guard';

export const guards = [
  AuthGuard,
  AnalyticsGuard,
  FeatureGuard,
  PublicRouteGuard,
  TenantGuard,
  CustomGuard,
  TenantOrganizationGuard,
  ProfileGuard,
  ClientOrganizationGuard,
  WorkOrderGuard,
];
