import { DefaultFilterModel } from '../models/default-filter.model';
import { FilterType } from '../constants/enums';
import { AssetModel } from '../models/asset.model';
import { LocationService } from './location.service';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { PresetModel } from '../models/preset.model';
import { Configs } from '../constants/configs.constants';
import { LocationModel } from '../models/location.model';
import { RequestService } from './request.service';
import crypto from 'crypto-js';
import { FILTER_STATE_TOKEN } from '../../store/states/filters.state';
import { Filters } from '../../store/actions/filters.action';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { CustomNotificationService } from './custom-notification.service';
import { environment } from '../../../environments/environment';
import { HelperService } from './helper.service';

@Injectable()
export class FilterService {
  public allowedFilters = {};
  public dependencyList: any = {
    location: 'building',
    building: 'floor',
    floor: 'room',
    room: '',
  };
  public indicesMode: string = Configs.indicesModes[0].key;

  private opened = {};
  private PresetDependencies = [
    { preset: 'classification_id', filter: 'classification_ids' },
    { preset: 'location_id', filter: 'location' },
    { preset: 'building_id', filter: 'building' },
    { preset: 'zone_id', filter: 'zone' },
    { preset: 'floor_id', filter: 'floor' },
    { preset: 'room_id', filter: 'room' },
    { preset: 'assets_repository_id', filter: 'types' },
    { preset: 'trade_id', filter: 'trades' },
    { preset: 'fhie_score_from', filter: 'fhieFrom' },
    { preset: 'fhie_score_to', filter: 'fhieTo' },
    { preset: 'total_risk_rank_from', filter: 'totalRiskFrom' },
    { preset: 'total_risk_rank_to', filter: 'totalRiskTo' },
    { preset: 'lifetime_end_regular_from', filter: 'lifetimeFrom' },
    { preset: 'lifetime_end_regular_to', filter: 'lifetimeTo' },
    { preset: 'score_from', filter: 'scoreFrom' },
    { preset: 'score_to', filter: 'scoreTo' },
    { preset: 'fhi_INDEX_r_index_from', filter: 'fhiFrom' },
    { preset: 'fhi_INDEX_r_index_to', filter: 'fhiTo' },
    { preset: 'installed_on_from', filter: 'installedAtFrom' },
    { preset: 'installed_on_to', filter: 'installedAtTo' },
    { preset: 'engineer', filter: 'engineer' },
    { preset: 'square_footage_from', filter: 'sqFootageFrom', float: true },
    { preset: 'square_footage_to', filter: 'sqFootageTo', float: true },
    { preset: 'tag_ids', filter: 'tag_ids' },
    {
      preset: 'total_fhi_INDEX_r_index_from',
      filter: 'totalFhiRIndexFrom',
      float: true,
    },
    {
      preset: 'total_fhi_INDEX_r_index_to',
      filter: 'totalFhiRIndexTo',
      float: true,
    },
    { preset: 'user', filter: 'user' },
    {
      preset: 'total_project_cost_from',
      filter: 'total_project_cost_from',
      float: true,
    },
    {
      preset: 'total_project_cost_to',
      filter: 'total_project_cost_to',
      float: true,
    },
    { preset: 'fte_from', filter: 'fteFrom', float: true },
    { preset: 'fte_to', filter: 'fteTo', float: true },
    { preset: 'total_fte_from', filter: 'totalFteFrom', float: true },
    { preset: 'total_fte_to', filter: 'totalFteTo', float: true },
    { preset: 'date_to', filter: 'dateTo' },
    { preset: 'date_from', filter: 'dateFrom' },
    { preset: 'area_served_id', filter: 'areaServed' },
    { preset: 'group_id', filter: 'groups' },
    { preset: 'subgroup_id', filter: 'subgroups' },
    { preset: 'presetId', filter: 'presetId' },
    { preset: 'funding_source_id', filter: 'funding_source_id' },
    { preset: 'client_only_asset_type', filter: 'client_only_asset_type' },
    {
      preset: 'uar_customized.funding_source_id',
      filter: 'uar_customized.funding_source_id',
    },
    { preset: 'assignee', filter: 'assignee' },
    { preset: 'end_date_from', filter: 'dueDateFrom' },
    { preset: 'end_date_to', filter: 'dueDateTo' },
    { preset: 'made_incomplete_by', filter: 'made_incomplete_by' },
    { preset: 'cost_center', filter: 'cost_center' },
    { preset: 'created_at_from', filter: 'dateRangeFrom' },
    { preset: 'created_at_to', filter: 'dateRangeTo' },
    { preset: 'uar_asset_type_id', filter: 'uar_asset_type_id' },
    { preset: 'frequency', filter: 'frequency' },
    { preset: 'trade_id', filter: 'trade_id' },
    { preset: 'user_id', filter: 'user_id' },
  ];

  private NewDTODependencies = [
    { dto: 'classification_id', filter: 'classification_ids' },
    { dto: 'location_id', filter: 'location' },
    { dto: 'building_id', filter: 'building' },
    { dto: 'zone_id', filter: 'zone' },
    { dto: 'floor_id', filter: 'floor' },
    { dto: 'room_id', filter: 'room' },
    { dto: 'assets_repository_id', filter: 'types' },
    { dto: 'trade_id', filter: 'trades' },
    { dto: 'fhie_score_from', filter: 'fhieFrom' },
    { dto: 'fhie_score_to', filter: 'fhieTo' },
    { dto: 'total_risk_rank_from', filter: 'totalRiskFrom' },
    { dto: 'total_risk_rank_to', filter: 'totalRiskTo' },
    { dto: 'lifetime_end_regular_from', filter: 'lifetimeFrom' },
    { dto: 'lifetime_end_regular_to', filter: 'lifetimeTo' },
    { dto: 'score_from', filter: 'scoreFrom' },
    { dto: 'score_to', filter: 'scoreTo' },
    { dto: 'fhi_INDEX_r_index_from', filter: 'fhiFrom' },
    { dto: 'fhi_INDEX_r_index_to', filter: 'fhiTo' },
    { dto: 'installed_on_from', filter: 'installedAtFrom' },
    { dto: 'installed_on_to', filter: 'installedAtTo' },
    { dto: 'engineer', filter: 'engineer' },
    { dto: 'square_footage_from', filter: 'sqFootageFrom' },
    { dto: 'square_footage_to', filter: 'sqFootageTo' },
    { dto: 'date_to', filter: 'dateTo' },
    { dto: 'date_from', filter: 'dateFrom' },
    { dto: 'user', filter: 'userId' },
    { dto: 'total_fhi_INDEX_r_index_from', filter: 'totalFhiRIndexFrom' },
    { dto: 'total_fhi_INDEX_r_index_to', filter: 'totalFhiRIndexTo' },
    { dto: 'area_served_id', filter: 'areaServed' },
    { dto: 'group_id', filter: 'groups' },
    // {dto: 'group_id', filter: 'orgGroups'},
    { dto: 'subgroup_id', filter: 'subgroups' },
    // {dto: 'subgroup_id', filter: 'orgSubgroups'},
    { dto: 'tier', filter: 'tiers' },
    { dto: 'specialty_id', filter: 'specialties' },
    { dto: 'funding_source_id', filter: 'funding_source_id' },
    { dto: 'client_only_asset_type', filter: 'client_only_asset_type' },
    {
      dto: 'uar_customized.funding_source_id',
      filter: 'uar_customized.funding_source_id',
    },
    { dto: 'made_incomplete_by', filter: 'made_incomplete_by' },
    { dto: 'cost_center', filter: 'cost_center' },
    { dto: 'created_at_from', filter: 'dateRangeFrom' },
    { dto: 'created_at_to', filter: 'dateRangeTo' },
    { dto: 'uar_asset_type_id', filter: 'uar_asset_type_id' },
    { dto: 'frequency', filter: 'frequency' },
    { dto: 'trade_id', filter: 'trade_id' },
    { dto: 'user_id', filter: 'user_id' },
    { dto: 'updated_by', filter: 'updated_by' },
  ];

  private allDTODependencies = {
    rangeFrom: 'created_at_from',
    rangeTo: 'created_at_to',
    dateFrom: 'date_from',
    dateTo: 'date_to',
    totalCostFrom: 'optimal_total_from',
    totalCostTo: 'optimal_total_to',
    costProjectFrom: 'total_project_cost_from',
    costProjectTo: 'total_project_cost_to',
    importFrom: 'import_date_from',
    importTo: 'import_date_to',
    source: 'source',
    importStatus: 'status',
    objectType: 'object_type',
    projectRisk: 'project_risk',
    projectType: 'project_type',
    fiscalYear: 'fiscal_years',
  };

  constructor(
    private _locationService: LocationService,
    private _requestService: RequestService,
    private _notificationService: CustomNotificationService,
    protected store: Store
  ) {}

  /**
   * converts field names from preset to filter (after receiving from backend)
   * @param filter
   * @param replaceWith
   */
  convertPresetToFilters(filter: PresetModel, replaceWith?: string) {
    const filters = {};
    this.PresetDependencies.forEach((dependency) => {
      const field = this.updateDependencyWithIndex(
        dependency.preset,
        replaceWith
      );
      filters[dependency.filter] = filter[field];
    });
    return this.checkFilters(filters);
  }

  /**
   * converts field names from preset (received in backend repsonse) to filter
   * @param filters
   * @param replaceWith
   */
  convertFiltersToPreset(filters: DefaultFilterModel, replaceWith?: string) {
    const filter = {};
    this.PresetDependencies.forEach((dependency) => {
      if (dependency.float) {
        filter[this.updateDependencyWithIndex(dependency.preset, replaceWith)] =
          HelperService.isExist(filters[dependency.filter])
            ? parseFloat(filters[dependency.filter])
            : null;
      } else {
        filter[this.updateDependencyWithIndex(dependency.preset, replaceWith)] =
          filters[dependency.filter];
      }
    });
    return this.checkFilters(filter);
  }

  public prepareFilterDTO(
    filter: DefaultFilterModel,
    filterType?: string,
    replaceWith?: string
  ) {
    switch (true) {
      case filterType === 'assessments':
        return this.prepareAssessmentsFilters(filter);
      case filterType === 'events':
        return this.prepareEventsFilters(filter);
      case filterType === 'work_orders':
        return this.prepareWorkOrdersFilters(filter);
      case filterType === 'complianceReports':
        return this.prepareReportsFilters(filter);
      case filterType === 'workOrder':
        return this.prepareWorkOrderFilters(filter);
      case filterType === 'buildings':
        return this.prepareBuildingsFilters(filter, replaceWith);
      case filterType === 'forecast':
      case filterType === 'forecasting':
        return this.prepareForecastingFilters(filter, replaceWith);
      case filterType === 'attachments':
        return this.prepareBuildingAttachmentsFilters(filter);
      case filterType === 'assets':
        return this.prepareAssetFilters(filter);
      case filterType === 'da':
      case filterType === 'ds':
        return this.prepareAssetFilters(filter, replaceWith);
      case filterType === 'forecast_list':
      case filterType === 'session':
      case filterType === 'facilities':
      case filterType === 'projectList':
      case filterType === 'projects':
        return this.prepareDefaultFilters(filter, replaceWith);
      case filterType === 'universalAssetTypeList':
        return this.prepareUniversalAssetTypeFilters(filter, replaceWith);
      default:
        return this.prepareAssetFilters(filter, replaceWith);
    }
  }

  public isOpened(type: FilterType | string): boolean {
    return this.opened[type];
  }

  public setOpened(type: FilterType | string = 'default', value: boolean) {
    this.opened[type] = value;
  }

  private updateDependencyWithIndex(
    dependencyDTO: string,
    replaceWith: string = ''
  ): string {
    return dependencyDTO.replace('INDEX', replaceWith);
  }

  private prepareDefaultFilters(
    filters: DefaultFilterModel,
    replaceWith?: string
  ) {
    const filterDTO: any = {};
    for (const filterField in filters) {
      if (this.allowedFilters[filterField]) {
        filters[filterField] = filters[filterField]?.isDateTime
          ? filters[filterField].toISO()
          : filters[filterField];
        filterDTO[
          this.updateDependencyWithIndex(
            this.allDTODependencies[filterField] || filterField,
            replaceWith
          )
        ] = filters[filterField];
      }
    }
    return this.checkFilters(filterDTO);
  }

  private prepareUniversalAssetTypeFilters(
    filter: DefaultFilterModel,
    replaceWith?: string
  ) {
    const filterDTO = this.prepareAssetFilters(filter, replaceWith);

    ['group_id', 'subgroup_id', 'specialty_id', 'trade_id'].forEach((field) => {
      if (filterDTO.hasOwnProperty(field)) {
        filterDTO[`uar_${field}`] = filterDTO[field];
        delete filterDTO[field];
      }
    });

    return filterDTO;
  }

  private prepareAssetFilters(
    filter: DefaultFilterModel,
    replaceWith?: string
  ): any {
    const filterDTO = {
      lifetime_end_regular_from: filter.lifetimeFrom,
      lifetime_end_regular_to: filter.lifetimeTo,
      installed_on_from: filter.installedAtFrom,
      installed_on_to: filter.installedAtTo,
    };
    this.NewDTODependencies.forEach((dependency) => {
      if (
        filterDTO[
          `${this.updateDependencyWithIndex(dependency.dto, replaceWith)}`
        ]
      ) {
        return;
      }
      filterDTO[
        `${this.updateDependencyWithIndex(dependency.dto, replaceWith)}`
      ] = filter[dependency.filter];
    });
    if (filter.lifetime_type) {
      filterDTO[`lifetime_end_${filter.lifetime_type}_from`] =
        filter.lifetimeFrom;
      filterDTO[`lifetime_end_${filter.lifetime_type}_to`] = filter.lifetimeTo;
    }
    return this.checkFilters(filterDTO);
  }

  private prepareForecastingFilters(
    filter: DefaultFilterModel,
    replaceWith?: string
  ): any {
    const filterDTO: any = {
      lifetime_end_regular_from: filter.lifetimeFrom,
      lifetime_end_regular_to: filter.lifetimeTo,
      ['filter_by[installed_on_from]']: filter.installedAtFrom,
      ['filter_by[installed_on_to]']: filter.installedAtTo,
    };
    this.PresetDependencies.forEach((dependency) => {
      filterDTO[
        `${this.updateDependencyWithIndex(dependency.preset, replaceWith)}`
      ] = filter[dependency.filter];
    });
    if (filter.lifetime_type) {
      filterDTO[`lifetime_end_${filter.lifetime_type}_from`] =
        filter.lifetimeFrom;
      filterDTO[`lifetime_end_${filter.lifetime_type}_to`] = filter.lifetimeTo;
    }
    return this.checkFilters(filterDTO);
  }

  private prepareAssessmentsFilters(filter: DefaultFilterModel): any {
    const filterDTO: any = {
      score_from: filter.scoreFrom,
      score_to: filter.scoreTo,
      user: filter.user,
      asset_type: filter.types,
      date_from: filter.lifetimeFrom,
      date_to: filter.lifetimeTo,
    };
    return this.checkFilters(filterDTO);
  }

  private prepareBuildingAttachmentsFilters(filter: DefaultFilterModel): any {
    const filterDTO: any = {
      user: filter.user,
      date_from: filter.lifetimeFrom,
      date_to: filter.lifetimeTo,
    };
    return this.checkFilters(filterDTO);
  }

  private prepareEventsFilters(filter: DefaultFilterModel): any {
    const filterDTO: any = {
      assignee: filter.assignee,
      location_id: filter.location,
      building_id: filter.building,
      end_date_from: filter.dueDateFrom,
      end_date_to: filter.dueDateTo,
    };

    return this.checkFilters(filterDTO);
  }

  private prepareWorkOrdersFilters(filter: DefaultFilterModel): any {
    const filterDTO: any = {};
    filterDTO['filter_by[user_id]'] = filter.assignee;
    return this.checkFilters(filterDTO);
  }

  private prepareReportsFilters(filter: DefaultFilterModel): any {
    const filterDTO: any = {
      ['assets.classification_id']: filter.classification_ids,
      ['assets.location_id']: filter.location,
      ['assets.building_id']: filter.building,
      ['assets.assets_repository_id']: filter.types,
      user_id: filter.assignee,
      end_date_from: filter.dueDateFrom,
      end_date_to: filter.dueDateTo,
    };

    return this.checkFilters(filterDTO);
  }

  private prepareWorkOrderFilters(filter: DefaultFilterModel): any {
    const filterDTO: any = {
      ['assets.classification_id']: filter.classification_ids,
      ['assets.location_id']: filter.location,
      ['assets.building_id']: filter.building,
      ['assets.assets_repository_id']: filter.types,
      user_id: filter.assignee,
      wo_end_date_from: filter.dueDateFrom,
      wo_end_date_to: filter.dueDateTo,
    };

    return this.checkFilters(filterDTO);
  }

  private prepareBuildingsFilters(
    filter: DefaultFilterModel,
    replaceWith: string
  ): any {
    const filterDTO: any = {
      total_fte_from: filter.totalFteFrom,
      total_fte_to: filter.totalFteTo,
      fte_from: filter.fteFrom,
      fte_to: filter.fteTo,
      tag_ids: filter.tag_ids,
    };
    this.NewDTODependencies.forEach((dependency) => {
      filterDTO[this.updateDependencyWithIndex(dependency.dto, replaceWith)] =
        filter[dependency.filter];
    });

    return this.checkFilters(filterDTO);
  }

  checkFilters(filter: any): any {
    for (const prop in filter) {
      if (filter.hasOwnProperty(prop)) {
        if (!HelperService.isExist(filter[prop])) {
          delete filter[prop];
        }
        if (Array.isArray(filter[prop]) && !filter[prop].length) {
          delete filter[prop];
        }
      }
    }
    return filter;
  }

  public filterByIds(items: Array<any>, ids: Array<number>): Array<AssetModel> {
    if (!ids || !items) {
      return null;
    }
    const search = items.filter((item) =>
      ids.some((id) => parseInt(item.id) === parseInt(id.toString()))
    );
    if (search.length) {
      return search;
    }
    return null;
  }

  public resetDependencyList(
    key,
    filters: DefaultFilterModel,
    filterDropdowns: { [key: string]: any },
    resetFilterField?: boolean
  ) {
    const dName = this.dependencyList[key];
    if (dName) {
      if (resetFilterField) {
        delete filters[dName];
      }
      filterDropdowns[dName] = [];
      this.resetDependencyList(
        dName,
        filters,
        filterDropdowns,
        resetFilterField
      );
    }
  }

  public restoreDropdownDependency(
    filterDropdowns,
    filters: DefaultFilterModel,
    params?
  ): Observable<any> {
    const filterDependency = {
      classification_ids: 'classifications',
      trades: 'trades',
      types: 'types',
      zone: 'zone',
    };
    // to check if the filters contain any deleted items and remove their ids from filter
    const filterKeys = Object.keys(filterDependency);
    filterKeys.forEach((key) => {
      if (filters[key]) {
        const allEnableValues = filterDropdowns[filterDependency[key]];
        const source = this.filterByIds(allEnableValues, filters[key]) || [];
        filters[key] = source.map((item) => item.id);
      }
    });
    if (!filters['location']) {
      return of({ dropdowns: filterDropdowns, filters });
    } else {
      return new Observable<any>((observe) => {
        this.setInnerArray(
          observe,
          filters,
          filterDropdowns,
          'location',
          params
        );
      });
    }
  }

  public setInnerArray(
    observe,
    filters: DefaultFilterModel,
    filterDropdowns: { [key: string]: any },
    level = 'location',
    params?
  ) {
    const subLevel = this.dependencyList[level];

    if (filters[level]) {
      filters[level] = filterDropdowns[level].reduce(
        (filtered: number[], el: LocationModel) => {
          if (filters[level].includes(el.id)) {
            filtered.push(el.id);
          }
          return filtered;
        },
        []
      );
    }

    if (!subLevel || !filters[level]) {
      if (observe) {
        observe.next({ dropdowns: filterDropdowns, filters });
        observe.complete();
      }
      return;
    }

    this._locationService
      .getSubLevelByParentLocation(subLevel, level, filters[level], params)
      .subscribe({
        next: (res) => {
          this.resetDependencyList(subLevel, filters, filterDropdowns);
          filterDropdowns[subLevel] = res.list;
          this.setInnerArray(
            observe,
            filters,
            filterDropdowns,
            subLevel,
            params
          );
        },
        error: (err) => this._notificationService.apiError(err),
      });
  }

  /**
   * @filters - filterDTO model
   **/
  createFiltersBatchId(filters: any) {
    return crypto.SHA256(JSON.stringify(filters)).toString();
  }

  updateFiltersBatchId(
    filters: any,
    key: string,
    nullOrg?: boolean
  ): Observable<string> {
    let headers = {};
    if (nullOrg) {
      headers = { [environment.tokens.org_header]: '' };
    }
    return this._requestService.post(
      '/v1/stored_filter_params',
      { stored_filter_params_key: key, stored_filter_params: filters },
      headers
    );
  }

  updateFiltersBatchIdInStore(
    filterDTO,
    filterType,
    forceUpdate = false,
    nullOrg?: boolean
  ): Observable<any> {
    const storeParam =
      this.store.selectSnapshot(FILTER_STATE_TOKEN).filterList[filterType];
    const newFiltersBatchId = this.createFiltersBatchId(filterDTO);
    const now: number = DateTime.now().toUnixInteger();

    if (
      !storeParam ||
      storeParam.batchId !== newFiltersBatchId ||
      now - storeParam.timestamp >= 82800 ||
      forceUpdate
    ) {
      this.store.dispatch(
        new Filters.SaveBatchId(filterType, newFiltersBatchId, now)
      );
      return this.updateFiltersBatchId(
        filterDTO,
        newFiltersBatchId,
        nullOrg
      ).pipe(map(() => ({ stored_filter_params_key: newFiltersBatchId })));
    }

    return of(newFiltersBatchId);
  }

  public prepareFilterBatchId(filterType, params: any = {}) {
    const storeParam =
      this.store.selectSnapshot(FILTER_STATE_TOKEN).filterList[filterType];
    if (storeParam) {
      params.stored_filter_params_key = storeParam.batchId;
    }
    return params;
  }
}
