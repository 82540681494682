import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { HelperService } from '../../core/services/helper.service';

@Directive({
  selector:
    '[customQuantityMax][formControlName],[customQuantityMax][formControl],[customQuantityMax][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CustomQuantityMaxDirective,
      multi: true,
    },
  ],
})
export class CustomQuantityMaxDirective implements Validator {
  @Input()
  customQuantityMax: number;

  validate(c: FormControl): { [key: string]: any } {
    let v = c.value;
    if (
      this.customQuantityMax > 1e9 ||
      !HelperService.isExist(this.customQuantityMax)
    ) {
      this.customQuantityMax = 1e9;
    }
    if (v !== null) {
      v = parseFloat(v);
      return v > this.customQuantityMax ? { customQuantityMax: true } : null;
    } else {
      return null;
    }
  }
}
