import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFormComponent } from '../base-form.component';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-date-form-edit',
  templateUrl: './date-form-edit.component.html',
  styleUrls: ['./date-form-edit.component.less'],
})
export class DateFormEditComponent extends BaseFormComponent {
  constructor(
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(_notificationService, _eventService);
  }

  @Input() showUndo: boolean = true;
  @Input() value: any;
  @Output() onUpdateValue = new EventEmitter();
  data: {
    value: string;
    oldValue: string;
  };
  valueChanged = false;
  minDate: string = DateTime.now().toISO();
  editing = false;

  ngOnInit(): void {
    this.data = {
      value: this.value,
      oldValue: this.minDate,
    };
  }

  emitChangedDate() {
    this.valueChanged = this.data.value !== this.data.oldValue;
    if (this.valueChanged && this.data.value >= this.minDate) {
      this.onUpdateValue.emit(this.data.value);
      this.editing = !this.editing;
    }
  }

  setDefaultDate() {
    this.data.value = this.data.oldValue;
    this.emitChangedDate();
  }
}
