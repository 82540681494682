import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
} from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'platform',
})
export class PlatformService {
  private userProfile: BehaviorSubject<{ [key: string]: unknown }> =
    new BehaviorSubject({
      isLoggedIn: false,
      orgId: '',
    });

  get userProfile$(): Observable<{ [key: string]: unknown }> {
    return this.userProfile.asObservable();
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.userProfile$.pipe(
      map((profile) => profile.isLoggedIn as boolean),
      distinctUntilChanged()
    );
  }

  isOrgLoading$ = new BehaviorSubject<any>(true);

  get accessChanged$(): Observable<unknown> {
    return this.isLoggedIn$.pipe(
      distinctUntilChanged(), // Ensure changes only trigger on state transition
      filter((isLoggedIn) => isLoggedIn), // Only proceed if the user is logged in
      switchMap(() =>
        this.userProfile$.pipe(
          distinctUntilKeyChanged('orgId') // React only to actual orgId changes
        )
      )
    );
  }

  get isPmScheduleEnabled$(): Observable<boolean> {
    return this.accessChanged$.pipe(
      switchMap(() =>
        this.isOrgLoading$.pipe(
          filter((val) => !val),
          switchMap(({ isLoading }) =>
            this.userProfile$.pipe(
              map(
                (user) =>
                  !isLoading && !!(user?.organization as any)?.pm_schedule
              )
            )
          ),
          distinctUntilChanged()
        )
      )
    );
  }

  updateUserProfile(user): void {
    this.userProfile.next({
      ...this.userProfile.value,
      ...user,
    });
  }
  clearUserProfile(): void {
    this.userProfile.next({
      isLoggedIn: false,
      orgId: '',
    });
  }
}
