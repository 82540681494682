import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { CustomNotificationService } from './custom-notification.service';
import { BaseStoreService } from './base-store.service';
import { IntegrationApiModel } from '../models/integration-api.model';
import { Observable } from 'rxjs';

@Injectable()
export class IntegrationApiService extends BaseStoreService<IntegrationApiModel> {
  protected resourceName = `v1/settings/integrations/api`;

  constructor(
    protected _requestService: RequestService,
    protected _notificationService: CustomNotificationService
  ) {
    super(_requestService, IntegrationApiModel);
  }

  public renewKey(model: IntegrationApiModel): Observable<any> {
    const url = `/${this.resourceName}/${model.id}/renew_key`;
    return this._requestService.put(url);
  }
}
