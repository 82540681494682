import { Component, Input, OnChanges } from '@angular/core';
import { BudgetingConstants } from '../../../core/constants/budgeting.constants';
import { EventService } from '../../../core/services/event.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { BudgetingLocationModel } from '../../../core/models/building-distribution.model';

@Component({
  selector: 'app-forecasting-location',
  templateUrl: './forecasting-location.component.html',
})
export class ForecastingLocationComponent implements OnChanges {
  @Input() public location: BudgetingLocationModel;
  public collapsed: boolean = true;
  public sortBy: string;
  public sortReverse = false;

  public get constants() {
    return BudgetingConstants;
  }

  constructor(
    protected _eventService: EventService,
    protected _navigationService: NavigationService
  ) {}

  ngOnChanges() {
    this.location.collapsed = this.collapsed;
  }
}
