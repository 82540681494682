<section class="loader">
  <div class="loader__wrapper">
    <svg
      class="loader__icon"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 2"
      viewBox="0 0 315.72 358.95"
    >
      <defs>
        <linearGradient id="logo-gradient" x1="50%" y1="0%" x2="50%" y2="100%">
          <stop offset="0%" stop-color="#19BEBC">
            <animate
              attributeName="stop-color"
              values="#19BEBC; #45FB9D; #19BEBC"
              dur="2s"
              repeatCount="indefinite"
            ></animate>
          </stop>

          <stop offset="100%" stop-color="#45FB9D">
            <animate
              attributeName="stop-color"
              values="#45FB9D; #19BEBC; #45FB9D"
              dur="2s"
              repeatCount="indefinite"
            ></animate>
          </stop>
        </linearGradient>
      </defs>
      <path
        stroke="url('#logo-gradient')"
        class="loader__icon-path"
        d="m91.51 104.28 2.02 136.48c0 55.16 44.66 95.68 99.82 95.68s99.87-38.66 99.87-93.82c0-137.57-257.7-153.56-270.41-84.11-10.13 55.32 232.27 68.95 232.27-54.24 0-45.17-36.61-81.78-81.78-81.78s-81.79 36.62-81.79 81.79"
        data-name="Layer 1"
      />
    </svg>
    <div
      *ngIf="bgJobModel"
      class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap"
    >
      <div class="position-relative">
        <progressbar
          [max]="100"
          [value]="bgJobModel?.progress"
          type="info"
          class="loader__progressbar"
        >
        </progressbar>
        <p class="loader__status text-center">{{ bgJobModel?.message }}</p>
      </div>

      <button
        type="button"
        class="btn sticky-button"
        *ngIf="bgJobModel?.step"
        [tooltip]="bgJobModel?.step"
      >
        <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
      </button>

      <button class="btn btn_iconed btn_iconed-small" (click)="cancelBgJob()">
        <app-svg [href]="'times'"></app-svg>
      </button>
    </div>
  </div>
</section>
