import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { map } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { UploadResourceService } from './upload-resource.service';

@Injectable()
export class UserService {
  constructor(
    private _requestService: RequestService,
    private _uploadService: UploadResourceService
  ) {}

  public uploadImage(model: UserModel, file): Observable<any> {
    const organizationKey = model.is_tenant ? null : model.organization.id_hash;
    return this._uploadService
      .uploadFile(file, `images/users/${model.id}`, organizationKey)
      .pipe(
        map((res) => {
          model.picture = res.fields.key;
          return res;
        })
      );
  }

  public validatePassword(password): Observable<any> {
    return this._requestService.post(`/v2/users/validate_password`, {
      password,
    });
  }

  public removeImage(model): void {
    model.picture = null;
  }

  getUsers(type): Observable<any> {
    let assetType = '';
    switch (type) {
      case 'incomplete':
      case 'general':
        assetType = type;
        break;
      case 'archive':
        assetType = 'archived';
        break;
      case 'has_errors':
        assetType = 'has_errors';
        break;
      case 'made_incomplete_by':
        assetType = type;
        break;
      default:
        assetType = 'all';
    }

    let url;
    if (type && !type.indexOf('project')) {
      url = `/v1/projects/${type.split('_')[1]}/assets/users`;
    } else {
      url = `/v1/assets/${assetType}/users`;
    }
    return this._requestService.get(url);
  }
}
