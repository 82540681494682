import { Pipe, PipeTransform } from '@angular/core';

import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';

@Pipe({
  name: 'feature',
})
export class FeaturePipe implements PipeTransform {
  constructor(private readonly featureService: FeatureFlagsService) {}
  public transform(name: string): boolean {
    return this.featureService.isFeatureEnabled(name);
  }
}
