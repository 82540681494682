<div
  dropdown
  #dropdown
  (isOpenChange)="toggled($event)"
  (onHidden)="emitHidden()"
  [isOpen]="isOpen"
  [insideClick]="insideClick"
  class="multiselect el-in-a-row el-in-a-row_c el-in-a-row_nowrap"
  [isDisabled]="isDisabled"
>
  <button
    type="button"
    class="btn btn_iconed btn_iconed-small"
    tooltip="Reset this filter"
    [disabled]="isDisabled"
    *ngIf="resetField && model?.length"
    (click)="resetFilter()"
  >
    <app-svg [href]="'times'" [iconClass]="'icon icon_xxs'"></app-svg>
  </button>
  <span *ngIf="resetField && model?.length" class="multiselect__field-title"
    >{{ fieldName }}:</span
  >
  <button
    id="button-{{ id }}"
    dropdownToggle
    [attr.aria-controls]="'dropdown-' + id"
    type="button"
    class="btn select multiselect__btn"
    [ngClass]="{
      'form-block_error': error,
      'multiselect__btn_has-value': model?.length && !singleValueSelected
    }"
  >
    {{ selectedValues || placeholder }}
    <span
      *ngIf="model?.length && !singleValueSelected"
      class="multiselect__count"
    >
      ({{ model.length }})
    </span>
  </button>

  <div
    id="dropdown-{{ id }}_dropdown"
    [ngClass]="{
      'dropdown-menu-right': rightSided,
      'multiselect__dropdown_menu-top': topDirection
    }"
    class="multiselect__dropdown"
    *dropdownMenu
    role="menu"
    [attr.aria-labelledby]="'button-' + id"
  >
    <header>
      <div
        role="menuitem"
        class="multiselect__filter"
        *ngIf="
          showSearch
        "
      >
        <input
          [(ngModel)]="keyword"
          (ngModelChange)="search()"
          class="form-block__element"
          name="search"
          placeholder="Keyword"
          type="text"
        />
      </div>
      <div
        *ngIf="showSelectAll"
        class="multiselect__header-item flex flex_nowrap"
      >
        <p class="text-bold">{{ 'labels.select_all' | translate }}</p>
        <label class="custom-checkbox">
          <input
            class="custom-checkbox__input"
            type="checkbox"
            [(ngModel)]="selectAll"
            name="select_all"
            (click)="selectAllOptions($event)"
          />
          <span class="custom-checkbox__check"></span>
        </label>
      </div>
    </header>
    <p *ngIf="!searchData?.length" class="multiselect__text">No items</p>
    <ul *ngIf="searchData?.length" class="multiselect__options-list">
      <li
        role="menuitemcheckbox"
        class="multiselect__option"
        [ngClass]="{ multiselect__option_active: isSelected(option[key]) }"
        *ngFor="let option of searchData"
      >
        <button
          type="button"
          class="btn multiselect__label"
          [disabled]="option[disableOptIfItHasField]"
          [tooltip]="showTooltip(option) ? getTooltip(option) : ''"
          (click)="selectOption(option[key], option)"
        >
          <span [ngClass]="{ 'text-bold': option[this.boldOption] }">{{
            getPseudo(option)
          }}</span>
          <span *ngIf="option[this.displayDataInOptionsParenthesis]">
            ({{ option[this.displayDataInOptionsParenthesis] }})
          </span>
        </button>
        <ng-container *appPermission="'ta_tu_te_ca_pa'">
          <button
            type="button"
            class="btn multiselect-item__btn"
            *ngIf="dropdownType === 'presetsDropdown'"
            (click)="removePreset(option)"
          >
            <app-svg [href]="'trash'"></app-svg>
          </button>
        </ng-container>
        <ng-container *appPermission="'cu_ce_pe'">
          <button
            type="button"
            class="btn multiselect-item__btn"
            *ngIf="
              dropdownType === 'presetsDropdown' && option.user_id === userId
            "
            (click)="removePreset(option)"
          >
            <app-svg [href]="'trash'"></app-svg>
          </button>
        </ng-container>
      </li>
    </ul>
  </div>
</div>
