<div class="flex-column flex-a-start" [ngClass]="{ 'xs-100': fullSize }">
  <div>
    <div
      class="crop-canvas mrgn-b_sm"
      [ngClass]="{ hidden: !cropHolder.original || error }"
    >
      <img-cropper #cropper [image]="cropHolder" [settings]="cropperSettings">
      </img-cropper>
    </div>
    <div class="crop-preview" *ngIf="cropHolder.original != null && !error">
      <img alt="" [src]="cropHolder.image" [class]="previewClass" />
    </div>

    <div
      class="el-in-a-row el-in-a-row_c flex_j-center mrgn-t"
      *ngIf="cropHolder.original && !error"
    >
      <button
        (click)="cropApply()"
        class="btn action-btn action-btn_success"
        type="button"
      >
        Apply
      </button>
      <button
        (click)="cropCancel()"
        class="btn action-btn action-btn_danger"
        type="button"
      >
        Cancel
      </button>
    </div>
  </div>

  <div
    *ngIf="!cropHolder.original"
    appFileUpload
    (click)="fileInput.click()"
    (filesDropped)="addPicture($event)"
    (filesHovered)="dropzoneState($event)"
    [ngClass]="{
      'image-gallery__drop_active': dropzoneActive,
      'image-gallery__drop_full': fullSize
    }"
    class="image-gallery__drop"
  >
    <input
      name="signature"
      type="file"
      #fileInput
      accept="image/*"
      [ngStyle]="{ display: 'none' }"
      (change)="fileChangeListener($event)"
    />
    <div>
      <p class="cropper__label">drop image here or click to browse</p>
      <p class="padding-t cropper__label cropper__label-grey">
        Recommended image resolution: {{ cropperSettings.width }}x{{
          cropperSettings.height
        }}px
      </p>
    </div>
  </div>
</div>
