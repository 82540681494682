<div [ngClass]="{ 'el-in-a-row el-in-a-row_c el-in-a-row_lg': isInline }">
  <mat-form-field
    class="datepicker-block datepicker-block_narrow"
    [ngClass]="{ 'datepicker-block_error': displayErrors }"
  >
    <input
      matInput
      type="text"
      placeholder="Select Date"
      required
      name="date"
      [(ngModel)]="date"
      [matDatepicker]="dp"
    />
    <mat-datepicker-toggle matSuffix [for]="dp">
      <mat-icon matDatepickerToggleIcon>
        <app-svg [href]="'datepicker-calendar'"></app-svg>
      </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker
      #dp
      startView="year"
      [calendarHeaderComponent]="customHeader"
    >
    </mat-datepicker>
  </mat-form-field>
  <p class="error" *ngIf="displayErrors">
    <ng-container *ngIf="errors.required">
      {{ 'validation.required' | translate }}
    </ng-container>
    <ng-container *ngIf="errors.api">
      {{ errors.api }}
    </ng-container>
  </p>
</div>
