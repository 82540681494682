import { Component } from '@angular/core';

@Component({
  templateUrl: './page-404.component.html',
  styleUrls: ['./page-404.component.less'],
})
export class Page404Component {
  public title: string = '404';
  public text: string = 'Page could not be found';
}
