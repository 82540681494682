import { AsyncPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { Component } from '@angular/core';
import { HighlightTextPipe } from '@core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'lib-default-cell-renderer',
  standalone: true,
  imports: [HighlightTextPipe, AsyncPipe],
  templateUrl: './default-cell-renderer.component.html',
})
export class DefaultCellRendererComponent implements ICellRendererAngularComp {
  searchText$;
  value;
  agInit(params: any): void {
    this.searchText$ = params.context?.searchText$ ?? new BehaviorSubject('');
    this.value = params.valueFormatted ?? params.value;
  }

  refresh(): boolean {
    return false;
  }
}
