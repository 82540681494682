import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { Observable } from 'rxjs';
import { UserCurrentService } from '../../core/services/user-current.service';
import { RoutesConstants } from '../../core/constants/routes.constants';
import { Settings } from 'luxon';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';

@Injectable()
export class AuthGuard {
  private _redirectUrl: string;

  constructor(
    private router: Router,
    private _activeRoute: ActivatedRoute,
    private _authService: AuthService,
    private _userCurrent: UserCurrentService,
    protected _featureFlagsService: FeatureFlagsService
  ) {}

  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    this._redirectUrl = state.url;
    if (!this._authService.getApiToken()) {
      // if this is registration - do not redirect anywhere
      if (
        this._activeRoute.snapshot.queryParams['token'] &&
        this.router.url.includes(RoutesConstants.AUTH.REGISTER)
      ) {
        return false;
      }
      // else redirect to the state.url
      this.router.navigate([RoutesConstants.AUTH.LOGIN], {
        replaceUrl: true,
        queryParams: { redirectUrl: this._redirectUrl },
      });
      return false;
    }
    return new Observable<boolean>((observer) => {
      this._featureFlagsService.loadConfig().then(() => {
        this._userCurrent.get().subscribe(
          (user) => {
            if (
              this._featureFlagsService.isFeatureEnabled(
                'OR_12510_Auth0_Login'
              ) &&
              user.first_login
            ) {
              this.router.navigate([RoutesConstants.UPDATE], {
                replaceUrl: true,
                queryParams: { redirectUrl: this._redirectUrl },
              });
              observer.next(false);
              observer.complete();
            }
            Settings.defaultZone =
              user.organization?.time_zone || user.time_zone;
            observer.next(true);
            observer.complete();
          },
          () => {
            if (
              this._featureFlagsService.isFeatureEnabled('OR_12510_Auth0_Login')
            ) {
              this._authService.logout();
            } else {
              this._authService.logoutUser().subscribe(
                () => {
                  this.router.navigate([RoutesConstants.AUTH.LOGIN]);
                },
                () => {
                  this._authService.logoutLocal();
                  this.router.navigate([RoutesConstants.AUTH.LOGIN], {
                    replaceUrl: true,
                    queryParams: { redirectUrl: this._redirectUrl },
                  });
                }
              );
            }
            observer.next(false);
            observer.complete();
          }
        );
      });
    });
  }
}
