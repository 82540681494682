import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

interface FeatureConfig {
  features: {
    all: string[];
    enabled: string[];
  };
  web_configs: Array<any>;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  config: FeatureConfig = null;

  constructor(private httpClient: HttpClient) {}

  /**
   * We convert it to promise so that this function can
   * be called by the APP_INITIALIZER
   */
  loadConfig() {
    if (this.config) {
      return Promise.resolve(this.config);
    }
    return this.httpClient
      .request('GET', environment.api.base + '/v2/feature_flags')
      .pipe(
        tap((data: any) => {
          this.config = data;
        })
      )
      .toPromise();
  }

  isFeatureEnabled(key: string) {
    return (
      this.config?.features.all.includes(key) &&
      this.config?.features.enabled.includes(key)
    );
  }
}
