import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../base-modal.component';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';

@Component({
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent extends BaseModalComponent {
  @Input() inputValue: string = '';
  @Input() inputDescription: string = '';
  @Input() modalWrapperClass: string;

  public buttonsToCenter: boolean;

  constructor(
    public activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(activeModal, _notificationService, _eventService);
    this.isTemplateDriven = true;
  }

  ngOnInit() {
    this.buttonsToCenter =
      [!this.hideCancel, !this.hideReset, !this.hideSubmit].filter(Boolean)
        .length === 1;
  }
}
