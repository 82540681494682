import { Injectable } from '@angular/core';
import { RoleType } from '../constants/types.constants';

@Injectable()
export class RoutingService {
  // private previousUrl: string = undefined;
  // private currentUrl: string = undefined;

  // constructor(private router: Router) {
  //   this.currentUrl = this.router.url;
  //   router.events.subscribe(event => {
  //     if (event instanceof NavigationEnd) {
  //       this.previousUrl = this.currentUrl;
  //       this.currentUrl = event.url;
  //     }
  //   });
  // }
  //
  // public getPreviousUrl() {
  //   return this.previousUrl;
  // }

  private _routeType: RoleType = 'tenant' as RoleType;

  public get routeType(): RoleType {
    return this._routeType;
  }

  public set routeType(value: RoleType) {
    this._routeType = value;
  }

  public get isTenant(): boolean {
    return this._routeType === ('tenant' as RoleType);
  }

  public get isClient(): boolean {
    return this._routeType === ('client' as RoleType);
  }
}
