import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { BaseListComponent } from '../base-list.component';
import { Configs } from '../../../core/constants/configs.constants';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { FilterService } from '../../../core/services/filter.service';
import { LogModel } from '../../../core/models/log.model';
import { LogType } from '../../../core/constants/enums';
import { LoggingService } from '../../../core/services/logging.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { RequestService } from '../../../core/services/request.service';
import { SearchService } from '../../../core/services/search.service';
import { Store } from '@ngxs/store';
import { UserCurrentService } from '../../../core/services/user-current.service';

@Component({
  selector: 'app-item-logs',
  templateUrl: './item-logs-list.component.html',
})
export class ItemLogsListComponent
  extends BaseListComponent<LogModel>
  implements OnInit
{
  @ViewChild('filtersComponent', { static: true })
  declare filtersComponent: any;
  @Input() public objectId: any;
  @Input() public orgId: any;
  @Input() public currentType: LogType;
  @Input() public shouldPassPageNumber: boolean = true;
  @Input() public withHeader: boolean = true;
  @Input() public isEmbeded: boolean;
  @Input() public userLogController: boolean = false;
  public currentTypeDetails: any;

  get params() {
    const params = this.requestParams();
    params.object_type = this.currentTypeDetails.type;
    params.object_id = this.objectId;
    return params;
  }

  public get items(): any[] {
    return this._items || [];
  }

  public set items(value: any[]) {
    this._items = value.map((e) => JSON.parse(JSON.stringify(e)));
  }

  constructor(
    protected _requestService: RequestService,
    protected _filterService: FilterService,
    protected _notificationService: CustomNotificationService,
    protected _searchService: SearchService,
    protected _loggingService: LoggingService,
    protected _userCurrent: UserCurrentService,
    protected _navigationService: NavigationService,
    protected appStore: Store
  ) {
    super(
      _requestService,
      _filterService,
      _notificationService,
      _searchService,
      _loggingService,
      _navigationService,
      appStore
    );
  }

  ngOnInit() {
    this.filterType = 'logs';
    this.listId = 'item-logs';
    this.setupParamsFromStorage();
    this.currentTypeDetails = Configs.LOG_CONFIGS[this.currentType];
    // Setup
    this._userCurrent.get().subscribe({
      next: () => this.loadList(),
      error: (err) => this._notificationService.apiError(err),
    });
  }

  getItems() {
    return this.store.getItems(this.params, this.orgId);
  }
}
