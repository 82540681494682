import { Directive, Input } from '@angular/core';
import { Validator, FormControl } from '@angular/forms';
import { REGEX } from '../../core/constants/regex.constants';

@Directive({
  selector: '[customPattern]',
})
export class CustomPatternDirective implements Validator {
  @Input() customPattern: string; // firs is name of regex, second is regex

  validate(control: FormControl): { [key: string]: any } {
    return customPatternValidator(this.customPattern)(control);
  }
}

function customPatternValidator(patternName) {
  const pattern = REGEX[patternName];
  if (!pattern) return null;
  let regex;
  if (typeof pattern === 'string') {
    let regexStr = '';
    if (pattern[0] !== '^') {
      regexStr += '^';
    }
    regexStr += pattern;
    if (pattern.at(-1) !== '$') {
      regexStr += '$';
    }
    regex = new RegExp(regexStr);
  } else {
    regex = pattern;
  }
  return (control) => {
    if (!control.value?.length) {
      return null; // don't validate empty values to allow optional controls
    }
    const value = control.value;
    return regex.test(value)
      ? null
      : { customPattern: { requiredPattern: patternName, actualValue: value } };
  };
}

export function patternValidator(patternName: string) {
  return customPatternValidator(patternName);
}
