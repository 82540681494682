<section class="modal-content__wrapper">
  <header class="modal-content__header">{{ header }}</header>

  <form #passwordForm="ngForm" (ngSubmit)="submit()">
    <div class="modal-content__body modal-content__body_without-form">
      <label for="password" class="board__description-label mrgn-b_xs"
        >New password:</label
      >
      <div class="form-block mrgn-b_sm">
        <input
          [(ngModel)]="password"
          [ngClass]="{ 'form-block_error': hasError(_password) }"
          name="password"
          type="password"
          id="password"
          required
          placeholder="New password"
          class="form-block__element"
          [pattern]="regex.onlyLatin"
          [customMaxlength]="30"
          #_password="ngModel"
          appTrimOnBlur
        />
        <p class="error" *ngIf="hasError(_password)">
          <ng-container *ngIf="_password.errors.required">
            {{ 'validation.required' | translate }}.
          </ng-container>
        </p>
      </div>
    </div>

    <div class="buttons-block modal-content__footer">
      <button
        type="button"
        (click)="cancel()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{ cancelText }}</span>
      </button>

      <button class="btn action-btn action-btn_success">
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">{{ submitText }}</span>
      </button>
    </div>
  </form>
</section>
