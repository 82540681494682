import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { FacilityAssetsModel, LocationModel } from '../models/location.model';
import { RequestService } from './request.service';
import { CustomNotificationService } from './custom-notification.service';
import { Observable } from 'rxjs';
import { DefaultListModel } from '../models/default-list.model';
import { map } from 'rxjs/operators';
import { DefaultIdNameModel } from '../models/default-id-name.model';

@Injectable()
export class LocationService extends BaseStoreService<LocationModel> {
  constructor(
    protected _requestService: RequestService,
    protected _notificationService: CustomNotificationService,
  ) {
    super(_requestService, LocationModel);
    this.resourceName = 'v1/facilities';
  }

  public getSubLevelByParentLocation(
    nextLevel = 'location',
    level?,
    ids: number[] = [],
    params: any = {}
  ): Observable<DefaultListModel<any>> {
    if (nextLevel !== 'location' && nextLevel !== 'building') {
      delete params.user_id;
    }
    if (level) {
      params[`${level}_ids`] = [ids];
    }
    return super.getItems(params, `${this.resourceName}/${nextLevel}s`);
  }

  public getSubLevelByParentLocationForeForecast(
    nextLevel = 'location',
    level?,
    ids: number[] = [],
    params: any = {},
    forecastId?
  ): Observable<DefaultListModel<any>> {
    if (nextLevel !== 'location' && nextLevel !== 'building') {
      delete params.user_id;
    }
    if (level) {
      params[`${level}_ids`] = [ids];
    }
    return super.getItems(params, `v1/forecast/${forecastId}/${nextLevel}s`);
  }

  public getLocations(
    params: any = {},
    deepLevel?
  ): Observable<DefaultListModel<LocationModel>> {
    if (deepLevel) {
      params.level = deepLevel;
    }
    return super.getItems(params, `${this.resourceName}/locations`);
  }

  public getForecastLocation(
    id,
    params?
  ): Observable<DefaultListModel<LocationModel>> {
    return super.getItems(params, `v1/forecast/${id}/locations`);
  }

  public getSearchResults(
    params?
  ): Observable<DefaultListModel<LocationModel>> {
    return super.getItems(params, `${this.resourceName}/search`);
  }

  public getLocationStructure(id, params?): Observable<LocationModel> {
    return super.getById(id, `${this.resourceName}/locations`, params);
  }

  public loadZonesList(
    params?
  ): Observable<DefaultListModel<DefaultIdNameModel>> {
    return this.getItems(params, `/${this.resourceName}/zones`);
  }

  public getAssetsCount(
    type: string,
    id: number
  ): Observable<FacilityAssetsModel> {
    return this._requestService
      .get(`/${this.resourceName}/${type}/${id}/assets_count`)
      .pipe(map((data) => data.assets_count as FacilityAssetsModel));
  }

  public create(
    body: { [key: string]: LocationModel },
    locationUnit: string
  ): Observable<any> {
    return this._requestService.post(
      `/${this.resourceName}/${locationUnit}`,
      body
    );
  }

  public delete(id: number, locationUnit: string): Observable<any> {
    return super.delete(id, `${this.resourceName}/${locationUnit}`);
  }

  public update(
    body: { [key: string]: LocationModel },
    resourceUrl: string
  ): Observable<LocationModel> {
    return this._requestService.put(
      `/${this.resourceName}/${resourceUrl}`,
      body
    );
  }

  public updateFacilityPosition(
    locations: { id: number; position: number }[],
    resourceUrl: string
  ): Observable<LocationModel> {
    return this._requestService.put(`/${this.resourceName}/${resourceUrl}`, {
      [resourceUrl]: locations,
    });
  }
}
