import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  constructor(protected _requestService: RequestService) {}

  public importFileToApi(
    object_key: string,
    type: string,
    org
  ): Observable<any> {
    const headers = org ? { [environment.tokens.org_header]: org } : undefined;
    const params = { input_object_key: object_key };

    return this._requestService.post(`/${type}/import/async`, params, headers);
  }
}
