import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { CustomNotificationService } from './custom-notification.service';
import { filter, first } from 'rxjs/operators';

@Injectable()
export class VersionCheckService {
  isRefreshShown: boolean;

  constructor(
    protected appRef: ApplicationRef,
    private updates: SwUpdate,
    private _notifyService: CustomNotificationService
  ) {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const everyMinute$ = interval(60 * 1000);
    const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);

    everyMinuteOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
  }

  public checkForUpdates(): void {
    this.updates.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe(() => {
        if (!this.isRefreshShown) {
          this.isRefreshShown = true;
          this.getReloadNotification();
        }
      });
  }

  public checkIfIE() {
    if (
      !localStorage.getItem('notified_about_ie') &&
      /msie\s|trident\//i.test(window.navigator.userAgent)
    ) {
      setTimeout(() => {
        this._notifyService.confirm(
          'You browser (Internet Explorer) has been discontinued and will no longer be supported by Origin soon.\n' +
            'Please consider switching to an up-to-date browser like Microsoft Edge, Google Chrome, Safari or Mozilla Firefox.',
          null,
          [
            {
              text: 'Remind me later',
              action: (toast) =>
                this._notifyService.clearNotification(toast.id),
            },
            {
              text: 'Ok, got it',
              action: (toast) => {
                localStorage.setItem('notified_about_ie', 'true');
                this._notifyService.clearNotification(toast.id);
              },
            },
          ]
        );
      }, 5000);
    }
  }

  private getReloadNotification() {
    this._notifyService.confirm(
      'Do you want to refresh this page?',
      'An Origin Software Update is Available.',
      [
        {
          text: 'Remind me later',
          action: (toast) => {
            this._notifyService.clearNotification(toast.id);
            setTimeout(() => this.getReloadNotification(), 60000);
          },
        },
        {
          text: 'Refresh',
          action: () => document.location.reload(),
        },
      ]
    );
  }
}
