import { Component, Input } from '@angular/core';
import { StatisticsModel } from '../../../core/models/statistics.model';
import { HelperService } from '../../../core/services/helper.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.less'],
})
export class StatisticsComponent {
  @Input() statistics: StatisticsModel[];

  constructor(public _featureFlagService: FeatureFlagsService) {}
  
  formatToCharsLimit(
    numberToFormat: number,
    charsLimit: number
  ): number | string {
    numberToFormat = HelperService.convertToNumberWithoutCommas(numberToFormat);
    const numLength = numberToFormat.toString().length;
    if (numLength <= charsLimit) {
      return HelperService.convertToNumberWithCommas(numberToFormat);
    }
    const intPart = parseInt(numberToFormat.toString()).toString().length;
    if (charsLimit >= intPart && charsLimit - intPart > 1) {
      return Number(numberToFormat).toFixed(1);
    }
    return (
      HelperService.convertToNumberWithCommas(
        Math.round(numberToFormat / 1000)
      ) + 'k'
    );
  }
  getFCIStatus(value: number): string {
    if (value <= 5) {
      return 'Good';
    } else if (value > 5 && value <= 10) {
      return 'Fair';
    } else if (value > 10 && value <= 15) {
      return 'Poor';
    } else {
      return 'Critical';
    }
  }
}
