import { DefaultModel } from './default.model';
import { DefaultDTO } from '../dto/default.dto';

export class ReportsModel extends DefaultModel<DefaultDTO> {
  total?: number;
  result?: Array<{ risk: string; quantity: number }>;

  constructor(data?) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  public get entity_name(): string {
    return 'reports';
  }

  public toString(): string {
    return this.total.toString();
  }

  public dto(): DefaultDTO {
    return null;
  }
}
