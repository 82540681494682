import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { RequestService } from './request.service';
import { Observable, throwError } from 'rxjs';
import { UserModel } from '../models/user.model';
import { catchError, map } from 'rxjs/operators';
import { DefaultListModel } from '../models/default-list.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserStoreService extends BaseStoreService<UserModel> {
  public userAvatarChangedId: number;

  constructor(protected _requestService: RequestService) {
    super(_requestService, UserModel);
    this.resourceName = 'v2/users';
  }

  public getItems(params?): Observable<DefaultListModel<UserModel>> {
    return super.getItems(params, `${this.resourceName}/tenant`);
  }

  public resetPasswordConfirm(params): Observable<any> {
    return this._requestService.put(
      `/${this.resourceName}/reset_password`,
      params
    );
  }

  public resetPasswordRequest(data: { email: string }): Observable<any> {
    return this._requestService.post(
      `/${this.resourceName}/forgot_password`,
      data
    );
  }

  public resetInvitation(id): Observable<any> {
    return this._requestService.delete(`/${this.resourceName}/${id}`);
  }

  public acceptInvite(data): Observable<any> {
    return this._requestService.post(
      `/${this.resourceName}/accept_invitation`,
      data
    );
  }

  public invite(user: UserModel, organizationId?: string): Observable<any> {
    const url = `/${this.resourceName}/client/invite`,
      headers = {},
      params = {
        user: {
          email: user.email,
          role_name: user.role_name,
          eam: user.eam,
        },
      };
    if (organizationId) {
      headers[environment.tokens.org_header] = organizationId;
    }
    return this._requestService.post(url, params, headers);
  }

  public reInvite(id, isTenant): Observable<any> {
    const url = isTenant
      ? `/${this.resourceName}/tenant/${id}/reinvite`
      : `/${this.resourceName}/client/${id}/reinvite`;
    return this._requestService.put(url);
  }

  public inviteTenantUser(
    user: UserModel,
    organization_ids?: Array<string>
  ): Observable<any> {
    const url = `/${this.resourceName}/tenant/invite`,
      params = {
        user: {
          email: user.email,
          role_name: user.role_name,
          eam: user.eam,
          organization_ids: organization_ids,
        },
      };
    return this._requestService.post(url, params);
  }

  public getUsersByOrganization(
    params?,
    organizationKey?
  ): Observable<DefaultListModel<any>> {
    const headers = {};
    if (organizationKey) {
      headers[environment.tokens.org_header] = organizationKey;
    }
    return super.getItems(params, `${this.resourceName}/client`, headers);
  }

  public getLogUsersByOrganization(
    organizationKey?: string
  ): Observable<UserModel[]> {
    const headers = {};
    if (organizationKey) {
      headers[environment.tokens.org_header] = organizationKey;
    }
    return this._requestService.get(
      `/${this.resourceName}/client/log_user_list`,
      {},
      headers
    );
  }

  // + tenant users
  public getAllUsersByOrganization(
    organizationKey?: string,
    withTenant?: boolean
  ): Observable<UserModel[]> {
    const headers = {};
    if (organizationKey) {
      headers[environment.tokens.org_header] = organizationKey;
    }
    let url = `/${this.resourceName}/client/list`;
    if (withTenant) {
      url += '?with_tenant=true';
    }
    return this._requestService.get(url, {}, headers);
  }

  lockAccount(model: UserModel, params = {}) {
    const url = `/${this.resourceName}/${model.id}/block`;
    params[model.entity_name] = model.dto();
    return this._requestService.put(url, params, this.headers).pipe(
      map((res) => new this.model(res) as UserModel),
      catchError((error) => throwError(() => error))
    );
  }

  unlockAccount(model: UserModel, params = {}) {
    const url = `/${this.resourceName}/${model.id}/unblock`;
    params[model.entity_name] = model.dto();
    return this._requestService.put(url, params, this.headers).pipe(
      map((res) => new this.model(res) as UserModel),
      catchError((error) => throwError(() => error))
    );
  }

  public getTableauTicket(): Observable<any> {
    return this._requestService.post('/v1/tabserver/trusted');
  }

  public getQlikTicket(): Observable<any> {
    return this._requestService.post('/v1/analytic_server/trusted');
  }
}
