<section class="modal-content__wrapper">
  <header class="flex flex__nowrap modal-content__header">
    {{ 'comment' | translate }}
  </header>
  <form [formGroup]="form">
    <fieldset class="modal-content__footer">
      <h2 class="modal-content__text mrgn-b_xs" *ngIf="model.author?.id">
        {{ 'commented_by' | translate }}
        <b>{{ model.author?.name }}</b>
        ({{ model.created_at | dateFormat: 'MM/dd/yyyy, h:mm a' }})
      </h2>
      <h2 class="modal-content__text mrgn-b_xs" *ngIf="model.editor?.id">
        {{ 'edited_by' | translate }}
        <b>{{ model.editor?.name }}</b>
        ({{ model.edited_at | dateFormat: 'MM/dd/yyyy, h:mm a' }})
      </h2>
      <label
        for="body"
        *ngIf="!model.author?.id"
        class="modal-content__label"
        >{{ 'comment' | translate }}</label
      >
      <div class="form-block form-block_lg">
        <textarea
          id="body"
          class="form-block__element"
          [placeholder]="'comment' | translate"
          formControlName="body"
          rows="3"
          [required]="!model.author?.id"
          [ngClass]="{ 'form-block_error': hasError('body') }"
        >
        </textarea>
        <button
          type="button"
          class="btn tooltip-btn_error"
          *ngIf="hasError('body')"
          [tooltip]="getErrorText('body')"
        >
          <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
        </button>
      </div>
      <button
        class="btn-add-attribute btn-add-attribute_neutral"
        *ngIf="isDeleteShown"
        (click)="resetForm()"
      >
        <app-svg [href]="'trash'" [iconClass]="'icon_xxs'"></app-svg>
        <span>{{ 'buttons.delete_comment' | translate }}</span>
      </button>
    </fieldset>
    <!-- Buttons -->
    <footer class="buttons-block modal-content__footer">
      <button
        type="button"
        (click)="close()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>

      <ng-template #saveBtn>
        <button
          (click)="saveAction()"
          [disabled]="!form?.dirty"
          class="btn action-btn action-btn_success"
        >
          <span class="action-btn__icon"
            ><app-svg [href]="'check'"></app-svg
          ></span>
          <span class="action-btn__title">{{
            'buttons.submit' | translate
          }}</span>
        </button>
      </ng-template>

      <button
        (click)="deleteComment()"
        *ngIf="!form.value.body && model.author?.id; else saveBtn"
        class="btn action-btn action-btn_success"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.confirm' | translate
        }}</span>
      </button>
    </footer>
  </form>
</section>
