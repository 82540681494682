<app-gallery *ngIf="images" [isInline]="true" [images]="images"></app-gallery>

<div class="flex-column flex-column__full-height edit-list__row_spec">
  <div class="image-gallery" [ngClass]="{ 'image-gallery_full': full }">
    <div
      class="image-gallery__drop"
      appFileUpload
      (click)="fileInput.click()"
      (filesDropped)="addPicture($event)"
      (filesHovered)="dropzoneState($event)"
      [ngClass]="{
        'image-gallery__drop_active': dropzoneActive,
        'image-gallery__drop_full': full
      }"
    >
      <input
        type="file"
        #fileInput
        name="fileInput"
        accept="image/*"
        [ngStyle]="{ display: 'none' }"
        (change)="addPicture($event.target['files'])"
      />
      <span>drop image here or click to browse</span>
    </div>
    <ul class="image-gallery__list" *ngIf="images.length || uploading_image">
      <li
        *ngFor="let image of images; let $index = index"
        (mouseleave)="overImage = null"
        class="image-gallery__item"
      >
        <ng-container *ngIf="image.url; else imageLoader">
          <img
            (mouseover)="overImage = $index"
            (click)="openGallery($index)"
            class="image-gallery__img"
            [src]="image.thumb"
            alt="Image {{ $index }}"
          />
          <div class="image-gallery__btn-container flex">
            <button
              type="button"
              *ngIf="!!image.tag || image.primary; else addTagBtn"
              [ngClass]="{ 'image-gallery__btn_active': image.primary }"
              (click)="removeTag(image)"
              class="btn image-gallery__btn image-gallery__btn-clickable el-in-a-row el-in-a-row_c"
            >
              <app-svg [href]="'trash'"></app-svg>
              <span>{{
                image.primary ? 'Primary' : (image.tag | capitalize)
              }}</span>
            </button>
            <ng-template #addTagBtn>
              <button
                type="button"
                (click)="chooseTag(image)"
                class="btn image-gallery__btn image-gallery__btn-clickable el-in-a-row el-in-a-row_c"
              >
                <app-svg [href]="'tags'"></app-svg>
                <span>Add tag</span>
              </button>
            </ng-template>

            <button
              type="button"
              class="btn image-gallery__btn image-gallery__btn-clickable image-gallery__btn-icon"
              (click)="removePicture(image, $index)"
            >
              <app-svg [href]="'trash'"></app-svg>
            </button>
          </div>
        </ng-container>
        <ng-template #imageLoader>
          <app-gallery-loader></app-gallery-loader>
        </ng-template>
      </li>
    </ul>
  </div>

  <p class="avatar-error organization" *ngIf="avatar_error">
    {{ 'validation.image_format' | translate }}
  </p>
</div>
