<lib-ag-grid-table
  style="width: 100%"
  className="ag-theme-alpine"
  [rowData]="rowData"
  [columnDefs]="colDefsInput"
  [rowDragManaged]="isRowDragManaged"
  [animateRows]="isAnimateRows"
  [icons]="icons"
  [gridOptions]="gridOptions"
  [defaultColDef]="defaultColDef"
  [postSortRows]="postSortRows"
  (gridReady)="onGridReady($event)"
  (rowDragEnter)="onRowDragEnter($event)"
  (rowDragEnd)="onRowDragEnd($event)"
  (sortChanged)="emitSortChanged()"
  (rowSelectedHasGroup)="showRowSelectionMsg()"
  (selectedIdsChange)="selectedIdsChange.emit($event)"
  (selectedParents)="selectedParents.emit($event)"
>
</lib-ag-grid-table>

<app-loader-with-progress
  *ngIf="toggleButtonClickedSubject"
></app-loader-with-progress>
