import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { UserCurrentService } from 'src/app/core/services/user-current.service';

@Component({
  selector: 'app-delete-icon',
  template: `
    <button
      type="button"
      class="btn btn_iconed"
      *ngIf="params?.availableToDelete(params.data)"
      (click)="onDelete()"
    >
      <app-svg [iconClass]="'delete-icon'" [href]="'trash'"></app-svg>
    </button>
  `,
})
export class DeleteIconComponent implements ICellRendererAngularComp {
  public params: any;

  constructor(public _userCurrent: UserCurrentService) {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }

  onDelete(): void {
    if (this.params?.onDelete) {
      this.params.onDelete(this.params.data);
    }
  }
}
