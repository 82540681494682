import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private _router: Router) {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        gtag('js', new Date());
        gtag('config', environment.googleAnalytics);
      });
  }

  init() {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics}`;
    script.async = true;
    document.head.appendChild(script);

    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
      window.dataLayer ||= [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${environment.googleAnalytics}');
    `;
    document.head.appendChild(gaScript);
  }
}
