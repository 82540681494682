import { Component, ViewChild } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-change-date-modal',
  templateUrl: './change-date.modal.component.html',
})
export class ChangeDateModalComponent extends ConfirmationModalComponent {
  currentDate!: string;
  @ViewChild('actionForm', { static: true }) declare tdForm: NgForm;

  constructor(
    public activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  save() {
    super.markFormGroupTouched();
    if (this.tdForm.invalid) {
      return;
    }
    this.submit(this.currentDate);
  }
}
