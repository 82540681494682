import { Component, Input } from '@angular/core';
import { BaseListComponent } from '../base-list.component';
import { RequestService } from '../../../core/services/request.service';
import { FilterService } from '../../../core/services/filter.service';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { SearchService } from '../../../core/services/search.service';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { UserCurrentService } from '../../../core/services/user-current.service';
import { UserStoreService } from '../../../core/services/user.store';
import { AssetsService } from '../../../core/services/assets.service';
import { OrganizationModel } from '../../../core/models/organization.model';
import { AssetModel } from '../../../core/models/asset.model';
import { UserModel } from '../../../core/models/user.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PmpeModel } from '../../../core/models/pmpe.model';
import { NavigationService } from '../../../core/services/navigation.service';
import { PathConstants } from '../../../core/constants/routes.constants';
import { Store } from '@ngxs/store';
import { PMWorkOrderModel } from 'src/app/core/models/pm-work-order.model';
import { PMWorkOrderService } from 'src/app/core/services/pm-work-order.service';

@Component({
  selector: 'app-pm-work-orders-list',
  templateUrl: './pm-work-orders-list.component.html',
})
export class PMWorkOrdersListComponent extends BaseListComponent<PMWorkOrderModel> {
  @Input() public set event(value: PmpeModel) {
    this._event = value;
    if (value && !(this.isSeparateList || this.isAssetList)) {
      this.isLoading = false;
    }
  }
  @Input() public users: Array<UserModel>;
  @Input() public organization: OrganizationModel;
  @Input() public isSeparateList: boolean;
  @Input() public isAssetList: boolean;
  @Input() public asset: AssetModel;
  @Input() public shouldPassPageNumber: boolean = true;
  public filterDropdowns: any;
  public filter: any = {};
  protected _event: PmpeModel;

  public get event(): PmpeModel {
    return this._event;
  }

  constructor(
    protected _requestService: RequestService,
    protected _filterService: FilterService,
    protected _notificationService: CustomNotificationService,
    protected store: PMWorkOrderService<PMWorkOrderModel>,
    protected _organizationCurrent: OrganizationCurrentService,
    protected _searchService: SearchService,
    protected _userCurrent: UserCurrentService,
    protected _userStore: UserStoreService,
    protected _filtersService: FilterService,
    protected _assetsService: AssetsService,
    protected modalService: BsModalService,
    protected _navigationService: NavigationService,
    protected appStore: Store
  ) {
    super(
      _requestService,
      _filterService,
      _notificationService,
      _searchService,
      store,
      _navigationService,
      appStore
    );
  }

  ngOnInit() {
    this.listId = 'work-order';
    this.keepKeywordAtPaths = [
      PathConstants.COMPLIANCE.WORK_ORDERS,
      PathConstants.COMPLIANCE.EVENTS,
    ];
    super.ngOnInit();
    this.setupFilter();
    this.loadList();
  }

  public canUserSeeWorkOrder(item): boolean {
    return (
      !this.isAssetList ||
      this._userCurrent.user.is_tenant ||
      !item.assignee.id ||
      this._userCurrent.user?.id === item.assignee.id
    );
  }

  /**
   * Setup asset list filters
   */
  private setupFilter() {
    this.filterDropdowns = {
      disabled: {},
    };
    this.filterDTO = this._filtersService.prepareFilterDTO(
      this.filter,
      'work_orders'
    );
    this.filterDropdowns.statuses = [
      { name: 'Failed', key: 'failed' },
      { name: 'Passed', key: 'passed' },
      { name: 'To Do', key: 'todo' },
      { name: 'Incomplete', key: 'incomplete' },
    ];
    const params = { per_page: 9999, page: 1 };
    if (this.isSeparateList) {
      this._userStore
        .getUsersByOrganization(params, this.organization?.id_hash)
        .subscribe({
          next: (data) => {
            this.filterDropdowns.users = data.list.filter(
              (item) => item.active_for_authentication
            );
            this.loadList();
          },
          error: (err) => this._notificationService.apiError(err),
        });
    } else if (this.isAssetList) {
      this.loadList();
    }
  }

  getItems() {
    if (this.isAssetList) {
      return this._assetsService.getPMWorkOrders(
        this.asset.id,
        this.requestParams()
      );
    }
  }

  requestParams() {
    const params = super.requestParams();
    if (this.isSeparateList) {
      if (this.filter.assignee) {
        params.assignee = this.filter.assignee;
      }
      if (this.filter.status) {
        params.status = this.filter.status;
      }
    }
    return params;
  }
}
