import { Injectable } from '@angular/core';

@Injectable()
export class SearchService {
  private keywords: any = {};

  public getKeyword(type): string {
    return this.keywords[type] ? this.keywords[type] : '';
  }

  public setKeyword(keyword, type): void {
    this.keywords[type] = keyword;
  }

  public reset() {
    this.keywords = {};
  }
}
