import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ForecastService } from '../../../core/services/forecast.service';
import { BaseReactiveForm } from '../../components/base-reactive-form';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { AutoUnsubscribeService } from '../../../core/services/auto-unsubscribe.service';
import { UserCurrentService } from '../../../core/services/user-current.service';
import { BgJobService } from '../../../core/services/bg-worker.service';
import { ErrorModel } from '../../../core/models/error.model';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { ClientAssetTypesService } from 'src/app/core/services/client-asset-types.service';
import { ForecastModel } from 'src/app/core/models/forecast.model';
import { HelperService } from 'src/app/core/services/helper.service';

@Component({
  selector: 'app-save-to-forecast-modal',
  templateUrl: './save-to-forecast-modal.component.html',
  styleUrls: ['./save-to-forecast-modal.component.less'],
})
export class SaveToForecastModalComponent
  extends BaseReactiveForm<ForecastModel>
  implements OnDestroy, OnInit {
  @Input() public forecastId: any;
  @Input() public header: string = 'Save To Forecast';
  @Input() public submitText: string = 'Submit';
  @Input() public organization_hash: string;
  @Input() public cancelText: string = 'Cancel';
  @Input() public budgetingData: any = null;
  @Input() public selectedAssetIDs = [];
  @Input() public existedForecast: ForecastModel;
  @Input() public createProject: boolean;
  @Input() public forecast: boolean;
  @Input() public periodList: string[] = [];
  @Input() public period: number;
  public forecastData: any;
  public apiErrors: any[] = [];
  public message: string = '';
  public body = document.querySelector('body');
  public leafUrl: string = '../../../../assets/img/noun-leaf.svg';
  private get generateRequestUrl(): string {
    return `${this.forecastId}`;
  }
  public timeout: any;
  public items: any = [];
  public proceedWithErrors: boolean = false;
  public needToBeFixed: boolean = true;

  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService,
    protected _destroy: AutoUnsubscribeService,
    protected _userCurrent: UserCurrentService,
    protected _bgJobService: BgJobService<ErrorModel>,
    private _forecastService: ForecastService<ForecastModel>,
    private _translateService: TranslateService,
    protected _featureFlagsService: FeatureFlagsService,
    protected _clientAssetTypesService: ClientAssetTypesService
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }

  ngOnInit() {
    this.setupForm();
    this.addProjectClassToBody();
    this.proceedWithErrors = false;
  }

  public submit(): void {
    this.needToBeFixed = true;
    this.apiErrors = [];
    let request;

    if (this.isFormValid) {
      this.isLoading = true;
      this.selectedAssetIDs = this.selectedAssetIDs.map((str) =>
        parseFloat(str)
      );
      const body: any = {
        forecast: {
          asset_ids: this.selectedAssetIDs.length
            ? this.selectedAssetIDs
            : null,
          force: this.proceedWithErrors,
        },
      };

      if (this.forecastId) {
        request = this._forecastService.addToForecast(
          this.generateRequestUrl,
          body,
          this.form.value.period
        );
          request.pipe(takeUntil(this._destroy)).subscribe({
            next: (res) => this.afterSuccessSubmit(res),
            error: (err) => {
              this.setErrorToControl(err, this.organization_hash);
              this.isLoading = false;
            },
          });
      }
    }
  }

  protected onUnhandledError(apiErrors, orgId) {
    this.apiErrors = this._notificationService.parseErrorsWithCodes(
      apiErrors,
      orgId
    );
    this.needToBeFixed = this.apiErrors.some((el) => el.needToBeFixed);
    if (!this.needToBeFixed) {
      this.cancelText = 'Cancel';
      this.apiErrors.push({ message: 'Do you want to proceed?' });
      this.submitText = this._translateService.instant('buttons.continue');
      this.proceedWithErrors = true;
    }
  }

  protected setupForm() {
    this.form = this.formBuilder.group({
      period: [0, Validators.required],
    });
    this.onFormChange();
  }

  private onFormChange() {
    if (!HelperService.isExist(this.period)) {
      this.periodList = this.existedForecast.fiscal_years.map((period) =>
        HelperService.getPeriodDuration(period)
      );
    }
    this.form.markAsDirty();
  }

  private createForecastLink() {
    return this.forecastData
      ? `/${this.organization_hash}/forecast/${this.forecastId}`
      : '';
  }

  private afterSuccessSubmit(forecastData, warnings?): void {
    this.forecastData = forecastData;
    if (warnings) {
      this.forecastData.warnings = warnings;
    }
    this.message = `Assets have been successfully added to forecast <a href=${this.createForecastLink()} class="link">${this.existedForecast.name.capitalize()}</a>.`;
    this.isLoading = false;
    this.cancelText = 'Ok';
  }

  addProjectClassToBody() {
    this.body.classList.add('projectbuilder-modal-open');
  }

  removeForecastClassFromBody() {
    this.body.classList.remove('projectbuilder-modal-open');
  }

  ngOnDestroy() {
    this.removeForecastClassFromBody();
  }
}
