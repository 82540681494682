import { Component, Input } from '@angular/core';

import { AutoUnsubscribeService } from '../../../core/services/auto-unsubscribe.service';
import { BgJobService } from '../../../core/services/bg-worker.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { ErrorModel } from '../../../core/models/error.model';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';
import { ExportModalComponent } from '../export.modal/export.modal.component';
import { FormBuilder } from '@angular/forms';
import { ImportService } from '../../../core/services/import.service';
import { Subject } from 'rxjs/internal/Subject';
import { UploadResourceService } from '../../../core/services/upload-resource.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-import-modal',
  templateUrl: './../export.modal/export.modal.component.html',
})
export class ImportModalComponent extends ExportModalComponent {
  @Input() file: any;
  @Input() declare type: string;

  public importCompleted$ = new Subject<void>();
  public action: string = 'import';
  public errorListMsg =
    'Import aborted due to the errors listed below. Please correct the errors and import anew.';

  constructor(
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected formBuilder: FormBuilder,
    protected _modalService: BsModalService,
    protected _errorService: ErrorService,
    protected _destroy: AutoUnsubscribeService,
    protected _bgJobService: BgJobService<ErrorModel>,
    private _importService: ImportService,
    private _uploadResourceService: UploadResourceService
  ) {
    super(
      _notificationService,
      _eventService,
      formBuilder,
      _modalService,
      _errorService,
      _destroy,
      _bgJobService
    );
  }

  ngOnInit() {
    this.header = 'Validation';
    this.status = 'loading';
    this.import();
  }

  public import() {
    if (!this.file) {
      return;
    }

    this._uploadResourceService
      .uploadFile(this.file, 'tmp', this.orgId)
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: (res) => {
          this._importService
            .importFileToApi(res.object_key, this.type, this.orgId)
            .pipe(takeUntil(this._destroy))
            .subscribe({
              next: (data) => {
                this.jobModel = data;
                this.checkProgress();
              },
              error: () => this.onErrorOccurs(),
            });
        },
        error: () => this.onErrorOccurs(),
      });
  }

  protected handleInProgressStatus() {
    this.header = 'Reading file';
    super.handleInProgressStatus();
  }

  protected handleSuccessStatus() {
    this.sendImportUpdate();
    this._notificationService.success('Import has been finished');
    this.result();
  }

  protected handleWarningStatus() {
    this.sendImportUpdate();
    super.handleWarningStatus();
  }

  private sendImportUpdate() {
    this.importCompleted$.next();
    this.importCompleted$ = new Subject<void>();
  }
}
