import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';
import { CropperModel } from '../../../core/models/cropper.model';

@Component({
  selector: 'app-cropper',
  templateUrl: './o-cropper.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OCropperComponent implements OnInit {
  @Input() public rounded: boolean;
  @Input() public cropWidth: number;
  @Input() public cropHeight: number;
  @Input() public previewHeight: number;
  @Input() public previewWidth: number;
  @Input() public cropHolder: CropperModel;
  @Input() public previewClass: string;
  @Input() public fullSize: boolean = false;
  @Output() public onCropApply = new EventEmitter<any>();
  @Output() public onCropCancel = new EventEmitter<any>();
  @ViewChild('cropper', { static: true }) cropper: ImageCropperComponent;
  error: boolean;
  cropperSettings: CropperSettings;
  fileName: string;
  dropzoneActive: boolean;

  ngOnInit() {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = this.cropWidth;
    this.cropperSettings.height = this.cropHeight;
    this.cropperSettings.croppedWidth = this.cropWidth;
    this.cropperSettings.croppedHeight = this.cropHeight;
    this.cropperSettings.canvasWidth = this.previewWidth;
    this.cropperSettings.canvasHeight = this.previewHeight;
    this.cropperSettings.rounded = this.rounded;
  }

  public fileChangeListener($event) {
    const file: File = $event.target.files[0];
    this.readFileInCropper(file);
  }

  public addPicture(files) {
    this.readFileInCropper(files[0]);
  }

  public isSupportedFormat(fileType: string = '') {
    return (
      fileType.includes('jpeg') ||
      fileType.includes('jpg') ||
      fileType.includes('png') ||
      fileType.includes('gif')
    );
  }

  protected readFileInCropper(file) {
    const image: any = new Image();
    this.error = false;
    if (!file || !this.isSupportedFormat(file.type)) {
      this.error = true;
      this.cropHolder.error = this.error;
      return;
    }
    this.fileName = file.name;
    const myReader: FileReader = new FileReader();
    const self = this;
    myReader.onloadend = function (loadEvent: any) {
      image.src = loadEvent.target.result;
      self.cropper.setImage(image);
    };
    myReader.onerror = () => {
      this.error = true;
    };
    myReader.readAsDataURL(file);
  }

  public cropApply() {
    this.cropHolder.file_name = this.fileName;
    this.cropHolder.error = this.error;
    this.onCropApply.emit(this.cropHolder);
  }

  public cropCancel() {
    this.cropHolder.error = this.error;
    this.onCropCancel.emit();
  }

  dropzoneState(state: boolean) {
    this.dropzoneActive = state;
  }
}
