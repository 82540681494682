import { ActivatedRoute, Router } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';

import { BaseFormComponent } from './base-form.component';
import { BaseStoreService } from '../../core/services/base-store.service';
import { BreadcrumbItemModel } from '../../core/models/breadcrumb-item.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../core/services/custom-notification.service';
import { ErrorService } from '../../core/services/error.service';
import { EventService } from '../../core/services/event.service';
import { HelperService } from '../../core/services/helper.service';
import { ImageResizeService } from '../../core/services/image-resize.service';
import { Observable } from 'rxjs';

@Injectable()
export class BaseEditComponent<M>
  extends BaseFormComponent
  implements OnDestroy
{
  public isSavingModel: boolean;
  public method: string = 'update';
  public listPath: string;
  public detailsPath: string;
  public model: M;
  public breadcrumbs: Array<BreadcrumbItemModel> = [];
  public entityId: string | number;
  protected modalRef: BsModalRef;

  public get isCreate(): boolean {
    return this.method === 'create';
  }

  constructor(
    protected _notificationService: CustomNotificationService,
    protected store: BaseStoreService<M>,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected entityKey: string,
    protected _eventService: EventService,
    protected _errorService: ErrorService,
    protected _imageResizingService?: ImageResizeService
  ) {
    super(_notificationService, _eventService);
    this.findEntityId();
  }

  protected findEntityId(): void {
    if (!this.entityId) {
      this.entityId = this.activeRoute.snapshot.params[this.entityKey];
    }
  }

  public afterSaveAction(model: M): void {
    this.isSavingModel = false;
    // saving to store service to avoid GET request from details component
    this.store.currentModel = model;
    // redirect
    this.router.navigate([this.afterEditRedirectPath(model)]);
  }

  /**
   * After save model redirect to page (eg. details or list)
   * @param _model
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public afterEditRedirectPath(_model?: M): string {
    return '';
  }

  public saveAction(form?): void {
    this.hasSubmitted = true;
    if (form) {
      this.tdForm = form;
    }

    this.markFormGroupTouched();
    if (this.tdForm?.invalid || this.form?.invalid || this.isSavingModel) {
      return;
    }
    this.isSavingModel = true;
    this.saveModel().subscribe({
      next: (res) => this.afterSaveAction(res),
      error: (err) => {
        this.isSavingModel = false;
        this.parseErrors(err);
      },
    });
  }

  protected saveModel(params?): Observable<M> {
    return new Observable<M>((observer) => {
      this.store[this.method](this.model, undefined, params).subscribe(
        (model: M) => this.afterSaveModel(observer, model),
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  protected afterSaveModel(observer, model) {
    observer.next(model);
    observer.complete();
  }

  public loadModel(): Observable<M> {
    return new Observable<M>((observer) => {
      this.store.getById(this.entityId).subscribe({
        next: (model) => {
          this.model = model;
          observer.next(model);
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
          observer.complete();
        },
      });
    });
  }

  public listMode(): void {
    this.router.navigate([this.listPath]);
  }

  public detailsMode(): void {
    this.router.navigate([this.detailsPath]);
  }

  public cancel(): void {
    this.detailsMode();
  }

  public setPageInBreadcrumbs(linkArgs: any[]): string {
    const newLinkArgs = linkArgs.join('/').replace(/[/][/]/g, '/');
    // Prevent absolute URLs (Open Redirect Protection)
    if (newLinkArgs.startsWith('http') || newLinkArgs.startsWith('//')) {
      // console.warn('Potential Open Redirect detected. Blocking navigation.');
      return '/'; // Redirect to a safe default route
    }
    const breadcrumbLink = JSON.parse(
      window.sessionStorage.getItem(newLinkArgs)
    );
    if (HelperService.isExist(breadcrumbLink)) {
      return `${newLinkArgs}/pages/${breadcrumbLink.pageNum}`;
    }
    return newLinkArgs;
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
}
