<div class="{{ baseClass }}" *ngIf="!isInline">
  <div class="ng-preview-holder" (click)="openGallery(0)">
    <img src="{{ images[0].thumb }}" alt="Image 1" id="o_gallery_img_id" />
    <span class="icon-zoom" *ngIf="withZoom">
      <app-svg [href]="'search-plus'"></app-svg>
    </span>
    <span class="counter-holder" *ngIf="images.length > 1"
      >+{{ images.length - 1 }}</span
    >
  </div>
</div>

<div class="ng-overlay" *ngIf="isOpened"></div>

<div
  class="ng-gallery__content"
  unselectable="on"
  *ngIf="isOpened"
  (swipeleft)="nextImage()"
  (swiperight)="prevImage()"
>
  <div class="uil-ring-css" *ngIf="isLoading">
    <div></div>
  </div>

  <a
    href="{{ getImageDownloadSrc() }}"
    target="_blank"
    *ngIf="showImageDownloadButton()"
    class="ng-gallery__popup-btn ng-gallery__popup-btn_download"
  >
    <app-svg [href]="'download'"></app-svg>
  </a>

  <button
    type="button"
    class="ng-gallery__btn ng-gallery__popup-btn ng-gallery__popup-btn_close"
    (click)="closeGallery()"
  >
    <app-svg [href]="'times'" [iconClass]="'icon icon_l'"></app-svg>
  </button>
  <button
    type="button"
    class="ng-gallery__btn ng-gallery__arrows ng-gallery__arrows_left"
    (click)="prevImage()"
  >
    <app-svg [href]="'angle-left'" [iconClass]="'icon icon_l'"></app-svg>
  </button>

  <div class="ng-gallery__img">
    <figure class="ng-gallery__img-holder">
      <figcaption
        *ngIf="images[this.index]?.tag || images[this.index]?.primary"
        class="btn image-gallery__btn"
      >
        {{
          images[this.index].primary
            ? 'Primary'
            : (images[this.index].tag | capitalize)
        }}
      </figcaption>
      <img
        [src]="image"
        alt="image-{{ index }}"
        (click)="nextImage()"
        *ngIf="!isLoading"
      />
    </figure>
  </div>

  <button
    class="ng-gallery__btn ng-gallery__arrows ng-gallery__arrows_right"
    type="button"
    (click)="nextImage()"
  >
    <app-svg [href]="'angle-right'" [iconClass]="'icon icon_l'"></app-svg>
  </button>

  <section class="ng-gallery__thumbnails-wrapper">
    <ul class="ng-gallery__thumbnails">
      <li
        *ngFor="let i of images; let _index = index"
        class="ng-gallery__thumbnail-item"
      >
        <img
          alt=""
          src="{{ i.thumb }}"
          class="ng-gallery__thumbnail-img"
          [ngClass]="{ active: index === _index }"
          (click)="changeImage(_index)"
        />
      </li>
    </ul>
  </section>
</div>
