import { Component, Input } from '@angular/core';

import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { IBgJobIssuesListModel } from '../../../core/models/bg-worker.model';

@Component({
  selector: 'app-issue-list',
  templateUrl: './issue-list.modal.component.html',
})
export class IssueListModalComponent extends BaseModalComponent {
  @Input() public entityColumnName = 'Row';
  @Input() public header = 'Import errors';
  @Input() public jobUuid: string;
  @Input() public issues: IBgJobIssuesListModel;
  @Input() public orgId: string;
  @Input() public tableView: boolean;
  @Input() declare message: string;

  public activeTab: 'warnings' | 'errors' = 'errors';

  constructor(
    protected activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  ngOnInit(): void {
    if (!this.issues.errors.length) {
      this.activeTab = 'warnings';
    }
  }
}
