import { Component, Input } from '@angular/core';
import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';

@Component({
  selector: 'app-adding-tag-modal',
  templateUrl: './adding-tag-modal.component.html',
})
export class AddingTagModalComponent extends BaseModalComponent {
  @Input() currentTag: string;

  defaultValue: string = 'primary';
  tags: Array<{ name: string; type: string }>;

  constructor(
    protected _activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(_activeModal, _notificationService, _eventService);
  }

  ngOnInit(): void {
    this.currentTag ||= this.defaultValue;
    this.tags = [
      {
        name: 'Primary',
        type: 'primary',
      },
      {
        name: 'Electrical',
        type: 'electrical',
      },
      {
        name: 'Mechanical',
        type: 'mechanical',
      },
      {
        name: 'Other',
        type: 'other',
      },
    ];
  }
}
