export namespace Filters {
  export class SaveBatchId {
    static readonly type = '[Filters] Save Batch Id';
    constructor(
      public filterType: string,
      public batchId: string,
      public timestamp: number
    ) {}
  }

  export class ResetState {
    static readonly type = '[Filters] Reset State';
  }
}
