<div class="ng-gallery__content">
  <figure class="ng-gallery__img">
    <button
      type="button"
      class="syncing-slider__arrow syncing-slider__arrow_left"
      [disabled]="!index"
      (click)="prevImage()"
    >
      <app-svg [href]="'angle-left'"></app-svg>
    </button>
    <button
      type="button"
      class="syncing-slider__arrow syncing-slider__arrow_right"
      [disabled]="index === slides.length - 1"
      (click)="nextImage()"
    >
      <app-svg [href]="'angle-right'"></app-svg>
    </button>

    <img alt="" [src]="image" (click)="openGallery()" />
  </figure>

  <section class="ng-gallery__thumbnails-wrapper">
    <ul class="ng-gallery__thumbnails">
      <li
        *ngFor="let i of slides; let _index = index"
        class="ng-gallery__thumbnail-item"
      >
        <img
          alt=""
          [src]="i.thumb"
          class="ng-gallery__thumbnail-img"
          [ngClass]="{ active: index === _index }"
          (click)="changeImage(_index)"
        />
      </li>
    </ul>
  </section>
</div>

<app-gallery *ngIf="slides" [isInline]="true" [images]="slides"></app-gallery>
