import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { HelperService } from '../../core/services/helper.service';

@Directive({
  selector:
    '[customMaxlength][formControlName],[customMaxlength][formControl],[customMaxlength][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CustomMaxlengthDirective,
      multi: true,
    },
  ],
})
export class CustomMaxlengthDirective implements Validator {
  @Input() public customMaxlength: number;

  validate(formControl: FormControl): { [key: string]: any } {
    const value = formControl.value;
    if (!HelperService.isExist(value)) {
      return null;
    }
    if (value.name) {
      return value.name.length > this.customMaxlength
        ? { maxlength: true }
        : null;
    }
    return value.length > this.customMaxlength ? { maxlength: true } : null;
  }
}
