import { RequestService } from './request.service';
import { Injectable } from '@angular/core';
import { AttachmentModel } from '../models/attachment.model';
import { BaseStoreService } from './base-store.service';

@Injectable()
export class ProjectsAttachments<M> extends BaseStoreService<M> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, AttachmentModel);
  }
}
