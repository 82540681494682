import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'lib-pinned-renderer',
  standalone: true,
  imports: [],
  templateUrl: './pinned-renderer.component.html',
})
export class PinnedRendererComponent implements ICellRendererAngularComp {
  value;
  agInit(
    params: ICellRendererParams<any> & {
      totalValueGetter: (params?: any) => string;
    }
  ): void {
    if (params.node.footer && params.totalValueGetter) {
      this.value = params.totalValueGetter();
    } else {
      this.value = params.value;
    }
  }

  refresh(): boolean {
    return false;
  }
}
