<label *ngIf="params.calculationStep >= 4" class="custom-checkbox">
  <input
    id="assetsCheckedId"
    class="custom-checkbox__input"
    type="checkbox"
    name="check-all"
    [ngModel]="checkAll"
    (ngModelChange)="onCheckboxChange($event)"
    [disabled]="params.isLockedBudget"
  />
  <span class="custom-checkbox__check"></span>
</label>
<span *ngIf="params.calculationStep <= 3" class="status-mark">
  <app-svg [href]="'check'"></app-svg>
</span>
