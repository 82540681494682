import { Injectable, Input } from '@angular/core';

import { DateTime } from 'luxon';
import { GraphTypes } from '../../core/constants/enums';
import { HelperService } from '../../core/services/helper.service';
import { OrganizationModel } from '../../core/models/organization.model';

@Injectable()
export class BaseChartComponent {
  @Input() public filename: string;
  @Input() public installDate: any;
  @Input() public type: GraphTypes;
  @Input()
  public set organization(value: OrganizationModel) {
    if (value) {
      this._organization = value;
    }
  }
  @Input()
  protected set chartValues(value) {
    this._chartValues = value;
  }

  protected get chartValues(): any {
    return this._chartValues;
  }

  public chart;
  public canvasId: string;
  protected _chartValues: any;
  protected helper = HelperService;
  protected isCreated: boolean;
  protected data;
  protected options;

  public lineChartPeriod: string;
  chartFontSize: number = 16;
  randomColors?: boolean;
  randomColorsArray = [
    '#98CFFE',
    '#66B4F8',
    '#3E93DC',
    '#2C699E',
    '#1C476B',
    '#0E2335',
  ];
  chartColors?: Array<string>;
  protected _organization: OrganizationModel;

  public downloadImage() {
    const a = document.createElement('a');
    const canvas = document.getElementById(this.canvasId) as HTMLCanvasElement;

    a.href = canvas.toDataURL();
    a.download = this.filename + '.png';
    a.click();
  }

  protected get maxDate() {
    const now = DateTime.now();

    if (this.type === GraphTypes.asset) {
      const fiscal_month =
          parseInt(this.organization.fiscal_year_start_at.split('-')[0]) - 1,
        fiscal_day = parseInt(
          this.organization.fiscal_year_start_at.split('-')[1]
        ),
        isLastYear =
          now.month < fiscal_month ||
          (now.month === fiscal_month && now.day < fiscal_day),
        fiscal_date_year = isLastYear ? now.year - 1 : now.year,
        fiscalDate = DateTime.local(
          fiscal_date_year,
          fiscal_month,
          fiscal_day,
          0,
          0,
          0
        );
      switch (this.lineChartPeriod) {
        case 'calendar':
          return DateTime.max(
            DateTime.fromISO(this.organization.cmms_start_at),
            fiscalDate,
            DateTime.fromISO(this.installDate)
          );
        case 'rolling':
          return DateTime.max(
            now.minus({ year: 1 }),
            DateTime.fromISO(this.organization.cmms_start_at),
            DateTime.fromISO(this.installDate)
          );
        default:
          return DateTime.max(
            DateTime.fromISO(this.organization.cmms_start_at),
            DateTime.fromISO(this.installDate)
          );
      }
    } else if (this.type === GraphTypes.dashboard) {
      return DateTime.max(
        now.minus({ year: 1 }),
        DateTime.fromISO(this.organization.cmms_start_at)
      );
    }
  }

  public get organization(): OrganizationModel {
    return this._organization;
  }

  /**
   * default settings:
   * type: bar
   * colors: #337ab7
   * paddings: 10px
   * **/

  protected getValuesFromDataSets(): number[] {
    if (!this.chartValues.length) {
      return Object.keys(this.chartValues).reduce(
        (arr, rank) =>
          rank !== 'total' ? [...arr, this.chartValues[rank]] : arr,
        []
      );
    }
    return this.chartValues.map((item) => item.quantity);
  }

  protected getLabelsFromDataSets(_values?) {
    if (this.chartValues.length > 1) {
      return this.chartValues.map((item) => item.risk);
    }
    return Object.keys(this.chartValues).filter((rank) => rank !== 'total');
  }

  protected setupChartColors(labels): void {
    if (this.randomColors) {
      this.chartColors = labels.map(
        (_item, index) =>
          this.randomColorsArray[index % this.randomColorsArray.length]
      );
    } else {
      this.chartColors = labels.map(() => '#337ab7');
    }
  }
}
