<!-- Budgeting Location -->

<section class="panel-collapsible">
  <div class="panel-collapsible__header panel-collapsible__header_padd-0">
    <div class="collapsible-title_el">
      <button class="btn btn-collapse-icon" (click)="collapsed = !collapsed">
        <app-svg *ngIf="collapsed" [href]="'plus-square'"></app-svg>
        <app-svg *ngIf="!collapsed" [href]="'minus-square'"></app-svg>
      </button>
    </div>
    <div class="collapsible-title_el">
      <div class="el_inner-wrapper">
        <h4 class="text-semibold">{{ location.name }}</h4>
        <span class="title_el--details">{{ location.address }}</span>
      </div>
    </div>

    <div
      class="collapsible-title_el right-el"
      [ngClass]="{ transparent: !collapsed }"
    >
      <div class="location__total-wrapper text-center el_inner-wrapper">
        <span>{{ location.total_expenses | money }}</span>
        <span class="title_el--details text-bold">Total expenses</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="location.buildings">
    <div [hidden]="collapsed">
      <table class="table table-striped table-location--buildings">
        <thead class="table__header">
          <tr>
            <th class="table__sorting-column">Building</th>
            <th class="text-right">Total expenses ($)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let building of location.buildings">
            <td>{{ building.name }}</td>
            <td class="text-right">{{ building.total_expenses | money }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="border-t. bg-color-white">
            <td>Grand Total</td>
            <td class="text-right">{{ location.total_expenses | money }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </ng-container>
</section>
