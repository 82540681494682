<div class="chart-line-component">
  <canvas
    baseChart
    [height]="300"
    [datasets]="chart.data"
    [options]="chart.options"
    [plugins]="chart.plugins"
    [type]="chart.type"
  ></canvas>
</div>
