<section class="flex_nowrap flex-a-center" [ngClass]="{ narrow: narrow }">
  <ul class="star-rating read-only" *ngIf="readonly">
    <li
      *ngFor="let star of stars; let _index = index"
      class="star"
      [ngClass]="{ filled: star.filled, active: star.active }"
      (mouseenter)="hover(_index)"
      (click)="toggle(_index)"
    >
      <app-svg [href]="'star'" class="star-rating_star"></app-svg>
      <app-svg [href]="'star-stroke'" class="star-rating_star-stroke"></app-svg>
    </li>
  </ul>

  <ng-container *ngIf="!readonly">
    <ul class="star-rating">
      <li
        *ngFor="let star of stars; let _index = index"
        id="{{ 'star' + (_index + 1) }}"
        class="star"
        [ngClass]="{ filled: star.filled, active: star.active }"
        (click)="toggle(_index)"
        (mouseenter)="hover(_index)"
        [tooltip]="'fhie_stars.' + (_index + 1) | translate"
      >
        <app-svg [href]="'star'" class="star-rating_star"></app-svg>
        <app-svg
          [href]="'star-stroke'"
          class="star-rating_star-stroke"
        ></app-svg>
      </li>
    </ul>

    <span class="stars-hint" *ngIf="hintVisible">({{ showScoreValue }})</span>
    <button
      type="button"
      class="star-rating-reset"
      (click)="setToZero()"
      [disabled]="helper.isExist(ratingModel) && !ratingModel"
      [tooltip]="'fhie_stars.' + 0 | translate"
    >
      Set 0
    </button>
  </ng-container>
</section>
