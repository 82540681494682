import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClassificationModel } from '../models/classification.model';
import { AssetTypeModel } from '../models/asset-type.model';
import { BaseStoreService } from './base-store.service';
import { AssetTypeExtendedModel } from '../models/asset-type-extended.model';
import { DefaultIdNameModel } from '../models/default-id-name.model';
import { HelperService } from './helper.service';

@Injectable()
export class UniversalAssetTypesService extends BaseStoreService<AssetTypeExtendedModel> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, AssetTypeExtendedModel);
    this.resourceName = 'v2/universal/asset_types';
  }

  public duplicateStatusSubject = new BehaviorSubject<boolean>(false);
  duplicateStatus$: Observable<boolean> =
    this.duplicateStatusSubject.asObservable();

    updateDuplicateStatus(value: any) {
    this.duplicateStatusSubject.next(value);
  }
  /**
   * Requests repository classifications
   * @returns {*|Promise.<TResult>}
   */
  public getUsedClassifications(): Observable<Array<ClassificationModel>> {
    return this._requestService.get(`/${this.resourceName}/classes`);
  }

  public getRepositoryClassifications(): Observable<
    Array<ClassificationModel>
  > {
    return this._requestService.get(`/${this.resourceName}/classes`);
  }

  public getRepositoryGroups(): Observable<Array<DefaultIdNameModel>> {
    return this._requestService.get(`/v2/universal/asset_types/groups`);
  }

  public getUsedRepositoryGroups(): Observable<Array<DefaultIdNameModel>> {
    return this._requestService.get(`/${this.resourceName}/groups`);
  }

  public getRepositorySubgroups(): Observable<Array<DefaultIdNameModel>> {
    return this._requestService.get(`/v2/universal/asset_types/subgroups`);
  }

  public getUsedRepositorySubgroups(): Observable<Array<DefaultIdNameModel>> {
    return this._requestService.get(`/${this.resourceName}/subgroups`);
  }

  public getRepositoryUnits(): Observable<Array<string>> {
    return this._requestService.get('/v2/universal/asset_types/units');
  }

  public getItemsWithShortDataScope(): Observable<Array<AssetTypeModel>> {
    return this._requestService.get(`/${this.resourceName}/compact`);
  }

  /**
   * Requests dependencies for asset type (all organizations, that contain assets with given type)
   * @param id
   * @returns {*|Promise.<TResult>}
   */
  public getRepositoryDependencies(id): Observable<any> {
    return this._requestService.get(
      `/${this.resourceName}/${id}/dependencies/count`
    );
  }

  public getRepositoryTrades(): Observable<DefaultIdNameModel[]> {
    return this._requestService.get(`/${this.resourceName}/trades`);
  }

  public getTradeByReprositoryID(id): Observable<any> {
    return this._requestService.get(`/v1/assets_repository/${id}`);
  }
  public getTradesList(): Observable<DefaultIdNameModel[]> {
    return this._requestService.get(
      `/${this.resourceName}?per_page=1000&page=1`
    );
  }

  public getUsedRepositoryTrades(): Observable<DefaultIdNameModel[]> {
    return this._requestService.get(`/${this.resourceName}/trades`);
  }

  public getRepositorySpecialties(): Observable<Array<DefaultIdNameModel>> {
    return this._requestService.get(`/${this.resourceName}/specialties`);
  }

  public updateCustomizedRisks(
    id,
    risksArray
  ): Observable<AssetTypeExtendedModel> {
    const data: any = {
      asset_type: {
        asset_type_risks_attributes: risksArray,
      },
    };
    return this._requestService.put(`/${this.resourceName}/${id}`, data);
  }

  public getManufacturersList(): Observable<string[]> {
    return this._requestService.get(`/${this.resourceName}/manufacturers`);
  }

  public getResourceName(): string {
    return this.resourceName;
  }

  public isValueChanged(
    item: AssetTypeExtendedModel,
    targetField: string,
    sourceField: string = targetField,
    isName?: boolean
  ): boolean {
    if (!item.universal_attributes || isName) {
      return;
    }

    let source = item.universal_attributes[sourceField];
    let target = item[targetField];

    if (targetField.includes('.')) {
      source = HelperService.getObjValueByString(
        item.universal_attributes,
        sourceField
      );
      target = HelperService.getObjValueByString(item, targetField);
    }
    if (HelperService.isExist(source) || HelperService.isExist(target)) {
      return source !== target;
    }
    return false;
  }
}
