import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { AutoUnsubscribeService } from '../../../core/services/auto-unsubscribe.service';
import { BaseReactiveForm } from '../../components/base-reactive-form';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ClientAssetTypesService } from 'src/app/core/services/client-asset-types.service';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';

interface ApiErrorDetail {
  code: string;
  message: string;
  value?: string;
  key?: string;
  type?: string;
  if?: any;
}

interface ApiErrorResponse {
  errors: ApiErrorDetail[];
}
@Component({
  selector: 'app-save-to-budget',
  templateUrl: './save-to-budget.component.html',
  styleUrls: ['./save-to-budget.component.less'],
})
export class SaveToBudgetComponent
  extends BaseReactiveForm<any>
  implements OnInit
{
  @Input() header: string = `Save budget`;
  @Input() isPublic: boolean;
  @Input() message: string = 'Budget name';
  @Input() name: string;
  @Input() nameLabel: string;
  @Input() submitText: string = 'Save';
  @Input() accessName: string;
  @Input() placeholder: string = 'Enter name';
  onResult = new EventEmitter<any>();
  formErrors: { [key: string]: string } = {};
  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService,
    protected _destroy: AutoUnsubscribeService,
    protected _featureFlagsService: FeatureFlagsService,
    protected _clientAssetTypesService: ClientAssetTypesService
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }

  ngOnInit() {
    this.setupForm();
  }

  public saveAction(): void {
    if (this.isFormValid) {
      const formData = {
        ...this.form.value,
      };
      // Emit the result without closing modal
      this.onResult.emit(formData);
    }
  }

  public close(): void {
    this.activeModal.hide();
  }

  protected setupForm() {
    this.form = this.formBuilder.group({
      name: [this.name, [Validators.required, Validators.maxLength(60)]],
      public: [!!this.isPublic, [Validators.required]],
    });

    // Clear errors on value changes
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).valueChanges.subscribe(() => {
        this.clearFieldError(key);
      });
    });
  }

  public handleApiError(errorResponse: { error: ApiErrorResponse }) {
    const apiErrors = errorResponse?.error?.errors;

    if (Array.isArray(apiErrors) && apiErrors.length > 0) {
      apiErrors.forEach((err: ApiErrorDetail) => {
        if (err.key && err.message) {
          // Set the error in formErrors object
          this.formErrors[err.key] = err.message;
          // Set the error on the form control
          const control = this.form.get(err.key);
          if (control) {
            control.setErrors({ apiError: true });
            control.markAsTouched();
          }
        }
      });
    }
  }

  private clearFieldError(fieldName: string) {
    if (this.formErrors[fieldName]) {
      delete this.formErrors[fieldName];
      const control = this.form.get(fieldName);
      if (control?.errors?.apiError) {
        delete control.errors.apiError;
        if (Object.keys(control.errors).length === 0) {
          control.setErrors(null);
        }
      }
    }
  }

  public getErrorText(field: string): string {
    return this.formErrors[field] || super.getErrorText(field);
  }
}
