import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CalculationErrorService } from '../../../core/services/calculation-error.service';

@Component({
  selector: 'app-calculation-error-parsing',
  templateUrl: './calculation-error-parsing.component.html',
})
export class CalculationErrorParsingComponent implements OnInit, OnChanges {
  @Input() keys?: Array<string> = [];
  @Input() public set calculationErrors(value) {
    this._calculationErrors = value;
    let resultErrors = {};
    if (
      typeof this._calculationErrors !== 'undefined' &&
      this._calculationErrors.length
    ) {
      resultErrors = this._calcErrorService.createFriendlyErrors(
        this._calculationErrors,
        this.keys
      );
    }
    this._parsedErrors = resultErrors;
  }

  public _calculationErrors: Array<any>;
  public _parsedErrors: any = {};

  public get parsedErrors() {
    return this._parsedErrors;
  }

  public get anyErrors() {
    return !!Object.keys(this._parsedErrors).length;
  }

  constructor(protected _calcErrorService: CalculationErrorService) {}

  ngOnInit() {}

  ngOnChanges() {}
}
