import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import {
  Injectable,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';

import { BaseStoreService } from '../../core/services/base-store.service';
import { BreadcrumbItemModel } from '../../core/models/breadcrumb-item.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Configs } from '../../core/constants/configs.constants';
import { CustomNotificationService } from '../../core/services/custom-notification.service';
import { DateTime } from 'luxon';
import { FilterService } from '../../core/services/filter.service';
import { HelperService } from '../../core/services/helper.service';
import { NavPanelTabModel } from '../../core/models/nav-panel-tab.model';
import { NavigationService } from '../../core/services/navigation.service';
import { REGEX } from '../../core/constants/regex.constants';
import { RequestService } from '../../core/services/request.service';
import { SearchService } from '../../core/services/search.service';
import { Store } from '@ngxs/store';

@Injectable()
export class BaseListComponent<M> implements OnInit, OnDestroy {
  @ViewChild('filtersComponent') filtersComponent: any;

  public detailsMode: string;
  public navValues: Array<NavPanelTabModel>;
  public pageSizes: Array<{ value: number; label: string }>;
  public paginationBase: number = 3;
  public perPage: number = 10;
  public page: number = 1;
  public total: number = 0;
  public totalItems: number = 0;
  public totalPages: number = 0;
  public isLoading: boolean;
  public hasLeftDots: boolean;
  public hasRightDots: boolean;
  public hasNextPage: boolean;
  public hasPrevPage: boolean;
  public sortBy: string;
  public sortReverse: boolean = false;
  public pages: Array<number>;
  public keyword: string = '';
  public filtersOpened: boolean;
  public filterDTO: any;
  public filterType: string;
  public maxDate = DateTime.now().toISO();
  public resourceName: string;
  public breadcrumbs: Array<BreadcrumbItemModel> = [];
  public listId: string;
  public selectedItems: { [key: number]: boolean } = {};
  public allSelected: boolean;
  public indicesMode: string = Configs.indicesModes[0].key;
  public helper = HelperService;
  protected _items: Array<M> = [];
  protected keepKeywordAtPaths: Array<string> = [];
  protected subscriptions: Subscription = new Subscription();
  protected modalRef: BsModalRef;
  protected initList: boolean;
  injector: Injector = inject(Injector);
  ngUnSubscribe = new Subject<void>();
  searchText$ = new BehaviorSubject(this.keyword);
  context = {
    searchText$: this.searchText$.pipe(takeUntil(this.ngUnSubscribe)),
  };
  apiRequestSubscription: Subscription;
  public get items(): Array<M> {
    return this._items || [];
  }

  public set items(value: Array<M>) {
    this._items = value;
  }

  public get regex() {
    return REGEX;
  }

  public get paramsForExport() {
    let params: any = {};

    if (this.filterType) {
      params = this._filterService.prepareFilterBatchId(
        this.filterType,
        params
      );
    }

    if (this.sortBy) {
      params.order_by = this.sortBy;
      params.direction = this.sortReverse ? 'desc' : 'asc';
    }
    return params;
  }

  get itemsCheckedCount(): number {
    return !this.items ? 0 : this.getSelectedItems.length;
  }

  get getSelectedItems(): any {
    return Object.keys(this.selectedItems).filter(
      (key) => this.selectedItems[key]
    );
  }

  constructor(
    protected _requestService: RequestService,
    protected _filterService: FilterService,
    protected _notificationService: CustomNotificationService,
    protected _searchService: SearchService,
    protected store: BaseStoreService<M>,
    protected _navigationService: NavigationService,
    protected appStore: Store
  ) {}

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  public ngOnInit() {
    this.setupParamsFromStorage();
    this.pageSizes = [
      {
        value: 10,
        label: '10/Page',
      },
      {
        value: 25,
        label: '25/Page',
      },
      {
        value: 50,
        label: '50/Page',
      },
      {
        label: '100/Page',
        value: 100,
      },
    ];
    // eslint-disable-next-line prefer-spread
    this.pages = Array.apply(null, { length: this.totalPages }).map(
      Number.call,
      Number
    );
    this.keyword = '';

    if (this.listId) {
      const keepKeyword = this.keepKeywordAtPaths.some(
        (path) => (this._navigationService.previousRoute || {}).path === path
      );
      this.keyword = keepKeyword
        ? this._searchService.getKeyword(this.listId)
        : '';
    }
  }

  public ngOnDestroy(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.subscriptions.unsubscribe();
    this.ngUnSubscribe.next();
    this.ngUnSubscribe.complete();
  }

  protected setupParamsFromStorage(): void {
    const dataObjStr = sessionStorage.getItem('origin_paths') || '{}';
    const pageNumMatching = window.location.href.match(/\/pages\/(\d+)/);
    const obj = JSON.parse(dataObjStr)[this.listId];

    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = isNaN(Number(obj[prop])) ? obj[prop] : Number(obj[prop]);
      }
    }

    if (pageNumMatching) {
      this.page = Number(pageNumMatching[1]);
    }

    this.perPage = +localStorage.getItem('perPage') || this.perPage;
  }

  public requestParams() {
    let params: any = {
      per_page: this.perPage,
      page: this.page || 1,
    };

    if (this.filterType) {
      params = this._filterService.prepareFilterBatchId(
        this.filterType,
        params
      );
    }

    if (this.keyword) {
      params.q = this.keyword;
    }

    if (this.sortBy) {
      params.order_by = this.sortBy;
      params.direction = this.sortReverse ? 'desc' : 'asc';
    }

    return params;
  }

  public getItems(resource?) {
    return this.store.getItems(
      this.requestParams(),
      resource || this.resourceName
    );
  }

  public afterGetItems(res, resource?): void {
    if (res.params?.page > res.params?.totalPages && res.params?.totalPages) {
      this.page = 1;
      this.loadList(resource);
    }
    if (res.params?.totalItems !== undefined) {
      this.totalItems = res.params.totalItems;
    }
    this.setItems(res.list);
    this.setTotal(res.params.total);
    this.setPaginationData(res.params);

    this.isLoading = false;
  }

  public onErrorOccurs(err): void {
    this._notificationService.apiError(err);
  }

  public loadList(resource?): void {
    this.clearSelectedItems();
    this.isLoading = true;
    this.items = [];

    if (this.apiRequestSubscription) {
      this.apiRequestSubscription.unsubscribe();
    }

    this.apiRequestSubscription = this.getItems(resource).subscribe({
      next: (res) => {
        this.afterGetItems(res, resource);
      },
      error: (err) => {
        this.onErrorOccurs(err);
        this.isLoading = false;
      },
    });
  }

  fetchSortedData(params): void {
    const sortModel = params.api.getState().sort?.sortModel ?? [];
    const { sort, colId } = sortModel[0] ?? {};
    this.sortBy = colId;
    if (colId === 'ag-Grid-AutoColumn') {
      const column = params.columnApi.getColumn('ag-Grid-AutoColumn');
      this.sortBy =
        column.colDef.cellRendererParams.colId ?? column.colDef.field;
    }
    this.sortReverse = sort === 'desc' ? true : false;
    this.loadList();
  }

  public search(keyword, type?): void {
    this.keyword = keyword;
    this.searchText$.next(this.keyword);
    this.page = 1;
    if (type) {
      this._searchService.setKeyword(this.keyword, type);
    }
    this.loadList();
  }

  public getSortingCellClass(property): string {
    if (this.sortBy === property) {
      return this.sortReverse
        ? 'table__sorting-column_desc'
        : 'table__sorting-column_asc';
    }
  }

  public updateAssetParent(id, data) {
    return this.store.update('', `v1/assets/${id}`, data);
  }

  public updateOnDragDrop(id, child) {
    return this.store.update('', `v1/assets/${id}`, child);
  }

  public updateOnExpanded(id, data) {
    this.store.update('', `v1/assets/${id}`, data).subscribe(() => {});
  }

  public setSortBy(property): void {
    if (this.items.length <= 1 && !this.isLoading) {
      return;
    }
    if (property === this.sortBy) {
      this.sortReverse = !this.sortReverse;
    }
    this.sortBy = property;
    this.saveListParam([
      { key: 'sortBy', value: this.sortBy },
      { key: 'sortReverse', value: this.sortReverse },
    ]);
    this.page = 1;
    this.loadList();
  }

  public onDrop(items: Array<M>): void {
    if (this.items.length <= 1 && !this.isLoading) {
      return;
    }
    this.setItems(items);
    this.loadList();
  }

  public getStartPageItem(): number {
    if (!+this.total) {
      return 0;
    }
    if (this.perPage >= +this.total) {
      return 1;
    }
    return 1 + this.perPage * (this.page - 1);
  }

  public getEndPageItem(): number {
    const itemsMax = this.perPage * this.page;
    if (this.total < itemsMax) {
      return this.total;
    }
    return itemsMax;
  }

  public updatePerPage($event: {
    location?: string;
    key: string;
    value: any;
  }): void {
    localStorage.setItem($event.key, $event.value);
    this.perPage = $event.value;
    this.page = 1;
    this.loadList();
  }

  protected saveListParam(props: Array<{ key: string; value: any }>) {
    const dataObjStr = sessionStorage.getItem('origin_paths') || '{}';
    const dataObj = JSON.parse(dataObjStr);

    if (!dataObj[this.listId]) {
      dataObj[this.listId] = {};
    }
    props.forEach(({ key, value }) => {
      dataObj[this.listId][key] = value;
    });
    sessionStorage.setItem('origin_paths', JSON.stringify(dataObj));
  }

  public setItems(items: Array<M>): void {
    this.items = items;
  }

  public setHasNextPage(): void {
    this.hasNextPage = this.totalPages > 1 && this.page < this.totalPages;
  }

  public setHasPrevPage(): void {
    this.hasPrevPage = this.totalPages > 1 && this.page > 1;
  }

  public setPage(page): void {
    this.page = page;
    this.allSelected = false;
    this.loadList();
  }

  public prevPage(): void {
    if (!this.hasPrevPage) {
      return;
    }
    this.setPage(this.page - 1);
  }

  public nextPage(): void {
    if (!this.hasNextPage) {
      return;
    }
    this.setPage(this.page + 1);
  }

  protected setTotal(total: number): void {
    if (!isNaN(total)) {
      this.total = total;
    } else {
      this.total = 0;
    }

    this.totalPages = Math.ceil(this.total / this.perPage);
    const pages = new Array(this.totalPages);

    for (let i = 0; i < pages.length; i++) {
      pages[i] = i + 1;
    }

    this.pages = pages;
    this.setHasNextPage();
    this.setHasPrevPage();
  }

  protected setPaginationData(params?): void {
    let pages = this.pages;
    const par = this.parseParams(params),
      base = this.paginationBase,
      baseShiftUp = base + (base - 1) / 2,
      baseShiftDown = base - (base - 1) / 2;

    this.hasLeftDots = false;
    this.hasRightDots = false;

    if (base + baseShiftUp < par.totalPages) {
      if (base + baseShiftDown < par.page + baseShiftDown - 1) {
        this.hasLeftDots = true;
      }

      if (par.totalPages - base > par.page) {
        this.hasRightDots = true;
      }
    }

    if (this.hasLeftDots && !this.hasRightDots) {
      pages = pages.slice(par.totalPages - base - baseShiftDown);
    } else if (!this.hasLeftDots && this.hasRightDots) {
      pages = pages.slice(0, base + baseShiftDown);
    } else if (this.hasLeftDots && this.hasRightDots) {
      pages = pages.slice(
        par.page - baseShiftDown,
        par.page + baseShiftDown - 1
      );
    }

    this.pages = pages;
  }

  protected parseParams(params): any {
    const parsed: any = {};

    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        parsed[key] = parseInt(params[key]);
      }
    }

    return parsed;
  }

  public applyFilters(filterDTO?): void {
    this.filterDTO = filterDTO;
    this.filtersOpened =
      this.filtersOpened || this.filtersComponent?.selectedFiltersCount;
    this.loadList();
  }

  public toggleFilters(): void {
    this.filtersOpened = !this.filtersOpened;
  }

  public selectAllOnPage(nestedField?: string, key = 'id'): void {
    this.allSelected = !this.allSelected;

    for (const val of this.items) {
      if (val) {
        this.selectedItems[
          val?.[nestedField] ? val[nestedField][key] : val[key]
        ] = this.allSelected;
      }
    }
  }

  public switchAllSelected(): void {
    setTimeout(
      () => (this.allSelected = this.itemsCheckedCount === this.items.length)
    );
  }

  /**
   * Clear all items in checked items array and un-select checkboxes
   */
  public clearSelectedItems(): void {
    this.allSelected = false;
    this.selectedItems = {};
  }

  public changeDetailsMode(mode): void {
    this.detailsMode = mode;
  }
}
