import { AddressPipe } from './address.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { CurrencyNamePipe } from './currency.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { ExistedPipe } from './existed.pipe';
import { FeaturePipe } from './feature.pipe';
import { MoneyPipe } from './money.pipe';
import { PercentPipe } from './percent.pipe';
import { PhonePipe } from './phone.pipe';
import { TextPipe } from './text.pipe';

export * from './currency.pipe';

export const pipes = [
  AddressPipe,
  CapitalizePipe,
  DateFormatPipe,
  ExistedPipe,
  MoneyPipe,
  PercentPipe,
  PhonePipe,
  TextPipe,
  CurrencyNamePipe,
  FeaturePipe,
];
