import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingComponent),
      multi: true,
    },
  ],
})
export class StarRatingComponent implements ControlValueAccessor {
  @Input() public max: number = 5;
  @Input() public readonly: boolean = false;
  @Input() public hintVisible: boolean = false;
  @Input() public narrow: boolean = false;

  public ratingModel: number;
  public stars: Array<{ filled: boolean; active: boolean }> = [];
  public ratingValue: number;
  public ratingModelChanged: boolean;

  private originalScore: number;

  propagateChange = (_: any) => {};

  get helper() {
    return HelperService;
  }

  get showScoreValue(): string {
    return this.helper.isExist(this.ratingModel) || this.ratingModelChanged
      ? this.ratingModel.toString()
      : 'no score yet';
  }

  public writeValue(inputVal: number) {
    // react on model changing
    this.ratingModel = inputVal;
    this.ratingValue = this.ratingModel;
    this.updateStars();
    if (!this.helper.isNumber(this.originalScore)) {
      this.originalScore = inputVal;
    }
  }

  public hover(index): void {
    if (this.readonly === true) {
      return;
    }
    this.ratingValue = index + 1;
    this.updateStars();
  }

  public toggle(index): void {
    if (this.readonly === true) {
      return;
    }
    this.ratingModelChanged = true;
    this.ratingModel = index + 1;
    this.updateStars();
    this.propagateChange(this.ratingModel);
  }

  public setToZero(): void {
    this.ratingModelChanged = true;
    this.ratingModel = 0;
    this.ratingValue = 0;
    this.updateStars();
    this.propagateChange(this.ratingModel);
  }

  public reset(): void {
    this.ratingModelChanged = false;
    this.ratingModel = this.originalScore;
    this.ratingValue = this.originalScore;
    this.updateStars();
    this.propagateChange(this.ratingModel);
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  private updateStars(): void {
    if (!this.stars.length) {
      for (let i = 0; i < this.max; i++) {
        this.stars.push({
          filled: i < this.ratingValue,
          active: i < this.ratingModel,
        });
      }
    } else {
      this.stars.forEach((star, i) => {
        star.filled = i < this.ratingValue;
        star.active = i < this.ratingModel;
      });
    }
  }
}
