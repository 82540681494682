import { Directive, ElementRef, Input } from '@angular/core';
import { HelperService } from '../../core/services/helper.service';

@Directive({
  selector: '[appTextHighlight]',
})
export class TextHighlightDirective {
  private _content: string;
  private _searchTerm: string;
  private separatedText = [];
  private separatedSearchedText = [];
  private final = '';
  searchPattern: any;
  matchPattern: any;
  splitFlag = '';
  matchFlag = '';
  spanStart = '<mark class="text-highlight">';
  spanEnd = '</mark>';

  @Input('content') set content(content: string) {
    this._content = content;
  }

  @Input('searchTerm') set searchTerm(searchTerm: string) {
    this._searchTerm = searchTerm;
    this.highlight();
  }

  @Input() caseSensitive: boolean;

  get content(): string {
    return this._content;
  }

  get searchTerm(): string {
    return this._searchTerm;
  }

  constructor(private el: ElementRef) {
    this.caseSensitive = false;
  }

  highlight() {
    this.splitFlag = this.caseSensitive ? '' : 'i';
    this.matchFlag = this.caseSensitive ? 'g' : 'gi';

    this.searchPattern = new RegExp(this._searchTerm, this.splitFlag);
    this.matchPattern = new RegExp(this._searchTerm, this.matchFlag);

    if (
      HelperService.isExist(this._searchTerm) &&
      this._searchTerm.length &&
      this._searchTerm[0] !== '.'
    ) {
      this.separatedText = this._content.split(this.searchPattern);
      this.separatedSearchedText = this._content.match(this.matchPattern);

      if (this.separatedSearchedText?.length) {
        for (let i = 0; i < this.separatedText.length; i++) {
          if (i <= this.separatedSearchedText.length - 1) {
            this.final +=
              this.separatedText[i] +
              this.spanStart +
              this.separatedSearchedText[i] +
              this.spanEnd;
          } else {
            this.final += this.separatedText[i];
          }
        }
      }
      this.el.nativeElement.innerHTML = this.final.length
        ? this.final
        : this._content;
    } else {
      this.el.nativeElement.innerText = this._content;
    }
  }
}
