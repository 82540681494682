import { Component, Input, ViewChild } from '@angular/core';

import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { NgForm } from '@angular/forms';
import { ReportModel } from '../../../core/models/report.model';
import { RequestService } from '../../../core/services/request.service';

@Component({
  templateUrl: './repair-work-order-modal.component.html',
})
export class RepairWorkOrderModalComponent extends BaseModalComponent {
  @ViewChild('workOrderForm', { static: true }) declare form: NgForm;
  @Input() public report: ReportModel;
  public repair_id: number;

  constructor(
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    private _requestService: RequestService,
    private modalInstance: BsModalRef
  ) {
    super(modalInstance, _notificationService, _eventService);
  }

  public ngOnInit() {
    this.repair_id = this.report.repair_order_number;
  }

  public isValidForm() {
    return this.form.valid;
  }

  public submit() {
    if (this.form && !this.isValidForm()) {
      return;
    }
    const url = `/compliance/reports/${this.report.id}`,
      data: any = {};
    data.report = {};
    data.report.repair_order_number = this.repair_id;
    this._requestService.put(url, data).subscribe({
      next: (success) => {
        this.onResult.next(success);
        this.modalInstance.hide();
      },
      error: (error) => this._notificationService.apiError(error),
    });
  }
}
