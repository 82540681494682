import { AssetModel } from '../models/asset.model';
import { BaseStoreService } from './base-store.service';
import { DefaultListModel } from '../models/default-list.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RequestService } from './request.service';
import { WorkOrderModel } from '../models/work-order.model';

@Injectable()
export class AssetsService extends BaseStoreService<AssetModel> {
  private toggleButtonClickedSubject = new BehaviorSubject<boolean>(false);
  toggleButtonClickedSubject$ = this.toggleButtonClickedSubject.asObservable();

  setToggleButtonClickedSubject(value: boolean): void {
    this.toggleButtonClickedSubject.next(value);
  }

  constructor(protected _requestService: RequestService) {
    super(_requestService, AssetModel);
    this.resourceName = 'v1/assets';
  }

  // region Edit/Delete/Restore

  public delete(id: number): Observable<any> {
    return this._requestService.put(
      `/${this.resourceName}/${id}/archive`,
      {},
      this.headers
    );
  }

  public deleteMultiple(asset_ids: Array<number>): Observable<any> {
    return this._requestService.delete(
      `/${this.resourceName}/delete_multiple`,
      { asset_ids },
      this.headers
    );
  }

  public archiveMultiple(
    asset_ids: Array<number>,
    parent_asset_ids?: Array<number>
  ): Observable<any> {
    return this._requestService.put(
      `/${this.resourceName}/archive_multiple`,
      { asset_ids, parent_asset_ids },
      this.headers
    );
  }

  public restoreMultiple(
    asset_ids: Array<number>,
    parent_asset_ids?: Array<number>
  ): Observable<any> {
    return this._requestService.put(
      `/${this.resourceName}/restore_multiple`,
      { asset_ids, parent_asset_ids },
      this.headers
    );
  }

  public getOrders(id, params): Observable<DefaultListModel<WorkOrderModel>> {
    return super._getItems<WorkOrderModel>(
      params,
      `/${this.resourceName}/${id}/orders`
    );
  }

  public getPMWorkOrders(
    id,
    params
  ): Observable<DefaultListModel<WorkOrderModel>> {
    return super._getItems<WorkOrderModel>(
      params,
      `/${this.resourceName}/${id}/pm_work_orders`
    );
  }

  // endregion
  getUsers(type): Observable<any> {
    let assetType = '';
    switch (type) {
      case 'incomplete':
      case 'general':
        assetType = type;
        break;
      case 'archived':
        assetType = 'archived';
        break;
      case 'has_errors':
        assetType = 'general';
        break;
      default:
        assetType = 'all';
    }

    let url;
    if (type && !type.indexOf('project')) {
      url = `/v1/projects/${type.split('_')[1]}/assets/users`;
    } else {
      url = `/${this.resourceName}/${assetType}/users`;
    }
    return this._requestService.get(url);
  }

  public getAssetCosts(params: {
    asset_type_id: number;
    regional_modifier: number;
    contingency_modifier: number;
    quantity?: number;
    cost_estimate?: number;
    forecast_id?: number;
  }): Observable<{ cost_estimate: number; cost_replacement: number }> {
    return this._requestService.get('/v2/client/calculate_asset_costs', params);
  }

  setAssetListType(assetModel: AssetModel): string {
    let assetListType = 'incomplete';
    switch (true) {
      case !!assetModel.calculation_errors?.length:
        assetListType = 'errors';
        break;
      case !!assetModel.archived_at:
        assetListType = 'archive';
        break;
      case assetModel.complete:
        assetListType = 'general';
        break;
    }
    return assetListType;
  }

  public getAssetRiskRanking(
    params?
  ): Observable<DefaultListModel<AssetModel>> {
    return super.getItems(
      params,
      `${this.resourceName}/risk_ranking/assets_list`
    );
  }

  public getAllCostCenters(params: string): Observable<string[]> {
    return this._requestService.get(`/v1/assets/${params}`);
  }

  public updateAssetParent(id, data): Observable<any> {
    return this._requestService.put(
      `/${this.resourceName}/${id}`,
      data,
      this.headers
    );
  }

  public updateOnDragDrop(id, child): Observable<any> {
    return this._requestService.put(
      `/${this.resourceName}/${id}`,
      child,
      this.headers
    );
  }

  public getAssetsWithDescendants(id: number): Observable<any> {
    return this._requestService.get(`/v1/assets/${id}/descendants`);
  }
}
