<section class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header">{{ header }}</h2>
  </header>

  <form class="modal-content__footer" #actionForm="ngForm">
    <label class="form-block__label">Next MWO Date</label>
    <mat-form-field class="datepicker-block datepicker-block_narrow">
      <input
        type="text"
        name="value"
        placeholder="Enter date"
        matInput
        #_value="ngModel"
        [ngClass]="{ 'form-block_error': hasError(_value) }"
        required
        [matDatepicker]="value"
        [(ngModel)]="currentDate"
      />

      <mat-datepicker-toggle matSuffix [for]="value"></mat-datepicker-toggle>
      <mat-datepicker #value [startAt]="currentDate"></mat-datepicker>
    </mat-form-field>
    <p class="error" *ngIf="hasError(_value) && _value.errors.required">
      {{ 'validation.required' | translate }}
    </p>
  </form>

  <footer class="buttons-block modal-content__footer">
    <button
      type="button"
      (click)="cancel()"
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"
        ><app-svg [href]="'times'" iconClass="icon icon_s"></app-svg
      ></span>
      <span class="action-btn__title">{{ 'buttons.cancel' | translate }}</span>
    </button>

    <button
      (click)="save()"
      type="button"
      class="btn action-btn action-btn_success"
    >
      <span class="action-btn__icon"
        ><app-svg [href]="'check'" iconClass="icon icon_s"></app-svg
      ></span>
      <span class="action-btn__title">Apply</span>
    </button>
  </footer>
</section>
