import { Component, Input, ViewChild } from '@angular/core';

import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { NgForm } from '@angular/forms';
import { PresetModel } from '../../../core/models/preset.model';
import { PresetService } from '../../../core/services/preset.service';

@Component({
  templateUrl: './preset-modal.component.html',
})
export class PresetModalComponent extends BaseModalComponent {
  @ViewChild('presetForm', { static: true }) public presetForm: NgForm;
  @Input() public header: string = `Create new preset`;
  @Input() declare fieldList: string[];
  @Input() public submitText: string = 'Save';
  userInput: string;
  public isShared: boolean = false;
  public existedPresetId: number;

  constructor(
    public activeModal: BsModalRef,
    protected _presetService: PresetService<PresetModel>,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    public _featureFlagsService?: FeatureFlagsService
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  public submit(): void {
    this.hasSubmitted = true;
    if (this.presetForm.invalid) {
      return;
    }
    if (this.existedPresetId) {
      this.onResult.next({
        name: this.userInput,
        isShared: this.isShared,
        existedPresetId: this.existedPresetId,
      });
      this.activeModal.hide();
    } else {
      this.isLoading = true;
      this._presetService.getItems({ q: this.userInput }).subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res.list?.length) {
            this.existedPresetId = res.list[0].id;
            return;
          }
          this.onResult.next({ name: this.userInput, isShared: this.isShared });
          this.activeModal.hide();
        },
        error: (err) => this._notificationService.apiError(err),
      });
    }
  }

  setShared(checked: boolean) {
    this.isShared = checked;
  }
}
