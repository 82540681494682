<div class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header">Enter Repair Work Order Number</h2>
  </header>
  <form
    #workOrderForm="ngForm"
    (ngSubmit)="submit()"
    (keydown.enter)="$event.preventDefault()"
  >
    <fieldset class="modal-content__body modal-content__body_without-form">
      <label for="repair_id" class="modal-content__label"
        >Repair Work Order</label
      >
      <input
        [(ngModel)]="repair_id"
        [customMaxlength]="15"
        [ngClass]="{ 'form-block_error': hasError(_repair_id) }"
        required
        #_repair_id="ngModel"
        class="form-block__element"
        name="repair_id"
        id="repair_id"
        type="text"
      />

      <p class="error" *ngIf="hasError(_repair_id)">
        <ng-container *ngIf="_repair_id.errors.required">
          {{ 'validation.required' | translate }}
        </ng-container>
        <ng-container *ngIf="_repair_id.errors.maxlength">
          {{
            'validation.maxlength_detail'
              | translate: { number: '15', value: 'Repair work order number' }
          }}
        </ng-container>
      </p>
    </fieldset>
    <div class="buttons-block modal-content__footer">
      <button
        (click)="cancel()"
        type="button"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>
      <button class="btn action-btn action-btn_success">
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Submit</span>
      </button>
    </div>
  </form>
</div>
