import { Component, Input } from '@angular/core';
import { BaseReactiveForm } from '../../components/base-reactive-form';
import { UserModel } from '../../../core/models/user.model';
import { IExportParams } from '../../../core/models/export-params.model';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { ErrorService } from '../../../core/services/error.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-export-with-additional-params',
  templateUrl: './export-with-additional-params.component.html',
})
export class ExportWithAdditionalParamsComponent extends BaseReactiveForm<UserModel> {
  @Input() public checkboxFieldName: string;
  @Input() public checkboxLabel: string;
  @Input() public orderOptions: any;
  @Input() public orgId: string;
  @Input() public header: string = 'Export';
  @Input() public successMessage: string;
  @Input() public type: string;
  @Input() public exportAddOns: IExportParams;

  public status: 'static' | 'in-progress' = 'static';

  public get params() {
    return { ...this.form.value, ...this.exportAddOns };
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }

  ngOnInit() {
    this.setupForm();
  }

  public saveAction() {
    if (this.isFormValid) {
      this.status = 'in-progress';
    }
  }

  protected setupForm() {
    const form: any = {};

    if (this.orderOptions) {
      form.order_by = new FormControl(this.orderOptions[0]?.type, [
        Validators.required,
      ]);
    }

    if (this.checkboxFieldName) {
      form[this.checkboxFieldName] = new FormControl(false);
    }

    this.form = this.formBuilder.group(form);
  }
}
