export interface QlikDashboardModel {
  id?: number;
  name: string;
  available_for: Array<string>;
  organization_ids?: Array<number>;
  position?: number;
  disable_drag?: boolean;
  app_id?: string;
  sheet_id?: string;
}

export class QlikSettingsModel {
  dashboards?: Array<QlikDashboardModel>;

  constructor(model?) {
    if (model) {
      Object.assign(this, model);
    }
    this.dashboards ||= [];
  }

  public get entity_name(): string {
    return 'tableau_settings';
  }
}
