import { SessionEntityModel } from './SessionEntityModel';
import { UserModel } from './user.model';

export class BaseSessionModel {
  created_at: string;
  details: any;
  error_code: number;
  status_code: number;
  id: number;
  showDetails?: boolean;
}

export class SessionModel extends BaseSessionModel {
  uuid: string;
  entities?: Array<SessionEntityModel>;
  user: UserModel;
  integration_api?: { name: string; id: number };
  integration_ftp?: { name: string; id: number };
  object_type?: string[];

  constructor(data) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }
}
