import { Directive, HostListener, Input } from '@angular/core';
import { CustomNotificationService } from '../../core/services/custom-notification.service';

@Directive({
  selector: '[appCopyToClipboard]',
})
export class CopyClipboardDirective {
  @Input('appCopyToClipboard') copyToClipboard: string;

  constructor(private _notificationsService: CustomNotificationService) {}

  @HostListener('click') onClick() {
    navigator.clipboard.writeText(this.copyToClipboard);
    this._notificationsService.success(
      'API key was copied to your clipboard',
      2000
    );
  }
}
