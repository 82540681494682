export class ComplianceConstants {
  public static TEST_TYPES = {
    checkbox: {
      key: 'checkbox',
      name: 'Checkboxes with Conditions',
      code: 0,
    },
    radio: {
      key: 'radio',
      name: 'Radiobuttons with Conditions',
      code: 1,
    },
    text: {
      key: 'text',
      name: 'Text Area',
      code: 2,
    },
    input: {
      key: 'input',
      name: 'Input Number with Conditions',
      code: 4,
    },
    data: {
      key: 'data',
      name: 'General Data Record',
      code: 5,
    },
    time: {
      key: 'time',
      name: 'Time',
      code: 6,
    },
    calculations: {
      key: 'calculations',
      name: 'Calculations',
      code: 7,
    },
    information: {
      key: 'text',
      name: 'Information',
      code: 8,
    },
    barcodes: {
      key: 'barcodes',
      name: 'Asset Barcodes',
      code: 9,
    },
  };

  public static CALCULATION_ANSWER = [
    { key: 'number', title: 'Number' },
    { key: 'time', title: 'Time' },
    { key: 'duration', title: 'Duration' },
  ];

  public static EVENT_STATUS = {
    all: {
      name: 'All',
      key: '',
    },
    current: {
      name: 'To Do',
      key: 'todo',
    },
    past_due: {
      name: 'Past Due',
      key: 'past',
    },
    completed: {
      name: 'Completed',
      key: 'completed',
    },
    repairs: {
      name: 'Repairs',
      key: 'repairs',
    },
  };

  public static CALCULATION_TYPES = {
    field: { name: 'Field', type: 0, action: 'field' },
    constant: { name: 'Constant', type: 3, action: 'const' },
    '+': { name: '+', type: 1, action: 'plus' },
    '-': { name: '-', type: 1, action: 'minus' },
    '/': { name: '/', type: 1, action: 'divide' },
    '*': { name: '*', type: 1, action: 'muliply' },
    '(': { name: '(', type: 2, action: 'lbracket' },
    ')': { name: ')', type: 2, action: 'rbracket' },
  };
}
