import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { RequestService } from './request.service';
import { UserStoreService } from './user.store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from './custom-notification.service';
import { Observable } from 'rxjs';
import { Configs } from '../constants/configs.constants';
import {
  QlikSettingsModel,
  QlikDashboardModel,
} from '../models/QlikSettingsModel';
import { QlikPreviewModalComponent } from 'src/app/shared/modals/qlik-preview-modal/qlik-preview-modal.component';
@Injectable({
  providedIn: 'root',
})
export class QlikSettingsService extends BaseStoreService<QlikSettingsModel> {
  protected modalRef: BsModalRef;

  constructor(
    protected _requestService: RequestService,
    protected _userStoreService: UserStoreService,
    protected modalService: BsModalService,
    protected _notificationService: CustomNotificationService
  ) {
    super(_requestService, QlikSettingsModel);
    this.resourceName = '/v1/analytics/settings';
  }

  getSettings(): Observable<QlikSettingsModel> {
    return this._requestService.get(`/${this.resourceName}/dashboards`);
  }

  createDashboard(
    settings_dashboard: QlikDashboardModel
  ): Observable<QlikDashboardModel> {
    return this._requestService.post(
      `${this.resourceName}/dashboards`,
      { settings_dashboard },
      this.headers
    );
  }

  updateDashboard(
    dashboard: QlikDashboardModel
  ): Observable<QlikDashboardModel> {
    const url = `${this.resourceName}/dashboards/${dashboard.id}`,
      body = {
        settings_dashboard: dashboard,
      };
    return this._requestService.put(url, body, this.headers);
  }

  deleteDashboard(
    dashboard: QlikDashboardModel
  ): Observable<QlikDashboardModel> {
    const url = `${this.resourceName}/dashboards/${dashboard.id}`,
      body: any = {};
    return this._requestService.delete(url, body, this.headers);
  }

  getTrustedTicket(dashboard: QlikDashboardModel) {
    this._userStoreService.getQlikTicket().subscribe({
      next: (ticket) => {
        let newSheetWithTicket = dashboard?.sheet_id;
        if (newSheetWithTicket.includes('/views')) {
          newSheetWithTicket = newSheetWithTicket.replace(
            '/views',
            `/trusted/${ticket}/views`
          );
        }
        this.showPreview(dashboard, newSheetWithTicket, dashboard?.app_id);
      },
      error: (err) => this._notificationService.apiError(err),
    });
  }

  protected showPreview(
    dashboard: QlikDashboardModel,
    dashboardSheetId: string,
    dashboardAppId: string
  ) {
    this.modalRef = this.modalService.show(QlikPreviewModalComponent, {
      class: Configs.MODAL_CLASS,
      animated: true,
      initialState: {
        header: dashboard.name,
        sheetId: dashboardSheetId,
        qlikAppId: dashboardAppId,
      },
    });
  }

  getUserDashboards(orgId?): Observable<Array<QlikDashboardModel>> {
    const params = {};
    if (orgId) {
      params['organization_id'] = orgId;
    }
    return this._requestService.get(
      '/v1/analytics/dashboards',
      params,
      this.headers
    );
  }

  getDashboard(dashboardId: number): Observable<QlikDashboardModel> {
    return this._requestService.get(
      `${this.resourceName}/dashboards/${dashboardId}`
    );
  }
}
