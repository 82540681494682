<section class="modal-content__wrapper">
  <header class="modal-content__header">
    {{ isCreate ? 'Add' : 'Edit' }} Assessment Score
  </header>

  <form
    #AssessForm="ngForm"
    (ngSubmit)="saveAction()"
    (keydown.enter)="$event.preventDefault()"
    appInvalidControlScroll
  >
    <fieldset
      class="modal-content__body modal-content__body_without-form"
      *ngIf="isLoading"
    >
      <app-loader></app-loader>
      <span class="sr-only">Loading...</span>
    </fieldset>
    <ul class="modal-content__body modal-content__list" *ngIf="model">
      <li
        class="modal-content__item modal-content__item--no-padding mrgn-r mrgn-b_sm"
      >
        <label for="completed_at" class="modal-content__label"
          >Assessment Date</label
        >
        <mat-form-field
          class="datepicker-block datepicker-block__display-block"
          [ngClass]="{ 'form-block_error': hasError(_completed_at) }"
        >
          <input
            type="text"
            name="completed_at"
            id="completed_at"
            placeholder="Select date"
            matInput
            (keydown.enter)="$event.target.blur()"
            #_completed_at="ngModel"
            [disabled]="isLoading"
            [matDatepicker]="completed_at"
            [(ngModel)]="model.completed_at"
            [max]="'now' | dateFormat: 'ISO'"
            [min]="minDate"
          />
          <mat-datepicker-toggle
            class="datepicker__custom-icon-wrapper"
            matSuffix
            [for]="completed_at"
          >
            <mat-icon matDatepickerToggleIcon>
              <app-svg [href]="'datepicker-calendar'"></app-svg>
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker
            #completed_at
            [startAt]="model.completed_at"
          ></mat-datepicker>
        </mat-form-field>
        <p class="error" *ngIf="hasError(_completed_at)">
          <ng-container *ngIf="_completed_at.errors.required">
            {{ 'validation.required' | translate }}
          </ng-container>
          <ng-container *ngIf="_completed_at.errors.matDatepickerMin">
            {{ 'validation.min_date' | translate }}
          </ng-container>
          <ng-container *ngIf="_completed_at.errors.matDatepickerMax">
            {{ 'validation.max_date' | translate }}
          </ng-container>
          <ng-container *ngIf="_completed_at.errors.matDatepickerParse">
            {{ 'validation.bad_date' | translate }}
          </ng-container>
        </p>
      </li>

      <li
        class="modal-content__item modal-content__item_grow modal-content__item--no-padding"
      >
        <label class="modal-content__label">FHIE Score</label>
        <div class="stars-block">
          <app-star-rating
            name="score"
            required
            [hintVisible]="true"
            [(ngModel)]="model.score"
            #_score="ngModel"
          >
          </app-star-rating>
        </div>
        <p class="error" *ngIf="hasError(_score) && _score.errors.required">
          {{ 'validation.required' | translate }}
        </p>
      </li>

      <li class="modal-content__item modal-content__item_full-width">
        <textarea
          [disabled]="isLoading"
          [(ngModel)]="model.comment"
          #_comment="ngModel"
          name="comment"
          id="comment"
          rows="8"
          class="form-block__element"
          [ngClass]="{ 'form-block_error': hasError(_comment) }"
          [customMaxlength]="2000"
        ></textarea>
        <p
          class="error"
          *ngIf="hasError(_comment) && _comment.errors.maxlength"
        >
          {{ 'validation.maxlength' | translate }}
        </p>
      </li>
    </ul>

    <!-- Buttons -->
    <footer class="buttons-block modal-content__footer">
      <button
        type="button"
        [disabled]="isSavingModel"
        (click)="cancel()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>

      <button
        [disabled]="isSavingModel"
        class="btn action-btn action-btn_success"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Submit</span>
      </button>
    </footer>
  </form>
</section>
