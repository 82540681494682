<!--WO Toolbar-->
<div
  *ngIf="isSeparateList || isAssetList"
  class="toolbar__panel toolbar__panel_not-bordered flex"
>
  <app-table-search
    [keyword]="keyword"
    (onSearch)="search($event, listId)"
  ></app-table-search>

  <!-- WO Filters -->
  <div *ngIf="!isAssetList" class="el-in-a-row el-in-a-row_c">
    <label>
      <app-select
        [(ngModel)]="filter.assignee"
        (ngModelChange)="applyFilters(filterDTO)"
        name="dt_basic_length"
        optValue="full_name"
        placeholder="Any Assignee"
        [values]="filterDropdowns.users"
      >
      </app-select>
    </label>
    <label>
      <app-select
        [(ngModel)]="filter.status"
        (ngModelChange)="loadList()"
        name="dt_basic_length"
        optKey="key"
        placeholder="Any Status"
        [values]="filterDropdowns.statuses"
      >
      </app-select>
    </label>
  </div>
  <!--END WO Filters -->
</div>
<!--END WO Toolbar-->
<table
  id="work_orders_list_table_id"
  class="table table-striped"
  [ngClass]="{ table_bordered: !isSeparateList && !isAssetList }"
>
  <caption style="display: none">
    Work Orders List Information
  </caption>
  <thead class="table__header">
    <tr *ngIf="!isSeparateList && !isAssetList">
      <th id="separate_list_table_id">Work Order</th>
      <th
        id="asset_name_id"
        [ngClass]="getSortingCellClass('asset.name')"
        (click)="setSortBy('asset.name')"
        class="table__sorting-column"
      >
        {{ 'asset_name' | translate }}
      </th>
      <th id="assignee_header_id">Assignee</th>
      <th id="due_date_header_id">Due Date</th>
      <th id="completion_date_header_id">Completion Date</th>
      <th id="status_header_id">Status</th>
    </tr>
    <tr *ngIf="isSeparateList || isAssetList">
      <th
        scope="col"
        [ngClass]="getSortingCellClass('number')"
        (click)="setSortBy('number')"
        class="table__sorting-column"
      >
        Work Order
      </th>
      <th id="asset_list_header_id" *ngIf="!isAssetList">
        {{ 'asset_name' | translate }}
      </th>
      <th
        id="pmpe_number_header_id"
        *ngIf="isAssetList"
        [ngClass]="getSortingCellClass('pmpe_number')"
        (click)="setSortBy('pmpe_number')"
        class="table__sorting-column"
      >
        PM/PE Number
      </th>
      <th
        scope="col"
        [ngClass]="getSortingCellClass('user.name')"
        (click)="setSortBy('user.name')"
        class="table__sorting-column"
      >
        Assignee
      </th>
      <th
        scope="col"
        [ngClass]="getSortingCellClass('end_date')"
        (click)="setSortBy('end_date')"
        class="table__sorting-column"
      >
        Due Date
      </th>
      <th
        scope="col"
        [ngClass]="getSortingCellClass('created_at')"
        (click)="setSortBy('created_at')"
        class="table__sorting-column"
      >
        Completion Date
      </th>
      <th
        scope="col"
        [ngClass]="getSortingCellClass('status')"
        (click)="setSortBy('status')"
        class="table__sorting-column"
      >
        Status
      </th>
    </tr>
  </thead>
  <tbody>
    <!--Loader-->
    <app-table-loader
      [colspan]="6"
      [isLoading]="isLoading"
      [items]="items"
    ></app-table-loader>
    <!--END Loader-->
    <ng-container *ngIf="!isLoading">
      <tr *ngFor="let item of items">
        <!--1 Work Order-->
        <td>
          <!--NO Report & USER IS performer-->
          <a
            *ngIf="!item.report_id && canUserSeeWorkOrder(item)"
            [routerLink]="[
              '/',
              organization.id_hash,
              'compliance',
              isAssetList ? item.pmpe.id : event.id,
              item.id
            ]"
            class="link text-semibold"
            >{{ item.number }}</a
          >
          <!--NO Report & USER IS NOT performer-->
          <span *ngIf="!item.report_id && canUserSeeWorkOrder(item) == false">
            {{ item.number }}
          </span>
          <!--HAS Report-->
          <a
            *ngIf="item.report_id"
            [routerLink]="[
              '/',
              organization.id_hash,
              'compliance',
              'reports',
              item.report_id
            ]"
            class="link text-semibold"
            >{{ item.number }}</a
          >
        </td>
        <!--END Work Order-->
        <!--2 Asset Name OR PMPE Number-->
        <td [ngSwitch]="true">
          <!--NO Asset Details-->
          <a
            *ngSwitchCase="!isAssetList && item.asset && !item.asset?.deleted"
            [routerLink]="['/', organization.id_hash, 'assets', item.asset.id]"
            class="link text-semibold"
          >
            {{ item.asset.name || item.asset.title }}
          </a>
          <!--IN Asset Details-->
          <ng-container *ngSwitchCase="isAssetList && !item.asset?.deleted">{{
            item.pmpe.number
          }}</ng-container>
          <ng-container *ngSwitchCase="item.asset?.deleted">{{
            item.asset.name || item.asset.title
          }}</ng-container>
        </td>
        <!--END Asset Name OR PMPE Number-->
        <!--3 Assignee-->
        <td>
          <app-user-link
            [userId]="item.assignee.id"
            [userName]="item.assignee.name"
            [isActive]="item.assignee.is_active"
          ></app-user-link>
        </td>
        <!--END Assignee-->

        <!--4 Due Date-->
        <td class="text-center">{{ item.end_date | dateFormat | text }}</td>
        <!--END Due Date-->

        <!--5 Completion Date-->
        <td class="text-center">
          {{ item.report_created_at | dateFormat | text }}
        </td>
        <!--END Completion Date-->

        <!--6 Status-->
        <td class="compliance-status">
          <div
            class="el-in-a-row el-in-a-row_c compliance-status-icon compliance-status-icon_{{
              store.getIcon(item.status, 'workorder')[0]
            }}"
          >
            <app-svg
              [href]="store.getIcon(item.status, 'workorder')[0]"
            ></app-svg>
            <span>{{ store.getIcon(item.status, 'workorder')[1] }}</span>
          </div>
        </td>
        <!--END Status-->
      </tr>
    </ng-container>
  </tbody>
</table>
