<div class="modal-content__wrapper" [hidden]="status !== 'static'">
  <header>
    <h2 class="modal-content__header">{{ header }}</h2>
  </header>

  <form [formGroup]="form" (ngSubmit)="saveAction()">
    <div
      class="modal-content__body modal-content__body_without-form el-in-a-row el-in-a-row_c el-in-a-row_nowrap el-in-a-row_lg"
    >
      <label>Period</label>
      <div class="el-in-a-row el-in-a-row_nowrap el-in-a-row_c">
        <mat-form-field
          class="datepicker-block"
          [ngClass]="{ 'form-block_error': hasError('date_from') }"
        >
          <input
            type="text"
            name="dateFrom"
            placeholder="From"
            formControlName="date_from"
            matInput
            [max]="form.value['date_to'] || 'now' | dateFormat: 'ISO'"
            [matDatepicker]="_dateFrom"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('date_from')"
            [tooltip]="getErrorText('date_from', 'Start Date')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
          <mat-datepicker-toggle matSuffix [for]="_dateFrom">
            <mat-icon matDatepickerToggleIcon>
              <app-svg [href]="'datepicker-calendar'"></app-svg>
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #_dateFrom></mat-datepicker>
        </mat-form-field>
        <span>-</span>
        <mat-form-field
          class="datepicker-block"
          [ngClass]="{ 'form-block_error': hasError('date_to') }"
        >
          <input
            type="text"
            name="dateTo"
            placeholder="To"
            formControlName="date_to"
            matInput
            [matDatepicker]="_dateTo"
            [min]="minDate"
            [max]="'now' | dateFormat: 'ISO'"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('date_to')"
            [tooltip]="getErrorText('date_to', 'End Date')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
          <mat-datepicker-toggle matSuffix [for]="_dateTo">
            <mat-icon matDatepickerToggleIcon>
              <app-svg [href]="'datepicker-calendar'"></app-svg>
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #_dateTo></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="buttons-block modal-content__footer">
      <button
        type="button"
        (click)="close()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>
      <button class="btn action-btn action-btn_success">
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Export</span>
      </button>
    </div>
  </form>
</div>

<app-export-modal
  *ngIf="status === 'in-progress'"
  [type]="type"
  [orgId]="orgId"
  [exportAddOns]="params"
>
</app-export-modal>
