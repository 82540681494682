import { Action, State, StateContext, StateToken } from '@ngxs/store';

import { Filters } from '../actions/filters.action';
import { Injectable } from '@angular/core';

export interface IFiltersStateModel {
  filterList: { [key: string]: { batchId: string; timestamp: number } };
}

export const FILTER_STATE_TOKEN = new StateToken<IFiltersStateModel>(
  'filtersState'
);

@State<IFiltersStateModel>({
  name: FILTER_STATE_TOKEN,
  defaults: {
    filterList: {},
  },
})
@Injectable()
export class FiltersState {
  @Action(Filters.SaveBatchId)
  saveFilterBatchId(
    ctx: StateContext<IFiltersStateModel>,
    { filterType, batchId, timestamp }: Filters.SaveBatchId
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filterList: {
        ...state.filterList,
        [filterType]: {
          batchId,
          timestamp,
        },
      },
    });
  }

  @Action(Filters.ResetState)
  resetState(ctx: StateContext<IFiltersStateModel>) {
    ctx.patchState({ filterList: {} });
  }
}
