import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { UserCurrentService } from '../../core/services/user-current.service';
import { HelperService } from '../../core/services/helper.service';

@Directive({
  selector: '[appPermission]',
})
export class PermissionDirective implements OnInit {
  @Input('appPermission') permission: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private _userCurrent: UserCurrentService
  ) {}

  ngOnInit() {
    this.setVisible();
  }

  private setVisible() {
    if (
      HelperService.hasPermissions(
        this.permission,
        this._userCurrent.user?.role_name
      )
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
