import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { OrganizationCurrentService } from '../../core/services/organization-current.service';
import { Observable } from 'rxjs';
import { RoutesConstants } from '../../core/constants/routes.constants';
import { Settings } from 'luxon';

@Injectable()
export class TenantOrganizationGuard {
  constructor(
    private router: Router,
    private _organizationCurrent: OrganizationCurrentService
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean> | boolean {
    const org_id = next.params['org_id'];
    this._organizationCurrent.tenantKey = org_id;
    this._organizationCurrent.clientKey = null;
    this._organizationCurrent.clean();
    if (org_id) {
      return new Observable<boolean>((observe) => {
        this._organizationCurrent.get(true).subscribe(
          (organization) => {
            Settings.defaultZone = organization.time_zone;
            observe.next(true);
            observe.complete();
          },
          () => {
            this.router.navigate([RoutesConstants.HOME]);
            observe.next(false);
            observe.complete();
          }
        );
      });
    } else {
      this._organizationCurrent.cleanTenant();
    }
    return false;
  }
}
