import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClientAssetTypesService } from '../../../core/services/client-asset-types.service';
import { Component } from '@angular/core';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { EventService } from '../../../core/services/event.service';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { RequestService } from '../../../core/services/request.service';
import { UniversalAssetTypesService } from '../../../core/services/universal-asset-types.service';
import { tap } from 'rxjs';

const serviceFactory = (
  org: OrganizationCurrentService,
  requestService: RequestService
) =>
  org.organization
    ? new ClientAssetTypesService(requestService)
    : new UniversalAssetTypesService(requestService);

@Component({
  templateUrl: '../delete-modal/delete-modal.component.html',
  providers: [
    {
      provide: UniversalAssetTypesService,
      useFactory: serviceFactory,
      deps: [OrganizationCurrentService, RequestService],
    },
  ],
})
export class UARDeleteModalComponent extends DeleteModalComponent {

  constructor(
    protected _assetTypesService: UniversalAssetTypesService,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    public activeModal: BsModalRef
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  ngOnInit() {
    // Setup
    this.isLoading = true;
    this.subscriptions.add(
      this._assetTypesService
        .getRepositoryDependencies(this.assetType.id)
        .pipe(tap(() => (this.isLoading = false)))
        .subscribe({
          next: (success) => (this.dependencies = success),
          error: (error) => this._notificationService.apiError(error),
        })
    );
  }

  public submit() {
    this.hasSubmitted = true;
    if (this.tdForm.invalid) {
      return;
    }
    super.submit();
  }
}
