import { Component, Input, ViewChild } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-confirmation-with-input-modal',
  templateUrl: './confirmation-with-input-modal.component.html',
})
// to be done extend all confirm modals with keyword
export class ConfirmationWithInputModalComponent extends ConfirmationModalComponent {
  @ViewChild('confirmForm') declare tdForm: NgForm;

  @Input() keyword: string = 'delete';

  public userInput: string;

  constructor(
    public activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  public submit(): void {
    this.markFormGroupTouched();
    this.hasSubmitted = true;
    if (this.tdForm.valid) {
      super.submit();
    }
  }
}
