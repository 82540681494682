<div class="chart-star-component flex-component">
  <canvas #elStarChart [id]="canvasId" class="chart-star"></canvas>
  <div class="text-center download-btn_wrapper">
    <button
      (click)="downloadImage()"
      class="btn action-btn action-btn_default"
      type="button"
    >
      <span class="action-btn__icon"
        ><app-svg [href]="'download'" iconClass="icon icon_s"></app-svg
      ></span>
      <span class="action-btn__title">Download</span>
    </button>
  </div>
</div>
