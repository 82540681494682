import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { Page404Component } from './shared/components/page-404/page-404.component';
import { Page403Component } from './shared/components/page-403/page-403.component';
import { SwaggerComponent } from './shared/components/swagger/swagger.component';
import { UpdateDetailsComponent } from './modules/auth/components/update-details/update-details.component';
import { ForbiddenComponent } from './shared/components/forbidden/forbidden.component';
import { Page500Component } from './shared/components/page-500/page-500.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '404',
    component: Page404Component,
  },
  {
    path: '403',
    component: Page403Component,
  },
  {
    path: '500',
    component: Page500Component,
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'swagger',
    component: SwaggerComponent,
  },
  {
    path: 'update',
    component: UpdateDetailsComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'api/swagger',
    component: SwaggerComponent,
  },
  {
    path: '**',
    component: Page404Component,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
