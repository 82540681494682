import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhonePipe implements PipeTransform {
  public transform(phone): string {
    if (!phone) {
      return '';
    }

    if (phone.charAt(0) === '+') {
      return phone;
    }

    return '+' + phone;
  }
}
