import { Directive, HostListener, Input } from '@angular/core';
import { RequestService } from '../../core/services/request.service';
import { CustomNotificationService } from '../../core/services/custom-notification.service';
import { UploadResourceService } from '../../core/services/upload-resource.service';

@Directive({
  selector: '[appDownloadLink]',
})
export class DownloadLinkDirective {
  @Input('appDownloadLink') downloadLink: string;
  @Input() filePrefix: string;
  @Input() type: string;
  @Input() params: any = {};

  constructor(
    private _requestService: RequestService,
    private _notificationService: CustomNotificationService,
    private _uploadResourceService: UploadResourceService
  ) {}

  @HostListener('click') onClick() {
    this._requestService.download(this.downloadLink, this.params).subscribe({
      next: (response) =>
        this._uploadResourceService.exportedDataToDownload(
          response,
          this.filePrefix,
          this.type
        ),
      error: (err) => this._notificationService.apiError(err),
    });
  }
}
