import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationCurrentService } from '../../core/services/organization-current.service';

@Injectable()
export class WorkOrderGuard {
  constructor(
    private router: Router,
    private _organizationCurrent: OrganizationCurrentService
  ) {}

  canActivate(): boolean {
    const enableWorkOrder =
      this._organizationCurrent?.organization?.pm_schedule;

    if (!enableWorkOrder) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
