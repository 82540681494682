import { DefaultModel } from './default.model';
import { FileImportDTO } from '../dto/file-import.dto';

export interface CsvColumn {
  title: string;
  checked: boolean;
  id?: string | number;
}

export class FileImportModel extends DefaultModel<FileImportDTO> {
  file_exp_days: number;
  system_ftp: boolean;
  name?: string;
  protocol?: string;
  host?: number;
  port?: number;
  paths?: {
    errors?: string;
    files?: string;
    processed?: string;
    new_assets?: string;
    images?: string;
    processed_images?: string;
    errors_images?: string;
  };
  test_connection?: number;
  test_connection_errors?: string;
  emails?: string[];
  import_at?: Date;
  checked_at?: Date;
  id?: number;
  user_name?: string;
  password?: string;
  reset_password?: boolean;
  csv_columns_list: {
    assets?: {
      selected_groups?: Array<string>;
      general_columns?: Array<CsvColumn>;
      risks?: Array<CsvColumn>;
      udfs?: Array<CsvColumn>;
    };
  };
  ssh_key?: string;
  ssh_key_updated_at?: string;

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
    }

    this.emails ||= [];

    if (!this.paths) {
      this.paths = {};
    }
  }

  public get entity_name(): string {
    return 'ftp';
  }

  public dto(): FileImportDTO {
    return new FileImportDTO(this);
  }

  public toString(): string {
    return this.name;
  }
}
