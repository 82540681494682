import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../base-modal.component';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';

declare const tableau: any;

@Component({
  templateUrl: './preview-modal.component.html',
})
export class PreviewModalComponent extends BaseModalComponent {
  @Input() public uri: string;
  @Input() public submitText: string = 'Ok';

  vizUrl: string;
  viz: any;
  window: any;
  containerDiv: any;

  constructor(
    public activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  public ngOnInit() {
    this.containerDiv = document.getElementById('vizContainer');

    if (this.containerDiv) {
      this.initVizWithTicket();
    }
  }

  public initVizWithTicket() {
    this.vizUrl = this.uri;
    const containerDiv = this.containerDiv,
      url = this.vizUrl,
      options = {
        hideTabs: true,
      };
    this.viz = new tableau.Viz(containerDiv, url, options);
  }
}
