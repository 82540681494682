import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { services } from './services';
import { HttpClientModule } from '@angular/common/http';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ColorPickerModule } from 'ngx-color-picker';
import { SnotifyModule } from 'ng-alt-snotify';
import { DefaultSnotifyOptions } from './constants/snotify.constants';
import { DefaultTooltipOptions } from './constants/tooltip.constants';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    ColorPickerModule,
    SnotifyModule,
    TranslateModule.forChild(),
  ],
  providers: [
    ...services,
    { provide: 'SnotifyToastConfig', useValue: DefaultSnotifyOptions },
    { provide: TooltipConfig, useValue: DefaultTooltipOptions },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
