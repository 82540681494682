import { Component, Input } from '@angular/core';
import { ExportService } from '../../../core/services/export.service';
import { IExportParams } from '../../../core/models/export-params.model';
import { AutoUnsubscribeService } from '../../../core/services/auto-unsubscribe.service';
import { takeUntil } from 'rxjs/operators';
import { BaseReactiveForm } from '../../components/base-reactive-form';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { FormBuilder } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ErrorService } from '../../../core/services/error.service';
import { BgJobService } from '../../../core/services/bg-worker.service';
import { ErrorModel } from '../../../core/models/error.model';
import { IBgJobModel } from '../../../core/models/bg-worker.model';
import { DateTime } from 'luxon';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import '../../../shared/prototypes/string.prototypes';
@Component({
  selector: 'app-export-modal',
  templateUrl: './export.modal.component.html',
})
export class ExportModalComponent extends BaseReactiveForm<any> {
  @Input() public exportAddOns: IExportParams;
  @Input() public orgId: string;
  @Input() public type: string;
  @Input() public successMessage: string;

  public action: string = 'export';
  public exportFilesArr: { url: string; filename: string }[] = [];
  public header: string = 'Export';
  public jobModel: IBgJobModel;
  public status:
    | 'static'
    | 'loading'
    | 'progress'
    | 'success'
    | 'warning'
    | 'error'
    | 'systemError';
  public errorListMsg =
    'Export aborted due to the errors listed below. Please try again. Should the problem persist, contact the technical support.';
  public linksAreExpired: boolean;
  protected timer: any;

  private linksExpiredAt: DateTime;

  public get exportName(): string {
    return this.jobModel?.name.split(/(?=[A-Z])|_/).join(' ');
  }

  protected get params(): IExportParams {
    return this.exportAddOns;
  }

  constructor(
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected formBuilder: FormBuilder,
    protected _modalService: BsModalService,
    protected _errorService: ErrorService,
    protected _destroy: AutoUnsubscribeService,
    protected _bgJobService: BgJobService<ErrorModel>,
    protected _exportService?: ExportService,
    protected _featureFlagsService?: FeatureFlagsService
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      _modalService,
      _errorService
    );
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.startExport();
  }

  ngOnDestroy() {
    this.close();
  }

  public close() {
    // if not success
    clearTimeout(this.timer);
    if (this.jobModel && this.jobModel?.status_code !== 30) {
      this._bgJobService
        .cancelBgJob(this.jobModel.job_uuid, this.orgId)
        .subscribe({
          next: () => {},
          error: (err) => this._notificationService.apiError(err),
        });
    }
    super.close();
  }

  protected startExport() {
    this.status = 'loading';
    // WORKER_NAME_EXPORT_FILE      = :forecast_export
    this._exportService
      .export(this.type, this.params)
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: (res) => {
          this.jobModel = res;
          this.checkProgress();
        },
        error: () => this.onErrorOccurs(),
      });
  }

  protected checkProgress(): void {
    this._bgJobService
      .checkBgJobStatus(this.jobModel.job_uuid, this.orgId)
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: (item) => this.handleProgress(item),
        error: (error) => {
          if (
            error.status == '401' &&
            this._featureFlagsService.isFeatureEnabled('OR_12510_Auth0_Login')
          ) {
            setTimeout(() => this.handleProgress(this.jobModel), 1000);
          } else {
            this.onErrorOccurs();
          }
        },
      });
  }

  protected handleProgress(item: IBgJobModel) {
    this.jobModel = item;
    switch (true) {
      case this._bgJobService.hasInProgressStatus(item):
        this.handleInProgressStatus();
        break;
      case this._bgJobService.hasSuccessStatus(item):
        this.handleSuccessStatus();
        break;
      case this._bgJobService.hasErrorStatus(item):
        this.header = 'Error';
        this.status = 'error';
        break;
      case this._bgJobService.hasWarningStatus(item):
        this.handleWarningStatus();
        break;
      case this._bgJobService.hasSystemError(item):
        this.onErrorOccurs();
        break;
      default:
        this.close();
    }
  }

  protected handleInProgressStatus() {
    this.status = 'progress';
    this.timer = setTimeout(() => this.checkProgress(), 1000);
  }

  protected handleSuccessStatus() {
    this.exportFilesArr = this.linksToFiles();
    this.linksExpiredAt = DateTime.now().plus({ minutes: 10 });
    this.checkIfLinksAreExpired();
    this.status = 'success';
  }

  protected handleWarningStatus() {
    this.status = 'warning';
  }

  private checkIfLinksAreExpired(): void {
    if (DateTime.now() >= this.linksExpiredAt) {
      this.linksAreExpired = true;
    } else {
      this.timer = setTimeout(() => this.checkIfLinksAreExpired(), 500);
    }
  }

  private linksToFiles(): { url: string; filename: string }[] {
    if (!this.jobModel.output_file_url) {
      return [];
    }
    let fileUrls = this.jobModel.output_file_url;
    let outputObjKeys = this.jobModel.details.output_object_key;

    if (typeof fileUrls === 'string') {
      fileUrls = [fileUrls];
    }

    if (typeof outputObjKeys === 'string') {
      outputObjKeys = [outputObjKeys];
    }

    return fileUrls.map((url, index) => ({
      url,
      filename: outputObjKeys[index].substring(
        outputObjKeys[index].lastIndexOf('/') + 1
      ),
    }));
  }

  protected onErrorOccurs() {
    this.status = 'systemError';
    this.header = 'Error';
    this.messagesArr = [
      `${this.action.capitalize()} failed.`,
      'Please try again. Should the problem persist, contact the technical support.',
    ];
    this.cancelText = 'Ok';
    this.cancelIcon = 'check';
  }
}
