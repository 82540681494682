<section
  [ngClass]="{ 'modal-content__wrapper modal-content__500': !tableView }"
>
  <header *ngIf="!tableView" class="modal-content__header">{{ header }}</header>

  <section
    [ngClass]="{
      'modal-content__body modal-content__body_without-form': !tableView
    }"
  >
    <header class="flex flex_j-center">
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-group__btn"
          [ngClass]="{ active: activeTab === 'errors' }"
          [disabled]="!issues.errors?.length"
          (click)="activeTab = 'errors'"
        >
          Errors
        </button>
        <button
          type="button"
          class="btn btn-group__btn"
          [ngClass]="{ active: activeTab === 'warnings' }"
          (click)="activeTab = 'warnings'"
          [disabled]="!issues.warnings?.length"
        >
          Warnings
        </button>
      </div>
    </header>

    <ng-container *ngIf="activeTab === 'errors'">
      <p class="form-error standalone">{{ message }}</p>
      <app-issue-table
        [entity]="activeTab"
        [entityColumnName]="entityColumnName"
        [orgId]="orgId"
        [issues]="issues"
        [uuid]="jobUuid"
      >
      </app-issue-table>
    </ng-container>

    <ng-container *ngIf="activeTab === 'warnings'">
      <p class="form-warning standalone">Import warnings</p>
      <app-issue-table
        [entity]="activeTab"
        [entityColumnName]="entityColumnName"
        [orgId]="orgId"
        [issues]="issues"
        [uuid]="jobUuid"
      >
      </app-issue-table>
    </ng-container>
  </section>

  <section
    *ngIf="!tableView"
    class="modal-content__footer buttons-block buttons-block_to-center"
  >
    <button
      type="button"
      (click)="cancel()"
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">Close</span>
    </button>
  </section>
</section>
