import { Component } from '@angular/core';
import { HelperService } from 'src/app/core/services/helper.service';
@Component({
  selector: 'app-custom-percent-cell-renderer',
  template: `
    <span [innerHTML]="highlightText(transform(params.value, params.returnZero, params.returnDash))"></span>
  `,
})
export class CustomPercentCellRendererComponent {
  public params: any;

  public transform(
    value: number | string,
    returnZero = true,
    returnDash = false,
    round: boolean = true,
  ): string {
    if (!HelperService.isExist(value)) {
      return returnZero ? '0%' : returnDash ? '\u2014' : '';
    }
    return (round ? Math.floor(+value) : HelperService.toFixed(value, 2)) + '%';
  }

  agInit(params: any): void {
    this.params = params;
  }

  highlightText(value: string): string {
    if (!this.params.searchText) {
      return value;
    }
    const searchText = this.params.searchText;
    const regex = new RegExp(`(${searchText})`, 'gi');
    const str = String(value);
    return str.replace(regex, `<span class="ag-grid-text-highlight">$1</span>`);
  }
}
