// ta - tenant admin
// tu - tenant user
// te - tenant engineer
// ca - client admin
// cu - client user
// ce - client engineer
// ts - tenant sale
// pa - partner admin
// pe - partner engineer

export const PERMISSIONS: { [key: string]: Array<string> } = {
  ta: ['tenant_admin'],
  tu: ['tenant_user'],
  te: ['tenant_engineer'],
  ca: ['client_admin'],
  cu: ['client_user'],
  ce: ['client_engineer'],
  ts: ['tenant_sale'],
  pa: ['partner_admin'],
  pe: ['partner_engineer'],

  ta_tu: ['tenant_admin', 'tenant_user'],
  ta_pa: ['tenant_admin', 'partner_admin'],
  ta_te: ['tenant_admin', 'tenant_engineer'],
  ta_ca: ['tenant_admin', 'client_admin'],
  ta_cu: ['tenant_admin', 'client_user'],
  ta_ce: ['tenant_admin', 'client_engineer'],
  tu_te: ['tenant_user', 'tenant_engineer'],
  tu_ca: ['tenant_user', 'client_admin'],
  tu_cu: ['tenant_user', 'client_user'],
  tu_ce: ['tenant_user', 'client_engineer'],
  te_ca: ['tenant_engineer', 'client_admin'],
  te_cu: ['tenant_engineer', 'client_user'],
  te_ce: ['tenant_engineer', 'client_engineer'],
  ca_cu: ['client_admin', 'client_user'],
  ca_ce: ['client_admin', 'client_engineer'],
  cu_ce: ['client_user', 'client_engineer'],

  ta_tu_te: ['tenant_admin', 'tenant_user', 'tenant_engineer'],
  ta_tu_pa: ['tenant_admin', 'tenant_user', 'partner_admin'],
  ta_tu_ca: ['tenant_admin', 'tenant_user', 'client_admin'],
  ta_tu_cu: ['tenant_admin', 'tenant_user', 'client_user'],
  ta_tu_ce: ['tenant_admin', 'tenant_user', 'client_engineer'],
  ta_te_ca: ['tenant_admin', 'tenant_engineer', 'client_admin'],
  ta_te_cu: ['tenant_admin', 'tenant_engineer', 'client_user'],
  ta_te_ce: ['tenant_admin', 'tenant_engineer', 'client_engineer'],
  ta_ca_cu: ['tenant_admin', 'client_admin', 'client_user'],
  ta_ca_ce: ['tenant_admin', 'client_admin', 'client_engineer'],
  ta_cu_ce: ['tenant_admin', 'client_user', 'client_engineer'],
  tu_te_ca: ['tenant_user', 'tenant_engineer', 'client_admin'],
  tu_te_cu: ['tenant_user', 'tenant_engineer', 'client_user'],
  tu_te_ce: ['tenant_user', 'tenant_engineer', 'client_engineer'],
  te_ca_cu: ['tenant_engineer', 'client_admin', 'client_user'],
  te_ca_ce: ['tenant_engineer', 'client_admin', 'client_engineer'],
  te_cu_ce: ['tenant_engineer', 'client_user', 'client_engineer'],
  ca_cu_ce: ['client_admin', 'client_user', 'client_engineer'],
  ta_ca_pa: ['tenant_admin', 'client_admin', 'partner_admin'],
  tu_ca_pa: ['tenant_user', 'client_admin', 'partner_admin'],
  cu_ce_pe: ['client_user', 'client_engineer', 'partner_engineer'],

  ca_cu_ce_pe_pa: [
    'client_admin',
    'client_user',
    'client_engineer',
    'partner_engineer',
    'partner_admin',
  ],

  ta_tu_te_ts: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'tenant_sale',
  ],
  ta_tu_te_ca: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_admin',
  ],
  ta_tu_te_cu: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_user',
  ],
  ta_tu_te_ce: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_engineer',
  ],
  ta_tu_cu_ce: [
    'tenant_admin',
    'tenant_user',
    'client_user',
    'client_engineer',
  ],
  ta_te_ca_cu: [
    'tenant_admin',
    'tenant_engineer',
    'client_admin',
    'client_engineer',
  ],
  ta_te_cu_ce: [
    'tenant_admin',
    'tenant_engineer',
    'client_user',
    'client_engineer',
  ],
  ta_te_ca_ce: [
    'tenant_admin',
    'tenant_engineer',
    'client_admin',
    'client_engineer',
  ],
  tu_te_ca_cu: [
    'tenant_user',
    'tenant_engineer',
    'client_admin',
    'client_user',
  ],
  tu_te_ca_ce: [
    'tenant_user',
    'tenant_engineer',
    'client_admin',
    'client_engineer',
  ],
  tu_te_cu_ce: [
    'tenant_user',
    'tenant_engineer',
    'client_user',
    'client_engineer',
  ],
  tu_ca_cu_ce: [
    'tenant_user',
    'client_admin',
    'client_user',
    'client_engineer',
  ],
  te_ca_cu_ce: [
    'tenant_engineer',
    'client_admin',
    'client_user',
    'client_engineer',
  ],
  ta_tu_te_pa_pe: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'partner_admin',
    'partner_engineer',
  ],
  ta_tu_ca_pa: ['tenant_admin', 'tenant_user', 'client_admin', 'partner_admin'],
  ta_te_ca_pa: [
    'tenant_admin',
    'tenant_engineer',
    'client_admin',
    'partner_admin',
  ],
  ta_te_ca_pa_ts: [
    'tenant_admin',
    'tenant_engineer',
    'client_admin',
    'partner_admin',
    'tenant_sale',
  ],
  te_cu_ce_pe: [
    'tenant_engineer',
    'client_user',
    'client_engineer',
    'partner_engineer',
  ],
  te_ca_ce_pe: [
    'tenant_engineer',
    'client_admin',
    'client_engineer',
    'partner_engineer',
  ],
  ta_tu_te_ca_cu: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_admin',
    'client_user',
  ],
  ta_tu_te_ca_ce: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_admin',
    'client_engineer',
  ],
  ta_tu_te_cu_ce: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_user',
    'client_engineer',
  ],
  ta_tu_ca_cu_ce: [
    'tenant_admin',
    'tenant_user',
    'client_admin',
    'client_user',
    'client_engineer',
  ],
  ta_te_ca_cu_ce: [
    'tenant_admin',
    'tenant_engineer',
    'client_admin',
    'client_user',
    'client_engineer',
  ],
  tu_te_ca_cu_ce: [
    'tenant_user',
    'tenant_engineer',
    'client_admin',
    'client_user',
    'client_engineer',
  ],
  ta_tu_te_ts_pa: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'tenant_sale',
    'partner_admin',
  ],
  ta_tu_te_ca_pa: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_admin',
    'partner_admin',
  ],
  ta_tu_te_ce_pe: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_engineer',
    'partner_engineer',
  ],

  ta_tu_te_ts_pa_pe: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'tenant_sale',
    'partner_admin',
    'partner_engineer',
  ],

  ta_tu_te_ca_cu_ce: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_admin',
    'client_user',
    'client_engineer',
  ],
  ta_tu_te_ca_cu_ce_ts: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_admin',
    'client_user',
    'client_engineer',
    'tenant_sale',
  ],
  ta_tu_te_ca_ce_pa_pe: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_admin',
    'client_engineer',
    'partner_admin',
    'partner_engineer',
  ],

  ta_tu_te_ca_cu_ce_pa_pe: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_admin',
    'client_user',
    'client_engineer',
    'partner_admin',
    'partner_engineer',
  ],
  ta_tu_te_ca_cu_ce_ts_pa_pe: [
    'tenant_admin',
    'tenant_user',
    'tenant_engineer',
    'client_admin',
    'client_user',
    'client_engineer',
    'tenant_sale',
    'partner_admin',
    'partner_engineer',
  ],
};
