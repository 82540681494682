import { Pipe, PipeTransform } from '@angular/core';

import { HelperService } from '../../core/services/helper.service';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  transform(value: number | string, withCents?: boolean): string {
    if (isNaN(+value) || !HelperService.isExist(value)) {
      return '\u2014';
    }
    let result = '$';
    if (withCents) {
      result += HelperService.parseMoney(Math.abs(+value));
    } else {
      result += HelperService.parseMoneyInteger(Math.abs(+value));
    }
    if (+value < 0) {
      result = '-' + result;
    }
    return result.trim();
  }
}
