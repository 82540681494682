import { BaseStoreService } from './base-store.service';
import { GenericAssetModel } from '../models/generic-asset.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectAssetModel } from '../models/project-asset.model';
import { RequestService } from './request.service';

@Injectable()
export class ProjectAssetsService<M> extends BaseStoreService<M> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, ProjectAssetModel);
    this.resourceName = 'v1/assets';
  }

  public delete(id, type): Observable<any> {
    return super.delete(id, type);
  }

  public update(model, resource, params?: any): Observable<any> {
    return super.update(model, resource, params);
  }

  public addGenericAsset(
    body: { asset: GenericAssetModel; period: number },
    id: number
  ): Observable<any> {
    return this._requestService.post(`/v1/projects/${id}/assets`, body);
  }

  public updateAsset(
    asset: any,
    id: number,
    assetId: number,
    isObjectFormat?: boolean
  ): Observable<any> {
    let requestBody = isObjectFormat ? asset : { asset };

    return this._requestService.put(
      `/v1/projects/${id}/assets/${assetId}`,
      requestBody
    );
  }
  public updateAssetComment(
    asset: any,
    id: number,
    assetId: number | string
  ): Observable<any> {
    return this._requestService.put(
      `/v1/projects/${id}/assets/${assetId}/comment`,
      { asset }
    );
  }

  public deleteAssetsFromProject(
    asset_ids: number[],
    id: number
  ): Observable<any> {
    return this._requestService.delete(
      `/v1/projects/${id}/assets`,
      { asset_ids },
      this.headers
    );
  }
}
