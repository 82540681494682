<section
  class="modal-content__wrapper model_min_height"
  *ngIf="
    this._featureFlagsService.isFeatureEnabled('OR_12876_project_attributes')
  "
>
  <header class="project-modal step_header">
    <h2 class="modal-content__header header_border">
      <img
        *ngIf="next && !message"
        class="arrow_button"
        (click)="next = false"
        alt=""
        src="./../../../../../assets/img/arrow_back_ios.svg"
        [width]="18"
        [height]="18"
      />
      {{ header }}
    </h2>
    <div *ngIf="!message" class="steps">Step {{ next ? '2' : '1' }} of 2</div>
  </header>

  <div class="text-center modal-content__body" *ngIf="isLoading">
    <p class="modal-content__message mrgn-b_sm">Please, wait...</p>
    <app-loader></app-loader>
  </div>

  <form
    [formGroup]="form"
    (ngSubmit)="submit()"
    [hidden]="isLoading"
    (keydown.enter)="$event.preventDefault()"
  >
    <section class="modal-content__body" *ngIf="apiErrors.length">
      <p
        *ngFor="let error of apiErrors"
        class="modal-content__message mrgn-b_sm"
        [innerHTML]="error.message"
      ></p>
    </section>

    <section class="modal-content__body" *ngIf="message">
      <p class="modal-content__message" [innerHTML]="message"></p>
      <ng-container *ngIf="data && data.warnings">
        <p
          *ngFor="let warning of data.warnings"
          class="modal-content__message error"
        >
          {{ warning.message }}
        </p>
      </ng-container>
    </section>

    <ul
      class="modal-content__list modal-content__body"
      *ngIf="!message && !apiErrors.length"
    >
      <ng-container *ngIf="!next">
        <li
          class="modal-content__item modal-content__item_full-width"
          *ngIf="!existedProject && !createProject"
        >
          <label for="projectId" class="modal-content__label"
            >Select a Project<span class="asterisk red">&ast;</span></label
          >
          <div class="form-block form-block_lg">
            <app-multiselect
              formControlName="projectId"
              [key]="'id'"
              [as]="'name'"
              [placeholder]="'Select a Project'"
              [id]="'projectId'"
              [singleValueSelected]="true"
              [returnSingleValueAsNumber]="true"
              [values]="projects"
              [ngClass]="{ 'form-block_error': hasError('projectId') }"
              id="projectId"
              class="form-block form-block_full-width"
            >
            </app-multiselect>
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('projectId')"
              [tooltip]="getErrorText('projectId')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
          <p
            *ngIf="forecastData"
            class="tip modal-content__item modal-content__item_full-width"
          >
            <span class="asterisk">*</span>&nbsp;Assets from the Forecast that
            match the end of life determination with projects can be added to an
            existing project. Non-matching projects are not displayed in the
            list.
          </p>
        </li>

        <li
          *ngIf="newProjectFromForecast"
          formGroupName="createNewProject"
          class="modal-content__item modal-content__item_full-width"
        >
          <div>
            <div
              class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap el-in-a-row_lg mrgn-b_sm"
            >
              <label class="fixed-width-80">
                <span class="no-wrap">
                  {{ 'start_year' | translate }}
                </span>
              </label>

              <label class="xs-100">
                <app-select
                  (ngModelChange)="createPeriodList()"
                  formControlName="period"
                  [ngClass]="{ 'form-block_error': hasError('period') }"
                  [showPlaceholder]="false"
                  [values]="periodList"
                >
                </app-select>
                <p class="error" *ngIf="hasError('period')">
                  {{ getErrorText('period') }}
                </p>
              </label>
            </div>

            <div
              class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap el-in-a-row_lg mrgn-b_sm"
            >
              <label class="fixed-width-80">
                <span class="no-wrap">
                  {{ 'end_year' | translate }}
                </span>
              </label>

              <label class="xs-100">
                <app-select
                  formControlName="end_period"
                  [ngClass]="{ 'form-block_error': hasError('end_period') }"
                  [showPlaceholder]="false"
                  [values]="endPeriods"
                ></app-select>
                <p class="error" *ngIf="hasError('end_period')">
                  {{ getErrorText('end_period') }}
                </p>
              </label>
            </div>
          </div>
        </li>

        <li
          class="modal-content__item modal-content__item_full-width"
          *ngIf="!form?.value.projectId && createProject"
        >
          <label for="name" class="modal-content__label"
            >Project Name<span class="asterisk red">&ast;</span></label
          >
          <div class="form-block form-block_lg">
            <input
              [ngClass]="{ 'form-block_error': hasError('name') }"
              formControlName="name"
              id="name"
              class="form-block__element"
              placeholder="Enter Project Name"
              type="text"
              (ngModelChange)="searchProjectName($event)"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('name')"
              [tooltip]="getErrorText('name')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
        </li>

        <li
          class="modal-content__item modal-content__item_full-width"
          *ngIf="form?.value.projectId && !createProject"
        >
          <label for="projectPeriod" class="modal-content__label">{{
            'fiscal_year' | translate
          }}</label>
          <div class="form-block">
            <app-select
              formControlName="period"
              id="projectPeriod"
              [ngClass]="{ 'form-block_error': hasError('period') }"
              [showPlaceholder]="false"
              [values]="periodList"
            >
            </app-select>

            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('period')"
              [tooltip]="getErrorText('period')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
        </li>

        <li
          class="modal-content__item modal-content__item_full-width padding-bottom-0"
        >
          <label for="name" class="modal-content__label"
            >Project Description</label
          >
          <div class="form-block form-block_lg mrgn-b_xs">
            <input
              [ngClass]="{ 'form-block_error': hasError('description') }"
              formControlName="description"
              id="description"
              class="form-block__element"
              placeholder="Enter Project Description"
              type="text"
              (input)="updateCharacterCount()"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('description')"
              [tooltip]="getErrorText('description')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
          <div class="character-counter text-dark-grey text-right form-block">
            {{ characterCount }}/{{ descriptionLength }}
          </div>
        </li>

        <li class="modal-content__item modal-content__item_full-width">
          <label class="modal-content__label"
            >Project Access<span class="asterisk red">&ast;</span></label
          >
          <div
            class="flex-a-center el-in-a-row el-in-a-row_nowrap el-in-a-row_xl"
          >
            <label class="custom-radiobutton">
              <input
                [value]="true"
                formControlName="public"
                class="custom-radiobutton__input"
                type="radio"
              />
              <span
                class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
              ></span>
              Public
            </label>
            <label class="custom-radiobutton">
              <input
                [value]="false"
                formControlName="public"
                class="custom-radiobutton__input"
                type="radio"
              />
              <span
                class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
              ></span>
              Private
            </label>
          </div>
        </li>

        <li class="modal-content__item modal-content__item_full-width">
          <label class="modal-content__label"
            >Does this project take place in, or affects an occupied space<span
              class="asterisk red"
              >&ast;</span
            ></label
          >
          <div
            class="flex-a-center el-in-a-row el-in-a-row_nowrap el-in-a-row_xl"
          >
            <label class="custom-radiobutton">
              <input
                [value]="true"
                formControlName="is_occupied"
                class="custom-radiobutton__input"
                type="radio"
              />
              <span
                class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
              ></span>
              Yes
            </label>
            <label class="custom-radiobutton">
              <input
                [value]="false"
                formControlName="is_occupied"
                class="custom-radiobutton__input"
                type="radio"
              />
              <span
                class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
              ></span>
              No
            </label>
          </div>
        </li>

        <div class="project-form-divider mrgn-t_xs mrgn-b_sm"></div>
        <li
          class="modal-content__item modal-content__item_full-width padding-top-0"
        >
          <label for="projectPeriod" class="modal-content__label"
            >Project Date</label
          >

          <mat-form-field
            class="daterangepicker-block form-block__element xs-100"
            [ngClass]="{
              'form-block_error':
                hasError('projectDate') || dateRangeInput?.ngControl?.invalid
            }"
          >
            <mat-date-range-input
              [rangePicker]="rangePicker"
              [max]="maxProjectYear"
              formGroupName="projectDate"
              #dateRangeInput
            >
              <input
                matStartDate
                placeholder="MM/DD/YYYY"
                formControlName="project_date_from"
                readonly
              />
              <input
                matEndDate
                placeholder="MM/DD/YYYY"
                formControlName="project_date_to"
                readonly
              />
            </mat-date-range-input>

            <button
              type="button"
              class="btn tooltip-btn_error daterangepicker-tooltip-btn_error"
              *ngIf="
                hasError('projectDate') || dateRangeInput?.ngControl?.invalid
              "
              [tooltip]="
                dateRangeInput?.ngControl?.invalid && !hasError('projectDate')
                  ? 'Start or end date is invalid.'
                  : getErrorText('projectDate')
              "
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>

            <mat-datepicker-toggle
              matIconSuffix
              [for]="rangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button
                  mat-raised-button
                  class="btn action-btn action-btn_default"
                  matDateRangePickerApply
                  (click)="clearSingleDateRange()"
                >
                  Clear
                </button>
                <button
                  mat-raised-button
                  matDateRangePickerCancel
                  class="btn action-btn action-btn_default"
                >
                  Cancel
                </button>
                <button
                  mat-raised-button
                  class="btn action-btn action-btn_success"
                  matDateRangePickerApply
                  (click)="checkSingleDateRange()"
                >
                  Done
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </li>
      </ng-container>

      <ng-container *ngIf="next">
        <li class="modal-content__item modal-content__item_half-width">
          <label for="allocatedById" class="modal-content__label"
            ><span class="flex flex-a-center flex_to-start"
              >Allocated By
            </span></label
          >
          <div class="form-block form-block_lg">
            <app-multiselect
              formControlName="allocated_by"
              [key]="'value'"
              [as]="'name'"
              [placeholder]="'placeholders.select_allocted_by' | translate"
              [id]="'allocatedById'"
              [showSearch]="false"
              [singleValueSelected]="true"
              [returnSingleValueAsNumber]="true"
              [values]="alloctedByOptions"
              [ngClass]="{ 'form-block_error': hasError('allocated_by') }"
              id="allocatedById"
              class="form-block form-block_full-width"
            >
            </app-multiselect>
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('allocated_by')"
              [tooltip]="getErrorText('allocated_by')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
        </li>
        <li
          formArrayName="tag_ids"
          class="modal-content__item modal-content__item_half-width"
          *ngFor="let item of items; let $index = index"
        >
          <label for="projectId" class="modal-content__label"
            ><span class="flex flex-a-center flex_to-start"
              >{{ item.name }}
            </span></label
          >
          <div class="form-block form-block_lg">
            <app-multiselect
              [formControlName]="$index"
              id="tagId"
              [id]="'tagId'"
              [key]="'id'"
              [as]="'name'"
              [placeholder]="' Select '"
              [showSearch]="false"
              [singleValueSelected]="true"
              [returnSingleValueAsNumber]="true"
              [values]="addNoneOption(item.tags)"
              class="form-block form-block_full-width"
            >
            </app-multiselect>
          </div>
        </li>
      </ng-container>
    </ul>
    <section
      class="buttons-block modal-content__footer buttons-block_not-bordered"
      [ngClass]="{
        'buttons-block_to-center': data,
        border_top: !message
      }"
    >
      <button
        type="button"
        (click)="close()"
        class="btn action-btn action-btn_default"
        [ngClass]="{
          btn_min_width: !message
        }"
      >
        <span *ngIf="message" class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{ cancelText }}</span>
      </button>
      <!-- test -->
      <button
        [disabled]="!form?.dirty"
        *ngIf="!message && !next"
        (click)="handleNext()"
        class="btn action-btn action-btn_success btn_min_width"
      >
        <span class="action-btn__title">{{ 'Next' }}</span>
      </button>
      <button
        [disabled]="!form?.dirty"
        *ngIf="!message && next"
        class="btn action-btn action-btn_success btn_min_width"
      >
        <span class="action-btn__icon">
          <app-svg [href]="'check'"></app-svg>
        </span>
        <span class="action-btn__title">{{ submitText }}</span>
      </button>
    </section>
  </form>
</section>

<section
  class="modal-content__wrapper"
  *ngIf="
    !this._featureFlagsService.isFeatureEnabled('OR_12876_project_attributes')
  "
>
  <header>
    <h2 class="modal-content__header project-modal">
      {{ header }}
    </h2>
  </header>

  <div class="text-center modal-content__body" *ngIf="isLoading">
    <p class="modal-content__message mrgn-b_sm">Please, wait...</p>
    <app-loader></app-loader>
  </div>

  <form
    [formGroup]="form"
    (ngSubmit)="submit()"
    [hidden]="isLoading"
    (keydown.enter)="$event.preventDefault()"
  >
    <section class="modal-content__body" *ngIf="apiErrors.length">
      <p
        *ngFor="let error of apiErrors"
        class="modal-content__message mrgn-b_sm"
        [innerHTML]="error.message"
      ></p>
    </section>

    <section class="modal-content__body" *ngIf="message">
      <p class="modal-content__message" [innerHTML]="message"></p>
      <ng-container *ngIf="data && data.warnings">
        <p
          *ngFor="let warning of data.warnings"
          class="modal-content__message error"
        >
          {{ warning.message }}
        </p>
      </ng-container>
    </section>

    <ul
      class="modal-content__list modal-content__body"
      *ngIf="!message && !apiErrors.length"
    >
      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="!existedProject && !createProject"
      >
        <label for="projectId" class="modal-content__label"
          >Select a Project<span class="asterisk red">&ast;</span></label
        >
        <div class="form-block form-block_lg">
          <app-multiselect
            formControlName="projectId"
            [key]="'id'"
            [as]="'name'"
            [placeholder]="'Select a Project'"
            [id]="'projectId'"
            [singleValueSelected]="true"
            [returnSingleValueAsNumber]="true"
            [values]="projects"
            [ngClass]="{ 'form-block_error': hasError('projectId') }"
            id="projectId"
            class="form-block form-block_full-width"
          >
          </app-multiselect>
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('projectId')"
            [tooltip]="getErrorText('projectId')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
        <p
          *ngIf="forecastData"
          class="tip modal-content__item modal-content__item_full-width"
        >
          <span class="asterisk">*</span>&nbsp;Assets from the Forecast that
          match the end of life determination with projects can be added to an
          existing project. Non-matching projects are not displayed in the list.
        </p>
      </li>

      <li
        *ngIf="newProjectFromForecast"
        formGroupName="createNewProject"
        class="modal-content__item modal-content__item_full-width"
      >
        <div>
          <div
            class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap el-in-a-row_lg mrgn-b_sm"
          >
            <label class="fixed-width-80">
              <span class="no-wrap">
                {{ 'start_year' | translate }}
              </span>
            </label>

            <label class="xs-100">
              <app-select
                (ngModelChange)="createPeriodList()"
                formControlName="period"
                [ngClass]="{ 'form-block_error': hasError('period') }"
                [showPlaceholder]="false"
                [values]="periodList"
              >
              </app-select>
              <p class="error" *ngIf="hasError('period')">
                {{ getErrorText('period') }}
              </p>
            </label>
          </div>

          <div
            class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap el-in-a-row_lg mrgn-b_sm"
          >
            <label class="fixed-width-80">
              <span class="no-wrap">
                {{ 'end_year' | translate }}
              </span>
            </label>

            <label class="xs-100">
              <app-select
                formControlName="end_period"
                [ngClass]="{ 'form-block_error': hasError('end_period') }"
                [showPlaceholder]="false"
                [values]="endPeriods"
              ></app-select>
              <p class="error" *ngIf="hasError('end_period')">
                {{ getErrorText('end_period') }}
              </p>
            </label>
          </div>
        </div>
      </li>

      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="!form?.value.projectId && createProject"
      >
        <label for="name" class="modal-content__label"
          >Project Name<span class="asterisk red">&ast;</span></label
        >
        <div class="form-block form-block_lg">
          <input
            [ngClass]="{ 'form-block_error': hasError('name') }"
            formControlName="name"
            id="name"
            class="form-block__element"
            placeholder="Enter Project Name"
            type="text"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('name')"
            [tooltip]="getErrorText('name')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>

      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="form?.value.projectId && !createProject"
      >
        <label for="projectPeriod" class="modal-content__label">{{
          'fiscal_year' | translate
        }}</label>
        <div class="form-block">
          <app-select
            formControlName="period"
            id="projectPeriod"
            [ngClass]="{ 'form-block_error': hasError('period') }"
            [showPlaceholder]="false"
            [values]="periodList"
          >
          </app-select>

          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('period')"
            [tooltip]="getErrorText('period')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>

      <li
        class="modal-content__item modal-content__item_full-width padding-bottom-0"
      >
        <label for="name" class="modal-content__label"
          >Project Description</label
        >
        <div class="form-block form-block_lg mrgn-b_xs">
          <input
            [ngClass]="{ 'form-block_error': hasError('description') }"
            formControlName="description"
            id="description"
            class="form-block__element"
            placeholder="Enter Project Description"
            type="text"
            (input)="updateCharacterCount()"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('description')"
            [tooltip]="getErrorText('description')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
        <div class="character-counter text-dark-grey text-right form-block">
          {{ characterCount }}/{{ descriptionLength }}
        </div>
      </li>

      <li class="modal-content__item modal-content__item_full-width">
        <label class="modal-content__label"
          >Project Access<span class="asterisk red">&ast;</span></label
        >
        <div
          class="flex-a-center el-in-a-row el-in-a-row_nowrap el-in-a-row_xl"
        >
          <label class="custom-radiobutton">
            <input
              [value]="true"
              formControlName="public"
              class="custom-radiobutton__input"
              type="radio"
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
            ></span>
            Public
          </label>
          <label class="custom-radiobutton">
            <input
              [value]="false"
              formControlName="public"
              class="custom-radiobutton__input"
              type="radio"
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
            ></span>
            Private
          </label>
        </div>
      </li>

      <li class="modal-content__item modal-content__item_full-width">
        <label class="modal-content__label"
          >Does this project take place in, or affects an occupied space<span
            class="asterisk red"
            >&ast;</span
          ></label
        >
        <div
          class="flex-a-center el-in-a-row el-in-a-row_nowrap el-in-a-row_xl"
        >
          <label class="custom-radiobutton">
            <input
              [value]="true"
              formControlName="is_occupied"
              class="custom-radiobutton__input"
              type="radio"
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
            ></span>
            Yes
          </label>
          <label class="custom-radiobutton">
            <input
              [value]="false"
              formControlName="is_occupied"
              class="custom-radiobutton__input"
              type="radio"
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
            ></span>
            No
          </label>
        </div>
      </li>

      <div class="project-form-divider mrgn-t_xs mrgn-b_sm"></div>
      <li
        class="modal-content__item modal-content__item_full-width padding-top-0"
      >
        <label for="projectPeriod" class="modal-content__label"
          >Project Date</label
        >

        <mat-form-field
          class="daterangepicker-block form-block__element xs-100"
          [ngClass]="{
            'form-block_error':
              hasError('projectDate') || dateRangeInput?.ngControl?.invalid
          }"
        >
          <mat-date-range-input
            [rangePicker]="rangePicker"
            [max]="maxProjectYear"
            formGroupName="projectDate"
            #dateRangeInput
          >
            <input
              matStartDate
              placeholder="MM/DD/YYYY"
              formControlName="project_date_from"
              readonly
            />
            <input
              matEndDate
              placeholder="MM/DD/YYYY"
              formControlName="project_date_to"
              readonly
            />
          </mat-date-range-input>

          <button
            type="button"
            class="btn tooltip-btn_error daterangepicker-tooltip-btn_error"
            *ngIf="
              hasError('projectDate') || dateRangeInput?.ngControl?.invalid
            "
            [tooltip]="
              dateRangeInput?.ngControl?.invalid && !hasError('projectDate')
                ? 'Start or end date is invalid.'
                : getErrorText('projectDate')
            "
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>

          <mat-datepicker-toggle
            matIconSuffix
            [for]="rangePicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #rangePicker>
            <mat-date-range-picker-actions>
              <button
                mat-raised-button
                class="btn action-btn action-btn_default"
                matDateRangePickerApply
                (click)="clearSingleDateRange()"
              >
                Clear
              </button>
              <button
                mat-raised-button
                matDateRangePickerCancel
                class="btn action-btn action-btn_default"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                class="btn action-btn action-btn_success"
                matDateRangePickerApply
                (click)="checkSingleDateRange()"
              >
                Done
              </button>
            </mat-date-range-picker-actions>
          </mat-date-range-picker>
        </mat-form-field>
      </li>
      <li class="modal-content__item modal-content__item_full-width">
        <label for="allocatedById" class="modal-content__label"
          ><span class="flex flex-a-center flex_to-start"
            >Allocated By
          </span></label
        >
        <div class="form-block form-block_lg">
          <app-multiselect
            formControlName="allocated_by"
            [key]="'value'"
            [as]="'name'"
            [placeholder]="'placeholders.select_allocted_by' | translate"
            [id]="'allocatedById'"
            [showSearch]="false"
            [singleValueSelected]="true"
            [returnSingleValueAsNumber]="true"
            [values]="alloctedByOptions"
            [ngClass]="{ 'form-block_error': hasError('allocated_by') }"
            id="allocatedById"
            class="form-block form-block_full-width"
          >
          </app-multiselect>
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('allocated_by')"
            [tooltip]="getErrorText('allocated_by')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>
      <li class="modal-content__item modal-content__item_full-width">
        <label for="projectTypeID" class="modal-content__label"
          ><span class="flex flex-a-center flex_to-start"
            >Project Type
          </span></label
        >
        <div class="form-block form-block_lg">
          <app-multiselect
            formControlName="project_type"
            [key]="'id'"
            [as]="'name'"
            [id]="'projectTypeID'"
            [placeholder]="' Select Project Type'"
            [showSearch]="false"
            [singleValueSelected]="true"
            [returnSingleValueAsNumber]="true"
            [values]="projectTypeOptions"
            [ngClass]="{ 'form-block_error': hasError('project_type') }"
            id="projectTypeID"
            class="form-block form-block_full-width"
          >
          </app-multiselect>
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('project_type')"
            [tooltip]="getErrorText('project_type')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>
      <li class="modal-content__item modal-content__item_full-width">
        <label for="energySavingID" class="modal-content__label"
          ><span class="flex flex-a-center flex_to-start"
            >Project Priority
          </span></label
        >
        <div class="form-block form-block_lg">
          <app-multiselect
            formControlName="project_risk"
            [key]="'id'"
            [as]="'name'"
            [id]="'projectPriorityID'"
            [placeholder]="' Select Project Priority'"
            [showSearch]="false"
            [singleValueSelected]="true"
            [returnSingleValueAsNumber]="true"
            [values]="projectPriorityOptions"
            [ngClass]="{ 'form-block_error': hasError('project_risk') }"
            id="projectPriorityID"
            class="form-block form-block_full-width"
          >
          </app-multiselect>
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('project_risk')"
            [tooltip]="getErrorText('project_risk')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>
      <li class="modal-content__item modal-content__item_full-width">
        <label for="energySavingID" class="modal-content__label"
          ><span class="flex flex-a-center flex_to-start"
            >Energy Savings
            <img
              [src]="leafUrl"
              class="mrgn-l-xs"
              alt="energy saving icon" /></span
        ></label>
        <div class="form-block form-block_lg">
          <app-multiselect
            formControlName="energy_saving"
            [key]="'value'"
            [as]="'name'"
            [placeholder]="'placeholders.select_energy_saving_type' | translate"
            [id]="'energySavingID'"
            [showSearch]="false"
            [singleValueSelected]="true"
            [returnSingleValueAsNumber]="true"
            [values]="energySavingOptions"
            [ngClass]="{ 'form-block_error': hasError('energy_saving') }"
            id="energySavingID"
            class="form-block form-block_full-width"
          >
          </app-multiselect>
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('energy_saving')"
            [tooltip]="getErrorText('energy_saving')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>
    </ul>

    <section
      class="buttons-block modal-content__footer buttons-block_not-bordered"
      [ngClass]="{
        'buttons-block_to-center': data
      }"
    >
      <button
        type="button"
        (click)="close()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{ cancelText }}</span>
      </button>
      <!-- test -->
      <button
        [disabled]="!form?.dirty"
        *ngIf="!message"
        class="btn action-btn action-btn_success"
      >
        <span class="action-btn__icon">
          <app-svg [href]="'check'"></app-svg>
        </span>
        <span class="action-btn__title">{{ submitText }}</span>
      </button>
    </section>
  </form>
</section>
