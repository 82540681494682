import { ConfigInterface } from './config.interface';
import { secrets } from 'src/secrets';

export const environment: ConfigInterface = {
  production: true,
  api: {
    base: 'https://dev.facilityhealthinc.com/api',
    domen: 'https://dev.facilityhealthinc.com/',
    wsUrl: 'wss://dev.facilityhealthinc.com',
    processor: 'https://dev.facilityhealthinc.com',
  },
  tokens: {
    bearer_token: 'authorization',
    bearer_header: 'X-Api-Key',
    org_header: 'X-Api-Organization-Key',
  },
  amazon: {
    region: 'us-east-1',
    shared_bucket_url:
      'https://fhi-origin-dev-global-origin-files.s3.amazonaws.com',
    aws_url: 'https://s3.amazonaws.com',
  },
  files: {
    base: 'https://fhi-origin-dev-global-origin-files.s3.amazonaws.com',
    csv_import_dir: 'csv_import_setup',
    csv_import_errors_dir: 'import_errors',
    user_images_dir: 'images/users',
    asset_images_dir: 'images/assets',
  },
  assets_base: 'https://fhi-origin-dev-global-origin-files.s3.amazonaws.com',
  image_resize: 'https://dev.facilityhealthinc.com/image_resize',
  domain: 'facilityhealthinc.com',
  googleAnalytics: '',
  pendoApiKey: secrets.PENDO_KEY,
  auth0_domain: 'https://dev.facilityhealthinc.com',
  auth0_url: 'https://dev.auth.brightlysoftware.com',
  clientId: 'bo8MSr4MYu4CSEWjUnAYExMUAStWrRaR',
};
