import { DefaultDTO } from './default.dto';
import { DateTime } from 'luxon';
export class AssessmentDTO extends DefaultDTO {
  score: number;
  comment: string;
  completed_at: string;

  constructor(assessment) {
    super();
    this.score = assessment.score;
    this.comment = assessment.comment;
    if (assessment.completed_at) {
      this.completed_at = DateTime.fromISO(assessment.completed_at).toISO();
    }
  }
}
