import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FHIHistoryPeriod,
  GraphTypes,
  ModulesEnum,
} from '../../../core/constants/enums';

import { BaseChartComponent } from '../base-chart.component';
import { Configs } from '../../../core/constants/configs.constants';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { DateTime } from 'luxon';
import { EventService } from '../../../core/services/event.service';
import { FHIHistoryModel } from '../../../core/models/fhi-module-history.model';
import { FHIModulesService } from '../../../core/services/fhi-modules.service';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { OrganizationModel } from '../../../core/models/organization.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fhi-graphs',
  templateUrl: './fhi-graphs.component.html',
  styleUrls: ['./fhi-graphs.component.less'],
})
export class FhiGraphsComponent
  extends BaseChartComponent
  implements OnInit, OnDestroy
{
  @Input() public assetId: number;
  @Input() declare type: GraphTypes;
  @Input()
  public set organization(value: OrganizationModel) {
    if (value) {
      this._organization = value;
      if (this.type === GraphTypes.dashboard && this.isInited) {
        this.getGraphsForOrganization();
      }
    }
  }

  @Output() public periodChanged: EventEmitter<string> =
    new EventEmitter<string>();
  public isLoading: boolean;
  public lineChartValues: any = {};
  public tempLineChartValues: any = {};
  private timer: any;
  private isInited: boolean;
  modules: any = ModulesEnum;
  indicesMode: string;
  private subscriptions: Subscription = new Subscription();

  public get organization(): OrganizationModel {
    return this._organization;
  }

  public get graphTypes(): any {
    return GraphTypes;
  }

  constructor(
    private _notificationService: CustomNotificationService,
    private _FHIModulesService: FHIModulesService<FHIHistoryModel>,
    private _eventService: EventService,
    private _organizationCurrent: OrganizationCurrentService
  ) {
    super();
  }

  ngOnInit() {
    this.tempLineChartValues = {};
    // default line chart period
    this.setDefaultPeriod();
    // Events
    this.subscribeToEvents();
    this.isInited = true;
    this.setupIndicesModeChange();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
    clearTimeout(this.timer);
  }

  private subscribeToEvents() {
    this._eventService.on(Configs.EVENTS.SERVICE_LOG_CHANGED, () =>
      this.getGraphsForOrganization()
    );
    this._eventService.on(Configs.EVENTS.ASSET_CHANGED, () => {
      if (this.type === GraphTypes.asset) {
        this.getGraphsForAsset(true);
      }
    });
  }

  private setDefaultPeriod() {
    this.lineChartPeriod =
      this.type !== GraphTypes.dashboard ? 'lifetime' : 'rolling';
  }

  public setLoading(val: boolean) {
    this.lineChartValues = {};
    this.isLoading = val;
  }

  /**
   * Requests and parses graphs values for organization
   */
  private getGraphsForOrganization(): void {
    this.setLoading(true);
    this._FHIModulesService.getModuleHistoryForOrganization().subscribe({
      next: (data) => this.setLineChartValues(data),
      error: (err) => this._notificationService.apiError(err),
    });
  }

  /**
   * Make request for asset modules history
   */
  public getGraphsForAsset(delay?: boolean): void {
    // request for history of FHI modules
    this.setLoading(true);

    if (delay) {
      setTimeout(() => this.getGraphsForAsset(), 1000);
      return;
    }

    this._FHIModulesService
      .getModuleHistoryForAsset(this.assetId, this.lineChartPeriod)
      .subscribe({
        next: (data) => this.setLineChartValues(data),
        error: (err) => this._notificationService.apiError(err),
      });
  }

  /**
   * Update line chart period after event 'period_changed'
   * @param period
   */
  public updateLineChartPeriod(period: string): void {
    this.periodChanged.emit(period);
    this.lineChartPeriod = period;
    // Setup if the graphs are on asset page - get org. and asset models and request the indexes
    if (this.type === GraphTypes.asset) {
      this.getGraphsForAsset();
    } else if (this.type === GraphTypes.dashboard) {
      this.getGraphsForOrganization();
    }
  }

  /**
   * Sets Line Chart Values
   * @param receivedData
   */
  public setLineChartValues(receivedData?: FHIHistoryModel) {
    if (!receivedData) {
      this.isLoading = false;
      return;
    }

    const currentIndexes = {};
    for (const fhi in this.modules) {
      let fhiData = receivedData.hasOwnProperty(fhi)
        ? receivedData[fhi]
        : receivedData[`${fhi}_${this.indicesMode}`];
      if (fhiData) {
        if (!this.organization.hasModule(fhi)) {
          continue;
        }
        const showOnlyKeypoints = fhiData.length > 30,
          period =
            this.type !== GraphTypes.dashboard
              ? this.lineChartPeriod
              : 'rolling';
        // if too many points - filter only key points
        if (showOnlyKeypoints) {
          fhiData = fhiData.filter((item) => item.point_type);
        }
        // if value more than 200 - set it to 200 and save real value to z
        currentIndexes[fhi] = [];
        fhiData.forEach((item) => {
          const obj: any = {
            x: DateTime.fromISO(item.date).toISO(),
            y:
              item[`${FHIHistoryPeriod[period]}_index`] > 200
                ? 200
                : item[`${FHIHistoryPeriod[period]}_index`],
            z:
              item[`${FHIHistoryPeriod[period]}_index`] > 200
                ? item[`${FHIHistoryPeriod[period]}_index`]
                : undefined,
          };
          currentIndexes[fhi].push(obj);
        });

        if (fhiData.every((dot) => dot.date === fhiData[0]?.date)) {
          currentIndexes[fhi].push({
            x: DateTime.now().toISO(),
            y:
              fhiData[0][`${FHIHistoryPeriod[period]}_index`] > 200
                ? 200
                : fhiData[0][`${FHIHistoryPeriod[period]}_index`],
            z:
              fhiData[0][`${FHIHistoryPeriod[period]}_index`] > 200
                ? fhiData[0][`${FHIHistoryPeriod[period]}_index`]
                : undefined,
          });
        }
      }
    }
    this.isLoading = false;
    this.lineChartValues = { ...currentIndexes };
  }

  setupIndicesModeChange() {
    // indices Straightline/Adjusted switcher
    this.subscriptions.add(
      this._organizationCurrent.modeSubj.subscribe({
        next: (mode) => {
          this.indicesMode = mode;
          this.updateLineChartPeriod(this.lineChartPeriod);
        },
      })
    );
  }
}
