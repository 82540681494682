import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { RoutesConstants } from '../../core/constants/routes.constants';

@Injectable()
export class PublicRouteGuard {
  constructor(
    private router: Router,
    private _authService: AuthService
  ) {}

  canActivate(): boolean {
    if (this._authService.getApiToken()) {
      this.router.navigate([RoutesConstants.HOME]);
      return false;
    }
    return true;
  }
}
