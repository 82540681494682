import { RepairDTO } from '../dto/repair.dto';
import { IServiceLog } from './service-log.model';
import { DefaultModel } from './default.model';

export class RepairModel
  extends DefaultModel<RepairDTO>
  implements IServiceLog
{
  asset: { id: number; name: string; asset_number: string };
  asset_number: string;
  id: number;
  name: string;
  building: { name: string };
  labor_cost: string;
  parts_cost: string;
  work_order_completed_at: string;
  work_order_number: string;
  time_charge?: string;
  asset_id?: number;
  comment?: string;

  constructor(model) {
    super();
    if (model) {
      Object.assign(this, model);
    }
    this.parts_cost = model ? model.parts_cost : 0;
  }

  public dto(): RepairDTO {
    return new RepairDTO(this);
  }

  public get entity_name(): string {
    return 'repair';
  }

  public toString(): string {
    return this.entity_name.capitalize();
  }
}
