import {
  COUNTRIES,
  STATES,
} from '../../../core/constants/collections.constants';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { AutoUnsubscribeService } from '../../../core/services/auto-unsubscribe.service';
import { BaseReactiveForm } from '../../components/base-reactive-form';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Configs } from '../../../core/constants/configs.constants';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';
import { FacilityAttributesService } from '../../../core/services/facility-attributes.service';
import { FacilityCategoryModel } from '../../../core/models/facility-category.model';
import { LocationModel } from '../../../core/models/location.model';
import { LocationService } from '../../../core/services/location.service';
import { REGEX } from '../../../core/constants/regex.constants';
import { Router } from '@angular/router';
import { patternValidator } from '../../validators/custom-pattern.validator';

@Component({
  templateUrl: './facility-update-modal.component.html',
})
export class FacilityUpdateModalComponent
  extends BaseReactiveForm<LocationModel>
  implements OnInit
{
  @ViewChild('facilityForm', { static: true }) declare tdForm: NgForm;
  @Input() public type: string;
  /**
   * Specifies the type of entity, which can be one of the following:
   * - 'locations'
   * - 'buildings'
   * - 'floors'
   * - 'rooms'
   * - 'zones'
   */
  @Input() public orgId: string;
  @Input() public parentId: number;

  public isCreate: boolean;
  public showAddress: boolean;
  public initialProjectModifier: number;
  public nameTitle: string;
  public applicableCategories: Array<FacilityCategoryModel> = [];
  public locationType: string;
  public states = STATES;
  public countries: Array<any> = COUNTRIES;
  public categories: Array<FacilityCategoryModel>;
  public invalidZipcodeAndCityError: boolean = false;

  public get confirmationNeeded(): boolean {
    return (
      !this.isCreate &&
      this.type === 'locations' &&
      this.form.value.project_location_modifier !== this.initialProjectModifier
    );
  }

  public get regex() {
    return REGEX;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService,
    private router: Router,
    private _destroy: AutoUnsubscribeService,
    private _facilityAttributesService: FacilityAttributesService<FacilityCategoryModel>,
    private _locationService: LocationService
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }
  ngOnInit() {
    this.isCreate = !Object.keys(this.model).length;
    this.locationType = this.type?.slice(0, -1);
    this.initialProjectModifier = this.model.project_location_modifier;
    this.header = `${this.isCreate ? 'New' : 'Edit'} ${this.locationType}`;
    this.showAddress = this.type === 'locations';

    this.setupForm();

    if (this.locationType !== 'zones') {
      this._facilityAttributesService
        .getFacilityCategories(false)
        .pipe(takeUntil(this._destroy))
        .subscribe({
          next: ({ list }) => {
            const facilityTypeIndex =
              Configs.FACILITIES.findIndex(
                (item) => item === this.locationType
              ) + 1;
            this.applicableCategories = list.filter((category) =>
              category.applicable_to.includes(facilityTypeIndex)
            );
          },
          error: (err) => this._notificationService.apiError(err),
        });
    }
  }

  protected setupForm() {
    const form: any = {
      name: [
        this.model.name,
        [
          Validators.required,
          Validators.maxLength(250),
          Validators.pattern(this.regex.specialSymbols),
        ],
      ],
    };

    if (this.type !== 'zones') {
      form['tag_ids'] = [this.model.tag_ids || []];
    }

    if (this.type === 'locations') {
      form['address_attributes'] = this.formBuilder.group({
        country: [this.model.address?.country],
        city: [
          this.model.address?.city,
          [Validators.maxLength(80), patternValidator('onlyAlphabets')],
        ],
        state: [this.model.address?.state],
        zipcode: [
          this.model.address?.zipcode,
          [Validators.maxLength(10), patternValidator('zipCode')],
        ],
        address1: [this.model.address?.address1, [Validators.maxLength(150)]],
      });
      form['project_location_modifier'] = [
        this.model.project_location_modifier,
        [
          Validators.required,
          Validators.max(1e3),
          patternValidator('floatingPositiveNumbers'),
        ],
      ];
    }
    this.form = this.formBuilder.group(form);

    if (this.type === 'locations') {
      this.onFormChange();
    }
  }

  public saveAction(confirm: boolean) {
    if (!this.isFormValid) {
      return;
    }

    if (this.confirmationNeeded && !confirm) {
      this.hasSubmitted = true;
      return;
    }

    this.updateLocation();
  }

  public updateLocation() {
    this.isLoading = true;
    const body = {
      ...this.form.value,
    };
    if (this.type === 'locations') {
      body.project_location_modifier =
        this.form.value.project_location_modifier;
    }
    if (this.parentId) {
      body[`${Configs.FACILITIES_TREE_REVERSE[this.locationType]}_id`] =
        this.parentId;
    }
    const method = this.isCreate
      ? this._locationService.create({ [this.locationType]: body }, this.type)
      : this._locationService.update(
          { [this.locationType]: body },
          `${this.type}/${this.model.id}`
        );

    method.pipe(takeUntil(this._destroy)).subscribe({
      next: (success) => {
        if (this.isCreate) {
          if (this.type === 'buildings') {
            this.router.navigate([
              '/',
              this.orgId,
              'facilities',
              'buildings',
              success.id,
            ]);
          }
        }
        this._eventService.broadcast(Configs.EVENTS.FACILITIES_RELOAD);
        this.close();
      },
      error: (error) => {
        this.isLoading = false;
        this.setErrorToControl(error);
      },
    });
  }

  public cancel() {
    if (!this.hasSubmitted) {
      super.close();
      return;
    }
    this.hasSubmitted = false;
  }

  private onFormChange() {
    this.form
      .get('address_attributes.country')
      .valueChanges.subscribe(() =>
        this.form.get('address_attributes.state').reset()
      );

    const zipcodeControl = this.form.get('address_attributes').get('zipcode');
    const cityControl = this.form.get('address_attributes').get('city');

    zipcodeControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe({
        next: (zipcodeValue) => {
          const cityValue = cityControl.value;
          if (REGEX.zipCode.test(zipcodeValue) && cityControl.valid) {
            this.getRegionalFactors(cityValue, zipcodeValue);
          }
        },
        error: (error) => this.setErrorToControl(error),
      });

    cityControl.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe({
        next: (cityValue) => {
          const zipcodeValue = zipcodeControl.value;
          if (REGEX.zipCode.test(zipcodeValue) && cityControl.valid) {
            this.getRegionalFactors(cityValue, zipcodeValue);
          }
        },
        error: (error) => this.setErrorToControl(error),
      });
  }

  getRegionalFactors(cityValue, zipcodeValue) {
    this._facilityAttributesService
      .getRegionalFactors(cityValue, zipcodeValue)
      .subscribe({
        next: (response) => {
          this.invalidZipcodeAndCityError = false;
          this.setValue(
            response?.regionalIndexFactor,
            'project_location_modifier'
          );
        },
        error: () => {
          this.invalidZipcodeAndCityError = true;
          this._notificationService.error(
            'The zip code and city do not match. Please enter a valid zip code and city, or provide the regional factor manually.'
          );
        },
      });
  }
}
