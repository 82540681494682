import { Component } from '@angular/core';

@Component({
  templateUrl: './page-500.component.html',
  styleUrls: ['./page-500.component.less'],
})
export class Page500Component {
  public title: string = '404';
  public text: string = 'Page could not be found';
}
