import { AddressDTO } from './address.dto';
import { DefaultDTO } from './default.dto';
import { HelperService } from '../services/helper.service';
import { ProjectFeesModel } from '../models/project-fees-model';
import { DefaultIdNameModel } from '../models/default-id-name.model';
import { ProjectFundingAllocation } from '../models/funding-and-allocation.model';

export class OrganizationDTO extends DefaultDTO {
  picture: string;
  name: string;
  email: string;
  phone: string;
  address_attributes: AddressDTO;
  asset_udfs_attributes?: Array<any>;
  building_udfs_attributes?: Array<any>;
  available_modules: Array<string>;
  time_zone: string;
  cmms_start_at: string;
  cmms_start_on: string;
  labour_hour_rate: number;
  labor_utilization_modifier: number;
  project_fees: ProjectFeesModel;
  project_udfs_attributes: any;
  project_risk_udfs_attributes: any;
  project_type_udfs_attributes: any;
  project_occupation_modifier: number;
  project_contingency_modifier: number;
  test_organization: boolean;
  degradation_modifier?: number;
  settings: { key: string; value: any }[];
  project_funding_allocation: ProjectFundingAllocation[];

  constructor(organization) {
    super();
    if (organization) {
      Object.assign(this, organization);
      this.address_attributes = new AddressDTO(organization.address);
      this.time_zone = Array.isArray(organization.time_zone)
        ? organization.time_zone[0]
        : organization.time_zone;
    }
    this.available_modules = [];

    organization.modules.map((item) => {
      if (item.checked) {
        this.available_modules.push(item.key);
      }
    });

    this.asset_udfs_attributes.forEach(this.setNullishIds);
    this.building_udfs_attributes.forEach(this.setNullishIds);

    this.project_occupation_modifier = HelperService.convertPercentToFloat(
      organization.project_occupation_modifier
    );
    this.project_funding_allocation = organization.project_funding_allocation;

    this.project_contingency_modifier = HelperService.convertPercentToFloat(
      organization.project_contingency_modifier
    );
    if (organization.project_fees) {
      this.project_fees = {};
      Object.keys(organization.project_fees).forEach((key) => {
        this.project_fees[key] = HelperService.convertPercentToFloat(
          organization.project_fees[key]
        );
      });
    }
    organization.project_risk_udfs.shift();
    organization.project_type_udfs.shift();
    this.project_udfs_attributes = this.prepareAttributes(
      organization.project_udfs
    );
    this.project_risk_udfs_attributes = organization.project_risk_udfs.map(
      this.setNullishIds
    );
    this.project_type_udfs_attributes = organization.project_type_udfs.map(
      this.setNullishIds
    );

    this.labor_utilization_modifier = HelperService.convertPercentToFloat(
      organization.labor_utilization_modifier
    );
    this.test_organization = !!organization.test_organization;
    if (organization.degradation_modifier) {
      this.degradation_modifier = organization.degradation_modifier;
    }
  }

  private prepareAttributes(
    orgAttr: DefaultIdNameModel[]
  ): DefaultIdNameModel[] {
    return orgAttr.map((item) => {
      const udfObj: any = {
        name: item.name,
        id: item.id,
      };
      if (item._delete) {
        udfObj._delete = item._delete;
      }
      return udfObj;
    });
  }

  private setNullishIds(el) {
    if (el.id?.includes('null_')) {
      el.id = null;
    }
    return el;
  }
}
