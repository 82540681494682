import { ServiceLogDTO } from './service-log.dto';
import { DefaultDTO } from './default.dto';
import { DateTime } from 'luxon';

export class RepairDTO extends DefaultDTO implements ServiceLogDTO {
  work_order_completed_at: string;
  work_order_number: number;
  time_charge: number;
  parts_cost: number;
  comment: string;
  labor_cost: number;

  constructor(repair) {
    super();
    this.work_order_completed_at = DateTime.fromISO(
      repair.work_order_completed_at
    ).toISO();
    this.work_order_number = repair.work_order_number;
    this.time_charge = parseFloat(repair.time_charge);
    this.parts_cost = repair.parts_cost ? parseFloat(repair.parts_cost) : 0;
    this.comment = repair.comment;
    this.labor_cost = parseFloat(repair.labor_cost);
  }
}
