import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RoutesConstants } from '../../core/constants/routes.constants';
import { UserCurrentService } from '../../core/services/user-current.service';
import { Observable } from 'rxjs';
import { OrganizationCurrentService } from '../../core/services/organization-current.service';
import { RoutingService } from '../../core/services/routing.service';
import { RoleType } from '../../core/constants/types.constants';
import { Settings } from 'luxon';

@Injectable()
export class CustomGuard {
  constructor(
    private router: Router,
    private _userCurrent: UserCurrentService,
    private _organizationCurrent: OrganizationCurrentService,
    private _routingService: RoutingService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const roles = route.data.roles as Array<string>;

    return new Observable<boolean>((observer) => {
      this._userCurrent.get().subscribe(
        (user) => {
          Settings.defaultZone = user.organization?.time_zone || user.time_zone;
          if (!roles.some((role) => role === user.role_name)) {
            if (!this._organizationCurrent.organization) {
              this._organizationCurrent.get(user.is_tenant).subscribe(
                () => {
                  this.router.navigate([
                    RoutesConstants.ORGANIZATION.HOME(
                      this._organizationCurrent.organization.id_hash
                    ),
                  ]);
                },
                () => {
                  this.router.navigate([RoutesConstants.HOME]);
                }
              );
            } else {
              this.router.navigate([
                RoutesConstants.ORGANIZATION.HOME(
                  this._organizationCurrent.organization.id_hash
                ),
              ]);
            }
          }
          if (this._organizationCurrent?.organization) {
            Settings.defaultZone =
              this._organizationCurrent.organization?.time_zone;
          }
          this._routingService.routeType = (
            user.is_tenant ? 'tenant' : 'client'
          ) as RoleType;
          observer.next(true);
          observer.complete();
        },
        () => {
          observer.next(false);
          observer.complete();
        }
      );
    });
  }
}
