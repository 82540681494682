import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseStoreService } from './base-store.service';
import { Configs } from '../constants/configs.constants';
import { EventService } from './event.service';
import { InactivityService } from './inactivity.service';
import { RequestService } from './request.service';
import { UserModel } from '../models/user.model';
import { map } from 'rxjs/operators';

@Injectable()
export class UserCurrentService extends BaseStoreService<UserModel> {
  public resourceName = 'v2/users/current';
  private inactivityService: InactivityService = inject(InactivityService);

  constructor(
    protected _requestService: RequestService,
    private _eventService: EventService
  ) {
    super(_requestService, UserModel);

    this._eventService.on(Configs.EVENTS.LOGOUT, () => {
      this.platformService.clearUserProfile();
      this.clean();
    });

    this._eventService.on(Configs.EVENTS.LOGIN, () => {
      this.clean();
    });
  }
  public isAvatarChanged: boolean;
  private cachedUser: UserModel;

  public get user(): UserModel {
    return this.cachedUser;
  }

  public get(): Observable<UserModel> {
    if (this.cachedUser) {
      return of(this.cachedUser);
    }
    return this._requestService.get(`/${this.resourceName}`).pipe(
      map((res) => {
        this.cachedUser = new UserModel(res);
        this.platformService.updateUserProfile({
          ...this.cachedUser,
          rawRes: res,
          isLoggedIn: true,
        });
        this.inactivityService.initializeTimers();
        return this.cachedUser;
      })
    );
  }

  public updateCachedUser(user: UserModel): void {
    this.platformService.updateUserProfile(user);
    this.cachedUser = Object.assign(this.cachedUser, user);
  }

  public clean(): void {
    this.cachedUser = null;
  }

  public update(model: any): Observable<UserModel> {
    return this._requestService.put(`/${this.resourceName}`, { user: model });
  }
}
