import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColorsConstants } from '../../../core/constants/colors.constants';
import { BaseChartComponent } from '../base-chart.component';

@Component({
  selector: 'app-chart-doughnut',
  templateUrl: './chart-doughnut.component.html',
  styleUrls: ['./chart-doughnut.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartDoughnutComponent extends BaseChartComponent {
  @Input() isDisabled: boolean;
  @Input() FCI: boolean;
  @Input()
  protected set chartValues(value) {
    if (this.helper.isExist(value)) {
      this._chartValues = +value;
      this.updateCharts();
    }
  }
  protected get chartValues(): any {
    return this._chartValues;
  }

  private optionsChartOptions: any;

  public get isShownOverflowBg(): boolean {
    return this.chartValues > 100;
  }

  protected updateCharts(): void {
    if (!this.isCreated) {
      this.createOptions();
      this.isCreated = true;
    }
    this.createChartMain();
  }

  private prepareOverflowData() {
    let endValue: number;

    if (this.isDisabled) {
      endValue = 100;
    } else if (this.chartValues >= 200) {
      endValue = 0;
    } else {
      endValue = 200 - this.chartValues;
    }
    const startValue = this.isDisabled ? 0 : this.chartValues - 100;
    return {
      data: [startValue, endValue],
      borderRadius: 10,
      borderWidth: 0,
      backgroundColor: [
        ColorsConstants.CHARTS.small,
        ColorsConstants.CHARTS.background,
      ],
      hoverBackgroundColor: [
        ColorsConstants.CHARTS.small,
        ColorsConstants.CHARTS.background,
      ],
    };
  }

  private createChartMain() {
    const datasets = [this.prepareChartMainData()];
    if (this.isShownOverflowBg) {
      datasets.push(this.prepareOverflowData());
    }
    this.chart = {
      chartType: 'doughnut',
      data: {
        datasets,
      },
      options: {
        ...this.optionsChartOptions,
        cutout: datasets.length > 1 ? '60%' : '75%',
      },
    };
  }

  private createOptions(): void {
    this.optionsChartOptions = {
      plugins: {
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      animation: {
        animateRotate: true,
      },
      aspectRatio: 2,
      circumference: 180,
      rotation: -90,
      cutout: '75%',
    };
  }

  private calculateEndValue(): number {
    if (this.isDisabled || this.chartValues >= 100) {
      return this.isDisabled ? 100 : 0;
    }
    return 100 - this.chartValues;
  }

  private calculateStartValue(): number {
    return this.isDisabled ? 0 : this.chartValues;
  }

  private getColorForFCI(): string {
    const colorRanges = [
      { max: 5, color: '#00C650' },
      { max: 10, color: '#FADB14' },
      { max: 15, color: '#FAAD14' },
      { max: 20, color: '#FFCCC7' },
      { max: 25, color: '#FF7875' },
      { max: 30, color: '#F5222D' },
      { max: 35, color: '#CF1322' },
      { max: 40, color: '#B40909' },
      { max: 45, color: '#820014' },
      { max: 50, color: '#610B00' },
    ];

    for (const range of colorRanges) {
      if (this.chartValues <= range.max) {
        return range.color;
      }
    }

    return '#000000';
  }

  private getColor(): string {
    if (this.FCI) {
      return this.getColorForFCI();
    }

    if (this.chartValues > 49) {
      return this.chartValues > 70
        ? ColorsConstants.DEFAULT.green
        : ColorsConstants.DEFAULT.orange;
    }

    return ColorsConstants.DEFAULT.red;
  }

  private prepareChartMainData(): any {
    if (this.FCI && this._chartValues < 1 && this._chartValues >= 0) {
      this._chartValues = 0.5;
    }

    let endValue = this.calculateEndValue();
    let startValue = this.calculateStartValue();
    let color = this.getColor();

    return {
      data: [startValue, endValue],
      borderRadius: 10,
      borderWidth: 0,
      backgroundColor: [color, ColorsConstants.CHARTS.background],
      hoverBackgroundColor: [color, ColorsConstants.CHARTS.background],
    };
  }
}
