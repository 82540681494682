import { DefaultDTO } from './default.dto';
import { RiskModel } from '../models/risk.model';

export class RiskDTO extends DefaultDTO {
  affect_assets_risks: boolean;
  name: string;
  available: boolean;
  risk_ranges_attributes?: Array<any>;

  constructor(risk: RiskModel) {
    super();
    if (risk.affect_assets_risks) {
      this.affect_assets_risks = risk.affect_assets_risks;
    }
    this.name = risk.name;
    this.available = risk.available;
    this.risk_ranges_attributes = risk.risk_ranges;
  }
}
