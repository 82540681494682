import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '../../core/services/helper.service';

@Pipe({
  name: 'text',
})
export class TextPipe implements PipeTransform {
  public transform(text, filler: string = '\u2014'): string {
    return HelperService.isExist(text) ? text : filler;
  }
}
