import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.less'],
})
export class PaginationComponent implements OnChanges {
  @Input() hasPrevPage: boolean = false;
  @Input() hasNextPage: boolean = false;
  @Input() hasLeftDots: boolean;
  @Input() hasRightDots: boolean;
  @Input() totalPages: number;
  @Input() page: number;
  @Input() shouldPassPageNumber: boolean = true;
  @Input() pages: Array<number>;
  @Output() onPrevPage = new EventEmitter<any>();
  @Output() onNextPage = new EventEmitter<any>();
  @Output() onSetPage = new EventEmitter<number>();

  constructor(
    private router: Router,
    private location: Location
  ) {}

  ngOnChanges() {
    this.setUrlForPage(this.page);
  }

  public prevPage(): void {
    this.page > 1 && this.setUrlForPage(this.page - 1);
    this.page > 1 && this.onPrevPage.emit();
  }

  public nextPage(): void {
    this.page < this.totalPages && this.setUrlForPage(this.page + 1);
    this.page < this.totalPages && this.onNextPage.emit();
  }

  public setPage(i: number): void {
    this.setUrlForPage(i);
    this.onSetPage.emit(i);
  }

  private setUrlForPage(pageNumber: number) {
    if (!this.shouldPassPageNumber) {
      return;
    }
    const newFullUrl = this.createUrlWithPageNum(this.router.url, pageNumber);
    this.location.replaceState(newFullUrl);
    this.savePageNumToStorage(newFullUrl, pageNumber);
  }

  private createUrlWithPageNum(url, pageNumber) {
    // parsing query parameters from url and inserting page number before query parameters
    // to avoid saving query parameters as filter value
    const parsedArray = decodeURIComponent(url).split('?');
    const newUrl = parsedArray.map((item) => {
      if (item.match('/pages')) {
        return item.split('/pages')[0];
      }
      return item;
    });
    const pagesSuffix = `/pages/${pageNumber}`;
    if (newUrl.length > 1) {
      return newUrl.join(`${pagesSuffix}?`);
    }
    return newUrl[0] + pagesSuffix;
  }

  private savePageNumToStorage(urlToSave, pageNum) {
    try {
      const currentLinkVar = urlToSave.split('/pages')[0];
      const shouldRemovePage = window.sessionStorage.getItem(currentLinkVar);
      if (typeof shouldRemovePage !== 'undefined') {
        window.sessionStorage.removeItem(currentLinkVar);
      }
      window.sessionStorage.setItem(
        currentLinkVar,
        JSON.stringify({ pageNum: pageNum })
      );
    } catch (err) {}
  }
}
