// ['tenant_admit', 'tenant_user', 'client_admin', 'client_user', 'engineer']

// []           > - - - - -
// local_edit   > + + + - +
// global_view  > + + - - +
// global_edit  > + - - - -
// user_view    > + + + - -
// user_edit    > + + - - -

export const Roles = {
  tenant_admin: {
    name: 'Tenant Admin',
  },
  tenant_user: {
    name: 'Tenant User',
  },
  client_admin: {
    name: 'Client Admin',
  },
  client_user: {
    name: 'Client User',
  },
  tenant_engineer: {
    name: 'FHI Engineer',
  },
  client_engineer: {
    name: 'Client Engineer',
  },
  tenant_sale: {
    name: 'Solution Consultant',
  },
  partner_admin: {
    name: 'Partner Admin',
  },
  partner_engineer: {
    name: 'Partner Engineer',
  },
};

export const TableauRoles = {
  tenant_admin: {
    name: 'FHI Admin',
    order: 0,
  },
  tenant_user: {
    name: 'FHI User',
    order: 1,
  },
  tenant_engineer: {
    name: 'FHI Engineer',
    order: 2,
  },
  client_admin: {
    name: 'Client Admin',
    order: 3,
  },
  client_user: {
    name: 'Client User',
    order: 4,
  },
  client_engineer: {
    name: 'Client Engineer',
    order: 5,
  },
  tenant_sale: {
    name: 'Solution Consultant',
    order: 6,
  },
  partner_admin: {
    name: 'Partner Admin',
  },
  partner_engineer: {
    name: 'Partner Engineer',
  },
};
export const TenantRoles = [
  'tenant_admin',
  'tenant_user',
  'tenant_engineer',
  'tenant_sale',
  'partner_admin',
  'partner_engineer',
];
export const ClientRoles = ['client_admin', 'client_user', 'client_engineer'];
