import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { BaseStoreService } from './base-store.service';
import { PmpeModel } from '../models/pmpe.model';
import { UserModel } from '../models/user.model';
import { DefaultListModel } from '../models/default-list.model';
import { map } from 'rxjs/operators';
import { ProjectModel } from '../models/project.model';
import { AttachmentModel } from '../models/attachment.model';
import { IBgJobModel } from '../models/bg-worker.model';

@Injectable()
export class ProjectsService<M> extends BaseStoreService<M> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, PmpeModel);
    this.resourceName = 'v1/projects';
  }

  public getAllowedProjects(forecast_id: any = null) {
    if (forecast_id) {
      return this.getItems(
        {},
        `/${this.resourceName}/changeable?forecast_id=${forecast_id}`
      );
    }
    return this.getItems({}, `/${this.resourceName}/changeable`);
  }

  public updateParams(model: ProjectModel): Observable<M> {
    return this._requestService.put(
      `/${this.resourceName}/${model.id}/additional`,
      { project: model }
    );
  }

  public updateAttachments(
    projectId: number,
    attachments_attributes: Array<AttachmentModel>
  ): Observable<M> {
    return this._requestService.put(
      `/${this.resourceName}/${projectId}/attachments`,
      { project: { attachments_attributes } }
    );
  }

  public updateProjectAssets(
    id: number,
    force: boolean = false
  ): Observable<any> {
    const queryParams = {};
    if (force) {
      queryParams['force'] = force;
    }
    const url = `/${this.resourceName}/${id}/update`;
    return this._requestService.put(url, {}, this.headers, false, queryParams);
  }

  public addToProject(url, body, params?): Observable<M> {
    return this._requestService.put(
      `/${this.resourceName}/${url}/append_assets`,
      body,
      this.headers,
      false,
      params
    );
  }

  public addPeriodToProject(projectId): Observable<M> {
    return this._requestService.post(
      `/${this.resourceName}/${projectId}/period`
    );
  }

  public createProject(url, body, params?): Observable<M> {
    url = url ? `/${this.resourceName}/${url}` : `/${this.resourceName}`;
    return this._requestService.post(url, body, this.headers, false, params);
  }

  public createProjectFromForecast(
    forecastId: number,
    body,
    params?
  ): Observable<IBgJobModel> {
    return this._requestService.post(
      `/${this.resourceName}/from_master_forecast/${forecastId}/async`,
      body,
      this.headers,
      false,
      params
    );
  }

  public deletePeriodToProject(projectId): Observable<M> {
    return this._requestService.delete(
      `/${this.resourceName}/${projectId}/period`
    );
  }

  public discardAllChanges(id: number): Observable<any> {
    const url = `/${this.resourceName}/${id}/discard_changes`;
    return this._requestService.put(url, {}, this.headers);
  }

  public getAllProjectsEditors(): Observable<UserModel[]> {
    return this._requestService.get(`/${this.resourceName}/editors`);
  }

  public getAllProjectsOwners(): Observable<UserModel[]> {
    return this._requestService.get(`/${this.resourceName}/owners`);
  }

  public getProjectOwners(projectId: number): Observable<UserModel[]> {
    return this._requestService.get(
      `/${this.resourceName}/${projectId}/owners`
    );
  }

  public updateProjectOwner(
    projectId: number,
    owner_id: number
  ): Observable<M> {
    return this._requestService.put(
      `/${this.resourceName}/${projectId}/owner`,
      { project: { owner_id } }
    );
  }

  public updateProjectStatus(projectId: number, params?): Observable<M> {
    return this._requestService.put(
      `/${this.resourceName}/${projectId}/status`,
      { project: { ...params } }
    );
  }

  public getNotArchivedAssets(
    projectId: number
  ): Observable<DefaultListModel<M>> {
    return this._requestService
      .get(
        `/${this.resourceName}/${projectId}/assets/not_archived`,
        undefined,
        this.headers,
        true
      )
      .pipe(
        map((data) => ({
          params: this.headersToParamsBag(data.headers),
          list: data.body,
        }))
      );
  }
  
  public getVirtualProjectAssetData(project_id, asset_id) {
    return this._requestService.get(
      `/${this.resourceName}/${project_id}/assets/id/${asset_id}`
    );
  }

  public verifyProjectName(name, id = null) {
    return this._requestService.get(`/${this.resourceName}/search?q=${name}${id? '&id='+id:''}`)
  }

  public getAllFiscalYears(): Observable<UserModel[]> {
    return this._requestService.get(`/v1/fiscal_years`);
  }
}
