import {
  AbstractControl,
  FormControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { Directive, Input, OnInit } from '@angular/core';

function validateAfterDot(value: number): ValidatorFn {
  const maxAfterDot = value;
  return (c: AbstractControl) => {
    const numberParts = c.value
        ? c.value.toString().replace(/,/g, '.').split('.')
        : null,
      numberAfterDot =
        numberParts && numberParts.length > 1 ? numberParts[1].length : null;
    const isValid = numberAfterDot && numberAfterDot <= maxAfterDot;
    if (!numberAfterDot || isValid) {
      return null;
    } else {
      return {
        afterdot: {
          valid: false,
          value,
        },
      };
    }
  };
}

@Directive({
  selector: '[appAfterDot]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: AfterDotValidator, multi: true },
  ],
})
export class AfterDotValidator implements Validator, OnInit {
  validator: ValidatorFn;
  @Input() appAfterDot: number;

  ngOnInit() {
    this.combineValidator();
  }

  private combineValidator(): void {
    this.validator = validateAfterDot(this.appAfterDot);
  }

  public validate(c: FormControl) {
    return this.validator(c);
  }
}
