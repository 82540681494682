import { DefaultDTO } from './default.dto';
import { AreaServeModel } from '../models/area-serve.model';

export class AreaServeDTO extends DefaultDTO {
  name: string;
  ra_modifier: number;
  uc_modifier: number;
  is_active: boolean;
  description: string;

  constructor(risk: AreaServeModel) {
    super();
    this.name = risk.name;
    this.ra_modifier = risk.ra_modifier;
    this.uc_modifier = risk.uc_modifier;
    this.is_active = risk.is_active;
  }
}
