import { Component, Input } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'app-gallery-loader',
  template: `
    <div class="flex" [ngStyle]="{ 'background-color': background }">
      <svg
        version="1.1"
        class="icon_inherit"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1000 1000"
        width="200px"
        height="200px"
      >
        <defs>
          <circle id="def-circle1" style="fill:#fff;" cx="0" cy="0" r="45" />
          <circle id="def-circle2" style="fill:#fff;" cx="0" cy="0" r="45" />
          <circle id="def-circle3" style="fill:#fff;" cx="0" cy="0" r="45" />
          <circle id="def-circle4" style="fill:#fff;" cx="0" cy="0" r="45" />
          <circle id="def-circle5" style="fill:#fff;" cx="0" cy="0" r="45" />
        </defs>

        <use xlink:href="#def-circle1" transform="translate(300 500)">
          <animateTransform
            attributeName="transform"
            type="scale"
            additive="sum"
            values="1 1; 0.7 0.7; 0.5 0.5; 0.5 0.5; 0.5 0.5; 0.7 0.7; 1 1"
            beg="0s"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </use>

        <use xlink:href="#def-circle2" transform="translate(400 500)">
          <animateTransform
            attributeName="transform"
            type="scale"
            additive="sum"
            values="0.7 0.7; 1 1; 0.7 0.7; 0.5 0.5; 0.5 0.5; 0.5 0.5; 0.7 0.7"
            beg="0s"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </use>

        <use xlink:href="#def-circle3" transform="translate(500 500)">
          <animateTransform
            attributeName="transform"
            type="scale"
            additive="sum"
            values="0.5 0.5; 0.7 0.7; 1 1; 0.7 0.7; 0.5 0.5; 0.5 0.5; 0.5 0.5"
            beg="0s"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </use>

        <use xlink:href="#def-circle4" transform="translate(600 500)">
          <animateTransform
            attributeName="transform"
            type="scale"
            additive="sum"
            values="0.7 0.7; 1 1; 0.5 0.5; 0.5 0.5; 0.5 0.5; 0.5 0.5; 0.7 0.7"
            beg="0s"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </use>

        <use xlink:href="#def-circle5" transform="translate(700 500)">
          <animateTransform
            attributeName="transform"
            type="scale"
            additive="sum"
            values="1 1; 0.7 0.7; 0.5 0.5; 0.5 0.5; 0.5 0.5; 0.7 0.7; 1 1"
            beg="0s"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </use>
        <text x="400" y="620" style="font: 50px Roboto; fill: #fff;">
          Loading...
        </text>
      </svg>
    </div>
  `,
  styleUrls: [],
})
export class GalleryLoaderComponent extends LoaderComponent {
  @Input() background: string = '#337AB7';

  constructor() {
    super();
  }
}
