export const SESSION_KEY = {
  assetPinnedNodes: 'assestsPinnedNodes',
};

export const SESSION_CONFIG = {
  // Idle wait time milliseconds to collect user action
  inactivityCheckFreqSec: 20, // 20 Sec
  appTimeoutInSeconds: 3 * 60 * 60,
  inactivityCounterKeyName: 'inactivity_counter',
};
