import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { RequestService } from './request.service';
import { PmTaskModel, PmTemplateModel } from '../models/pm-template.model';
import { DefaultIdNameModel } from '../models/default-id-name.model';

@Injectable({
  providedIn: 'root',
})
export class PmTemplateService<M> extends BaseStoreService<M> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, PmTemplateModel);
    this.resourceName = 'v1/pm_builder/universal/templates';
  }

  pmFrequency: DefaultIdNameModel[] = [
    { id: 10, name: 'Daily' },
    { id: 20, name: 'Weekly' },
    { id: 30, name: 'Monthly' },
    { id: 35, name: 'Quarterly' },
    { id: 40, name: 'Semi Annual' },
    { id: 50, name: 'Annual' },
  ];

  performanceWindow: DefaultIdNameModel[] = [
    { id: 1, name: '1 day' },
    { id: 2, name: '2 days' },
    { id: 3, name: '3 days' },
    { id: 4, name: '4 days' },
    { id: 5, name: '5 days' },
    { id: 6, name: '6 days' },
    { id: 7, name: '7 days' },
    { id: 8, name: '8 days' },
    { id: 9, name: '9 days' },
    { id: 10, name: '10 days' },
  ];

  setupTasksAndTrades(
    model: PmTemplateModel,
    taskList: PmTaskModel[],
    tradesList: DefaultIdNameModel[]
  ) {
    if (model.task_ids?.length) {
      model.tasks = [];
      model.task_ids.forEach((taskId) => {
        const tasksToAdd = taskList.filter((item) => item.id === taskId);
        model.tasks.push(...tasksToAdd);
      });
    }
    if (model.trade_id && tradesList.length) {
      const trade = tradesList.filter((item) => item.id === model.trade_id);
      model.trade = trade.length ? trade[0] : {};
    }
    return model;
  }

  copyMultipleToOrganization(templateIds: number[]) {
    const url = `/pm_builder/client/templates/copy_universal`,
      body: any = {
        universal_template_ids: templateIds,
      };
    return this._requestService.post(url, body, this.headers);
  }

  copyTemplateToOrganization(template) {
    const url = `/pm_builder/client/templates/copy_universal/${template.id}`,
      body: any = {
        template: template,
      };
    return this._requestService.post(url, body, this.headers);
  }

  assignAssetsToTemplate(assetIds, templateId) {
    const body = { asset_ids: assetIds };
    const url = `/pm_builder/client/templates/${templateId}/assign_assets`;
    return this._requestService.post(url, body, this.headers);
  }

  unassignAssetsToTemplate(assetIds, templateId) {
    const body = { asset_ids: assetIds };
    const url = `/pm_builder/client/templates/${templateId}/unassign_assets`;
    return this._requestService.delete(url, body, this.headers);
  }

  deleteMultiple(templateIds: Array<number>) {
    const body = { template_ids: templateIds };
    const url = `/pm_builder/client/templates/`;
    return this._requestService.delete(url, body, this.headers);
  }

  prepareIds(selectedItems: any) {
    const selectedIds = [];
    Object.keys(selectedItems).forEach((item) => {
      if (selectedItems[item]) {
        selectedIds.push(parseInt(item));
      }
    });
    // clear selected items
    selectedItems = {};
    return selectedIds;
  }

  getOrgTemplates() {
    return this._requestService.get('/pm_builder/client/templates');
  }

  updateNextMWO(body) {
    return this._requestService.put('/pm_builder/client/assets/wo_dates', {
      emitters: body,
    });
  }

  getTemplateAssetList(templateId: number, mode: string, params = {}) {
    return super.getItems(
      params,
      `/pm_builder/client/templates/${templateId}/assets/${mode}`
    );
  }

  public getResourceName(): string {
    return this.resourceName;
  }
}
