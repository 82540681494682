import { AuthService } from './auth.service';
import { EventService } from './event.service';
import { CustomNotificationService } from './custom-notification.service';
import { RequestService } from './request.service';
import { UserService } from './user.service';
import { UserStoreService } from './user.store';
import { UserCurrentService } from './user-current.service';
import { HelperService } from './helper.service';
import { UploadResourceService } from './upload-resource.service';
import { OrganizationStoreService } from './organization-store.service';
import { OrganizationCurrentService } from './organization-current.service';
import { ImageResizeService } from './image-resize.service';
import { AssetsService } from './assets.service';
import { FilterService } from './filter.service';
import { SearchService } from './search.service';
import { RisksService } from './risks.service';
import { LocalSessionService } from './local-session.service';
import { FHIModulesService } from './fhi-modules.service';
import { ServiceLogsService } from './service-log.service';
import { LocationService } from './location.service';
import { UniversalAssetTypesService } from './universal-asset-types.service';
import { ClientAssetTypesService } from './client-asset-types.service';
import { ComplianceService } from './compliance.service';
import { LoggingService } from './logging.service';
import { BudgetingService } from './budgeting.service';
import { ForecastService } from './forecast.service';
import { NavigationService } from './navigation.service';
import { RoutingService } from './routing.service';
import { IntegrationApiService } from './integration-api.service';
import { SessionService } from './session.service';
import { DuplicateModelService } from './duplicate-model.service';
import { ProjectsService } from './projects.service';
import { ProjectAssetsService } from './project-assets.service';
import { FacilityAttributesService } from './facility-attributes.service';
import { ProjectsAttachments } from './project-attachments';
import { PresetService } from './preset.service';
import { ReportsService } from './reports.service';
import { ErrorService } from './error.service';
import { BuildingDistributionService } from './building-distribution.service';
import { TableauSettingsService } from './tableau-settings.service';
import { VersionCheckService } from './version-check.service';
import { PmTemplateService } from './pm-template.service';
import { PmTaskService } from './pm-task.service';
import { CalculationErrorService } from './calculation-error.service';
import { CustomValidationService } from './custom-validation.service';
import { SnotifyService } from 'ng-alt-snotify';
import { BgJobService } from './bg-worker.service';
import { AreaServeRisksService } from './area-serve-risks.service';
import { AreaServeRisksUniversalService } from './area-serve-risks-universal.service';
import { AEService } from './ae.service';
import { WorkOrderService } from './work-order.service';
import { PMWorkOrderService } from './pm-work-order.service';
import { QlikSettingsService } from './qlik-settings.service';

export const services = [
  AuthService,
  BgJobService,
  EventService,
  SnotifyService,
  CustomNotificationService,
  RequestService,
  UserService,
  UserStoreService,
  UserCurrentService,
  HelperService,
  UploadResourceService,
  OrganizationStoreService,
  OrganizationCurrentService,
  ImageResizeService,
  AssetsService,
  FilterService,
  SearchService,
  RisksService,
  LocalSessionService,
  FHIModulesService,
  ServiceLogsService,
  LocationService,
  UniversalAssetTypesService,
  ClientAssetTypesService,
  ComplianceService,
  WorkOrderService,
  LoggingService,
  BudgetingService,
  ForecastService,
  NavigationService,
  RoutingService,
  IntegrationApiService,
  SessionService,
  DuplicateModelService,
  ProjectsService,
  PresetService,
  ProjectsAttachments,
  ProjectAssetsService,
  ReportsService,
  ProjectAssetsService,
  FacilityAttributesService,
  ErrorService,
  BuildingDistributionService,
  TableauSettingsService,
  VersionCheckService,
  PmTemplateService,
  PmTaskService,
  CalculationErrorService,
  CustomValidationService,
  AreaServeRisksService,
  AreaServeRisksUniversalService,
  AEService,
  PMWorkOrderService,
  QlikSettingsService
];
