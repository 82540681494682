import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';

import { AssetsService } from '../../../core/services/assets.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';
import { ImageResizeService } from '../../../core/services/image-resize.service';
import { InvestmentModel } from '../../../core/models/investment.model';
import { NgForm } from '@angular/forms';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { ServiceEventComponent } from '../service-event-modal/service-event-modal.component';
import { ServiceLogsService } from '../../../core/services/service-log.service';

@Component({
  selector: 'app-cii-modal',
  templateUrl: './cii-modal.component.html',
  styleUrls:['./cii-modal.component.less'],
})
export class CiiModalComponent extends ServiceEventComponent<InvestmentModel> {
  @ViewChild('modalForm', { static: true }) declare tdForm: NgForm;

  public ciiMode: 'dollars' | 'percent' = 'dollars';

  get customMax(): number {
    let customMaxCii = Math.floor(
      999999999.99 +
        parseFloat(this.model.investment_amount.toString()) -
        parseFloat((this.asset?.cii || 0).toString())
    );
    if (this.isCreate) {
      customMaxCii = Math.floor(
        999999999.99 - parseFloat((this.asset?.cii || 0).toString())
      );
    }
    return customMaxCii;
  }

  get customMaxToString(): string {
    return this.customMax < 0.1 ? '' : ` $${this.customMax.toString()}`;
  }

  constructor(
    protected activeModal: BsModalRef,
    protected store: ServiceLogsService<InvestmentModel>,
    protected _imageResizingService: ImageResizeService,
    protected _organizationCurrent: OrganizationCurrentService,
    protected _notificationService: CustomNotificationService,
    protected _assetsService: AssetsService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected _eventService: EventService,
    protected _errorService: ErrorService
  ) {
    super(
      activeModal,
      store,
      _imageResizingService,
      _organizationCurrent,
      _notificationService,
      _assetsService,
      router,
      activeRoute,
      _eventService,
      _errorService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = 'Capital Improvement Investment';
    // Setup
    this.isLoading = true;
    this.isTemplateDriven = true;
    this.store.service_type = 'capital_improvements';
    this.loadModel().subscribe(() => {
      this.model.score =
        this.model.assessment?.id ||
        this.asset.last_assessment?.score ||
        this.asset.fhie_score;
      this.model.asset_id = this.asset.id;
      this.isLoading = false;
      if (this.isCreate) {
        this.ciiMode = 'percent';
        this.assignAmount();
      } else {
        this.assignPercents();
      }
    });
  }

  public updateAmountPercentCorrelation(mode: 'dollars' | 'percent'): void {
    if (
      !this.model.investment_amount ||
      !this.asset.project_costs?.replacement_cost
    ) {
      return;
    }

    if (mode === 'dollars') {
      this.assignPercents();
    } else {
      this.assignAmount();
    }
  }

  protected createModel(data?): InvestmentModel {
    return new InvestmentModel(data);
  }

  private assignAmount(): void {
    this.model.investment_amount = Math.round(
      parseFloat(this.asset.project_costs.replacement_cost) *
        this.model.investment_percent
    );
  }

  private assignPercents(): void {
    this.model.investment_percent =
      this.model.investment_amount /
      Math.round(parseFloat(this.asset.project_costs.replacement_cost));
  }
}
