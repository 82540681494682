import { BehaviorSubject, Observable } from 'rxjs';
import {
  BudgetingGraphPointModel,
  BudgetingOptimalModel,
} from '../models/budgeting-data.model';

import { BaseStoreService } from './base-store.service';
import { BudgetModel } from '../models/budget.model';
import { BudgetingAssetModel } from '../models/budgeting-asset.model';
import { BudgetingConstants } from '../constants/budgeting.constants';
import { BudgetingDistributionModel } from '../models/budgeting-distribution.model';
import { DefaultListModel } from '../models/default-list.model';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { UserModel } from '../models/user.model';
import { map } from 'rxjs/operators';

@Injectable()
export class BudgetingService extends BaseStoreService<BudgetingAssetModel> {
  private headerCheckboxClickedSubject = new BehaviorSubject<boolean>(false);
  headerCheckboxClicked$ = this.headerCheckboxClickedSubject.asObservable();

  setHeaderCheckboxClicked(value: boolean): void {
    this.headerCheckboxClickedSubject.next(value);
  }

  private generateButtonStatusSubject = new BehaviorSubject<boolean>(false);
  generateButtonStatusSubject$ =
    this.generateButtonStatusSubject.asObservable();

  setGenerateButtonStatusSubject(value: boolean): void {
    this.generateButtonStatusSubject.next(value);
  }

  private updateHeaderCheckboxStateSubject = new BehaviorSubject<boolean>(
    false
  );
  updateHeaderCheckboxState$ =
    this.updateHeaderCheckboxStateSubject.asObservable();

  updateHeaderCheckboxState(value: boolean): void {
    this.updateHeaderCheckboxStateSubject.next(value);
  }
  constructor(protected _requestService: RequestService) {
    super(_requestService, BudgetingAssetModel);
    this.resourceName = 'v1/budgeting';
  }

  /**
   * Gets assets list for type
   * @param params
   * @param type - can be maintenance or replacement
   * @param id
   * @returns
   */
  public getItems(
    params: any = {},
    type: string,
    id?: number
  ): Observable<DefaultListModel<BudgetingAssetModel>> {
    let url: string;

    if (type === 'budgeting-list') {
      url = `${this.resourceName}`;
    } else if (id && type === 'pm_work_orders') {
      url = `${this.resourceName}/${id}/${type}`;
    } else if (id) {
      url = `${this.resourceName}/${type}/${id}/assets`;
    } else {
      url = `${this.resourceName}/${type}/assets`;
    }
    return super.getItems(params, url);
  }

  // to be done check if result is correct
  public getBudgetingData(): Observable<BudgetModel> {
    return this._requestService.get(`/${this.resourceName}/data`);
  }

  public getBudgetingDataById(id: number): Observable<BudgetModel> {
    return this._requestService.get(`/${this.resourceName}/${id}/data`);
  }

  public deleteBudgetingData(): Observable<any> {
    return this._requestService.delete(`/${this.resourceName}/reset`);
  }

  /**
   * Sends info about (un)checked items
   * @param list_type - can be maintenance or replacement
   * @param asset_ids
   * @param status - checked or unchecked (true/false)
   * @returns
   */
  public putCheckedItem(
    list_type: string,
    asset_ids: string,
    status: boolean
  ): Observable<Array<BudgetingAssetModel>> {
    return this._requestService.put(`/${this.resourceName}/asset_switcher`, {
      list_type,
      asset_ids,
      status,
    });
  }

  public putCheckedItemById(
    list_type: string,
    asset_ids: string,
    status: boolean,
    id: number
  ): Observable<Array<BudgetingAssetModel>> {
    return this._requestService.put(
      `/${this.resourceName}/${id}/asset_switcher`,
      {
        list_type,
        asset_ids,
        status,
      }
    );
  }

  /**
   * Requests projected FHI
   * @returns
   */
  public getProjectedFHI(): Observable<number> {
    return this._requestService
      .get(`/${this.resourceName}/projected_fhi_index`)
      .pipe(map((data) => parseFloat(data)));
  }
  public getProjectedFHIById(id: number): Observable<number> {
    return this._requestService
      .get(`/${this.resourceName}/${id}/projected_fhi_index`)
      .pipe(map((data) => parseFloat(data)));
  }
  /**
   * Request budgeting destribution for maintenance and replacement
   * @param maintenance_amount
   * @param replacement_amount
   * @returns
   */
  // to be done check if result is correct
  public getBudgetDistribution(
    maintenance_amount,
    replacement_amount
  ): Observable<BudgetingDistributionModel> {
    return this._requestService.put(
      `/${this.resourceName}/budget_distribution`,
      { replacement_amount, maintenance_amount }
    );
  }

  /**
   * Request budgeting distribution for maintenance and replacement by id
   * @param id - The unique identifier for the budget
   * @param maintenance_amount - The amount allocated for maintenance activities
   * @param replacement_amount - The amount allocated for replacement activities
   * @returns Observable that emits the budget distribution data
   */
  public getBudgetDistributionById(
    id: number,
    maintenance_amount,
    replacement_amount
  ): Observable<BudgetingDistributionModel> {
    return this._requestService.put(
      `/${this.resourceName}/${id}/budget_distribution`,
      { replacement_amount, maintenance_amount, pm_wo_status: true }
    );
  }

  /**
   * Requests normalized percent
   * @param amount
   * @returns
   */
  public getNormalizedPercent(amount): Observable<any> {
    return this._requestService.get(
      `/${this.resourceName}/maintenance/current_normalized`,
      { amount }
    );
  }

  public getNormalizedPercentById(amount, id): Observable<any> {
    return this._requestService.get(
      `/${this.resourceName}/maintenance/${id}/current_normalized`,
      { amount }
    );
  }

  /**
   * Requests funds optimal
   * @param params
   * @returns
   */
  // to be done check if result is correct
  public getFundsOptimal(params): Observable<BudgetingOptimalModel> {
    return this._requestService.post(`/${this.resourceName}/optimal`, params);
  }

  /**
   * Requests budgeting graph
   * @param graph_type - by builind/risk/overall
   * @param budget_type - maintenance/replacement
   * @param params
   * @returns
   */
  public getBudgetingGraphs(
    graph_type: string,
    budget_type?: string,
    params?
  ): Observable<Array<BudgetingGraphPointModel>> {
    let url = `/${this.resourceName}/${budget_type ? budget_type + '/' : ''}graph`;
    url =
      graph_type === BudgetingConstants.GRAPHS_TYPE.overall.key
        ? `${url}/${graph_type}`
        : `${url}/by_${graph_type}`;
    return this._requestService.get(url, params).pipe(
      map((success) => {
        return success.map((item) => new BudgetingGraphPointModel(item));
      })
    );
  }

  public updateBudgetAssetsData(id: number): Observable<BudgetModel> {
    return this._requestService.delete(`/${this.resourceName}/${id}/assets`);
  }

  public deleteBudgetById(id): Observable<BudgetModel> {
    return this._requestService.delete(`/${this.resourceName}/${id}`);
  }

  public getOwnersList(bugdetId: number): Observable<Array<BudgetModel>> {
    return this._requestService.get(`/${this.resourceName}/${bugdetId}/owners`);
  }

  public updateBudget(id, body): Observable<BudgetModel> {
    return this._requestService.put(`/${this.resourceName}/${id}/update`, body);
  }

  public getOwners(): Observable<UserModel> {
    return this._requestService.get(`/${this.resourceName}/owners`, {
      per_page: 9999,
      page: 1,
    });
  }

  generatePMWorkOrderById(id: number): Observable<any> {
    return this._requestService.post(`/${this.resourceName}/${id}/work_orders`);
  }
}
