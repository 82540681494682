import { AddressModel } from './address.model';
import { BudgetingBuildingModel } from './BudgetingBuildingModel';

export class BudgetingLocationModel {
  location_id?: number;
  address: AddressModel | string;
  id: number;
  fte?: number;
  location_name?: string;
  name?: string;
  maintenance_cost?: number;
  maintenance_hours?: number;
  replacement_cost?: number;
  total_maintenance_cost?: number;
  total_maintenance_hours?: number;
  total_replacement_cost?: number;
  total_expenses?: number;
  total_fte?: number;
  collapsed?: boolean;
  shouldBeUpdated?: boolean;
  buildings?: Array<BudgetingBuildingModel>;

  constructor(model) {
    if (model) {
      Object.assign(this, model);
    }
  }
}

