import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.less'],
})
export class TableSearchComponent implements AfterViewInit {
  @Input() public keyword: string;
  @Input() public disabled: boolean = false;
  @Input() public placeholder: string = 'Search...';
  @Output() onSearch = new EventEmitter<string>();
  subject: Subject<void> = new Subject<void>();
  pastKeyword: string;

  public search(): void {
    this.pastKeyword = this.keyword;
    this.onSearch.emit(this.keyword);
  }

  public searchIfChanged(): void {
    this.subject.next();
  }

  ngAfterViewInit() {
    this.subject
      .pipe(
        debounceTime(500),
        distinctUntilChanged(() => this.keyword === this.pastKeyword)
      )
      .subscribe(() => this.search());
  }

  public ifSearch() {
    return this.keyword?.length;
  }

  public clearSearch() {
    this.keyword = '';
    this.search();
  }
}
