import { AssetDTO } from '../dto/asset.dto';
import { AssetTypeModel } from './asset-type.model';
import { DefaultModel } from './default.model';
import { IAssetComment } from './asset.model';

export class BudgetingAssetModel extends DefaultModel<AssetDTO> {
  asset_id?: number;
  asset_name?: string;
  asset_uuid?: string;
  asset_number?: string;
  asset_type?: AssetTypeModel;
  asset_type_id?: number;
  building: {
    name: string;
    id: number;
  };
  building_name: string;
  cii_amount?: number;
  comment?: IAssetComment;
  current_fhi_index: number;
  fh_cost_estimate: string;
  fh_replacement_cost: string;
  fh_pm_frequency: number;
  generation?: number;
  id?: number;
  fhi_index: number;
  initial_data?: {
    replacement_status?: boolean;
    total_risk_rank: number;
    cost_estimate: number;
    replacement_cost: number;
    lifetime_days: number;
    recommended_cii?: number;
  };
  lifetime_end: string;
  location: {
    project_location_modifier?: any;
    name: string;
    id: number;
  };
  location_name: string;
  maintenance_status: boolean;
  name?: string;
  prev_replacement_status?: boolean;
  projected_fhi_index: number;
  replacement_cost?: number;
  replacement_status: boolean;
  replacement_date?: string;
  total_risk_rank: number;
  lifetime_end_date?: any;
  api_error?: string;
  editMode?: boolean;
  cost_estimate?: number;
  cost_center: any;
  public: boolean;
  created_at: string;
  updated_at: string;
  locked: boolean;
  snapshotted_at: string;
  editor: {
    id: number;
    name: string;
    is_active: boolean;
  };
  owner: {
    id: number;
    name: string;
    is_active: boolean;
  };
  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }

  public get entity_name(): string {
    return 'asset';
  }

  public dto(): AssetDTO {
    return new AssetDTO(this);
  }

  public toString(): string {
    return this.asset_name;
  }
}
