<div
  dropdown
  (isOpenChange)="toggled($event)"
  [insideClick]="insideClick"
  class="multiselect"
  [isDisabled]="isDisabled"
>
  <button
    type="button"
    class="btn-add-attribute btn-add-attribute_default"
    tooltip="Reset this filter"
    [disabled]="isDisabled"
    *ngIf="resetField && model?.length"
    (click)="resetFilter()"
  >
    <app-svg [href]="'times'" [iconClass]="'icon icon_xxs'"></app-svg>
    <span>{{ fieldName }}:</span>
  </button>
  <button
    id="button-{{ id }}"
    dropdownToggle
    [attr.aria-controls]="'dropdown-' + id"
    type="button"
    class="btn select multiselect__btn multiselect__btn_has-value"
    [ngClass]="{ 'form-block_error': error }"
  >
    {{ viewValues() }}
    <span
      *ngIf="model?.length && !singleValueSelected"
      class="multiselect__count"
    >
      ({{ model.length }})
    </span>
    <span class="multiselect__icon" *ngIf="!model?.length">
      <app-svg [href]="iconClass"></app-svg>
    </span>
  </button>

  <div
    id="dropdown-{{ id }}_dropdown"
    [ngClass]="{
      'dropdown-menu-right': rightSided,
      'multiselect__dropdown_menu-top': topDirection,
      'disable-top-level': disableTopLevel
    }"
    class="multiselect__dropdown"
    *dropdownMenu
    role="menu"
    [attr.aria-labelledby]="'button-' + id"
  >
    <header
      *ngIf="showSelectAll"
      class="multiselect__header-item flex flex_nowrap"
    >
      <p class="text-bold">{{ 'labels.select_all' | translate }}</p>
      <label class="custom-checkbox">
        <input
          class="custom-checkbox__input"
          type="checkbox"
          [(ngModel)]="selectAll"
          name="select_all"
          (click)="selectAllOptions($event)"
        />
        <span class="custom-checkbox__check"></span>
      </label>
    </header>
    <ul class="multiselect__options-list">
      <ng-container *ngIf="!oneLevel">
        <li
          role="menuitemcheckbox"
          class="{{ isCatSelected(option[key]) }} facility-multiselect__option"
          *ngFor="let option of searchData; let catIndex = index"
        >
          <button
            *ngIf="!disableTopLevel"
            (click)="selectCategory(option[key], catIndex)"
            type="button"
            class="facility-multiselect__item el-in-a-row el-in-a-row_c el-in-a-row_md el-in-a-row_nowrap"
          >
            <span class="facility-checkbox">
              <span class="facility-checkbox__check"></span>
            </span>
            <label class="text-semibold">{{ getPseudo(option) }}</label>
          </button>
          <button
            *ngIf="disableTopLevel"
            type="button"
            class="facility-multiselect__item text-semibold mrgn-b_sm"
          >
            {{ getPseudo(option) }}
          </button>
          <ul class="facility-multiselect--sublist">
            <li
              role="menuitemcheckbox"
              class="facility-multiselect__option"
              [ngClass]="{ facility__option_active: isTagSelected(tag[key]) }"
              *ngFor="let tag of option.tags"
            >
              <button
                type="button"
                (click)="selectTag(tag[key])"
                class="facility-multiselect__item el-in-a-row el-in-a-row_c el-in-a-row_md el-in-a-row_nowrap"
              >
                <span class="facility-checkbox">
                  <span class="facility-checkbox__check"></span>
                </span>
                <label
                  [ngStyle]="{
                    'background-color':
                      colorSchemes[option.color_scheme]?.background,
                    color: colorSchemes[option.color_scheme]?.text
                  }"
                  class="facility-multiselect__tag"
                >
                  {{ getPseudo(tag) }}</label
                >
              </button>
            </li>
          </ul>
        </li>
      </ng-container>
      <ng-container *ngIf="oneLevel">
        <li
          role="menuitemcheckbox"
          [ngClass]="{ facility__option_active: isSelected(option[key]) }"
          class="facility-multiselect__option"
          *ngFor="let option of searchData"
        >
          <button
            (click)="selectOption(option[key])"
            type="button"
            class="facility-multiselect__item el-in-a-row el-in-a-row_c el-in-a-row_md el-in-a-row_nowrap"
          >
            <span class="facility-checkbox">
              <span class="facility-checkbox__check"></span>
            </span>
            <label class="text-semibold">{{ getPseudo(option) }}</label>
          </button>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<div class="multiselect-error" *ngIf="error">
  <span class="form-error">
    {{ 'validation.required' | translate }}
  </span>
</div>
