import { DefaultDTO } from './default.dto';
import { DateTime } from 'luxon';

export class InvestmentDTO extends DefaultDTO {
  investment_date: Date;
  score: number;

  constructor(maintenance) {
    super();
    Object.assign(this, maintenance);
    // @ts-ignore
    this.investment_date = DateTime.fromISO(this.investment_date).toISO();
  }
}
