import { Injectable } from '@angular/core';
import { DateTime, Duration, DurationLikeObject } from 'luxon';
import { PERMISSIONS } from '../constants/permissions.constants';
import { KeyValue } from '@angular/common';

@Injectable()
export class HelperService {
  public static isObjExist(obj: Object): boolean {
    return !!Object.keys(obj).length;
  }

  public static keyValueOrderByValue(
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number {
    return a.value.localeCompare(b.value);
  }

  public static keyValueOriginalOrder(): number {
    return 0;
  }

  public static formatArrayToObject<M>(
    array: Array<M>,
    field: string,
    alterField?: string
  ): { [key: string]: M } {
    const data: any = {};
    array.forEach((item) => {
      if (item[field]) {
        data[item[field]] = item;
      } else {
        if (alterField) {
          data[item[alterField]] = item;
        }
      }
    });
    return data;
  }

  /**
   * Convert number to number with commas
   * @param number
   * @param separator
   * @returns {string}
   */
  public static convertToNumberWithCommas(
    number: number | string,
    separator: string = ','
  ): string {
    if (!number) {
      return '0';
    }
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    return parts.join('.');
  }

  public static convertToNumberWithoutCommas(number: number | string): number {
    if (!number) {
      return 0;
    }
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/(,)/g, '');
    return parseFloat(parts.join('.'));
  }

  public static parseMoney(number, separator: string = ',') {
    if (!number) {
      return '0';
    }

    let num: number = parseFloat(number);
    num = HelperService.toFixed(num, 2);
    return this.convertToNumberWithCommas(num.toFixed(2), separator);
  }

  public static parseMoneyInteger(
    number: number | string,
    separator: string = ','
  ): string {
    if (!number) {
      return '0';
    }

    const num: number = Math.round(Number(number));
    return this.convertToNumberWithCommas(num, separator);
  }

  public static parseExtremelySmallNumbers(
    number: number | string,
    isInteger: boolean = false,
    separator: string = ','
  ): string {
    if (+number > 0 && +number < 0.01) {
      return '0.01';
    }

    let result: string = '';
    if (number) {
      if (isInteger) {
        result = this.parseMoneyInteger(number, separator);
      } else {
        result = this.parseMoney(number, separator);
      }
    }
    return result;
  }

  public static toFixed(number: number | string, value: number): number {
    const res = +(Math.round(+(Number(number) + `e+${value}`)) + `e-${value}`);

    if (isNaN(res)) {
      return 0;
    }

    return res;
  }

  public static objToArray(obj: any): Array<any> {
    return Object.values(obj).map((value) => value);
  }

  public static alterEval(obj) {
    return Function('"use strict";return (' + obj + ')')();
  }

  /**
   * Converts flat object into array
   * @param entryEnum - source object
   * @param keys - unnecessary, defines field names for object entities in new array, default ['key', 'value']
   */
  public static convertEnumToArray(
    entryEnum: any,
    keys: Array<string> = ['key', 'value']
  ): any {
    const resultArray = [];
    Object.keys(entryEnum)
      .sort()
      .forEach((itemKey) => {
        const newObject = {};
        newObject[keys[0]] = isNaN(Number(itemKey)) ? itemKey : Number(itemKey);
        newObject[keys[1]] = entryEnum[itemKey];
        resultArray.push(newObject);
      });
    return resultArray;
  }

  public static convertEnumToArrayWithoutSorting(
    entryEnum: any,
    keys: Array<string> = ['key', 'value']
  ): any {
    const resultArray = [];
    Object.keys(entryEnum).forEach((itemKey) => {
      const newObject = {};
      newObject[keys[0]] = itemKey;
      newObject[keys[1]] = entryEnum[itemKey];
      resultArray.push(newObject);
    });
    return resultArray;
  }

  public static convertFloatToPercent(entryFloat: number | string): any {
    if (HelperService.isExist(entryFloat)) {
      return HelperService.toFixed(parseFloat(entryFloat.toString()) * 100, 6);
    }
    return null;
  }

  public static convertPercentToFloat(entryPercent: number | string): any {
    if (HelperService.isExist(entryPercent)) {
      return HelperService.toFixed(
        parseFloat(entryPercent.toString()) / 100,
        6
      );
    }
    return null;
  }

  static isNumber(value: any): boolean {
    if (typeof Number(value) === 'number' && this.isExist(value)) {
      return true;
    }

    return typeof value === 'number' && !Number.isNaN(value) && isFinite(value);
  }

  static getYears(date: any): string {
    if (date) {
      const years: number = Math.round(
        DateTime.now().diff(DateTime.local(date), 'years').as('years')
      );
      return `${years} year${years === 1 ? '' : 's'}`;
    }
  }

  static getWordEnding(time: number): string {
    return time === 1 ? '' : 's';
  }

  /**
   * Calculates useful life time
   * @returns {String}
   */
  static getUsefulLife(date: number): string {
    if (date) {
      const fullYears = Math.floor(date),
        residue = date - fullYears,
        months = Math.floor(12 * residue);
      let message = fullYears + ` year${this.getWordEnding(fullYears)}`;
      if (months && fullYears) {
        message += ',';
      }
      if (months) {
        message += ` ${months} month${this.getWordEnding(months)}`;
      }
      return message.length ? message : '&boxh;';
    }
  }

  static getTextDateDuration(date: string): string {
    if (date) {
      const luxonDate = DateTime.fromISO(date);
      let duration = DateTime.now().diff(luxonDate, ['years', 'months']);

      if (!Math.round(duration.as('years'))) {
        if (!Math.round(duration.as('months'))) {
          if (!Math.round(duration.as('days'))) {
            duration = DateTime.now().diff(luxonDate, ['hours', 'minutes']);
          } else {
            duration = DateTime.now().diff(luxonDate, ['days']);
          }
        } else {
          duration = DateTime.now().diff(luxonDate, ['years', 'days']);
        }
      }

      return this.getLuxonDurationHumanize(duration) + ' ago';
    }
  }

  static getLuxonDurationHumanize(duration: Duration): string {
    const timeObj: DurationLikeObject = duration.toObject();

    for (let prop in timeObj) {
      // @ts-ignore
      if (Object.hasOwn(timeObj, prop)) {
        if (!Math.round(timeObj[prop])) {
          delete timeObj[prop];
        } else {
          timeObj[prop] = Math.round(timeObj[prop]);
        }
      }
    }
    return Duration.fromObject(timeObj).toHuman({ listStyle: 'long' });
  }

  static hasPermissions(permission, userRole): boolean {
    if (
      userRole &&
      permission &&
      typeof PERMISSIONS[permission] !== 'undefined'
    ) {
      return PERMISSIONS[permission].some((role) => role === userRole);
    }
    return false;
  }

  static convertObjectToString(
    object,
    stringsToRemove: Array<string> = ['message:', '[', ']'],
    delimiter: string = '.'
  ) {
    let errorMessage = '';
    stringifyObject(object);
    if (stringsToRemove.length) {
      stringsToRemove.forEach((string) => {
        errorMessage = errorMessage.replace(string, '');
      });
    }
    const stringToCapitalize = errorMessage.split(delimiter);
    errorMessage = '';
    stringToCapitalize.forEach((string) => {
      const trimmedString = string.trim();
      if (trimmedString.length) {
        errorMessage += `${trimmedString.capitalize()}${delimiter} `;
      }
    });
    return errorMessage;
    function stringifyObject(obj) {
      if (typeof obj === 'undefined') {
        return '';
      }
      if (typeof obj === 'string' || typeof obj === 'number' || obj === null) {
        errorMessage += obj + delimiter;
      } else {
        const keys = Object.keys(obj);
        keys.forEach((key) => {
          errorMessage += key + ': ';
          stringifyObject(obj[key]);
        });
      }
    }
  }

  static slideUp(target, duration = 300) {
    if (!target) {
      return;
    }
    target.style.height = target.offsetHeight + 'px';
    target.classList.add('animating');
    setTimeout(() => {
      target.style.height = '0px';
      target.style.padding = '0px';
    }, 10);
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      target.parentElement &&
        target.parentElement.classList.remove('navigation__item_open');
      target.classList.remove('animating');
    }, duration);
  }

  static slideDown(target, duration = 300) {
    if (!target) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    target.parentElement &&
      target.parentElement.classList.add('navigation__item_open');
    target.classList.add('animating');
    target.style.height = target.scrollHeight + 'px';
    target.style.padding = '';
    setTimeout(() => {
      target.style.height = '';
      target.classList.remove('animating');
    }, duration);
  }

  static slideToggle(target, duration = 300) {
    if (!target || target.classList.contains('animating')) {
      return;
    }
    if (
      target.parentElement &&
      target.parentElement.classList.contains('navigation__item_open')
    ) {
      this.slideUp(target, duration);
    } else {
      this.slideDown(target, duration);
    }
  }

  static isExist(item) {
    return item !== null && item !== undefined && item !== '';
  }

  static getPeriodDuration(value: string[], shirt: boolean = false): string {
    const date1 = DateTime.fromISO(value[0]).toFormat('MMM d, yyyy');
    if (shirt) {
      return date1;
    }
    const date2 = DateTime.fromISO(value.at(-1)).toFormat('MMM d, yyyy');
    return `${date1} - ${date2} `;
  }

  static setValueIntoObject(obj: Object, path: string[], value) {
    switch (true) {
      case path.length === 1 && value !== undefined:
        return (obj[path[0]] = value);
      case !path.length:
        return obj;
      default:
        return this.setValueIntoObject(obj[path[0]], path.slice(1), value);
    }
  }

  static getObjValueByString(obj: Object, path: string) {
    let objCopy = structuredClone(obj);
    const pathPuzzles = path.split('.');

    for (const puzzle of pathPuzzles) {
      if (objCopy[puzzle] == undefined) {
        return undefined;
      } else {
        objCopy = objCopy[puzzle];
      }
    }
    return objCopy;
  }
}
