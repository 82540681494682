import { Component, Input, ViewChild } from '@angular/core';

import { AttachmentModel } from '../../../core/models/attachment.model';
import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { DateTime } from 'luxon';
import { EventService } from '../../../core/services/event.service';
import { HelperService } from '../../../core/services/helper.service';
import { NgForm } from '@angular/forms';
import { OrganizationModel } from '../../../core/models/organization.model';
import { UploadResourceService } from '../../../core/services/upload-resource.service';

@Component({
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.less'],
})
export class UploadFilesComponent extends BaseModalComponent {
  @Input() org: OrganizationModel;
  @Input() set model(value) {
    this._model = Object.assign({}, value);
  }
  @Input() accept?: string = 'image/*';
  @Input() title?: string = 'Upload ';
  @Input() fileType: string = 'file';

  @ViewChild('uploadForm', { static: true }) declare tdForm: NgForm;

  public dropzoneActive: boolean;
  public file: any;
  public progress: number;
  public minDate: any;
  public mode: string = 'update';
  public documentTypes: Array<{ key: string; name: string }> = [
    { key: 'agreement', name: 'Agreement' },
    { key: 'certificate', name: 'Certificate' },
    { key: 'license', name: 'License' },
    { key: 'permit', name: 'Permit' },
    { key: 'other', name: 'Other' },
  ];
  public remindIn: Array<{ key: number; name: string }> = [
    { key: 0, name: 'Never' },
    { key: 7, name: 'In 1 week' },
    { key: 14, name: 'In 2 weeks' },
    { key: 30, name: 'In 1 month' },
    { key: 60, name: 'In 2 months' },
  ];
  public isReminderLaterThanExpiration: boolean = false;

  private _model: AttachmentModel;

  get model(): AttachmentModel {
    return this._model;
  }

  get helper() {
    return HelperService;
  }

  constructor(
    protected modalInstance: BsModalRef,
    protected _uploadResourceService: UploadResourceService,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(modalInstance, _notificationService, _eventService);
  }

  ngOnInit() {
    this.isTemplateDriven = true;
    this.isLoading = true;
    this.mode = 'create';
    if (this.helper.isExist(this.model)) {
      this.mode = 'update';
      this.model.expires_at = this.model.expires_at
        ? DateTime.fromISO(this.model.expires_at).toISO()
        : undefined;
    } else {
      this.model = new AttachmentModel();
      if (this.fileType === 'document') {
        this.model.remind_in = 0;
        this.model.document_type = 'other';
        this.model.expires_at = DateTime.now().toISO();
      }
    }
    this.isLoading = false;
  }

  public dropzoneState(state: boolean): void {
    this.dropzoneActive = state;
  }

  public addFile($event: any): void {
    this.file = $event[0];
  }

  public removeFile(): void {
    this.file = undefined;
  }

  public submit(): void {
    this.markFormGroupTouched();
    if (this.tdForm.invalid) {
      return;
    }
    this.hasSubmitted = true;
    if (this.mode === 'update') {
      this.updateAttachment();
    } else {
      this.createAttachment();
    }
  }

  public updateAttachment(): void {
    this.modalInstance.hide();
    this.onResult.next(this.model);
  }

  public createAttachment(): void {
    this.progress = 0;
    this._uploadResourceService
      .uploadFile(this.file, 'tmp', this.org.id_hash)
      .subscribe((file) => {
        this.progress = file.progress;

        if (file.complete) {
          this.progress = undefined;
          this.modalInstance.hide();
          this.model.original_file_name = this.file.name;
          this.model.uploaded_s3key = file.object_key;
          this.onResult.next(this.model);
        }
      });
  }

  public checkReminderLaterThanExpiration() {
    if (this.model.remind_in !== '0') {
      const expireDate = DateTime.fromISO(this.model.expires_at).toISO();
      const notificationDate = DateTime.now()
        .plus({ day: +this.model.remind_in })
        .toISO();

      this.isReminderLaterThanExpiration = notificationDate > expireDate;
    } else {
      this.isReminderLaterThanExpiration = false;
    }
  }
}
