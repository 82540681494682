import { MaintenanceDTO } from '../dto/maintenance.dto';
import { IServiceLog } from './service-log.model';
import { DefaultModel } from './default.model';
import { DateTime } from 'luxon';

export class MaintenanceModel
  extends DefaultModel<MaintenanceDTO>
  implements IServiceLog
{
  asset: { id: number; name: string; asset_number: string };
  asset_number: string;
  id: number;
  name: string;
  building: { name: string };
  labor_cost: string;
  parts_cost: string;
  work_order_completed_at: string;
  work_order_number: string;
  pm_pe_number?: string;
  time_charge?: string;
  asset_id?: number;
  comment?: string;

  constructor(model) {
    super();
    if (model) {
      Object.assign(this, model);
      this.work_order_completed_at = DateTime.fromISO(
        model.work_order_completed_at
      ).toISO();
    }
    this.parts_cost = model ? model.parts_cost : 0;
  }

  public dto(): MaintenanceDTO {
    return new MaintenanceDTO(this);
  }

  public get entity_name(): string {
    return 'maintenance';
  }

  public toString(): string {
    return this.entity_name.capitalize();
  }
}
