import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlighttext',
  standalone: true,
})
export class HighlightTextPipe implements PipeTransform {
  transform(
    originalText: string,
    searchTermHightlight,
    cssClass: string = 'ag-grid-text-highlight'
  ): string {
    if (typeof originalText !== 'string' || !searchTermHightlight) {
      return originalText;
    }

    const uniqueSearchTerms: string[] = [searchTermHightlight];

    uniqueSearchTerms.forEach((searchText) => {
      const searchItem: string = searchText.replace(/%/g, '');

      const regex = new RegExp(`(${searchText})`, 'gi');

      originalText = searchItem
        ? originalText.replace(regex, (match) =>
            'span'.includes(match)
              ? match
              : `<span class=${cssClass}>${match}</span>`
          )
        : originalText;
    });
    return originalText;
  }
}
