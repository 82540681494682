import { AgCostComponent } from './cell-components/ag-cost/ag-cost.component';
import { AgLinkComponent } from './cell-components/ag-link/ag-link.component';
import { DefaultCellRendererComponent } from './cell-components/default-cell-renderer/default-cell-renderer.component';
import { GridOptions } from 'ag-grid-community';
import { AgCheckboxToggleComponent } from './cell-components/ag-checkbox-toggle/ag-checkbox-toggle.component';
import { PinnedRendererComponent } from './cell-components/pinned-renderer/pinned-renderer.component';

export const DEFAULT_GRID_OPTIONS: GridOptions = {
  rowSelection: 'multiple', // Enable multiple row selection
  suppressRowClickSelection: true, // Prevent row selection when the row is clicked
  suppressContextMenu: true, // Disable the context menu
  suppressGroupRowsSticky: true,
  enableCellTextSelection: true,
  ensureDomOrder: true,
  overlayNoRowsTemplate: `<span style="padding-top: 42px" class="no-items">No items</span>`,
  suppressAggFuncInHeader: true, // Suppress the default group column
  suppressAggAtRootLevel: true, // Suppress the default group column at root level
  autoSizeStrategy: {
    type: 'fitGridWidth',
  },
  suppressScrollOnNewData: true,
  suppressNoRowsOverlay: true,
};

export const AG_GRID_CELL_COMPONENTS = {
  checkboxToggle: AgCheckboxToggleComponent,
  cost: AgCostComponent,
  link: AgLinkComponent,
  default: DefaultCellRendererComponent,
  pinned: PinnedRendererComponent,
};
