<section class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header">{{ header }}</h2>
  </header>

  <form
    #confirmForm="ngForm"
    (ngSubmit)="submit()"
    (keydown.enter)="$event.preventDefault()"
  >
    <section class="modal-content__body">
      <fieldset *ngIf="message">
        <p class="modal-content__message mrgn-b_sm" [innerHTML]="message"></p>
        <ng-container *ngIf="fieldList && fieldList.length">
          <p class="modal-content__message" *ngFor="let field of fieldList">
            {{ field }}
          </p>
        </ng-container>
      </fieldset>
      <fieldset>
        <label for="userInput" class="modal-content__message mrgn-b_sm">
          To confirm, type '<b>{{ keyword.toUpperCase() }}</b
          >' in the field below:
        </label>

        <div class="modal-content__item modal-content__action-input mrgn-b">
          <input
            [(ngModel)]="userInput"
            [ngClass]="{ 'form-block_error': hasError(_userInput) }"
            required
            [isUppercaseEqual]="keyword"
            placeholder="Enter value"
            class="form-block__element"
            name="userInput"
            id="userInput"
            #_userInput="ngModel"
            type="text"
          />
          <p class="error" *ngIf="hasError(_userInput)">
            <ng-container *ngIf="_userInput.errors.required">
              {{ 'validation.required' | translate }}.
            </ng-container>
            <ng-container *ngIf="_userInput.errors.isNotEqual">
              {{ 'validation.invalid_value' | translate }}.
            </ng-container>
          </p>
        </div>
      </fieldset>
    </section>

    <footer class="buttons-block modal-content__footer">
      <button
        type="button"
        *ngIf="!hideCancel"
        (click)="cancel()"
        class="btn action-btn action-btn_{{ cancelClass }}"
      >
        <span class="action-btn__icon" *ngIf="!!cancelIcon">
          <app-svg [href]="'times'"></app-svg>
        </span>
        <span class="action-btn__title">{{ cancelText }}</span>
      </button>

      <button
        *ngIf="!hideSubmit"
        class="btn action-btn action-btn_{{ submitClass }}"
      >
        <span class="action-btn__icon" *ngIf="!!submitIcon">
          <app-svg [href]="submitIcon" iconClass="icon icon_s"></app-svg>
        </span>
        <span class="action-btn__title">{{ submitText }}</span>
      </button>
    </footer>
  </form>
</section>
