import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BaseReactiveForm } from '../../../../../shared/components/base-reactive-form';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ForecastService } from '../../../../../core/services/forecast.service';
import { ErrorService } from '../../../../../core/services/error.service';
import { UserCurrentService } from '../../../../../core/services/user-current.service';
import { IAssetComment } from '../../../../../core/models/asset.model';
import { CustomNotificationService } from '../../../../../core/services/custom-notification.service';
import { EventService } from '../../../../../core/services/event.service';
import { RequestService } from '../../../../../core/services/request.service';
import { AutoUnsubscribeService } from '../../../../../core/services/auto-unsubscribe.service';
import { takeUntil } from 'rxjs/operators';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { ForecastModel } from '../../../../../core/models/forecast.model';

@Component({
  templateUrl: './forecast-comment.component.html',
  providers: [
    {
      provide: ForecastService,
      useFactory: (
        _requestService: RequestService,
        _featureFlagsService: FeatureFlagsService
      ) => {
        return new ForecastService(
          _requestService,
          _featureFlagsService,
          ForecastService.isOld ? 'x_forecast' : 'forecast'
        );
      },
      deps: [RequestService, FeatureFlagsService],
    },
  ],
})
export class ForecastCommentComponent extends BaseReactiveForm<IAssetComment> {
  @Input() forecastId: number;
  @Input() isForecastPublic: boolean;
  @Input() assetId: number | string;
  @Input() generation: number;

  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService,
    protected _userService: UserCurrentService,
    protected _destroy: AutoUnsubscribeService,
    private forecastService?: ForecastService<ForecastModel>
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }

  ngOnInit() {
    this.setupForm();
  }

  public get isDeleteShown() {
    return (
      (this.isForecastPublic ||
        this._userService.user.id === this.model.author?.id) &&
      this.model?.id &&
      this.form.value.body
    );
  }

  public saveAction() {
    if (this.isFormValid) {
      const body = {
        body: this.form.value.body,
        generation: this.generation,
      };
      let request = this.forecastService.createAssetComment(
        body,
        this.forecastId,
        this.assetId
      );

      if (this.model.id) {
        request = this.forecastService.updateAssetComment(
          body,
          this.forecastId,
          this.assetId,
          this.model.id
        );
      }

      request.pipe(takeUntil(this._destroy)).subscribe({
        next: (res) => this.result(res),
        error: (err) => this.setErrorToControl(err),
      });
    }
  }

  public deleteComment() {
    this.forecastService
      .deleteAssetComment(this.forecastId, this.assetId, this.model.id)
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: () => this.result(),
        error: (err) => this.setErrorToControl(err),
      });
  }

  protected setupForm(model = this.model) {
    this.form = this.formBuilder.group({
      body: new FormControl(model?.body || '', [Validators.maxLength(500)]),
    });
  }
}
