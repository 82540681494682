export class RoutesConstants {
  public static AUTH = {
    LOGIN: '/auth/login',
    FORGOT_PASSWORD: '/auth/forgot_password',
    RESET_PASSWORD: '/auth/reset_password',
    REGISTER: '/auth/register',
    CALLBACK: '/auth/callback',
  };

  public static UPDATE = '/update';

  public static PROFILE = {
    DETAILS: (orgId?) => `${orgId ? '/' + orgId : ''}/profile`,
    EDIT: (orgId?) => `${orgId ? '/' + orgId : ''}profile/edit`,
  };

  public static NOT_FOUND = '/404';

  public static FORBIDDEN = '/403';

  public static TENANT_USERS = {
    LIST: '/tenant_users',
    DETAILS: (id) => `/tenant_users/${id}`,
  };

  public static USERS = {
    LIST: (org_id) => `/${org_id}/users`,
    DETAILS: (id, org_id) => `/${org_id}/users/${id}`,
  };

  public static HOME = '/dashboard';

  public static ORGANIZATION = {
    HOME: (id_hash) => {
      return `${id_hash}/dashboard`;
    },
    DETAILS: (id_hash) => {
      return `/${id_hash}/organization`;
    },
    DETAILS_ADMIN: (id_hash) => `/organizations/${id_hash}`,
    USER_DETAILS: (id_hash, id) => `/organizations/${id_hash}/users/${id}`,
    USER_EDIT: (id_hash, id) => `/organizations/${id_hash}/user/${id}/edit`,
    INVITE_ADMIN: (id_hash) => `/${id_hash}/organization/invite_client_admin`,
  };

  public static SETTINGS = {
    PROJECTS: (id_hash) => `/${id_hash}/settings/projects`,
    PROJECTS_EDIT: (id_hash) => `/${id_hash}/settings/projects/edit`,
  };

  public static ASSETS = {
    LIST: (id_hash, type?) => `/${id_hash}/assets` + (type ? `/${type}` : ''),
    DETAILS: (id_hash, id, type?) =>
      `/${id_hash}/assets` + (type ? `/${type}` : '') + `/${id}`,
    EDIT: (id_hash, id, type?) =>
      `/${id_hash}/assets` + (type ? `/${type}` : '') + `/${id}/edit`,
    NEW: (id_hash) => `/${id_hash}/assets/new`,
  };

  public static FACILITIES = {
    FACILITIES: (id_hash) => `/${id_hash}/facilities/structure`,
    ZONES: (id_hash) => `/${id_hash}/facilities/zones`,
  };

  public static INTEGRATION_API = {
    SETTINGS: '/api/settings',
    ROLLBACK: '/api/history',
    CMMS: '/settings/integrations',
  };

  static PREV_MAINTENANCE = '/settings/prev-maintenance';

  static PM_BUILDER = '/pm-builder/';

  public static UAR = {
    LIST: '/assets_repository',
    DETAILS: (id) => `/assets_repository/${id}`,
    EDIT: (id) => `/assets_repository/${id}/edit`,
    NEW: '/assets_repository/new',
  };
  public static CAR = {
    GENERAL: '/settings/car',
    LIST: (orgId) => `/${orgId}/settings/car`,
    DETAILS: (orgId, id) => `/${orgId}/settings/car/${id}`,
    EDIT: (orgId, id) => `/${orgId}/settings/car/${id}/edit`,
    NEW: (orgId) => `/${orgId}/settings/car/new`,
  };

  public static UFA = '/facility-attributes';
  public static TABLEAU_SETTINGS = '/tableau-settings';
  public static QLIK_SETTINGS = '/qlik-settings';

  public static RISKS = {
    UNIVERSAL: {
      LIST: '/risks/list',
    },
    CLIENT: {
      GENERAL: '/settings/risks',
      LIST: (orgId: string) => `/${orgId}/settings/risks`,
      EDIT: (id: number) => `/settings/risks/${id}`,
      CREATE: '/settings/risks/new',
      AREA_RISK_CREATE: '/settings/arearisk/add',
      AREA_RISK_EDIT: (id: number) => `/settings/arearisks/${id}`,
    },
  };
  public static TEMPLATES_DEFAULT = '/default_templates';
  public static BUDETING_LIST = '/budgeting/budgeting-list';
  public static BUDGETING = '/budgeting';
  public static QLIKBOARD = '/qlikboard';
  public static PROJECTS = '/projects';
  public static FORECAST = '/forecast';
  public static LOGS = '/logs';
  public static REPORTS = '/reports';

  public static COMPLIANCE = {
    EVENTS: '/compliance',
    REPORTS: '/compliance/reports',
    WORK_ORDERS: '/compliance/work_orders',
    TEMPLATES: '/compliance/templates',
  };

  public static PMWORKORDER = {
    PM_WORK_ORDERS: '/pm_work_orders',
  };
  public static MAPPING = '/settings/mapping';
  public static ACTIVITY_LOGS = '/settings/activity';
}

export class PathConstants {
  public static AUTH = {
    LOGIN: 'app.auth.login',
    FORGOT_PASSWORD: 'app.auth.forgot_password',
    RESET_PASSWORD: 'app.auth.reset_password',
    REGISTER: 'app.register',
  };

  public static HOME = 'app.dashboard';

  public static ORGANIZATION = 'app.organizations';

  public static TENANT_USERS = {
    LIST: 'app.users.list',
  };

  public static USERS = 'app.users';

  public static MY_ORGANIZATION = 'app.organization';
  public static UDF = 'app.project_settings';

  public static ASSETS = 'app.assets.general.list';
  public static ASSETS_GENERAL = 'app.assets.general.list';
  public static ASSETS_SCOPE = 'app.assets.scope.list';
  public static ASSETS_INCOMPLETE = 'app.assets.incomplete.list';
  public static ASSETS_ARCHIVE = 'app.assets.archive.list';
  public static ASSETS_ERROR = 'app.assets.errors.list';
  public static ASSETS_CUSTOM = 'app.assets.custom.list';
  public static ASSETS_WO_DATES = 'app.assets.wo-dates.list';
  public static FACILITIES = 'app.facilities';
  public static ZONES = 'app.zones';
  public static BUILDINGS = 'app.building_optics';

  public static MODULES = {
    FHI: 'FHI',
    FHIM: 'FHIM',
    FHIR: 'FHIR',
    FHIE: 'FHIE',
  };
  public static QLIKBOARD = 'app.analytics_dashboard';
  public static BUDGETING = 'app.budgeting';
  public static PROJECTS = 'app.project_builder';
  public static FORECAST = 'app.forecast';
  public static LOGS = 'app.logs';
  public static PM_BUILDER = 'app.pm_builder';
  public static UAR = 'app.assets_repository';
  public static TABLEAU_SETTINGS = 'app.tableau_settings';
  public static QLIK_SETTINGS = 'app.qlik_settings';

  public static INTEGRATION_API = {
    SETTINGS: 'app.integration_api',
    ROLLBACK: 'app.import_history',
    CMMS: 'app.cmms_integration',
  };
  public static PREV_MAINTENANCE = 'app.prev_maintenance';
  public static ACTIVITY_LOGS = 'app.activity';

  public static UFA = 'app.facility_attributes';
  public static CFA = 'app.facility_attributes';

  public static RISKS = {
    UNIVERSAL: 'app.risks.universal',
    CLIENT: 'app.risks.client',
  };
  public static TEMPLATES_DEFAULT = 'app.default_templates';

  public static REPORTS = 'app.reports';
  public static ANALYTICS_OVERVIEW = 'app.analytics_dashboard';

  public static COMPLIANCE = {
    WORK_ORDERS: 'app.compliance.work_orders',
    EVENTS: 'app.compliance.events',
    REPORTS: 'app.compliance.reports',
    TEMPLATES: 'app.compliance.templates',
  };

  public static PMWORKORDER = {
    PM_WORK_ORDERS: 'app.pm_work_orders',
  };

  public static MAPPING = 'app.mapping';
  public static CAR = 'app.car';

  public static getPath = (path) => {
    return { path };
  };
}
