import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { IBgJobModel } from '../models/bg-worker.model';
import { Observable } from 'rxjs/internal/Observable';
import { ErrorModel } from '../models/error.model';
import { DefaultListModel } from '../models/default-list.model';
import { map } from 'rxjs/operators';
import { BaseStoreService } from './base-store.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BgJobService<M> extends BaseStoreService<M> {
  constructor(protected _requestService: RequestService) {
    super(_requestService);
  }
  // 10 for pending, 20 for in progress and 30 for finished

  public isFinishedWithAnyResult(res: IBgJobModel): boolean {
    return res.status_code === 30;
  }

  public hasInProgressStatus(res: IBgJobModel): boolean {
    return (
      !res.success &&
      (res.status_code === 10 ||
        res.status_code === 20 ||
        res.status_code === 40)
    );
  }

  public hasSuccessStatus(res: IBgJobModel): boolean {
    return (
      res.success &&
      res.status_code === 30 &&
      !res.issues?.errors_count &&
      !res.issues?.warnings_count
    );
  }

  public hasWarningStatus(res: IBgJobModel): boolean {
    return (
      res.success && res.status_code === 30 && !!res.issues?.warnings_count
    );
  }

  public hasErrorStatus(res: IBgJobModel): boolean {
    return (
      !res.success &&
      (res.status_code === 30 || res.status_code === 50) &&
      !!res.issues?.errors_count
    );
  }

  public hasSystemError(res: IBgJobModel): boolean {
    return !res.success && !!res.details?.exception_error;
  }

  public checkBgJobStatus(
    uuid: string,
    orgId?: string
  ): Observable<IBgJobModel> {
    const headers = orgId
      ? { [environment.tokens.org_header]: orgId }
      : undefined;

    return this._requestService.get(
      `/v1/${orgId ? '/client' : ''}/bg_workers_manager/${uuid}`,
      undefined,
      headers
    );
  }

  public getBgJobIssues(
    uuid: string,
    entity: 'warnings' | 'errors',
    params,
    orgId: string
  ): Observable<DefaultListModel<ErrorModel>> {
    const headers = orgId ? { [environment.tokens.org_header]: orgId } : {};

    return this._requestService
      .get(
        `/v1/${
          orgId ? '/client' : ''
        }/bg_workers_manager/${uuid}/issues/${entity}`,
        params,
        { ...this.headers, ...headers },
        true
      )
      .pipe(
        map((data) => {
          return {
            list: data.body.map((issue) => ({
              ...issue.details,
              column_name: issue.entity_number,
            })),
            params: this.headersToParamsBag(data.headers),
          };
        })
      );
  }

  public cancelBgJob(uuid: string, orgId: string): Observable<IBgJobModel> {
    return this._requestService.delete(
      `/v1/${orgId ? '/client' : ''}/bg_workers_manager/${uuid}/cancel`,
      undefined,
      undefined,
      orgId ? { [environment.tokens.org_header]: orgId } : undefined
    );
  }
  public cancelForecast(forecastId) {
    return this._requestService.delete(`/v1/forecast/${forecastId}?force=true`);
  }
}
