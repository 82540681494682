<div class="chart-bar-component flex-component">
  <div class="chart-bar">
    <canvas
      baseChart
      width="1200"
      [id]="canvasId"
      [datasets]="chart.data"
      [labels]="chart.labels"
      [options]="chart.options"
      [plugins]="chart.plugins"
      [type]="chart.type"
    ></canvas>
  </div>

  <div class="text-center download-btn_wrapper">
    <button
      (click)="downloadImage()"
      class="btn action-btn action-btn_default"
      type="button"
    >
      <span class="action-btn__icon"
        ><app-svg [href]="'download'" iconClass="icon icon_s"></app-svg
      ></span>
      <span class="action-btn__title">Download</span>
    </button>
  </div>
</div>
