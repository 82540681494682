<form
  #fileImportForm="ngForm"
  novalidate
  (ngSubmit)="saveAction()"
  appInvalidControlScroll
>
  <fieldset class="content-loading" *ngIf="isLoading || !model; else elseBlock">
    <app-loader></app-loader>
  </fieldset>

  <ng-template #elseBlock>
    <fieldset class="board mrgn-b edit-list_sm edit-list">
      <header class="board__header">
        <h2 class="board__title">{{ isCreate ? 'New ' : model.name }}</h2>
      </header>
      <ul class="edit-list edit-list_sm">
        <!-- Integration Name -->
        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'integration_name' | translate }}<span class="asterisk">*</span>
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <input
                type="text"
                name="name"
                id="name"
                #_name="ngModel"
                placeholder="Enter..."
                class="form-block__element"
                [ngClass]="{ 'form-block_error': hasError(_name) }"
                [(ngModel)]="model.name"
                [customMaxlength]="120"
                required
              />
            </label>
            <p class="error" *ngIf="hasError(_name)">
              <ng-container class="form-error" *ngIf="_name.errors.required">
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container class="form-error" *ngIf="_name.errors.maxlength">
                {{ 'validation.maxlength' | translate }}
              </ng-container>
              <ng-container class="form-error" *ngIf="_name.errors.api">
                {{ _name.errors.api }}
              </ng-container>
            </p>
          </div>
        </li>
        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'integration_protocol' | translate
            }}<span class="asterisk">*</span>
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <app-select
                name="protocol"
                id="protocol"
                required
                [ngClass]="{ 'form-block_error': hasError(_protocol) }"
                #_protocol="ngModel"
                [(ngModel)]="model.protocol"
                placeholder="Select Protocol"
                [values]="protocolOptions"
              >
              </app-select>
            </label>
            <p class="error" *ngIf="hasError(_protocol)">
              <ng-container
                class="form-error"
                *ngIf="_protocol.errors.required"
              >
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container class="form-error" *ngIf="_protocol.errors.api">
                {{ _protocol.errors.api }}
              </ng-container>
            </p>
          </div>
        </li>

        <li class="edit-list__row" *ngIf="!isSystemFtp">
          <h3 class="xs-24 edit-list__title">
            {{ 'host' | translate }}<span class="asterisk">*</span>
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <input
                type="text"
                name="host"
                id="host"
                #_host="ngModel"
                placeholder="Enter..."
                class="form-block__element"
                [ngClass]="{ 'form-block_error': hasError(_host) }"
                [(ngModel)]="model.host"
                required
              />
            </label>
            <p class="error" *ngIf="hasError(_host)">
              <ng-container class="form-error" *ngIf="_host.errors.required">
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container class="form-error" *ngIf="_host.errors.api">
                {{ _host.errors.api }}
              </ng-container>
            </p>
          </div>
        </li>

        <li class="edit-list__row" *ngIf="!isSystemFtp">
          <h3 class="xs-24 edit-list__title">
            {{ 'port' | translate }}<span class="asterisk">*</span>
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <input
                type="number"
                name="port"
                id="port"
                required
                #_port="ngModel"
                placeholder="Enter..."
                class="form-block__element"
                [ngClass]="{ 'form-block_error': hasError(_port) }"
                [(ngModel)]="model.port"
              />
            </label>
            <p class="error" *ngIf="hasError(_port)">
              <ng-container class="form-error" *ngIf="_port.errors.required">
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container class="form-error" *ngIf="_port.errors.api">
                {{ _port.errors.api }}
              </ng-container>
            </p>
          </div>
        </li>
        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'pathToFiles' | translate }}<span class="asterisk">*</span>
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <input
                type="text"
                prefix="/"
                [showMaskTyped]="true"
                mask
                name="pathToFiles"
                id="pathToFiles"
                required
                #_pathToFiles="ngModel"
                placeholder="Enter..."
                class="form-block__element"
                [ngClass]="{ 'form-block_error': hasError(_pathToFiles) }"
                [(ngModel)]="model.paths.files"
              />
            </label>
            <p class="error" *ngIf="hasError(_pathToFiles)">
              <ng-container
                class="form-error"
                *ngIf="_pathToFiles.errors.required"
              >
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container class="form-error" *ngIf="_pathToFiles.errors.api">
                {{ _pathToFiles.errors.api }}
              </ng-container>
            </p>
          </div>
        </li>

        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'pathToProcessed' | translate }}<span class="asterisk">*</span>
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <input
                type="text"
                prefix="/"
                [showMaskTyped]="true"
                mask
                name="pathToProcessed"
                id="pathToProcessed"
                required
                #_pathToProcessed="ngModel"
                placeholder="Enter..."
                class="form-block__element"
                [ngClass]="{ 'form-block_error': hasError(_pathToProcessed) }"
                [(ngModel)]="model.paths.processed"
              />
            </label>
            <p class="error" *ngIf="hasError(_pathToProcessed)">
              <ng-container
                class="form-error"
                *ngIf="_pathToProcessed.errors.required"
              >
                {{ 'validation.required' | translate }}
              </ng-container>
              <ng-container
                class="form-error"
                *ngIf="_pathToProcessed.errors.api"
              >
                {{ _pathToProcessed.errors.api }}
              </ng-container>
            </p>
          </div>
        </li>
        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'pathToErrors' | translate }}<span class="asterisk">*</span>
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <input
                type="text"
                prefix="/"
                [showMaskTyped]="true"
                mask
                name="pathToErrors"
                id="pathToErrors"
                required
                #_pathToErrors="ngModel"
                placeholder="Enter..."
                class="form-block__element"
                [ngClass]="{ 'form-block_error': hasError(_pathToErrors) }"
                [(ngModel)]="model.paths.errors"
              />
            </label>
            <p class="error" *ngIf="hasError(_pathToErrors)">
              <ng-container
                class="form-error"
                *ngIf="_pathToErrors.errors.required"
              >
                {{ 'validation.required' | translate }}
              </ng-container>
            </p>
          </div>
        </li>
        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'pathToNewAssets' | translate }}<span class="asterisk">*</span>
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <input
                type="text"
                prefix="/"
                [showMaskTyped]="true"
                mask
                name="pathToNewAssets"
                id="pathToNewAssets"
                required
                #_pathToNewAssets="ngModel"
                placeholder="Enter..."
                class="form-block__element"
                [ngClass]="{ 'form-block_error': hasError(_pathToNewAssets) }"
                [(ngModel)]="model.paths.new_assets"
              />
            </label>
            <p class="error" *ngIf="hasError(_pathToNewAssets)">
              <ng-container
                class="form-error"
                *ngIf="_pathToNewAssets.errors.required"
              >
                {{ 'validation.required' | translate }}
              </ng-container>
            </p>
          </div>
        </li>
        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'pathToImages' | translate }}
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <input
                type="text"
                prefix="/"
                [showMaskTyped]="true"
                mask
                name="pathToImages"
                id="pathToImages"
                disabled
                class="form-block__element"
                [ngModel]="
                  model.paths.files ? model.paths.files + '/images' : ''
                "
              />
            </label>
          </div>
        </li>

        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'pathToProcessedImages' | translate }}
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <input
                type="text"
                prefix="/"
                [showMaskTyped]="true"
                mask
                name="pathToProcessedImages"
                id="pathToProcessedImages"
                disabled
                class="form-block__element"
                [ngModel]="
                  model.paths.processed ? model.paths.processed + '/images' : ''
                "
              />
            </label>
          </div>
        </li>

        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'pathToErrorsImages' | translate }}
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <input
                type="text"
                prefix="/"
                [showMaskTyped]="true"
                mask
                name="pathToErrorsImages"
                id="pathToErrorsImages"
                disabled
                class="form-block__element"
                [ngModel]="
                  model.paths.errors ? model.paths.errors + '/images' : ''
                "
              />
            </label>
          </div>
        </li>

        <li class="edit-list__row" *ngIf="!isSystemFtp">
          <h3 class="xs-24 edit-list__title">
            {{ 'username' | translate }}<span class="asterisk">*</span>
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <input
                type="text"
                name="userName"
                id="userName"
                required
                #_userName="ngModel"
                placeholder="Enter..."
                class="form-block__element"
                [ngClass]="{ 'form-block_error': hasError(_userName) }"
                [(ngModel)]="model.user_name"
              />
            </label>
            <p class="error" *ngIf="hasError(_userName)">
              <ng-container
                class="form-error"
                *ngIf="_userName.errors.required"
              >
                {{ 'validation.required' | translate }}
              </ng-container>
            </p>
          </div>
        </li>
        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'password' | translate }}<span class="asterisk">*</span>
          </h3>
          <div
            *ngIf="!isSystemFtp"
            class="edit-list__description edit-list__description_with-error"
          >
            <button
              *ngIf="!changePasswordState && !isCreate"
              class="btn action-btn action-btn_default"
              (click)="changePasswordState = !changePasswordState"
            >
              Change Password
            </button>
            <ng-container *ngIf="changePasswordState || isCreate">
              <div
                class="form-block__element flex_nowrap"
                [ngClass]="{ 'form-block_error': hasError(_password) }"
              >
                <input
                  [type]="passwordState ? 'text' : 'password'"
                  name="password"
                  id="password"
                  required
                  #_password="ngModel"
                  placeholder="Enter..."
                  [(ngModel)]="model.password"
                />
                <button
                  (click)="togglePasswordState()"
                  type="button"
                  class="btn_iconed"
                >
                  <app-svg [href]="'eye'"></app-svg>
                </button>
              </div>

              <p class="error" *ngIf="hasError(_password)">
                <ng-container
                  class="form-error"
                  *ngIf="_password.errors.required"
                >
                  {{ 'validation.required' | translate }}
                </ng-container>
                <ng-container class="form-error" *ngIf="_password.errors.api">
                  {{ _password.errors.api }}
                </ng-container>
              </p>
            </ng-container>
          </div>
          <div *ngIf="isSystemFtp && !isCreate" class="edit-list__description">
            <button
              class="btn action-btn action-btn_default"
              type="button"
              [disabled]="resetPasswordApplied"
              (click)="resetPassword()"
            >
              Reset Password
            </button>
          </div>
        </li>
        <li class="edit-list__row" *ngIf="!isSystemFtp">
          <div class="xs-24 edit-list__title el-in-a-row el-in-a-row_c">
            <h3>{{ 'labels.integrations.private_key' | translate }}</h3>
            <button
              type="button"
              [tooltip]="'tooltips.private_key' | translate"
              placement="right"
              class="btn btn_iconed tooltip-btn"
            >
              <app-svg [href]="'info-circle'" iconClass="icon icon_s"></app-svg>
            </button>
          </div>
          <div class="edit-list__description edit-list__description_with-error">
            <button
              *ngIf="!showPrivateKey && !isCreate"
              class="btn action-btn action-btn_default"
              (click)="editPrivateKey()"
            >
              {{ isPrivateKeyExist ? 'Change private key' : 'Add private key' }}
            </button>
            <label *ngIf="showPrivateKey || isCreate" class="form-block">
              <textarea
                type="text"
                name="ssh_key"
                id="ssh_key"
                placeholder="Enter value"
                class="form-block__element"
                #_privateKey="ngModel"
                [(ngModel)]="model.ssh_key"
                [ngClass]="{ 'form-block_error': hasError(_privateKey) }"
              >
              </textarea>

              <p class="error" *ngIf="hasError(_privateKey)">
                <ng-container class="form-error" *ngIf="_privateKey.errors.api">
                  {{ _privateKey.errors.api }}
                </ng-container>
              </p>
            </label>
          </div>
        </li>
        <li class="edit-list__row">
          <div class="xs-24 edit-list__title el-in-a-row el-in-a-row_c">
            <h3>{{ 'email' | translate }}</h3>
            <button
              type="button"
              [tooltip]="'integration_api_email_tip' | translate"
              placement="right"
              class="btn btn_iconed tooltip-btn"
            >
              <app-svg [href]="'info-circle'" iconClass="icon icon_s"></app-svg>
            </button>
          </div>
          <div class="edit-list__description">
            <ul class="edit-list__description">
              <li
                class="mrgn-b_sm"
                *ngFor="
                  let emailItem of model.emails;
                  let $index = index;
                  trackBy: trackByIndex
                "
              >
                <div class="el-in-a-row el-in-a-row_c">
                  <label class="form-block">
                    <input
                      [(ngModel)]="model.emails[$index]"
                      required
                      name="email-{{ $index }}"
                      type="email"
                      class="form-block__element"
                      [pattern]="regex.email"
                      placeholder="Enter email"
                      #_email="ngModel"
                      [ngClass]="{ 'form-block_error': hasError(_email) }"
                      [customMaxlength]="200"
                      appTrimOnBlur
                    />
                  </label>
                  <button
                    type="button"
                    class="btn btn_iconed"
                    *ngIf="model.emails.length > 1"
                    (click)="removeEmail($index)"
                  >
                    <app-svg [href]="'trash'"></app-svg>
                  </button>
                </div>

                <p class="error" *ngIf="hasError(_email)">
                  <ng-container *ngIf="_email.errors.required">
                    {{ 'validation.required' | translate }}
                  </ng-container>
                  <ng-container *ngIf="_email.errors.maxlength">
                    {{ 'validation.maxlength' | translate }}
                  </ng-container>
                  <ng-container *ngIf="_email.errors.pattern">
                    {{ 'validation.bad_email' | translate }}
                  </ng-container>
                  <ng-container *ngIf="_email.errors.unique">
                    {{
                      'validation.unique_field'
                        | translate: { type_name: 'Risk', field_name: 'name' }
                    }}
                  </ng-container>
                  <ng-container *ngIf="_email.errors.api">
                    {{ _email.errors.api }}
                  </ng-container>
                </p>
              </li>
            </ul>

            <button
              type="button"
              class="btn-add-attribute btn-add-attribute_blue"
              (click)="addEmail()"
            >
              <app-svg [href]="'plus'"></app-svg>
              <span>Add Email</span>
            </button>
          </div>
        </li>
        <li class="edit-list__row">
          <h3 class="xs-24 edit-list__title">
            {{ 'integrations.delete_files' | translate }}
          </h3>
          <div class="edit-list__description edit-list__description_with-error">
            <label class="form-block">
              <app-select
                [(ngModel)]="model.file_exp_days"
                name="file_exp_days"
                [showPlaceholder]="false"
                [values]="configs.FILE_EXPIRATION_DAYS"
              >
              </app-select>
            </label>
          </div>
        </li>
      </ul>
    </fieldset>

    <fieldset
      class="board mrgn-b edit-list_sm edit-list"
      *appPermission="'ta_ca_pa'"
    >
    <legend style="display: none">File Import Edit Information</legend>
      <header class="board__header">
        <h2 class="board__title">Exportable Asset Attributes</h2>
      </header>
      <div class="header flex flex_to-start">
        <ul class="el-in-a-row el-in-a-row_c">
          <li>
            <label class="custom-checkbox">
              <input
                class="custom-checkbox__input"
                [(ngModel)]="selectedGroups.all"
                (ngModelChange)="selectAll($event)"
                name="select.all"
                type="checkbox"
              />
              <span
                class="custom-checkbox__check custom-checkbox__check_labeled"
              ></span
              ><span class="secondary-font__title">Select All</span>
            </label>
          </li>
          <li class="el-in-a-row el-in-a-row_c el-in-a-row_md">
            <label class="custom-toggle mrgn-l">
              <input
                class="custom-toggle__input"
                [(ngModel)]="selectedGroups.risks"
                (ngModelChange)="selectGroup($event, 'risks')"
                name="select.risks"
                type="checkbox"
              />
              <span class="custom-toggle__check custom-toggle__check_lock"
                ><span class="custom-toggle__toggler"></span
              ></span>
            </label>
            <h4 class="secondary-font__title">Risks</h4>
            <button
              type="button"
              placement="right"
              [tooltip]="'integration_api_ftp_group_tip' | translate"
              class="btn btn_iconed tooltip-btn"
            >
              <app-svg [href]="'info-circle'"></app-svg>
            </button>
          </li>
          <li class="el-in-a-row el-in-a-row_c el-in-a-row_md">
            <label class="custom-toggle mrgn-l">
              <input
                class="custom-toggle__input"
                [(ngModel)]="selectedGroups.udfs"
                (ngModelChange)="selectGroup($event, 'udfs')"
                name="select.custom_attributes"
                type="checkbox"
              />
              <span class="custom-toggle__check custom-toggle__check_lock"
                ><span class="custom-toggle__toggler"></span
              ></span>
            </label>
            <span class="secondary-font__title">{{
              'custom_a_attributes' | translate
            }}</span>
            <button
              type="button"
              placement="right"
              [tooltip]="'integration_api_ftp_group_tip' | translate"
              class="btn btn_iconed tooltip-btn"
            >
              <app-svg [href]="'info-circle'"></app-svg>
            </button>
          </li>
        </ul>
      </div>
      <section class="flex flex-a-stretch flex_to-start block_bullet-wrapper">
        <div class="text-items--list-wrapper" *ngFor="let group of columns">
          <ul class="text-items--list" *ngFor="let column of group">
            <div class="text-items--header">
              <h3 class="secondary-font__title">{{ column.title }}</h3>
            </div>
            <li
              *ngFor="let item of column.items; let _index = index"
              class="text-items--item"
            >
              <label class="custom-checkbox">
                <input
                  class="custom-checkbox__input"
                  [ngSwitch]="item.checked"
                  [(ngModel)]="item.checked"
                  (ngModelChange)="checkItem($event, item)"
                  name="{{ column.name }}_{{ _index }}"
                  type="checkbox"
                />
                <span
                  class="custom-checkbox__check custom-checkbox__check_labeled"
                ></span>
                {{ item.title }}
              </label>
            </li>
          </ul>
        </div>
      </section>
    </fieldset>
  </ng-template>
  <footer class="buttons-block buttons-block_list">
    <button
      type="button"
      (click)="cancelEdit()"
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">{{ 'buttons.cancel' | translate }}</span>
    </button>
    <button class="btn action-btn action-btn_success">
      <span class="action-btn__icon"><app-svg [href]="'check'"></app-svg></span>
      <span class="action-btn__title">{{ 'buttons.save' | translate }}</span>
    </button>
  </footer>
</form>
