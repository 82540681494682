import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from './core/services/navigation.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { VersionCheckService } from './core/services/version-check.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { environment } from '../environments/environment';
import { Settings } from 'luxon';
import { TranslateService } from '@ngx-translate/core';
import { datadogRum } from '@datadog/browser-rum';
import { FeatureFlagsService } from './core/services/feature-flags.service';
import { RequestService } from './core/services/request.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  protected subscriptions: Subscription = new Subscription();

  constructor(
    private _navigationService: NavigationService,
    private _versionCheckService: VersionCheckService,
    private titleService: Title,
    private googleAnalytics: GoogleAnalyticsService,
    _translateService: TranslateService,
    private _featureFlagsService: FeatureFlagsService,
    private _requestService: RequestService
  ) {
    _translateService.setDefaultLang('en');
    _translateService.use('en');
    this._versionCheckService.checkForUpdates();
    this._versionCheckService.checkIfIE();
  }
  public ngOnInit() {
    if(this._featureFlagsService.isFeatureEnabled('OR_12510_Auth0_Login')){
      this._requestService.refresh_auth_token()
    }
    if (this._featureFlagsService.isFeatureEnabled('web_datadog_metrics')) {
      datadogRum.init({
        applicationId: 'efe5f5cc-c7d3-4843-b425-83adbaa7701d',
        clientToken: 'pub0bc9a316a627de4a93ab86c3e51c33cf',
        site: 'datadoghq.com',
        service: 'fhi-prod',
        env: 'prod',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }

    Settings.defaultLocale = 'en-US';
    this.googleAnalytics.init();
    this.subscriptions.add(
      this._navigationService.currentPathObservable.subscribe((curPath) => {
        this.parsePathToTitle(curPath);
        window.scrollTo(0, 0);
        this.checkForIe11();
      })
    );
    const script = document.createElement('script');
    script.innerHTML = `
    (function(apiKey){
      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
      v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
          y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
          z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);
      })(window,document,'script','pendo');
    })('${environment.pendoApiKey}');
    `;
    document.head.appendChild(script);
  }

  public checkForIe11() {
    if (window.navigator.userAgent.indexOf('rv:11') > -1) {
      document.body.classList.add('ie11');
    }
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private parsePathToTitle(pathToTitle: string | object) {
    if (typeof pathToTitle === 'object' && pathToTitle['path']) {
      pathToTitle = pathToTitle['path'];
    }
    let title = 'Origin ';
    if (pathToTitle) {
      title += ' - ';
      let pathParts: any[];
      if (typeof pathToTitle === 'string') {
        pathParts = pathToTitle.split('.');
      } else {
        pathParts = [];
      }
      pathParts = pathParts.filter((part) => part !== 'app');
      pathParts.forEach((part) => {
        const subtitle = part
          .split('_')
          .map((subpart) => subpart.capitalize())
          .join(' ');
        title += ` ${subtitle}`;
      });
    }
    this.titleService.setTitle(title);
  }
}
