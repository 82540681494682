import { DefaultDTO } from './default.dto';
import { AssetTypeModel } from '../models/asset-type.model';
import { HelperService } from '../services/helper.service';

export class ProjectAssetDTO extends DefaultDTO {
  id: number;
  name: string;
  title?: string;
  asset_number: number;
  asset_cost: {
    value: number;
    oldValue: number;
  };
  location_modifier: number;
  expenses_general_sum: {
    value: number;
    oldValue: number;
  };
  selected: boolean;
  asset_type?: AssetTypeModel;
  assets_repository_id: number;
  quantity: number;
  fhi: number;
  location: Location;
  origin_entity: {
    asset_id: number;
    complete: boolean;
    archived: boolean;
    existent: boolean;
  };
  expenses_general: {
    engineering_fees: string;
    permits: string;
  };

  constructor(asset: any) {
    super();
    this.asset_number = asset.asset_number;
    this.name = asset.name;
    this.quantity = asset.quantity;
    this.assets_repository_id = asset.assets_repository_id;
    this.asset_type = asset.asset_type;
    this.location_modifier = HelperService.convertPercentToFloat(
      asset.location_modifier
    );
    this.origin_entity = asset.origin_entity;
    this.expenses_general = asset.expenses_general;
  }
}
