import { DefaultDTO } from './default.dto';
import { PictureModel } from '../models/picture.model';

export class AssetDTO extends DefaultDTO {
  pictures_attributes: Array<PictureModel>;
  asset_number: number;
  name: string;
  description?: string;
  manufacturer?: string;
  location_id: number;
  building_id: number;
  floor_name?: string;
  room_name?: string;
  zone_name?: string;
  installed_on: string;
  barcode?: string;
  classification_id: number;
  cost_center: string;
  parent_asset_number: string;
  total_risk_rank: number;
  area_served_id: number;

  quantity?: number;
  fh_cost_estimate?: number;
  assets_repository_id?: number;
  funding_source_id?: number;
  trade_name?: string;

  udfs: any;
  assets_risks_attributes: any;

  model_number: string;
  serial_number: string;
  verify?: boolean;
  verify_notes?: string;
  project_status: number;
  warnings?: Array<any>;
  ignore_fs_threshold: boolean;

  constructor(asset: any) {
    super();
    this.pictures_attributes = asset.pictures_edit;
    this.asset_number = asset.asset_number;
    this.name = asset.name;
    this.description = asset.description;
    this.manufacturer = asset.manufacturer;
    this.location_id = asset.location_id;
    this.building_id = asset.building_id;
    this.floor_name = asset.floor_name;
    this.room_name = asset.room_name;
    this.zone_name = asset.zone_name;
    this.installed_on = asset.installed_on;
    this.barcode = asset.barcode;
    this.classification_id = asset.classification?.id;
    this.quantity = asset.quantity;
    this.fh_cost_estimate = asset.fh_cost_estimate;
    this.assets_repository_id = asset.asset_type?.id;
    this.trade_name = asset.trade?.name;
    this.udfs = this.removeEmptyUdfs(asset.udfs);
    this.assets_risks_attributes = asset.assets_risks;
    this.model_number = asset.model_number;
    this.serial_number = asset.serial_number;
    this.verify = asset.verify;
    this.verify_notes = asset.verify_notes;
    this.area_served_id = asset.area_served_id;
    this.funding_source_id = asset.funding_source?.id;
    this.ignore_fs_threshold = asset.ignore_fs_threshold;
    this.cost_center = asset.cost_center;
    this.parent_asset_number = asset.parent_asset_number;
  }

  public removeEmptyUdfs(udfs) {
    for (const key in udfs) {
      if (udfs[key] === '') {
        delete udfs[key];
      }
    }
    return udfs;
  }
}
