import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  public transform(str): string {
    if (!str && typeof str !== 'string') return;
    return (str ? str[0] : '').toUpperCase() + str.slice(1);
  }
}
