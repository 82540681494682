import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';
import { Component } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  template: ` <div class="custom-tooltip" [style.background-color]="color">
    <div>{{ params.value }}</div>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip {
        color: white;
        height: auto;
        max-height: 300px;
        width: auto;
        max-width: 300px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        word-wrap: break-word;
        white-space: normal;
        text-align: center;
        border-radius: 3px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      }

      .custom-tooltip p {
        margin: 5px;
        white-space: nowrap;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }
    `,
  ],
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  public params!: { color: string } & ITooltipParams;
  public color!: string;

  agInit(params: { color: string } & ITooltipParams): void {
    this.params = params;
    this.color = this.params.color || '#002743';
  }
}
