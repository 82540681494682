import { Component, Input } from '@angular/core';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EventService } from '../../../core/services/event.service';
import { BaseReactiveForm } from '../../components/base-reactive-form';
import { UserModel } from '../../../core/models/user.model';
import { FormBuilder, Validators } from '@angular/forms';
import { ErrorService } from '../../../core/services/error.service';
import { DateTime } from 'luxon';
import { IExportParams } from '../../../core/models/export-params.model';

@Component({
  templateUrl: './period-export.modal.component.html',
})
export class PeriodExportModalComponent extends BaseReactiveForm<UserModel> {
  @Input() public orgId: string;
  @Input() public header: string = 'Export';
  @Input() public type: string;
  @Input() public exportAddOns: IExportParams;

  public status: 'static' | 'in-progress' = 'static';

  public get minDate() {
    return DateTime.fromISO(this.form.value['date_from']) > DateTime.now()
      ? null
      : this.form.value['date_from'];
  }

  public get params() {
    this.form.value['date_to'] = DateTime.fromISO(
      this.form.value['date_to']
    ).endOf('day');
    return { ...this.form.value, ...this.exportAddOns };
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }

  ngOnInit() {
    this.setupForm();
  }

  public saveAction() {
    if (this.isFormValid) {
      this.status = 'in-progress';
    }
  }

  protected setupForm() {
    this.form = this.formBuilder.group({
      date_from: ['', [Validators.required]],
      date_to: ['', [Validators.required]],
    });
  }
}
