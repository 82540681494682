<table class="table table-striped table-hover table_bordered mrgn-b">
  <thead class="table__header">
    <tr>
      <th class="import-error">{{ entity.slice(0, -1).capitalize() }}</th>
      <th>{{ entityColumnName }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let _item of itemsArrPlug; let _i = index">
      <td class="import-error">{{ getErrorMessage(this.items[_i]) }}</td>
      <td>{{ this.items[_i].column_name }}</td>
    </tr>
  </tbody>
</table>

<footer *ngIf="callIssueListApi" class="flex-column flex-a-end">
  <app-pagination-caption
    [start]="getStartPageItem()"
    [end]="getEndPageItem()"
    [total]="total"
    class="mrgn-b_sm"
  >
  </app-pagination-caption>
  <app-pagination
    [hasLeftDots]="hasLeftDots"
    [hasRightDots]="hasRightDots"
    [hasNextPage]="hasNextPage"
    [hasPrevPage]="hasPrevPage"
    [page]="page"
    [pages]="pages"
    [shouldPassPageNumber]="false"
    [totalPages]="totalPages"
    (onNextPage)="nextPage()"
    (onPrevPage)="prevPage()"
    (onSetPage)="setPage($event)"
  >
  </app-pagination>
</footer>
