import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { RequestService } from './request.service';
import { CustomNotificationService } from './custom-notification.service';
import { Observable } from 'rxjs';
import { DefaultListModel } from '../models/default-list.model';
import { FacilityCategoryModel } from '../models/facility-category.model';

@Injectable()
export class FacilityAttributesService<M> extends BaseStoreService<M> {
  constructor(
    protected _requestService: RequestService,
    protected _notificationService: CustomNotificationService
  ) {
    super(_requestService, FacilityCategoryModel);
    this.resourceName = 'v1/facilities';
  }

  public getRegionalFactors(regionalName, postalCode): Observable<any> {
    const url = `/processor-api/regionalfactor`;
    return this._requestService.get(url, { postalCode, regionalName });
  }

  public getFacilityCategories(
    isUFA: boolean,
    params?
  ): Observable<DefaultListModel<M>> {
    const subUrl = isUFA ? '/universal' : '';
    const serviceParams = this.checkParams(params);
    const url = `${this.resourceName}${subUrl}/categories`;
    return super.getItems(serviceParams, url);
  }

  public updateFacilityCategories(
    isUFA: boolean,
    body,
    params?
  ): Observable<M> {
    const subUrl = isUFA ? '/universal/categories/async' : '';
    const serviceParams = this.checkParams(params);
    const url = `/${this.resourceName}${subUrl}`;
    return this._requestService.post(
      url,
      body,
      this.headers,
      false,
      serviceParams
    );
  }

  protected checkParams(params: any = {}) {
    if (!params.force) {
      delete params.force;
    }
    return params;
  }

  getTags(categories: Array<FacilityCategoryModel>) {
    const tags = [];
    if (categories) {
      categories.forEach((category) => {
        category.tags.forEach((tag) => {
          if (category.name == 'Occupancy') {
            tags.push({
              name: tag.name,
              amount: tag.amount,
              colorScheme: category.color_scheme,
            });
            return;
          }
          tags.push({
            name: tag.name,
            colorScheme: category.color_scheme,
          });
        });
      });
    }
    return tags;
  }
}
