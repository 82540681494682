import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(
    value: DateTime | string | undefined,
    format: string = 'MMM d, yyyy',
    inputFormat?: string
  ): string {
    if (value === 'now' || typeof value === undefined) {
      if (format === 'ISO') {
        return DateTime.now().toISO();
      }
      return DateTime.now().toFormat(format);
    }

    if (typeof value === 'string') {
      if (inputFormat) {
        return DateTime.fromFormat(value, inputFormat).toFormat(format);
      }

      return DateTime.fromISO(value).toFormat(format);
    }

    if (value instanceof DateTime) {
      return value.toFormat(format);
    }

    return '';
  }
}
