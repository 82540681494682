import { Directive, ElementRef, OnInit, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';

@Directive({
  selector: '[appTrimOnBlur]',
})
export class TrimOnBlurDirective implements OnInit {
  constructor(
    private elementRef: ElementRef,
    @Self() private ngControl: NgControl
  ) {}

  ngOnInit(): void {
    fromEvent(this.elementRef.nativeElement, 'blur')
      .pipe()
      .subscribe(() => {
        const currentValue: string = (this.ngControl.value || '').toString();
        const whitespace: string = ' ';
        if (
          currentValue.startsWith(whitespace) ||
          currentValue.endsWith(whitespace)
        ) {
          this.ngControl.control.patchValue(currentValue.trim());
        }
      });
  }
}
