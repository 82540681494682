<div class="details-errors__block flex_nowrap" *ngIf="anyErrors">
  <span class="details-errors__icon">
    <app-svg [href]="'exclamation-triangle'" iconClass="icon icon_s"></app-svg>
  </span>
  <div *ngIf="keys.length; else elseBlock">
    <div class="details-errors__description" *ngFor="let key of keys">
      <p *ngIf="parsedErrors[key]">
        {{ parsedErrors[key].name }} cannot be calculated –
        <span *ngFor="let issue of parsedErrors[key].issues; let index = index">
          {{ issue
          }}<span *ngIf="index < parsedErrors[key].issues.length - 1">;</span>
        </span>
      </p>
    </div>
  </div>
  <ng-template #elseBlock>
    <div class="details-description__wrapper">
      <p *ngFor="let error of parsedErrors | keyvalue">
        {{ error.value.name }} cannot be calculated –
        <span *ngFor="let issue of error.value.issues; let index = index">
          {{ issue
          }}<span *ngIf="index < error.value.issues.length - 1">;</span>
        </span>
      </p>
    </div>
  </ng-template>
</div>
