export const icons = {
  groupExpanded: `
      <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2" viewBox="0 0 32 32">
        <path d="M7.334 10.667 16 21.334l8.667-10.667H7.334Z" style="fill-rule:nonzero"/>
      </svg>`,
  groupContracted: `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="_small-right-theme-alpine">
          <path id="small-right" d="M5.33398 12.333L10.6675 8L5.33398 3.6665V12.333Z" fill="#181D1F"/>
        </g>
      </svg>`,
  rowDrag: `<svg width="23" height="41" style="width: 20px; height: 40px;" viewBox="0 0 23 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12C0 5.37258 5.37258 0 12 0H23V41H12C5.37258 41 0 35.6274 0 29V12Z" fill="#002743"/>
<path d="M11.1278 14.4542C11.1278 14.8534 11.1278 15.2527 11.1278 15.652C11.1278 16.6048 11.1278 17.5576 11.1278 18.5104C11.1278 19.6689 11.1278 20.8274 11.1278 21.9859C11.1278 22.984 11.1278 23.9822 11.1278 24.9774C11.1278 25.4644 11.1127 25.9544 11.1278 26.4414C11.1278 26.4474 11.1278 26.4565 11.1278 26.4625C11.1278 26.6985 11.337 26.9283 11.5825 26.9163C11.828 26.9042 12.0372 26.7166 12.0372 26.4625C12.0372 26.0633 12.0372 25.664 12.0372 25.2647C12.0372 24.3119 12.0372 23.3591 12.0372 22.4063C12.0372 21.2478 12.0372 20.0893 12.0372 18.9308C12.0372 17.9327 12.0372 16.9345 12.0372 15.9393C12.0372 15.4523 12.0523 14.9623 12.0372 14.4753C12.0372 14.4693 12.0372 14.4602 12.0372 14.4542C12.0372 14.2182 11.828 13.9884 11.5825 14.0005C11.337 14.0126 11.1278 14.2001 11.1278 14.4542Z" fill="#FFFCFC"/>
<path d="M9.97041 16.9804C10.5342 16.3392 11.098 15.6979 11.6618 15.0597C11.7406 14.969 11.8225 14.8782 11.9013 14.7875C11.6861 14.7875 11.4739 14.7875 11.2587 14.7875C11.8225 15.4287 12.3863 16.07 12.9501 16.7082C13.0289 16.799 13.1107 16.8897 13.1896 16.9804C13.3502 17.165 13.6655 17.1468 13.8322 16.9804C14.014 16.799 13.9959 16.5237 13.8322 16.3392C13.2684 15.6979 12.7046 15.0567 12.1408 14.4185C12.0619 14.3277 11.9801 14.237 11.9013 14.1462C11.7467 13.9708 11.4133 13.9708 11.2587 14.1462C10.6949 14.7845 10.1311 15.4257 9.56725 16.067C9.48844 16.1577 9.4066 16.2484 9.32779 16.3392C9.16714 16.5237 9.14592 16.799 9.32779 16.9804C9.49147 17.1468 9.80672 17.168 9.97041 16.9804Z" fill="#FFFCFC"/>
<path d="M9.32388 24.6753C9.88769 25.3165 10.4515 25.9578 11.0153 26.596C11.0941 26.6867 11.176 26.7775 11.2548 26.8682C11.4094 27.0437 11.7428 27.0437 11.8974 26.8682C12.4612 26.227 13.025 25.5857 13.5888 24.9475C13.6676 24.8567 13.7495 24.766 13.8283 24.6753C13.9889 24.4908 14.0101 24.2155 13.8283 24.034C13.6646 23.8707 13.3493 23.8495 13.1856 24.034C12.6218 24.6753 12.058 25.3165 11.4942 25.9547C11.4154 26.0455 11.3336 26.1362 11.2548 26.227C11.47 26.227 11.6822 26.227 11.8974 26.227C11.3336 25.5857 10.7698 24.9445 10.206 24.3062C10.1272 24.2155 10.0453 24.1248 9.9665 24.034C9.80585 23.8495 9.4906 23.8676 9.32388 24.034C9.14201 24.2155 9.16323 24.4908 9.32388 24.6753Z" fill="#FFFCFC"/>
<path d="M5.45514 21.0062C5.85526 21.0062 6.25538 21.0062 6.6555 21.0062C7.61033 21.0062 8.56516 21.0062 9.51999 21.0062C10.6809 21.0062 11.8419 21.0062 13.0029 21.0062C14.0032 21.0062 15.0035 21.0062 16.0007 21.0062C16.4887 21.0062 16.9798 21.0213 17.4678 21.0062C17.4739 21.0062 17.483 21.0062 17.489 21.0062C17.7255 21.0062 17.9559 20.7975 17.9437 20.5525C17.9316 20.3075 17.7437 20.0988 17.489 20.0988C17.0889 20.0988 16.6888 20.0988 16.2887 20.0988C15.3339 20.0988 14.379 20.0988 13.4242 20.0988C12.2632 20.0988 11.1023 20.0988 9.94133 20.0988C8.94103 20.0988 7.94073 20.0988 6.94346 20.0988C6.45544 20.0988 5.96438 20.0836 5.47636 20.0988C5.4703 20.0988 5.4612 20.0988 5.45514 20.0988C5.21871 20.0988 4.98833 20.3075 5.00046 20.5525C5.01258 20.8005 5.20052 21.0062 5.45514 21.0062Z" fill="#FFFCFC"/>
<path d="M7.98773 22.167C7.34511 21.6044 6.70249 21.0418 6.06291 20.4792C5.97197 20.4006 5.88104 20.3189 5.7901 20.2403C5.7901 20.455 5.7901 20.6668 5.7901 20.8815C6.43272 20.3189 7.07533 19.7563 7.71492 19.1937C7.80586 19.115 7.89679 19.0334 7.98773 18.9547C8.17263 18.7944 8.15444 18.4798 7.98773 18.3135C7.80586 18.132 7.53001 18.1501 7.34511 18.3135C6.70249 18.8761 6.05988 19.4387 5.42029 20.0013C5.32936 20.08 5.23842 20.1616 5.14748 20.2403C4.97167 20.3945 4.97167 20.7273 5.14748 20.8815C5.7901 21.4441 6.43272 22.0067 7.0723 22.5693C7.16324 22.648 7.25417 22.7297 7.34511 22.8083C7.53001 22.9686 7.80586 22.9898 7.98773 22.8083C8.15444 22.645 8.17566 22.3304 7.98773 22.167Z" fill="#FFFCFC"/>
<path d="M15.6691 22.8089C16.3117 22.2463 16.9543 21.6837 17.5939 21.1211C17.6848 21.0424 17.7758 20.9608 17.8667 20.8821C18.0425 20.7279 18.0425 20.3951 17.8667 20.2409C17.2241 19.6783 16.5815 19.1157 15.9419 18.553C15.851 18.4744 15.76 18.3927 15.6691 18.3141C15.4842 18.1538 15.2083 18.1326 15.0265 18.3141C14.8628 18.4774 14.8416 18.792 15.0265 18.9553C15.6691 19.5179 16.3117 20.0806 16.9513 20.6432C17.0422 20.7218 17.1332 20.8035 17.2241 20.8821C17.2241 20.6674 17.2241 20.4556 17.2241 20.2409C16.5815 20.8035 15.9389 21.3661 15.2993 21.9287C15.2083 22.0073 15.1174 22.089 15.0265 22.1677C14.8416 22.328 14.8598 22.6425 15.0265 22.8089C15.2083 22.9934 15.4842 22.9722 15.6691 22.8089Z" fill="#FFFCFC"/>
</svg>`,
};

// public icons: {
//   [key: string]: ((...args: any[]) => any) | string;
// } = {
//   // use font awesome for menu icons
//   // menu: '<i class="fa fa-bath" style="width: 10px"/>',
//   // menuAlt: '<i class="fa fa-bath" style="width: 10px"/>',
//   // filter: '<i class="fa fa-long-arrow-alt-down"/>',
//   // columns: '<i class="fa fa-handshake"/>',
//   // sortAscending: '<i class="fa fa-long-arrow-alt-down"/>',
//   // sortDescending: '<i class="fa fa-long-arrow-alt-up"/>',
//   // use some strings from group
//   groupExpanded:
//     '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2" viewBox="0 0 32 32"><path d="M7.334 10.667 16 21.334l8.667-10.667H7.334Z" style="fill-rule:nonzero"/></svg>',
//   groupContracted: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <g id="_small-right-theme-alpine">
//       <path id="small-right" d="M5.33398 12.333L10.6675 8L5.33398 3.6665V12.333Z" fill="#181D1F"/>
//       </g>
//       </svg>`,
//   // columnMovePin: '<i class="far fa-hand-rock"/>',
//   // columnMoveAdd: '<i class="fa fa-plus-square"/>',
//   // columnMoveHide: '<i class="fa fa-times"/>',
//   // columnMoveMove: '<i class="fa fa-link"/>',
//   // columnMoveLeft: '<i class="fa fa-arrow-left"/>',
//   // columnMoveRight: '<i class="fa fa-arrow-right"/>',
//   // columnMoveGroup: '<i class="fa fa-users"/>',
//   // rowGroupPanel: '<i class="fa fa-university"/>',
//   // pivotPanel: '<i class="fa fa-magic"/>',
//   // valuePanel: '<i class="fa fa-magnet"/>',
//   // menuPin: "P", // just showing letters, no graphic
//   // menuValue: "V",
//   // menuAddRowGroup: "A",
//   // menuRemoveRowGroup: "R",
//   // clipboardCopy: ">>",
//   // clipboardCut: "<<",
//   // clipboardPaste: ">>",
//   rowDrag: `<svg width="23" height="41" viewBox="0 0 23 41" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M0 12C0 5.37258 5.37258 0 12 0H23V41H12C5.37258 41 0 35.6274 0 29V12Z" fill="#002743"/>
// <path d="M11.1278 14.4542C11.1278 14.8534 11.1278 15.2527 11.1278 15.652C11.1278 16.6048 11.1278 17.5576 11.1278 18.5104C11.1278 19.6689 11.1278 20.8274 11.1278 21.9859C11.1278 22.984 11.1278 23.9822 11.1278 24.9774C11.1278 25.4644 11.1127 25.9544 11.1278 26.4414C11.1278 26.4474 11.1278 26.4565 11.1278 26.4625C11.1278 26.6985 11.337 26.9283 11.5825 26.9163C11.828 26.9042 12.0372 26.7166 12.0372 26.4625C12.0372 26.0633 12.0372 25.664 12.0372 25.2647C12.0372 24.3119 12.0372 23.3591 12.0372 22.4063C12.0372 21.2478 12.0372 20.0893 12.0372 18.9308C12.0372 17.9327 12.0372 16.9345 12.0372 15.9393C12.0372 15.4523 12.0523 14.9623 12.0372 14.4753C12.0372 14.4693 12.0372 14.4602 12.0372 14.4542C12.0372 14.2182 11.828 13.9884 11.5825 14.0005C11.337 14.0126 11.1278 14.2001 11.1278 14.4542Z" fill="#FFFCFC"/>
// <path d="M9.97041 16.9804C10.5342 16.3392 11.098 15.6979 11.6618 15.0597C11.7406 14.969 11.8225 14.8782 11.9013 14.7875C11.6861 14.7875 11.4739 14.7875 11.2587 14.7875C11.8225 15.4287 12.3863 16.07 12.9501 16.7082C13.0289 16.799 13.1107 16.8897 13.1896 16.9804C13.3502 17.165 13.6655 17.1468 13.8322 16.9804C14.014 16.799 13.9959 16.5237 13.8322 16.3392C13.2684 15.6979 12.7046 15.0567 12.1408 14.4185C12.0619 14.3277 11.9801 14.237 11.9013 14.1462C11.7467 13.9708 11.4133 13.9708 11.2587 14.1462C10.6949 14.7845 10.1311 15.4257 9.56725 16.067C9.48844 16.1577 9.4066 16.2484 9.32779 16.3392C9.16714 16.5237 9.14592 16.799 9.32779 16.9804C9.49147 17.1468 9.80672 17.168 9.97041 16.9804Z" fill="#FFFCFC"/>
// <path d="M9.32388 24.6753C9.88769 25.3165 10.4515 25.9578 11.0153 26.596C11.0941 26.6867 11.176 26.7775 11.2548 26.8682C11.4094 27.0437 11.7428 27.0437 11.8974 26.8682C12.4612 26.227 13.025 25.5857 13.5888 24.9475C13.6676 24.8567 13.7495 24.766 13.8283 24.6753C13.9889 24.4908 14.0101 24.2155 13.8283 24.034C13.6646 23.8707 13.3493 23.8495 13.1856 24.034C12.6218 24.6753 12.058 25.3165 11.4942 25.9547C11.4154 26.0455 11.3336 26.1362 11.2548 26.227C11.47 26.227 11.6822 26.227 11.8974 26.227C11.3336 25.5857 10.7698 24.9445 10.206 24.3062C10.1272 24.2155 10.0453 24.1248 9.9665 24.034C9.80585 23.8495 9.4906 23.8676 9.32388 24.034C9.14201 24.2155 9.16323 24.4908 9.32388 24.6753Z" fill="#FFFCFC"/>
// <path d="M5.45514 21.0062C5.85526 21.0062 6.25538 21.0062 6.6555 21.0062C7.61033 21.0062 8.56516 21.0062 9.51999 21.0062C10.6809 21.0062 11.8419 21.0062 13.0029 21.0062C14.0032 21.0062 15.0035 21.0062 16.0007 21.0062C16.4887 21.0062 16.9798 21.0213 17.4678 21.0062C17.4739 21.0062 17.483 21.0062 17.489 21.0062C17.7255 21.0062 17.9559 20.7975 17.9437 20.5525C17.9316 20.3075 17.7437 20.0988 17.489 20.0988C17.0889 20.0988 16.6888 20.0988 16.2887 20.0988C15.3339 20.0988 14.379 20.0988 13.4242 20.0988C12.2632 20.0988 11.1023 20.0988 9.94133 20.0988C8.94103 20.0988 7.94073 20.0988 6.94346 20.0988C6.45544 20.0988 5.96438 20.0836 5.47636 20.0988C5.4703 20.0988 5.4612 20.0988 5.45514 20.0988C5.21871 20.0988 4.98833 20.3075 5.00046 20.5525C5.01258 20.8005 5.20052 21.0062 5.45514 21.0062Z" fill="#FFFCFC"/>
// <path d="M7.98773 22.167C7.34511 21.6044 6.70249 21.0418 6.06291 20.4792C5.97197 20.4006 5.88104 20.3189 5.7901 20.2403C5.7901 20.455 5.7901 20.6668 5.7901 20.8815C6.43272 20.3189 7.07533 19.7563 7.71492 19.1937C7.80586 19.115 7.89679 19.0334 7.98773 18.9547C8.17263 18.7944 8.15444 18.4798 7.98773 18.3135C7.80586 18.132 7.53001 18.1501 7.34511 18.3135C6.70249 18.8761 6.05988 19.4387 5.42029 20.0013C5.32936 20.08 5.23842 20.1616 5.14748 20.2403C4.97167 20.3945 4.97167 20.7273 5.14748 20.8815C5.7901 21.4441 6.43272 22.0067 7.0723 22.5693C7.16324 22.648 7.25417 22.7297 7.34511 22.8083C7.53001 22.9686 7.80586 22.9898 7.98773 22.8083C8.15444 22.645 8.17566 22.3304 7.98773 22.167Z" fill="#FFFCFC"/>
// <path d="M15.6691 22.8089C16.3117 22.2463 16.9543 21.6837 17.5939 21.1211C17.6848 21.0424 17.7758 20.9608 17.8667 20.8821C18.0425 20.7279 18.0425 20.3951 17.8667 20.2409C17.2241 19.6783 16.5815 19.1157 15.9419 18.553C15.851 18.4744 15.76 18.3927 15.6691 18.3141C15.4842 18.1538 15.2083 18.1326 15.0265 18.3141C14.8628 18.4774 14.8416 18.792 15.0265 18.9553C15.6691 19.5179 16.3117 20.0806 16.9513 20.6432C17.0422 20.7218 17.1332 20.8035 17.2241 20.8821C17.2241 20.6674 17.2241 20.4556 17.2241 20.2409C16.5815 20.8035 15.9389 21.3661 15.2993 21.9287C15.2083 22.0073 15.1174 22.089 15.0265 22.1677C14.8416 22.328 14.8598 22.6425 15.0265 22.8089C15.2083 22.9934 15.4842 22.9722 15.6691 22.8089Z" fill="#FFFCFC"/>
// </svg>`,
//   // columnDrag: '<i class="fa fa-square"/>',
// };

