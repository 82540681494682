import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, ViewChild } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';

import { AssetModel } from '../../../core/models/asset.model';
import { AssetsService } from '../../../core/services/assets.service';
import { BaseEditComponent } from '../../components/base-edit.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { DateTime } from 'luxon';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';
import { IServiceLog } from '../../../core/models/service-log.model';
import { ImageResizeService } from '../../../core/services/image-resize.service';
import { NgForm } from '@angular/forms';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { OrganizationModel } from '../../../core/models/organization.model';
import { ServiceLogsService } from '../../../core/services/service-log.service';

@Component({
  templateUrl: './service-event-modal.component.html',
})
export class ServiceEventComponent<
  M extends IServiceLog,
> extends BaseEditComponent<M> {
  @ViewChild('eventForm', { static: true }) declare tdForm: NgForm;
  @Input() public asset: AssetModel;
  @Input() public organization: OrganizationModel;
  @Input() declare entityId: number;
  @Input() public onResult: Subject<any> = new Subject<any>();
  @Input() public onClose: Subject<any> = new Subject<any>();

  public title: string;
  public minDate;
  public maxDate;
  public assetImgSrc: string;

  constructor(
    protected activeModal: BsModalRef,
    protected store: ServiceLogsService<M>,
    protected _imageResizingService: ImageResizeService,
    protected _organizationCurrent: OrganizationCurrentService,
    protected _notificationService: CustomNotificationService,
    protected _assetsService: AssetsService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected _eventService: EventService,
    protected _errorService: ErrorService
  ) {
    super(
      _notificationService,
      store,
      router,
      activeRoute,
      '',
      _eventService,
      _errorService
    );
  }

  ngOnInit() {
    this.isTemplateDriven = true;
    this.method = this.entityId ? 'update' : 'create';
  }

  public setDatepickerMinimalDate(): void {
    this.minDate = DateTime.fromISO(this.asset.installed_on)
      .set({ hour: 0 })
      .toISO();
    this.maxDate = DateTime.now().toISO();
  }

  /**
   * Calculate labour cost
   * @returns {Number}
   */
  public calculateLabourCost() {
    if (!this.model) {
      return;
    }
    this.model.labor_cost = (
      +this.organization.labour_hour_rate * +this.model.time_charge
    )
      .toFixed(2)
      .toString();
  }

  /**
   * Set asset resized image
   */
  public setAssetImgSrc(): void {
    if (this.asset.pictures?.length) {
      this.assetImgSrc =
        this.asset.pictures[0].dimensions['200'] ||
        this.asset.pictures[0].dimensions['original'];
    }
  }

  // region BaseEditController implementation

  public loadModel(): Observable<M> {
    return new Observable<M>((observer) => {
      if (this.isCreate) {
        this.model = this.createModel();
        this.setAssetImgSrc();
        this.setDatepickerMinimalDate();
        observer.next(this.model);
        observer.complete();
      } else {
        forkJoin([
          this.store.getById(this.asset.id, this.entityId),
          this._assetsService.getById(this.asset.id),
        ]).subscribe({
          next: (data) => {
            this.model = this.createModel(data[0]);
            this.asset = data[1];
            this.setAssetImgSrc();
            this.setDatepickerMinimalDate();
            observer.next(this.model);
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
            observer.complete();
          },
        });
      }
    });
  }

  public cancel() {
    this.onClose.next('cancel');
    this.activeModal.hide();
  }

  public afterSaveAction(res) {
    this.onResult.next(res);
    this.activeModal.hide();
  }

  protected createModel(_data?): M {
    return null;
  }
}
