import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RoutesConstants } from '../../core/constants/routes.constants';
import { UserCurrentService } from '../../core/services/user-current.service';
import { Observable } from 'rxjs';
import { OrganizationCurrentService } from '../../core/services/organization-current.service';
import { RoutingService } from '../../core/services/routing.service';
import { RoleType } from '../../core/constants/types.constants';
import { TableauSettingsService } from '../../core/services/tableau-settings.service';
import { Settings } from 'luxon';

@Injectable()
export class AnalyticsGuard {
  constructor(
    private router: Router,
    private _userCurrent: UserCurrentService,
    private _organizationCurrent: OrganizationCurrentService,
    private _routingService: RoutingService,
    private _tableauSettingsService: TableauSettingsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this._tableauSettingsService
        .getUserDashboards()
        .subscribe((dashboards) => {
          const dashboard = dashboards.find(
            (el) => el.id === Number(route.params.analytics_id)
          );
          this._userCurrent.get().subscribe(
            (user) => {
              Settings.defaultZone =
                user.organization?.time_zone || user.time_zone;
              if (!dashboard) {
                try {
                  this.router.navigate([
                    RoutesConstants.ORGANIZATION.HOME(
                      this._organizationCurrent.clientKey
                    ),
                  ]);
                } catch (e) {
                  this.router.navigate([RoutesConstants.HOME]);
                }
              }
              this._routingService.routeType = (
                user.is_tenant ? 'tenant' : 'client'
              ) as RoleType;
              observer.next(true);
              observer.complete();
            },
            () => {
              observer.next(false);
              observer.complete();
            }
          );
        });
    });
  }
}
