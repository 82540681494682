import { Component, Input } from '@angular/core';
import { BaseListComponent } from '../../../components/base-list.component';
import { RequestService } from '../../../../core/services/request.service';
import { FilterService } from '../../../../core/services/filter.service';
import { CustomNotificationService } from '../../../../core/services/custom-notification.service';
import { SearchService } from '../../../../core/services/search.service';
import { OrganizationCurrentService } from '../../../../core/services/organization-current.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EventService } from '../../../../core/services/event.service';
import { NavigationService } from '../../../../core/services/navigation.service';
import { Store } from '@ngxs/store';
import { BgJobService } from '../../../../core/services/bg-worker.service';
import { Observable } from 'rxjs/internal/Observable';
import { DefaultListModel } from '../../../../core/models/default-list.model';
import { ErrorModel } from '../../../../core/models/error.model';
import { IBgJobIssuesListModel } from '../../../../core/models/bg-worker.model';
import '../../../../shared/prototypes/string.prototypes';

@Component({
  selector: 'app-issue-table',
  templateUrl: './issue-table.component.html',
})
export class IssueTableComponent extends BaseListComponent<ErrorModel> {
  @Input() public entity: 'warnings' | 'errors' = 'errors';
  @Input() public entityColumnName: string = 'Row';
  @Input() public issues: IBgJobIssuesListModel;
  @Input() private uuid: string;
  @Input() private orgId: string;

  public itemsArrPlug: any[] = Array(this.perPage);

  public get callIssueListApi() {
    return this.issues[`${this.entity}_count`] > 10;
  }

  constructor(
    protected _requestService: RequestService,
    protected _filterService: FilterService,
    protected _notificationService: CustomNotificationService,
    protected _searchService: SearchService,
    protected store: BgJobService<ErrorModel>,
    protected _organizationCurrent: OrganizationCurrentService,
    protected modalService: BsModalService,
    protected _eventService: EventService,
    protected _navigationService: NavigationService,
    protected appStore: Store
  ) {
    super(
      _requestService,
      _filterService,
      _notificationService,
      _searchService,
      store,
      _navigationService,
      appStore
    );
  }

  ngOnInit(): void {
    if (this.callIssueListApi) {
      this.loadList();
    } else {
      this.setItems(
        this.issues[this.entity].map((issue) => ({
          ...issue.details,
          column_name: issue.entity_number,
        }))
      );
    }
  }

  public getErrorMessage(item: ErrorModel): string {
    const firstLetter: string = String(item.message).charAt(0);
    return firstLetter.toLowerCase() === firstLetter
      ? `${item.key.capitalize()} ${item.message}`
      : item.message;
  }

  public getItems(): Observable<DefaultListModel<ErrorModel>> {
    return this.store.getBgJobIssues(
      this.uuid,
      this.entity,
      this.requestParams(),
      this.orgId
    );
  }

  public setItems(items: Array<any>) {
    super.setItems(items);
    this.itemsArrPlug = Array(this.items.length || this.perPage);
  }
}
