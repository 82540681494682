import { DefaultDTO } from './default.dto';
import { TemplateTestModel } from '../models/template.model';
import { HelperService } from '../services/helper.service';

export class TemplateDTO extends DefaultDTO {
  title: string;
  description: string;
  assets_repositories_report_templates_repositories_attributes?: Array<any>;
  assets_repositories_report_templates_attributes?: Array<any>;
  template: Array<TemplateTestModel>;

  constructor(template) {
    super();
    const assetsAttributesKey = `assets_repositories_${template.isDefault ? 'report_templates_repositories' : 'report_templates'}_attributes`;
    this.title = template.title;
    this.description = template.description;
    this[assetsAttributesKey] = [];
    template.types.forEach((type) => {
      const dtoType: any = {};
      dtoType.assets_repository_id = type.type;
      dtoType.is_default = type.default;
      this[assetsAttributesKey].push(dtoType);
    });

    this.template = [];
    template.tests.forEach((test) => {
      const parsedTest: TemplateTestModel = {};
      parsedTest.title = test.title;
      parsedTest.description = test.description;
      parsedTest.type = test.type.code;
      parsedTest.labels = [];
      parsedTest.validations = [];
      parsedTest.hints = [];
      parsedTest.format = test.format;
      test.options.forEach((option) => {
        let validation: any = {},
          hint = option.hint,
          label = option.label;
        if (option.min !== undefined && option.max !== undefined) {
          validation.min = option.min;
          validation.max = option.max;
        } else {
          validation = { required: option.validation };
        }
        if (test.type.code === 7) {
          validation = null;
          hint = undefined;
          if (HelperService.isExist(option.test)) {
            const testOption = option.test_option || 0;
            label = `{ "test": ${option.test}, "option" : ${testOption}}`;
          } else if (option.const) {
            label = parseFloat(option.const);
          } else {
            label = option.label.name;
          }
        }
        parsedTest.labels.push(label);
        parsedTest.hints.push(hint);
        parsedTest.validations.push(validation);
      });
      this.template.push(parsedTest);
    });
  }
}

export default TemplateDTO;
