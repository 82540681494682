<button
  type="button"
  class="link"
  style="text-align: inherit"
  *ngIf="userId && isActive && canViewLink()"
  [ngClass]="{ 'text-semibold': isBold }"
  (click)="userDetailsMode(userId)"
>
  @if (isHighlightText) {
    <span [innerHTML]="userName | highlighttext: (searchText$ | async)"> </span>
  } @else {
    {{ userName !== "" ? userName : "(" + userId + ")" }}
  }
</button>

<span *ngIf="userId && !canViewLink()">{{
  userName !== "" ? userName : "(" + userId + ")"
}}</span>
<span *ngIf="!userId">{{ userName || "System" }}</span>
