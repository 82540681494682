import { AbstractControl, NgForm } from '@angular/forms';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { AssetsService } from '../../../core/services/assets.service';
import { BaseFiltersComponent } from '../base-filters.component';
import { BuildingsService } from '../../../core/services/buildings.service';
import { ClientAssetTypesService } from '../../../core/services/client-asset-types.service';
import { Configs } from '../../../core/constants/configs.constants';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { DateTime } from 'luxon';
import { DefaultFilterModel } from '../../../core/models/default-filter.model';
import { EventService } from '../../../core/services/event.service';
import { FacilityAttributeModel } from '../../../core/models/facility-attribute.model';
import { FacilityAttributesService } from '../../../core/services/facility-attributes.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { FilterService } from '../../../core/services/filter.service';
import { ForecastModel } from 'src/app/core/models/forecast.model';
import { ForecastService } from 'src/app/core/services/forecast.service';
import { HelperService } from '../../../core/services/helper.service';
import { LocationService } from '../../../core/services/location.service';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { PresetModalComponent } from '../../modals/preset-modal/preset-modal.component';
import { PresetModel } from '../../../core/models/preset.model';
import { PresetService } from '../../../core/services/preset.service';
import { RequestService } from '../../../core/services/request.service';
import { RisksService } from '../../../core/services/risks.service';
import { UniversalAssetTypesService } from '../../../core/services/universal-asset-types.service';
import { UserCurrentService } from '../../../core/services/user-current.service';
import { UserModel } from '../../../core/models/user.model';
import { UserService } from '../../../core/services/user.service';
import { UserStoreService } from '../../../core/services/user.store';

const serviceFactory = (
  org: OrganizationCurrentService,
  requestService: RequestService
) =>
  org.organization
    ? new ClientAssetTypesService(requestService)
    : new UniversalAssetTypesService(requestService);

@Component({
  selector: 'app-filters-component',
  templateUrl: './filters.component.html',
  inputs: BaseFiltersComponent.metaData.inputs,
  outputs: BaseFiltersComponent.metaData.outputs,
  providers: [
    {
      provide: ClientAssetTypesService,
      useFactory: serviceFactory,
      deps: [OrganizationCurrentService, RequestService],
    },
  ],
})
export class FiltersComponent
  extends BaseFiltersComponent
  implements AfterContentChecked, OnInit
{
  @ViewChild('filtersForm') declare tdForm: NgForm;
  @Input() public allowedPresets: boolean = true;
  @Input() public allowedDefaultPresets: boolean = true;
  @Input() public onlyDefaultFilters: boolean;
  @Input() public dataFromComponents: any;
  @Input() public set entityId(value: number) {
    if (value) {
      this._entityId = value;
    }
  }
  @Output() onPresetUpdating = new EventEmitter<DefaultFilterModel>();

  public presetsList: Array<PresetModel>;
  public preset: PresetModel;
  public presetId: Array<number>;
  public userId: number;
  public helper = HelperService;
  protected modalRef: BsModalRef;
  protected filtersToUpdateLimit = ['totalRiskFrom', 'fhieFrom'];
  protected _entityId: number = null;
  protected presetIsLoading: boolean = true;
  public todayDate = new Date();

  private allowedFiltersDefaultList = {
    classification_ids: true,
    location: true,
    building: true,
    floor: true,
    room: true,
    zone: true,
    types: true,
    trades: true,
    lifetimeFrom: true,
    lifetimeTo: true,
    fhieFrom: true,
    fhieTo: true,
    totalRiskFrom: true,
    totalRiskTo: true,
    fhiFrom: true,
    fhiTo: true,
    installedAtFrom: true,
    installedAtTo: true,
    engineer: true,
    areaServed: true,
    groups: true,
    subgroups: true,
    funding_source_id: true,
  };
  isTenant: boolean;
  facilityAttributes: any;
  showRisksFhiFiltersBlock: boolean = false;
  showLocationFiltersBlock: boolean = false;
  showBuildingFiltersBlock: boolean = false;

  public get allowedFilters() {
    if (!this.isTenant) {
      this.allowedFiltersList['engineer'] = false;
    }
    return this.allowedFiltersList;
  }

  public get entityId(): number {
    return this._entityId;
  }

  private get isDefaultPresetAllowed(): boolean {
    return (
      this.allowedDefaultPresets &&
      !!this._userCurrentService.user.default_facilities?.length
    );
  }

  public get isOnlyDefaultPreset(): boolean {
    return this.onlyDefaultFilters && this.isDefaultPresetAllowed;
  }

  constructor(
    protected cdref: ChangeDetectorRef,
    protected _locationService: LocationService,
    protected _filterService: FilterService,
    protected activeRoute: ActivatedRoute,
    protected _organizationCurrent: OrganizationCurrentService,
    protected _assetService: AssetsService,
    protected _risksService: RisksService,
    protected _notificationService: CustomNotificationService,
    protected _presetService: PresetService<PresetModel>,
    protected modalService: BsModalService,
    protected _userCurrentService: UserCurrentService,
    protected _userService: UserService,
    protected _eventService: EventService,
    protected _assetTypesService: ClientAssetTypesService,
    protected _facilityAttributeService: FacilityAttributesService<FacilityAttributeModel>,
    protected _buildingService: BuildingsService<UserModel>,
    protected _userStoreService: UserStoreService,
    protected _forecastService: ForecastService<ForecastModel>,
    protected _featureFlagsService: FeatureFlagsService
  ) {
    super(
      _locationService,
      _filterService,
      activeRoute,
      _organizationCurrent,
      _notificationService,
      _eventService
    );
    this.isTenant = this._userCurrentService.user.is_tenant;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
    this.presetIsLoading = false;
  }

  ngOnInit() {
    this.allowedFiltersList =
      this.allowedFiltersList || this.allowedFiltersDefaultList;
    this.userId = this._userCurrentService.user.id;
    this.organization =
      this.activeRoute.snapshot.params['org_id'] ||
      this._organizationCurrent?.organization?.id_hash ||
      null;
    this.filterDropdowns = {
      disabled: {},
    };
    const fhieRange = [0, 1, 2, 3, 4, 5];
    const tiers = [
      { id: 1, name: '1' },
      { id: 2, name: '2' },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
      { id: 5, name: 'Custom' },
      { id: 6, name: 'Life Safety' },
      { id: 7, name: 'Architectural' },
    ];

    this.filterDropdowns.fhieFrom = fhieRange;
    this.filterDropdowns.fhieTo = fhieRange;
    this.filterDropdowns.scoreFrom = fhieRange;
    this.filterDropdowns.scoreTo = fhieRange;
    this.filterDropdowns.fs = Configs.FUNDING_SOURCES_LIST;
    if (this.allowedFilters.tiers) {
      this.filterDropdowns.tiers = tiers;
    } else {
      delete this.filterDropdowns.subgroups;
    }
    const servicesIndex = {};
    this.requestData(servicesIndex).subscribe(
      (data) => {
        if (this.allowedFilters.location) {
          this.filterDropdowns.location =
            data[servicesIndex['location']]['list'];
        } else {
          delete this.filterDropdowns.location;
        }
        if (this.allowedFilters.zone) {
          this.filterDropdowns.zone = data[servicesIndex['zone']]['list'];
        } else {
          delete this.filterDropdowns.zone;
        }

        // types list - for assets filters
        // this.allowedFilters.types ? this.filterDropdowns.types =
        //   data[servicesIndex['types']] : delete this.filterDropdowns.types;
        // types_all list - for asset types filters
        if (this.allowedFilters.types) {
          this.filterDropdowns.types = data[servicesIndex['types']];
        } else {
          !this.allowedFilters.types && delete this.filterDropdowns.types;
        }
        // trades list - for assets filter
        // this.allowedFilters.trades ? this.filterDropdowns.trades =
        //   data[servicesIndex['trades']] : delete this.filterDropdowns.trades;
        // trades all list - for asset types filter
        if (this.allowedFilters.trades) {
          this.filterDropdowns.trades = data[servicesIndex['trades']];
        } else {
          delete this.filterDropdowns.trades;
        }
        // classification_ids - for assets filters, classification_ids_all - for asset types filters
        if (this.allowedFilters.classification_ids) {
          this.filterDropdowns.classifications =
            data[servicesIndex['classification_ids']];
        } else {
          delete this.filterDropdowns.classifications;
        }
        if (this.allowedFilters.tag_ids) {
          this.facilityAttributes = data[servicesIndex['tag_ids']]['list'];
        } else {
          delete this.filterDropdowns.tag_ids;
        }
        if (this.allowedFilters.user) {
          this.filterDropdowns.user = data[servicesIndex['user']];
        } else {
          delete this.filterDropdowns.user;
        }
        if (this.allowedFilters.areaServed) {
          this.filterDropdowns.areaServed = data[servicesIndex['areaServed']];
        } else {
          delete this.filterDropdowns.areaServed;
        }
        if (this.allowedFilters.engineer) {
          this.filterDropdowns.engineer = data[servicesIndex['engineer']];
        } else {
          delete this.filterDropdowns.engineer;
        }
        if (this.allowedFilters.made_incomplete_by) {
          this.filterDropdowns.made_incomplete_by =
            data[servicesIndex['made_incomplete_by']];
        } else {
          delete this.filterDropdowns.made_incomplete_by;
        }
        if (this.allowedFilters.cost_center) {
          this.filterDropdowns.cost_center = data[servicesIndex['cost_center']];
        } else {
          delete this.filterDropdowns.cost_center;
        }
        if (this.allowedFilters.totalRiskFrom) {
          this.setupRisksFilter(data[servicesIndex['totalRiskFrom']]['list']);
        }
        if (this.allowedFilters.lifetimeFrom) {
          this.setupDatepickers();
        }
        if (this.allowedFilters.assignee) {
          this.filterDropdowns.assignee = data[servicesIndex['assignee']][
            'list'
          ].filter((item) => item.active_for_authentication);
        } else {
          delete this.filterDropdowns.assignee;
        }
        if (this.allowedFilters.groups) {
          this.filterDropdowns.groups = data[servicesIndex['groups']];
        } else {
          delete this.filterDropdowns.groups;
        }
        if (this.allowedFilters.subgroups) {
          this.filterDropdowns.subgroups = data[servicesIndex['subgroups']];
        } else {
          delete this.filterDropdowns.subgroups;
        }
        if (this.allowedFilters.specialties) {
          this.filterDropdowns.specialties = data[servicesIndex['specialties']];
        } else {
          delete this.filterDropdowns.specialties;
        }
        if (this.allowedPresets && this.organization) {
          this.presetsList = data[servicesIndex['presets']]['list'];
        } else {
          this.presetsList = null;
        }
        this.filterDropdownsReady = true;
        this.restoreFilters();
      },
      () => {}
    );
  }

  protected createRequestToServices(servicesIndex) {
    this.setupListsToAppearance();
    const servicesToRequest = [];
    if (this.allowedPresets && this.organization) {
      servicesIndex['presets'] = servicesToRequest.length;
      servicesToRequest.push(
        this._presetService.getItems({ restricted: !!this.isOnlyDefaultPreset })
      );
    }
    if (this.allowedFilters.classification_ids) {
      servicesIndex['classification_ids'] = servicesToRequest.length;
      servicesToRequest.push(this._assetTypesService.getUsedClassifications());
    }
    if (this.allowedFilters.location) {
      let params = {};
      if (this.isOnlyDefaultPreset) {
        params = { user_id: this._userCurrentService.user.id };
      }
      servicesIndex['location'] = servicesToRequest.length;
      servicesToRequest.push(this.getLocationsForDropdown(params));
    }
    if (this.allowedFilters.zone) {
      servicesIndex['zone'] = servicesToRequest.length;
      servicesToRequest.push(this._locationService.loadZonesList());
    }
    if (this.allowedFilters.types) {
      servicesIndex['types'] = servicesToRequest.length;
      servicesToRequest.push(
        this._assetTypesService.getItemsWithShortDataScope()
      );
    }
    if (this.allowedFilters.trades) {
      servicesIndex['trades'] = servicesToRequest.length;
      servicesToRequest.push(this._assetTypesService.getUsedRepositoryTrades());
    }
    if (this.allowedFilters.totalRiskFrom) {
      servicesIndex['totalRiskFrom'] = servicesToRequest.length;
      servicesToRequest.push(this._risksService.getItems());
    }
    if (this.allowedFilters.engineer) {
      servicesIndex['engineer'] = servicesToRequest.length;
      servicesToRequest.push(this._userService.getUsers(this.filterType));
    }
    if (this.allowedFilters.made_incomplete_by) {
      servicesIndex['made_incomplete_by'] = servicesToRequest.length;
      servicesToRequest.push(this._userService.getUsers('made_incomplete_by'));
    }
    if (this.allowedFilters.cost_center) {
      servicesIndex['cost_center'] = servicesToRequest.length;
      servicesToRequest.push(
        this.filterType.includes('forecastAssetList') &&
          this._featureFlagsService.isFeatureEnabled(
            'OR_13108_append_cost_center_forecast'
          )
          ? this._forecastService.getCostCenterBasedOnForecastId(
              this.dataFromComponents
            )
          : this._assetService.getAllCostCenters('cost_centers')
      );
    }
    if (this.allowedFilters.tag_ids) {
      servicesIndex['tag_ids'] = servicesToRequest.length;
      servicesToRequest.push(
        this._facilityAttributeService.getFacilityCategories(false)
      );
    }
    if (this.allowedFilters.user && this.entityId) {
      servicesIndex['user'] = servicesToRequest.length;
      servicesToRequest.push(
        this._buildingService.getEntityUsers(this.entityId, this.filterType)
      );
    }
    if (this.allowedFilters.assignee) {
      servicesIndex['assignee'] = servicesToRequest.length;
      servicesToRequest.push(
        this._userStoreService.getUsersByOrganization(
          { per_page: 9999, page: 1 },
          this._organizationCurrent.organization.id_hash
        )
      );
    }
    if (this.allowedFilters.areaServed) {
      servicesIndex['areaServed'] = servicesToRequest.length;
      servicesToRequest.push(this._assetTypesService.getAreaServed());
    }
    if (this.allowedFilters.groups) {
      servicesIndex['groups'] = servicesToRequest.length;
      servicesToRequest.push(this._assetTypesService.getUsedRepositoryGroups());
    }
    if (this.allowedFilters.subgroups) {
      servicesIndex['subgroups'] = servicesToRequest.length;
      servicesToRequest.push(
        this._assetTypesService.getUsedRepositorySubgroups()
      );
    }
    if (this.allowedFilters.specialties) {
      servicesIndex['specialties'] = servicesToRequest.length;
      servicesToRequest.push(
        this._assetTypesService.getRepositorySpecialties()
      );
    }
    this.isLoading = !!servicesToRequest.length;
    return servicesToRequest;
  }

  public restoreDropdownDependencyAndApplyFilters(filter?: any): Subscription {
    let params = {};
    if (this.isOnlyDefaultPreset) {
      params = { user_id: this._userCurrentService.user.id };
    }
    if (filter) {
      this.filter = this._filterService.convertPresetToFilters(
        filter,
        this.indicesMode
      );
    }
    return this._filterService
      .restoreDropdownDependency(this.filterDropdowns, this.filter, params)
      .subscribe({
        next: (data) => {
          this.filterDropdowns = data.dropdowns;
          this.filter = data.filters;
          this.isLoading = false;
          this.checkSelectedFilters();
          this.submitFilters();
        },
        error: (err) => {
          this._notificationService.apiError(err);
          this.isLoading = false;
        },
      });
  }

  isEmptyFilter(obj): boolean {
    const values = Object.values(obj).filter(
      (val) => val !== undefined || (typeof val === 'number' && !isNaN(val))
    );
    if (values.length) {
      return (
        !obj ||
        values.every((value) => {
          if (Array.isArray(value)) {
            return !value.length;
          } else if (Number(value)) {
            return false;
          }
          return value === undefined;
        })
      );
    }

    return true;
  }

  resetFilters() {
    this.clearFilters();
    this.resetPreset();
    this._filterService.resetDependencyList(
      'location',
      {},
      this.filterDropdowns
    );
    this.checkSelectedFilters();
    this.submitFilters(true);
  }

  updateSelectLimit(prop): void {
    const isPropFirstInPair = prop.indexOf('From') > -1;
    const isPropLastInPair = prop.indexOf('To') > -1;
    if (!(isPropFirstInPair || isPropLastInPair)) {
      // no need to update limit
      return;
    }
    const toName = isPropLastInPair ? prop : prop.replace('From', 'To'),
      fromName = isPropFirstInPair ? prop : prop.replace('To', 'From'),
      toValue = this.filter[toName],
      fromValue = this.filter[fromName];
    if (typeof toName !== 'undefined') {
      if (!this.helper.isExist(fromValue)) {
        this.filterDropdowns[toName] = this.filterDropdowns[fromName];
      } else {
        if (Array.isArray(this.filterDropdowns[fromName])) {
          // for select with array of items
          this.filterDropdowns[toName] = [
            ...this.filterDropdowns[fromName],
          ].splice(this.filterDropdowns[fromName].indexOf(parseInt(fromValue)));
          if (fromValue > toValue) {
            this.filter[toName] = fromValue;
          }
        } else {
          if (!this.helper.isExist(toValue)) {
            this.filterDropdowns[fromName] = this.filterDropdowns[toName];
          }
          // reset validation for paired field

          this.revalidateFormField(
            isPropLastInPair
              ? this.tdForm.controls[fromName]
              : this.tdForm.controls[toName]
          );
        }
      }
    }
  }

  private revalidateFormField(field: AbstractControl) {
    field.setErrors(null);
    field.markAsTouched();
  }

  private setupDatepickers() {
    for (const prop in this.datepickers) {
      if (this.datepickers.hasOwnProperty(prop)) {
        this.updateFieldLimits(prop);
      }
    }
  }

  private setupRisksFilter(risks): void {
    let maxTotalRisk = 0;
    let minTotalRisk = 0;
    const totalRisk = [];

    risks.forEach((risk) => {
      if (risk.available && risk.risk_ranges.length) {
        maxTotalRisk += risk.risk_ranges.at(-1).value;
        if (minTotalRisk > risk.risk_ranges[0].value) {
          minTotalRisk = risk.risk_ranges[0].value;
        }
      }
    });
    for (let i = minTotalRisk; i < maxTotalRisk + 1; i++) {
      totalRisk.push(i);
    }
    this.filterDropdowns.totalRiskFrom = totalRisk;
    this.filterDropdowns.totalRiskTo = totalRisk;

    this.updateSelectLimit('totalRiskFrom');
  }

  public onFiltersChanges(name): void {
    let params = {};

    if (this.isOnlyDefaultPreset) {
      params = { user_id: this._userCurrentService.user.id };
    }

    if (name === 'fhiFrom') {
      this.filter.fhiFrom = +this.filter.fhiFrom;
    } else if (name === 'fhiTo') {
      this.filter.fhiTo = +this.filter.fhiTo;
    }

    super.onFiltersChanges(name, params);
    this.resetPreset();
  }

  private resetPreset() {
    if (this.allowedPresets && !this.presetIsLoading) {
      this.filter.presetId = null;
    }
  }

  private getPresets(delay?) {
    this._presetService.getItems().subscribe({
      next: (res) => {
        this.presetsList = res.list;
        if (delay) {
          delay.next();
          delay.complete();
        }
      },
      error: (err) => this._notificationService.apiError(err),
    });
  }

  deletePreset(preset) {
    this.modalRef = this.modalService.show(ConfirmationModalComponent, {
      class: 'modal-holder',
      animated: true,
      initialState: {
        header: 'Delete Preset?',
        submitIcon: 'trash',
        submitClass: 'danger',
        message: `Are you sure you want to delete preset "<b>${preset.name}</b>"?`,
        submitText: 'Delete',
      },
    });
    this.subscriptions.add(
      this.modalRef.content.onResult.subscribe(() => {
        this._presetService.delete(preset.id).subscribe({
          next: () => {
            this._eventService.broadcast(Configs.EVENTS.PRESET_DELETE);
            const delay = new Subject<any>();
            this.getPresets(delay);
            delay.subscribe(() =>
              this._notificationService.success(
                `Preset '${preset.name}' has been deleted`
              )
            );
          },
          error: (err) => {
            this._notificationService.apiError(err);
          },
        });
      })
    );
  }

  createPreset() {
    this.modalRef = this.modalService.show(PresetModalComponent, {
      class: Configs.MODAL_CLASS,
      animated: true,
    });
    this.modalRef.content.onResult.subscribe(
      (data) => {
        const preset = {
          filter_preset: {
            name: data.name,
            shared: data.isShared,
            preset: this._filterService.convertFiltersToPreset(
              this.filter,
              this.indicesMode
            ),
          },
        };

        this.savePreset(preset, data.existedPresetId);
      },
      (err) => this._notificationService.apiError(err)
    );
  }

  savePreset(preset, existedPresetId?: number) {
    let method = this._presetService.create(preset);
    if (existedPresetId) {
      method = this._presetService.update(existedPresetId, preset);
    }
    method.subscribe({
      next: (res) => {
        this.getPresets();
        this.presetIsLoading = true;
        this.filter.presetId = res.id;
        this.submitFilters();
      },
      error: (err) => this._notificationService.apiError(err),
    });
  }

  setPresetFilters(presetId: any) {
    const { preset: presetFilters } =
      this.presetsList.find((preset) => preset.id === presetId) || {};
    this.clearFilters();
    this.presetIsLoading = true;

    this.isOpened = presetFilters && !!Object.keys(presetFilters).length;
    if (!this.isOpened) {
      this.submitFilters();
      return;
    }
    this.setFilterByKeys(presetFilters, presetId);
  }

  setFilterByKeys(presetFilters, presetId) {
    const filters = this._filterService.convertPresetToFilters(
      presetFilters,
      this.indicesMode
    );

    Object.keys(filters).forEach((key) => {
      if (!HelperService.isExist(this.allowedFiltersList[key])) {
        delete filters[key];
      } else {
        if (key === 'location') {
          Configs.FACILITIES.forEach((facility) => {
            if (filters[facility] != null && facility !== 'zone') {
              this.filter[facility] = filters[facility];
            }
          });
          return;
        }
        this.filter[key] = this.allowedFilters[key] ? filters[key] : null;
        const shouldUpdateSelectedLimit = this.filtersToUpdateLimit.filter(
          (item) => key === item
        );
        if (shouldUpdateSelectedLimit.length) {
          this.updateSelectLimit(key);
        }
      }
    });
    this.filter.presetId = presetId;
    this.setupDatepickers();
    this.restoreDropdownDependencyAndApplyFilters();
  }

  filterFromCookies() {
    let filtersCookie = this.loadFilters() || {};
    if (!Object.keys(filtersCookie).length) {
      filtersCookie = this.filterSetOnNothingSelected;
    }
    this.clearFilters();
    this.isOpened =
      filtersCookie !== undefined && !!Object.keys(filtersCookie).length;
    this._filterService.setOpened(this.filterType, this.isOpened);
    const filterKeys = Object.keys(filtersCookie);
    if (
      this.allowedPresets &&
      this.organization &&
      (this.presetsList.filter((item) => item.id === filtersCookie.presetId)
        .length ||
        this.isDefaultPresetAllowed)
    ) {
      this.setPresetFilters(
        this.isDefaultPresetAllowed
          ? this.presetsList[0].id
          : filtersCookie.presetId
      );
    } else {
      filterKeys.forEach((key) => {
        if (
          this.helper.isExist(filtersCookie[key]) &&
          this.allowedFilters[key]
        ) {
          switch (key) {
            case 'installedAtFrom':
            case 'lifetimeFrom':
            case 'lifetimeTo':
            case 'installedAtTo':
            case 'dueDateTo':
            case 'dueDateFrom':
              this.filter[key] = DateTime.fromISO(filtersCookie[key]).toISO();
              break;
            case 'totalRiskTo':
            case 'totalRiskFrom':
            case 'fhieFrom':
            case 'fhieTo':
            case 'fhiFrom':
            case 'fhiTo':
              this.filter[key] = +filtersCookie[key];
              break;
            case 'location':
              Configs.FACILITIES.forEach((facility) => {
                if (filtersCookie[facility] != null && facility !== 'zone') {
                  this.filter[facility] = filtersCookie[facility];
                }
              });
              break;
            default:
              this.filter[key] = filtersCookie[key];
          }
        }
      });
      this.restoreDropdownDependencyAndApplyFilters();
    }
  }

  protected restoreFilters() {
    const $stateParams = this.activeRoute.snapshot.queryParams;
    if ($stateParams.zone || $stateParams.location || $stateParams.asset_type) {
      this.filterFromQuery();
    } else {
      switch (true) {
        case this.saveToCookies || this.isDefaultPresetAllowed:
          this.filterFromCookies();
          break;
        case !!this.readyFilterSet:
          this.restoreDropdownDependencyAndApplyFilters(this.readyFilterSet);
          break;
        default:
          this.fillFiltersIfOptionIsNotPicked();
          this.restoreDropdownDependencyAndApplyFilters();
      }
    }
  }

  protected setupListsToAppearance() {
    this.showLocationFiltersBlock =
      ((this.allowedFilters.location && !this.allowedFilters.tag_ids) ||
        this.allowedFilters.floor ||
        this.allowedFilters.room ||
        this.allowedFilters.types ||
        // || this.allowedFilters.trades
        this.allowedFilters.zone ||
        this.allowedFilters.engineer ||
        this.allowedFilters.classification_ids) &&
      !this.allowedFilters.user;

    this.showRisksFhiFiltersBlock =
      this.allowedFilters.fhieFrom ||
      this.allowedFilters.fhieTo ||
      this.allowedFilters.totalRiskFrom ||
      this.allowedFilters.totalRiskTo ||
      this.allowedFilters.fhiFrom ||
      this.allowedFilters.fhiTo ||
      this.allowedFilters.installedAtFrom ||
      this.allowedFilters.installedAtTo ||
      this.allowedFilters.assignee ||
      this.allowedFilters.dueDateFrom ||
      this.allowedFilters.dueDateTo;

    this.showBuildingFiltersBlock =
      this.allowedFilters.tag_ids ||
      this.allowedFilters.totalFhiRIndexFrom ||
      this.allowedFilters.totalFhiRIndexTo ||
      this.allowedFilters.fteFrom ||
      this.allowedFilters.fteTo ||
      this.allowedFilters.totalFteFrom ||
      this.allowedFilters.totalFteTo ||
      this.allowedFilters.sqFootageFrom ||
      this.allowedFilters.sqFootageTo ||
      this.allowedFilters.scoreFrom ||
      this.allowedFilters.scoreTo ||
      this.allowedFilters.total_project_cost_from ||
      this.allowedFilters.total_project_cost_to ||
      this.allowedFilters.user;
  }

  protected getLocationsForDropdown(params) {
    if (
      this._featureFlagsService.isFeatureEnabled(
        'OR_13306_relevant_location_building'
      ) &&
      this.filterType.indexOf('forecastAssetList') > -1
    ) {
      let forecastId = this.dataFromComponents;
      return this._locationService.getForecastLocation(forecastId, params);
    } else {
      return this._locationService.getLocations(params);
    }
  }
}
