<!-- Preset -->
<div
  class="toolbar__panel flex"
  *ngIf="allowedPresets && organization"
  [hidden]="!isOpened"
>
  <app-multiselect
    [(ngModel)]="filter.presetId"
    [isDisabled]="!presetsList || !presetsList.length || disableFilters"
    [key]="'id'"
    [as]="'name'"
    [placeholder]="'Preset...'"
    [id]="'presets'"
    [singleValueSelected]="true"
    [returnSingleValueAsNumber]="true"
    [dropdownType]="'presetsDropdown'"
    [userId]="userId"
    [values]="presetsList"
    (onPresetDeleting)="deletePreset($event)"
    (onPresetChoosing)="setPresetFilters($event)"
    class="filter__dropdown filter__dropdown_no-mrgn filter-multiselect"
  >
  </app-multiselect>
  <button
    type="button"
    class="btn action-btn action-btn_default"
    [disabled]="isEmptyFilter(filter)"
    (click)="createPreset()"
  >
    <span class="action-btn__icon"
      ><app-svg [href]="'check-square'" iconClass="icon icon_s"></app-svg
    ></span>
    <span class="action-btn__title">Save as preset</span>
  </button>
</div>
<!-- end Preset -->
<form #filtersForm="ngForm" (ngSubmit)="submitFilters()" [hidden]="!isOpened">
  <ul class="filter" *ngIf="!isLoading && showLocationFiltersBlock">
    <!--Class Filter-->
    <li
      class="filter__dropdown"
      *ngIf="
        filterDropdowns.classifications && allowedFilters.classification_ids
      "
    >
      <app-multiselect
        [(ngModel)]="filter.classification_ids"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Classes'"
        (ngModelChange)="onFiltersChanges('class')"
        [id]="'class'"
        [values]="filterDropdowns.classifications"
        [resetField]="'classification_ids'"
        name="classification_ids"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!--END Class Filter-->

    <!--Location Filter-->
    <li
      class="filter__dropdown"
      *ngIf="filterDropdowns.location && allowedFilters.location"
    >
      <app-multiselect
        [(ngModel)]="filter.location"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Locations'"
        (ngModelChange)="onFiltersChanges('location')"
        [id]="'location'"
        [values]="filterDropdowns.location"
        [resetField]="'location'"
        [showSelectAll]="true"
        name="location"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!--END Location Filter-->
    <!--Buildings Filter-->
    <li class="filter__dropdown" *ngIf="allowedFilters.building">
      <app-multiselect
        [(ngModel)]="filter.building"
        [key]="'id'"
        [as]="'name'"
        [isDisabled]="!filterDropdowns.building?.length || disableFilters"
        [placeholder]="'All Buildings'"
        (ngModelChange)="onFiltersChanges('building')"
        (onResetValue)="resetFilterField('building')"
        [id]="'building'"
        [values]="filterDropdowns.building"
        [resetField]="'building'"
        [showSelectAll]="true"
        [combineValues]="true"
        name="building"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!--END Buildings Filter-->

    <!--Floors Filter-->
    <li class="filter__dropdown" *ngIf="allowedFilters.floor">
      <app-multiselect
        [id]="'floor'"
        [(ngModel)]="filter.floor"
        [isDisabled]="!filterDropdowns.floor?.length || disableFilters"
        (ngModelChange)="onFiltersChanges('floor')"
        (onResetValue)="resetFilterField('floor')"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Floors'"
        [combineValues]="true"
        [values]="filterDropdowns.floor"
        [resetField]="'floor'"
        [showSelectAll]="true"
        name="floor"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!--END Floors Filter-->

    <!--Rooms Filter-->
    <li class="filter__dropdown" *ngIf="allowedFilters.room">
      <app-multiselect
        [id]="'room'"
        [(ngModel)]="filter.room"
        [isDisabled]="!filterDropdowns.room?.length || disableFilters"
        (ngModelChange)="onFiltersChanges('room')"
        (onResetValue)="resetFilterField('room')"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Rooms'"
        [combineValues]="true"
        [values]="filterDropdowns.room"
        [resetField]="'room'"
        [showSelectAll]="true"
        name="room"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!--END Rooms Filter-->

    <!--Engineers Filter-->
    <li class="filter__dropdown" *ngIf="isTenant && allowedFilters.engineer">
      <app-multiselect
        [id]="'engineer'"
        [(ngModel)]="filter.engineer"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Engineers'"
        [values]="filterDropdowns.engineer"
        (ngModelChange)="onFiltersChanges('engineer')"
        [rightSided]="true"
        [resetField]="'engineer'"
        [showSelectAll]="true"
        name="engineer"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>

    <li class="filter__dropdown" *ngIf="allowedFilters.made_incomplete_by">
      <app-multiselect
        [id]="'made_incomplete_by'"
        [(ngModel)]="filter.made_incomplete_by"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'Made Incomplete By'"
        [values]="filterDropdowns.made_incomplete_by"
        (ngModelChange)="onFiltersChanges('made_incomplete_by')"
        [rightSided]="true"
        [resetField]="'made_incomplete_by'"
        [showSelectAll]="true"
        name="made_incomplete_by"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>

    <!--Zones Filter-->
    <li class="filter__dropdown" *ngIf="allowedFilters.zone">
      <app-multiselect
        [id]="'zone'"
        [(ngModel)]="filter.zone"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Zones'"
        [values]="filterDropdowns.zone"
        (ngModelChange)="onFiltersChanges('zone')"
        [rightSided]="true"
        [resetField]="'zone'"
        [showSelectAll]="true"
        name="zone"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!--END Zones Filter-->

    <!--All Types Filter-->
    <li class="filter__dropdown" *ngIf="allowedFilters.types">
      <app-multiselect
        [id]="'type'"
        [(ngModel)]="filter.types"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Types'"
        [values]="filterDropdowns.types"
        (ngModelChange)="onFiltersChanges('type')"
        [resetField]="'types'"
        [showSelectAll]="true"
        name="types"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!--END Types Filter-->

    <!--Trades All Filter-->
    <li class="filter__dropdown" *ngIf="allowedFilters.trades">
      <app-multiselect
        [id]="'trades'"
        [(ngModel)]="filter.trades"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Trades'"
        [values]="filterDropdowns.trades"
        (ngModelChange)="onFiltersChanges('trades')"
        [resetField]="'trades'"
        [showSelectAll]="true"
        name="trades"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!-- END Trades All Filter-->

    <!-- All Groups Filter -->
    <li class="filter__dropdown" *ngIf="allowedFilters.groups">
      <app-multiselect
        [id]="'groups'"
        [(ngModel)]="filter.groups"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Groups'"
        [values]="filterDropdowns.groups"
        (ngModelChange)="onFiltersChanges('groups')"
        [resetField]="'groups'"
        [showSelectAll]="true"
        name="groups"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!-- END Groups Filter -->

    <!-- All Subgroups Filter -->
    <li class="filter__dropdown" *ngIf="allowedFilters.subgroups">
      <app-multiselect
        [id]="'subgroups'"
        [(ngModel)]="filter.subgroups"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Subgroups'"
        [values]="filterDropdowns.subgroups"
        (ngModelChange)="onFiltersChanges('subgroups')"
        [resetField]="'subgroups'"
        [showSelectAll]="true"
        name="subgroups"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>

    <li class="filter__dropdown" *ngIf="allowedFilters.customizedFundingSource">
      <app-multiselect
        [id]="'funding_source_id'"
        [(ngModel)]="filter['uar_customized.funding_source_id']"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Funding Sources'"
        [values]="filterDropdowns.fs"
        (ngModelChange)="onFiltersChanges('uar_customized.funding_source_id')"
        [resetField]="'uar_customized.funding_source_id'"
        fieldName="FS"
        name="uar_customized.funding_source_id"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <li class="filter__dropdown" *ngIf="allowedFilters.funding_source_id">
      <app-multiselect
        [id]="'funding_source_id'"
        [(ngModel)]="filter.funding_source_id"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Funding Sources'"
        [values]="filterDropdowns.fs"
        (ngModelChange)="onFiltersChanges('funding_source_id')"
        [resetField]="'funding_source_id'"
        fieldName="FS"
        name="funding_source_id"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!-- Tiers Filter -->
    <li class="filter__dropdown" *ngIf="allowedFilters.tiers">
      <app-multiselect
        [id]="'tiers'"
        [(ngModel)]="filter.tiers"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Tiers'"
        [values]="filterDropdowns.tiers"
        (ngModelChange)="onFiltersChanges('tiers')"
        [resetField]="'tiers'"
        [showSelectAll]="true"
        name="tiers"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!-- END Tiers Filter -->
    <!-- Specialties Filter -->
    <li class="filter__dropdown" *ngIf="allowedFilters.specialties">
      <app-multiselect
        [id]="'specialties'"
        [(ngModel)]="filter.specialties"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Specialties'"
        [values]="filterDropdowns.specialties"
        (ngModelChange)="onFiltersChanges('specialties')"
        [resetField]="'specialties'"
        [showSelectAll]="true"
        name="specialties"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!-- END Specialities Filter -->

    <!-- Area Served filter -->
    <li class="filter__dropdown" *ngIf="allowedFilters.areaServed">
      <app-multiselect
        [id]="'areaServed'"
        [(ngModel)]="filter.areaServed"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [tooltipField]="'description'"
        [placeholder]="'All Areas'"
        [values]="filterDropdowns.areaServed"
        (ngModelChange)="onFiltersChanges('areaServed')"
        [resetField]="'areaServed'"
        [showSelectAll]="true"
        name="areaServed"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!-- Area Served filter -->

    <!-- Cost Center filter -->
    <li class="filter__dropdown" *ngIf="allowedFilters.cost_center">
      <app-multiselect
        [id]="'cost_center'"
        [(ngModel)]="filter.cost_center"
        [isDisabled]="disableFilters"
        [key]="'name'"
        [as]="'name'"
        [placeholder]="'All Cost Centers'"
        [values]="filterDropdowns.cost_center"
        (ngModelChange)="onFiltersChanges('cost_center')"
        [rightSided]="true"
        [resetField]="'cost_center'"
        [showSelectAll]="true"
        name="cost_center"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!-- Cost Center filter -->

    <!-- Assignee Filter -->
    <li class="filter__dropdown" *ngIf="allowedFilters.assignee">
      <app-multiselect
        [(ngModel)]="filter.assignee"
        [key]="'id'"
        [as]="'full_name'"
        [placeholder]="'Any Assignee'"
        [fieldName]="'Assignee'"
        [id]="'assignee'"
        [values]="filterDropdowns.assignee"
        [resetField]="'assignee'"
        (ngModelChange)="onFiltersChanges('assignee')"
        [showSelectAll]="true"
        name="assignee"
        class="filter-multiselect"
      ></app-multiselect>
    </li>
    <li
      *ngIf="allowedFilters.client_only_asset_type"
      class="filter__dropdown el-in-a-row el-in-a-row_c"
    >
      <label class="custom-checkbox">
        <input
          class="custom-checkbox__input"
          type="checkbox"
          name="client_only_asset_type"
          [isDisabled]="disableFilters"
          [(ngModel)]="filter.client_only_asset_type"
          (ngModelChange)="onFiltersChanges('client_only_asset_type')"
        />
        <span
          class="custom-checkbox__check custom-checkbox__check_labeled"
        ></span>
        Only Client AR
      </label>
    </li>
    <!-- END Assignee Filter -->
    <!--Due Date Filter-->
    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c el-in-a-row_lg"
      *ngIf="allowedFilters.dueDateFrom || allowedFilters.dueDateTo"
    >
      <div class="el-in-a-row el-in-a-row_c">
        <span class="filter-label">Due Date</span>
        <label>
          <mat-form-field
            class="datepicker-block datepicker-block_narrow"
            [ngClass]="{ 'form-block_error': hasError(_dueDateFrom) }"
          >
            <input
              type="text"
              name="dueDateFrom"
              placeholder="From"
              matInput
              (keydown.enter)="$event.target.blur()"
              #_dueDateFrom="ngModel"
              [matDatepicker]="dueDateFrom"
              [(ngModel)]="filter.dueDateFrom"
              (ngModelChange)="onFiltersChanges('dueDateFrom')"
              [max]="maxStopDate('dueDateTo')"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_dueDateFrom)"
              [tooltip]="dueDateFromError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
            <mat-datepicker-toggle matSuffix [for]="dueDateFrom">
              <mat-icon matDatepickerToggleIcon>
                <app-svg [href]="'datepicker-calendar'"></app-svg>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #dueDateFrom
              [startAt]="filter.dueDateFrom"
            ></mat-datepicker>
          </mat-form-field>
        </label>
        <span class="filter__extended-label">-</span>
        <label>
          <mat-form-field
            class="datepicker-block datepicker-block_narrow"
            [ngClass]="{ 'form-block_error': hasError(_dueDateTo) }"
          >
            <input
              type="text"
              name="dueDateTo"
              placeholder="To"
              matInput
              (keydown.enter)="$event.target.blur()"
              #_dueDateTo="ngModel"
              [matDatepicker]="dueDateTo"
              [(ngModel)]="filter.dueDateTo"
              (ngModelChange)="onFiltersChanges('dueDateTo')"
              [min]="minStopDate('dueDateFrom')"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_dueDateTo)"
              [tooltip]="dueDateToError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
            <mat-datepicker-toggle matSuffix [for]="dueDateTo">
              <mat-icon matDatepickerToggleIcon>
                <app-svg [href]="'datepicker-calendar'"></app-svg>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #dueDateTo
              [startAt]="filter.dueDateTo"
            ></mat-datepicker>
          </mat-form-field>
        </label>
      </div>
      <ng-template #dueDateFromError>
        <p *ngIf="_dueDateFrom.errors && _dueDateFrom.errors.matDatepickerMax">
          {{ "validation.max_date_limit" | translate }}
        </p>
        <p
          *ngIf="_dueDateFrom.errors && _dueDateFrom.errors.matDatepickerParse"
        >
          {{ "validation.bad_date" | translate }}
        </p>
      </ng-template>
      <ng-template #dueDateToError>
        <p *ngIf="_dueDateTo.errors && _dueDateTo.errors.matDatepickerMax">
          {{ "validation.max_date" | translate }}
        </p>
        <p *ngIf="_dueDateTo.errors && _dueDateTo.errors.matDatepickerParse">
          {{ "validation.bad_date" | translate }}
        </p>
        <p
          *ngIf="
            _dueDateTo.errors &&
            !_dueDateTo.errors.matDatepickerMax &&
            _dueDateTo.errors.matDatepickerMin
          "
        >
          {{ "validation.min_date_limit" | translate }}
        </p>
      </ng-template>
    </li>
    <!--END Due Date Filter-->
  </ul>
  <ul
    class="filter flex-a-start filter__unbordered filter__no_padding-top"
    *ngIf="!isLoading && showRisksFhiFiltersBlock"
  >
    <!--Lifetime Filter-->
    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c el-in-a-row_lg"
      *ngIf="allowedFilters.lifetimeFrom"
    >
      <span class="filter-label">Lifetime Ends</span>
      <div class="el-in-a-row el-in-a-row_c">
        <label>
          <mat-form-field
            class="datepicker-block datepicker-block_narrow"
            [ngClass]="{ 'form-block_error': hasError(_lifetimeFrom) }"
          >
            <input
              type="text"
              name="lifetimeFrom"
              placeholder="From"
              matInput
              [disabled]="disableFilters"
              #_lifetimeFrom="ngModel"
              [matDatepicker]="lifetimeFrom"
              [max]="maxStopDate('lifetimeTo', true)"
              [(ngModel)]="filter.lifetimeFrom"
              (ngModelChange)="onFiltersChanges('lifetimeFrom')"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_lifetimeFrom)"
              [tooltip]="lifetimeFromError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
            <mat-datepicker-toggle matSuffix [for]="lifetimeFrom">
              <mat-icon matDatepickerToggleIcon>
                <app-svg [href]="'datepicker-calendar'"></app-svg>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #lifetimeFrom
              [startAt]="filter.lifetimeFrom"
            ></mat-datepicker>
          </mat-form-field>
        </label>
        <span class="filter-label">-</span>
        <label>
          <mat-form-field
            class="datepicker-block datepicker-block_narrow"
            [ngClass]="{ 'form-block_error': hasError(_lifetimeTo) }"
          >
            <input
              type="text"
              name="lifetimeTo"
              placeholder="To"
              matInput
              [disabled]="disableFilters"
              #_lifetimeTo="ngModel"
              [matDatepicker]="lifetimeTo"
              [(ngModel)]="filter.lifetimeTo"
              (ngModelChange)="onFiltersChanges('lifetimeTo')"
              [min]="minStopDate('lifetimeFrom')"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_lifetimeTo)"
              [tooltip]="lifetimeToError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
            <mat-datepicker-toggle matSuffix [for]="lifetimeTo">
              <mat-icon matDatepickerToggleIcon>
                <app-svg [href]="'datepicker-calendar'"></app-svg>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #lifetimeTo
              [startAt]="filter.lifetimeFrom"
            ></mat-datepicker>
          </mat-form-field>
        </label>
        <ng-template #lifetimeFromError>
          <p *ngIf="_lifetimeFrom.errors.matDatepickerMax">
            {{ "validation.max_date_limit" | translate }}
          </p>
          <p
            *ngIf="
              _lifetimeFrom.errors && !_lifetimeFrom.errors.matDatepickerMax
            "
          >
            {{ "validation.bad_date" | translate }}
          </p>
        </ng-template>
        <ng-template #lifetimeToError>
          <p *ngIf="_lifetimeTo.errors.matDatepickerMin">
            {{ "validation.min_date_limit" | translate }}
          </p>
          <p *ngIf="_lifetimeTo.errors && !_lifetimeTo.errors.matDatepickerMin">
            {{ "validation.bad_date" | translate }}
          </p>
        </ng-template>
      </div>
    </li>
    <!--END Lifetime Filter-->
    <!--FHI Filter-->
    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_lg flex-column flex-a-start"
      *ngIf="allowedFilters.fhiFrom"
    >
      <div class="el-in-a-row el-in-a-row_c el-in-a-row_lg">
        <span class="filter-label">FHI index</span>
        <div class="el-in-a-row el-in-a-row_c">
          <label>
            <input
              [(ngModel)]="filter.fhiFrom"
              (ngModelChange)="onFiltersChanges('fhiFrom')"
              name="fhiFrom"
              #_fhiFrom="ngModel"
              type="text"
              placeholder="From"
              class="form-block__element select_xs"
              [disabled]="disableFilters"
              [ngClass]="{ 'form-block_error': hasError(_fhiFrom) }"
              [customMin]="0"
              [customMax]="validateMax('fhiTo', 100)"
              [pattern]="regex.regexIntegerNumber"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_fhiFrom)"
              [tooltip]="fhiFromError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </label>
          <span class="filter-label">-</span>
          <label>
            <input
              [(ngModel)]="filter.fhiTo"
              (ngModelChange)="onFiltersChanges('fhiTo')"
              name="fhiTo"
              #_fhiTo="ngModel"
              type="text"
              placeholder="To"
              class="form-block__element select_xs"
              [disabled]="disableFilters"
              [ngClass]="{ 'form-block_error': hasError(_fhiTo) }"
              [customMin]="validateMin('fhiFrom')"
              [customMax]="100"
              [pattern]="regex.regexIntegerNumber"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_fhiTo)"
              [tooltip]="fhiToError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </label>
        </div>
      </div>
      <ng-template #fhiFromError>
        <p *ngIf="_fhiFrom.errors.pattern">
          {{ "validation.integer" | translate }}
        </p>
        <p *ngIf="_fhiFrom.errors.customMin && !_fhiFrom.errors.pattern">
          {{
            "validation.costMin"
              | translate: { name: "FHI Index From", "0": "0" }
          }}
        </p>
        <p *ngIf="_fhiFrom.errors.customMax && !_fhiFrom.errors.pattern">
          {{
            "validation.costMax"
              | translate
                : {
                    name: "FHI Index",
                    number: validateMax("fhiTo", 100).toString()
                  }
          }}
        </p>
      </ng-template>
      <ng-template #fhiToError>
        <p *ngIf="_fhiTo.errors.pattern">
          {{ "validation.integer" | translate }}
        </p>
        <p *ngIf="_fhiTo.errors.customMin && !_fhiTo.errors.pattern">
          {{
            "validation.costMin"
              | translate
                : {
                    name: "FHI Index To",
                    "0": validateMin("fhiFrom").toString()
                  }
          }}
        </p>
        <p *ngIf="_fhiTo.errors.customMax && !_fhiTo.errors.pattern">
          {{
            "validation.costMax"
              | translate: { name: "FHI Index To", number: "100" }
          }}
        </p>
      </ng-template>
    </li>
    <!--END FHI Filter-->

    <!--FHIE Filter-->
    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c el-in-a-row_lg"
      *ngIf="allowedFilters.fhieFrom"
    >
      <span class="filter-label">FHIE Score Range</span>
      <div class="el-in-a-row el-in-a-row_c">
        <label>
          <app-select
            name="fhieFrom"
            [(ngModel)]="filter.fhieFrom"
            [disabled]="disableFilters"
            (ngModelChange)="onFiltersChanges('fhieFrom')"
            [keyAsValue]="true"
            placeholder="From"
            [values]="filterDropdowns.fhieFrom"
            widthClass="multiselect_xs"
          >
          </app-select>
        </label>
        <span class="filter-label">-</span>
        <label>
          <app-select
            [(ngModel)]="filter.fhieTo"
            (ngModelChange)="onFiltersChanges('fhieTo')"
            name="fhieTo"
            [disabled]="disableFilters"
            [keyAsValue]="true"
            placeholder="To"
            [values]="filterDropdowns.fhieTo"
            widthClass="multiselect_xs"
          >
          </app-select>
        </label>
      </div>
    </li>
    <!--END FHIE Filter-->
    <!--Risk Filter-->
    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c el-in-a-row_lg"
      *ngIf="allowedFilters.totalRiskFrom"
    >
      <span class="filter-label">Total Risk Range</span>
      <div class="el-in-a-row el-in-a-row_c">
        <label>
          <app-select
            [(ngModel)]="filter.totalRiskFrom"
            name="totalRiskFrom"
            [disabled]="disableFilters"
            (ngModelChange)="onFiltersChanges('totalRiskFrom')"
            [keyAsValue]="true"
            placeholder="From"
            [values]="filterDropdowns.totalRiskFrom"
            widthClass="multiselect_xs"
          >
          </app-select>
        </label>
        <span class="filter-label">-</span>
        <label>
          <app-select
            [(ngModel)]="filter.totalRiskTo"
            (ngModelChange)="onFiltersChanges('totalRiskTo')"
            name="totalRiskTo"
            [disabled]="disableFilters"
            [keyAsValue]="true"
            placeholder="To"
            [values]="filterDropdowns.totalRiskTo"
            widthClass="multiselect_xs"
          >
          </app-select>
        </label>
      </div>
    </li>
    <!--Installation Filter-->
    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c"
      *ngIf="allowedFilters.installedAtFrom && allowedFilters.installedAtTo"
    >
      <div class="el-in-a-row el-in-a-row_c el-in-a-row_lg">
        <span class="filter-label">Installation Date</span>
        <label>
          <mat-form-field
            class="datepicker-block datepicker-block_narrow"
            [ngClass]="{ 'form-block_error': hasError(_installedAtFrom) }"
          >
            <input
              type="text"
              name="installedAtFrom"
              placeholder="From"
              matInput
              [disabled]="disableFilters"
              #_installedAtFrom="ngModel"
              [matDatepicker]="installedAtFrom"
              [(ngModel)]="filter.installedAtFrom"
              [max]="maxStopDate('installedAtTo')"
              (ngModelChange)="onFiltersChanges('installedAtFrom')"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_installedAtFrom)"
              [tooltip]="installedAtFromError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
            <mat-datepicker-toggle matSuffix [for]="installedAtFrom">
              <mat-icon matDatepickerToggleIcon>
                <app-svg [href]="'datepicker-calendar'"></app-svg>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #installedAtFrom
              [startAt]="filter.installedAtFrom"
            ></mat-datepicker>
          </mat-form-field>
        </label>
        <span class="filter-label">-</span>
        <label>
          <mat-form-field
            class="datepicker-block datepicker-block_narrow"
            [ngClass]="{ 'form-block_error': hasError(_installedAtTo) }"
          >
            <input
              type="text"
              name="installedAtTo"
              placeholder="To"
              matInput
              [disabled]="disableFilters"
              #_installedAtTo="ngModel"
              [matDatepicker]="installedAtTo"
              [(ngModel)]="filter.installedAtTo"
              [min]="minStopDate('installedAtFrom')"
              (ngModelChange)="onFiltersChanges('installedAtTo')"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_installedAtTo)"
              [tooltip]="installedAtToError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
            <mat-datepicker-toggle matSuffix [for]="installedAtTo">
              <mat-icon matDatepickerToggleIcon>
                <app-svg [href]="'datepicker-calendar'"></app-svg>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #installedAtTo
              [startAt]="filter.installedAtFrom"
            ></mat-datepicker>
          </mat-form-field>
        </label>
      </div>
      <ng-template #installedAtFromError>
        <p
          *ngIf="
            _installedAtFrom.errors && _installedAtFrom.errors.matDatepickerMax
          "
        >
          {{ "validation.max_date_limit" | translate }}
        </p>
        <p
          *ngIf="
            _installedAtFrom.errors &&
            _installedAtFrom.errors.matDatepickerParse
          "
        >
          {{ "validation.bad_date" | translate }}
        </p>
      </ng-template>
      <ng-template #installedAtToError>
        <p
          *ngIf="
            _installedAtTo.errors && _installedAtTo.errors.matDatepickerMax
          "
        >
          {{ "validation.max_date_limit" | translate }}
        </p>
        <p
          *ngIf="
            _installedAtTo.errors && _installedAtTo.errors.matDatepickerParse
          "
        >
          {{ "validation.bad_date" | translate }}
        </p>
        <p
          *ngIf="
            _installedAtTo.errors &&
            !_installedAtTo.errors.matDatepickerMax &&
            _installedAtTo.errors.matDatepickerMin
          "
        >
          {{ "validation.min_date_limit" | translate }}
        </p>
      </ng-template>
    </li>
    <!--Installation Filter-->

    <!--Date Range-->
    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c"
      *ngIf="allowedFilters.dateRangeFrom && allowedFilters.dateRangeTo"
    >
      <div class="el-in-a-row el-in-a-row_c el-in-a-row_lg">
        <span class="filter-label">Date Created</span>
        <label>
          <mat-form-field
            class="datepicker-block datepicker-block_narrow"
            [ngClass]="{ 'form-block_error': hasError(_dateRangeFrom) }"
          >
            <input
              type="text"
              name="dateRangeFrom"
              placeholder="From"
              matInput
              [disabled]="disableFilters"
              #_dateRangeFrom="ngModel"
              [matDatepicker]="dateRangeFrom"
              [(ngModel)]="filter.dateRangeFrom"
              [max]="
                maxStopDate('dateRangeTo')
                  ? maxStopDate('dateRangeTo')
                  : todayDate
              "
              (ngModelChange)="onFiltersChanges('dateRangeFrom')"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_dateRangeFrom)"
              [tooltip]="dateRangeFromError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
            <mat-datepicker-toggle matSuffix [for]="dateRangeFrom">
              <mat-icon matDatepickerToggleIcon>
                <app-svg [href]="'datepicker-calendar'"></app-svg>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #dateRangeFrom
              [startAt]="filter.dateRangeFrom"
            ></mat-datepicker>
          </mat-form-field>
        </label>
        <span class="filter-label">-</span>
        <label>
          <mat-form-field
            class="datepicker-block datepicker-block_narrow"
            [ngClass]="{ 'form-block_error': hasError(_dateRangeTo) }"
          >
            <input
              type="text"
              name="dateRangeTo"
              placeholder="To"
              matInput
              [disabled]="disableFilters"
              #_dateRangeTo="ngModel"
              [matDatepicker]="dateRangeTo"
              [(ngModel)]="filter.dateRangeTo"
              [min]="minStopDate('dateRangeFrom')"
              [max]="todayDate"
              (ngModelChange)="onFiltersChanges('dateRangeTo')"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_dateRangeTo)"
              [tooltip]="dateRangeToError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
            <mat-datepicker-toggle matSuffix [for]="dateRangeTo">
              <mat-icon matDatepickerToggleIcon>
                <app-svg [href]="'datepicker-calendar'"></app-svg>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #dateRangeTo
              [startAt]="filter.dateRangeFrom"
            ></mat-datepicker>
          </mat-form-field>
        </label>
      </div>
      <ng-template #dateRangeFromError>
        <p
          *ngIf="
            _dateRangeFrom.errors && _dateRangeFrom.errors.matDatepickerMax
          "
        >
          {{ "validation.max_date_limit" | translate }}
        </p>
        <p
          *ngIf="
            _dateRangeFrom.errors && _dateRangeFrom.errors.matDatepickerParse
          "
        >
          {{ "validation.bad_date" | translate }}
        </p>
      </ng-template>
      <ng-template #dateRangeToError>
        <p *ngIf="_dateRangeTo.errors && _dateRangeTo.errors.matDatepickerMax">
          {{ "validation.max_date_limit" | translate }}
        </p>
        <p
          *ngIf="_dateRangeTo.errors && _dateRangeTo.errors.matDatepickerParse"
        >
          {{ "validation.bad_date" | translate }}
        </p>
        <p
          *ngIf="
            _dateRangeTo.errors &&
            !_dateRangeTo.errors.matDatepickerMax &&
            _dateRangeTo.errors.matDatepickerMin
          "
        >
          {{ "validation.min_date_limit" | translate }}
        </p>
      </ng-template>
    </li>
  </ul>

  <ul class="filter" *ngIf="!isLoading && showBuildingFiltersBlock">
    <!--Location Filter-->
    <li
      class="filter__dropdown"
      *ngIf="filterDropdowns.location && allowedFilters.location"
    >
      <app-multiselect
        [(ngModel)]="filter.location"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Locations'"
        (ngModelChange)="onFiltersChanges('location')"
        [id]="'location'"
        [values]="filterDropdowns.location"
        [resetField]="'location'"
        [showSelectAll]="true"
        name="location"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!--END Location Filter-->

    <!--Buildings Filter-->
    <li class="filter__dropdown" *ngIf="allowedFilters.building">
      <app-multiselect
        [(ngModel)]="filter.building"
        [key]="'id'"
        [as]="'name'"
        [isDisabled]="!filterDropdowns.building?.length || disableFilters"
        [placeholder]="'All Buildings'"
        (ngModelChange)="onFiltersChanges('building')"
        [id]="'building'"
        [values]="filterDropdowns.building"
        [resetField]="'building'"
        [showSelectAll]="true"
        name="building"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!--END Buildings Filter-->

    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c el-in-a-row_lg"
      *ngIf="allowedFilters.tag_ids"
    >
      <span class="filter-label">Facility Attributes</span>
      <app-fa-multiselect
        [(ngModel)]="filter.tag_ids"
        (ngModelChange)="onFiltersChanges('tag_ids')"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All facility attributes'"
        [id]="'tags'"
        [values]="facilityAttributes"
        [oneLevel]="false"
        [iconClass]="'tags'"
        [topDirection]="true"
        name="fa_ids"
        class="form-block_sm"
      >
      </app-fa-multiselect>
    </li>

    <!--FHIE Filter-->
    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_lg flex-column flex-a-start"
      *ngIf="
        allowedFilters.totalFhiRIndexFrom && allowedFilters.totalFhiRIndexTo
      "
    >
      <div class="el-in-a-row el-in-a-row_c el-in-a-row_lg">
        <span class="filter-label">FHI</span>
        <div class="el-in-a-row el-in-a-row_c">
          <label>
            <input
              [(ngModel)]="filter.totalFhiRIndexFrom"
              (ngModelChange)="onFiltersChanges('totalFhiRIndexFrom')"
              name="totalFhiRIndexFrom"
              #_totalFhiRIndexFrom="ngModel"
              type="text"
              placeholder="From"
              class="form-block__element select_xs"
              [disabled]="disableFilters"
              [ngClass]="{ 'form-block_error': hasError(_totalFhiRIndexFrom) }"
              [customMin]="0"
              [customMax]="validateMax('totalFhiRIndexTo', 100)"
              [pattern]="regex.regexIntegerNumber"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_totalFhiRIndexFrom)"
              [tooltip]="totalFhiRIndexFrom_error"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </label>
          <span class="filter-label">-</span>
          <label>
            <input
              [(ngModel)]="filter.totalFhiRIndexTo"
              (ngModelChange)="onFiltersChanges('totalFhiRIndexTo')"
              name="totalFhiRIndexTo"
              #_totalFhiRIndexTo="ngModel"
              type="text"
              placeholder="To"
              class="form-block__element select_xs"
              [disabled]="disableFilters"
              [ngClass]="{ 'form-block_error': hasError(_totalFhiRIndexTo) }"
              [customMin]="validateMin('totalFhiRIndexFrom')"
              [customMax]="100"
              [pattern]="regex.regexIntegerNumber"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_totalFhiRIndexTo)"
              [tooltip]="totalFhiRIndexTo_error"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </label>
        </div>
      </div>
      <ng-template #totalFhiRIndexFrom_error>
        <p
          *ngIf="
            _totalFhiRIndexFrom.errors.customMin &&
            !_totalFhiRIndexFrom.errors.customMax &&
            !_totalFhiRIndexFrom.errors.pattern
          "
        >
          {{
            "validation.costMin" | translate: { name: "FHI Index", "0": "0" }
          }}
        </p>
        <p *ngIf="_totalFhiRIndexFrom.errors.customMax">
          {{
            "validation.costMax"
              | translate
                : {
                    name: "FHI Index From",
                    number: validateMax("totalFhiRIndexTo", 100).toString()
                  }
          }}
        </p>
        <p *ngIf="_totalFhiRIndexFrom.errors.pattern">
          {{ "validation.number" | translate }}
        </p>
      </ng-template>
      <ng-template #totalFhiRIndexTo_error>
        <p
          *ngIf="
            _totalFhiRIndexTo.errors.customMin &&
            !_totalFhiRIndexTo.errors.customMax
          "
        >
          {{
            "validation.costMin"
              | translate
                : {
                    name: "FHI Index To",
                    "0": validateMin("totalFhiRIndexFrom").toString()
                  }
          }}
        </p>
        <p *ngIf="_totalFhiRIndexTo.errors.customMax">
          {{
            "validation.costMax"
              | translate: { name: "FHI index", number: "100" }
          }}
        </p>
        <p *ngIf="_totalFhiRIndexTo.errors.pattern">
          {{ "validation.number" | translate }}
        </p>
      </ng-template>
    </li>
    <!--END FHIE Filter-->

    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c el-in-a-row_lg"
      *ngIf="allowedFilters.fteFrom && allowedFilters.fteTo"
    >
      <span class="filter-label">FTE actual</span>
      <div class="el-in-a-row el-in-a-row_c">
        <label>
          <input
            class="form-block_xxs form-block__element"
            type="text"
            name="fteFrom"
            placeholder="From"
            #_fteFrom="ngModel"
            (ngModelChange)="onFiltersChanges('fteFrom')"
            [(ngModel)]="filter.fteFrom"
            [ngClass]="{ 'form-block_error': hasError(_fteFrom) }"
            [customMin]="0"
            [customMax]="validateMax('fteTo', 999999.99)"
            [pattern]="regex.floatingPositiveNumbers"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError(_fteFrom)"
            [tooltip]="fteFromError"
            content-type="template"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </label>
        <span class="conjunction">-</span>
        <label>
          <input
            class="form-block__element form-block_xxs"
            type="text"
            [(ngModel)]="filter.fteTo"
            #_fteTo="ngModel"
            (ngModelChange)="onFiltersChanges('fteTo')"
            name="fteTo"
            placeholder="To"
            [ngClass]="{ 'form-block_error': hasError(_fteTo) }"
            [customMin]="validateMin('fteFrom')"
            [customMax]="999999.99"
            [pattern]="regex.floatingPositiveNumbers"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError(_fteTo)"
            [tooltip]="fteToError"
            content-type="template"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </label>
      </div>
      <ng-template #fteFromError>
        <p *ngIf="_fteFrom.errors.pattern">
          {{ "validation.number" | translate }}
        </p>
        <p *ngIf="_fteFrom.errors.customMin && !_fteFrom.errors.pattern">
          {{ "validation.costMin" | translate: { name: "FTE", "0": "0" } }}
        </p>
        <p *ngIf="_fteFrom.errors.customMax && !_fteFrom.errors.pattern">
          {{
            "validation.costMax"
              | translate
                : {
                    name: "FTE From",
                    number: validateMax("fteTo", 999999.99).toString()
                  }
          }}
        </p>
      </ng-template>
      <ng-template #fteToError>
        <p *ngIf="_fteTo.errors.pattern">
          {{ "validation.number" | translate }}
        </p>
        <p *ngIf="_fteTo.errors.customMin && !_fteTo.errors.pattern">
          {{
            "validation.costMin"
              | translate
                : {
                    name: "FTE To",
                    "0": validateMin("fteFrom").toString()
                  }
          }}
        </p>
        <p *ngIf="_fteTo.errors.customMax && !_fteTo.errors.pattern">
          {{
            "validation.costMax"
              | translate: { name: "FTE", number: "999999.99" }
          }}
        </p>
      </ng-template>
    </li>

    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c el-in-a-row_lg"
      *ngIf="allowedFilters.totalFteFrom && allowedFilters.totalFteTo"
    >
      <span class="filter-label">PM FTE Calculated</span>
      <div class="el-in-a-row el-in-a-row_c">
        <label>
          <input
            class="form-block_xxs form-block__element"
            type="text"
            name="totalFteFrom"
            placeholder="From"
            [(ngModel)]="filter.totalFteFrom"
            #_totalFteFrom="ngModel"
            (ngModelChange)="onFiltersChanges('totalFteFrom')"
            [ngClass]="{ 'form-block_error': hasError(_totalFteFrom) }"
            [customMin]="0"
            [customMax]="validateMax('totalFteTo', 999999.99)"
            [pattern]="regex.floatingPositiveNumbers"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError(_totalFteFrom)"
            [tooltip]="totalFteFromError"
            content-type="template"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </label>
        <span class="conjunction">-</span>
        <label>
          <input
            class="form-block__element form-block_xxs"
            type="text"
            [(ngModel)]="filter.totalFteTo"
            #_totalFteTo="ngModel"
            (ngModelChange)="onFiltersChanges('totalFteTo')"
            name="totalFteTo"
            placeholder="To"
            [ngClass]="{ 'form-block_error': hasError(_totalFteTo) }"
            [customMin]="validateMin('totalFteFrom', 0)"
            [customMax]="999999.99"
            [pattern]="regex.floatingPositiveNumbers"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError(_totalFteTo)"
            [tooltip]="totalFteToError"
            content-type="template"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </label>
      </div>
      <ng-template #totalFteFromError>
        <p *ngIf="_totalFteFrom.errors.pattern">
          {{ "validation.number" | translate }}
        </p>
        <p
          *ngIf="
            _totalFteFrom.errors.customMin && !_totalFteFrom.errors.pattern
          "
        >
          {{
            "validation.costMin" | translate: { name: "Total FTE", "0": "0" }
          }}
        </p>
        <p
          *ngIf="
            _totalFteFrom.errors.customMax && !_totalFteFrom.errors.pattern
          "
        >
          {{ 'validation.costMax' | translate: {
          'name': 'Total FTE From',
          number': validateMax('totalFteTo', 999999.99).toString()
          } }}
        </p>
      </ng-template>
      <ng-template #totalFteToError>
        <p *ngIf="_totalFteTo.errors.pattern">
          {{ "validation.number" | translate }}
        </p>
        <p *ngIf="_totalFteTo.errors.customMin && !_totalFteTo.errors.pattern">
          {{
            "validation.costMin"
              | translate
                : {
                    name: "Total FTE To",
                    "0": validateMin("totalFteFrom", 0).toString()
                  }
          }}
        </p>
        <p *ngIf="_totalFteTo.errors.customMax && !_totalFteTo.errors.pattern">
          {{
            "validation.costMax"
              | translate: { name: "Total FTE To", number: "999999.99" }
          }}
        </p>
      </ng-template>
    </li>

    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c el-in-a-row_lg"
      *ngIf="allowedFilters.sqFootageFrom && allowedFilters.sqFootageTo"
    >
      <span class="filter-label">Sq Footage</span>
      <div class="el-in-a-row el-in-a-row_c">
        <label>
          <input
            class="form-block_xxs form-block__element"
            type="text"
            name="sqFootageFrom"
            placeholder="From"
            (ngModelChange)="onFiltersChanges('sqFootageFrom')"
            [(ngModel)]="filter.sqFootageFrom"
            #_sqFootageFrom="ngModel"
            [ngClass]="{ 'form-block_error': hasError(_sqFootageFrom) }"
            [customMin]="0"
            [customMax]="validateMax('sqFootageTo')"
            [pattern]="regex.floatingPositiveNumbers"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError(_sqFootageFrom)"
            [tooltip]="sqFootageFromError"
            content-type="template"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </label>
        <span class="conjunction">-</span>
        <label>
          <input
            class="form-block_xxs form-block__element"
            type="text"
            name="sqFootageTo"
            placeholder="To"
            (ngModelChange)="onFiltersChanges('sqFootageTo')"
            [(ngModel)]="filter.sqFootageTo"
            #_sqFootageTo="ngModel"
            [ngClass]="{ 'form-block_error': hasError(_sqFootageTo) }"
            [customMin]="validateMin('sqFootageFrom')"
            [customMax]="999999999.99"
            [pattern]="regex.floatingPositiveNumbers"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError(_sqFootageTo)"
            [tooltip]="sqFootageToError"
            content-type="template"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </label>
      </div>
      <ng-template #sqFootageFromError>
        <p *ngIf="_sqFootageFrom.errors.pattern">
          {{ "validation.number" | translate }}
        </p>
        <p
          *ngIf="
            _sqFootageFrom.errors.customMin && !_sqFootageFrom.errors.pattern
          "
        >
          {{
            "validation.costMin" | translate: { name: "Sq Footage", "0": "0" }
          }}
        </p>
        <p
          *ngIf="
            _sqFootageFrom.errors.customMax && !_sqFootageFrom.errors.pattern
          "
        >
          {{
            "validation.costMax"
              | translate
                : {
                    name: "Sq Footage From",
                    number: validateMax("sqFootageTo").toString()
                  }
          }}
        </p>
      </ng-template>
      <ng-template #sqFootageToError>
        <p *ngIf="_sqFootageTo.errors.pattern">
          {{ "validation.number" | translate }}
        </p>
        <p
          *ngIf="_sqFootageTo.errors.customMin && !_sqFootageTo.errors.pattern"
        >
          {{
            "validation.costMin"
              | translate
                : {
                    name: "Sq Footage To",
                    "0": validateMin("sqFootageFrom").toString()
                  }
          }}
        </p>
        <p
          *ngIf="_sqFootageTo.errors.customMax && !_sqFootageTo.errors.pattern"
        >
          {{
            "validation.costMax"
              | translate: { name: "Sq Footage To", number: "999999999.99" }
          }}
        </p>
      </ng-template>
    </li>

    <!--Types Filter-->
    <li class="filter__dropdown" *ngIf="allowedFilters.types">
      <app-multiselect
        [id]="'type'"
        [(ngModel)]="filter.types"
        [isDisabled]="disableFilters"
        [key]="'id'"
        [as]="'name'"
        [placeholder]="'All Types'"
        [values]="filterDropdowns.types"
        (ngModelChange)="onFiltersChanges('type')"
        [resetField]="'types'"
        [showSelectAll]="true"
        name="types"
        class="filter-multiselect"
      >
      </app-multiselect>
    </li>
    <!--END Types Filter-->

    <!-- Score Filter -->
    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c el-in-a-row_lg"
      *ngIf="allowedFilters.scoreFrom && allowedFilters.scoreTo"
    >
      <span class="filter-label">Score</span>
      <div class="el-in-a-row el-in-a-row_c">
        <label>
          <app-select
            name="scoreFrom"
            [(ngModel)]="filter.scoreFrom"
            [disabled]="disableFilters"
            (ngModelChange)="onFiltersChanges('scoreFrom')"
            [keyAsValue]="true"
            placeholder="From"
            [values]="filterDropdowns.scoreFrom"
            widthClass="multiselect_xs"
          >
          </app-select>
        </label>
        <span class="filter-label">-</span>
        <label>
          <app-select
            [(ngModel)]="filter.scoreTo"
            (ngModelChange)="onFiltersChanges('scoreTo')"
            name="scoreTo"
            [disabled]="disableFilters"
            [keyAsValue]="true"
            placeholder="To"
            [values]="filterDropdowns.scoreTo"
            widthClass="multiselect_xs"
          >
          </app-select>
        </label>
      </div>
    </li>
    <!-- end Score Filter -->
    <!--Lifetime Filter-->
    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c el-in-a-row_lg"
      *ngIf="allowedFilters.lifetimeFrom && allowedFilters.lifetimeTo"
    >
      <span class="filter-label">Date</span>
      <div class="el-in-a-row el-in-a-row_c">
        <label>
          <mat-form-field
            class="datepicker-block datepicker-block_narrow"
            [ngClass]="{ 'form-block_error': hasError(_lifetimeFrom) }"
          >
            <input
              type="text"
              name="lifetimeFrom"
              placeholder="From"
              matInput
              [disabled]="disableFilters"
              #_lifetimeFrom="ngModel"
              [matDatepicker]="lifetimeFrom"
              [(ngModel)]="filter.lifetimeFrom"
              [max]="maxStopDate('lifetimeTo')"
              (ngModelChange)="onFiltersChanges('lifetimeFrom')"
            />
            <mat-datepicker-toggle matSuffix [for]="lifetimeFrom">
              <mat-icon matDatepickerToggleIcon>
                <app-svg [href]="'datepicker-calendar'"></app-svg>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #lifetimeFrom
              [startAt]="filter.lifetimeFrom"
            ></mat-datepicker>
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_lifetimeFrom)"
              [tooltip]="lifetimeFromError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </mat-form-field>
        </label>
        <span class="filter-label">-</span>
        <label>
          <mat-form-field
            class="datepicker-block datepicker-block_narrow"
            [ngClass]="{ 'form-block_error': hasError(_lifetimeTo) }"
          >
            <input
              type="text"
              name="lifetimeTo"
              placeholder="To"
              matInput
              [disabled]="disableFilters"
              #_lifetimeTo="ngModel"
              [matDatepicker]="lifetimeTo"
              [(ngModel)]="filter.lifetimeTo"
              (ngModelChange)="onFiltersChanges('lifetimeTo')"
              [min]="minStopDate('lifetimeFrom')"
            />
            <mat-datepicker-toggle matSuffix [for]="lifetimeTo">
              <mat-icon matDatepickerToggleIcon>
                <app-svg [href]="'datepicker-calendar'"></app-svg>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #lifetimeTo
              [startAt]="filter.lifetimeFrom"
            ></mat-datepicker>
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError(_lifetimeTo)"
              [tooltip]="lifetimeToError"
              content-type="template"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </mat-form-field>
        </label>
      </div>
      <ng-template #lifetimeFromError>
        <p class="error error_inline" *ngIf="hasError(_lifetimeFrom)">
          <ng-container *ngIf="_lifetimeFrom.errors.matDatepickerMax">
            {{ "validation.max_date_limit" | translate }}
          </ng-container>
          <ng-container *ngIf="_lifetimeFrom.errors">
            {{ "validation.bad_date" | translate }}
          </ng-container>
        </p>
      </ng-template>
      <ng-template #lifetimeToError>
        <p class="error error_inline" *ngIf="hasError(_lifetimeTo)">
          <ng-container *ngIf="_lifetimeTo.errors.matDatepickerMin">
            {{ "validation.min_date_limit" | translate }}
          </ng-container>
          <ng-container *ngIf="_lifetimeTo.errors">
            {{ "validation.bad_date" | translate }}
          </ng-container>
        </p>
      </ng-template>
    </li>
    <!--END Lifetime Filter-->

    <!--Project cost -->
    <li
      class="filter__extended-filter el-in-a-row el-in-a-row_c el-in-a-row_lg"
      *ngIf="
        allowedFilters.total_project_cost_from &&
        allowedFilters.total_project_cost_to
      "
    >
      <span class="filter-label">Total Project Cost</span>
      <div class="el-in-a-row el-in-a-row_c">
        <label>
          <input
            class="form-block_xxs form-block__element"
            type="text"
            name="total_project_cost_from"
            placeholder="From"
            [(ngModel)]="filter.total_project_cost_from"
            #_costFrom="ngModel"
            (ngModelChange)="onFiltersChanges('total_project_cost_from')"
            [ngClass]="{ 'form-block_error': hasError(_costFrom) }"
            [customMin]="0"
            [customMax]="validateMax('total_project_cost_to')"
            [pattern]="regex.regexIntegerNumber"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError(_costFrom)"
            [tooltip]="costFromError"
            content-type="template"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </label>
        <span class="conjunction">-</span>
        <label>
          <input
            class="form-block_xxs form-block__element"
            type="text"
            name="total_project_cost_to"
            placeholder="To"
            [(ngModel)]="filter.total_project_cost_to"
            #_costTo="ngModel"
            (ngModelChange)="onFiltersChanges('total_project_cost_to')"
            [ngClass]="{ 'form-block_error': hasError(_costTo) }"
            [customMin]="validateMin('total_project_cost_from')"
            [customMax]="999999999.99"
            [pattern]="regex.regexIntegerNumber"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError(_costTo)"
            [tooltip]="costToError"
            content-type="template"
          >
            <app-svg iconClass="icon_s" [href]="'info-circle'"></app-svg>
          </button>
        </label>
      </div>
      <ng-template #costFromError>
        <p *ngIf="_costFrom.errors.pattern">
          {{ "validation.number" | translate }}
        </p>
        <p *ngIf="_costFrom.errors.customMin && !_costFrom.errors.pattern">
          {{
            "validation.costMin" | translate: { name: "Project Cost", "0": "0" }
          }}
        </p>
        <p *ngIf="_costFrom.errors.customMax && !_costFrom.errors.pattern">
          {{
            "validation.costMax"
              | translate
                : {
                    name: "Project Cost From",
                    number: validateMax("total_project_cost_to").toString()
                  }
          }}
        </p>
      </ng-template>
      <ng-template #costToError>
        <p *ngIf="_costTo.errors.pattern">
          {{ "validation.number" | translate }}
        </p>
        <p *ngIf="_costTo.errors.customMin && !_costTo.errors.pattern">
          {{
            "validation.costMin"
              | translate
                : {
                    name: "Project Cost To",
                    "0": validateMin("total_project_cost_from").toString()
                  }
          }}
        </p>
        <p *ngIf="_costTo.errors.customMax && !_costTo.errors.pattern">
          {{
            "validation.costMax"
              | translate: { name: "Project Cost To", number: "999999999.99" }
          }}
        </p>
      </ng-template>
    </li>
    <!-- end Project Cost -->
    <!--User Filter-->
    <li class="filter__dropdown" *ngIf="allowedFilters.user">
      <app-select
        [(ngModel)]="filter.user"
        (ngModelChange)="onFiltersChanges('user')"
        name="userName"
        placeholder="Engineer Name"
        [values]="filterDropdowns.user"
      >
      </app-select>
    </li>
  </ul>

  <div class="toolbar__panel flex">
    <button
      id="filter_reset_btn_id"
      type="button"
      *ngIf="!hideResetButton"
      [disabled]="disableResetButton || !helper.isObjExist(filter)"
      class="btn action-btn action-btn_default"
      (click)="resetFilters()"
    >
      <span class="action-btn__icon"><app-svg [href]="'undo'"></app-svg></span>
      <span class="action-btn__title">{{ resetButtonText }}</span>
    </button>
    <button
      id="filter_apply_btn_id"
      type="button"
      *ngIf="!hideUnlockButton"
      class="btn action-btn action-btn_default"
      (click)="unlockFilters()"
    >
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">Unlock filters</span>
    </button>
    <button
      type="submit"
      *ngIf="!hideApplyButton"
      class="btn action-btn action-btn_success"
      [disabled]="disableFilters || disableApplyButton || !tdForm?.dirty"
    >
      <span class="action-btn__icon"><app-svg [href]="'check'"></app-svg></span>
      <span class="action-btn__title">{{ applyButtonText }}</span>
    </button>
  </div>
</form>
