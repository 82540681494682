import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserCurrentService } from '../../core/services/user-current.service';
import { Observable } from 'rxjs';
import { RoutesConstants } from '../../core/constants/routes.constants';
import { Settings } from 'luxon';

@Injectable()
export class ProfileGuard {
  constructor(
    private router: Router,
    private _userCurrent: UserCurrentService
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const userID = next.params['user_id'],
      isEdit = next.url.toString().includes('/edit');
    if (userID) {
      return new Observable<boolean>((observer) => {
        this._userCurrent.get().subscribe(
          (user) => {
            Settings.defaultZone =
              user.organization?.time_zone || user.time_zone;
            const isProfile = user.id === userID;
            if (isProfile) {
              this.router.navigate([
                isEdit
                  ? RoutesConstants.PROFILE.EDIT
                  : RoutesConstants.PROFILE.DETAILS,
              ]);
            }
            observer.next(!isProfile);
            observer.complete();
          },
          () => {
            observer.next(false);
            observer.complete();
          }
        );
      });
    }
    return true;
  }
}
