import { WorkOrderModel } from './work-order.model';
import { DefaultModel } from './default.model';
import { DefaultDTO } from '../dto/default.dto';

export class PmpeModel extends DefaultModel<DefaultDTO> {
  building_id: number;
  location_id: number;
  room_id: number;
  floor_id: number;
  id: number;
  number: string;
  title: string;
  zone_id: number;
  locations?: Array<string>;
  orders?: Array<WorkOrderModel>;
  status?: number;
  type?: string;
  isOrdersOpened?: boolean;

  constructor(data?) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  public get entity_name(): string {
    return 'pmpe';
  }

  public toString(): string {
    return this.number;
  }

  public dto(): DefaultDTO {
    return null;
  }
}
