import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { Injectable, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ErrorService } from '../../core/services/error.service';
import { REGEX } from '../../core/constants/regex.constants';
import { ErrorModel } from '../../core/models/error.model';
import { CustomNotificationService } from '../../core/services/custom-notification.service';
import { EventService } from '../../core/services/event.service';
import { HelperService } from '../../core/services/helper.service';

@Injectable()
export class BaseReactiveForm<M> {
  // BaseModal should extends BaseReactiveForm, but all @Input() must be moved to BaseModal
  @Input() public header: string = `Are you sure?`;
  @Input() messagesArr: string[];
  @Input() submitText: string = 'Submit';
  @Input() submitIcon: string = 'check';
  @Input() submitClass: string = 'success';
  @Input() cancelText: string = 'Cancel';
  @Input() cancelIcon: string = 'times';
  @Input() cancelClass: string = 'default';
  @Input() hideCancel: boolean = false;
  @Input() resetText: string = 'Reset';
  @Input() resetClass: string = 'default';
  @Input() hideReset: boolean = true;
  @Input() hideSubmit: boolean = false;

  @Input() set errors(errors) {
    if (errors && this.form) {
      this.setErrorToControl(errors);
    } else {
      setTimeout(() => (this.errors = errors), 1000);
    }
  }

  public form!: FormGroup;
  public apiErrors: any = {};
  public isLoading: boolean;
  public hasSubmitted: boolean;

  protected _model: M;

  @Input() set model(val: M) {
    this._model = val;
    this.setupForm();
  }
  @Input() public onResult: Subject<any> = new Subject<any>();
  @Input() public onClose: Subject<any> = new Subject<any>();

  get model(): M {
    return this._model || ({} as M);
  }

  get regex() {
    return REGEX;
  }

  public get formData() {
    return this.form.getRawValue();
  }

  get isFormValid() {
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService
  ) {}

  protected setupForm() {}

  public saveAction(_?) {}

  getControl(controlName: string | number, nested?: string, index: number = 0) {
    let control;
    if (nested) {
      control = (this.form?.controls?.[nested] as FormArray).controls[index];
      if (typeof controlName !== 'number') {
        control = control.controls[controlName];
      }
    } else {
      control = this.form.get(String(controlName));
    }
    return control || {};
  }

  hasError(
    controlName: string | number,
    nested?: string,
    index: number = 0
  ): boolean {
    const control = this.getControl(controlName, nested, index);
    return !!control.errors && control.touched;
  }

  getErrorText(
    controlName: string,
    alias?: string,
    nested?: string,
    index: number = 0
  ): string {
    const { errors } = this.getControl(controlName, nested, index);
    if (errors) {
      controlName =
        errors.customPattern !== undefined
          ? errors.customPattern.actualValue
          : controlName;
      return this._errorService.getErrorText(controlName, errors, alias);
    }
    return 'Some errors are presented';
  }

  setValue($event, control: string | number, markAsUntouched?) {
    this.form.patchValue({ [control]: $event });
    if (markAsUntouched) {
      this.form.controls[control].markAsUntouched();
    }
  }

  close(): void {
    this.onClose.next('cancel');
    this.activeModal.hide();
  }

  result(res?): void {
    this.onResult.next(res);
    this.activeModal.hide();
  }

  resetForm(initialValue?: any) {
    this.form.reset(initialValue);
    this.form.markAsDirty();
  }

  getFormArray(field: string): AbstractControl[] {
    return (this.form?.controls[field] as FormArray).controls || [];
  }

  updateValueFromController(field: string) {
    this.form.controls[field].updateValueAndValidity();
  }

  setErrorToControl(
    _errors,
    orgId?: string,
    replacedKeys: { [key: string]: string } = {}
  ) {
    const errors: ErrorModel[] =
      _errors.error?.error || _errors.error?.errors || _errors.error || _errors;

    if (Array.isArray(errors)) {
      const apiErrors: ErrorModel[] = [];

      errors.forEach((err) => {
        let key = replacedKeys[err.key] || err.key;

        if (HelperService.isExist(err.index)) {
          key = key + '.' + err.index;
        }

        if (!this.form.get(key)) {
          apiErrors.push(err);
          return;
        }

        this.form.get(key).setErrors({ api: err.message });
        this.form.get(key).markAsTouched();
      });

      if (apiErrors.length) {
        this.onUnhandledError(apiErrors, orgId);
      }
    } else if (typeof errors === 'object') {
      this.setErrorToControl(
        Object.keys(errors).map((err) => ({
          key: err,
          message: errors[err][0] || errors[err],
        })),
        orgId
      );
    }
  }

  protected onUnhandledError(error, orgId) {
    this._notificationService.apiError(error, orgId);
  }
}
