export class Configs {
  public static PROJECT_STATUSES: string[] = [
    'conceptual',
    'committed',
    'completed',
  ];

  public static PROJECT = {
    CATEGORY_ERROR_CODE_ON_DUPLICATE: 'E30002',
    CATEGORY_ERROR_CODE_ON_DUPLICATE_CHANGE_COMPULSORY_FIELD: 'E30006',
    TAG_ERROR_CODE_ON_DUPLICATE: 'E22001',
  };

  public static MODAL_CLASS = 'modal-holder';

  public static LOGGING_TYPES = [
    'Assessment',
    'Asset',
    'Building',
    'Floor',
    'Location',
    'Maintenance',
    'Organization',
    'Repair',
    'Room',
    'User',
    'ReportTemplate',
    'ReportTemplatesRepository',
  ];

  public static EVENTS = {
    LOGIN: 'user:login',
    LOGOUT: 'user:logout',
    NOT_AUTHORIZED: 'a:401',
    ORGANIZATIONS_CHANGED: 'organizations:changed',
    ORGANIZATION_CHANGED: 'organization:changed',
    ORGANIZATION_CREATED: 'organization:created',
    ORGANIZATION_LOGO_CHANGED: 'organizationLogo:changed',
    MINIFY_MENU_TOGGLED: 'minify-menu-toggled',
    MENU_HIDE: 'menu:hide',
    MENU_SHOW: 'menu:show',
    ASSET_COMPLETED: 'assets:completed',
    ASSET_CHANGED: 'assets:changed',
    ASSET_IMPORTED: 'assets:imported',
    ASSET_CREATED: 'assets:created',
    ASSET_CUSTOM: 'assets:custom',
    ASSET_ARCHIVED: 'assets:archived',
    ASSET_RESTORED: 'assets:restored',
    ASSET_FILTERED: 'assets:filtered',
    RESET_FILTERS: 'filters:reset',
    PROJECT_ASSET_FILTERED: 'project_assets:filtered',
    SERVICE_LOG_CHANGED: 'service_log:changed',
    GALLERY_OPEN: 'openGallery',
    BUDGET_CHANGED: 'budget:changed',
    FORECAST_DISTRIBUTION: 'forecast:distribution',
    FORECAST_CHANGED: 'forecast:changed',
    FORECAST_RESET: 'forecast:reset',
    FORECAST_FILTERS_UPDATED: 'forecast:filters',
    FORECAST_ASSETS_UPDATED: 'forecast:assets_updated',
    FORM_ERRORS: 'submit:serverFormErrors',
    FACILITIES_EXPAND: 'facilities:expand',
    FACILITIES_COLLAPSE: 'facilities:collapse',
    FACILIIES_UPDATE_ACTION: 'facilities:updateaction',
    FACILITIES_RELOAD: 'facilities:reload',
    LOCATIONS_EMPTY: 'locations:empty',
    USER_CREATED: 'user:created',
    ATTACHMENT_UPDATED: 'attachment:updated',
    CHARTS_DOWNLOAD: 'charts:download',
    FILTERS_UPDATING: 'filters:updating',
    NAVMENU_OPENED: 'navmenu:opened',
    BUILDING_DOCUMENT: 'buildingDocument:updated',
    BUILDING_LINK: 'buildingLink:updated',
    BUILDING_USERS: 'buildingUsers:updated',
    PRESET_DELETE: 'preset:delete',
    FACILITY_ATTRIBUTE_RESET: 'facility_attribute:reset',
    INDICES_MODE_CHANGED: 'indices_mode:changed',
    FORECAST_CHART_DOWNLOAD: 'forecast_chart:download',
  };

  public static GRAPH_TYPES = {
    dashboard: 'dashboard',
    asset: 'asset',
  };

  public static TOKENS = {
    BEARER: 'b',
    SSO: 's',
    REFRESH: 'r',
  };

  public static IMAGE_RESIZE_RESOURCE = {
    asset: 'asset',
    organization: 'organization',
    user: 'user',
  };

  public static FACILITIES = ['location', 'building', 'floor', 'room', 'zone'];

  public static FACILITIES_TREE = {
    locations: 'buildings',
    buildings: 'floors',
    floors: 'rooms',
  };

  public static FACILITIES_TREE_REVERSE = {
    building: 'location',
    floor: 'building',
    room: 'floor',
  };

  public static FACILITIES_TREE_SINGLE = {
    location: 'building',
    building: 'floor',
    floor: 'room',
  };

  public static SERVICE_NAMES = {
    maintenances: 'Maintenance',
    assessments: 'Assessment',
    repairs: 'Repair',
    capital_improvements: 'CapitalImprovement',
  };

  static SERVICE_FULL_NAMES = {
    Maintenance: 'Maintenance',
    Assessment: 'Assessment',
    Repair: 'Repair',
    CapitalImprovement: 'Capital Improvement Investment',
  };

  public static LOG_CONFIGS = {
    asset: {
      key: 'detail',
      type: 'Asset',
      roles: ['tenant_admin'],
      name: 'Asset Logs',
    },
    clientRepository: {
      type: 'AssetsRepository::Client::AssetType',
      name: 'Asset Type Logs',
    },
    template: {
      key: 'template',
      type: 'ReportTemplate',
      roles: ['tenant_admin', 'tenant_user'],
      name: 'Template Logs',
    },
    template_repository: {
      key: 'template_repository',
      type: 'ReportTemplatesRepository',
      roles: ['tenant_admin', 'tenant_user'],
      name: 'Default Template Logs',
    },
    universalRepository: {
      type: 'AssetsRepository::Universal::AssetType',
      name: 'Asset Type Logs',
    },
  };

  public static FHI_NAMES = {
    fhim: 'Maintenance',
    fhir: 'Repair',
    fhie: 'Assessments',
  };

  public static FORECAST_STATUS = {
    // just created
    UNINITIALIZED: 10,
    // initialized
    FORECASTING_SUCCESS: 20,
    // distributed
    DISTRIBUTION_SUCCESS: 30,
    DISTRIBUTION_FAIL: 35,
  };

  public static IMPORT_HISTORY_STATUSES = {
    10: 'STATUS_PENDING',
    20: 'STATUS_IN_PROGRESS',
    30: 'STATUS_SUCCESS',
  };

  public static IMPORT_HISTORY_ERROR_CODES = {
    0: 'ERROR_CODE_SUCCESS',
    10: 'ERROR_CODE_WARNING',
    20: 'ERROR_CODE_FAIL',
  };

  public static IMPORT_HISTORY_ENTITY_STATUSES = {
    1: 'OPERATION_CREATE',
    2: 'OPERATION_UPDATE',
    10: 'STATUS_IN_PROGRESS',
    20: 'STATUS_FINISHED',
    30: 'STATUS_FAIL',
  };

  public static REPORT_TYPES = [
    // { type: 'system_summary', title: 'System Summary' },
    // { type: 'financial_reporting', title: 'Financial Reporting' },
    // { type: 'budgeting_forecasting', title: 'Budgeting & Forecasting' },
    // { type: 'safety', title: 'Safety & AEM' },
    { type: 'asset_summary', title: 'Asset Summary Data' },
    { type: 'risk-ranking', title: 'Risk Ranking' },
  ];

  public static FREQUENCY_BREAKDOWNS = {
    daily: '× FHI_PM / 365',
    weekly: '× FHI_PM / 52',
    monthly: '× FHI_PM / 12',
    quarterly: '× FHI_PM / 4',
    semi_annual: '× FHI_PM / 2',
    annual: '× FHI_PM',
  };

  public static FILEIMPORT_CRED_STATUS = {
    STATUS_PENDING: 0,
    STATUS_SUCCESS: 1,
    STATUS_FAIl: 2,
  };

  public static indicesModes = [
    {
      key: 'da',
      name: 'Adjusted',
      value: 'adjusted',
    },
    {
      key: 'ds',
      name: 'Straightline',
      value: 'straightline',
    },
  ];

  public static imageDimensions = {
    thumbnail: 300,
    medium: 800,
    large: 1200,
    xlarge: 1620,
  };

  public static FUNDING_SOURCES_LIST = [
    { id: 1, name: 'Capital Project' },
    { id: 2, name: 'CapEx' },
    { id: 3, name: 'OpEx' },
    { id: 4, name: 'Age in Place' },
  ];

  public static FILE_EXPIRATION_DAYS = [
    { name: 'Never' },
    { id: 7, name: 'Older than 1 week' },
    { id: 30, name: 'Older than 1 month' },
    { id: 183, name: 'Older than 6 months' },
    { id: 365, name: 'Older than 1 year' },
  ];

  public static STEP_MESSAGE = {
    init: 'Step 1 of 4',
    loading_snapshot: 'Step 1 of 4',
    preparing: 'Step 2 of 4',
    distribute: 'Step 2 of 4',
    distribute_optimal: 'Step 2 of 4',
    saving_snapshot: 'Step 3 of 4',
    calculating_indexes: 'Step 3 of 4',
    done: 'Step 4 of 4',
  };
}
