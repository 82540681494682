export const ErrorMessagesConstants = {
  SET_ASSET_AS_PARENT:
    'Error: Set asset as parent first then create hierarchy.',
  MAX_LEVELS_REACHED:
    'Error: You can create up to 5 levels only , the maximum limit has been reached.',
  MAX_CHILDREN_REACHED:
    'Error: You can add only 10 children under a parent asset.',
  HIERARCHY_CANNOT_BE_MOVED: 'Error: The entire hierarchy cannot be moved.',
  ALL_ROWS_CANNOT_BE_MOVED: 'Error: Please check your selection and try again.',
  INVALID_PARENT_MOVE:
    'Error: Dragging a parent asset into a child asset is not allowed.',
};
