import { OnDestroy, OnInit, Injectable } from '@angular/core';
import { CustomNotificationService } from '../../core/services/custom-notification.service';
import { BaseStoreService } from '../../core/services/base-store.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Configs } from '../../core/constants/configs.constants';
import { BreadcrumbItemModel } from '../../core/models/breadcrumb-item.model';
import { OrganizationModel } from '../../core/models/organization.model';
import { HelperService } from '../../core/services/helper.service';

@Injectable()
export class BaseDetailsComponent<M> implements OnInit, OnDestroy {
  public model: M;
  public entityId: number | string;
  public isLoading: boolean;
  protected modalRef: BsModalRef;
  detailsMode: string;
  resourceName: string;
  subscriptions: Subscription = new Subscription();
  indicesMode: string = Configs.indicesModes[0].key;
  breadcrumbs: Array<BreadcrumbItemModel> = [];
  organization: OrganizationModel;

  constructor(
    protected _notificationService: CustomNotificationService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected store: BaseStoreService<M>,
    protected entityKey: string
  ) {}

  ngOnInit() {
    this.findEntityId();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();

    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  protected findEntityId(): void {
    if (!this.entityId) {
      this.entityId = this.activeRoute.snapshot.params[this.entityKey];
    }
  }

  public loadModel(
    resource: string = this.resourceName,
    _retries?: number
  ): Observable<M> {
    this.isLoading = true;
    return new Observable<M>((observer) => {
      const currentModel = this.store.currentModel;
      if (
        currentModel &&
        currentModel.id === parseInt(this.entityId.toString())
      ) {
        // model saved in store from response
        this.model = currentModel;
        observer.next(this.model);
        observer.complete();
      } else {
        // no models in store - sending request
        this.subscriptions.add(
          this.store.getById(this.entityId, resource).subscribe({
            next: (model) => {
              this.model = model;
              this.isLoading = false;
              observer.next(this.model);
              observer.complete();
            },
            error: (error) => {
              this.isLoading = false;
              observer.error(error);
              observer.complete();
            },
          })
        );
      }
      this.store.currentModel = null;
    });
  }

  public listMode(): void {
    this.router.navigate([`../`], { relativeTo: this.activeRoute });
  }

  public editMode(): void {
    this.router.navigate([`./edit`], { relativeTo: this.activeRoute });
  }

  protected setPageInBreadcrumbs(linkArgs) {
    const newLinkArgs = linkArgs.join('/').replace(/[/][/]/g, '/');
    const breadcrumbLink = JSON.parse(
      window.sessionStorage.getItem(newLinkArgs)
    );
    if (HelperService.isExist(breadcrumbLink)) {
      return `${newLinkArgs}/pages/${breadcrumbLink.pageNum}`;
    }
    return newLinkArgs;
  }

  changeDetailsMode(mode) {
    this.detailsMode = mode;
  }
}
