<ul class="doughnut-list position-relative" id="facility_health_index_ul_id">
  <ng-container *ngFor="let index of indexes">
    <li
      class="doughnut-list__item"
      *ngIf="!fhiType || index.abbreviation === fhiType"
    >
      <h3 class="doughnut-list__title">
        <ng-container *ngIf="!showModulesLinks">{{
          index.abbreviation.toUpperCase()
        }}</ng-container>
        <a
          *ngIf="showModulesLinks"
          (click)="generateEvent(index.abbreviation?.toUpperCase())"
          [routerLink]="[
            '/',
            organization.id_hash,
            'modules',
            index.abbreviation
          ]"
          [queryParams]="{ period: index.period }"
          >{{ index.abbreviation.toUpperCase() }}
        </a>
      </h3>

      <div class="doughnut-list__chart-holder">
        <app-chart-doughnut
          [chartValues]="chartValues[index.abbreviation] || 0"
          [isDisabled]="!index.hasModule"
        ></app-chart-doughnut>
        <span
          *ngIf="
            helper.isExist(chartValues[index.abbreviation]) &&
            index.hasModule &&
            !isLoading
          "
          class="doughnut-list__amount"
        >
          {{ chartValues[index.abbreviation] | customPercent }}
        </span>
        <span
          *ngIf="
            !isLoading &&
            (!helper.isExist(chartValues[index.abbreviation]) ||
              !index.hasModule)
          "
          class="doughnut-list__amount"
        >
          N/A
        </span>
        <div class="doughnut-list__amount" *ngIf="isLoading">
          <app-loader></app-loader>
        </div>
      </div>

      <h4 class="doughnut-list__subtitle doughnut-list__subtitle_italic">
        Facility Health Index™{{ index.name ? ' ' + index.name : '' }}
      </h4>
    </li>
  </ng-container>
</ul>
