import { DefaultDTO } from '../dto/default.dto';
import { DefaultModel } from './default.model';

export interface CsvColumn {
  title: string;
  checked: boolean;
  id?: string | number;
}

export class AEModel extends DefaultModel<DefaultDTO> {
  sourceFieldName: string;
  destinationFieldName: string;
  mapping: any;
  importType: string[];
  status: [
    {
      key: string;
      value: string;
      status: string;
      deleted: true;
      errorCode: string;
    },
  ];
  message: string;
  showDetails: boolean;
  mappingCopy: any;
  keyNumbers: number;
  valueNumbers: number;
  firstKey: string;
  firstValue: string;

  constructor(model?) {
    super();
    let originName = [
      'Building',
      'Floor',
      'Location',
      'Room',
      'Work Order Status',
    ];
    if (model) {
      Object.assign(this, model);
    }

    let obj = [];
    let keyCount = -1;
    if (originName.indexOf(this.destinationFieldName) > -1) {
      this.mapping &&
        Object.keys(JSON.parse(this.mapping)).forEach((key) => {
          let value = JSON.parse(this.mapping)[key];
          if (keyCount == -1) {
            keyCount += 1;
          } else {
            keyCount += value.length;
          }
          obj.push({
            mappingKey: value,
            mappingValue: key.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase()
            ),
          });
        });
    } else {
      this.mapping &&
        Object.keys(JSON.parse(this.mapping)).forEach((key) => {
          let value = JSON.parse(this.mapping)[key].split(',');
          if (keyCount == -1) {
            keyCount += 1;
          } else {
            keyCount += value.length;
          }
          obj.push({
            mappingKey: value,
            mappingValue: key,
          });
        });
    }
    this.mapping = obj;
    this.keyNumbers = keyCount;
    this.valueNumbers =
      this.mapping.length > 1 ? this.mapping.length - 1 : null;
  }

  public get entity_name(): string {
    return 'ae';
  }

  public dto(): DefaultDTO {
    return new DefaultDTO();
  }

  public toString(): string {
    return this.destinationFieldName;
  }
}
