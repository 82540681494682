import { Component, Input, ViewChild } from '@angular/core';

import { AssetTypeExtendedModel } from '../../../core/models/asset-type-extended.model';
import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { NgForm } from '@angular/forms';

@Component({
  templateUrl: './delete-modal.component.html',
})
export class DeleteModalComponent extends BaseModalComponent {
  @ViewChild('deleteForm', { static: true }) declare tdForm: NgForm;

  @Input() public submitText: string = 'Delete';
  @Input() public keyword: string = 'delete';
  @Input() public assetType: AssetTypeExtendedModel;
  @Input() public deleteType: string = null;

  public userInput: string;
  public dependencies: Array<any> = [];

  constructor(
    public activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(activeModal, _notificationService, _eventService);
    this.isTemplateDriven = true;
  }

  public submit(): void {
    this.hasSubmitted = true;
    if (this.tdForm.invalid) {
      return;
    }
    super.submit();
  }

  public calculateQuantity(dependencies: any): number {
    let quantity: number = 0;
    dependencies.forEach((dependencie) => {
      quantity = quantity + dependencie.assets_quantity;
    });

    return quantity;
  }
}
