import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-user-link-renderer',
  template: `
    <app-user-link
      [userId]="params?.value?.id"
      [userName]="params?.value?.name"
      [isActive]="params?.value?.is_active"
      [orgId]="organizationId"
      [isHighlightText]="params.isHighlightText"
      [searchText$]="params.searchText$"
    ></app-user-link>
  `,
})
export class UserLinkRendererComponent implements ICellRendererAngularComp {
  params: any;
  organizationId: string;

  agInit(params: any): void {
    this.organizationId = params.organization?.id_hash || '';
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
