import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { ErrorsConstants } from '../constants/errors.constants';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatPipe } from '../../shared/pipes/date-format.pipe';

@Injectable()
export class ErrorService {
  errorCodes = ErrorsConstants;

  constructor(private _translateService: TranslateService) {}

  static recursiveSearch(obj = {}, results = [], row = '') {
    Object.keys(obj).forEach((key) => {
      switch (true) {
        case !key.indexOf('row'):
        case !key.indexOf('csv'):
          row = key;
          break;
      }
      const value = obj[key];
      if (typeof value !== 'object') {
        results.push({
          text:
            (key !== 'entity' &&
            typeof key === 'string' &&
            isNaN(parseFloat(key))
              ? `${key}: `
              : '') + value,
          row: row,
        });
      } else {
        if (value) {
          if (value['message']?.length) {
            results.push({ text: value.message || key, row: value.row || row });
            // if received new errors format from backend - show only Message field
          } else {
            // for old formats - flatten object further
            this.recursiveSearch(value, results, row);
          }
        }
      }
    });
    return results;
  }

  errorHandler(apiError): string[] {
    apiError = apiError.error?.errors || apiError.errors || apiError;
    const errors = Array.isArray(apiError) ? apiError : [apiError];
    const errArray = [] as string[];
    errors.forEach((error) => {
      errArray.push(
        error.code && this.errorCodes[error.code]
          ? this.errorCodes[error.code].message
          : error.message
      );
    });
    return errArray;
  }

  getErrorText(
    control: string,
    errors: ValidationErrors,
    field?: string
  ): string {
    const errStack: string[] = [];
    field ||= control
      .split(/(?=[A-Z])|_/)
      .join(' ')
      .capitalize();

    Object.keys(errors).forEach((error) => {
      if (errors.hasOwnProperty(error)) {
        
        switch (error) {
          case 'api':
            errStack.push(errors[error]);
            break;
          case 'afterdot':
            errStack.push(
              this._translateService.instant('validation.after_dot', {
                number: errors[error].value,
              })
            );
            break;
          case 'customPattern':
            switch (errors[error].requiredPattern) {
              case 'floatingPositiveNumbersNoZero':
                errStack.push(
                  `${field} value should be a number greater than 0`
                );
                break;
              default:
                errStack.push(`${field} value not satisfies field format`);
            }
            break;
          case 'email':
            errStack.push(
              this._translateService.instant('validation.bad_email')
            );
            break;
          case 'equal_to_start_time':
            errStack.push(`${field} should not be equal to Start time`);
            break;
          case 'equalTo':
            errStack.push(
              `${errors[error].source} should be equal to ${errors[error].target}`
            );
            break;
          case 'mask':
            errStack.push(
              `${field} requires ${errors[error].requiredMask} format`
            );
            break;
          case 'customMax':
          case 'max':
            errStack.push(
              this._translateService.instant(
                errors[error].max === 9.99
                  ? 'validation.max_number_1'
                  : 'validation.max_number',
                {
                  value: field,
                  number: HelperService.parseMoneyInteger(errors[error].max),
                }
              )
            );
            break;
          case 'matDatepickerMax':
            errStack.push(
              this._translateService.instant('validation.dateMax', {
                field,
                date: new DateFormatPipe().transform(errors[error].max),
              })
            );
            break;
          case 'matDatepickerMin':
            errStack.push(
              this._translateService.instant('validation.dateMin', {
                field,
                date: new DateFormatPipe().transform(errors[error].min),
              })
            );
            break;
          case 'maxlength_detail':
          case 'maxlength':
            errStack.push(
              this._translateService.instant('validation.maxlength_detail', {
                value: field,
                number: errors[error].requiredLength,
              })
            );
            break;
          case 'min':
            errStack.push(
              this._translateService.instant('validation.min_number', {
                value: field,
                number: parseFloat(errors[error].min).toFixed(2),
              })
            );
            break;
          case 'minChecked':
            errStack.push(
              `You should check at least ${errors.minChecked} option(s)`
            );
            break;
          case 'minlength':
            errStack.push(
              this._translateService.instant('validation.password_short', {
                value: field,
              })
            );
            break;
          case 'pattern':
            errStack.push(`${field} value not satisfies field format`);
            break;
          case 'required':
            errStack.push(
              this._translateService.instant('validation.required')
            );
            break;
          case 'projectDateRange':
            errStack.push(
              this._translateService.instant(
                `Start date should not be greater than end date`
              )
            );
            break;
          case 'maxProjectDateRange':
            errStack.push(
              this._translateService.instant(
                'validation.max_project_date_range'
              )
            );
            break;
          case 'invalidProjectYear':
            errStack.push(
              this._translateService.instant('validation.invalid_project_year')
            );
            break;
          case 'projectStartDateBeyondCmms':
            errStack.push(
              this._translateService.instant(
                'validation.project_start_date_beyond_cmms'
              )
            );
            break;
          default:
            errStack.push(`There is some error in ${field} field`);
        }
      }
    });
    return errStack.join('.\n');
  }
}
