import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `<app-svg
    [ngClass]="{ 'el-block': isBlock }"
    [href]="'circle-notch'"
    [height]="size"
    [width]="size"
    [fill]="'#555555'"
    class="spinning"
  ></app-svg>`,
})
export class LoaderComponent {
  @Input() public size: number = 36;
  @Input() public isBlock: boolean = false;
}
