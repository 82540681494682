import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { RiskModel } from '../models/risk.model';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { DefaultListModel } from '../models/default-list.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class RisksService extends BaseStoreService<RiskModel> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, RiskModel);
    this.resourceName = 'v2/client/risks';
  }

  public getItems(
    params?: any,
    orgId?: string | number
  ): Observable<DefaultListModel<RiskModel>> {
    const headers = orgId
      ? { [environment.tokens.org_header]: orgId }
      : undefined;
    return super.getItems(params, this.resourceName, headers);
  }

  public getAvailableRisks(
    params?: any
  ): Observable<DefaultListModel<RiskModel>> {
    return super.getItems(params, `${this.resourceName}/available`);
  }

  public getDefaultRisksDependencies(id, params?): any {
    return this._requestService.get(
      `/${this.resourceName}/${id}/dependencies`,
      params,
      this.headers
    );
  }
}
