<section class="update" *ngIf="!isLoading">
  <header class="update__header">
    <img
      alt=""
      src="./../../../../../assets/img/brightly-logo.png"
      [width]="158"
      [height]="44"
    />
  </header>
  <main class="update__main">
    <form [formGroup]="form" (ngSubmit)="submit()" class="login__form">
      <fieldset class="mrgn-b_lg">
        <h2 class="update__main-header">Update Your Profile Details</h2>
        <legend class="update__main-details">
          Kindly fill out the required details to finalize your profile.
        </legend>
        <ul class="flex">
          <li class="mrgn-b xs-47">
            <label class="form-block_field_label">First name</label>
            <div class="form-block form-block_full-width">
              <input
                type="text"
                placeholder="Enter First Name"
                formControlName="first_name"
                [ngClass]="{ 'form-block_error': hasError('first_name') }"
                class="form-block__element form-block__element_md"
              />
              <button
                type="button"
                class="btn tooltip-btn_error"
                *ngIf="hasError('first_name')"
                [tooltip]="getErrorText('first_name')"
              >
                <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
              </button>
            </div>
          </li>
          <li class="mrgn-b xs-47">
            <label class="form-block_field_label">Last name</label>
            <div class="form-block form-block_full-width">
              <input
                type="text"
                placeholder="Enter Last Name"
                formControlName="last_name"
                [ngClass]="{ 'form-block_error': hasError('last_name') }"
                class="form-block__element form-block__element_md"
              />
              <button
                type="button"
                class="btn tooltip-btn_error"
                *ngIf="hasError('last_name')"
                [tooltip]="getErrorText('last_name')"
              >
                <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
              </button>
            </div>
          </li>
          <li class="mrgn-b xs-47">
            <label class="form-block_field_label">Phone</label>
            <div class="form-block form-block_full-width">
              <input
                type="tel"
                placeholder="Enter Phone Number Here"
                formControlName="phone"
                [ngClass]="{ 'form-block_error': hasError('phone') }"
                class="form-block__element form-block__element_md"
              />
              <button
                type="button"
                class="btn tooltip-btn_error"
                *ngIf="hasError('phone')"
                [tooltip]="getErrorText('phone')"
              >
                <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
              </button>
            </div>
          </li>
          <ng-container formGroupName="address_attributes">
            <li class="mrgn-b xs-47">
              <label class="form-block_field_label">Country</label>
              <div class="form-block form-block_full-width">
                <app-select
                  formControlName="country"
                  [ngClass]="{
                    'form-block_error': hasError('address_attributes.country')
                  }"
                  heightClass="multiselect__btn_medium"
                  optKey="value"
                  placeholder="Select Country"
                  [values]="countries"
                  widthClass="multiselect_update_page"
                >
                </app-select>
                <button
                  type="button"
                  class="btn tooltip-btn_error"
                  *ngIf="hasError('address_attributes.country')"
                  [tooltip]="
                    getErrorText(
                      'address_attributes.country',
                      'country' | translate
                    )
                  "
                >
                  <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
                </button>
              </div>
            </li>
            <li class="mrgn-b xs-47">
              <label class="form-block_field_label">State</label>
              <div class="form-block form-block_full-width">
                <app-select
                  formControlName="state"
                  [ngClass]="{
                    'form-block_error': hasError('address_attributes.state')
                  }"
                  [required]="form.value.address_attributes.country === 'US'"
                  [disabled]="form.value.address_attributes.country !== 'US'"
                  heightClass="multiselect__btn_medium"
                  optKey="key"
                  placeholder="Select State"
                  [values]="states"
                  widthClass="multiselect_update_page"
                >
                </app-select>
                <button
                  type="button"
                  class="btn tooltip-btn_error"
                  *ngIf="hasError('address_attributes.state')"
                  [tooltip]="
                    getErrorText(
                      'address_attributes.state',
                      'state' | translate
                    )
                  "
                >
                  <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
                </button>
              </div>
            </li>
            <li class="mrgn-b xs-47">
              <label class="form-block_field_label">City</label>
              <div class="form-block form-block_full-width">
                <input
                  type="text"
                  placeholder="Enter City Here"
                  formControlName="city"
                  [ngClass]="{
                    'form-block_error': hasError('address_attributes.city')
                  }"
                  class="form-block__element form-block__element_md"
                />
                <button
                  type="button"
                  class="btn tooltip-btn_error"
                  *ngIf="hasError('address_attributes.city')"
                  [tooltip]="
                    getErrorText(
                      'address_attributes.city',
                      'city' | translate
                    )
                  "
                >
                  <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
                </button>
              </div>
            </li>
            <li class="mrgn-b xs-47">
              <label class="form-block_field_label">{{
                'address' | translate
              }}</label>
              <div class="form-block form-block_full-width">
                <input
                  type="text"
                  placeholder="Enter Address Here"
                  formControlName="address1"
                  [ngClass]="{
                    'form-block_error': hasError('address_attributes.address1')
                  }"
                  class="form-block__element form-block__element_md"
                />
                <button
                  type="button"
                  class="btn tooltip-btn_error"
                  *ngIf="hasError('address_attributes.address1')"
                  [tooltip]="
                    getErrorText(
                      'address_attributes.address1',
                      'address' | translate
                    )
                  "
                >
                  <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
                </button>
              </div>
            </li>
            <li class="mrgn-b xs-47">
              <label class="form-block_field_label">Zip/Postal Code</label>
              <div class="form-block form-block_full-width">
                <input
                  type="text"
                  placeholder="Enter Zip/Postal Code Here"
                  formControlName="zipcode"
                  [ngClass]="{
                    'form-block_error': hasError('address_attributes.zipcode')
                  }"
                  class="form-block__element form-block__element_md"
                />
                <button
                  type="button"
                  class="btn tooltip-btn_error"
                  *ngIf="hasError('address_attributes.zipcode')"
                  [tooltip]="
                    getErrorText(
                      'address_attributes.zipcode',
                      'zip' | translate
                    )
                  "
                >
                  <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
                </button>
              </div>
            </li>
          </ng-container>
        </ul>
      </fieldset>

      <button class="btn action-btn action-btn_xl update_button">Submit</button>
    </form>
  </main>
</section>
