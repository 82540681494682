import { BaseStoreService } from './base-store.service';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { UploadResourceService } from './upload-resource.service';
import { combineLatest, filter, mergeMap, Observable, of, tap } from 'rxjs';
import { OrganizationModel } from '../models/organization.model';
import { DefaultListModel } from '../models/default-list.model';
import { EventService } from './event.service';
import { Configs } from '../constants/configs.constants';
import { CustomNotificationService } from './custom-notification.service';

@Injectable()
export class OrganizationStoreService extends BaseStoreService<OrganizationModel> {
  constructor(
    protected _requestService: RequestService,
    private _uploadResourceService: UploadResourceService,
    private _eventService: EventService,
    private _notificationService: CustomNotificationService
  ) {
    super(_requestService, OrganizationModel);
    this.resourceName = 'v1/organizations';
  }

  getItems(params): Observable<DefaultListModel<OrganizationModel>> {
    return super.getItems(params, `v1/webclient/organizations`);
  }

  getCMMSList() {
    return this._requestService.get(`/${this.resourceName}/ae_cmms_client`);
  }

  /**
   * Check if org. logo data present in local storage
   * @param org
   */
  public checkOrgCachedAvatar(org) {
    if (localStorage.getItem(org?.email)) {
      this.uploadOrgLogo(
        JSON.parse(localStorage.getItem(org.email)),
        org.id_hash
      );
      localStorage.removeItem(org.email);
    }
  }

  /**
   * Transforms base64 string to file and upload logo file to server
   * @param url - object with file data {name, url}
   * @param id_hash
   */
  public uploadOrgLogo(url, id_hash): void {
    const file = this._uploadResourceService.dataURLtoFile(url.url, url.name);
    this._uploadResourceService
      .uploadFile(file, `images/logo`, id_hash)
      .pipe(
        filter((res) => res.complete),
        mergeMap((res) => combineLatest([of(res), this.getById(id_hash)])),
        tap((data) => {
          data[1].picture = data[0].fields.key;
        }),
        mergeMap((data) => this.update(data[1]))
      )
      .subscribe({
        next: (org) => {
          localStorage.setItem(org.id_hash, org.picture);
          this._eventService.broadcast(
            Configs.EVENTS.ORGANIZATION_LOGO_CHANGED
          );
        },
        error: (err) => this._notificationService.apiError(err),
      });
  }

  public recalculate(id): Observable<any> {
    return this._requestService.put(`/v1/organizations/${id}/recalculate`);
  }

  public all(): Observable<DefaultListModel<OrganizationModel>> {
    return super.getItems(undefined, `/v1/webclient/organizations/short`);
  }
}
