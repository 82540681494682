<section class="modal-content__wrapper" [hidden]="status !== 'static'">
  <header class="modal-content__header">Export</header>

  <form
    [formGroup]="form"
    (ngSubmit)="saveAction()"
    class="modal-content__body"
  >
    <section class="modal-content__item modal-content__item_full-width">
      <p
        class="mrgn-b"
        [ngClass]="{
          'error error_inline error_inherit': !form.valid && form.touched
        }"
      >
        Please, select export type:
      </p>
      <ul>
        <li *ngFor="let option of options" class="mrgn-b_sm">
          <label class="custom-radio flex">
            {{ option.name }}
            <input
              class="custom-radio__input"
              type="radio"
              [formControlName]="option.fieldApiName || 'exportType'"
              [value]="option.type"
            />
            <span class="custom-radio__check"></span>
          </label>

          <ul
            *ngIf="option.group"
            [formGroupName]="option.groupName"
            class="mrgn-l"
          >
            <li *ngFor="let groupOption of option.group" class="mrgn-t_sm">
              <label class="custom-radio flex">
                <span
                  class="custom-radio__check_labeled"
                  [ngClass]="{
                    disabled: form.value.exportType !== option.type
                  }"
                >
                  {{ groupOption.name }}
                </span>
                <input
                  class="custom-radio__input"
                  type="radio"
                  [formControlName]="groupOption.fieldApiName"
                  [value]="groupOption.type"
                  [required]="form.value.exportType === option.type"
                  [attr.disabled]="
                    form.value.exportType === option.type ? null : true
                  "
                />
                <span class="custom-radio__check"></span>
              </label>
            </li>
          </ul>
        </li>
      </ul>
    </section>
  </form>
  <div
    class="buttons-block modal-content__footer"
    [ngClass]="{ 'buttons-block_to-center': status !== 'static' }"
  >
    <button
      type="button"
      (click)="close()"
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">{{ 'buttons.cancel' | translate }}</span>
    </button>
    <button class="btn action-btn action-btn_success" (click)="saveAction()">
      <span class="action-btn__icon"><app-svg [href]="'check'"></app-svg></span>
      <span class="action-btn__title">Export</span>
    </button>
  </div>
</section>

<app-export-modal
  *ngIf="status === 'progress'"
  [type]="type"
  [orgId]="orgId"
  [exportAddOns]="params"
  [successMessage]="successMessage"
>
</app-export-modal>
