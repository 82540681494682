<ul class="statistic" *ngIf="statistics?.length">
  <li class="statistic__item" *ngFor="let item of statistics">
    <p class="statistic__value" *ngIf="!item.doughnut; else elseBlock">
      {{ formatToCharsLimit(item.value, 5) }}
    </p>
    <ng-template #elseBlock>
      <div class="doughnut-list__item">
        <div class="doughnut-list__chart-holder">
          <app-chart-doughnut
            [FCI]="
              item.doughnut.value &&
              (item.title === 'Average FCI' || item.title === 'FCI')
            "
            [chartValues]="item.doughnut.value"
          ></app-chart-doughnut>
          <span class="doughnut-list__amount" [hidden]="!item.doughnut.value">{{
            item.doughnut.value | customPercent: false | text: "N/A"
          }}</span>
          <span [hidden]="item.doughnut.value" class="doughnut-list__amount"
            >N/A</span
          >
          <span
            *ngIf="item.title === 'Average FCI' || item.title === 'FCI'"
            class="doughnut-list__amount statistic_margin_top_fci"
            [hidden]="!item.doughnut.value"
            >{{ getFCIStatus(item.doughnut.value) }}</span
          >
        </div>
      </div>
    </ng-template>

    <h3 class="text-center">
      {{ item.title }}
    </h3>
  </li>
</ul>
