import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class TableLoaderComponent {
  @Input() public isLoading: boolean;
  @Input() public items: Array<any>;
  @Input() public colspan: number;
  @Input() public message: string = 'No items';
}
