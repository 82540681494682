<section class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header">
      {{
        isDublicate ? (isDefault ? 'Copy' : 'Duplicate') : 'Default'
      }}
      template
    </h2>
  </header>

  <div class="modal-content__body modal-content__body_without-form">
    <form
      #templateForm="ngForm"
      name="DublicateForm"
      novalidate
      [hidden]="!isDublicate"
      (ngSubmit)="submit()"
      (keydown.enter)="$event.preventDefault()"
    >
      <p class="modal-content__message mrgn-b">
        Please, choose new title for template:
      </p>
      <label class="modal-content__label" for="title">Title</label>
      <input
        [(ngModel)]="model.title"
        (ngModelChange)="checkTitle(_title)"
        #_title="ngModel"
        [customMaxlength]="80"
        [ngClass]="{ 'form-block_error': hasError(_title) }"
        class="form-block__element"
        required
        name="title"
        id="title"
        type="text"
      />
      <p class="error" *ngIf="hasError(_title)">
        <ng-container *ngIf="_title.errors.required">
          {{ 'validation.required' | translate }}
        </ng-container>
        <ng-container *ngIf="_title.errors.maxlength">
          {{
            'validation.maxlength_detail'
              | translate: { number: '80', value: 'Title' }
          }}
        </ng-container>
        <ng-container *ngIf="_title.errors.title">
          You cannot use original title
        </ng-container>
        <ng-container *ngIf="_title.errors.unique">
          {{
            'validation.unique_field'
              | translate: { type_name: 'Template', field_name: 'title' }
          }}
        </ng-container>
      </p>
    </form>

    <ng-container *ngIf="!isDublicate">
      <p class="modal-content__message" *ngFor="let template of data">
        Asset type {{ template.name }} already has a default template.
      </p>
      <p class="modal-content__message">Do you want to override?</p>
    </ng-container>
  </div>

  <div class="buttons-block modal-content__footer">
    <button (click)="cancel()" class="btn action-btn action-btn_default">
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">{{ 'buttons.cancel' | translate }}</span>
    </button>

    <button class="btn action-btn action-btn_success" (click)="submit()">
      <span class="action-btn__icon"><app-svg [href]="'check'"></app-svg></span>
      <span class="action-btn__title">{{
        isDublicate ? (isDefault ? 'Copy' : 'Duplicate') : 'Proceed'
      }}</span>
    </button>
  </div>
</section>
