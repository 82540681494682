import './shared/prototypes';

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ChatService } from './core/services/websokets/chat.service';
import { CoreModule } from './core/core.module';
import { FeatureFlagsService } from './core/services/feature-flags.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { HttpClient } from '@angular/common/http';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { NgxsModule } from '@ngxs/store';
import { ProgressBarServiceService } from './core/services/progress-bar-service.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';
import { SnotifyModule } from 'ng-alt-snotify';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { states } from './store';

/**
 *
 * @param http
 */
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const featureFactory = (featureFlagsService: FeatureFlagsService) => () =>
  featureFlagsService.loadConfig();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    NgxJsonViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    SnotifyModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxsModule.forRoot(states, { developmentMode: !environment.production }),
  ],
  providers: [
    GoogleAnalyticsService,
    {
      provide: APP_INITIALIZER,
      useFactory: featureFactory,
      deps: [FeatureFlagsService],
      multi: true,
    },
    ChatService,
    ProgressBarServiceService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
