@if (isTreeDataEnabledOnBudgeting) {
  <label class="custom-toggle" *ngIf="params.calculationStep >= 4">
    <input
      id="toggleIncludeInBugdet"
      type="checkbox"
      class="custom-toggle__input custom-toggle__input_lg"
      (ngModelChange)="onCheckboxChange($event)"
      [ngModel]="status"
      [disabled]="params.isLockedBudget"
    />
    <span class="custom-toggle__check custom-toggle__check_lg">
      <span class="custom-toggle__toggler custom-toggle__toggler_lg"></span>
    </span>
  </label>
} @else {
  <label *ngIf="params.calculationStep >= 4" class="custom-checkbox">
    <input
      id="valueMaintenanceStatusId"
      class="custom-checkbox__input"
      type="checkbox"
      [ngModel]="status"
      (ngModelChange)="onCheckboxChange($event)"
      [name]="params.data.id"
      [disabled]="params.isLockedBudget"
    />
    <span class="custom-checkbox__check"></span>
  </label>
  <span *ngIf="params.calculationStep <= 3" class="status-mark">
    <app-svg [href]="'check'"></app-svg>
  </span>
}
