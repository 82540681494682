import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgForm,
} from '@angular/forms';

import { BaseFormComponent } from '../base-form.component';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { ErrorModel } from '../../../core/models/error.model';
import { EventService } from '../../../core/services/event.service';
import { HelperService } from 'src/app/core/services/helper.service';

@Component({
  selector: 'app-text-form-edit',
  templateUrl: './text-form-edit.component.html',
  styleUrls: ['./text-form-edit.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFormEditComponent),
      multi: true,
    },
  ],
})
export class TextFormEditComponent
  extends BaseFormComponent
  implements OnInit, ControlValueAccessor
{
  @ViewChild('textEditForm', { static: false }) declare tdForm: NgForm;
  @Input() public oldValue: any;
  @Input() public notEmpty: boolean = true;
  @Input() public validationPattern?: string;
  @Input() public inputName: string = 'name';
  @Input() public editing?: boolean = false;
  @Input() public onlyEdit: boolean = false;
  @Input() public maxLength?: number = 250;
  @Input() public set error(val: ErrorModel[]) {
    if (val) {
      this._error = val;
      this.editing = true;
      this.parseErrors(val, this.inputName);
    }
  }
  @Input() set value(v: string) {
    if (this.helper.isExist(v)) {
      this._value = v;
      this.onChange(v);
      this.onTouch(v);
    }
  }
  @Output() updateValue = new EventEmitter();
  @Output() internalError = new EventEmitter();

  public onChange: any = () => {};
  public onTouch: any = () => {};
  public valueChanged = false;

  private _error: ErrorModel[];
  private _value;

  public get value() {
    return this._value;
  }

  get error() {
    return this._error;
  }

  public get helper() {
    return HelperService;
  }

  constructor(
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(_notificationService, _eventService);
  }

  ngOnInit() {
    this.isTemplateDriven = true;
    this.valueChanged = false;
    this.setupValues();
  }

  public emitSaveValue() {
    this.markFormGroupTouched();
    this.internalError.emit(this.tdForm.controls[this.inputName].errors || {});
    if (this.tdForm.invalid) {
      return;
    } else {
      this.valueChanged = true;
      this.updateValue.emit(this.value);
    }
    this.editing = false;
  }

  emitSaveValueIfOnlyEdit() {
    if (this.onlyEdit) {
      this.emitSaveValue();
    }
  }

  public setupValues() {
    this.value = this.oldValue;
  }

  public stopEditingIfEsc() {
    this.value = this.oldValue || '';
    this.editing = false;
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  public writeValue(value: any) {
    this.value = value;
  }
}
