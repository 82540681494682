import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PAGE_SIZES } from '../../../core/constants/collections.constants';

@Component({
  selector: 'app-per-page-selector',
  templateUrl: './per-page-selector.component.html',
})
export class PerPageSelectorComponent implements OnInit {
  @Input() public disabled: boolean;
  @Input() public perPage: number;
  @Output() public onUpdate = new EventEmitter<{
    location?: string;
    key: string;
    value: any;
  }>();
  public pageSizes: Array<any>;

  ngOnInit(): void {
    this.pageSizes = PAGE_SIZES;
  }

  public updatePerPage(newValue): void {
    this.onUpdate.emit({ key: 'perPage', value: newValue });
  }
}
