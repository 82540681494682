<section
  class="modal-content__wrapper"
  [ngClass]="{
    'modal-content__500': status === 'warning' || status === 'error'
  }"
>
  <header class="modal-content__header">{{ header }}</header>
  <div class="modal-content__body modal-content__body_without-form">
    <div *ngIf="status === 'loading'" class="text-center">
      <app-loader></app-loader>
    </div>

    <p
      *ngIf="
        jobModel?.message && !_bgJobService.isFinishedWithAnyResult(jobModel)
      "
      class="text-center mrgn-b_sm"
    >
      {{ jobModel?.message }}
    </p>

    <progressbar
      *ngIf="status === 'progress'"
      [max]="100"
      [value]="jobModel?.progress"
      type="success"
    >
      <span class="progress-bar__title">{{
        jobModel?.progress | customPercent
      }}</span>
    </progressbar>

    <ng-container
      *ngIf="
        action === 'export' && (status === 'success' || status === 'warning')
      "
    >
      <p class="mrgn-b">
        {{ successMessage || exportName + ' is ' + jobModel?.status }}
      </p>
      <p *ngIf="!exportFilesArr.length">No data to export</p>
      <p *ngIf="linksAreExpired">
        <ng-container *ngIf="exportFilesArr.length === 1"
          >The link has expired</ng-container
        >
        <ng-container *ngIf="exportFilesArr.length > 1"
          >The links have expired</ng-container
        >
      </p>
      <ul *ngIf="!linksAreExpired">
        <li *ngFor="let link of exportFilesArr; let _i = index">
          <a class="link text-cropped" [href]="link.url"
            >{{ _i + 1 }}. {{ link.filename }}</a
          >
        </li>
      </ul>
    </ng-container>
    <app-issue-list
      *ngIf="status === 'warning' || status === 'error'"
      [header]="'Error'"
      [jobUuid]="jobModel?.job_uuid"
      [orgId]="orgId"
      [issues]="jobModel?.issues"
      [message]="errorListMsg"
      [tableView]="true"
    >
    </app-issue-list>

    <ng-container *ngIf="status === 'systemError'">
      <p *ngFor="let msg of messagesArr" class="modal-content__message">
        {{ msg }}
      </p>
    </ng-container>
  </div>
  <div
    class="buttons-block modal-content__footer"
    [ngClass]="{
      'buttons-block_to-center':
        !jobModel?.output_issues_list_url &&
        !jobModel?.output_failed_objects_url
    }"
  >
    <a
      *ngIf="jobModel?.output_issues_list_url"
      [href]="jobModel.output_issues_list_url"
      target="_blank"
      class="btn action-btn action-btn_default mrgn-r"
    >
      <span class="action-btn__icon">
        <app-svg [href]="'download'" iconClass="icon icon_s"></app-svg>
      </span>
      <span class="action-btn__title">Errors TXT</span>
    </a>

    <a
      *ngIf="jobModel?.output_failed_objects_url"
      [href]="jobModel.output_failed_objects_url"
      target="_blank"
      class="btn action-btn action-btn_default mrgn-r"
    >
      <span class="action-btn__icon">
        <app-svg [href]="'download'" iconClass="icon icon_s"></app-svg>
      </span>
      <span class="action-btn__title">Failed items CSV</span>
    </a>

    <button
      type="button"
      class="btn action-btn"
      [ngClass]="{
        'action-btn_default': status !== 'success',
        'action-btn_success': status === 'success'
      }"
      (click)="close()"
    >
      <span class="action-btn__icon"
        ><app-svg [href]="cancelIcon" iconClass="icon_xs"></app-svg
      ></span>
      <span class="action-btn__title">{{
        status === 'success' &&
        (jobModel?.name === 'Export Forecast' ||
          jobModel?.name === 'Export Project' || jobModel?.name === 'Export Projects')
          ? 'Done'
          : cancelText
      }}</span>
    </button>
  </div>
</section>
