import { DefaultModel } from './default.model';
import { DefaultDTO } from '../dto/default.dto';
import { ProjectDTO } from '../dto/project.dto';
import { ProjectAssetModel } from './project-asset.model';
import { ProjectFeesModel } from './project-fees-model';
import { AttachmentModel } from './attachment.model';
import { DefaultIdNameModel } from './default-id-name.model';

export class ProjectModel extends DefaultModel<DefaultDTO> {
  id: number;
  name: string;
  description?: string;
  assets_count?: number;
  is_occupied?: boolean;
  contingency_modifier?: number;
  contingency_modifier_generic?: number;
  occupation_modifier?: number;
  occupation_modifier_generic?: number;
  created_at?: string;
  updated_at?: string;
  fees?: ProjectFeesModel;
  created_by?: {
    id?: number;
    name?: string;
    is_active?: boolean;
  };
  totals?: {
    assets_costs_total?: number;
    assets_expenses_total?: number;
    assets_regional_values_total?: number;
    contingency?: number;
    fees_total?: number;
    occupied_space_cost?: number;
    project_cost_total?: number;
    assets_regional_factor?: string;
    assets_generic_and_labor_total: number;
  };
  total_project_cost?: number;
  subtotals?: any;
  udfs?: any;
  assets?: Array<ProjectAssetModel>;
  attachments?: Array<AttachmentModel>;
  attachments_attributes?: Array<AttachmentModel>;
  user?: {
    id?: number;
    name?: string;
  };
  building_assets_count: number;
  fiscal_years: string[][];
  edited_by: {
    id?: number;
    name?: string;
    is_active?: boolean;
  };
  periods: {
    assets_count: number;
    subtotals: { [key: string]: number };
    totals: {
      replacement_cost_total: number;
      assets_costs_total?: number;
      assets_expenses_total?: number;
      assets_regional_values_total?: number;
      contingency?: number;
      fees_total?: number;
      occupied_space_cost?: number;
      project_cost_total?: number;
      assets_regional_factor?: string;
    };
  }[];
  public: boolean;
  snapshot_at: string;
  owned_by?: {
    id: number;
    name: string;
  };
  locked?: boolean;
  project_status: string;
  assets_commited_on_other_projects: boolean;
  reference_number: string;
  inflation_rate: number;
  fees_coefficients: ProjectFeesModel;

  project_type: DefaultIdNameModel;
  project_risk: DefaultIdNameModel;
  project_date_from: Date;
  project_date_to: Date;
  energy_saving: string;
  allocated_by: string;
  lte_type: string;
  tag_ids: any = [];
  categories: any = [];
  editable_total_project_cost: string;
  funding_requested: number | string;
  funding_approved: number | string;

  constructor(data?) {
    super();
    if (data) {
      Object.assign(this, data);
    }
    this.id ||= null;
    this.attachments_attributes = [];
  }

  public get entity_name(): string {
    return 'project';
  }

  public toString(): string {
    return this.name;
  }

  public dto(): ProjectDTO {
    return new ProjectDTO(this);
  }
}
