<section class="body-wrapper">
  <header class="header header_not-bordered flex flex_to-end">
    <app-svg [href]="'logo'" [width]="100" [height]="35" [iconClass]="'icon_inherit'">
    </app-svg>
  </header>
  <main class="global-body">
    <div>
      <div class="alignment">
        <app-svg [href]="'exclamation-circle'" [width]="150" [height]="150" [iconClass]="'icon_inherit'"></app-svg>
      </div>
      <h2 class="system-page__title">{{text}}</h2>
      <div class="alignment">
        <a class="link link_simple system-page__btn" [routerLink]="['/']">
          Back to Origin
        </a>
      </div>
    </div>
  </main>
</section>