import { Injectable, Injector, NgZone, OnDestroy, inject } from '@angular/core';
import { Observable, Subject, fromEvent, interval, merge, of } from 'rxjs';
import { catchError, debounceTime, filter, takeUntil } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { FeatureFlagsService } from './feature-flags.service';
import { Router } from '@angular/router';
import { RoutesConstants } from '../constants/routes.constants';
import { SESSION_CONFIG } from '../constants';

const ACTIVITY_EVENTS = [
  'click',
  'keypress',
  'scroll',
  'touchstart',
  'mousemove',
  'beforeunload',
];

@Injectable({
  providedIn: 'root',
})
export class InactivityService implements OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private activityEvents$!: Observable<Event>;
  private injector = inject(Injector);

  constructor(
    private readonly ngZone: NgZone,
    private router: Router
  ) {}

  /**
   * Initialize inactivity timer and event listeners.
   */
  initializeTimers(): void {
    if (
      !this.injector
        .get(FeatureFlagsService)
        .isFeatureEnabled('OR_14024_Inactivity')
    ) {
      return;
    }
    this.setCounter();

    this.ngZone.runOutsideAngular(() => {
      this.activityEvents$ = merge(
        ...ACTIVITY_EVENTS.map((evt) => fromEvent(window, evt))
      ).pipe(debounceTime(2000), takeUntil(this.ngUnsubscribe));

      this.activityEvents$.subscribe(() => this.resetTimer());

      this.startInactivityCheck();
    });
  }

  /**
   * Starts the inactivity timer.
   */
  private startInactivityCheck(): void {
    this.ngZone.runOutsideAngular(() => {
      interval(SESSION_CONFIG.inactivityCheckFreqSec * 1000)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          filter(() => this.isInactive())
        )
        .subscribe(() => this.handleInactivity());
    });
  }

  /**
   * Sets the last activity timestamp in local storage.
   */
  private setCounter(): void {
    localStorage.setItem(
      SESSION_CONFIG.inactivityCounterKeyName,
      Date.now().toString()
    );
  }

  /**
   * Checks if the user has been inactive for the allowed time.
   */
  private isInactive(): boolean {
    const lastActive = Number(
      localStorage.getItem(SESSION_CONFIG.inactivityCounterKeyName)
    );
    return (
      (Date.now() - lastActive) / 1000 >= SESSION_CONFIG.appTimeoutInSeconds
    );
  }

  /**
   * Handles user inactivity.
   */
  private handleInactivity(): void {
    this.clearTimers();
    // used injector so that we can break circular dependency
    const authService = this.injector.get(AuthService);
    if (
      this.injector
        .get(FeatureFlagsService)
        .isFeatureEnabled('OR_12510_Auth0_Login')
    ) {
      authService.logout();
      return;
    }
    authService
      .logoutUser()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        catchError(() => {
          authService.logoutLocal();
          return of(null);
        })
      )
      .subscribe({
        next: () => {
          this.router.navigate([RoutesConstants.AUTH.LOGIN]);
        },
      });
  }

  /**
   * Resets the inactivity timer by updating the last activity timestamp.
   */
  private resetTimer(): void {
    this.setCounter();
  }

  /**
   * Clears all subscriptions and removes listeners.
   */
  private clearTimers(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnDestroy(): void {
    this.clearTimers();
  }
}
