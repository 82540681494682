import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';

import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { UserService } from '../../../core/services/user.service';

@Component({
  templateUrl: './work-order-modal.component.html',
})
export class WorkOrderModalComponent extends BaseModalComponent {
  @ViewChild('modalForm', { static: true }) declare tdForm: NgForm;
  @Input() public isFailed: boolean;
  public password: string;

  constructor(
    protected _userService: UserService,
    protected modalRef: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(modalRef, _notificationService, _eventService);
    this.isTemplateDriven = true;
  }

  public isValidForm(): boolean {
    return this.tdForm.valid;
  }

  public submit() {
    if (!this.isFailed && !this.isValidForm()) {
      return;
    }
    if (!this.isFailed) {
      this._userService.validatePassword(this.password).subscribe({
        next: (success) => {
          if (success) {
            this.onResult.next(true);
            this.modalRef.hide();
          } else {
            this.setError(
              this.tdForm.controls['password'] as FormControl,
              'api',
              'true'
            );
          }
        },
        error: (error) => this._notificationService.apiError(error),
      });
    } else {
      this.onResult.next(true);
      this.modalRef.hide();
    }
  }
}
