import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { RequestService } from './request.service';
import { PmTaskModel } from '../models/pm-template.model';

@Injectable({
  providedIn: 'root',
})
export class PmTaskService<M> extends BaseStoreService<M> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, PmTaskModel);
    this.resourceName = 'v1/pm_builder/universal/tasks';
  }

  public getResourceName(): string {
    return this.resourceName;
  }
}
