import { DefaultDTO } from './default.dto';
import { AttachmentModel } from '../models/attachment.model';
import { ProjectFeesModel } from '../models/project-fees-model';

export class ProjectDTO extends DefaultDTO {
  id: number;
  name: string;
  description: string;
  assets_count: number;
  is_occupied: boolean;
  occupation_modifier: number;
  public: boolean;
  contingency_modifier: number;
  attachments_attributes?: Array<AttachmentModel>;
  fees?: ProjectFeesModel;
  locked: boolean;
  project_type: number;
  project_risk: number;
  project_date_from: Date;
  project_date_to: Date;
  energy_saving: string;
  allocated_by: string;
  tag_ids:any;

  constructor(project: any) {
    super();
    this.id = project.id;
    this.name = project.name;
    this.assets_count = project.assets_count;
    this.is_occupied = project.is_occupied;
    this.occupation_modifier = project.occupation_modifier;
    this.public = project.public;
    this.contingency_modifier = project.contingency_modifier;
    this.attachments_attributes = project.attachments_attributes;
    this.locked = project.locked;
    this.project_type = project.project_type;
    this.project_risk = project.project_risk;
    this.description = project.description;
    this.project_date_from = project.project_date_from;
    this.project_date_to = project.project_date_to;
    this.energy_saving = project.energy_saving;
    this.allocated_by = project.allocated_by;
    this.tag_ids = project.tag_ids;
  }
}
