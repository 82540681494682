export class BudgetingConstants {
  public static ASSETS_FILTERS = {
    all: {
      key: 'all',
      name: 'All',
    },
    checked: {
      key: 'checked',
      name: 'Checked',
    },
    unchecked: {
      key: 'unchecked',
      name: 'Unchecked',
    },
  };

  public static FORECAST_ASSETS_FILTERS = {
    all: {
      type: 'all',
      title: 'All',
    },
    replaced: {
      type: 'replaced',
      title: 'Replaced',
    },
    invested: {
      type: 'invested',
      title: 'Invested',
    },
    no_funds: {
      type: 'no_funds',
      title: 'No funds',
    },
  };

  public static FUND_TYPES = {
    optimal: 'optimal',
    manual: 'manual',
  };

  public static TYPES = {
    maintenance: {
      key: 'maintenance',
      name: 'Maintenance',
    },
    replacement: {
      key: 'replacement',
      name: 'Replacements',
    },
  };

  public static LIFETIME_TYPES = {
    straightline: {
      key: 'straightline',
      name: 'Straightline',
    },
    adjusted: {
      key: 'adjusted',
      name: 'Adjusted',
    },
  };

  public static FORECAST_LIFETIME_TYPES = {
    regular: {
      key: 10,
      name: 'Straightline',
    },
    adjusted: {
      key: 20,
      name: 'Adjusted',
    },
  };

  public static PRIORITY = {
    risk: {
      key: 'risk_based',
      name: 'Risk',
    },
    normalized: {
      key: 'normalized',
      name: 'Normalized',
    },
  };

  public static GRAPHS_TYPE = {
    overall: {
      key: 'overall',
      name: 'Overall',
    },
    by_buildings: {
      key: 'buildings',
      name: 'By Buildings',
    },
    by_risk: {
      key: 'risks',
      name: 'By Risk',
    },
  };
  public static PM_WORK_ORDERS = {
    pm_work_orders: {
      key: 'pm_work_orders',
      name: 'PM Work Order',
    },
  };
}
