import { DefaultModel } from './default.model';
import { DefaultDTO } from '../dto/default.dto';
import { FacilityCategoryDTO } from '../dto/facility-category.dto';

export class FacilityCategoryModel extends DefaultModel<DefaultDTO> {
  id: number | string;
  parent_id?: number;
  type?: string;
  name: string;
  color_scheme?: number;
  color?: string;
  applicable_to?: Array<number>;
  position?: number;
  tags?: Array<FacilityTag>;
  errorMessage?: string;
  job_uuid?: string;
  _delete?: boolean;
  expanded?: boolean;
  isPickerOpened?: boolean;
  isEdit?: boolean = true;
  delete?: boolean;

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }

  public get entity_name(): string {
    return 'facility-category';
  }

  public toString(): string {
    return this.name;
  }

  public dto(): FacilityCategoryDTO {
    return new FacilityCategoryDTO(this);
  }
}

export interface FacilityCategoryError {
  key: string;
  message: string;
  type: string;
  value: string;
}

export interface FacilityTag {
  id: number;
  name: string;
  amount?: string;
  _delete?: boolean;
  isEdit?: boolean;
}

export interface FacilityCategoryUpdateModel {
  id?: number | string;
  index?: number;
  tagId?: any;
  tagIndex?: number;
  model?: FacilityCategoryModel;
}
