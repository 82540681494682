import { FormGroup, ValidatorFn } from '@angular/forms';
import { CapitalizePipe } from '../pipes/capitalize.pipe';

export function isEqual(_target: string, _source: string): ValidatorFn {
  return (group: FormGroup): { [key: string]: any } => {
    const target = group.get(_target);
    const source = group.get(_source);

    if (source.errors && !source.errors.equalTo) {
      return;
    }
    if (target.value !== source.value && target.valid && source.valid) {
      const error = {
        equalTo: {
          target: new CapitalizePipe().transform(
            _target
              .split(/(?=[A-Z])|_/)
              .join(' ')
              .capitalize()
          ),
          source: new CapitalizePipe().transform(
            _source
              .split(/(?=[A-Z])|_/)
              .join(' ')
              .capitalize()
          ),
        },
      };
      source.setErrors(error);
      return error;
    }

    return null;
  };
}
