import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-show',
  templateUrl: './password-show.component.html',
})
export class PasswordShowComponent implements OnInit {
  @Input() password: string;
  showPassword: boolean = false;
  fakePwd: string;
  enablePassword: boolean = true;

  ngOnInit() {
    if (!this.password || !this.password.length) {
      this.enablePassword = false;
    }
    this.fakePwd = '*********';
  }

  showPasswordByState() {
    if (this.showPassword && this.password.length) {
      return this.password;
    }
    return this.fakePwd;
  }
}
