import { Injectable } from '@angular/core';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Observable, Observer, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../auth.service';
import { IWebsocketEventModel } from '../../models/websocket-event.model';

@Injectable({
  providedIn: 'root',
})
export class WebsocketsService {
  private ws: ReconnectingWebSocket;
  private subject: Subject<IWebsocketEventModel>;

  constructor(private authService: AuthService) {}

  connect(): Observable<IWebsocketEventModel> {
    if (!this.subject || this.ws?.readyState === WebSocket.CLOSED) {
      this.subject = this.create();
    }

    return this.subject.asObservable();
  }

  disconnect(): void {
    this.ws?.close();
  }

  sendMessage(message: any): void {
    this.subject.next(message);
  }

  private create(): Subject<IWebsocketEventModel> {
    this.ws = new ReconnectingWebSocket(
      `${
        environment.api.wsUrl
      }/cable?api_key=${this.authService.getApiToken()}`,
      [],
      { debug: !environment.production }
    );

    const observable = new Observable((obs: Observer<string>) => {
      this.ws.onopen = () =>
        this.sendMessage({
          command: 'subscribe',
          identifier: '{"channel":"WebNotificationsChannel"}',
        });
      this.ws.onmessage = (event: MessageEvent<string>) =>
        obs.next(JSON.parse(event.data));
      this.ws.onerror = obs.error.bind(obs);
      this.ws.onclose = (e) => {
        if (e.wasClean) {
          obs.complete.bind(obs);
        } else {
          obs.error.bind(obs);
        }
      };

      return this.ws.close.bind(this.ws);
    });

    const observer = {
      next: (data: string) => {
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send(JSON.stringify(data));
        }
      },
    };

    return Subject.create(observer, observable);
  }
}
