import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { RequestService } from './request.service';
import { Observable, throwError } from 'rxjs';
import {
  BaseSessionModel,
  SessionModel,
} from '../models/session.model';
import { SessionEntityModel } from '../models/SessionEntityModel';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SessionService<
  T extends BaseSessionModel,
> extends BaseStoreService<T> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, SessionModel);
    this.resourceName = 'v1/integration/sessions';
  }

  public getWithEntities(
    id: string,
    params: any
  ): Observable<{
    params: any;
    session: SessionModel;
    list: Array<SessionEntityModel>;
  }> {
    const url = `/${this.resourceName}/${id}/entities`;
    return this._requestService.get(url, params, this.headers, true).pipe(
      map((data) => ({
        list: data.body.entities,
        session: data.body.session,
        params: this.headersToParamsBag(data.headers),
      })),
      catchError((error) => throwError(() => error))
    );
  }
}
