import { BehaviorSubject, Observable, of } from 'rxjs';

import { Configs } from '../constants/configs.constants';
import { EventService } from './event.service';
import { Injectable } from '@angular/core';
import { LocalSessionService } from './local-session.service';
import { OrganizationModel } from '../models/organization.model';
import { OrganizationStoreService } from './organization-store.service';
import { SESSION_KEY } from '../constants';
import { Settings } from 'luxon';
import { UserCurrentService } from './user-current.service';

@Injectable()
export class OrganizationCurrentService {
  public cachedOrganizationSubject: BehaviorSubject<OrganizationModel> =
    new BehaviorSubject(null);
  public cachedOrganizationSubject$: Observable<OrganizationModel> =
    this.cachedOrganizationSubject;
  public cachedTenantOrganizationSubject: BehaviorSubject<OrganizationModel> =
    new BehaviorSubject(null);
  public logoChangedID: string;
  private _mode: string;
  modes: { key: string; name: string; value: string }[] = Configs.indicesModes;
  modeSubj: BehaviorSubject<string> = new BehaviorSubject(this.modes[0].key);

  public get organization(): OrganizationModel {
    return (
      this.cachedOrganizationSubject.getValue() ||
      this.cachedTenantOrganizationSubject.getValue()
    );
  }

  public get tenantKey(): string {
    return this._localSessionService.tenantOrganizationKey;
  }

  public set tenantKey(value: string) {
    this._localSessionService.tenantOrganizationKey = value;
  }

  public get isTenant(): boolean {
    return !!this._localSessionService.tenantOrganizationKey;
  }

  public get clientKey(): string {
    return this._localSessionService.organizationKey;
  }

  public set clientKey(value: string) {
    this._localSessionService.organizationKey = value;
  }

  public get isClient(): boolean {
    return !!this._localSessionService.organizationKey;
  }

  set setIndicesMode(mode: string) {
    if (mode) {
      this._mode = mode;
      localStorage.setItem('selectedIndicesMode', this._mode);
    } else {
      this._mode = this.modes[0].key;
    }
    this.modeSubj.next(this._mode);
  }

  constructor(
    private _userCurrent: UserCurrentService,
    private _organizationStore: OrganizationStoreService,
    private _eventService: EventService,
    private _localSessionService: LocalSessionService
  ) {
    this._eventService.on(Configs.EVENTS.LOGOUT, () => {
      this.clearOrganizationSession();
      this.clean();
    });
  }

  clearOrganizationSession(): void {
    const keysToRemove = ['activeRouteId', SESSION_KEY.assetPinnedNodes];
    keysToRemove.forEach((key) => sessionStorage.removeItem(key));
  }

  public update(organization?: OrganizationModel): void {
    if (!organization) {
      this._organizationStore.getById(this.organization.id_hash).subscribe({
        next: (org) => this.update(org),
      });
      return;
    }
    if (this.isClient) {
      const currentOrg = this.cachedOrganizationSubject.getValue();
      this.cachedOrganizationSubject.next(
        Object.assign(currentOrg, organization)
      );
    } else {
      const currentOrg = this.cachedTenantOrganizationSubject.getValue();
      this.cachedTenantOrganizationSubject.next(
        Object.assign(currentOrg, organization)
      );
    }
  }

  public get(isTenant?: boolean): Observable<OrganizationModel> {
    return isTenant ? this.getTenant() : this.getClient();
  }

  public getTenant(): Observable<OrganizationModel> {
    const cachedTenantOrganization =
      this.cachedTenantOrganizationSubject.getValue();
    if (
      cachedTenantOrganization &&
      cachedTenantOrganization.id_hash === this.tenantKey
    ) {
      return of(this.cachedTenantOrganizationSubject.getValue());
    }
    return new Observable<OrganizationModel>((observer) => {
      this._organizationStore.platformService.isOrgLoading$.next(true);
      this._userCurrent.get().subscribe({
        next: (user) => {
          if (user.is_tenant && this.tenantKey != null) {
            this._organizationStore.getById(this.tenantKey).subscribe({
              next: (organization) => {
                Settings.defaultZone = organization.time_zone;
                this.cachedTenantOrganizationSubject.next(organization);
                // it seems getTenet loads for different org for admin
                // this._organizationStore.platformService.updateUserProfile({
                //   organization,
                //   orgId: this.tenantKey,
                // });
                this._organizationStore.platformService.isOrgLoading$.next(
                  false
                );
                observer.next(this.cachedTenantOrganizationSubject.getValue());
                observer.complete();
              },
              error: (error) => {
                this.cleanTenant();
                observer.error(error);
                observer.complete();
              },
            });
          } else {
            this.cachedTenantOrganizationSubject.next(null);
            this._organizationStore.platformService.isOrgLoading$.next(false);
            observer.error();
            observer.complete();
          }
        },
        error: (error) => {
          this.cleanTenant();
          observer.error(error);
          observer.complete();
        },
      });
    });
  }

  public getClient(forced?: boolean): Observable<OrganizationModel> {
    const cachedOrganization = this.cachedOrganizationSubject.getValue();
    if (
      !forced &&
      cachedOrganization &&
      cachedOrganization.id_hash === this.clientKey
    ) {
      return of(this.cachedOrganizationSubject.getValue());
    }
    return new Observable<OrganizationModel>((observer) => {
      this._organizationStore.platformService.isOrgLoading$.next(true);
      this._userCurrent.get().subscribe({
        next: (user) => {
          let orgSelected = true;
          if (!user.is_tenant && user.organization) {
            if (forced) {
              this._organizationStore.getById(this.clientKey).subscribe({
                next: (organization) => {
                  this.cachedOrganizationSubject.next(organization);
                  observer.next(this.cachedOrganizationSubject.getValue());
                  this._organizationStore.platformService.updateUserProfile({
                    organization,
                    orgId: this.clientKey,
                  });
                  this._organizationStore.platformService.isOrgLoading$.next(
                    false
                  );
                  observer.complete();
                },
                error: () => {
                  this._organizationStore.platformService.isOrgLoading$.next(
                    false
                  );
                },
              });
            } else {
              this.cachedOrganizationSubject.next(user.organization);
              this._organizationStore.platformService.isOrgLoading$.next(false);
              observer.next(this.cachedOrganizationSubject.getValue());
              observer.complete();
            }
          } else if (this.clientKey) {
            this._organizationStore.getById(this.clientKey).subscribe({
              next: (organization) => {
                this.cachedOrganizationSubject.next(organization);
                observer.next(this.cachedOrganizationSubject.getValue());
                this._organizationStore.platformService.updateUserProfile({
                  organization,
                  orgId: this.clientKey,
                });
                this._organizationStore.platformService.isOrgLoading$.next(
                  false
                );
                observer.complete();
              },
              error: (error) => {
                this.onError(observer, error.error);
              },
            });
          } else {
            this.cachedOrganizationSubject.next(null);
            this._organizationStore.platformService.updateUserProfile({
              organization: null,
              orgId: '',
            });
            this._organizationStore.platformService.isOrgLoading$.next(false);
            orgSelected = false;
            observer.error();
            observer.complete();
          }
          this._eventService.broadcast(Configs.EVENTS.ORGANIZATION_CHANGED, {
            orgSelected: orgSelected,
          });
        },
        error: (error) => this.onError(observer, error.error),
      });
    });
  }

  public cleanBoth() {
    this.clean();
    this.cleanTenant();
  }

  public clean(): void {
    this.cachedOrganizationSubject.next(null);
    this._localSessionService.organizationKey = null;
  }

  public cleanTenant(): void {
    this.cachedTenantOrganizationSubject.next(null);
    this._organizationStore.platformService.isOrgLoading$.next(false);
    this._localSessionService.tenantOrganizationKey = null;
  }

  private onError(observer, error) {
    this.clean();
    observer.error(error);
    observer.complete();
    this._organizationStore.platformService.isOrgLoading$.next(false);
  }
}
