<!--WO Toolbar-->
<div class="toolbar__panel toolbar__panel_not-bordered flex">
  <app-table-search
    [keyword]="keyword"
    (onSearch)="search($event, listId)"
  ></app-table-search>
  <app-per-page-selector
    [disabled]="!items?.length"
    [perPage]="perPage"
    (onUpdate)="updatePerPage($event)"
  >
  </app-per-page-selector>
</div>
<!--END WO Toolbar-->
<table
  id="pm_work_orders_list_table_id"
  class="table table-striped"
  [ngClass]="{ table_bordered: !isSeparateList && !isAssetList }"
>
  <thead class="table__header">
    <tr>
      <th
        [ngClass]="getSortingCellClass('number')"
        (click)="setSortBy('number')"
        class="table__sorting-column"
      >
        Work Order
      </th>
      <th
        [ngClass]="getSortingCellClass('pmpe_number')"
        (click)="setSortBy('pmpe_number')"
        class="table__sorting-column"
      >
        PM/PE Number
      </th>
      <th>Assignee</th>
      <th
        [ngClass]="getSortingCellClass('end_date')"
        (click)="setSortBy('end_date')"
        class="table__sorting-column"
      >
        Due Date
      </th>
      <th
        [ngClass]="getSortingCellClass('status')"
        (click)="setSortBy('status')"
        class="table__sorting-column"
      >
        Status
      </th>
    </tr>
  </thead>
  <tbody>
    <!--Loader-->
    <app-table-loader
      [colspan]="5"
      [isLoading]="isLoading"
      [items]="items"
    ></app-table-loader>
    <!--END Loader-->
    <ng-container *ngIf="!isLoading">
      <tr *ngFor="let item of items">
        <!--1 Work Order-->
        <td>
          <!--NO Report & USER IS performer-->
          <a
            [routerLink]="[
              '/',
              organization.id_hash,
              'pm_work_orders',
              item.id
            ]"
            class="link text-semibold"
            >{{ item.number }}</a
          >
        </td>
        <!--END Work Order-->
        <!--2 PMPE Number-->
        <td>
          {{ item.pmpe.number }}
        </td>
        <!--PMPE Number-->
        <!--3 Assignee-->
        <td>
          <app-user-link
            [userId]="item.assignee.id"
            [userName]="item.assignee.name"
            [isActive]="item.assignee.is_active"
          ></app-user-link>
        </td>
        <!--END Assignee-->

        <!--4 Due Date-->
        <td>{{ item.end_date | dateFormat | text }}</td>
        <!--END Due Date-->

        <!--6 Status-->
        <td class="compliance-status">
          <div
            class="el-in-a-row el-in-a-row_c compliance-status-icon compliance-status-icon_{{
              store.getIcon(item.status, 'workorder')[0]
            }}"
          >
            <app-svg
              [href]="store.getIcon(item.status, 'workorder')[0]"
            ></app-svg>
            <span>{{ store.getIcon(item.status, 'workorder')[1] }}</span>
          </div>
        </td>
        <!--END Status-->
      </tr>
    </ng-container>
  </tbody>
</table>
<footer
  class="content-footer el-in-a-row el-in-a-row_c el-in-a-row_lg flex_to-end"
>
  <app-pagination-caption
    [start]="getStartPageItem()"
    [end]="getEndPageItem()"
    [total]="total"
  >
  </app-pagination-caption>
  <app-pagination
    [hasLeftDots]="hasLeftDots"
    [hasRightDots]="hasRightDots"
    [hasNextPage]="hasNextPage"
    [hasPrevPage]="hasPrevPage"
    [page]="page"
    [pages]="pages"
    [shouldPassPageNumber]="false"
    [totalPages]="totalPages"
    (onNextPage)="nextPage()"
    (onPrevPage)="prevPage()"
    (onSetPage)="setPage($event)"
  >
  </app-pagination>
</footer>
