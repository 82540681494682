import { CUSTOM_EVENTS } from '../constants';

export class CustomEventDispatcher {
  /**
   * dispatch custom event on side nav toogle
   * @param isOpen is side nav toogle state
   */
  public static dispatchSideNavToogle(isOpen: boolean): void {
    window.dispatchEvent(
      new CustomEvent<boolean>(CUSTOM_EVENTS.global.sideNavToggle, {
        detail: isOpen,
      })
    );
  }
}
