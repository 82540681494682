import { DefaultModel } from './default.model';
import TemplateDTO from '../dto/template.dto';

export class TemplateModel extends DefaultModel<TemplateDTO> {
  id: number;
  updated_at: string;
  title: string;
  description: string;
  assets_types: Array<string>;
  assets_repository_ids: Array<number>;
  template: Array<TemplateTestModel>;
  applicable_for_types?: Array<{
    assets_repository_id: number;
    template_is_default: boolean;
  }>;
  types?: Array<any>;
  tests?: Array<TemplateTestModel>;
  isDefault?: boolean;

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
    }
    if (!this.types) {
      this.types = [];
    }
    if (!this.tests) {
      this.tests = [];
    }
  }

  public toString() {
    return this.title;
  }

  public get entity_name(): string {
    return this.isDefault ? 'report_template_repository' : 'report_template';
  }

  public dto(): TemplateDTO {
    return new TemplateDTO(this);
  }
}

export interface TemplateTestModel {
  id?: number;
  disabled?: boolean;
  type?: any;
  title?: string;
  description?: string;
  hints?: Array<any>;
  labels?: Array<any>;
  validations?: Array<any>;
  answers?: Array<any>;
  frontend_date?: string;
  original_answer?: any;
  isEdit?: boolean;
  isDatePickerOpened?: boolean;
  invalid?: boolean;
  incomplete?: boolean;
  format?: string;
  answer?: Array<any>;
  errors?: Array<boolean>;
  options?: Array<TestOption>;
  calculation_types?: Array<{ id: string | number; name: string }>;
  error?: boolean;
  _id?: number;
}

export interface TestOption {
  type?: any;
  hint?: string;
  label?: any;
  description?: string;
  min?: any;
  max?: any;
  validation?: number;
  const?: string;
  test?: number;
  test_option?: number;
  error?: boolean;
  _id?: number;
}

export interface TestAnswerModel {
  title?: string;
  value?: string | number;
  passed?: boolean;
}

export interface TemplateAttachment {
  file: any;
  name: string;
  size: string;
}
