<section class="body-wrapper">
  <header class="header header_not-bordered flex flex_to-end">
    <app-svg
      [href]="'logo'"
      [width]="100"
      [height]="35"
      [iconClass]="'icon_inherit'"
    >
    </app-svg>
  </header>
  <main class="global-body">
    <section class="xs-100 flex flex-a-start">
      <div class="main-content system-page xs-24">
        <h1 class="system-page__heading">404</h1>
        <h2 class="system-page__title">Nothing to see here</h2>
        <a class="link link_simple system-page__btn" [routerLink]="['/']">
          Go to homepage
        </a>
      </div>
      <figure class="text-center xs-74">
        <img
          class="system-page__image"
          src="../../../../assets/img/404.png"
          alt="404"
        />
      </figure>
    </section>
  </main>
</section>
