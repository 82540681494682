<section class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header">Change password</h2>
  </header>

  <form [formGroup]="form" (ngSubmit)="saveAction()">
    <div class="modal-content__body text-center" *ngIf="isLoading">
      <app-loader></app-loader>
    </div>

    <ul [hidden]="isLoading" class="modal-content__body modal-content__list">
      <li class="modal-content__item modal-content__item_full-width">
        <label for="currentPassword" class="modal-content__label">{{
          'labels.authorization.old_pass' | translate
        }}</label>
        <div class="form-block form-block_lg">
          <input
            type="password"
            id="currentPassword"
            formControlName="current_password"
            class="form-block__element"
            appTrimOnBlur
            [ngClass]="{ 'form-block_error': hasError('current_password') }"
            [placeholder]="'placeholders.enter_value' | translate"
            appTrimOnBlur
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('current_password')"
            [tooltip]="getErrorText('current_password')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>

      <li class="modal-content__item modal-content__item_full-width">
        <label for="newPassword" class="modal-content__label">{{
          'labels.authorization.new_pass' | translate
        }}</label>
        <div class="form-block form-block_lg">
          <input
            type="password"
            id="newPassword"
            formControlName="new_password"
            class="form-block__element"
            appTrimOnBlur
            [ngClass]="{ 'form-block_error': hasError('new_password') }"
            [placeholder]="'placeholders.enter_value' | translate"
            appTrimOnBlur
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('new_password')"
            [tooltip]="getErrorText('new_password')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>

      <li class="modal-content__item modal-content__item_full-width">
        <label for="confirmNewPassword" class="modal-content__label">{{
          'labels.authorization.confirm_new_pass' | translate
        }}</label>
        <div class="form-block form-block_lg">
          <input
            type="password"
            id="confirmNewPassword"
            formControlName="new_password_confirmation"
            class="form-block__element"
            appTrimOnBlur
            [ngClass]="{
              'form-block_error': hasError('new_password_confirmation')
            }"
            [placeholder]="'placeholders.enter_value' | translate"
            appTrimOnBlur
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('new_password_confirmation')"
            [tooltip]="getErrorText('new_password_confirmation')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>
    </ul>

    <section class="buttons-block modal-content__footer">
      <button
        type="button"
        (click)="close()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>

      <button class="btn action-btn action-btn_success">
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Change</span>
      </button>
    </section>
  </form>
</section>
