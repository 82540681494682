export const ErrorsConstants = {
  E1084: {
    message: 'Desired score can not exceed fhi optimal funded',
    showContinue: true,
  },
  E1002: {
    message:
      'There are some invalid assets that prevent Forecast calculations. You can view the list of such assets %here%.\n\nThe Forecast will be created without those assets',
    showContinue: true,
  },
  E1003: {
    message: `There are no assets to be replaced within the selected time period. Please adjust the settings or filters to include at least one asset.`,
    needToBeFixed: true,
  },
  E1016: {
    message: `The new filter settings will remove some assets from this Forecast.\nBy clicking "Continue" you agree to proceed with removing those assets`,
    showContinue: true,
  },
  E3002: {
    message:
      'Some assets of the current Project scope have been made archived, incomplete, or have inconsistent data. Such assets will be excluded from the Project',
    showContinue: true,
  },
  E3005: {
    message: `Some assets have critical issues and cannot be .`,
  },
  E07000: {
    message: 'There are some assets with inconsistent data in scope',
  },
  E07200: {
    message: 'There are some assets with inconsistent data in scope',
    needToBeFixed: true,
  },
};
