import { AssetsService } from 'src/app/core/services/assets.service';
import { Component } from '@angular/core';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-toggle-button-cell-renderer',
  template: `
    <label class="custom-toggle">
      <input
        *ngIf="params.data"
        type="checkbox"
        class="custom-toggle__input custom-toggle__input_lg"
        (click)="!isMaxHierarchyLevelReached ? toggleParent() : null"
        [(ngModel)]="params.data.is_parent"
        [disabled]="isMaxHierarchyLevelReached"
      />
      <span
        class="custom-toggle__check custom-toggle__check_lg"
        [tooltip]="toolTipText"
        [attr.disabled]="isMaxHierarchyLevelReached"
      >
        <span class="custom-toggle__toggler custom-toggle__toggler_lg"></span>
      </span>
    </label>
  `,
  styles: [],
})
export class ToggleButtonCellRendererComponent
  implements ICellRendererAngularComp
{
  constructor(
    public _assetService: AssetsService,
    public _notificationService: CustomNotificationService
  ) {}

  params: any;
  isMaxHierarchyLevelReached: boolean = false;
  toolTipText: string = null;
  maxHierarchyLevel: number = 5;
  hasChildren: boolean = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isMaxHierarchyLevelReached =
      params.data?.data_path.length === this.maxHierarchyLevel;
    this.hasChildren = this.params?.node?.childrenAfterGroup.length > 0;

    if (this.isMaxHierarchyLevelReached) {
      this.toolTipText = `You can create upto ${this.maxHierarchyLevel} levels only, the maximum limit has been reached.`;
    } else if (this.hasChildren) {
      this.toolTipText =
        'Toggling off a parent will deconstruct the entire hierarchy.';
    }

    if (this.isMaxHierarchyLevelReached && this.params.data.is_parent) {
      this.toggleParent();
    }
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  toggleParent() {
    this.params.data.is_parent = !this.params.data.is_parent;
    this.params.api.refreshCells({ rowNodes: [this.params.node] });
    this._assetService.setToggleButtonClickedSubject(true);
    this._assetService
      .updateAssetParent(this.params.data.id, {
        asset: {
          is_parent: this.params?.data?.is_parent,
          is_external_hierarchy_change: true,
        },
      })
      .subscribe({
        next: () => {
          this._assetService.setToggleButtonClickedSubject(false);
          this.params.context.handleComparisonToggle(
            this.params.data.id,
            this.params.data.is_parent,
            this.hasChildren
          );
        },
        error: (error) => {
          const errorMessage = error?.error?.errors?.[0]?.message;
          this._notificationService.error(errorMessage);
          this._assetService.setToggleButtonClickedSubject(false);
          this.params.data.is_parent = !this.params.data.is_parent;
          this.params.api.refreshCells({ rowNodes: [this.params.node] });
        },
      });
  }
}
