import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  FHIApiHistoryModel,
  FHIHistoryModel,
  FHIModuleHistoryModel,
} from '../models/fhi-module-history.model';
import { FHIScoreModel } from '../models/fhi-score.model';
import { AssetModel } from '../models/asset.model';
import { AssetTypeExtendedModel } from '../models/asset-type-extended.model';
import { BaseStoreService } from './base-store.service';
import { BaseModel } from '../models/base.model';
import { DefaultListModel } from '../models/default-list.model';
import { OrganizationCurrentService } from './organization-current.service';
import { OrganizationModel } from '../models/organization.model';
import { HelperService } from './helper.service';

@Injectable()
export class FHIModulesService<M> extends BaseStoreService<M> {
  organization: OrganizationModel;

  constructor(
    protected _requestService: RequestService,
    private organizationCurrent: OrganizationCurrentService
  ) {
    super(_requestService, BaseModel);
    this.organization = this.organizationCurrent.organization;
  }

  getModuleChartsValues(
    params: { [key: string]: any } = {}
  ): Observable<FHIScoreModel> {
    return this._requestService.get('/v1/modules/scores', params);
  }

  public getItems(
    params: any,
    ...rest: any[]
  ): Observable<DefaultListModel<M>> {
    const [serviceType, fhiType] = rest;
    return super.getItems(
      params,
      `v1/modules/${fhiType}${fhiType !== 'fhi' ? '' : '/assets'}/${serviceType}`
    );
  }

  /**
   * Requests a module history for asset
   * @param asset_id
   * @param period - time period (calendar, lifetime, rolling)
   * @returns {Promise}
   */
  public getModuleHistoryForAsset(
    asset_id: number,
    period: string
  ): Observable<FHIHistoryModel> {
    return this._requestService
      .get(`/v1/assets/${asset_id}/history/${period}`)
      .pipe(map((success) => this.parseHistoryData(success)));
  }

  /**
   * Requests modules history for organization
   * @returns {Promise}
   */
  public getModuleHistoryForOrganization(): Observable<FHIHistoryModel> {
    return this._requestService
      .get('/v1/modules/history')
      .pipe(map((success) => this.parseHistoryData(success)));
  }

  private parseHistoryData(raw: FHIApiHistoryModel): FHIHistoryModel {
    const modules: FHIHistoryModel = {};
    for (const fhi in raw) {
      if (raw.hasOwnProperty(fhi)) {
        modules[fhi] = raw[fhi].map((elem) => new FHIModuleHistoryModel(elem));
      }
    }
    return modules;
  }

  /**
   * Check item for empry module values
   * @param item - asset model
   * @param fhiType
   * @returns {boolean}
   */
  public isEmptyModuleValue(item: AssetModel, fhiType: string): boolean {
    return (
      !HelperService.isExist(item[fhiType + '_r_index']) ||
      HelperService.isExist(item[fhiType + '_c_index']) ||
      (fhiType !== 'fhir' && HelperService.isExist(item[fhiType + '_l_index']))
    );
  }

  public getFHILife(item: AssetTypeExtendedModel): string {
    if (!item) {
      return;
    }
    let sum = 0,
      count = 0;
    const neededFields = [
      'rs_means_life',
      'aha_ashrae_life',
      'experiential_life',
    ];
    neededFields.forEach((field) => {
      if (HelperService.isExist(item[field])) {
        sum += Number(item[field]);
        count++;
      }
    });
    item.fh_life = count && sum / count ? sum / count : 0;
  }
}
