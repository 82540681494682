import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EMPTY, Observable, Subscription, of, throwError, timer } from 'rxjs';
import { Configs } from '../constants/configs.constants';
import { LocalSessionService } from './local-session.service';
import { catchError, mergeMap, retryWhen, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EventService } from './event.service';
import { RoutesConstants } from '../constants/routes.constants';
import { FeatureFlagsService } from './feature-flags.service';

@Injectable()
export class RequestService {
  public showUnauthorizedError: boolean = true;
  public isRefreshing: boolean = false;
  private authTokenSubscription: Subscription | null = null;
  public TOKEN_EXPIRATION_BUFFER: number = 5000;

  constructor(
    private httpClient: HttpClient,
    private _localSessionService: LocalSessionService,
    private _eventsService: EventService,
    private router: Router,
    private _featureFlagsService: FeatureFlagsService
  ) {}

  public get(
    url: string,
    params?: any,
    headers?: any,
    observeResponse?: boolean,
    responseType?,
    statusRedirect: boolean = false
  ): Observable<any> {
    return this.request(
      'GET',
      url,
      this.combineOptions({ params }, headers, responseType),
      observeResponse,
      statusRedirect
    );
  }

  public download(url: string, params?): Observable<any> {
    return this.request(
      'GET',
      url,
      this.combineOptions({ params }, undefined, 'blob')
    );
  }

  public post(
    url: string,
    body?: any,
    headers?: any,
    observeResponse?: boolean,
    params?: any,
    statusRedirect: boolean = false
  ): Observable<any> {
    return this.request(
      'POST',
      url,
      this.combineOptions({ body, params }, headers),
      observeResponse,
      statusRedirect
    );
  }

  public put(
    url: string,
    body?: any,
    headers?: any,
    observeResponse?: boolean,
    params?: any,
    statusRedirect: boolean = false
  ): Observable<any> {
    return this.request(
      'PUT',
      url,
      this.combineOptions({ body, params }, headers),
      observeResponse,
      statusRedirect
    );
  }

  public delete(
    url: string,
    body?: any,
    params?: any,
    headers?: any,
    observeResponse?: boolean,
    statusRedirect: boolean = false
  ): Observable<any> {
    return this.request(
      'DELETE',
      url,
      this.combineOptions({ body, params }, headers),
      observeResponse,
      statusRedirect
    );
  }

  private combineOptions(options?: any, headers?: any, responseType?): any {
    if (headers) {
      options.headers = new HttpHeaders(headers);
    }
    if (responseType) {
      options.responseType = responseType;
    }
    if (!options.params) {
      delete options.params;
    }
    if (!options.body) {
      delete options.body;
    }
    return options;
  }

  // eslint-disable-next-line max-len
  private request(
    method: string,
    url: string,
    options: any = {},
    observeResponse?: boolean,
    statusRedirect: boolean = true
  ): Observable<any> {
    const token = localStorage.getItem(Configs.TOKENS.BEARER);
    const orgKey =
      this._localSessionService.organizationKey ||
      this._localSessionService.tenantOrganizationKey;

    if (token) {
      const bearerHeader = this._featureFlagsService.isFeatureEnabled(
        'OR_12510_Auth0_Login'
      )
        ? {
            [environment.tokens.bearer_token]: `Bearer ${token}`,
          }
        : {
            [environment.tokens.bearer_header]: token,
          };
      if (options.headers) {
        options.headers = this._featureFlagsService.isFeatureEnabled(
          'OR_12510_Auth0_Login'
        )
          ? options.headers.append(
              `${environment.tokens.bearer_token}`,
              `Bearer ${token}`
            )
          : options.headers.append(
              `${environment.tokens.bearer_header}`,
              token
            );
      } else {
        options.headers = new HttpHeaders(bearerHeader);
      }
    }
    if (
      options.headers &&
      !options.headers.get(environment.tokens.org_header) &&
      orgKey
    ) {
      options.headers = options.headers.append(
        environment.tokens.org_header,
        orgKey
      );
    }
    if (observeResponse) {
      options.observe = 'response';
    }
    let environmentBase;
    if (url.includes('processor-api')) {
      environmentBase = environment.api.processor;
    } else if (url.includes('image_resize')) {
      environmentBase = '';
    } else {
      environmentBase = environment.api.base;
    }

    const request = this.httpClient
      .request(method, environmentBase + url, options)
      .pipe(
        catchError((error) => {
          switch (true) {
            case error.status == 401: {
              if (
                this._featureFlagsService.isFeatureEnabled(
                  'OR_12510_Auth0_Login'
                )
              ) {
                if (!this.isRefreshing) {
                  this.isRefreshing = true;
                  const currentUrl = this.router.url;
                  this.refresh_token().subscribe({
                    next: (data) => {
                      this.isRefreshing = false;
                      localStorage.setItem(
                        Configs.TOKENS.BEARER,
                        data.access_token
                      );
                      this.router
                        .navigateByUrl('/', { skipLocationChange: true })
                        .then(() => {
                          this.router.navigate([currentUrl]);
                        });
                    },
                    error: () => {
                      this.isRefreshing = false;
                      const logoutUrl = `${environment.auth0_url}/logout?returnTo=${environment.auth0_domain}&client_id=${environment.clientId}`;
                      window.location.href = logoutUrl;
                    },
                  });
                }
                break;
              } else {
                this._eventsService.broadcast(Configs.EVENTS.NOT_AUTHORIZED);
                if (this.showUnauthorizedError) {
                  this.showUnauthorizedError = false;
                  return throwError(error);
                } else {
                  return EMPTY;
                }
              }
            }
            case (error.status == 404 || error.status == 403) &&
              statusRedirect: {
              this.router.navigate([RoutesConstants.NOT_FOUND], {
                replaceUrl: true,
              });
              return of();
            }
          }

          return throwError(error);
        })
      );
    return request.pipe(this.retryWithDelay(2, 1000));
  }

  private refresh_token(): Observable<any> {
    const auth0Domain = environment.auth0_domain;
    const clientId = environment.clientId;
    const auth0_url = environment.auth0_url;
    const token = localStorage.getItem(Configs.TOKENS.REFRESH);
    const url = environment.api.processor + '/processor-api/refresh';
    const body = {
      refreshToken: token,
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        refreshToken: token,
      }),
    };
    const request = this.httpClient.post(url, body, options).pipe(
      catchError((error) => {
        if (error.status == 403 || error.status === 401) {
          const logoutUrl = `${auth0_url}/logout?returnTo=${auth0Domain}&client_id=${clientId}`;
          window.location.href = logoutUrl;
          return EMPTY;
        }
        this.isRefreshing = false;
        return throwError(error);
      })
    );
    return request.pipe(this.retryWithDelay(2, 1000));
  }

  public refresh_auth_token() {
    const token = localStorage.getItem(Configs.TOKENS.BEARER);
    if (this.authTokenSubscription) {
      this.authTokenSubscription.unsubscribe();
    }
    if (!token) {
      this.handleTokenExpiration(1000);
      return;
    }
    const expirationTime = this.parseTokenExpiry(token);
    if (expirationTime <= 0) {
      this.refresh_token().subscribe({
        next: (data) => {
          localStorage.setItem(Configs.TOKENS.BEARER, data.access_token);
          this.handleTokenExpiration(this.parseTokenExpiry(data.access_token));
        },
      });
    } else {
      this.handleTokenExpiration(expirationTime);
    }
  }

  public parseTokenExpiry(token: string): number {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp * 1000;
    return expiry - Date.now() - this.TOKEN_EXPIRATION_BUFFER;
  }

  public handleTokenExpiration(time): void {
    this.authTokenSubscription = timer(time).subscribe(() =>
      this.refresh_auth_token()
    );
  }

  public retryWithDelay(
    maxRetries: number,
    delay: number
  ): (source: Observable<any>) => Observable<any> {
    return (source: Observable<any>) =>
      source.pipe(
        retryWhen((errors: Observable<any>) =>
          errors.pipe(
            mergeMap((error, count) => {
              if (
                count < maxRetries &&
                (error.status === 502 ||
                  error.status === 503 ||
                  error.status === 504)
              ) {
                return timer(delay);
              }
              return throwError(() => error);
            })
          )
        ),
        take(maxRetries + 1)
      );
  }
}
