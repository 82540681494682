import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBgJobModel } from 'src/app/core/models/bg-worker.model';
import { BgJobService } from 'src/app/core/services/bg-worker.service';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { HelperService } from 'src/app/core/services/helper.service';
import { ProgressBarServiceService } from 'src/app/core/services/progress-bar-service.service';

@Component({
  selector: 'app-common-progress-bar',
  templateUrl: './common-progress-bar.component.html',
  styleUrls: ['./common-progress-bar.component.less'],
})
export class CommonProgressBarComponent implements OnInit {
  @Input() bgJobModel: IBgJobModel;
  @Input() orgId: string;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() destroyLoader = new EventEmitter();
  @Output() goToForecastClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() backToForecastClicked: EventEmitter<void> =
    new EventEmitter<void>();

  public helper = HelperService;
  public showProgressBar: boolean = true;
  public serverErrorOccurred: boolean = false;

  constructor(
    private _bgJobService: BgJobService<any>,
    private _notificationService: CustomNotificationService,
    private progressBarService: ProgressBarServiceService
  ) {}

  ngOnInit() {
    this.progressBarService.showProgressBar$.subscribe((show) => {
      this.showProgressBar = show;
    });
    this.progressBarService.serverError$.subscribe((value) => {
      this.serverErrorOccurred = value;
    });
  }
  public cancelBgJob() {
    if (this.bgJobModel) {
      // Emit the destroyLoader event
      this.destroyLoader.emit();

      if (this.bgJobModel.status !== 30 || !this.bgJobModel.success) {
        // If job is not completed successfully, cancel it
        this.cancelOtherJob(this.bgJobModel?.job_uuid, this.orgId);
      } else {
        // Otherwise, clear the job model
        this.progressBarService.startProgress(null);
        this.bgJobModel = null;
      }
    }
  }

  private cancelOtherJob(jobUuid, orgId) {
    this._bgJobService.cancelBgJob(jobUuid, orgId).subscribe({
      next: () => {
        if (this.bgJobModel?.name === 'forecast_create') {
          const forecastId =
            this.bgJobModel.details?.forecast_id ?? this.bgJobModel.entity_id;
          this._bgJobService.cancelForecast(forecastId).subscribe({
            next: () => (this.bgJobModel = null),
            error: (err) => this._notificationService.apiError(err),
          });
        }
        this.progressBarService.startProgress(null);
      },
      error: (err) => this._notificationService.apiError(err),
    });
  }

  getMessage(): string {
    if (
      this.bgJobModel?.message &&
      typeof this.bgJobModel.message === 'string'
    ) {
      return this.bgJobModel.message;
    } else {
      return 'Forecast Uploading...';
    }
  }

  getPercentage(): string {
    if (this.bgJobModel && typeof this.bgJobModel.message === 'number') {
      return `${this.bgJobModel.message}%`;
    } else {
      return '';
    }
  }

  emitEvent() {
    this.goToForecastClicked.emit();
  }

  backToForecast() {
    this.backToForecastClicked.emit();
  }
}
