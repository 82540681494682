import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PictureModel } from '../../../core/models/picture.model';
import { ImageResizeService } from '../../../core/services/image-resize.service';
import { UploadResourceService } from '../../../core/services/upload-resource.service';
import { HelperService } from '../../../core/services/helper.service';
import { Configs } from '../../../core/constants/configs.constants';
import { OrganizationModel } from '../../../core/models/organization.model';
import { EventService } from '../../../core/services/event.service';
import { AsyncSubject, Observable, throwError } from 'rxjs';
import { RequestService } from '../../../core/services/request.service';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AddingTagModalComponent } from '../../modals/adding-tag-modal/adding-tag-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import crypto from 'crypto-js';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';

@Component({
  selector: 'app-uploading-gallery',
  templateUrl: './uploading-gallery.component.html',
})
export class UploadingGalleryComponent {
  @Input() images: Array<PictureModel> = [];
  @Input() picturesEdit: Array<PictureModel> = [];
  @Input() isGalleryOpened: boolean = false;
  @Input() modelEntity: string;
  @Input() modelId: number = null;
  @Input() full = false;
  @Input() organization: OrganizationModel;

  @Output() onImageAction = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;
  avatar_error: boolean;
  uploading_image: boolean;
  imagesUploaded: number = 0;
  imageBaseUrl: string;
  dropzoneActive: boolean;
  galleryImageIndex: number;
  overImage: number;
  private imageHashes: any = {};
  private modalRef: BsModalRef;

  get helper() {
    return HelperService;
  }

  constructor(
    protected _imageResizingService: ImageResizeService,
    protected _uploadResourceService: UploadResourceService,
    protected _eventService: EventService,
    protected _requestService: RequestService,
    protected modalService: BsModalService,
    private httpClient: HttpClient,
    protected _notificationService: CustomNotificationService
  ) {}

  ngOnInit() {
    this.formPicturesGallery();
    this.imageBaseUrl = `${this.organization.bucket_base_url}/`;
    this.images.forEach((image) => {
      this.httpClient
        .get(image.dimensions.original, { responseType: 'blob' })
        .subscribe({
          next: (res) => {
            this.getImageHash(res).subscribe({
              next: (hash) => (this.imageHashes[image.url] = hash),
              error: (err) => this._notificationService.apiError(err),
            });
          },
          error: (err) => this._notificationService.apiError(err),
        });
    });
  }

  public isSupportedFormat(fileType: string = '') {
    return (
      fileType.includes('jpeg') ||
      fileType.includes('jpg') ||
      fileType.includes('png') ||
      fileType.includes('gif')
    );
  }

  getImageHash(file): Observable<string> {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.onload = () => {
        const wordArray = crypto.lib.WordArray.create(reader.result);
        observer.next(crypto.MD5(wordArray).toString());
        observer.complete();
      };
      reader.readAsArrayBuffer(file);
    });
  }

  addPicture(files) {
    const fileKeys = Object.keys(files);
    if (!fileKeys.length) {
      return;
    }
    const defer = new AsyncSubject<any>();
    defer.subscribe((data) => this.onImageAction.emit(data));

    fileKeys.forEach((key) => {
      const file = files[key];
      if (!this.isSupportedFormat(file?.type)) {
        this.avatar_error = true;
        return;
      }

      this.getImageHash(file).subscribe((hash) => {
        if (
          Object.keys(this.imageHashes).findIndex(
            (url) => this.imageHashes[url] === hash
          ) >= 0
        ) {
          this._notificationService.warn(
            `Image ${file['name']} already exists for this asset.`
          );
          return;
        } else {
          this.avatar_error = false;
          this.uploading_image = true;
          this.imagesUploaded += 1;
          this.images.push({ original_file_name: file['name'] });
          const index: number = this.images.length - 1;
          this._uploadResourceService
            .uploadFile(file, `tmp`, this.organization?.id_hash)
            .subscribe((res) => {
              if (res.complete) {
                this.imageHashes[res.object_key] = hash;
                const pic: any = {
                  url: res.fields.key,
                  uploaded_url: res.fields.key,
                  original_file_name: file['name'],
                };
                this.uploading_image = false;
                this.imagesUploaded -= 1;
                defer.next(this.picturesEdit);

                if (Number(key) === Object.keys(files).length - 1) {
                  defer.complete();
                  this.fileInput.nativeElement.value = '';
                }
                // check if image available on s3 and them add to html
                this.addImageWhenAvailable(pic, 1, index);
              }
            });
        }
      });
    });
  }

  addPicToAssetImages(pic, index) {
    if (typeof index === 'undefined' || index < 0) {
      this.images.push(pic);
    } else {
      this.images[index] = pic;
    }
    this.picturesEdit.push(pic);
    this.formPicturesGallery();
  }

  removePicture(image, $index) {
    this.images.splice($index, 1);
    delete this.imageHashes[image.url];
    if (image.id) {
      this.picturesEdit.push({
        id: image.id,
        _destroy: 1,
      });
    } else {
      this.picturesEdit.splice($index, 1);
    }
  }

  protected setPrimaryImage(image: PictureModel) {
    this.images.forEach((pic) => {
      if (pic.primary) {
        pic.primary = false;
      }
    });
    image.primary = true;
    const primary = this.picturesEdit.find((img) => img.primary);
    const newPrimaryId = image.id;
    if (typeof newPrimaryId !== 'undefined') {
      if (primary) {
        const primaryIndex = this.picturesEdit.indexOf(primary);
        if (newPrimaryId) {
          this.picturesEdit[primaryIndex].id = image.id;
        }
      } else {
        this.picturesEdit.push({
          id: newPrimaryId,
          primary: true,
          tag: null,
        });
      }
    }
  }

  chooseTag(image: PictureModel) {
    this.modalRef = this.modalService.show(AddingTagModalComponent, {
      class: 'modal-holder',
      animated: true,
      initialState: {
        currentTag: image.primary ? 'primary' : image.tag,
      },
    });
    this.modalRef.content.onResult.subscribe((tag) => {
      tag === 'primary'
        ? this.setPrimaryImage(image)
        : this.setTagValue(image, tag);
      this.onImageAction.emit(this.picturesEdit);
    });
  }

  removeTag(image: PictureModel) {
    this.setTagValue(image, null);
    this.onImageAction.emit(this.picturesEdit);
  }

  setTagValue(image: PictureModel, value: any) {
    const existImgIndex = this.picturesEdit.findIndex(
      (el) => el.id === image.id
    );

    if (this.picturesEdit.length && existImgIndex >= 0) {
      delete this.picturesEdit[existImgIndex].primary;
      this.picturesEdit[existImgIndex].tag = value;
    } else {
      this.picturesEdit.push({
        id: image.id,
        tag: value,
        primary: false,
      });
    }
    delete image.primary;
    image.tag = value;
  }

  dropzoneState(state: boolean) {
    this.dropzoneActive = state;
  }

  openGallery(index: number) {
    this.galleryImageIndex = index;
    this.isGalleryOpened = true;
    this._eventService.broadcast(Configs.EVENTS.GALLERY_OPEN, { index: index });
  }

  public formPicturesGallery() {
    this.images =
      this._imageResizingService.getResizedImages(
        this.images,
        this.imageBaseUrl
      ) || [];
  }

  addImageWhenAvailable(pic, retries, index?) {
    this.imageAvailable(this.imageBaseUrl + pic.url).subscribe(
      () => {
        this.addPicToAssetImages(pic, index);
      },
      () => {
        if (retries > 0) {
          setTimeout(() => {
            retries--;
            this.addImageWhenAvailable(pic, retries, index);
          }, 2000);
        } else {
          this.addPicToAssetImages(pic, index);
        }
      }
    );
  }

  imageAvailable(imageLink: string): Observable<any> {
    return this.httpClient
      .request('get', imageLink, { responseType: 'blob' })
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
