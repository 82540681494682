import { DefaultModel } from './default.model';
import { RiskDTO } from '../dto/risk.dto';

export class RiskModel extends DefaultModel<RiskDTO> {
  affect_assets_risks: boolean;
  id: number;
  value: number;
  name: string;
  risk_ranges?: Array<{ id?: number; value: number; description: string }>;
  available?: boolean;
  _destroy?: boolean;
  created_at: string;
  updated_at: string;
  default_value?: number;
  frontend_range?: string;
  description: string;

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
    }
    this.risk_ranges ??= [];
  }

  public get entity_name(): string {
    return 'risk';
  }

  public dto(): RiskDTO {
    return new RiskDTO(this);
  }

  public toString(): string {
    return this.name;
  }
}
