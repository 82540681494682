import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class NavigationService {
  private currentPath: BehaviorSubject<string> = new BehaviorSubject<string>(
    'app.home'
  );
  private currentBreadcrumbs: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );

  private _previousRoute: string;

  public get currentPathObservable(): Observable<string> {
    return this.currentPath.asObservable();
  }

  public get currentBreadcrumbsObservable(): Observable<any> {
    return this.currentBreadcrumbs.asObservable();
  }

  public currentBreadcrumb(value) {
    return this.currentBreadcrumbs.next(value);
  }

  public get previousRoute(): any {
    return this._previousRoute;
  }

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.subscribeToNavigation();
  }

  private subscribeToNavigation() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activeRoute.snapshot),
        map((r) => {
          while (r.firstChild) {
            r = r.firstChild;
          }
          return r;
        })
      )
      .subscribe(() => {
        let route = this.activeRoute.snapshot;
        while (route.firstChild) {
          route = route.firstChild;
        }
        this._previousRoute = this.currentPath.getValue();
        this.currentPath.next(route.data['path']);
      });
  }
}
