import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EventService } from '../../../core/services/event.service';
import { Configs } from '../../../core/constants/configs.constants';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';

@Component({
  selector: 'app-multiselect',
  templateUrl: './o-multiselect.component.html',
  styleUrls: ['./o-multiselect.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OMultiselectComponent),
      multi: true,
    },
  ],
})
export class OMultiselectComponent implements ControlValueAccessor, OnInit {
  @ViewChild('dropdown', { static: true }) dropdown: ElementRef;

  @Input() public set values(newVal: Array<any>) {
    this._values = newVal;

    // if directive combines values with same name - check model
    if (this._values) {
      if (this.isModelExists) {
        this.checkModel();
      }
      this.selectedValues = this.viewValues();
    }
  }
  @Input() oneLevel?: boolean = true;
  @Input() id: string;
  @Input() placeholder: string = '';
  @Input() isDisabled: boolean = false;
  @Input() key: string;
  @Input() as: string;
  @Input() rightSided: boolean;
  @Input() combineValues: boolean;
  @Input() topDirection: boolean;
  @Input() automaticDirection: boolean = false;
  @Input() required: boolean;
  @Input() singleValueSelected: boolean;
  @Input() insideClick: boolean = true;
  @Input() dropdownType: string;
  @Input() userId: number;
  @Input() resetField: string;
  @Input() fieldName: string;
  @Input() disableOptIfItHasField: string = 'disabled';
  @Input() boldOption: string; // if value in field exists the option becomes bold
  @Input() displayDataInOptionsParenthesis: string;
  @Input() returnSingleValueAsNumber: boolean = false;
  @Input() tooltipField: string = '';
  @Input() showSelectAll: boolean;
  @Input() addResetToOptList: boolean;
  @Input() showSearch: boolean = true;

  @Output() onPresetDeleting = new EventEmitter();
  @Output() onPresetChoosing = new EventEmitter();
  @Output() onResetValue = new EventEmitter();
  @Output() onHidden = new EventEmitter();

  public selectedValues: string = '';
  public selectAll: boolean;
  public isSearch: boolean;
  public isOpen: boolean;
  public searchData: Array<any>;
  public keyword: string = '';
  public error: boolean;
  public model: Array<any> = [];
  public selectedOption: any;
  protected _values: Array<any>;
  propagateChange = (_: any) => {};

  public get values(): any[] {
    return this._values || [];
  }

  protected get isModelExists(): boolean {
    return Array.isArray(this.model) && !!this.model?.length;
  }

  constructor(
    protected elementRef: ElementRef,
    protected cd: ChangeDetectorRef,
    protected _eventService: EventService,
    public _featureFlagsService?: FeatureFlagsService
  ) {}

  ngOnInit() {
    this._eventService.on(Configs.EVENTS.PRESET_DELETE, () => this.search());
    this.fieldName = this.fieldName || this.placeholder.replace('All ', '');
  }

  public getPseudo(option: any): string {
    return option[this.as];
  }

  public showTooltip(option: any): boolean {
    if (this.tooltipField.length) {
      return !!option[this.tooltipField]?.length;
    }

    return option[this.as]?.length;
  }

  public getTooltip(option: any): string {
    if (this.tooltipField.length) {
      return option[this.tooltipField];
    }

    return option[this.as];
  }

  writeValue(inputVal: Array<any> | number) {
    // react on model changing
    this.model = typeof inputVal === 'number' ? [inputVal] : inputVal;
    if (Array.isArray(this.model) && this.values.length) {
      this.model = this.model.filter((id) =>
        this.values.find((value) => value[this.key] === id)
      );
    }
    this.selectedValues = this.viewValues();
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  onChange() {
    let result: number[] | string = this.model;

    if (this.singleValueSelected && this.returnSingleValueAsNumber) {
      result = this.model?.length ? this.model[0] : null;
    }

    this.propagateChange(result);
  }

  // get values to show in select for user
  public viewValues(): string {
    // no model or values - show placeholder
    if (!this.values?.length) {
      return;
    }

    const selected = [];
    // find values if directive combines values with same name
    if (Array.isArray(this.model)) {
      if (this.combineValues) {
        const data = this.prepareValues(),
          selectedObj = {};
        this.model.forEach((value) => {
          const item = data.find((dItem) =>
            dItem[this.key].find((key) => key === value)
          );

          if (item) {
            if (!selectedObj[item[this.as]]) {
              selectedObj[item[this.as]] = true;
            }
          }
        });

        for (const field in selectedObj) {
          if (selectedObj.hasOwnProperty(field)) {
            selected.push(field);
          }
        }
      } else {
        // find values to show
        this.model.forEach((value) => {
          const item = this.values.find((dItem) => dItem[this.key] === value);

          if (item) {
            selected.push(item[this.as]);
          }
        });
      }
    } else {
      const item = this.values.find((dItem) => dItem[this.key] === this.model);

      if (item) {
        selected.push(item[this.as]);
      }
    }

    return selected ? selected.join(', ') : null;
  }

  // react on toggle multiselect event
  public toggled(open): void {
    if (!open) {
      this.keyword = null;
      this.error =
        this.required && !this.model?.length && !this.addResetToOptList;
    } else {
      this.searchData = this.prepareValues();
      this.selectAll = this.searchData.length === this.model?.length;
    }

    this.isOpen = open;
  }

  // react on select option event
  selectOption(value, option?) {
    this.prepareModel();
    if (this.combineValues) {
      // if directive combines values with same name to one...
      let select = null;
      value.forEach((id) => {
        // try get index of item in model
        const index = this.model.indexOf(id);
        // detect action once for whole check - select or unselect
        if (select === null) {
          select = index === -1;
        }
        // check to not push nor delete same value twice
        if (select) {
          if (index === -1) {
            this.model.push(id);
          }
        } else if (!select) {
          if (index >= 0) {
            this.model.splice(index, 1);
          }
        }
      });
    } else {
      const index = this.model.indexOf(value);
      // in simple mode just add/delete value from model

      if (index >= 0) {
        if (this.dropdownType !== 'presetsDropdown') {
          if (this.singleValueSelected) {
            return;
          }
          this.model.splice(index, 1);
        }
        this.selectedOption = undefined;
      } else {
        if (this.singleValueSelected) {
          this.model = [];
        }
        this.model.push(value);
        this.selectedOption = option;
      }
    }

    // raise on change event
    this.onChange();

    if (this.dropdownType === 'presetsDropdown') {
      this.onPresetChoosing.emit(value);
    }
    this.singleValueSelected && (this.isOpen = false);
    this.selectedValues = this.viewValues();
    this.selectAll = this.searchData.length === this.model?.length;
  }

  // checks if value selected to mark it
  isSelected(value: any): boolean {
    // if directive combines values with same name to once...
    if (this.combineValues && this.model) {
      // try to find at least one value in model to set item selected
      return value.some((id) => this.model.some((item) => item === id));
    }
    return this.isModelExists && this.model.find((item) => item === value);
  }

  // search in items
  search() {
    this.isSearch = false;
    const values = this.prepareValues();
    // if no or empty keyword - set values to all possible
    if (this.keyword === '') {
      this.searchData = values;
      return;
    }
    // get actual values, compare each 'as'-part with keyword
    this.searchData = [];
    values.forEach((option) => {
      if (
        option[this.as]
          .toString()
          .toLowerCase()
          .includes(this.keyword.toLowerCase())
      ) {
        this.searchData.push(option);
      }
    });
  }

  // checks model to not be undefined
  prepareModel() {
    if (!this.model) {
      this.isSearch = false;
      this.model = [];
    }
  }

  // checks model to hold all values from combined field (if combineValues turned on)
  checkModel() {
    const values = this.prepareValues(),
      addValues = [],
      removeValues = [];

    if (this.combineValues) {
      this.model.forEach((value) => {
        // check if at least one item from combined value present in model
        const item = values.find((dItem) =>
          dItem[this.key].find((key) => key === value)
        );
        // if so, add all values from the combined one to special 'add'-array (except already existed)
        if (item) {
          item[this.key].forEach((iValue) => {
            const iIndex = this.model.indexOf(iValue);
            if (iIndex < 0) {
              addValues.push(iValue);
            }
          });
        }
        // else {
        //   // if item not exist, add value to special 'remove'-array
        //   removeValues.push(value);
        // }
      });

      // remove un-existed values and add new
      // removeValues.forEach(value => {
      //   const index = this.model.indexOf(value);
      //   this.model.splice(index, 1);
      // });

      addValues.forEach((value) => {
        this.model.push(value);
      });
    } else if (!this.oneLevel) {
      let allTags = [];

      this.values.forEach((category) => {
        allTags = allTags.concat(category.tags);
      });
      // then filter tags by ids to find values to show
      allTags.forEach((tag) => {
        const item = this.model.find((dItem) => tag[this.key] === dItem);
        if (!item) {
          // if item not exist, add value to special 'remove'-array
          removeValues.push(tag);
        }
      });
    } else {
      this.model.forEach((value) => {
        // check if at least one item from combined value present in model
        const item = values.find((dItem) => dItem[this.key] === value);
        // if so, add all values from the combined one to special 'add'-array (except already existed)
        if (!item) {
          // if item not exist, add value to special 'remove'-array
          removeValues.push(value);
        }
      });

      // remove un-existed values and add new
      // removeValues.forEach(value => {
      //   const index = this.model.indexOf(value);
      //   this.model.splice(index, 1);
      // });
    }
  }

  // prepares values to work with
  prepareValues(): Array<any> {
    let valuesArr = [];
    if (this.addResetToOptList) {
      valuesArr.push({ [this.key]: undefined, [this.as]: this.placeholder });
    }
    if (this.combineValues) {
      // if directive combines values to one
      // create object with 'key's - 'as'-parts of values (visible one),
      // and 'value's - array of 'key'-parts of values
      const valuesObj = {};
      this.values.forEach((val) => {
        const field = val[this.as];
        if (!valuesObj[field]) {
          valuesObj[field] = [];
        }
        valuesObj[field].push(val[this.key]);
      });
      // then add all fields to array with key - 'as'-part, and 'value' - array of 'key's-parts
      for (const field in valuesObj) {
        if (valuesObj.hasOwnProperty(field)) {
          const obj = {};
          obj[this.as] =
            valuesObj[field].length > 1
              ? `${field} (${valuesObj[field].length})`
              : field;
          obj[this.key] = valuesObj[field];
          valuesArr.push(obj);
        }
      }
    } else {
      valuesArr.push(...this.values);
    }

    return valuesArr;
  }

  removePreset(presetId) {
    this.onPresetDeleting.emit(presetId);
  }

  resetFilter() {
    this.model = [];
    this.onChange();
    this.selectedValues = null;
    this.onResetValue.emit();
  }

  emitHidden() {
    this.onHidden.emit();
  }

  selectAllOptions($event) {
    this.model = [];
    this.selectedValues = '';

    if ($event.target.checked) {
      const selectedOptions = [];
      this.searchData.forEach((option) => {
        if (Array.isArray(option[this.key])) {
          this.model.push(...option[this.key]);
        } else {
          this.model.push(option[this.key]);
        }
        selectedOptions.push(option[this.as]);
      });
      this.selectedValues = selectedOptions.join(', ');
    }

    this.onChange();
  }
}
