import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { BaseStoreService } from './base-store.service';
import { ReportsModel } from '../models/reports.model';

@Injectable()
export class ReportsService extends BaseStoreService<ReportsModel> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, ReportsModel);
    this.resourceName = 'v1/reports';
  }

  public getRanks(params): Observable<ReportsModel> {
    return this._requestService.get('/v1/assets/ranks', params);
  }

  public getRiskRanks(params): Observable<ReportsModel> {
    return this._requestService.get(`/v1/assets/risk_ranks`, params);
  }
}
