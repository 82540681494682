import { DateTime } from 'luxon';
import { ErrorModel } from './error.model';

export class BudgetingOptimalModel {
  fhi_r_index_current: number;
  maintenance: IEndOfLifeModel;
  replacement: IEndOfLifeModel;
  total_assets_count: string;
  blocking_assets_count: string;
  id?: number;
  warnings?: ErrorModel[];
}

interface IEndOfLifeModel {
  amount: number;
  optimal: number;
  type: string;
  used_amount: number;
}

export class BudgetingGraphPointModel {
  budget_adjusted: number;
  budget_optimal: number;
  from: string;
  to: string;
  point: number;

  constructor(data) {
    Object.assign(this, data);
    this.from = DateTime.fromISO(this.from).toISO();
    this.to = DateTime.fromISO(this.to).toISO();
    if (this.budget_adjusted) {
      this.budget_adjusted = parseFloat(this.budget_adjusted.toString());
    }
    if (this.budget_optimal) {
      this.budget_optimal = parseFloat(this.budget_optimal.toString());
    }
  }
}
