import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { Configs } from '../../core/constants/configs.constants';
import { EventService } from '../../core/services/event.service';

@Directive({
  selector: '[appInvalidControlScroll]',
})
export class InvalidControlScrollDirective {
  constructor(
    private el: ElementRef,
    private form: NgForm,
    private _eventService: EventService
  ) {}

  ngOnInit() {
    this._eventService.on(Configs.EVENTS.FORM_ERRORS, () =>
      setTimeout(() => this.onSubmit())
    );
  }

  @HostListener('ngSubmit') onSubmit() {
    if (this.form.invalid) {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('.ng-invalid');

    if (!firstInvalidControl) {
      return;
    }

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth',
    });

    fromEvent(window, 'scroll')
      .pipe(debounceTime(100), take(1))
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
