<section class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header">{{ header }}</h2>
  </header>
  <section
    class="modal-content__body modal-content__body_without-form text-center"
    *ngIf="isLoading"
  >
    <p class="mrgn-b_sm">Please, wait...</p>
    <app-loader></app-loader>
  </section>

  <form
    #presetForm="ngForm"
    (ngSubmit)="submit()"
    (keydown.enter)="$event.preventDefault()"
    [ngClass]="{ hidden: isLoading }"
  >
    <ul
      class="modal-content__list modal-content__body"
      *ngIf="!existedPresetId; else elseBlock"
    >
      <li class="modal-content__item modal-content__item_full-width">
        <label class="xs-100">
          <input
            [(ngModel)]="userInput"
            required
            placeholder="Name"
            name="userInput"
            class="form-block__element"
            #_userInput="ngModel"
            [maxlength]="250"
            [ngClass]="{ 'form-block_error': hasError(_userInput) }"
            type="text"
          />
        </label>
        <p class="error" *ngIf="hasError(_userInput)">
          <ng-container *ngIf="_userInput.errors.required">
            {{ 'validation.required' | translate }}.
          </ng-container>
          <ng-container *ngIf="_userInput.errors.isNotEqual">
            {{ 'validation.invalid_value' | translate }}.
          </ng-container>
        </p>
      </li>
      <li
        *appPermission="'ta_tu_te_ca_pa'"
        class="modal-content__item modal-content__item_full-width"
      >
        <label class="custom-checkbox">
          <input
            class="custom-checkbox__input"
            type="checkbox"
            name="is_occupied"
            #isSharedCheckbox
            [ngModel]="isShared"
            (ngModelChange)="setShared(isSharedCheckbox.checked)"
          />
          <span
            class="custom-checkbox__check custom-checkbox__check_labeled"
          ></span>
          Available for everyone
        </label>
      </li>
    </ul>
    <ng-template #elseBlock>
      <p class="modal-content__message modal-content__body">
        The preset '{{ userInput }}' already exist. Do you want to overwrite it?
      </p>
    </ng-template>
    <div class="buttons-block modal-content__footer">
      <ng-container *ngIf="!existedPresetId; else elseBtnBlock">
        <button
          type="button"
          (click)="cancel()"
          class="btn action-btn action-btn_default"
        >
          <span class="action-btn__icon"
            ><app-svg [href]="'times'"></app-svg
          ></span>
          <span class="action-btn__title">{{ cancelText }}</span>
        </button>

        <button class="btn action-btn action-btn_success">
          <span class="action-btn__icon"
            ><app-svg [href]="'check'"></app-svg
          ></span>
          <span class="action-btn__title">{{ submitText }}</span>
        </button>
      </ng-container>
      <ng-template #elseBtnBlock>
        <button class="btn action-btn action-btn_default">
          <span class="action-btn__icon"
            ><app-svg [href]="'check'"></app-svg
          ></span>
          <span class="action-btn__title">Overwrite</span>
        </button>

        <button
          class="btn action-btn action-btn_success"
          type="button"
          (click)="existedPresetId = null"
        >
          <span class="action-btn__icon"
            ><app-svg [href]="'pencil'" iconClass="icon icon_s"></app-svg
          ></span>
          <span class="action-btn__title">Choose another name</span>
        </button>
      </ng-template>
    </div>
  </form>
</section>
