import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBgJobModel } from 'src/app/core/models/bg-worker.model';
import { BgJobService } from 'src/app/core/services/bg-worker.service';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { HelperService } from 'src/app/core/services/helper.service';
import { ProgressBarServiceService } from 'src/app/core/services/progress-bar-service.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.less'],
})
export class ProgressBarComponent implements OnInit {
  @Input() bgJobModel: IBgJobModel;
  @Input() orgId: string;
  @Output() destroyLoader = new EventEmitter<void>();
  public showSecondPart: boolean = false;
  helper = HelperService;

  constructor(
    private _bgJobService: BgJobService<any>,
    private _notificationService: CustomNotificationService,
    private progressBarService: ProgressBarServiceService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.showSecondPart = true;
    }, 10000);
  }

  public cancelBgJob() {
    this.destroyLoader.emit();
    this.cancelOtherJob(this.bgJobModel.job_uuid, this.orgId);
  }

  private cancelOtherJob(jobUuid, orgId) {
    this._bgJobService.cancelBgJob(jobUuid, orgId).subscribe({
      next: () => {
        if (this.bgJobModel.name === 'forecast_create') {
          const forecastId =
            this.bgJobModel.details?.forecast_id ?? this.bgJobModel.entity_id;
          this._bgJobService.cancelForecast(forecastId).subscribe({
            next: () => {},
            error: (err) => this._notificationService.apiError(err),
          });
        }
        this.progressBarService.startProgress(null);
      },
      error: (err) => this._notificationService.apiError(err),
    });
  }

  public getMessage(): string {
    const { message, name } = this.bgJobModel || {};

    if (typeof message === 'string' && message) {
      return message;
    }

    return name === 'forecast_update'
      ? 'Forecast Updating...'
      : 'Forecast Uploading...';
  }

  public getPercentage(): string {
    const { message } = this.bgJobModel || {};
    return typeof message === 'number' ? `${message}%` : '';
  }
}
