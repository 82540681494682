<div class="breadcrumbs-bar flex">
  <div class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap">
    <!-- This file could not read feature flag service -->
    <ng-container *appPermission="'ta_tu_te_ca_pa'">
      <button
        type="button"
        (click)="lockAccount()"
        *ngIf="!isCurrent && canEdit && !model?.blocked"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'lock'" iconClass="icon icon_s"></app-svg
        ></span>
        <span class="action-btn__title">Lock Account</span>
      </button>

      <button
        type="button"
        (click)="unlockAccount()"
        *ngIf="!isCurrent && canEdit && model?.blocked"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'unlock'" iconClass="icon icon_s"></app-svg
        ></span>
        <span class="action-btn__title">Unlock Account</span>
      </button>

      <!--|| isAdmins-->
      <button
        type="button"
        (click)="deleteAction()"
        *ngIf="!isCurrent && canEdit"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'trash'" iconClass="icon icon_s"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.delete' | translate
        }}</span>
      </button>
    </ng-container>

    <button
      type="button"
      (click)="editMode()"
      *ngIf="canEdit || isCurrent"
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"
        ><app-svg [href]="'pencil'" iconClass="icon icon_s"></app-svg
      ></span>
      <span class="action-btn__title">{{ 'buttons.edit' | translate }}</span>
    </button>

    <button
      type="button"
      (click)="changePassword()"
      *ngIf="
        isCurrent &&
        !this._featureFlagsService.isFeatureEnabled('OR_12510_Auth0_Login')
      "
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"
        ><app-svg [href]="'key'" iconClass="icon icon_s"></app-svg
      ></span>
      <span class="action-btn__title">{{
        'labels.user.change_password' | translate
      }}</span>
    </button>
  </div>
</div>

<article class="board mrgn-b" *ngIf="model">
  <header class="board__header">
    <h2 class="board__title">User Details</h2>
  </header>
  <div class="flex flex-a-stretch">
    <section class="xs-25 content-details">
      <h1 class="content-title text-center mrgn-b_sm">{{ model.full_name }}</h1>
      <h2 class="content-subtitle text-center mrgn-b_sm">
        {{ model?.sale_role_text || model?.roles_human }} (ID {{ model.id }})
      </h2>
      <h2 class="content-subtitle text-center mrgn-b_sm">
        {{ model.position }}
      </h2>
      <figure class="content-gallery text-center">
        <img *ngIf="model.picture && this.imageUrl" [src]="this.imageUrl" alt="User Avatar" />
      </figure>
    </section>
    <ul class="xs-75 edit-list_sm edit-list_border-left">
      <li class="edit-list__row">
        <h3 class="edit-list__title xs-24">
          {{ 'labels.user.bio' | translate }}
        </h3>
        <p class="edit-list__value text-semibold">{{ model.bio }}</p>
      </li>
      <li class="edit-list__row">
        <h3 class="edit-list__title xs-24">{{ 'email' | translate }}</h3>
        <p class="edit-list__value text-semibold">
          <a href="mailto:{{ model.email }}" class="link">{{ model.email }}</a>
        </p>
      </li>
      <li class="edit-list__row">
        <h3 class="edit-list__title xs-24">
          {{ 'labels.user.phone' | translate }}
        </h3>
        <p class="edit-list__value text-semibold">
          {{ model.phone | phoneNumber }}
        </p>
      </li>
      <li class="edit-list__row">
        <h3 class="edit-list__title xs-24">{{ 'address' | translate }}</h3>
        <p class="edit-list__value text-semibold">
          {{ model.address | address }}
        </p>
      </li>
      <li class="edit-list__row">
        <h3 class="edit-list__title xs-24">
          {{ 'labels.user.position' | translate }}
        </h3>
        <p class="edit-list__value text-semibold">{{ model.position }}</p>
      </li>
      <li class="edit-list__row">
        <h3 class="xs-24 edit-list__title">{{ 'registered' | translate }}</h3>
        <p class="edit-list__value text-semibold">
          {{ model?.created_at | dateFormat }}
        </p>
      </li>
      <li
        [hidden]="_featureFlagsService.isFeatureEnabled('OR_12510_Auth0_Login')"
        class="edit-list__row"
      >
        <h3 class="edit-list__title xs-24">
          {{ 'labels.user.last_login' | translate }}
        </h3>
        <p class="edit-list__value text-semibold">
          {{ model?.last_sign_in_at | dateFormat: 'MMM d, yyyy h:mm a' }}
        </p>
      </li>
      <li
        class="edit-list__row"
        *ngIf="
          organizations &&
          (model?.role_name === 'tenant_engineer' ||
            model?.role_name == 'partner_admin' ||
            model?.role_name == 'partner_engineer') &&
          model?.organization_ids?.length
        "
      >
        <h3 class="edit-list__title xs-24">
          {{ getOrganizationLabelText(model) | translate }}
        </h3>
        <p class="edit-list__value text-semibold">
          <span *ngFor="let id of model?.organization_ids; let last = last">
            {{ organizations[id].name }}{{ !last ? ', ' : ' ' }}
          </span>
        </p>
      </li>
      <li class="edit-list__row" *ngIf="model?.signature">
        <h3 class="edit-list__title xs-24">Signature</h3>
        <figure class="edit-list__value text-semibold">
          <img
            height="150px"
            width="300px"
            class="scaled-img"
            src="{{ getSignature() }}"
            alt="User Signature"
          />
        </figure>
      </li>
    </ul>
  </div>
</article>

<section class="content" *ngIf="model?.default_facilities?.length">
  <header class="header">
    <h2 class="header__title">{{ 'titles.default_facilities' | translate }}</h2>
  </header>
  <ul class="content-details_sm">
    <li
      class="board"
      *ngFor="let facility of model?.default_facilities; let _i = index"
      [ngClass]="{ 'mrgn-b': _i !== model?.default_facilities.length - 1 }"
    >
      <header class="board__header">
        <h2 class="board__title">{{ 'location' | translate }} {{ _i + 1 }}</h2>
      </header>
      <ul class="edit-list_sm">
        <li class="edit-list__row">
          <h3 class="edit-list__title xs-32">{{ 'location' | translate }}</h3>
          <p class="edit-list__value text-semibold">
            {{ facility.location_name }}
          </p>
        </li>
        <li class="edit-list__row">
          <h3 class="edit-list__title xs-32">{{ 'buildings' | translate }}</h3>
          <p class="edit-list__value text-semibold">
            {{ facility.building_names.join(', ') }}
          </p>
        </li>
      </ul>
    </li>
  </ul>
</section>
