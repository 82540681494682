import { AddressModel } from './address.model';
import { DefaultIdNameModel } from './default-id-name.model';
import { DefaultModel } from './default.model';
import { FacilityCategoryModel } from './facility-category.model';
import { HelperService } from '../services/helper.service';
import { OrganizationDTO } from '../dto/organization.dto';
import { ProjectFeesModel } from './project-fees-model';
import { ProjectFundingAllocation } from './funding-and-allocation.model';
import { environment } from '../../../environments/environment';

export class OrganizationModel extends DefaultModel<OrganizationDTO> {
  id?: number;
  id_hash?: string;
  name?: string;
  picture?: string;
  email?: string;
  phone?: number;
  currency?: string;
  bucket_name?: string;
  fiscal_year_start_at?: string;
  business_type_id?: number;
  labour_hour_rate?: number;
  labor_utilization_modifier?: number;
  cmms_start_at?: string;
  cmms_start_on?: string;
  cmms_client_name?: string;
  any_assets?: boolean;
  asset_udfs?: any;
  asset_udfs_attributes?: Array<any>;
  available_modules?: Array<string>;
  building_udfs?: any;
  building_udfs_attributes?: Array<any>;
  project_udfs?: Array<{
    _delete?: boolean;
    id: string;
    name: string;
  }>;
  created_at?: string;
  updated_at?: string;
  creation_status?: number;
  creation_message?: string;
  autogenerate_asset_number?: boolean;
  address?: AddressModel;
  business_types?: Array<{ id: number; name: string }>;
  address_attributes?: AddressModel;
  modules?: Array<OrganizationModuleModel> = [];
  project_occupation_modifier?: number;
  project_contingency_modifier?: number;
  project_fees?: ProjectFeesModel;
  assets_with_errors?: boolean;
  test_organization?: boolean;
  degradation_modifier?: number;
  settings: { key: string; value: any }[];
  time_zone: string;
  categories?: { category: FacilityCategoryModel }[];
  use_parts_cost: boolean;
  inflation_rate: number;
  funding_source_threshold: number;
  project_type_udfs: DefaultIdNameModel[];
  project_risk_udfs: DefaultIdNameModel[];
  area_served_enabled: boolean;
  import_capital_assets_only: boolean;
  pm_schedule?: boolean;
  customer_identifier: string;
  customer_identifier_presence: boolean;
  project_funding_allocation?: ProjectFundingAllocation[];

  constructor(model?) {
    super();
    this.customer_identifier_presence = !!model?.customer_identifier;
    this.business_types = [
      {
        id: 2,
        name: 'Education',
      },
      {
        id: 4,
        name: 'Government',
      },
      {
        id: 1,
        name: 'Healthcare',
      },
      {
        id: 3,
        name: 'Manufacturing',
      },
      {
        id: 0,
        name: 'Other',
      },
    ];

    if (model) {
      Object.assign(this, model);
    }

    this.setOptionalModules();

    this.address ||= {};
    this.address_attributes = this.address;
    this.available_modules ||= [];
    this.project_type_udfs ||= [];
    this.project_risk_udfs ||= [];

    this.available_modules.push('fhi');
    this.asset_udfs_attributes = model?.asset_udfs
      ? HelperService.convertEnumToArrayWithoutSorting(model.asset_udfs, [
          'id',
          'name',
        ])
      : [];
    this.building_udfs_attributes = model?.building_udfs
      ? HelperService.convertEnumToArray(model.building_udfs, ['id', 'name'])
      : [];
    this.project_funding_allocation = model?.project_funding_allocation;
    this.project_occupation_modifier =
      HelperService.convertFloatToPercent(model?.project_occupation_modifier) ||
      0;
    this.project_contingency_modifier =
      HelperService.convertFloatToPercent(
        model?.project_contingency_modifier
      ) || 0;
    this.project_udfs = model?.project_udfs
      ? HelperService.convertEnumToArrayWithoutSorting(model.project_udfs, [
          'id',
          'name',
        ])
      : [];

    this.project_risk_udfs = model?.project_risk_udfs
      ? HelperService.convertEnumToArray(model.project_risk_udfs, [
          'id',
          'name',
        ])
      : [];
    this.project_type_udfs = model?.project_type_udfs
      ? HelperService.convertEnumToArray(model.project_type_udfs, [
          'id',
          'name',
        ])
      : [];
    this.project_risk_udfs.unshift({ id: null, name: 'None' });
    this.project_type_udfs.unshift({ id: null, name: 'None' });

    if (model?.project_fees) {
      Object.keys(model.project_fees).forEach((key) => {
        this.project_fees[key] = HelperService.convertFloatToPercent(
          model.project_fees[key]
        );
      });
    }
    this.labor_utilization_modifier = model?.labor_utilization_modifier
      ? HelperService.convertFloatToPercent(model.labor_utilization_modifier)
      : 100;
    this.labour_hour_rate = model?.labour_hour_rate
      ? parseFloat(model.labour_hour_rate)
      : null;
  }

  private setOptionalModules(): void {
    const optionalModules: Array<OrganizationModuleModel> = [
      { key: 'fhie', name: 'FHIE' },
      { key: 'fhim', name: 'FHIM' },
      { key: 'fhir', name: 'FHIR' },
    ];

    this.modules = optionalModules.map((item) => {
      item.checked = this.hasModule(item.key);
      return item;
    });
  }

  public hasModule(key): boolean {
    if (!this.available_modules) {
      return false;
    }
    return this.available_modules.indexOf(key) > -1;
  }

  public toString(): string {
    return this.name;
  }

  public dto(): OrganizationDTO {
    return new OrganizationDTO(this);
  }

  public get business_type_name() {
    const search = this.business_types.filter(
      (item) => item.id === this.business_type_id
    );
    if (search.length) {
      return search[0].name;
    }
    return null;
  }

  public get bucket_base_url(): string {
    return `${environment.amazon.aws_url}/${this.bucket_name}`;
  }

  public get entity_name(): string {
    return 'organization';
  }
}

export interface OrganizationModuleModel {
  key: string;
  name: string;
  checked?: boolean;
}
