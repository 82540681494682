import { Pipe, PipeTransform } from '@angular/core';

import { CURRENCY_LIST } from 'src/app/core/constants/collections.constants';

@Pipe({
  name: 'currencyName',
})
export class CurrencyNamePipe implements PipeTransform {
  currencyMap: Map<string, string>;
  constructor() {
    this.currencyMap = new Map(
      CURRENCY_LIST.map(({ name, value }) => [value, name])
    );
  }
  public transform(currency: string): string {
    return this.currencyMap.get(currency) ?? '--';
  }
}
