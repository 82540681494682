import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorsConstants } from '../../../core/constants/colors.constants';
import { OMultiselectComponent } from '../o-multiselect/o-multiselect.component';
import { EventService } from '../../../core/services/event.service';

@Component({
  selector: 'app-fa-multiselect',
  templateUrl: './fa-multiselect.component.html',
  styleUrls: [
    '../o-multiselect/o-multiselect.component.less',
    './fa-multiselect.component.less',
  ],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FaMultiselectComponent),
      multi: true,
    },
  ],
})
export class FaMultiselectComponent
  extends OMultiselectComponent
  implements ControlValueAccessor
{
  @Input() iconClass?: string = '';
  @Input() disableTopLevel?: boolean = false;
  @Input() selectAllEnabled?: boolean = false;
  @Input() deselectAllEnabled?: boolean = false;
  @Output() public isOpened = new EventEmitter<any>();

  public colorSchemes = ColorsConstants.UFA_COLORSCHEMES_TEXT;

  constructor(
    protected elementRef: ElementRef,
    protected cd: ChangeDetectorRef,
    protected _eventService: EventService
  ) {
    super(elementRef, cd, _eventService);
  }

  // get values to show in select for user
  public viewValues(): string {
    // no model or values - show placeholder
    if (!this.isModelExists) {
      return this.placeholder;
    }
    if (!this.values?.length) {
      return this.placeholder;
    }
    const selected = [];
    if (this.oneLevel) {
      this.model.forEach((value) => {
        const item = this.values.find((dItem) => dItem[this.key] === value);
        if (item) {
          selected.push(item[this.as]);
        }
      });
    } else {
      // extract all tags list from categories
      let allTags = [];

      this.values.forEach((category) => {
        allTags = allTags.concat(category.tags);
      });
      // then filter tags by ids to find values to show
      allTags.forEach((tag) => {
        const item = this.model.find((dItem) => tag[this.key] === dItem);
        if (item) {
          selected.push(tag[this.as]);
        }
      });
    }
    return selected.length ? selected.join(', ') : null;
  }

  toggled(open) {
    super.toggled(open);
    this.isOpened.emit(open);
  }

  // react on select option event
  selectCategory(value) {
    this.prepareModel();
    const currentCategoryTags = this.getAllCategoryTags(value);
    let categoryWasSelected = false;
    currentCategoryTags.forEach((tag) => {
      const index = this.model.indexOf(tag);
      if (index >= 0) {
        categoryWasSelected = true;
        this.model.splice(index, 1);
      }
    });
    if (!categoryWasSelected) {
      currentCategoryTags.forEach((tag) => {
        this.model.push(tag);
      });
    }
    // raise on change event
    this.onChange();
  }

  getAllCategoryTags(catId) {
    const currentCategory = this.values.filter((item) => item.id === catId);
    return currentCategory[0]['tags'].map((item) => item.id);
  }

  selectTag(value) {
    this.prepareModel();
    const index = this.model.indexOf(value);
    if (index >= 0) {
      this.model.splice(index, 1);
    } else {
      this.model.push(value);
    }

    // raise on change event
    this.onChange();
  }

  // checks if value selected to mark it
  isCatSelected(value) {
    if (typeof this.model === 'undefined') {
      this.model = [];
    }
    const thisCatTags = this.getAllCategoryTags(value);
    let tagsSelected = 0;
    thisCatTags.forEach((tag) => {
      if (this.model.indexOf(tag) > -1) {
        tagsSelected++;
      }
    });
    if (tagsSelected === thisCatTags.length) {
      return 'facility__option_active';
    }
    if (tagsSelected) {
      return 'facility__option_partially';
    }
    if (!tagsSelected) {
      return '';
    }
  }

  isTagSelected(value): boolean {
    // if directive combines values with same name to once...

    return this.isModelExists && this.model.find((item) => item === value);
  }

  // prepares values to work with
  prepareValues(): Array<any> {
    let valuesArr = this.values || [];
    // remove empty categories
    if (!this.oneLevel) {
      valuesArr = valuesArr.filter((value) => value.tags.length);
    }
    return valuesArr;
  }

  checkAll(): void {
    this.model.splice(0, this.model.length);
    this.values.forEach((value) => {
      this.model.push(value[this.key]);
    });
    this.onChange();
  }

  uncheckAll(): void {
    this.model.splice(0, this.model.length);
    this.onChange();
  }

  public writeValue(inputVal: Array<any> | number): void {
    this.model = typeof inputVal === 'number' ? [inputVal] : inputVal;
    this.selectedValues = this.viewValues();
  }
}
