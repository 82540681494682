import { DefaultModel } from './default.model';
import { AssetTypeExtendedDTO } from '../dto/asset-type-extended.dto';
import { AssetTypeRiskModel } from './asset-type-risk.model';
import { ProjectExpensesModel } from './project-expenses-model';
import { DefaultIdNameModel } from './default-id-name.model';
import { FrequencyBreakdownModel } from './frequency-breakdown.model';

export interface AliasModel {
  aliases?: DefaultIdNameModel[];
  id?: number;
  name?: string;
  _destroy?: number;
}

export class AssetTypeExtendedModel extends DefaultModel<AssetTypeExtendedDTO> {
  aha_ashrae_life: number;
  aliases?: Array<AliasModel>;
  classification: DefaultIdNameModel;
  compliance: boolean;
  code: number;
  cost: number;
  cost_estimate: number;
  created_at: string;
  currentRiskValue?: number;
  customized?: {
    name?: string;
    project_udfs?: any;
  };
  experiential_cost_estimate: string;
  experiential_life: number;
  experiential_pm_frequency: string;
  fh_life?: number;
  funding_source?: DefaultIdNameModel;
  group: DefaultIdNameModel;
  id: number;
  isOpened?: boolean;
  max_quantity: number;
  min_quantity: number;
  name: string;
  pm_frequency: number;
  pm_frequency_breakdown: FrequencyBreakdownModel;
  project_expenses: ProjectExpensesModel;
  project_udfs: any;
  recommended_pm_frequency: string;
  risks?: Array<AssetTypeRiskModel>;
  rs_means_cost_estimate: string;
  rs_means_life: number;
  rs_means_pm_frequency: string;
  specialty: DefaultIdNameModel;
  subgroup: DefaultIdNameModel;
  tier?: DefaultIdNameModel;
  total_risk_rank?: number;
  trade: DefaultIdNameModel;
  uar_customized_attributes?: {
    name?: string;
    project_udfs?: any;
  };
  units: string;
  universal_attributes?: AssetTypeExtendedModel;
  updated_at: string;
  vendor_cost_estimate?: string;
  warnings?: Array<any>;
  uar_asset_type_id?: number;
  duplicate?: boolean;

  constructor(data?) {
    super();
    this.compliance = false;
    if (data) {
      Object.assign(this, data);
    }
    this.classification ||= { id: null };
    this.subgroup ||= { id: null };
    this.group ||= { id: null };
    this.funding_source ||= { id: null };
    this.risks ||= [];
    this.pm_frequency_breakdown ||= {};
    this.project_expenses ||= {};
    if (this.min_quantity) {
      this.min_quantity = parseFloat(this.min_quantity.toString());
      this.max_quantity = parseFloat(this.max_quantity.toString());
    }
    this.tier ||= { id: null, name: '' };
  }

  public get entity_name(): string {
    return 'asset_type';
  }

  public toString(): string {
    return this.name;
  }

  public dto(): AssetTypeExtendedDTO {
    return new AssetTypeExtendedDTO(this);
  }
}
