import { Component, Input, ViewChild } from '@angular/core';

import { AttachmentModel } from '../../../core/models/attachment.model';
import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { NgForm } from '@angular/forms';
import { OrganizationModel } from '../../../core/models/organization.model';
import { UploadResourceService } from '../../../core/services/upload-resource.service';

@Component({
  selector: 'app-building-action-modal',
  templateUrl: './building-action-modal.component.html',
})
export class BuildingActionModalComponent extends BaseModalComponent {
  @Input() org: OrganizationModel;
  @Input() set model(value) {
    this._model = Object.assign({}, value);
  }
  @Input() title?: string = 'Upload';

  @ViewChild('actionForm', { static: true }) declare tdForm: NgForm;

  private _model: AttachmentModel;
  isCreate: boolean;

  get model(): AttachmentModel {
    return this._model;
  }

  constructor(
    protected modalInstance: BsModalRef,
    protected _uploadResourceService: UploadResourceService,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(modalInstance, _notificationService, _eventService);
  }

  ngOnInit() {
    this.isTemplateDriven = true;
    this.isCreate = true;
    if (typeof this.model !== 'undefined') {
      this.isCreate = false;
    } else {
      this.model = new AttachmentModel();
    }
  }

  submit() {
    this.markFormGroupTouched();

    if (this.tdForm.invalid) {
      return;
    }
    this.modalInstance.hide();
    this.onResult.next({
      id: this.model.id,
      description: this.model.description,
      uri: this.model.uri,
    });
  }
}
