import { AreaServeModel } from './area-serve.model';
import { AssessmentModel } from './assessment.model';
import { AssetDTO } from '../dto/asset.dto';
import { AssetRiskModel } from './asset-risk.model';
import { AssetTypeExtendedModel } from './asset-type-extended.model';
import { CalculationErrorsModel } from './calculation-errors-model';
import { DateTime } from 'luxon';
import { DefaultIdNameModel } from './default-id-name.model';
import { DefaultModel } from './default.model';
import { FrequencyBreakdownModel } from './frequency-breakdown.model';
import { LocationModel } from './location.model';
import { PictureModel } from './picture.model';
import { PmTemplateModel } from './pm-template.model';
import { RiskModel } from './risk.model';

export interface IAssetComment {
  author: { id: number; name: string };
  body: string;
  created_at: string;
  editor?: { id: number; name: string };
  id?: number;
  edited_at: string;
}

export interface IVirtualAsset {
  asset_type_id?: number;
  location_id?: number;
  building_id?: number;
  quantity?: number;
  asset_cost?: number;
  asset_name?: string;
  cost_center?: string;
  funding_source_id?: any;
  ignore_fs_threshold?: boolean;
}

export interface IAreaServedModel {
  id?: number;
  name?: string;
  description?: string;
}

export interface IEmitterModel {
  id?: number;
  last_mwo?: Date | string;
  next_mwo?: Date | string;
  current_mwo?: Date | string;
}

interface DataPathName {
  id: number;
  name: string;
  current_level: number;
  asset_number?: string;
}
export class AssetModel extends DefaultModel<AssetDTO> {
  parent_asset_number: string;
  assetReplacementCost: number;
  area_served?: AreaServeModel;
  id: number;
  uuid: string;
  name: string;
  cost_center: string;
  title?: string;
  asset_number: string;
  asset_type?: AssetTypeExtendedModel;
  barcode: string;
  location_id: number;
  location: LocationModel;
  building_id: number;
  building: LocationModel;
  zone_id?: number;
  zone?: DefaultIdNameModel;
  ignore_fs_threshold: boolean;
  floor_id?: number;
  floor?: LocationModel;
  room_id?: number;
  room?: LocationModel;
  floor_name?: string;
  room_name?: string;
  zone_name?: string;
  manufacturer: string;
  trade: DefaultIdNameModel;
  description?: string;
  quantity: number;
  fh_cost_estimate: number;
  total_risk_rank: number;
  rs_means_life?: number;
  aha_ashrae_life: number;
  experiential_life: number;
  fh_pm_frequency: number;
  assets_repository_id?: number;
  fhie_score: number;
  fhim_r_index: number;
  fhir_r_index: number;
  fhi_r_index: number;
  lifetime_end_regular: string;
  lifetime_end_adjusted: string;
  assets_risks: Array<AssetRiskModel>;
  last_assessment?: AssessmentModel;
  assessment?: AssessmentModel;
  pictures?: Array<PictureModel>;
  pictures_edit?: Array<PictureModel>;
  installed_on?: string;
  created_at?: Date;
  updated_at?: Date;
  archived_at?: Date;
  frontend_image?: string;
  primary_picture?: any;
  asset_pictures?: any;
  model_number: string;
  serial_number: string;
  excluded_custom_fields: Array<string>;
  complete?: boolean;
  project_costs?: {
    expenses_sum?: string;
    contingency?: string;
    regional_value?: string;
    replacement_cost?: string;
    fees: string;
  };
  project_status: string;
  calculation_errors?: Array<CalculationErrorsModel>;
  pm_frequency_breakdown: FrequencyBreakdownModel;
  udfs: any;
  verify?: boolean;
  verify_notes?: string;
  cii: number;
  associated?: boolean;
  area_served_id: number;
  fhi_da_r_index: number;
  fhie_da_index: number;
  fhie_ds_index: number;
  fhir_c_index: number;
  fhim_c_index: number;
  template?: PmTemplateModel;
  emitter?: IEmitterModel;
  fhie_c_index: number;
  first_service_log: string;
  committed_on?: {
    id: number;
    name: string;
  };
  deleted: boolean;
  funding_source: DefaultIdNameModel;
  location_name: string;
  building_name: string;
  risks: RiskModel[];
  made_incomplete_by?: DefaultIdNameModel;
  is_parent: boolean;
  children: any = [];
  is_expended: boolean = false;
  pinned: boolean = false;
  data_path_names: DataPathName[];
  data_path: string[];

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
      this.quantity = model.quantity ? parseFloat(model.quantity) : null;
      this.cii = model.cii ? parseFloat(model.cii) : null;
      this.fh_pm_frequency = model.fh_pm_frequency
        ? parseFloat(model.fh_pm_frequency)
        : null;
      this.parent_asset_number = model.parent_asset_number
      this.installed_on = model.installed_on
        ? DateTime.fromISO(model.installed_on).toISO()
        : null;
      this.fh_cost_estimate = Math.round(model.fh_cost_estimate);
    }
    this.pictures ||= [];
    this.asset_type ||= { id: null } as AssetTypeExtendedModel;
    this.funding_source ||= { id: null };
    this.udfs ||= {};
    this.pictures_edit = [];
    this.assets_risks ||= [];
    this.area_served ||= { ra_modifier: 0, uc_modifier: 0 } as AreaServeModel;
    this.area_served_id ||= 1;
    // this array need temporary for backward compatibility after adding Model Number and Serial Number to the  asset details
    this.excluded_custom_fields = ['model_number', 'serial_number'];
  }

  public get entity_name(): string {
    return 'asset';
  }

  public dto(): AssetDTO {
    return new AssetDTO(this);
  }

  public toString(): string {
    return this.name || 'Incomplete asset';
  }
}
