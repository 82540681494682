import { AddressModel } from './address.model';
import { environment } from '../../../environments/environment';
import { ClientRoles, Roles, TenantRoles } from '../constants/roles.constant';
import { DefaultModel } from './default.model';
import { UserDTO } from '../dto/user.dto';
import { OrganizationModel } from './organization.model';

export interface IUserFacilities {
  location_id: number;
  building_ids: number[];
  location_name?: string;
  building_names?: string[];
}

export class UserModel extends DefaultModel<UserDTO> {
  active_for_authentication: boolean;
  address: AddressModel;
  address_attributes?: AddressModel;
  blocked: number;
  created_at: string;
  bio: string;
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  invitation_accepted: boolean;
  invited: boolean;
  last_name: string;
  last_sign_in_at: string;
  organization_ids: Array<string>;
  phone: string;
  picture: string;
  position: string;
  role_name: string;
  sale_role_text: string;
  signature: string;
  updated_at: string;
  isResendingInvitation?: boolean;
  organization: OrganizationModel;
  roles_available: Array<{ key: string; title: string }>;
  isAvatarChanged?: boolean;
  name?: any;
  eam?: {
    eam_role_name: string;
    organization_hash?: string;
  };
  default_facilities: IUserFacilities[];
  facilities_attributes: IUserFacilities[];
  time_zone: string;
  first_login: boolean;

  constructor(data?) {
    super();
    if (data) {
      Object.assign(this, data);
    }
    if (!this.is_tenant && this.organization) {
      this.organization = new OrganizationModel(this.organization);
    }
    this.address ||= {};
    this.address_attributes = this.address;
    this.roles_available = (this.is_tenant ? TenantRoles : ClientRoles).map(
      (key) => ({
        key: key,
        title: Roles[key].name,
      })
    );
    this.eam ||= { eam_role_name: null };
  }

  public get_avatar(): string {
    return this.picture
      ? `${environment.assets_base}/${this.picture}`
      : '../../../assets/img/no-avatar.png';
  }

  public toString(): string {
    return this.full_name;
  }

  public dto(): UserDTO {
    return new UserDTO(this);
  }

  public hasRole(role: string): boolean {
    return this.role_name === role;
  }

  public get is_tenant(): boolean {
    return TenantRoles.some((key) => this.hasRole(key));
  }

  public get is_inactive(): boolean {
    return this.blocked === 1 || this.is_pending;
  }

  public get is_pending(): boolean {
    return this.invited && !this.invitation_accepted;
  }

  public get roles_human(): string {
    return Roles[this.role_name]?.name;
  }

  public get entity_name(): string {
    return 'user';
  }
}
