import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
@Component({
  selector: 'lib-ag-checkbox-toggle',
  template: `
    <label class="custom-toggle">
      <input
        type="checkbox"
        class="custom-toggle__input custom-toggle__input_lg"
        (change)="toggleCheckbox($event)"
        [checked]="params.isCheckboxChecked(params.data)"
        [disabled]="params.isDisabled(params.data)"
      />
      <span class="custom-toggle__check custom-toggle__check_lg">
        <span class="custom-toggle__toggler custom-toggle__toggler_lg"
          >&nbsp;</span
        >
      </span>
    </label>
  `,
  styles: [
    `
      .custom-toggle {
        display: flex;
      }
    `,
  ],
})
export class AgCheckboxToggleComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  toggleCheckbox(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.params.handleComparisonToggle(this.params.data, checked);
  }

  refresh(): boolean {
    return false;
  }
}
