export class FHIModuleHistoryModel {
  date: string;
  c_index: number;
  r_index: number;
  l_index: number;
  history_type: string;
  point_type: boolean;

  constructor(model: FHIApiModuleHistoryModel) {
    this.date = model.created_at ? model.created_at : model.date;
    this.c_index = model.c_index;
    this.r_index = model.r_index;
    this.l_index = model.l_index;
    this.history_type = this.history_type
      ? model.history_type.replace('History', '').toLowerCase()
      : '';
    this.point_type = model.point_type;
  }
}

export interface FHIHistoryModel {
  fhim?: Array<FHIModuleHistoryModel>;
  fhi_ds?: Array<FHIModuleHistoryModel>;
  fhir_ds?: Array<FHIModuleHistoryModel>;
  fhie_ds?: Array<FHIModuleHistoryModel>;
  fhi_da?: Array<FHIModuleHistoryModel>;
  fhir_da?: Array<FHIModuleHistoryModel>;
  fhie_da?: Array<FHIModuleHistoryModel>;
}

export interface FHIApiModuleHistoryModel {
  c_index?: number;
  date: string;
  created_at?: string;
  history_type?: string;
  point_type: boolean;
  l_index?: number;
  r_index?: number;
}

export interface FHIApiHistoryModel {
  fhi: Array<FHIApiModuleHistoryModel>;
  fhim: Array<FHIApiModuleHistoryModel>;
  fhir?: Array<FHIApiModuleHistoryModel>;
  fhie?: Array<FHIApiModuleHistoryModel>;
}
