import { DefaultDTO } from './default.dto';
import { DefaultIdNameModel } from '../models/default-id-name.model';

export class PmTemplateDTO extends DefaultDTO {
  id?: number;
  name: string;
  frequency: number;
  schedule_type: number;
  trade_id: number;
  performance_window: number;
  task_ids?: Array<number>;
  tasks?: Array<string>;
  isOpened?: boolean;
  trade?: DefaultIdNameModel;
  assigned_assets_ids?: number[];
  unassigned_assets_ids?: number[];

  constructor(model) {
    super();
    Object.assign(this, model);
    this.trade_id = model.trade.id;
    delete this.trade;
    if (model.tasks) {
      this.tasks = model.tasks.map((item) => item.description);
    }
  }
}
