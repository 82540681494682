import { Injectable } from '@angular/core';
import { FeatureFlagsService } from './feature-flags.service';

@Injectable()
export class DuplicateModelService<M> {
  private newModel: any;
  private fieldsToRemoveInNewModel = {
    asset: [
      'asset_number',
      'first_service_log',
      'name',
      'barcode',
      'pictures',
      'installed_on',
      'id',
    ],
    asset_type: ['id', 'name', 'created_at', 'code'],
  };

  constructor(private _featureFlagsService: FeatureFlagsService) {
    if (
      !this._featureFlagsService.isFeatureEnabled(
        'OR_12916_update_uar_car_relation'
      )
    ) {
      this.fieldsToRemoveInNewModel.asset_type.push('universal_attributes');
    }
  }

  public setNewModel(model: M) {
    if (!model) {
      this.newModel = null;
      return;
    }

    this.newModel = Object.assign({}, model);
    const fieldsToDelete = this.fieldsToRemoveInNewModel[model['entity_name']];
    const objectsToDelete = { assets_risks: 'id', risks: 'id' };
    if (fieldsToDelete && fieldsToDelete.length) {
      fieldsToDelete.forEach((field) => {
        if (this.newModel[field]) {
          delete this.newModel[field];
        }
      });
    }
    Object.keys(objectsToDelete).forEach((key) => {
      if (this.newModel[key]) {
        this.newModel[key].forEach((item) => {
          if (item[objectsToDelete[key]]) {
            delete item[objectsToDelete[key]];
          }
        });
      }
    });
  }

  public getNewModel() {
    return this.newModel;
  }
}
