<div class="field-edit">
  <div *ngIf="!editing">
    <div
      *ngIf="valueChanged"
      class="flex_nowrap"
      [ngClass]="{ flex_reverse: editPosition === 'left' }"
    >
      <p
        class="field-edit__value_hovered field-edit__value_crossed-out"
        [ngClass]="{ 'text-bold': bold, 'ag-grid': agGridView }"
      >
        {{ data?.oldValue | money }}
      </p>
      <button
        class="btn btn_iconed show-on-hover"
        type="button"
        title="Reset change"
        (click)="emitResetValue()"
        *ngIf="!disabled"
      >
        <app-svg [href]="'undo'"></app-svg>
      </button>
    </div>
    <div
      class="flex_nowrap"
      [ngClass]="{ flex_reverse: editPosition === 'left' }"
    >
      <p
        class="field-edit__value_hovered reponew-value"
        [ngClass]="{
          'field-edit__value_changed': valueChanged,
          'ag-grid': agGridView
        }"
      >
        {{ data.value | money }}
      </p>
      <button
        class="btn btn_iconed show-on-hover"
        type="button"
        (click)="editing = true"
        *ngIf="!disabled"
      >
        <app-svg [href]="'pencil-square'"></app-svg>
      </button>
    </div>
  </div>
  <form
    #valueForm="ngForm"
    novalidate
    (ngSubmit)="emitSaveValue(edited.value)"
    (keydown.enter)="$event.preventDefault()"
    (keyup.escape)="stopEditingIfEsc()"
    *ngIf="editing"
  >
    <div class="field-edit__container field-edit__container_narrow">
      <button
        class="btn field-edit__btn"
        title="Cancel"
        type="button"
        (click)="stopEditingIfEsc()"
      >
        <app-svg [href]="'times'"></app-svg>
      </button>
      <input
        type="text"
        class="field-edit__input"
        [ngClass]="{
          'form-block_error': validationError || apiError,
          'ag-grid': agGridView
        }"
        [ngModel]="data.value"
        name="edited"
        (ngModelChange)="validateContent(edited.value)"
        #edited
        (blur)="checkForErrors()"
        (keydown.ArrowLeft)="$event.stopPropagation()"
        (keydown.ArrowRight)="$event.stopPropagation()"
      />
      <button
        class="btn field-edit__btn btn_iconed_success"
        title="Save value"
        type="button"
        (click)="emitSaveValue(edited.value)"
      >
        <app-svg [href]="'check'"></app-svg>
      </button>
    </div>
    <p class="error" *ngIf="!agGridView && (validationError || apiError)">
      <ng-container *ngIf="validationError">
        {{ validationMessage }}
      </ng-container>
      <ng-container *ngIf="apiError">
        {{ apiError }}
      </ng-container>
    </p>
  </form>
</div>
