import { DefaultDTO } from './default.dto';
import { AddressDTO } from './address.dto';

export class LocationDTO extends DefaultDTO {
  name: string;
  email: string;
  address_attributes: AddressDTO;
  phone: number;
  location_id: number;
  project_location_modifier?: number;
  building_id: number;
  floor_id: number;
  tag_ids: Array<number>;

  constructor(location, actions?) {
    super();
    this.name = (location.name || '').trim();
    this.tag_ids = location.tag_ids || [];
    if (!actions) {
      this.email = location.email;
      this.phone = location.phone;
      this.address_attributes = new AddressDTO(location.address);
    }
  }
}
