import { Component, Input } from '@angular/core';

import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { DateTime } from 'luxon';
import { EventService } from '../../../core/services/event.service';
import { IntegrationApiModel } from '../../../core/models/integration-api.model';
import { IntegrationApiService } from '../../../core/services/integration-api.service';
import { OrganizationModel } from '../../../core/models/organization.model';

@Component({
  templateUrl: './integration-api-key-modal.component.html',
})
export class IntegrationApiKeyModalComponent extends BaseModalComponent {
  @Input() public model: IntegrationApiModel;
  @Input() public organization: OrganizationModel;
  public key: string;

  constructor(
    protected modalInstance: BsModalRef,
    protected _integrationApiService: IntegrationApiService,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(modalInstance, _notificationService, _eventService);
  }

  ngOnInit() {
    this.generateApiKey();
  }

  private generateApiKey() {
    this.isLoading = true;
    this._integrationApiService.renewKey(this.model).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.model.access_key_renewed_at = DateTime.now().toISO();
        this.key = data.integration_api_key;
      },
      error: (err) => {
        this.isLoading = false;
        this._notificationService.apiError(err);
      },
    });
  }
}
