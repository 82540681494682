import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector:
    '[isUppercaseEqual][formControlName],[isUppercaseEqual][formControl],[isUppercaseEqual][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: IsUppercaseEqualDirective,
      multi: true,
    },
  ],
})
export class IsUppercaseEqualDirective implements Validator {
  @Input()
  isUppercaseEqual: string;

  validate(c: FormControl): { [key: string]: any } {
    const value = c.value ? c.value.toUpperCase() : '';
    const isUppercaseEqual =
      !value || value === this.isUppercaseEqual.toUpperCase();
    return isUppercaseEqual ? null : { isNotEqual: true };
  }
}
