<div class="chart-bar-component flex-component">
  <canvas
    baseChart
    class="chart-pie"
    width="800"
    [id]="canvasId"
    [datasets]="chart.data"
    [labels]="chart.labels"
    [options]="chart.options"
    [type]="chart.type"
  ></canvas>

  <div class="text-center download-btn_wrapper">
    <button
      (click)="downloadImage()"
      class="btn action-btn action-btn_default"
      type="button"
    >
      <span class="action-btn__icon"
        ><app-svg [href]="'download'" iconClass="icon icon_s"></app-svg
      ></span>
      <span class="action-btn__title">Download</span>
    </button>
  </div>
</div>
