import { Configs } from '../constants/configs.constants';
import { Injectable } from '@angular/core';
import { UserStoreService } from './user.store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { EventService } from './event.service';
import { SearchService } from './search.service';
import { RoutesConstants } from '../constants/routes.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { UserCurrentService } from './user-current.service';
import { OrganizationCurrentService } from './organization-current.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {
  private auth0Domain = environment.auth0_domain;
  private clientId = environment.clientId;
  private auth0_url = environment.auth0_url;

  constructor(
    private _requestService: RequestService,
    private _userStore: UserStoreService,
    private _userCurrent: UserCurrentService,
    private _eventService: EventService,
    private _searchService: SearchService,
    private _organizationCurrent: OrganizationCurrentService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this._eventService.on(Configs.EVENTS.NOT_AUTHORIZED, () => {
      const urlToRedirect = document.location.pathname;
      this.logoutLocal();
      this.router.navigate([RoutesConstants.AUTH.LOGIN], {
        replaceUrl: true,
        queryParams: { redirectUrl: urlToRedirect },
      });
    });
  }

  public login(): void {
    const authUrl = `${this.auth0_url}/authorize?client_id=${this.clientId}&response_type=code&redirect_uri=${this.auth0Domain}/auth/callback&scope=openid%20offline_access&audience=origin-api&ext-hideHelpLineNumber=true&ext-hideLocaleDropdown=true`;
    window.location.href = authUrl;
  }

  public handleAuthentication(): Observable<any> {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    if (!code || localStorage.getItem(Configs.TOKENS.BEARER)) {
      this.router.navigate(['/500'], {
        replaceUrl: true,
      });
      return;
    }
    return this._requestService
      .get('/processor-api/token', {}, { authCode: code })
      .pipe(
        map((res) => {
          this._eventService.broadcast(Configs.EVENTS.LOGIN);
          this.storeApiToken(res.access_token);
          this.storeSsoToken(res.id_token);
          this.storeRefreshToken(res.refresh_token);
        })
      );
  }

  public logout(): void {
    if (this.activeRoute.snapshot.queryParams['redirectUrl']) {
      window.sessionStorage.setItem(
        'uri',
        this.activeRoute.snapshot.queryParams['redirectUrl']
      );
    }
    this.logoutLocal();
    const logoutUrl = `${this.auth0_url}/logout?returnTo=${this.auth0Domain}&client_id=${this.clientId}`;
    window.location.href = logoutUrl;
  }

  public getApiToken(): string {
    return localStorage.getItem(Configs.TOKENS.BEARER);
  }

  // public getSsoToken(): string {
  //   return localStorage.getItems(Configs.TOKENS.SSO);
  // }

  public resetPassword(params): Observable<any> {
    return this._userStore.resetPasswordConfirm(params).pipe(
      map((res) => {
        this.storeTokens(res);
        return res;
      })
    );
  }

  public logoutUser(): Observable<any> {
    return this._requestService.get('/v2/auth/logout').pipe(
      map((res) => {
        this.logoutLocal();
        return res;
      })
    );
  }

  public logoutLocal(sendEvent: boolean = true): void {
    this._searchService.reset();
    this._userCurrent.clean();
    this._organizationCurrent.cleanBoth();
    this.removeApiToken();
    this.removeRefreshToken();
    if (sendEvent) {
      this._eventService.broadcast(Configs.EVENTS.LOGOUT);
    }
  }

  public loginUser(creds): Observable<any> {
    this.removeApiToken();
    return this._requestService.post('/v2/auth/login', creds).pipe(
      map((res) => {
        this._eventService.broadcast(Configs.EVENTS.LOGIN);
        this.storeTokens(res);
        return res;
      })
    );
  }

  public registerByInvite(data): Observable<any> {
    return this._userStore.acceptInvite(data).pipe(
      map((res) => {
        this.storeTokens(res);
        return res;
      })
    );
  }

  public storeTokens(res: any) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    res.api_key?.length && this.storeApiToken(res.api_key);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    res.sso_session_token?.length && this.storeSsoToken(res.sso_session_token);
  }

  private storeApiToken(token): void {
    this.removeApiToken();
    localStorage.setItem(Configs.TOKENS.BEARER, token);
  }

  private storeSsoToken(token): void {
    localStorage.setItem(Configs.TOKENS.SSO, token);
  }

  private removeApiToken(): void {
    localStorage.removeItem(Configs.TOKENS.BEARER);
  }

  private storeRefreshToken(token): void {
    this.removeRefreshToken();
    localStorage.setItem(Configs.TOKENS.REFRESH, token);
  }

  private removeRefreshToken(): void {
    localStorage.removeItem(Configs.TOKENS.REFRESH);
  }
}
