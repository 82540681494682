<section ng-modal-fix class="modal-content__wrapper modal-content__1100">
  <header class="modal-content__header">{{ header }}</header>

  <section
    class="modal-content__body modal-content__body_without-form modal-content__scrollable"
    id="vizWrapper"
  >
    <div class="body">
      <div class="classic-app">
        <qlik-embed
          ui="classic/app"
          [attr.app-id]="qlikAppId"
          [attr.sheet-id]="sheetId"
        ></qlik-embed>
      </div>
    </div>
  </section>

  <footer class="buttons-block buttons-block_to-end modal-content__footer">
    <button
      (click)="submit()"
      type="button"
      class="btn action-btn action-btn_{{ submitClass }}"
    >
      <span class="action-btn__icon"
        ><app-svg [href]="submitIcon" iconClass="icon icon_s"></app-svg
      ></span>
      <span class="action-btn__title">{{ submitText }}</span>
    </button>
  </footer>
</section>
