import { AgGridTableComponent, provideAgGridLicense } from '@core/ag-grid';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import {
  TranslateModule,
  TranslatePipe,
  TranslateService,
} from '@ngx-translate/core';

import { AutocompleteInputComponent } from '../components/molecules/autocomplete-input/autocomplete-input.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ColorPickerModule } from 'ngx-color-picker';
import { DefaultTooltipOptions } from '../core/constants/tooltip.constants';
import { DndModule } from 'ngx-drag-drop';
import { HighlightTextPipe } from '@core';
import { HttpClientModule } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-img-cropper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { NgChartsModule } from 'ng2-charts';
import { NgxFileHelpersModule } from 'ngx-file-helpers';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { OverlayModule } from '@angular/cdk/overlay';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ZoomOnHoverDirective } from './directives/zoom-on-hover.directive';
import { components } from './components';
import { directives } from './directives';
import { guards } from './guards';
import { modals } from './modals';
import { pipes } from './pipes';
import { secrets } from 'src/secrets';
import { validators } from './validators';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgxMaskModule,
    BsDatepickerModule,
    TooltipModule.forRoot(),
    TimepickerModule,
    ProgressbarModule,
    BsDropdownModule,
    NgxFileHelpersModule,
    DndModule,
    NgxJsonViewerModule,
    ColorPickerModule,
    MatLuxonDateModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule.forChild(),
    MatIconModule,
    NgChartsModule,
    TypeaheadModule.forRoot(),
    AutocompleteInputComponent,
    OverlayModule,
    ScrollingModule,
    AgGridTableComponent,
    HighlightTextPipe,
    ZoomOnHoverDirective,
    KeyValuePipe,
  ],
  declarations: [
    ...pipes,
    ...directives,
    ...validators,
    ...modals,
    ...components,
  ],
  providers: [
    ...guards,
    ...pipes,
    KeyValuePipe,
    HighlightTextPipe,
    TranslatePipe,
    TranslateService,
    { provide: TooltipConfig, useValue: DefaultTooltipOptions },
    provideAgGridLicense(secrets.AG_GRID_LICENSE),
  ],
  exports: [
    ...pipes,
    ...directives,
    ...validators,
    ...modals,
    ...components,
    AgGridTableComponent,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgxMaskModule,
    BsDatepickerModule,
    TooltipModule,
    TimepickerModule,
    ProgressbarModule,
    BsDropdownModule,
    NgxFileHelpersModule,
    DndModule,
    ColorPickerModule,
    MatLuxonDateModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule,
    AutocompleteInputComponent,
    OverlayModule,
    ScrollingModule,
    HighlightTextPipe,
    ZoomOnHoverDirective,
    KeyValuePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
