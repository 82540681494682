import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, Input, ViewChild } from '@angular/core';

import { BaseEditComponent } from '../../components/base-edit.component';
import { ClientAssetTypesService } from '../../../core/services/client-asset-types.service';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';
import { NgForm } from '@angular/forms';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { OrganizationModel } from '../../../core/models/organization.model';
import { OrganizationStoreService } from '../../../core/services/organization-store.service';
import { RisksService } from '../../../core/services/risks.service';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './batch-edit-cpc-modal.component.html',
})
export class BatchEditCpcModalComponent extends BaseEditComponent<OrganizationModel> {
  @ViewChild('formulaForm', { static: true }) declare tdForm: NgForm;

  @Input() public header: string = `Batch operation`;
  @Input() public submitText: string = 'Apply';
  @Input() public cancelText: string = 'Cancel';
  @Input() public hideCancel: boolean = false;
  @Input() public organization_hash: string;
  @Input() public cpc: any;
  @Input() public onResult: Subject<any> = new Subject<any>();
  @Input() public onClose: Subject<any> = new Subject<any>();

  public applyTo: string;
  public formula: string;

  constructor(
    public activeModal: BsModalRef,
    protected _organizationStore: OrganizationStoreService,
    protected _notificationService: CustomNotificationService,
    protected _organizationCurrent: OrganizationCurrentService,
    protected modalService: BsModalService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected _eventService: EventService,
    protected _risksService: RisksService,
    protected _clientAssetTypesService: ClientAssetTypesService,
    protected _errorService: ErrorService
  ) {
    super(
      _notificationService,
      _organizationStore,
      router,
      activeRoute,
      'org_id',
      _eventService,
      _errorService
    );
  }

  ngOnInit() {
    this.isTemplateDriven = true;
    this.entityId = this._organizationCurrent.organization.id_hash;
    this.applyTo = 'all';
  }

  public submitForm(): void {
    this.markFormGroupTouched();

    if (this.tdForm.invalid) {
      return;
    }

    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    const requestBody = {
      applied_to: this.applyTo,
      asset_type_ids: [],
      project_udfs: {
        [this.cpc.id]: this.formula,
      },
    };

    this._clientAssetTypesService.editAllCPCs(requestBody).subscribe({
      next: (res) => {
        this.onResult.next(res);
        this.activeModal.hide();
      },
      error: (err) => {
        this.isLoading = false;
        this.parseErrors(err, 'formula');
      },
    });
  }

  public ngOnChanges(): void {
    if (this.cpc && this.cpc.id) {
      this.isLoading = false;
    }
  }

  public cancel(): void {
    this.onClose.next('cancel');
    this.activeModal.hide();
  }
}
