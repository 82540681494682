import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MoneyPipe } from 'src/app/shared/pipes/money.pipe';

@Component({
  selector: 'lib-ag-cost',
  template: `<span class="text-right">{{
    formattedValue
  }}</span>`,
  providers: [MoneyPipe],
  standalone: true,
})
export class AgCostComponent implements ICellRendererAngularComp {
  formattedValue: string;
  constructor(private readonly moneyPipe: MoneyPipe) {}

  agInit(params: any): void {
    this.formattedValue = this.moneyPipe.transform(params.value);
  }

  refresh(): boolean {
    return false;
  }
}
