import { AfterViewChecked, Component, Input } from '@angular/core';
import { Configs } from '../../../core/constants/configs.constants';
import { EventService } from '../../../core/services/event.service';
import { ImageResizeService } from '../../../core/services/image-resize.service';
import { PictureModel } from '../../../core/models/picture.model';

@Component({
  selector: 'app-syncing-slider',
  templateUrl: './syncing-slider.component.html',
  styleUrls: ['./syncing-slider.component.less'],
})
export class SyncingSliderComponent implements AfterViewChecked {
  @Input() public slides: Array<PictureModel> = [];
  @Input() public imageBaseUrl: string;
  public index: number = 0;
  public thumbsWidth: number = 0;
  public image;

  private isSliderInited: boolean;

  constructor(
    private _eventService: EventService,
    private _imageResizingService: ImageResizeService
  ) {}

  ngOnInit() {
    this.slides = this._imageResizingService.getResizedImages(
      this.slides,
      this.imageBaseUrl
    );
  }

  ngAfterViewChecked(): void {
    if (!this.isSliderInited) {
      this.isSliderInited = true;
      this.setThumbsWidth();
      this.showImage();
    }
  }

  changeImage(i) {
    this.index = i;
    this.showImage();
  }

  openGallery() {
    this._eventService.broadcast(Configs.EVENTS.GALLERY_OPEN, {
      index: this.index,
    });
  }

  private showImage() {
    this.image = this.slides[this.index].img;
    this.smartScroll(this.index);
  }

  public nextImage() {
    if (this.index < this.slides.length - 1) {
      this.index += 1;
    }
    this.showImage();
  }

  public prevImage() {
    this.index -= 1;
    if (this.index < 0) {
      this.index = this.slides.length - 1;
    }
    this.showImage();
  }

  private setThumbsWidth() {
    document
      .querySelectorAll('.ng-gallery__thumbnail-img')
      .forEach((thumb: HTMLImageElement, _index) => {
        const img = new window.Image();
        img.src = thumb.src;
        img.onload = () =>
          (this.thumbsWidth += (Number(thumb.clientWidth) || 0) + 10);
      });
  }

  private smartScroll(index) {
    const len = this.slides.length,
      item_scroll = parseInt((this.thumbsWidth / len).toString(), 10),
      i = index + 1,
      s = Math.ceil(len / i);

    const thumbwrapper = document.getElementsByClassName(
      'ng-gallery__thumbnails-wrapper'
    );
    thumbwrapper[0].scrollLeft = i * item_scroll - s * item_scroll;
  }
}
