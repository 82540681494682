import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../base-modal.component';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { RoutesConstants } from '../../../core/constants/routes.constants';

@Component({
  templateUrl: './score-deletion-modal.component.html',
})
export class ScoreDeletionModalComponent extends BaseModalComponent {
  @Input() public organizationId: string = '';
  @Input() public assetTypes: Array<string> = [];
  @Input() public scopeId: number;
  @Input() public assetsNumber: number;

  constructor(
    public activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  public getAssetTypeDetails(id: number) {
    if (this.organizationId) {
      return RoutesConstants.CAR.DETAILS(this.organizationId, id);
    }
    return RoutesConstants.UAR.DETAILS(id);
  }

  public scopeLink(value): string {
    if (this.organizationId.length) {
      return `/${this.organizationId}/assets/scope/${value}`;
    }
  }
}
