import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'existed',
  pure: false,
})
export class ExistedPipe implements PipeTransform {
  transform(items: any[], term: string = '_delete'): any[] {
    return items.filter((item) => !item[term]);
  }
}
