<section
  class="modal-content__wrapper"
  *ngIf="
    this._featureFlagsService.isFeatureEnabled('OR_12876_project_attributes')
  "
>
  <header class="project-modal step_header">
    <h2 class="modal-content__header header_border">
      <img
        *ngIf="next && showProjectEditOptions"
        class="arrow_button"
        (click)="next = false"
        alt=""
        src="./../../../../../assets/img/arrow_back_ios.svg"
        [width]="18"
        [height]="18"
      />
      {{ header }}
    </h2>
    <div *ngIf="showProjectEditOptions" class="steps">
      Step {{ next ? "2" : "1" }} of 2
    </div>
  </header>
  <form [formGroup]="form" (ngSubmit)="saveAction()">
    <ul class="modal-content__list modal-content__body ng-star-inserted">
      <ng-container *ngIf="!next">
        <li class="modal-content__item modal-content__item_full-width">
          <label for="name" class="modal-content__label"
            >{{ nameLabel }}<span class="asterisk red">&ast;</span></label
          >
          <div class="form-block mrgn-b_sm">
            <input
              [ngClass]="{ 'form-block_error': hasError('name') }"
              formControlName="name"
              type="text"
              id="forecastNameInputId"
              required
              placeholder="New name"
              class="form-block__element"
              (ngModelChange)="searchProjectName($event)"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('name')"
              [tooltip]="getErrorText('name')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
        </li>
        <li
          class="modal-content__item modal-content__item_full-width"
          *ngIf="showProjectEditOptions"
        >
          <label for="name" class="modal-content__label">{{
            descriptionLabel
          }}</label>
          <div class="form-block mrgn-b_xs">
            <input
              [ngClass]="{ 'form-block_error': hasError('description') }"
              formControlName="description"
              type="text"
              id="description"
              placeholder="New description"
              class="form-block__element"
              (input)="updateCharacterCount()"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('description')"
              [tooltip]="getErrorText('description')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
          <div class="character-counter text-dark-grey text-right form-block">
            {{ characterCount }}/{{ descriptionLength }}
          </div>
        </li>

        <li
          class="modal-content__item modal-content__item_full-width"
          *ngIf="!form?.value.projectId && showProjectEditOptions"
        >
          <label class="modal-content__label"
            >{{ accessName }}<span class="asterisk red">&ast;</span></label
          >
          <div
            class="flex-a-center el-in-a-row el-in-a-row_nowrap el-in-a-row_xl"
          >
            <label class="custom-radiobutton custom-radiobutton_xs mrgn-b_sm">
              <input
                id="forecastAccessPublicRadioButtonInputId"
                [value]="true"
                formControlName="public"
                class="custom-radiobutton__input"
                type="radio"
                required
              />
              <span
                class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
              ></span>
              Public
            </label>
            <label class="custom-radiobutton custom-radiobutton_xs mrgn-b_sm">
              <input
                id="forecastAccessPrivateRadioButtonInputId"
                [value]="false"
                formControlName="public"
                class="custom-radiobutton__input"
                type="radio"
              />
              <span
                class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
              ></span>
              Private
            </label>
          </div>
        </li>

        <li
          class="modal-content__item modal-content__item_full-width"
          *ngIf="!form?.value.projectId && !showProjectEditOptions"
        >
          <label
            class="custom-radiobutton custom-radiobutton_xs mrgn-b_sm xs-100"
          >
            <input
              [value]="true"
              formControlName="public"
              class="custom-radiobutton__input"
              type="radio"
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled"
            ></span>
            Public
          </label>
          <label
            class="custom-radiobutton custom-radiobutton_xs mrgn-b_sm xs-100"
          >
            <input
              [value]="false"
              formControlName="public"
              class="custom-radiobutton__input"
              type="radio"
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled"
            ></span>
            Private
          </label>
        </li>

        <li
          class="modal-content__item modal-content__item_full-width"
          *ngIf="showProjectEditOptions"
        >
          <label class="modal-content__label"
            >Does this project take place in, or affects an occupied space<span
              class="asterisk red"
              >&ast;</span
            ></label
          >
          <div
            class="flex-a-center el-in-a-row el-in-a-row_nowrap el-in-a-row_xl"
          >
            <label class="custom-radiobutton">
              <input
                [value]="true"
                formControlName="is_occupied"
                class="custom-radiobutton__input"
                type="radio"
              />
              <span
                class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
              ></span>
              Yes
            </label>
            <label class="custom-radiobutton">
              <input
                [value]="false"
                formControlName="is_occupied"
                class="custom-radiobutton__input"
                type="radio"
              />
              <span
                class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
              ></span>
              No
            </label>
          </div>
        </li>

        <div
          *ngIf="showProjectEditOptions"
          class="project-form-divider mrgn-b_sm mrgn-t_xs"
        ></div>

        <li
          *ngIf="showProjectEditOptions"
          class="modal-content__item modal-content__item_full-width padding-top-0"
        >
          <label for="projectPeriod" class="modal-content__label"
            >Project Date</label
          >

          <mat-form-field
            class="daterangepicker-block form-block__element xs-100"
            [ngClass]="{
              'form-block_error':
                hasError('projectDate') || dateRangeInput?.ngControl?.invalid
            }"
          >
            <mat-date-range-input
              [rangePicker]="rangePicker"
              [max]="maxProjectYear"
              formGroupName="projectDate"
              #dateRangeInput
            >
              <input
                matStartDate
                placeholder="MM/DD/YYYY"
                formControlName="project_date_from"
                readonly
              />
              <input
                matEndDate
                placeholder="MM/DD/YYYY"
                formControlName="project_date_to"
                readonly
              />
            </mat-date-range-input>

            <button
              type="button"
              class="btn tooltip-btn_error daterangepicker-tooltip-btn_error"
              *ngIf="
                hasError('projectDate') || dateRangeInput?.ngControl?.invalid
              "
              [tooltip]="
                dateRangeInput?.ngControl?.invalid && !hasError('projectDate')
                  ? 'Start or end date is invalid.'
                  : getErrorText('projectDate')
              "
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>

            <mat-datepicker-toggle
              matIconSuffix
              [for]="rangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button
                  mat-raised-button
                  class="btn action-btn action-btn_default"
                  matDateRangePickerApply
                  (click)="clearSingleDateRange()"
                >
                  Clear
                </button>
                <button
                  mat-raised-button
                  matDateRangePickerCancel
                  class="btn action-btn action-btn_default"
                >
                  Cancel
                </button>
                <button
                  mat-raised-button
                  class="btn action-btn action-btn_success"
                  matDateRangePickerApply
                  (click)="checkSingleDateRange()"
                >
                  Done
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </li>
      </ng-container>
      <ng-container *ngIf="showProjectEditOptions && next">
        <li class="modal-content__item modal-content__item_half-width">
          <label for="allocatedById" class="modal-content__label"
            ><span class="flex flex-a-center flex_to-start"
              >Allocated By
            </span></label
          >
          <div class="form-block form-block_lg">
            <app-multiselect
              formControlName="allocated_by"
              [key]="'value'"
              [as]="'name'"
              [placeholder]="'placeholders.select_allocted_by' | translate"
              [id]="'allocatedById'"
              [showSearch]="false"
              [singleValueSelected]="true"
              [returnSingleValueAsNumber]="true"
              [values]="alloctedByOptions"
              [ngClass]="{ 'form-block_error': hasError('allocated_by') }"
              id="allocatedById"
              class="form-block form-block_full-width"
            >
            </app-multiselect>
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('allocated_by')"
              [tooltip]="getErrorText('allocated_by')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
        </li>
        <li
          formArrayName="tag_ids"
          class="modal-content__item modal-content__item_half-width"
          *ngFor="let item of items; let $index = index"
        >
          <label for="tagId" class="modal-content__label"
            ><span class="flex flex-a-center flex_to-start"
              >{{ item.name }}
            </span></label
          >
          <div class="form-block form-block_lg">
            <app-multiselect
              [formControlName]="$index"
              [key]="'id'"
              [as]="'name'"
              [placeholder]="' Select '"
              [id]="'tagId'"
              [showSearch]="false"
              [singleValueSelected]="true"
              [returnSingleValueAsNumber]="true"
              [values]="addNoneOption(item.tags)"
              id="tagId"
              class="form-block form-block_full-width"
            >
            </app-multiselect>
          </div>
        </li>
      </ng-container>
    </ul>

    <div
      class="buttons-block modal-content__footer"
      [ngClass]="{
        'buttons-block_not-bordered': showProjectEditOptions,
        border_top: showProjectEditOptions
      }"
    >
      <button
        id="saveForecastCancelBtnId"
        type="button"
        (click)="close()"
        class="btn action-btn action-btn_default"
        [ngClass]="{
          btn_min_width: showProjectEditOptions
        }"
      >
        <span *ngIf="!showProjectEditOptions" class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{ cancelText }}</span>
      </button>
      <button
        *ngIf="!next && showProjectEditOptions"
        (click)="handleNext()"
        class="btn action-btn action-btn_success btn_min_width"
      >
        <span class="action-btn__title">{{ "Next" }}</span>
      </button>
      <button
        *ngIf="showProjectEditOptions ? next : true"
        class="btn action-btn action-btn_success"
        id="saveForecastSaveBtnId"
        [ngClass]="{
          btn_min_width: showProjectEditOptions
        }"
      >
        <span *ngIf="!showProjectEditOptions" class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">{{ submitText }}</span>
      </button>
    </div>
  </form>
</section>

<section
  class="modal-content__wrapper"
  *ngIf="
    !this._featureFlagsService.isFeatureEnabled('OR_12876_project_attributes')
  "
>
  <header class="modal-content__header project-modal">
    {{ header }}
  </header>
  <form [formGroup]="form" (ngSubmit)="saveAction()">
    <div class="modal-content__body">
      <div>
        <label for="name" class="modal-content__label"
          >{{ nameLabel }}<span class="asterisk red">&ast;</span></label
        >
        <div class="form-block mrgn-b_sm">
          <input
            [ngClass]="{ 'form-block_error': hasError('name') }"
            formControlName="name"
            type="text"
            id="forecastNameInputId"
            required
            placeholder="New name"
            class="form-block__element"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('name')"
            [tooltip]="getErrorText('name')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </div>
      <div *ngIf="showProjectEditOptions">
        <label for="name" class="modal-content__label">{{
          descriptionLabel
        }}</label>
        <div class="form-block mrgn-b_xs">
          <input
            [ngClass]="{ 'form-block_error': hasError('description') }"
            formControlName="description"
            type="text"
            id="description"
            placeholder="New description"
            class="form-block__element"
            (input)="updateCharacterCount()"
          />
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('description')"
            [tooltip]="getErrorText('description')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
        <div class="character-counter text-dark-grey text-right form-block">
          {{ characterCount }}/{{ descriptionLength }}
        </div>
      </div>

      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="!form?.value.projectId && showProjectEditOptions"
      >
        <label class="modal-content__label"
          >{{ accessName }}<span class="asterisk red">&ast;</span></label
        >
        <div
          class="flex-a-center el-in-a-row el-in-a-row_nowrap el-in-a-row_xl"
        >
          <label class="custom-radiobutton custom-radiobutton_xs mrgn-b_sm">
            <input
              id="forecastAccessPublicRadioButtonInputId"
              [value]="true"
              formControlName="public"
              class="custom-radiobutton__input"
              type="radio"
              required
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
            ></span>
            Public
          </label>
          <label class="custom-radiobutton custom-radiobutton_xs mrgn-b_sm">
            <input
              id="forecastAccessPrivateRadioButtonInputId"
              [value]="false"
              formControlName="public"
              class="custom-radiobutton__input"
              type="radio"
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
            ></span>
            Private
          </label>
        </div>
      </li>

      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="!form?.value.projectId && !showProjectEditOptions"
      >
        <label
          class="custom-radiobutton custom-radiobutton_xs mrgn-b_sm xs-100"
        >
          <input
            [value]="true"
            formControlName="public"
            class="custom-radiobutton__input"
            type="radio"
          />
          <span
            class="custom-radiobutton__check custom-radiobutton__check_labeled"
          ></span>
          Public
        </label>
        <label
          class="custom-radiobutton custom-radiobutton_xs mrgn-b_sm xs-100"
        >
          <input
            [value]="false"
            formControlName="public"
            class="custom-radiobutton__input"
            type="radio"
          />
          <span
            class="custom-radiobutton__check custom-radiobutton__check_labeled"
          ></span>
          Private
        </label>
      </li>

      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="showProjectEditOptions"
      >
        <label class="modal-content__label"
          >Does this project take place in, or affects an occupied space<span
            class="asterisk red"
            >&ast;</span
          ></label
        >
        <div
          class="flex-a-center el-in-a-row el-in-a-row_nowrap el-in-a-row_xl"
        >
          <label class="custom-radiobutton">
            <input
              [value]="true"
              formControlName="is_occupied"
              class="custom-radiobutton__input"
              type="radio"
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
            ></span>
            Yes
          </label>
          <label class="custom-radiobutton">
            <input
              [value]="false"
              formControlName="is_occupied"
              class="custom-radiobutton__input"
              type="radio"
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled full-size"
            ></span>
            No
          </label>
        </div>
      </li>

      <div
        *ngIf="showProjectEditOptions"
        class="project-form-divider mrgn-b_sm mrgn-t_xs"
      ></div>

      <li
        *ngIf="showProjectEditOptions"
        class="modal-content__item modal-content__item_full-width padding-top-0"
      >
        <label for="projectPeriod" class="modal-content__label"
          >Project Date</label
        >

        <mat-form-field
          class="daterangepicker-block form-block__element xs-100"
          [ngClass]="{
            'form-block_error':
              hasError('projectDate') || dateRangeInput?.ngControl?.invalid
          }"
        >
          <mat-date-range-input
            [rangePicker]="rangePicker"
            [max]="maxProjectYear"
            formGroupName="projectDate"
            #dateRangeInput
          >
            <input
              matStartDate
              placeholder="MM/DD/YYYY"
              formControlName="project_date_from"
              readonly
            />
            <input
              matEndDate
              placeholder="MM/DD/YYYY"
              formControlName="project_date_to"
              readonly
            />
          </mat-date-range-input>

          <button
            type="button"
            class="btn tooltip-btn_error daterangepicker-tooltip-btn_error"
            *ngIf="
              hasError('projectDate') || dateRangeInput?.ngControl?.invalid
            "
            [tooltip]="
              dateRangeInput?.ngControl?.invalid && !hasError('projectDate')
                ? 'Start or end date is invalid.'
                : getErrorText('projectDate')
            "
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>

          <mat-datepicker-toggle
            matIconSuffix
            [for]="rangePicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #rangePicker>
            <mat-date-range-picker-actions>
              <button
                mat-raised-button
                class="btn action-btn action-btn_default"
                matDateRangePickerApply
                (click)="clearSingleDateRange()"
              >
                Clear
              </button>
              <button
                mat-raised-button
                matDateRangePickerCancel
                class="btn action-btn action-btn_default"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                class="btn action-btn action-btn_success"
                matDateRangePickerApply
                (click)="checkSingleDateRange()"
              >
                Done
              </button>
            </mat-date-range-picker-actions>
          </mat-date-range-picker>
        </mat-form-field>
      </li>

      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="showProjectEditOptions"
      >
        <label for="allocatedById" class="modal-content__label"
          ><span class="flex flex-a-center flex_to-start"
            >Allocated By
          </span></label
        >
        <div class="form-block form-block_lg">
          <app-multiselect
            formControlName="allocated_by"
            [key]="'value'"
            [as]="'name'"
            [placeholder]="'placeholders.select_allocted_by' | translate"
            [id]="'allocatedById'"
            [showSearch]="false"
            [singleValueSelected]="true"
            [returnSingleValueAsNumber]="true"
            [values]="alloctedByOptions"
            [ngClass]="{ 'form-block_error': hasError('allocated_by') }"
            id="allocatedById"
            class="form-block form-block_full-width"
          >
          </app-multiselect>
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('allocated_by')"
            [tooltip]="getErrorText('allocated_by')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>

      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="showProjectEditOptions"
      >
        <label for="projectTypeID" class="modal-content__label"
          ><span class="flex flex-a-center flex_to-start"
            >Project Type
          </span></label
        >
        <div class="form-block form-block_lg">
          <app-multiselect
            formControlName="project_type"
            [key]="'id'"
            [as]="'name'"
            [id]="'projectTypeID'"
            [placeholder]="'Select Project Type'"
            [showSearch]="false"
            [singleValueSelected]="true"
            [returnSingleValueAsNumber]="true"
            [values]="projectTypeOption"
            [ngClass]="{ 'form-block_error': hasError('project_type') }"
            id="projectTypeID"
            class="form-block form-block_full-width"
          >
          </app-multiselect>
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('project_type')"
            [tooltip]="getErrorText('project_type')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>
      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="showProjectEditOptions"
      >
        <label for="projectPriorityID" class="modal-content__label"
          ><span class="flex flex-a-center flex_to-start"
            >Project Priority
          </span></label
        >
        <div class="form-block form-block_lg">
          <app-multiselect
            formControlName="project_risk"
            [key]="'id'"
            [as]="'name'"
            [id]="'projectPriorityID'"
            [placeholder]="'Select Project Priority'"
            [showSearch]="false"
            [singleValueSelected]="true"
            [returnSingleValueAsNumber]="true"
            [values]="projectPriorityOption"
            [ngClass]="{ 'form-block_error': hasError('project_risk') }"
            id="projectPriorityID"
            class="form-block form-block_full-width"
          >
          </app-multiselect>
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('project_risk')"
            [tooltip]="getErrorText('project_risk')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>
      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="showProjectEditOptions"
      >
        <label for="energySavingID" class="modal-content__label"
          ><span class="flex flex-a-center flex_to-start"
            >Energy Savings
            <img
              [src]="leafUrl"
              class="mrgn-l-xs"
              alt="energy saving icon" /></span
        ></label>
        <div class="form-block form-block_lg">
          <app-multiselect
            formControlName="energy_saving"
            [key]="'value'"
            [as]="'name'"
            [placeholder]="'placeholders.select_energy_saving_type' | translate"
            [id]="'energySavingID'"
            [showSearch]="false"
            [singleValueSelected]="true"
            [returnSingleValueAsNumber]="true"
            [values]="energySavingOptions"
            [ngClass]="{ 'form-block_error': hasError('energy_saving') }"
            id="energySavingID"
            class="form-block form-block_full-width"
          >
          </app-multiselect>
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('energy_saving')"
            [tooltip]="getErrorText('energy_saving')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>
    </div>

    <div
      class="buttons-block modal-content__footer"
      [ngClass]="{
        'buttons-block_not-bordered': showProjectEditOptions
      }"
    >
      <button
        id="saveForecastCancelBtnId"
        type="button"
        (click)="close()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{ cancelText }}</span>
      </button>

      <button
        class="btn action-btn action-btn_success"
        id="saveForecastSaveBtnId"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">{{ submitText }}</span>
      </button>
    </div>
  </form>
</section>
