<section class="modal-content__wrapper">
  <header class="modal-content__header">{{ header }}</header>

  <form
    (keydown.enter)="$event.preventDefault()"
    [formGroup]="form"
    (ngSubmit)="saveAction(confirmationNeeded && hasSubmitted)"
  >
    <section class="modal-content__body">
      <div class="text-center" *ngIf="isLoading">
        <app-loader></app-loader>
      </div>

      <p
        *ngIf="confirmationNeeded && hasSubmitted && !isLoading"
        class="modal-content__message mrgn-b_sm"
      >
        The changes you made will force recalculation of Asset Replacement Costs
        for all assets in current location.
      </p>

      <ul
        [hidden]="isLoading || (confirmationNeeded && hasSubmitted)"
        class="modal-content__list"
      >
        <li class="modal-content__item modal-content__item_full-width">
          <label for="name" class="modal-content__label"
            >{{ locationType.capitalize() }} name</label
          >
          <div class="form-block form-block_lg">
            <input
              type="text"
              id="name"
              formControlName="name"
              class="form-block__element"
              [ngClass]="{ 'form-block_error': hasError('name') }"
              [placeholder]="'Enter Name'"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('name')"
              [tooltip]="getErrorText('name')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
        </li>
        <li
          *ngIf="type === 'locations'"
          formGroupName="address_attributes"
          class="flex xs-100"
        >
          <ul class="flex xs-100">
            <li class="modal-content__item modal-content__item_half-width">
              <label class="modal-content__label">Country</label>
              <div class="form-block form-block_full-width">
                <app-select
                  formControlName="country"
                  [ngClass]="{
                    'form-block_error': hasError('address_attributes.country')
                  }"
                  optKey="value"
                  [placeholder]="'placeholders.select_country' | translate"
                  [values]="countries"
                >
                </app-select>
                <button
                  type="button"
                  class="btn tooltip-btn_error"
                  *ngIf="hasError('address_attributes.country')"
                  [tooltip]="
                    getErrorText(
                      'address_attributes.country',
                      'country' | translate
                    )
                  "
                >
                  <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
                </button>
              </div>
            </li>
            <li class="modal-content__item modal-content__item_half-width">
              <label class="modal-content__label">City</label>
              <div class="form-block form-block_full-width">
                <input
                  type="text"
                  placeholder="City"
                  formControlName="city"
                  [ngClass]="{
                    'form-block_error': hasError('address_attributes.city')
                  }"
                  class="form-block__element"
                  [ngStyle]="{
                    'border-color': invalidZipcodeAndCityError ? '#B40909' : ''
                  }"
                />
                <button
                  type="button"
                  class="btn tooltip-btn_error"
                  *ngIf="hasError('address_attributes.city')"
                  [tooltip]="
                    getErrorText('address_attributes.city', 'city' | translate)
                  "
                >
                  <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
                </button>
              </div>
            </li>
            <li class="modal-content__item modal-content__item_half-width">
              <label class="modal-content__label">State</label>
              <div class="form-block form-block_full-width">
                <app-select
                  formControlName="state"
                  [ngClass]="{
                    'form-block_error': hasError('address_attributes.state')
                  }"
                  [required]="form.value.address_attributes.country === 'US'"
                  [disabled]="form.value.address_attributes.country !== 'US'"
                  optKey="key"
                  placeholder="Select state"
                  [values]="states"
                >
                </app-select>
                <button
                  type="button"
                  class="btn tooltip-btn_error"
                  *ngIf="hasError('address_attributes.state')"
                  [tooltip]="
                    getErrorText(
                      'address_attributes.state',
                      'state' | translate
                    )
                  "
                >
                  <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
                </button>
              </div>
            </li>
            <li class="modal-content__item modal-content__item_half-width">
              <label class="modal-content__label">Zip/Postal Code</label>
              <div class="form-block form-block_full-width">
                <input
                  type="text"
                  placeholder="Zip/Postal Code"
                  formControlName="zipcode"
                  [ngClass]="{
                    'form-block_error': hasError('address_attributes.zipcode')
                  }"
                  class="form-block__element"
                  [ngStyle]="{
                    'border-color': invalidZipcodeAndCityError ? '#B40909' : ''
                  }"
                />
                <button
                  type="button"
                  class="btn tooltip-btn_error"
                  *ngIf="hasError('address_attributes.zipcode')"
                  [tooltip]="
                    getErrorText(
                      'address_attributes.zipcode',
                      'zip' | translate
                    )
                  "
                >
                  <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
                </button>
              </div>
            </li>
            <li class="modal-content__item modal-content__item_full-width">
              <label class="modal-content__label">{{
                'address' | translate
              }}</label>
              <div class="form-block form-block_full-width">
                <input
                  type="text"
                  [placeholder]="'address' | translate"
                  formControlName="address1"
                  [ngClass]="{
                    'form-block_error': hasError('address_attributes.address1')
                  }"
                  class="form-block__element"
                />
                <button
                  type="button"
                  class="btn tooltip-btn_error"
                  *ngIf="hasError('address_attributes.address1')"
                  [tooltip]="
                    getErrorText(
                      'address_attributes.address1',
                      'address' | translate
                    )
                  "
                >
                  <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
                </button>
              </div>
            </li>
          </ul>
        </li>
        <li *ngIf="type === 'locations'" class="modal-content__item">
          <label for="project_location_modifier" class="modal-content__label"
            >Regional Factor</label
          >
          <div class="form-block form-block_lg">
            <input
              type="number"
              id="project_location_modifier"
              formControlName="project_location_modifier"
              class="form-block__element"
              [ngClass]="{
                'form-block_error': hasError('project_location_modifier')
              }"
              [placeholder]="'Regional Factor'"
              [min]="0"
              [max]="9.99"
            />
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('project_location_modifier')"
              [tooltip]="getErrorText('project_location_modifier')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
        </li>
        <li
          *ngIf="type !== 'zones'"
          [ngClass]="{
            'modal-content__item_two-third-width': type === 'locations',
            'modal-content__item_full-width': type !== 'locations'
          }"
          class="modal-content__item"
        >
          <label class="modal-content__label">{{ 'fa' | translate }}</label>
          <div class="form-block form-block_lg">
            <app-fa-multiselect
              [key]="'id'"
              [as]="'name'"
              [placeholder]="'No facility attributes'"
              [id]="'tags'"
              [values]="applicableCategories"
              [oneLevel]="false"
              [disableTopLevel]="true"
              formControlName="tag_ids"
            >
            </app-fa-multiselect>
            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('tag_ids')"
              [tooltip]="getErrorText('tag_ids')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
        </li>
      </ul>
    </section>

    <footer class="buttons-block modal-content__footer">
      <button
        type="button"
        (click)="close()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>

      <button class="btn action-btn action-btn_default action-btn_success">
        <span class="action-btn__icon">
          <app-svg [href]="'check'" iconClass="icon icon_s"></app-svg>
        </span>
        <span class="action-btn__title">{{
          (confirmationNeeded && hasSubmitted
            ? 'buttons.confirm'
            : 'buttons.submit'
          ) | translate
        }}</span>
      </button>
    </footer>
  </form>
</section>
