import { Component } from '@angular/core';
import { ServiceEventComponent } from '../service-event-modal/service-event-modal.component';
import { ServiceLogsService } from '../../../core/services/service-log.service';
import { ImageResizeService } from '../../../core/services/image-resize.service';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { AssetsService } from '../../../core/services/assets.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RepairModel } from '../../../core/models/repair.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from '../../../core/services/event.service';
import { ErrorService } from '../../../core/services/error.service';

@Component({
  templateUrl: '../service-event-modal/service-event-modal.component.html',
})
export class ServiceRepairModalComponent extends ServiceEventComponent<RepairModel> {
  constructor(
    protected activeModal: BsModalRef,
    protected store: ServiceLogsService<RepairModel>,
    protected _imageResizingService: ImageResizeService,
    protected _organizationCurrent: OrganizationCurrentService,
    protected _notificationService: CustomNotificationService,
    protected _assetsService: AssetsService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected _eventService: EventService,
    protected _errorService: ErrorService
  ) {
    super(
      activeModal,
      store,
      _imageResizingService,
      _organizationCurrent,
      _notificationService,
      _assetsService,
      router,
      activeRoute,
      _eventService,
      _errorService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.title = 'Repair';
    // Setup
    this.isLoading = true;
    this.store.service_type = 'repairs';
    this.loadModel().subscribe({
      next: () => {
        this.setDatepickerMinimalDate();
        this.model.asset_id = this.asset.id;
        this.isLoading = false;
      },
      error: (err) => this._notificationService.apiError(err),
    });
  }

  protected createModel(data?): RepairModel {
    return new RepairModel(data);
  }
}
