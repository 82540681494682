<section ng-modal-fix class="modal-content__wrapper modal-content__500">
  <header class="modal-content__header">{{ header }}</header>

  <section class="modal-content__body modal-content__body_without-form">
    <div class="flex flex_j-center">
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-group__btn"
          [ngClass]="{ active: activeTab === 'errors' }"
          [disabled]="!errors.length"
          (click)="activeTab = 'errors'"
        >
          {{ 'errors' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-group__btn"
          [ngClass]="{ active: activeTab === 'warnings' }"
          (click)="activeTab = 'warnings'"
          [disabled]="!warnings.length"
        >
          {{ 'warnings' | translate }}
        </button>
      </div>
    </div>

    <div *ngIf="activeTab === 'errors'" class="tab errors-tab">
      <p class="form-error standalone">{{ errorMessage }}</p>
      <div class="popup__table-holder">
        <table
          class="table table-striped table-hover"
          aria-describedby="mydesc"
        >
          <thead class="table__header">
            <tr>
              <th class="save-error">{{ 'error' | translate }}</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="popup__table-holder">
        <table
          class="table table-striped table-hover"
          aria-describedby="mydesc"
        >
          <th style="display: none">Table header</th>
          <tbody>
            <tr *ngFor="let value of errors">
              <td class="save-error">{{ value.text }}</td>
              <td>{{ value.row }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="activeTab === 'warnings'" class="errors-tab">
      <p class="form-warning standalone">{{ warningMessage }}</p>
      <div class="popup__table-holder">
        <table
          class="table table-striped table-hover"
          aria-describedby="mydesc"
        >
          <th style="display: none">Table header</th>
          <thead class="table__header">
            <tr>
              <th>{{ entityColumnName }}</th>
              <th class="save-error">{{ 'warning' | translate }}</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="popup__table-holder">
        <table
          class="table table-striped table-hover"
          aria-describedby="mydesc"
        >
          <th style="display: none">Table header</th>
          <tbody>
            <tr *ngFor="let value of warnings">
              <td>{{ value.row }}</td>
              <td class="save-error">{{ value.text }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>

  <section class="modal-content__footer buttons-block buttons-block_to-center">
    <button
      type="button"
      (click)="cancel()"
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">{{ 'close' | translate }}</span>
    </button>
  </section>
</section>
