<section class="modal-content__wrapper">
  <header class="modal-content__header">
    {{ title }} {{ fileType.capitalize() }}
  </header>

  <form
    class="modal-content__body"
    #uploadForm="ngForm"
    [hidden]="isLoading || !model"
  >
    <section class="modal-content__list" *ngIf="fileType === 'document'">
      <div class="modal-content__item">
        <label for="documentType" class="modal-content__label">
          {{ 'type_of_document' | translate }}
        </label>
        <app-select
          name="document_type"
          id="documentType"
          [(ngModel)]="model.document_type"
          optKey="key"
          required
          [showPlaceholder]="false"
          [values]="documentTypes"
        >
        </app-select>
      </div>

      <div class="modal-content__item">
        <label for="expires_at" class="modal-content__label">{{
          'expiration_date' | translate
        }}</label>
        <div>
          <mat-form-field
            class="datepicker-block datepicker-block__display-block"
            [ngClass]="{ 'form-block_error': hasError(_expires_at) }"
          >
            <input
              type="text"
              name="expires_at"
              id="expires_at"
              placeholder="Date"
              matInput
              (keydown.enter)="$event.target.blur()"
              [min]="minDate"
              [(ngModel)]="model.expires_at"
              #_expires_at="ngModel"
              [matDatepicker]="expires_at"
              (ngModelChange)="checkReminderLaterThanExpiration()"
            />
            <mat-datepicker-toggle matSuffix [for]="expires_at">
              <mat-icon matDatepickerToggleIcon>
                <app-svg [href]="'datepicker-calendar'"></app-svg>
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #expires_at></mat-datepicker>
          </mat-form-field>
          <p
            class="error"
            *ngIf="hasError(_expires_at) && (_expires_at.errors || {})"
          >
            <ng-container *ngIf="_expires_at.errors?.matDatepickerParse">
              {{ 'validation.bad_date' | translate }}
            </ng-container>
            <ng-container *ngIf="_expires_at.errors?.matDatepickerMin">
              {{ 'validation.min_date_past' | translate }}
            </ng-container>
          </p>
        </div>
      </div>

      <div class="modal-content__item modal-content__item_last-in-row">
        <label for="remindIn" class="modal-content__label">
          {{ 'send_me_reminder' | translate }}
        </label>
        <app-select
          name="renind_in"
          id="remindIn"
          optKey="key"
          required
          [showPlaceholder]="false"
          [values]="remindIn"
          [(ngModel)]="model.remind_in"
          (ngModelChange)="checkReminderLaterThanExpiration()"
        >
        </app-select>
        <p class="error error_warn" *ngIf="isReminderLaterThanExpiration">
          {{ 'validation.notification_warning' | translate }}
        </p>
      </div>
    </section>

    <label class="modal-content__item modal-content__item_full-width">
      <textarea
        class="form-block__element"
        [ngClass]="{ 'form-block_error': hasError(_comment) }"
        [(ngModel)]="model.description"
        placeholder="Description"
        id="comment"
        name="comment"
        rows="8"
        #_comment="ngModel"
        [customMaxlength]="255"
      ></textarea>
      <span
        class="error"
        *ngIf="hasError(_comment) && _comment.errors.maxlength"
      >
        {{ 'validation.maxlength' | translate }}
      </span>
    </label>

    <fieldset
      *ngIf="mode === 'create'"
      class="modal-content__item modal-content__item_full-width"
    >
    <legend style="display: none">Upload Files Information</legend>
      <div
        class="image-gallery__drop image-gallery__drop_full"
        appFileUpload
        *ngIf="!file"
        (click)="fileInput.click()"
        (filesDropped)="addFile($event)"
        (filesHovered)="dropzoneState($event)"
        [ngClass]="{ 'image-gallery__drop_active': dropzoneActive }"
      >
        <input
          type="file"
          #fileInput
          accept="{{ accept }}"
          [ngStyle]="{ display: 'none' }"
          (change)="addFile($event.target.files)"
        />
        {{ 'drop_file' | translate }}
      </div>
      <div
        class="upload__added-item flex el-in-a-row_lg"
        *ngIf="file && !helper.isExist(progress)"
      >
        <div>
          <h2 class="upload__added-title">{{ file.name }}</h2>
          <p class="content-remark">
            {{
              helper.convertToNumberWithCommas(
                helper.toFixed(file.size / 1024, 2)
              )
            }}kb
          </p>
        </div>

        <button
          type="button"
          class="btn btn_iconed btn-add-attribute_blue"
          (click)="removeFile()"
        >
          <app-svg [href]="'times'"></app-svg>
        </button>
      </div>

      <progressbar
        *ngIf="helper.isExist(progress)"
        [max]="100"
        [value]="progress"
        type="success"
      >
        <span class="progress-bar__title">{{
          'upload_in_progress' | translate
        }}</span>
      </progressbar>
    </fieldset>
  </form>

  <footer class="buttons-block modal-content__footer">
    <button
      type="button"
      (click)="cancel()"
      class="btn action-btn action-btn_default"
    >
      <span class="action-btn__icon"><app-svg [href]="'times'"></app-svg></span>
      <span class="action-btn__title">{{ 'buttons.cancel' | translate }}</span>
    </button>

    <button
      (click)="submit()"
      [disabled]="
        (!file && mode !== 'update') || uploadForm.invalid || hasSubmitted
      "
      type="button"
      class="btn action-btn action-btn_success"
    >
      <span class="action-btn__icon"><app-svg [href]="'check'"></app-svg></span>
      <span class="action-btn__title">{{ 'buttons.update' | translate }}</span>
    </button>
  </footer>
</section>
