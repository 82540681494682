import { DefaultModel } from './default.model';
import { LocationDTO } from '../dto/location.dto';
import { AddressModel } from './address.model';
import { FacilityCategoryModel } from './facility-category.model';

export class LocationModel extends DefaultModel<LocationDTO> {
  id?: number;
  name?: string;
  position?: number;
  updated_at?: string;
  created_at?: string;
  address?: AddressModel;
  address_attributes?: AddressModel;
  type?: string;
  assets_count?: number;
  locations?: Array<LocationModel>;
  buildings?: Array<LocationModel>;
  rooms?: Array<LocationModel>;
  floors?: Array<LocationModel>;
  filterId?: FilterIdModel;
  location_id?: number;
  collapsed?: boolean;
  hasChildren?: boolean;
  hideNested?: boolean;
  isLoading?: boolean;
  project_location_modifier?: number;
  facility_id?: number;
  facility_type?: string;
  parent_id?: number;
  without_actual_fte?: number;
  without_square_footage?: number;
  parents?: {
    location?: LocationModel;
    building?: LocationModel;
    floor?: LocationModel;
    room?: LocationModel;
  };
  tags?: Array<any>;
  tag_ids?: Array<number>;
  categories?: Array<FacilityCategoryModel>;
  total_square_footage?: number;
  total_calculated_fte?: number;
  total_actual_fte?: number;
  total_fc_index?: number;

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
      this.tag_ids ||= [];
    }
    if (!this.address) {
      this.address = {};
    }
    this.hasChildren = true;
    this.collapsed = true;
    this.isLoading = false;
    this.address_attributes = model ? model.address : {};
    if (!this.id) {
      this.id = this.facility_id;
    }
  }

  public dto(): LocationDTO {
    return new LocationDTO(this);
  }

  public get entity_name(): string {
    return 'location';
  }

  public toString(): string {
    return this.name;
  }
}

export class FacilityAssetsModel {
  regular_total: number;
  archived_total: number;
  incomplete_total: number;
}

export class FilterIdModel {
  locationId?: number;
  buildingId?: number;
}
