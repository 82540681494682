import { DefaultModel } from './default.model';
import { AreaServeDTO } from '../dto/area-serve.dto';

export class AreaServeModel extends DefaultModel<AreaServeDTO> {
  id: number;
  name: string;
  description: string;
  ra_modifier: number;
  uc_modifier: number;
  is_active: boolean;
  assets: Array<{ id?: number; name: string }>;

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }

  public get entity_name(): string {
    return 'area_served';
  }

  public dto(): AreaServeDTO {
    return new AreaServeDTO(this);
  }

  public toString(): string {
    return this.name;
  }
}
