import { Component, Input, ViewEncapsulation } from '@angular/core';

import { BaseChartComponent } from '../base-chart.component';

@Component({
  selector: 'app-chart-pie',
  templateUrl: './chart-pie.component.html',
  styleUrls: ['./chart-pie.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ChartPieComponent extends BaseChartComponent {
  @Input() public chartTitle: string;
  @Input()
  protected set chartValues(value) {
    this._chartValues = value;
    if (this.helper.isExist(value)) {
      this.updateCharts();
    }
  }

  protected get chartValues(): any {
    return this._chartValues;
  }

  @Input() public randomColors?: boolean = true;
  @Input() declare filename: string;

  ngOnInit() {
    this.canvasId = 'pieCanvas';
  }

  /**
   * default settings:
   * type: bar
   * colors: #337ab7
   * paddings: 10px
   * **/

  protected updateCharts(): void {
    const data = this.getValuesFromDataSets();
    const labels = this.getLabelsFromDataSets(data);
    this.setupChartColors(labels);
    this.chart = {
      type: 'pie',
      data: [
        {
          label: this.chartTitle,
          data,
          backgroundColor: this.chartColors,
          hoverBackgroundColor: this.chartColors,
          borderWidth: 2,
        },
      ],
      labels,
      options: this.setupOptions(),
    };
  }

  protected convertLabelsToStars(labels, values) {
    return labels.map((item, index) => {
      let newItem = `${index} `;
      if (item.indexOf('score') > -1) {
        const scoreNumber = parseInt(item.replace('score', '0'));
        for (let j = 0; j < 5; j++) {
          newItem += j < scoreNumber ? '★' : '☆';
        }
        newItem += ': ' + Math.round(values[index] * 100) / 100 + '%';
        return newItem;
      }
      return item;
    });
  }

  private setupOptions() {
    return {
      responsive: true,
      devicePixelRatio: 1,
      aspectRatio: 1.4,
      layout: {
        padding: {
          left: this.chartFontSize,
          right: 3 * this.chartFontSize,
          top: 3 * this.chartFontSize,
          bottom: -30,
        },
      },
      backgroundColor: '#ffffff',
      plugins: {
        legend: {
          display: true,
          position: 'left',
          labels: {
            boxWidth: 12,
          },
        },
        title: {
          display: true,
          padding: 50,
          position: 'bottom',
          text: this.chartTitle,
        },
      },
    };
  }

  protected getLabelsFromDataSets(values) {
    const labels = super.getLabelsFromDataSets();
    return this.convertLabelsToStars(labels, values);
  }
}
