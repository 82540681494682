import { Component, OnInit } from '@angular/core';
import { Configs } from '../../../core/constants/configs.constants';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';

@Component({
  selector: 'app-indices-switcher',
  templateUrl: './indices-switcher.component.html',
})
export class IndicesSwitcherComponent implements OnInit {
  modes: any = Configs.indicesModes;
  mode: string = '';
  constructor(private _organizationCurrent: OrganizationCurrentService) {}

  ngOnInit(): void {
    this._organizationCurrent.modeSubj.subscribe({
      next: (mode) => (this.mode = mode),
    });
  }

  set indicesMode(mode: string) {
    this._organizationCurrent.setIndicesMode = mode;
  }
}
