import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarServiceService {
  public showProgressBarSubject = new BehaviorSubject<boolean>(true);
  showProgressBar$: Observable<boolean> =
    this.showProgressBarSubject.asObservable();

  private disableButtonSubject = new BehaviorSubject<boolean>(false);
  disableButton$: Observable<boolean> =
    this.disableButtonSubject.asObservable();

  private serverErrorSubject = new BehaviorSubject<boolean>(false);
  serverError$: Observable<boolean> = this.serverErrorSubject.asObservable();

  private progressStartSubject = new BehaviorSubject<any>({
    job_uuid: null,
    client: null,
    name: null,
    entity_id: null,
  });
  progressStart$: Observable<any> = this.progressStartSubject.asObservable();

  private simulationStatusSubject = new BehaviorSubject<any>({
    entity_id: null,
  });
  simulationStatus$ = this.simulationStatusSubject.asObservable();

  updateSimulationStatus(value: any) {
    this.simulationStatusSubject.next(value);
  }

  startProgress(value: any) {
    this.progressStartSubject.next(value);
  }

  setShowProgressBar(value: boolean) {
    this.showProgressBarSubject.next(value);
  }

  updateDisableButton(value: boolean) {
    this.disableButtonSubject.next(value);
  }

  setServerError(hasError: boolean) {
    this.serverErrorSubject.next(hasError);
  }
}
