import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { catchError, map, Observable, throwError } from 'rxjs';
import { DefaultListModel } from '../models/default-list.model';
import { WorkOrderModel } from '../models/work-order.model';
import { BaseStoreService } from './base-store.service';
import { PmpeModel } from '../models/pmpe.model';
import { FeatureFlagsService } from './feature-flags.service';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class WorkOrderService<M> extends BaseStoreService<M> {
  constructor(
    protected _requestService: RequestService,
    protected _featureFlagsService: FeatureFlagsService,
    protected _activeRoute: ActivatedRoute
  ) {
    super(_requestService, PmpeModel);
    this.resourceName = 'v1/compliance';
  }

  /**
   * Gets list of compliance items
   * @param params
   * @param type
   * @returns {Promise}
   */
  public getItems(type: string, params?: any): Observable<DefaultListModel<M>> {
    return super.getItems(params, `${this.resourceName}/${type}`);
  }

  public getById(id, type): Observable<M> {
    return this.getPMWorkOdersById(id, `${this.resourceName}/${type}`);
  }

  public update(model, type?): Observable<M> {
    return super.update(model, `${this.resourceName}/${type}`);
  }

  public create(model: any, type: string): Observable<M> {
    return super.create(model, `/${this.resourceName}/${type}`);
  }

  public createDuplicate(model: any, type: string): Observable<M> {
    // set duplicate for template not default
    // set duplicate for template not default for all asset types
    const params: any = {
      [model.entity_name]: model.dto(),
      [model.isDefault]: false,
    };
    params.report_template.assets_repositories_report_templates_attributes.map(
      (item) => {
        item.is_default = false;
        return item;
      }
    );
    return this._requestService.post(`/${this.resourceName}/${type}`, params);
  }

  public delete(id, type): Observable<any> {
    return super.delete(id, `${this.resourceName}/${type}`, {
      pm_wo_delete: true,
    });
  }

  public deletePMWOBudgeting(id): Observable<any> {
    return this._requestService.delete(
      `/${this.resourceName}/work_orders/${id}/budget_pm_wo`
    );
  }

  public editWorkOrder(
    id: number,
    user_id: number,
    report_template_id: number,
    changeTemplate?: boolean
  ): Observable<WorkOrderModel> {
    const data: any = {
      order: {
        user_id,
        report_template_id,
      },
    };
    if (changeTemplate) {
      data.order.change_template_for_pmpe = 1;
    }
    return this._requestService.put(`/${this.resourceName}/orders/${id}`, data);
  }

  public editWorkOrderBudgeting(
    id: number,
    user_id: number,
    changeTemplate?: boolean
  ): Observable<WorkOrderModel> {
    const data: any = {
      user_id,
    };
    if (changeTemplate) {
      data.order.change_template_for_pmpe = 1;
    }
    return this._requestService.put(
      `/${this.resourceName}/work_orders/${id}/budget_pm_wo`,
      data
    );
  }

  public copyTemplate(id: number, title: string): Observable<any> {
    return this._requestService.post(
      `/${this.resourceName}/templates_repositories/${id}/copy`,
      { report_template: { title } }
    );
  }

  public getTemplatesForAsset(id): Observable<any> {
    return this._requestService.get(`/v1/pm_builder/universal/templates/${id}`);
  }

  getIcon(status: number, entityType: string): Array<string> {
    // workorder statuses: 40 - passed,  30 - failed, 20 - to do, 10 - incomplete
    // event statuses: 30 - done, 20 - to do, 10 - incomplete
    status = entityType === 'event' && status === 30 ? 0 : status;
    switch (status) {
      case 40:
        return ['check', 'Passed'];
      case 30:
        return ['exclamation-triangle', 'Failed'];
      case 20:
        return ['gears', 'To Do'];
      case 10:
        return ['wrench', 'Incomplete'];
      default:
        return ['check', 'Done'];
    }
  }

  public getPMWorkOdersById(
    id: string | number,
    resource = this.resourceName,
    params?: any,
    headers = this.headers
  ): Observable<M> {
    if (resource.indexOf(':id') > -1) {
      resource = resource.replace(':id', String(id));
    } else if (this._activeRoute.snapshot.queryParams.source === 'budgeting') {
      resource = `/${resource}/${id}/budget_pm_wo`;
    } else {
      resource = `/${resource}/${id}`;
    }
    return this._requestService
      .get(resource, params, headers, undefined, undefined, true)
      .pipe(
        map((res) => new this.model(res) as M),
        catchError((error) => throwError(() => error))
      );
  }
}
