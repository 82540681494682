export const STATES = [
  {
    name: 'Alabama',
    key: 'AL',
  },
  {
    name: 'Alaska',
    key: 'AK',
  },
  {
    name: 'Arizona',
    key: 'AZ',
  },
  {
    name: 'Arkansas',
    key: 'AR',
  },
  {
    name: 'California',
    key: 'CA',
  },
  {
    name: 'Colorado',
    key: 'CO',
  },
  {
    name: 'Connecticut',
    key: 'CT',
  },
  {
    name: 'Delaware',
    key: 'DE',
  },
  {
    name: 'District Of Columbia',
    key: 'DC',
  },
  {
    name: 'Florida',
    key: 'FL',
  },
  {
    name: 'Georgia',
    key: 'GA',
  },
  {
    name: 'Hawaii',
    key: 'HI',
  },
  {
    name: 'Idaho',
    key: 'ID',
  },
  {
    name: 'Illinois',
    key: 'IL',
  },
  {
    name: 'Indiana',
    key: 'IN',
  },
  {
    name: 'Iowa',
    key: 'IA',
  },
  {
    name: 'Kansas',
    key: 'KS',
  },
  {
    name: 'Kentucky',
    key: 'KY',
  },
  {
    name: 'Louisiana',
    key: 'LA',
  },
  {
    name: 'Maine',
    key: 'ME',
  },
  {
    name: 'Maryland',
    key: 'MD',
  },
  {
    name: 'Massachusetts',
    key: 'MA',
  },
  {
    name: 'Michigan',
    key: 'MI',
  },
  {
    name: 'Minnesota',
    key: 'MN',
  },
  {
    name: 'Mississippi',
    key: 'MS',
  },
  {
    name: 'Missouri',
    key: 'MO',
  },
  {
    name: 'Montana',
    key: 'MT',
  },
  {
    name: 'Nebraska',
    key: 'NE',
  },
  {
    name: 'Nevada',
    key: 'NV',
  },
  {
    name: 'New Hampshire',
    key: 'NH',
  },
  {
    name: 'New Jersey',
    key: 'NJ',
  },
  {
    name: 'New Mexico',
    key: 'NM',
  },
  {
    name: 'New York',
    key: 'NY',
  },
  {
    name: 'North Carolina',
    key: 'NC',
  },
  {
    name: 'North Dakota',
    key: 'ND',
  },
  {
    name: 'Ohio',
    key: 'OH',
  },
  {
    name: 'Oklahoma',
    key: 'OK',
  },
  {
    name: 'Oregon',
    key: 'OR',
  },
  {
    name: 'Pennsylvania',
    key: 'PA',
  },
  {
    name: 'Rhode Island',
    key: 'RI',
  },
  {
    name: 'South Carolina',
    key: 'SC',
  },
  {
    name: 'South Dakota',
    key: 'SD',
  },
  {
    name: 'Tennessee',
    key: 'TN',
  },
  {
    name: 'Texas',
    key: 'TX',
  },
  {
    name: 'Utah',
    key: 'UT',
  },
  {
    name: 'Vermont',
    key: 'VT',
  },
  {
    name: 'Virginia',
    key: 'VA',
  },
  {
    name: 'Washington',
    key: 'WA',
  },
  {
    name: 'West Virginia',
    key: 'WV',
  },
  {
    name: 'Wisconsin',
    key: 'WI',
  },
  {
    name: 'Wyoming',
    key: 'WY',
  },
];

export const PAGE_SIZES = [
  {
    value: 10,
    label: '10/Page',
  },
  {
    value: 25,
    label: '25/Page',
  },
  {
    value: 50,
    label: '50/Page',
  },
  {
    label: '100/Page',
    value: 100,
  },
];
export const CURRENCY_LIST = [
  {
    name: 'US Dollar (USD)',
    value: 'USD',
  },
  {
    name: 'Canadian Dollar (CAD)',
    value: 'CAD',
  },
];

export const DEFAULT_COUNTRY_CURRENCY = {
  US: 'USD',
  CA: 'CAD',
};

export const COUNTRIES = [
  {
    name: 'United States',
    value: 'US',
  },
  {
    name: 'United Kingdom',
    value: 'GB',
  },
  {
    name: 'Afghanistan',
    value: 'AF',
  },
  {
    name: 'Albania',
    value: 'AL',
  },
  {
    name: 'Algeria',
    value: 'DZ',
  },
  {
    name: 'American Samoa',
    value: 'AS',
  },
  {
    name: 'Andorra',
    value: 'AD',
  },
  {
    name: 'Angola',
    value: 'AO',
  },
  {
    name: 'Anguilla',
    value: 'AI',
  },
  {
    name: 'Antarctica',
    value: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    value: 'AG',
  },
  {
    name: 'Argentina',
    value: 'AR',
  },
  {
    name: 'Armenia',
    value: 'AM',
  },
  {
    name: 'Aruba',
    value: 'AW',
  },
  {
    name: 'Australia',
    value: 'AU',
  },
  {
    name: 'Austria',
    value: 'AT',
  },
  {
    name: 'Azerbaijan',
    value: 'AZ',
  },
  {
    name: 'Bahamas',
    value: 'BS',
  },
  {
    name: 'Bahrain',
    value: 'BH',
  },
  {
    name: 'Bangladesh',
    value: 'BD',
  },
  {
    name: 'Barbados',
    value: 'BB',
  },
  {
    name: 'Belarus',
    value: 'BY',
  },
  {
    name: 'Belgium',
    value: 'BE',
  },
  {
    name: 'Belize',
    value: 'BZ',
  },
  {
    name: 'Benin',
    value: 'BJ',
  },
  {
    name: 'Bermuda',
    value: 'BM',
  },
  {
    name: 'Bhutan',
    value: 'BT',
  },
  {
    name: 'Bolivia',
    value: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  {
    name: 'Botswana',
    value: 'BW',
  },
  {
    name: 'Bhutan',
    value: 'BT',
  },
  {
    name: 'Brazil',
    value: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    value: 'IO',
  },
  {
    name: 'British Virgin Islands',
    value: 'VG',
  },
  {
    name: 'Brunei',
    value: 'BN',
  },
  {
    name: 'Bulgaria',
    value: 'BG',
  },
  {
    name: 'Burkina Faso',
    value: 'BF',
  },
  {
    name: 'Burundi',
    value: 'BI',
  },
  {
    name: 'Cambodia',
    value: 'KH',
  },
  {
    name: 'Cameroon',
    value: 'CM',
  },
  {
    name: 'Canada',
    value: 'CA',
  },
  {
    name: 'Cape Verde',
    value: 'CV',
  },
  {
    name: 'Cayman Islands',
    value: 'KY',
  },
  {
    name: 'Central African Republic',
    value: 'CF',
  },
  {
    name: 'Chad',
    value: 'TD',
  },
  {
    name: 'Chile',
    value: 'CL',
  },
  {
    name: 'China',
    value: 'CN',
  },
  {
    name: 'Christmas Island',
    value: 'CX',
  },
  {
    name: 'Cocos Islands',
    value: 'CC',
  },
  {
    name: 'Colombia',
    value: 'CO',
  },
  {
    name: 'Comoros',
    value: 'KM',
  },
  {
    name: 'Cook Islands',
    value: 'CK',
  },
  {
    name: 'Costa Rica',
    value: 'CR',
  },
  {
    name: 'Croatia',
    value: 'HR',
  },
  {
    name: 'Cuba',
    value: 'CU',
  },
  {
    name: 'Curacao',
    value: 'CW',
  },
  {
    name: 'Cyprus',
    value: 'CY',
  },
  {
    name: 'Czech Republic',
    value: 'CZ',
  },
  {
    name: 'Democratic Republic of the Congo',
    value: 'CD',
  },
  {
    name: 'Denmark',
    value: 'DK',
  },
  {
    name: 'Djibouti',
    value: 'DJ',
  },
  {
    name: 'Dominica',
    value: 'DM',
  },
  {
    name: 'Dominican Republic',
    value: 'DO',
  },
  {
    name: 'East Timor',
    value: 'TL',
  },
  {
    name: 'Ecuador',
    value: 'EC',
  },
  {
    name: 'Egypt',
    value: 'EG',
  },
  {
    name: 'El Salvador',
    value: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    value: 'GQ',
  },
  {
    name: 'Eritrea',
    value: 'ER',
  },
  {
    name: 'Estonia',
    value: 'EE',
  },
  {
    name: 'Ethiopia',
    value: 'ET',
  },
  {
    name: 'Falkland Islands',
    value: 'FK',
  },
  {
    name: 'Faroe Islands',
    value: 'FO',
  },
  {
    name: 'Fiji',
    value: 'FJ',
  },
  {
    name: 'Finland',
    value: 'FI',
  },
  {
    name: 'France',
    value: 'FR',
  },
  {
    name: 'French Polynesia',
    value: 'PF',
  },
  {
    name: 'Gabon',
    value: 'GA',
  },
  {
    name: 'Gambia',
    value: 'GM',
  },
  {
    name: 'Georgia',
    value: 'GE',
  },
  {
    name: 'Germany',
    value: 'DE',
  },
  {
    name: 'Ghana',
    value: 'GH',
  },
  {
    name: 'Gibraltar',
    value: 'GI',
  },
  {
    name: 'Greece',
    value: 'GR',
  },
  {
    name: 'Greenland',
    value: 'GL',
  },
  {
    name: 'Grenada',
    value: 'GD',
  },
  {
    name: 'Guam',
    value: 'GU',
  },
  {
    name: 'Guatemala',
    value: 'GT',
  },
  {
    name: 'Guernsey',
    value: 'GG',
  },
  {
    name: 'Guinea',
    value: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    value: 'GW',
  },
  {
    name: 'Guyana',
    value: 'GY',
  },
  {
    name: 'Haiti',
    value: 'HT',
  },
  {
    name: 'Honduras',
    value: 'HN',
  },
  {
    name: 'Hong Kong',
    value: 'HK',
  },
  {
    name: 'Hungary',
    value: 'HU',
  },
  {
    name: 'Iceland',
    value: 'IS',
  },
  {
    name: 'India',
    value: 'IN',
  },
  {
    name: 'Indonesia',
    value: 'ID',
  },
  {
    name: 'Iran',
    value: 'IR',
  },
  {
    name: 'Iraq',
    value: 'IQ',
  },
  {
    name: 'Ireland',
    value: 'IE',
  },
  {
    name: 'Isle of Man',
    value: 'IM',
  },
  {
    name: 'Israel',
    value: 'IL',
  },
  {
    name: 'Italy',
    value: 'IT',
  },
  {
    name: 'Ivory Coast',
    value: 'CI',
  },
  {
    name: 'Jamaica',
    value: 'JM',
  },
  {
    name: 'Japan',
    value: 'JP',
  },
  {
    name: 'Jersey',
    value: 'JE',
  },
  {
    name: 'Jordan',
    value: 'JO',
  },
  {
    name: 'Kazakhstan',
    value: 'KZ',
  },
  {
    name: 'Kenya',
    value: 'KE',
  },
  {
    name: 'Kiribati',
    value: 'KI',
  },
  {
    name: 'Kosovo',
    value: 'XK',
  },
  {
    name: 'Kuwait',
    value: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    value: 'KG',
  },
  {
    name: 'Laos',
    value: 'LA',
  },
  {
    name: 'Latvia',
    value: 'LV',
  },
  {
    name: 'Lebanon',
    value: 'LB',
  },
  {
    name: 'Lesotho',
    value: 'LS',
  },
  {
    name: 'Liberia',
    value: 'LR',
  },
  {
    name: 'Libya',
    value: 'LY',
  },
  {
    name: 'Liechtenstein',
    value: 'LI',
  },
  {
    name: 'Lithuania',
    value: 'LT',
  },
  {
    name: 'Luxembourg',
    value: 'LU',
  },
  {
    name: 'Macau',
    value: 'MO',
  },
  {
    name: 'Macedonia',
    value: 'MK',
  },
  {
    name: 'Madagascar',
    value: 'MG',
  },
  {
    name: 'Malawi',
    value: 'MW',
  },
  {
    name: 'Malaysia',
    value: 'MY',
  },
  {
    name: 'Maldives',
    value: 'MV',
  },
  {
    name: 'Mali',
    value: 'ML',
  },
  {
    name: 'Malta',
    value: 'MT',
  },
  {
    name: 'Marshall Islands',
    value: 'MH',
  },
  {
    name: 'Mauritania',
    value: 'MR',
  },
  {
    name: 'Mauritius',
    value: 'MU',
  },
  {
    name: 'Mayotte',
    value: 'YT',
  },
  {
    name: 'Mexico',
    value: 'MX',
  },
  {
    name: 'Micronesia',
    value: 'FM',
  },
  {
    name: 'Moldova',
    value: 'MD',
  },
  {
    name: 'Monaco',
    value: 'MC',
  },
  {
    name: 'Mongolia',
    value: 'MN',
  },
  {
    name: 'Montenegro',
    value: 'ME',
  },
  {
    name: 'Montserrat',
    value: 'MS',
  },
  {
    name: 'Morocco',
    value: 'MA',
  },
  {
    name: 'Mozambique',
    value: 'MZ',
  },
  {
    name: 'Myanmar',
    value: 'MM',
  },
  {
    name: 'Namibia',
    value: 'NA',
  },
  {
    name: 'Nauru',
    value: 'NR',
  },
  {
    name: 'Nepal',
    value: 'NP',
  },
  {
    name: 'Netherlands',
    value: 'NL',
  },
  {
    name: 'Netherlands Antilles',
    value: 'AN',
  },
  {
    name: 'New Caledonia',
    value: 'NC',
  },
  {
    name: 'New Zealand',
    value: 'NZ',
  },
  {
    name: 'Nicaragua',
    value: 'NI',
  },
  {
    name: 'Niger',
    value: 'NE',
  },
  {
    name: 'Nigeria',
    value: 'NG',
  },
  {
    name: 'Niue',
    value: 'NU',
  },
  {
    name: 'North Korea',
    value: 'KP',
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    name: 'Norway',
    value: 'NO',
  },
  {
    name: 'Oman',
    value: 'OM',
  },
  {
    name: 'Pakistan',
    value: 'PK',
  },
  {
    name: 'Palau',
    value: 'PW',
  },
  {
    name: 'Palestine',
    value: 'PS',
  },
  {
    name: 'Panama',
    value: 'PA',
  },
  {
    name: 'Papua New Guinea',
    value: 'PG',
  },
  {
    name: 'Paraguay',
    value: 'PY',
  },
  {
    name: 'Peru',
    value: 'PE',
  },
  {
    name: 'Philippines',
    value: 'PH',
  },
  {
    name: 'Pitcairn',
    value: 'PN',
  },
  {
    name: 'Poland',
    value: 'PL',
  },
  {
    name: 'Portugal',
    value: 'PT',
  },
  {
    name: 'Puerto Rico',
    value: 'PR',
  },
  {
    name: 'Qatar',
    value: 'QA',
  },
  {
    name: 'Republic of the Congo',
    value: 'CG',
  },
  {
    name: 'Reunion',
    value: 'RE',
  },
  {
    name: 'Romania',
    value: 'RO',
  },
  {
    name: 'Russia',
    value: 'RU',
  },
  {
    name: 'Rwanda',
    value: 'RW',
  },
  {
    name: 'Saint Barthelemy',
    value: 'BL',
  },
  {
    name: 'Saint Helena',
    value: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    value: 'KN',
  },
  {
    name: 'Saint Lucia',
    value: 'LC',
  },
  {
    name: 'Saint Martin',
    value: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'VC',
  },
  {
    name: 'Samoa',
    value: 'WS',
  },
  {
    name: 'San Marino',
    value: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    value: 'ST',
  },
  {
    name: 'Saudi Arabia',
    value: 'SA',
  },
  {
    name: 'Senegal',
    value: 'SN',
  },
  {
    name: 'Serbia',
    value: 'RS',
  },
  {
    name: 'Seychelles',
    value: 'SC',
  },
  {
    name: 'Sierra Leone',
    value: 'SL',
  },
  {
    name: 'Singapore',
    value: 'SG',
  },
  {
    name: 'Sint Maarten',
    value: 'SX',
  },
  {
    name: 'Slovakia',
    value: 'SK',
  },
  {
    name: 'Slovenia',
    value: 'SI',
  },
  {
    name: 'Solomon Islands',
    value: 'SB',
  },
  {
    name: 'Somalia',
    value: 'SO',
  },
  {
    name: 'South Africa',
    value: 'ZA',
  },
  {
    name: 'South Korea',
    value: 'KR',
  },
  {
    name: 'South Sudan',
    value: 'SS',
  },
  {
    name: 'Spain',
    value: 'ES',
  },
  {
    name: 'Sri Lanka',
    value: 'LK',
  },
  {
    name: 'Sudan',
    value: 'SD',
  },
  {
    name: 'Suriname',
    value: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    value: 'SJ',
  },
  {
    name: 'Swaziland',
    value: 'SZ',
  },
  {
    name: 'Sweden',
    value: 'SE',
  },
  {
    name: 'Switzerland',
    value: 'CH',
  },
  {
    name: 'Syria',
    value: 'SY',
  },
  {
    name: 'Taiwan',
    value: 'TW',
  },
  {
    name: 'Tajikistan',
    value: 'TJ',
  },
  {
    name: 'Tanzania',
    value: 'TZ',
  },
  {
    name: 'Thailand',
    value: 'TH',
  },
  {
    name: 'Togo',
    value: 'TG',
  },
  {
    name: 'Tokelau',
    value: 'TK',
  },
  {
    name: 'Tonga',
    value: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    value: 'TT',
  },
  {
    name: 'Tunisia',
    value: 'TN',
  },
  {
    name: 'Turkey',
    value: 'TR',
  },
  {
    name: 'Turkmenistan',
    value: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    value: 'TC',
  },
  {
    name: 'Tuvalu',
    value: 'TV',
  },
  {
    name: 'U.S. Virgin Islands',
    value: 'VI',
  },
  {
    name: 'Uganda',
    value: 'UG',
  },
  {
    name: 'Ukraine',
    value: 'UA',
  },
  {
    name: 'United Arab Emirates',
    value: 'AE',
  },
  {
    name: 'Uruguay',
    value: 'UY',
  },
  {
    name: 'Uzbekistan',
    value: 'UZ',
  },
  {
    name: 'Vanuatu',
    value: 'VU',
  },
  {
    name: 'Vatican',
    value: 'VA',
  },
  {
    name: 'Venezuela',
    value: 'VE',
  },
  {
    name: 'Vietnam',
    value: 'VN',
  },
  {
    name: 'Wallis and Futuna',
    value: 'WF',
  },
  {
    name: 'Western Sahara',
    value: 'EH',
  },
  {
    name: 'Yemen',
    value: 'YE',
  },
  {
    name: 'Zambia',
    value: 'ZM',
  },
  {
    name: 'Zimbabwe',
    value: 'ZW',
  },
];
