import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.less'],
})
export class SvgComponent {
  @Input() href: string;
  @Input() iconClass: string = 'icon icon_xs';
  @Input() width: number = 24;
  @Input() height: number = 24;
  @Input() fill: string = 'currentColor';
}
