import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseModel } from '../models/base.model';
import { DefaultListModel } from '../models/default-list.model';
import { HttpHeaders } from '@angular/common/http';
import { PlatformService } from './platform.service';
import { RequestService } from './request.service';
import { inject } from '@angular/core';

export class BaseStoreService<M> {
  protected resourceName: string;
  protected headers: any;
  protected _currentModel: any;
  platformService: PlatformService = inject(PlatformService);

  get currentModel() {
    return this._currentModel;
  }

  set currentModel(value: any) {
    this._currentModel = value;
  }

  constructor(
    protected _requestService: RequestService,
    protected model: any = BaseModel
  ) {}

  protected headersToParamsBag(headers: HttpHeaders): any {
    // This returns value in string so for no-items it will be "0"
    const totalItemsHeader = headers.get('X-Total-Items');
    return {
      totalPages: parseInt(headers.get('X-Total-Pages')),
      totalItems: totalItemsHeader ? parseInt(totalItemsHeader) : undefined,
      perPage: parseInt(headers.get('X-Per-Page')),
      total: parseInt(headers.get('X-Total')),
      page: parseInt(headers.get('X-Page')),
    };
  }

  public create(
    model: any,
    resource = this.resourceName,
    params = {}
  ): Observable<M> {
    params[model.entity_name] = model.dto();
    return this._requestService.post(`/${resource}`, params, this.headers).pipe(
      map((res) => new this.model(res) as M),
      catchError((error) => throwError(() => error))
    );
  }

  public update(
    model: any,
    resource = this.resourceName,
    params = {}
  ): Observable<M> {
    if (resource.indexOf(':id') > -1) {
      resource = resource.replace(
        ':id',
        model[model.entity_name]?.id || model.id
      );
    } else if (model.id_hash || model.id) {
      resource = `/${resource}/` + (model.id_hash || model.id);
    } else {
      resource = `/${resource}`;
    }
    if (model.entity_name) {
      params[model.entity_name] = model?.dto() ?? model;
    }
    return this._requestService.put(resource, params, this.headers).pipe(
      map((res) => new this.model(res) as M),
      catchError((error) => throwError(() => error))
    );
  }

  public delete(id, resource = this.resourceName, params?): Observable<any> {
    return this._requestService.delete(
      `/${resource}/${id}`,
      params,
      this.headers
    );
  }

  public getById(
    id: string | number,
    resource = this.resourceName,
    params?: any,
    headers = this.headers
  ): Observable<M> {
    if (resource.indexOf(':id') > -1) {
      resource = resource.replace(':id', String(id));
    } else {
      resource = `/${resource}/${id}`;
    }
    return this._requestService
      .get(resource, params, headers, undefined, undefined, true)
      .pipe(
        map((res) => new this.model(res) as M),
        catchError((error) => throwError(() => error))
      );
  }

  // to be done shift params after resource
  public getItems(
    params?: any,
    resource = this.resourceName,
    headers = this.headers
  ): Observable<DefaultListModel<M>> {
    return this._requestService.get(`/${resource}`, params, headers, true).pipe(
      map((data) => ({
        params: this.headersToParamsBag(data.headers),
        list: data.body.map((item) => new this.model(item) as M),
      })),
      catchError((error) => throwError(() => error))
    );
  }

  public getItemsByTradeId(id?: any, headers = this.headers): Observable<any> {
    return this._requestService.get(
      `/v1/pm_builder/universal/trade_tasks/${id}`,
      headers,
      true
    );
  }

  protected _getItems<T>(
    params?: any,
    resource = this.resourceName
  ): Observable<DefaultListModel<T>> {
    return this._requestService
      .get(`/${resource}`, params, this.headers, true)
      .pipe(
        map((data) => ({
          params: this.headersToParamsBag(data.headers),
          list: data.body.map((item) => item as T),
        })),
        catchError((error) => throwError(() => error))
      );
  }
}
