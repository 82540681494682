import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
} from '@angular/material-luxon-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
} from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';

export const FISCAL_FORMATS = {
  parse: {
    dateInput: 'dd MMM',
  },
  display: {
    dateInput: 'dd MMM',
    monthLabel: 'MMM',
    dayMonthLabel: 'dd MMM',
    dateA11yLabel: 'MMMM d, yyyy',
    dayMonthA11yLabel: 'dd MMM',
    monthYearA11yLabel: 'MMM yyyy',
    monthYearLabel: `MMM`,
  },
};

@Component({
  selector: 'app-datepicker-fiscal',
  templateUrl: './datepicker-fiscal.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: FISCAL_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatepickerFiscalComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerFiscalComponent implements ControlValueAccessor {
  @Input() displayErrors: boolean;
  @Input() errors: any;
  @Input() isInline: boolean;
  @Input() name: string = 'fiscal_year_start_at';
  @Input()
  set date(val: DateTime) {
    if (val) {
      this._date =
        typeof val === 'string' ? DateTime.fromFormat(val, 'M-d') : val;
      setTimeout(() => this.onChange(this._date.toFormat('M-d')));
    }
  }

  public customHeader = CustomHeader;

  private _date: DateTime;

  get date(): DateTime {
    return this._date;
  }

  onChange(_: any) {}

  writeValue(date: any) {
    this.date = date;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() {}
}

/** Custom header component for datepicker. */
@Component({
  selector: 'app-fiscal-header',
  styles: [
    `
      .fiscal-header {
        display: flex;
        align-items: center;
        padding: 0.5em;
      }

      .fiscal-header .mat-mdc-button-base:hover {
        background-color: #f5f5f5;
      }
    `,
  ],
  template: `
    <div class="fiscal-header">
      <button
        class="mat-focus-indicator mat-calendar-period-button mat-button mat-button-base"
        (click)="toMonthView()"
      >
        {{ periodLabel }}
      </button>
      <button
        class="mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base"
        (click)="previousClicked()"
      ></button>
      <button
        class="mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base"
        (click)="nextClicked()"
      ></button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CustomHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  get periodLabel() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearLabel
      )
      .toLocaleUpperCase();
  }

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  previousClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this._calendar.activeDate,
      -1
    );
  }

  nextClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this._calendar.activeDate,
      1
    );
  }

  toMonthView() {
    this._calendar.currentView = 'year';
  }
}
