import { BaseStoreService } from './base-store.service';
import { BudgetingBuildingModel } from '../models/BudgetingBuildingModel';
import { BudgetingLocationModel } from '../models/building-distribution.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';

@Injectable()
export class BuildingDistributionService extends BaseStoreService<BudgetingLocationModel> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, BudgetingLocationModel);
  }

  /**
   *
   * @param serviceName - resource name, like 'budgeting', 'forecast'
   * @param params - request parameters
   */

  public getLocations(serviceName, params?): Observable<any> {
    return this._requestService.get(`/v1/${serviceName}/locations`, params);
  }

  public getLocationById(serviceName, locationId, params?): Observable<any> {
    return this._requestService.get(
      `/v1/${serviceName}/locations/${locationId}`,
      params
    );
  }

  public getBuildingsById(
    serviceName,
    locationId,
    params?
  ): Observable<Array<BudgetingBuildingModel>> {
    return this._requestService.get(
      `/v1/${serviceName}/locations/${locationId}/buildings`,
      params
    );
  }

  public getBuildingsByBudgetId(
    serviceName,
    locationId,
    budgetId,
    params?
  ): Observable<Array<BudgetingBuildingModel>> {
    return this._requestService.get(
      `/v1/${serviceName}/${budgetId}/locations/${locationId}/buildings`,
      params
    );
  }

  public getForecastFacilities(
    serviceName,
    serviceId,
    period = 0,
    params
  ): Observable<any> {
    return this._requestService.get(
      `/v1/${serviceName}/${serviceId}/period/${period}/facilities`,
      params
    );
  }
}
