<section class="modal-content__wrapper">
  <header class="modal-content__header">
    {{ isFailed ? 'Are you sure?' : 'Please enter your password:' }}
  </header>

  <form
    #modalForm="ngForm"
    novalidate
    (ngSubmit)="submit()"
    (keydown.enter)="$event.preventDefault()"
  >
    <fieldset class="modal-content__body modal-content__list">
      <div
        class="modal-content__item modal-content__item_full-width"
        *ngIf="!isFailed"
      >
        <h3 class="xs-32 edit-list__title">Password</h3>
        <div class="edit-list__description">
          <label class="xs-100">
            <input
              [(ngModel)]="password"
              [ngClass]="{ 'form-block_error': hasError(_password) }"
              minlength="8"
              required
              class="form-block__element"
              #_password="ngModel"
              name="password"
              type="password"
              appTrimOnBlur
            />
          </label>
          <p class="error" *ngIf="hasError(_password)">
            <ng-container *ngIf="_password.errors.required">
              {{ 'validation.required' | translate }}
            </ng-container>
            <ng-container *ngIf="_password.errors.minlength">
              {{
                'validation.password_short' | translate: { value: 'Password' }
              }}
            </ng-container>
            <ng-container *ngIf="_password.errors.api">
              {{ 'validation.password_invalid' | translate }}
            </ng-container>
          </p>
        </div>
      </div>
      <p
        *ngIf="isFailed"
        class="modal-content__item modal-content__item_full-width"
      >
        This test failed. Do you want to create a failed test report?
      </p>
    </fieldset>

    <div class="buttons-block modal-content__footer">
      <button (click)="cancel()" class="btn action-btn action-btn_default">
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>
      <button class="btn action-btn action-btn_success">
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Submit</span>
      </button>
    </div>
  </form>
</section>
