import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../../core/services/helper.service';
import { OrganizationStoreService } from '../../../core/services/organization-store.service';
import { UserModel } from '../../../core/models/user.model';
import { UserStoreService } from '../../../core/services/user.store';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { UserCurrentService } from '../../../core/services/user-current.service';
import { ImageResizeService } from '../../../core/services/image-resize.service';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Configs } from '../../../core/constants/configs.constants';
import { forkJoin } from 'rxjs';
import { RoutesConstants } from '../../../core/constants/routes.constants';
import { OrganizationModel } from '../../../core/models/organization.model';
import { environment } from '../../../../environments/environment';
import { BaseDetailsComponent } from '../base-details.component';
import { UserChangePasswordComponent } from '../../modals/user-change-password/user-change-password.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeleteModalComponent } from '../../modals/delete-modal/delete-modal.component';
import { BreadcrumbItemModel } from '../../../core/models/breadcrumb-item.model';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';

@Component({
  templateUrl: './user-details.component.html',
})
export class UserDetailsComponent
  extends BaseDetailsComponent<UserModel>
  implements OnInit
{
  public canEdit: boolean;
  public isInited: boolean;
  public isTenant: boolean;
  public organizations: { [key: string]: OrganizationModel };
  public title: string;
  protected org_id: string;
  public imageUrl: string;
  public get isCurrent(): boolean {
    return this.model && this.model.id === this._userCurrent.user?.id;
  }

  constructor(
    protected _organizationStore: OrganizationStoreService,
    protected _userStore: UserStoreService,
    protected _notificationService: CustomNotificationService,
    protected _userCurrent: UserCurrentService,
    protected modalService: BsModalService,
    protected _imageResizingService: ImageResizeService,
    protected _organizationCurrent: OrganizationCurrentService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected _navigationService?: NavigationService,
    public _featureFlagsService?: FeatureFlagsService
  ) {
    super(_notificationService, router, activeRoute, _userStore, 'user_id');
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.org_id = this._organizationCurrent.organization?.id_hash;
    if (this.org_id) {
      this.breadcrumbs = [
        {
          name: 'Settings',
        },
      ];
    }
    if (!HelperService.isExist(this.entityId)) {
      this.setupAsProfile();
    } else {
      this.setupAsUser();
    }

    this.loadUserInfo();
    this._navigationService?.currentBreadcrumb(this.breadcrumbs);
  }

  /**
   * Gets signature url for user
   */
  public getSignature(): string {
    if (
      !this.isInited ||
      !this.model.signature ||
      !this._organizationCurrent.organization
    ) {
      return;
    }
    return `${environment.amazon.shared_bucket_url}/${this.model.signature}`;
  }

  public getAvatar(): void {
    if (!this.isInited) {
      return;
    } else if (
      this._userStore.userAvatarChangedId === this.model.id &&
      this.model.picture
    ) {
      this.imageUrl = `${environment.amazon.shared_bucket_url}/${this.model.picture}`;
    } else
      this._imageResizingService
        .getResizedImageUrl(
          this.model.id,
          Configs.IMAGE_RESIZE_RESOURCE.user,
          Configs.imageDimensions.thumbnail,
          null
        )
        .subscribe({
          next: (data) => {
            this.imageUrl = data['resize_image_url'];
          },
          error: (error) => {
            this._notificationService.error(`${error.error}`);
          },
        });
  }

  public loadUserInfo(): void {
    forkJoin([
      this._userCurrent.get(),
      this.loadModel(),
      this.isTenant
        ? this._organizationStore.all()
        : this._organizationCurrent.get(),
    ]).subscribe({
      next: (res) => {
        if (this.entityId) {
          this.breadcrumbs.push({
            name: this.model?.full_name,
          });
        }
        if (this.isTenant) {
          this.organizations =
            HelperService.formatArrayToObject<OrganizationModel>(
              res[2]['list'],
              'id'
            );
        }
        this.setupUser(res[0]);
        if (this.model?.picture) {
          this.getAvatar();
        }
      },
      error: (err) => this._notificationService.apiError(err),
    });
  }

  public deleteAction(): void {
    this.modalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-holder',
      animated: true,
      initialState: {
        header: 'Warning',
        message: `You are going to delete user <b>"${this.model}"</b>.<br> This action is irreversible.`,
      },
    });
    this.subscriptions.add(
      this.modalRef.content.onResult.subscribe(() => {
        this.store.delete(this.model.id).subscribe({
          next: () => {
            this.listMode();
            this._notificationService.success(
              `User ${this.model} has been deleted`
            );
          },
          error: (err) => this._notificationService.apiError(err),
        });
      })
    );
  }

  public lockAccount(): void {
    this.model.blocked = 1;
    this._userStore.lockAccount(this.model).subscribe({
      next: () => {
        this._notificationService.success(
          `User "${this.model.toString()}" has been locked`
        );
        this.model.blocked = 1;
      },
      error: (err) => {
        this.model.blocked = 0;
        this._notificationService.apiError(err);
      },
    });
  }

  public unlockAccount() {
    this.model.blocked = 0;
    this._userStore.unlockAccount(this.model).subscribe({
      next: () => {
        this._notificationService.success(
          `User "${this.model.toString()}" has been unlocked`
        );
        this.model.blocked = 0;
      },
      error: (err) => {
        this.model.blocked = 1;
        this._notificationService.apiError(err);
      },
    });
  }

  public changePassword() {
    if (this.model && this.model.id === this._userCurrent.user?.id) {
      this.modalRef = this.modalService.show(UserChangePasswordComponent, {
        class: 'modal-holder',
        animated: true,
      });
    }
  }

  protected setupAsProfile() {
    this.entityId = this._userCurrent.user?.id;
    this.isTenant = this._userCurrent.user.is_tenant;
    if (this.org_id) {
      this.breadcrumbs.push({
        name: 'My Profile',
      });
    }
  }

  protected setupAsUser() {
    this.isTenant = !this.activeRoute.snapshot.params['org_id'];
    this.title = this.isTenant ? 'Tenant Users' : 'Users';
    const breadcrumb: BreadcrumbItemModel = {
      name: this.title,
      link: this.setPageInBreadcrumbs([
        this.isTenant
          ? RoutesConstants.TENANT_USERS.LIST
          : RoutesConstants.USERS.LIST(this.org_id),
      ]),
    };
    this.breadcrumbs.push(breadcrumb);
  }

  protected setupUser(currentUser: UserModel): void {
    if (this.model.is_pending) {
      this.router.navigate([RoutesConstants.NOT_FOUND], { replaceUrl: true });
    }
    this.isInited = true;
    this.setCanEditFlags(currentUser.role_name, this.model.role_name);
  }

  private setCanEditFlags(currentRole, userRole) {
    const isTenant =
      currentRole === 'tenant_admin' ||
      (currentRole === 'tenant_user' && userRole.includes('client'));

    const isClientAdmin =
      (currentRole === 'client_admin' &&
        (userRole === 'client_user' || userRole === 'client_engineer')) ||
      (currentRole === 'partner_admin' &&
        (userRole === 'client_user' || userRole === 'client_engineer'));

    if (isTenant || isClientAdmin) {
      this.canEdit = true;
    }
  }

  public getOrganizationLabelText(model): string {
    if (model.sale_role_text) {
      return 'Solution Consultant Organizations';
    } else {
      switch (model.role_name) {
        case 'partner_admin':
          return 'Partner Admin Organisations';
        case 'partner_engineer':
          return 'Partner Engineer Organisations';
        default:
          return 'Engineer Organization';
      }
    }
  }
}
