<section class="modal-content__wrapper {{ modalWrapperClass }}">
  <header class="modal-content__header">{{ header }}</header>

  <section class="modal-content__body">
    <ng-container *ngIf="messagesArr && messagesArr.length">
      <p
        class="modal-content__message modal-content__message_mrgn mrgn-b_sm"
        *ngFor="let msg of messagesArr"
        [innerHTML]="msg"
      ></p>
    </ng-container>

    <ng-container *ngIf="message">
      <p class="modal-content__message mrgn-b_sm" [innerHTML]="message"></p>
      <ng-container *ngIf="fieldList && fieldList.length">
        <p class="modal-content__message" *ngFor="let field of fieldList">
          {{ field }}
        </p>
      </ng-container>
    </ng-container>
  </section>
  <footer
    class="buttons-block modal-content__footer"
    [ngClass]="{ 'buttons-block_to-center': buttonsToCenter }"
  >
    <button
      type="button"
      *ngIf="!hideCancel"
      (click)="cancel()"
      class="btn action-btn action-btn_{{ cancelClass }}"
    >
      <span class="action-btn__icon" *ngIf="!!cancelIcon"
        ><app-svg [href]="'times'"></app-svg
      ></span>
      <span class="action-btn__title">{{ cancelText }}</span>
    </button>

    <button
      type="button"
      *ngIf="!hideReset"
      (click)="reset()"
      class="btn action-btn action-btn_{{ resetClass }}"
    >
      <span class="action-btn__icon"><app-svg [href]="'undo'"></app-svg></span>
      <span class="action-btn__title">{{ resetText }}</span>
    </button>

    <button
      *ngIf="!hideSubmit"
      (click)="submit()"
      type="button"
      class="btn action-btn action-btn_{{ submitClass }}"
    >
      <span class="action-btn__icon" *ngIf="!!submitIcon"
        ><app-svg [href]="submitIcon"></app-svg
      ></span>
      <span class="action-btn__title">{{ submitText }}</span>
    </button>
  </footer>
</section>
