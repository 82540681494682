import { DateTime } from 'luxon';
import 'chartjs-adapter-luxon';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ColorsConstants } from '../../../core/constants/colors.constants';
import { BaseChartComponent } from '../base-chart.component';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLineComponent extends BaseChartComponent {
  @Input() public chartPeriod: string;

  @Input()
  protected set chartValues(value) {
    if (this.helper.isExist(value)) {
      this._chartValues = value;
      this.updateCharts();
    }
  }
  protected get chartValues(): any {
    return this._chartValues;
  }
  private maxValue: number;
  private firstChartPointDate;
  private lastChartPointDate;

  private createCharts(): void {
    this.chart = {
      type: 'line',
      data: this.getDatasets(this.chartValues),
      options: this.getOptions(),
      plugins: [
        {
          id: 'verticalline',
          afterDatasetsDraw: (chart) => {
            if (chart.tooltip?._active?.length && chart.scales?.y) {
              let x = chart.tooltip._active[0].element.x;
              let { top, bottom } = chart.scales.y;

              chart.ctx.save();
              chart.ctx.beginPath();
              chart.ctx.moveTo(x, top);
              chart.ctx.lineTo(x, bottom);
              chart.ctx.lineWidth = 1;
              chart.ctx.strokeStyle = '#8597A6';
              chart.ctx.stroke();
              chart.ctx.restore();
            }
          },
        },
      ],
    };
  }

  protected updateCharts(): void {
    this.maxValue = this.getMaxValue(this.chartValues);
    this.createCharts();
  }

  /**
   * Calculate max Y value for graphs
   * @param data
   * @returns {Number}
   */
  private getMaxValue(data): number {
    let maxValue = 100;
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        for (let val of data[prop]) {
          if (val && val.y > maxValue) {
            maxValue = val.y;
          }
        }
      }
    }
    return maxValue > 100 ? 200 : 100;
  }

  private getTimeOptions(period: string): any {
    const opt: any = {};
    switch (true) {
      case period === 'rolling':
        opt.suggestedMin = this.firstChartPointDate
          ? DateTime.fromISO(this.firstChartPointDate)
          : DateTime.now().minus({ year: 1 }).startOf('day');
        break;
      case period === 'lifetime':
        delete opt.suggestedMax;
        opt.suggestedMin = DateTime.fromISO(this.firstChartPointDate);
        break;
      default:
        opt.suggestedMin = this.maxDate;
    }
    opt.suggestedMin = DateTime.min(
      DateTime.fromISO(this.firstChartPointDate),
      DateTime.fromISO(opt.min)
    ).toISO();
    if (period !== 'lifetime') {
      opt.suggestedMax = DateTime.fromISO(this.lastChartPointDate).toISO();
    }
    return opt;
  }

  protected getOptions(): any {
    return {
      maintainAspectRatio: false,
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: ({ raw: { y, z } }) => Math.floor(z || y) + '%',
          },
        },
      },
      scales: {
        y: {
          suggestedMax: Math.ceil(this.maxValue / 4) * 4,
          beginAtZero: true,
          ticks: {
            callback: (value) => value + '%',
            stepSize: Math.ceil(this.maxValue / 4),
          },
        },
        x: {
          grid: {
            display: false,
          },
          type: 'time',
          ticks: {
            font: {
              size: 10,
            },
            maxRotation: 0,
            autoSkip: true,
            maxTicksLimit: 12,
          },
          time: {
            unit: 'month',
            tooltipFormat: 'yyyy MMM dd, HH:mm',
          },
          ...this.getTimeOptions(this.chartPeriod),
        },
      },
    };
  }

  private getDatasetItem(color: string, data: any): any {
    return {
      fill: false,
      backgroundColor: 'rgba(220,220,220,0.2)',
      borderColor: color,
      borderWidth: 1.5,
      pointBorderColor: color,
      pointBackgroundColor: '#fff',
      pointHoverRadius: 3,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: color,
      pointHoverBorderWidth: 2,
      pointRadius: 0.1,
      data: data,
      segment: {
        borderDash: (ctx) => (ctx.p0.raw.z && ctx.p1.raw.z ? [10, 5] : [10, 0]),
      },
      tension: 0,
      spanGaps: true,
    };
  }

  protected getDatasets(data: any): any {
    const datasets = [];

    for (const prop in data) {
      if (data.hasOwnProperty(prop) && data[prop]?.length) {
        this.firstChartPointDate = DateTime.min(
          DateTime.fromISO(this.firstChartPointDate),
          DateTime.fromISO(data[prop][0].x)
        );
        if (!this.lastChartPointDate) {
          this.lastChartPointDate = DateTime.fromISO(data[prop].at(-1).x);
        } else {
          this.lastChartPointDate = DateTime.max(
            DateTime.fromISO(this.lastChartPointDate),
            DateTime.fromISO(data[prop].at(-1).x)
          );
        }

        if (['fhim', 'fhir', 'fhie', 'fhi'].some((el) => el === prop)) {
          datasets.push(
            this.getDatasetItem(ColorsConstants.CHARTS[prop], data[prop])
          );
        } else {
          datasets.push(this.getDatasetItem('#6ABCDE', data[prop]));
        }
      }
    }
    return datasets;
  }
}
