import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  NgControl,
  NgForm,
} from '@angular/forms';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { Configs } from '../../core/constants/configs.constants';
import { CustomNotificationService } from '../../core/services/custom-notification.service';
import { EventService } from '../../core/services/event.service';
import { HelperService } from '../../core/services/helper.service';
import { REGEX } from '../../core/constants/regex.constants';

@Injectable()
export class BaseFormComponent implements OnDestroy {
  public form: any;
  public isLoading: boolean;
  public validators: any = {};
  tdForm?: NgForm;
  protected isTemplateDriven: boolean;
  protected submitted: boolean;
  protected subscriptions: Subscription = new Subscription();
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  public get hasSubmitted(): boolean {
    return this.submitted;
  }

  public get isFormValid(): boolean {
    return this.form.valid;
  }

  public get regex(): any {
    return REGEX;
  }

  constructor(
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    this.isTemplateDriven = true;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscriptions.unsubscribe();
  }

  public getValidators(form, hasSubmitted: boolean): any {
    const validators = {};
    Object.getOwnPropertyNames(form.controls).forEach((field) => {
      if (form.controls[field].constructor === FormArray) {
        const array = form.controls[field] as FormArray;
        validators[field] = [];
        for (let i = 0; i < array.length; i++) {
          const element = array.at(i);
          validators[field][i] = this.getValidators(
            element as FormGroup,
            hasSubmitted
          );
        }
      } else if (form.controls[field].constructor === FormGroup) {
        validators[field] = this.getValidators(
          form.controls[field] as FormGroup,
          hasSubmitted
        );
      } else {
        validators[field] =
          (form.controls[field].dirty || hasSubmitted) &&
          !form.controls[field].valid;
      }
    });
    return validators;
  }

  public validateForm(form?: FormGroup): void {
    Object.assign(
      this.validators,
      this.getValidators(form || this.form || this.tdForm, this.hasSubmitted)
    );
  }

  public submit(): void {
    this.hasSubmitted = true;
    if (this.tdForm.invalid) {
      this.validateForm();
    }
  }

  public hasError($el: NgControl): boolean {
    return $el && $el.touched && $el.invalid;
  }

  public set hasSubmitted(value: boolean) {
    this.submitted = value;
    if (this.form && value) {
      this.markFormGroupTouched();
    }
  }

  public markAsUntouched() {
    if (this.isTemplateDriven) {
      Object.keys(this.tdForm.controls).forEach((field) => {
        this.tdForm.controls[field].markAsPristine();
      });
    } else {
      Object.keys(this.form.controls).forEach((field) => {
        this.form.controls[field].markAsPristine();
      });
    }
  }

  protected markFormGroupTouched() {
    if (this.isTemplateDriven) {
      Object.keys(this.tdForm.controls).forEach((field) => {
        this.tdForm.controls[field].markAsTouched();
      });
    } else {
      Object.keys(this.form.controls).forEach((field) => {
        this.form.controls[field].markAsTouched();
      });
    }
  }

  protected parseErrors(error, customFieldName?: string): void {
    const errorDictionary = error.error
      ? error.error.errors || error.error.error || error.error
      : error;

    if (typeof errorDictionary === 'string') {
      this._notificationService.apiError(error);
    } else if (Array.isArray(errorDictionary)) {
      // new errors parsing
      errorDictionary.forEach((errorMessage) => {
        if (errorMessage && errorMessage.key && this.isTemplateDriven) {
          let key = errorMessage.attr || errorMessage.key;

          if (HelperService.isExist(errorMessage.index)) {
            key = key.split('.')[0] + '.' + errorMessage.index;
          }

          const control = this.tdForm.controls[
            customFieldName || key
          ] as FormControl;

          if (control) {
            const message = errorMessage.message;
            this.setError(control, 'api', message.capitalize());
          } else {
            this._notificationService.apiError(errorMessage);
          }

          this._eventService.broadcast(Configs.EVENTS.FORM_ERRORS, errorMessage);
        } else {
          this._notificationService.apiError(error);
        }
      });

      return;
    } else if (
      errorDictionary &&
      Object.keys(errorDictionary) &&
      this.isTemplateDriven
    ) {
      Object.keys(errorDictionary).forEach((errorField) => {
        const control = this.tdForm.controls[errorField] as FormControl;

        if (control) {
          const message = Array.isArray(errorDictionary[errorField])
            ? errorDictionary[errorField][0]
            : errorDictionary[errorField];
          this.setError(
            control,
            'api',
            `${errorField} ${message}`.capitalize()
          );
        } else {
          if (errorField === 'custom_attributes') {
            this._notificationService.apiError({
              error: `Custom attribute ${errorDictionary[errorField][0]}`,
            });
          } else {
            this._notificationService.apiError(errorDictionary);
          }
        }
      });

      this._eventService.broadcast(Configs.EVENTS.FORM_ERRORS);
    } else {
      this._notificationService.apiError(error);
    }
  }

  protected setError(
    control: FormControl | AbstractControl,
    error: string,
    message?: string
  ) {
    if (!message) {
      if (control.errors) {
        delete control.errors[error];

        if (!Object.keys(control.errors).length) {
          control.setErrors(null);
        }
      }
    } else {
      let errors = {};
      if (control.errors) {
        errors = { ...control.errors };
      }

      if (message === 'true') {
        errors[error] = true;
      } else {
        errors[error]
          ? (errors[error] += `. ${message}`)
          : (errors[error] = message);
      }

      control.setErrors(errors);
    }
  }
}
