import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { IExportParams } from '../models/export-params.model';
import { Observable } from 'rxjs/internal/Observable';
import { IBgJobModel } from '../models/bg-worker.model';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(public _requestService: RequestService) {}

  public export(type: string, params: IExportParams): Observable<IBgJobModel> {
    return this._requestService.post(`/v1/${type}/export/async`, params);
  }
}
