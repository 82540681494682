import { AddressDTO } from './address.dto';
import { IUserFacilities, UserModel } from '../models/user.model';
import { DefaultDTO } from './default.dto';

export class UserDTO extends DefaultDTO {
  first_name: string;
  last_name: string;
  bio: string;
  email: string;
  phone: string;
  position: string;
  picture: string;
  blocked: number;
  role_name: string;
  address_attributes: AddressDTO;
  signature: string;
  organization_ids?: Array<string>;
  eam?: {
    eam_role_name?: string;
    organization_hash?: string;
  };
  facilities_attributes: IUserFacilities[];

  constructor(user: UserModel) {
    super();
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.bio = user.bio;
    this.email = user.email;
    this.phone = user.phone;
    this.position = user.position;
    this.picture = user.picture;
    this.blocked = user.blocked;
    this.role_name = user.role_name;
    this.address_attributes = new AddressDTO(user.address);
    this.signature = user.signature;
    this.organization_ids = user.organization_ids;
    this.facilities_attributes = user.default_facilities;
  }
}
