<div class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap" *ngIf="!editing">
  <p class="date-form__text no-wrap">{{ data.value | dateFormat }}</p>
  <button
    class="btn btn_iconed"
    *ngIf="!valueChanged || !showUndo"
    (click)="editing = !editing"
  >
    <app-svg [href]="'calendar'"></app-svg>
  </button>

  <button
    class="btn btn_iconed"
    *ngIf="valueChanged && showUndo"
    (click)="setDefaultDate()"
  >
    <app-svg [fill]="'green'" [href]="'undo'"></app-svg>
  </button>
</div>

<form [hidden]="!editing">
  <label>
    <mat-form-field class="datepicker-block datepicker-block_narrow">
      <input
        type="text"
        name="date"
        placeholder="Enter a date"
        matInput
        #_date="ngModel"
        [matDatepicker]="datepicker"
        [min]="minDate"
        [(ngModel)]="data.value"
        (blur)="emitChangedDate()"
      />
      <mat-datepicker-toggle matSuffix [for]="datepicker">
        <mat-icon matDatepickerToggleIcon>
          <app-svg [href]="'datepicker-calendar'"></app-svg>
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #datepicker [startAt]="data.value"></mat-datepicker>
    </mat-form-field>
  </label>
  <p class="error" *ngIf="hasError(_date)">
    <ng-container *ngIf="_date.errors.matDatepickerMax">
      {{ 'validation.min_date' | translate }}
    </ng-container>
    <ng-container *ngIf="_date.errors.matDatepickerParse">
      {{ 'validation.bad_date' | translate }}
    </ng-container>
  </p>
</form>
