export class LogModel {
  created_at: string;
  data: any;
  event: string;
  id: number;
  logable_id: number;
  logable_type: string;
  user_id: number;
  user_name: string;
  showDetails?: boolean;
  frontend_date?: any;

  constructor(data) {
    Object.assign(this, data);
  }
}
