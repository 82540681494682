import { DefaultModel } from './default.model';
import { AssessmentDTO } from '../dto/assessment.dto';
import { AssetModel } from './asset.model';
import { UserModel } from './user.model';
import { DateTime } from 'luxon';

export class AssessmentModel extends DefaultModel<AssessmentDTO> {
  id: number;
  asset_id: number;
  score: number;
  comment: string;
  completed_at: string;
  created_at: string;
  user_id: number;
  user_name: string;
  asset?: AssetModel;
  user?: UserModel;

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
      this.completed_at = DateTime.fromISO(model.completed_at).toISO();
    }
  }

  public dto(): AssessmentDTO {
    return new AssessmentDTO(this);
  }

  public get entity_name(): string {
    return 'assessment';
  }

  public toString(): string {
    return this.name;
  }

  public get name(): string {
    return this.entity_name.capitalize();
  }
}
