import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  asapScheduler,
  debounceTime,
  filter,
  map,
  switchMap,
} from 'rxjs';
import { IAutoCompleteOptions, IPatternRegex } from 'src/app/interfaces';
import {
  MatAutocomplete,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';

import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { VIRTUAL_SCROLL_CONFIG } from './autocomplete-input.config.';
@Component({
  selector: 'app-autocomplete-input',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    ScrollingModule,
    MatIconModule,
    A11yModule,
  ],
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.less'],
})
export class AutocompleteInputComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('auto') autoComplete!: MatAutocomplete;

  private scrollListener!: (event) => void;
  @Input() formGroup: FormGroup = new FormGroup({});

  @Input() controlName?: string = 'autoComplete';

  // height of row in pixels;
  @Input() itemSize = 31;

  @Input() placeholderText: string;

  @Input() ariaLabel: string;

  @Input() ariaLabelledBy: string = '';

  @Input() id: string = 'autocomplete-input';

  @Input() label: string;

  @Input() virtualScrollHeight: string = '150px';
  @Input() calcVirtualScrollHeight: boolean = false;

  @Input()
  set options(options: IAutoCompleteOptions[]) {
    this._options.next(options);
  }

  get options(): IAutoCompleteOptions[] {
    return this._options.value;
  }

  @Input() getOptionsList: (
    value: string
  ) => Observable<IAutoCompleteOptions[]>;

  @Input() minLength: number = 1;

  @Input() maxCharLength: number;

  @Input() serverFiltering: boolean = false; // to know whether the options list changes by making api call

  @Input() showNoRecordMessage: boolean = false;

  @Input() noRecordMessage: string = 'Not Found';

  @Input() patternRegex: IPatternRegex;
  //Behaviors for how the subscript height is set.
  @Input() subscriptSizing: 'fixed' | 'dynamic' = 'dynamic';

  @Input()
  classList: string | string[] = '';
  @Input() isFocusOnFirstInputEnabled: boolean = true;
  @Input() shouldShowIconsOnSuffix: boolean = false;
  @Input() shouldShowIconsOnPrefix: boolean = true;
  @Input() shouldStyleFormPanel: boolean = false;
  @Input() isScrollAdjustmentEnabled: boolean = false;

  @Output() optionSelectedEvent: EventEmitter<IAutoCompleteOptions> =
    new EventEmitter();
  @Output() closed: EventEmitter<void> = new EventEmitter();

  filteredOptions: IAutoCompleteOptions[] = [];
  virtualScrollConfig = VIRTUAL_SCROLL_CONFIG;
  height: string;
  _options: BehaviorSubject<IAutoCompleteOptions[]> = new BehaviorSubject<
    IAutoCompleteOptions[]
  >([]);
  optionNotFound: boolean = false;
  errorIcon: string = 'exclamation-triangle-solid';
  isRequired: boolean = false;
  @ViewChild('auto') matAutoCompleteElt: MatAutocomplete;
  @ViewChild('firstInput')
  firstInput: ElementRef;
  isOptionSelected: boolean = false;
  prevFocusElement: HTMLElement;
  get formControl(): AbstractControl {
    return this.formGroup.get(this.controlName);
  }

  get isformControlError(): ValidationErrors {
    return (
      (this.formControl?.touched || this.formControl?.dirty) &&
      this.formControl?.errors
    );
  }

  get isSearchParentAsset() {
    return this.placeholderText === 'Search Parent Asset';
  }

  get ariaLabelledByIds(): string {
    let ids: string = `${this.ariaLabelledBy || this.controlName + 'label'} `;

    if (this.patternRegex?.description) {
      ids += this.controlName + 'regexDescription ';
    }

    if (this.isformControlError) {
      ids += this.controlName + '_err';
    }
    return ids;
  }

  displayFn = (value: string): void => {
    this.options.find((option) => option.value === value)?.label || value;
  };
  /**
   * fetch account details
   * @param event autocomplete event
   */
  optionSelected(event: IAutoCompleteOptions): void {
    this.isOptionSelected = true;
    this.formControl.setValue(event.label);
    this.optionSelectedEvent.emit(event);
  }

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
    this.ngZone.run(() => {
      this.scrollListener = (event) => {
        if (
          this.autoComplete._isOpen &&
          !event.target.className.includes('cdk-virtual-scroll-viewport')
        ) {
          this.autoComplete._isOpen = false;
          this.clearInput();
        }
      };

      window.addEventListener(
        'scroll',
        (event) => this.scrollListener(event),
        true
      );
    });
    this.prevFocusElement = document.activeElement as HTMLElement;
    if (!this.formGroup.controls[this.controlName]) {
      this.formGroup.addControl(this.controlName, new FormControl(''));
    }
    this.isRequired = this.formControl.hasValidator(Validators.required);
    this.subscribeToControlValueChanges();
    if (this.isScrollAdjustmentEnabled) {
      this.virtualScrollHeight = '190px';
    }
  }

  ngAfterViewInit(): void {
    if (this.isFocusOnFirstInputEnabled) {
      asapScheduler.schedule(() => {
        this.firstInput?.nativeElement.focus();
      }, 0);
    }
  }

  private subscribeToControlValueChanges(): void {
    this.formControl.valueChanges
      .pipe(
        filter((value: string) => {
          // value = value?.trim();
          if (this.isOptionSelected) {
            this.isOptionSelected = false;
            return false;
          }
          const minLengthCheck: boolean =
            value && value.length >= this.minLength;
          if (!minLengthCheck && typeof value === 'string') {
            this.optionNotFound = false;
            this.filteredOptions = [];
          }
          return minLengthCheck;
        }),
        debounceTime(300),
        switchMap((value: string) =>
          this.serverFiltering
            ? this.getOptionsList(value)
            : this._options.asObservable().pipe(map(() => this._filter(value)))
        )
      )
      .subscribe((options: IAutoCompleteOptions[]) => {
        this.filteredOptions = [...options];
        this.optionNotFound = !options.length;
        this.setVirtualScrollHeight(options);
      });
  }

  setVirtualScrollHeight(options: IAutoCompleteOptions[]): void {
    if (this.calcVirtualScrollHeight) {
      if (!options.length) {
        this.height = 0 + 'px';
      } else {
        this.height =
          Math.min(
            VIRTUAL_SCROLL_CONFIG.heightPx,
            this.itemSize * options.length
          ) + 'px';
      }
    } else {
      this.height = this.virtualScrollHeight;
    }
  }

  /**
   * emit keypress event
   * @param event Event emitted on keypress
   * @returns True if the pattern is matched
   */
  onKeypress(event: KeyboardEvent): boolean {
    return this.patternRegex?.pattern ? this.validateInputPattern(event) : true;
  }

  /**
   * clear input field
   *
   */
  clearInput(): void {
    this.filteredOptions = [];
    this.formControl.setValue('');
  }

  /**
   * Validate input with given pattern regerx and remove from field if not allowed
   * @param event Event emitted on keypress
   * @returns True for certain key codes
   */
  private validateInputPattern(event: KeyboardEvent): boolean {
    if (this.patternRegex.pattern.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  private _filter(value: string): IAutoCompleteOptions[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  ngOnDestroy(): void {
    this.prevFocusElement.focus();
    window.removeEventListener(
      'scroll',
      (event) => this.scrollListener(event),
      true
    );
  }
}
