import { DefaultDTO } from './default.dto';
import { AddressModel } from '../models/address.model';
import { LocationModel } from '../models/location.model';
import { PictureModel } from '../models/picture.model';
import { HelperService } from '../services/helper.service';
import { AddressDTO } from './address.dto';

export class BuildingsDTO extends DefaultDTO {
  name?: any;
  location?: LocationModel;
  address?: AddressModel;
  address_attributes?: AddressDTO;
  id: number;
  fhie?: number;
  pictures?: Array<PictureModel>;
  floors?: number;
  assets_count?: number;
  udfs?: any;
  tag_ids?: number[];
  sprinkler_protected_modifier: number;

  constructor(building: any) {
    super();
    Object.assign(this, building);
    this.sprinkler_protected_modifier = HelperService.convertPercentToFloat(
      this.sprinkler_protected_modifier
    );
    this.address_attributes = new AddressDTO(building.address);
    this.udfs = this.removeEmptyUdfs(building.udfs);
  }

  public removeEmptyUdfs(udfs) {
    for (const key in udfs) {
      if (udfs.hasOwnProperty(key)) {
        if (!udfs[key]) {
          udfs[key] = null;
        }
      }
    }
    return udfs;
  }
}
