import { TooltipConfig } from 'ngx-bootstrap/tooltip';

export const DefaultTooltipOptions: TooltipConfig = Object.assign(
  new TooltipConfig(),
  {
    delay: 500,
    placement: 'bottom',
    container: 'body',
  }
);
