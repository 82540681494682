import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  inject,
} from '@angular/core';

import { EventService } from '../../../core/services/event.service';
import { HelperService } from 'src/app/core/services/helper.service';
import { REGEX } from '../../../core/constants/regex.constants';
import { SnotifyService } from 'ng-alt-snotify';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-value-form-edit',
  // to be done possibly replace with del ins tags
  templateUrl: './value-form-edit.component.html',
  styleUrls: ['./value-form-edit.component.less'],
})
export class ValueFormEditComponent implements OnInit, OnChanges {
  @Input() public genericValue: any;
  @Input() public customValue: any;
  @Input() public bold: boolean = false;
  @Input() public noZero: boolean = false;
  @Input() public apiError: string = '';
  @Input() public editPosition: string = 'right';
  @Input() agGridView: boolean = false;
  @Input() set disabled(v: boolean) {
    this._disabled = v;
    if (v) {
      this.editing = false;
    }
  }
  @Output() updateValue = new EventEmitter();

  public editing: boolean = false;
  public data: {
    value: number;
    oldValue: number;
  };
  public valueChanged: boolean = false;
  public validationError: boolean = false;
  public validationMessage: string = '';
  public isSubmitted: boolean;
  private _disabled: boolean;
  private maxValue: number = 1e7;
  private snotifyService: SnotifyService = inject(SnotifyService);
  ngUnsubscribe: Subject<void> = new Subject<void>();
  errorId;
  public get disabled(): boolean {
    return this._disabled;
  }

  public get helper() {
    return HelperService;
  }

  public get regex() {
    return REGEX;
  }

  constructor(
    private _eventService: EventService,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this.valueChanged = false;
    this.setupValues();
    this.isSubmitted = false;
    this._eventService.on('project.updateList', () => {
      this.valueChanged = false;
    });
  }

  checkForErrors(): void {
    if (
      this.agGridView &&
      this.editing &&
      (this.validationError || this.apiError)
    ) {
      const msgToShow = `${this.validationMessage ?? ''} ${this.validationMessage ? '. ' : ''} ${this.apiError ?? ''}`;
      const toast = this.snotifyService.get(this.errorId);
      if (!toast?.body.includes(msgToShow)) {
        this.snotifyService.remove(this.errorId, true);
        this.errorId = this.snotifyService.error(msgToShow, '', {
          bodyMaxLength: 400,
          showProgressBar: false,
          timeout: 10000,
        }).id;
      }
    }
  }

  ngOnChanges() {
    if (this.apiError?.length) {
      if (Array.isArray(this.apiError)) {
        this.apiError = this.apiError.map((err) => err.message).join('. ');
      }
      this.editing = true;
      this.checkForErrors();
      this.isSubmitted = false;
    }
  }

  public emitSaveValue(htmlValue: string) {
    if (!this.validationError && !this.isSubmitted) {
      this.data.value = Number(htmlValue);
      this.editing = false;
      if (this.data.value !== this.data.oldValue) {
        this.isSubmitted = true;
        this.valueChanged = true;
        this.updateValue.emit(this.data.value);
      } else {
        this.valueChanged = false;
      }
    }
  }

  public setupValues() {
    this.data = {
      value: parseFloat(this.customValue),
      oldValue: parseFloat(this.genericValue),
    };
    if (this.customValue !== this.genericValue) {
      this.valueChanged = true;
    }
  }

  public emitResetValue() {
    if (!(this.noZero && !this.data.oldValue)) {
      this.valueChanged = false;
    }
    this.data.value = this.data.oldValue;
    this.updateValue.emit(this.data.value);
  }

  public validateContent(htmlValue: string) {
    let regexToValidate = 'floatingPositiveNumbers';
    if (this.noZero) {
      regexToValidate = 'floatingPositiveNumbersNoZero';
    }
    this.validationError = !(
      htmlValue.match(this.regex[regexToValidate]) &&
      Number(htmlValue) < this.maxValue
    );
    if (this.validationError) {
      if (!htmlValue.match(this.regex[regexToValidate])) {
        this.validationMessage =
          this._translateService.instant('validation.number');
      } else {
        this.validationMessage = this._translateService
          .instant('validation.lessThen')
          .replace('{{number}}', String(this.maxValue))
          .replace('{{name}}', 'value');
      }
      this.checkForErrors();
    }
  }

  public stopEditingIfEsc() {
    this.validationError = false;
    this.data.value = this.data.oldValue;
    this.editing = false;
  }
}
