import { Component, Input } from '@angular/core';
import { ExportModalComponent } from '../export.modal/export.modal.component';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ErrorService } from '../../../core/services/error.service';
import { ExportService } from '../../../core/services/export.service';
import { AutoUnsubscribeService } from '../../../core/services/auto-unsubscribe.service';
import { BgJobService } from '../../../core/services/bg-worker.service';
import { ErrorModel } from '../../../core/models/error.model';
import { IExportTypeOption } from '../../../core/models/export-type-option.model';
import { IExportParams } from '../../../core/models/export-params.model';

@Component({
  selector: 'app-export-with-options-modal',
  templateUrl: './export-with-options.modal.component.html',
})
export class ExportWithOptionsModalComponent extends ExportModalComponent {
  @Input() options: IExportTypeOption[] = [];

  private multilevelOptions: boolean;

  protected get params(): IExportParams {
    const params = this.exportAddOns;
    const option = this.options.find((el) => el.type === this.type);

    if (option?.group) {
      return { ...params, ...this.form.value[option.groupName] };
    }

    if (!this.multilevelOptions) {
      if (this.form.value.order_by === 'total_risk_rank') {
        return { ...params, ...this.form.value, ...{ direction: 'desc' } };
      } else {
        return { ...params, ...this.form.value };
      }
    }

    return params;
  }

  constructor(
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected formBuilder: FormBuilder,
    protected _modalService: BsModalService,
    protected _errorService: ErrorService,
    protected _exportService: ExportService,
    protected _destroy: AutoUnsubscribeService,
    protected _bgJobService: BgJobService<ErrorModel>
  ) {
    super(
      _notificationService,
      _eventService,
      formBuilder,
      _modalService,
      _errorService,
      _destroy,
      _bgJobService,
      _exportService
    );
  }

  ngOnInit(): void {
    this.multilevelOptions = this.options.some((el) => el.group);
    this.status = 'static';
    this.setupForm();
  }

  protected setupForm(): void {
    const form: any = {};

    this.options.forEach((option) => {
      form[option.fieldApiName || 'exportType'] = new FormControl(
        '',
        Validators.required
      );
      if (option.group) {
        const nestedForm = {};
        option.groupName = option.type + 'Group';
        option.group.forEach(
          (nestedOption) =>
            (nestedForm[nestedOption.fieldApiName] = new FormControl(''))
        );
        form[option.groupName] = this.formBuilder.group(nestedForm);
      }
    });

    this.form = this.formBuilder.group(form);
  }

  public saveAction(): void {
    if (this.params.order_by === 'total_risk_rank') {
      this.params.direction = '';
    }
    if (this.isFormValid) {
      this.type ||= this.form.value.exportType;
      this.status = 'progress';
    }
  }
}
