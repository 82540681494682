import { Injectable } from '@angular/core';
import { Route, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard {
  constructor(
    private featureFlagsService: FeatureFlagsService,
    private router: Router
  ) {}
  canActivate(
    route: Route
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      route.data.feature &&
      this.featureFlagsService.isFeatureEnabled(route.data.feature)
    ) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
