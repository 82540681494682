<div
  class="autocomplete-input"
  matAutocompleteOrigin
  #origin="matAutocompleteOrigin"
  cdkTrapFocus
>
  <button
    matPrefix
    *ngIf="shouldShowIconsOnPrefix"
    class="close-icon"
    (click)="closed.emit()"
    (keyup.enter)="closed.emit()"
    tabindex="0"
  >
    <mat-icon aria-label="close" aria-hidden="true" class="d-flex"
      >close</mat-icon
    >
  </button>

  <!--Autocomplete input field -->
  <div [formGroup]="formGroup">
    <div class="mat-input-field-wrapper" [ngClass]="classList">
      <mat-form-field appearance="fill" [subscriptSizing]="subscriptSizing">
        <mat-label
          class="hover-class"
          [id]="controlName + 'label'"
          *ngIf="label"
        >
          {{ label }}
        </mat-label>
        <mat-icon
          *ngIf="shouldShowIconsOnPrefix"
          matPrefix
          aria-hidden="false"
          aria-label="Search"
          >search</mat-icon
        >
        <input
          matInput
          type="text"
          [formControlName]="controlName"
          [matAutocomplete]="auto"
          [required]="isRequired"
          [attr.id]="id"
          [maxlength]="maxCharLength"
          (keypress)="onKeypress($event)"
          (blur)="!filteredOptions.length && clearInput()"
          [attr.aria-labelledby]="ariaLabelledByIds"
          [placeholder]="placeholderText"
          [matAutocompleteConnectedTo]="origin"
          #firstInput
          [ngClass]="{ 'style-placeholder': !isSearchParentAsset }"
        />
      </mat-form-field>
      <ng-container *ngIf="shouldShowIconsOnSuffix">
        <button
          matSuffix
          *ngIf="formGroup?.get(controlName)?.value"
          class="suffix-close-icon"
          type="button"
          (click)="clearInput()"
          (keyup.enter)="clearInput()"
          tabindex="0"
        >
          <mat-icon
            aria-label="close"
            aria-hidden="true"
            class="icon-margin-left d-flex"
            >close</mat-icon
          >
        </button>
        <mat-icon
          matSuffix
          *ngIf="!formGroup?.get(controlName)?.value"
          class="suffix-close-icon"
          aria-hidden="false"
          aria-label="Search"
          >search</mat-icon
        ></ng-container
      >
    </div>
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      class="autocomplete-input-options-panel {{
        shouldStyleFormPanel ? 'form-panel' : ''
      }}"
      (optionSelected)="optionSelected($event)"
      [displayWith]="displayFn"
    >
      <!-- (closed)="!isOptionSelected && clearInput()" -->
      <mat-option
        class="no-option-found"
        disabled
        *ngIf="showNoRecordMessage && optionNotFound"
      >
        {{ noRecordMessage }}
      </mat-option>
      <cdk-virtual-scroll-viewport
        *ngIf="!showNoRecordMessage || (showNoRecordMessage && !optionNotFound)"
        [itemSize]="itemSize"
        [minBufferPx]="virtualScrollConfig.minBufferPx"
        [maxBufferPx]="virtualScrollConfig.maxBufferPx"
        [ngStyle]="{ height: height }"
      >
        <ng-container *ngIf="firstInput.value.length >= minLength">
          <mat-option
            *cdkVirtualFor="let option of filteredOptions"
            [value]="option"
          >
            {{ option.label }}
          </mat-option>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
  </div>
  <!-- <p *ngIf="isformControlError" class="error-text">
    <i *ngIf="errorIcon" class="{{ errorIcon }}" aria-hidden="true"></i
    >&nbsp;
    <span id="{{ controlName }}_err"
      >{{ label }} {{ "required.error" | translate }}</span
    >
  </p>
  <span
    *ngIf="patternRegex?.description"
    class="sr-only"
    [id]="controlName + 'regexDescription'"
  >
    {{ "error" | translate }}
    {{ patternRegex?.description | translate }}
  </span> -->
</div>
