import { Component } from '@angular/core';

@Component({
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.less'],
})
export class ForbiddenComponent {
  public title: string = '404';
  public text: string = 'Access Denied!';
}
