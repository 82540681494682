<section class="loader">
  <div class="loader__wrapper" [ngClass]="{ 'increase-size': showSecondPart }">
    <div class="header-percentage">{{ bgJobModel?.progress }}%</div>
    <div class="sub-header">
      {{ getMessage() }}
    </div>
    <div class="position-relative">
      <progressbar
        [max]="100"
        type="info"
        [value]="bgJobModel?.progress"
        class="loader__progressbar"
      >
      </progressbar>
    </div>
    <button
      id="barCancelButtonBtnId"
      class="cancel-button"
      (click)="cancelBgJob()"
    >
      Cancel
    </button>
    <hr style="margin-top: 30px" *ngIf="showSecondPart" />
    <div class="notification-container" *ngIf="showSecondPart">
      <div class="notification-content">
        <div class="notification-text-main">
          Looks like things are taking a little longer than planned
        </div>
        <div class="notification-text-secondary">
          Feel free to browse around during this time
        </div>
        <div class="notification-icon">
          <a
            id="progressBarAId"
            [routerLink]="['/', this.orgId, 'forecast', 'pages', '1']"
          >
            <svg
              fill="#4C4E51"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="64px"
              height="64px"
              viewBox="-64 -64 528.00 528.00"
              xml:space="preserve"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0">
                <rect
                  x="-64"
                  y="-64"
                  width="528.00"
                  height="528.00"
                  rx="264"
                  fill="#FFFFFF"
                  strokewidth="0"
                ></rect>
              </g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <path
                    d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"
                  ></path>
                </g>
              </g>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
