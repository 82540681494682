<div class="field-edit">
  <div *ngIf="!editing" class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap">
    <p>
      {{ chosenOptionName }}
    </p>
    <button
      class="btn btn_iconed"
      type="button"
      *ngIf="!disabled && selectOptions.length"
      (click)="editing = true"
    >
      <app-svg [href]="'pencil-square'"></app-svg>
    </button>
  </div>
  <form
    [formGroup]="form"
    (keyup.enter)="emitSaveValue()"
    (keyup.escape)="stopEditingIfEsc()"
    [hidden]="!editing"
  >
    <div class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap">
      <label for="value" class="form-block form-block_sm">
        <app-select
          id="value"
          formControlName="value"
          [ngClass]="{ 'form-block_error': hasError('value') }"
          [placeholder]="showPlaceholder ? 'Select value' : null"
          [showPlaceholder]="showPlaceholder"
          [values]="selectOptions"
        >
        </app-select>

        <button
          type="button"
          class="btn tooltip-btn_error"
          *ngIf="hasError('value')"
          [tooltip]="getErrorText('value')"
        >
          <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
        </button>
      </label>

      <button
        class="btn btn_iconed btn_iconed-small"
        title="Cancel"
        type="button"
        (click)="stopEditingIfEsc()"
      >
        <app-svg [href]="'times'" iconClass="icon_xxs"></app-svg>
      </button>
      <button
        class="btn btn_iconed btn_iconed-small btn_iconed_success"
        title="Save value"
        type="button"
        (click)="emitSaveValue()"
      >
        <app-svg [href]="'check'"></app-svg>
      </button>
    </div>
  </form>
</div>
