import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AssessmentModel } from '../../../core/models/assessment.model';
import { AssetModel } from '../../../core/models/asset.model';
import { AssetsService } from '../../../core/services/assets.service';
import { BaseEditComponent } from '../../components/base-edit.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { DateTime } from 'luxon';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';
import { ImageResizeService } from '../../../core/services/image-resize.service';
import { NgForm } from '@angular/forms';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { OrganizationModel } from '../../../core/models/organization.model';
import { ServiceLogsService } from '../../../core/services/service-log.service';

@Component({
  templateUrl: './service-assessment-modal.component.html',
})
// to be done is inheritance possible from ServiceEventComponent or BaseModalClass
export class ServiceAssessmentModalComponent extends BaseEditComponent<AssessmentModel> {
  @ViewChild('AssessForm', { static: true }) declare tdForm: NgForm;
  @Input() public asset: AssetModel;
  @Input() declare entityId: number;
  @Input() public organization: OrganizationModel;
  @Input() public onResult: Subject<any> = new Subject<any>();
  @Input() public onClose: Subject<any> = new Subject<any>();
  public minDate;

  constructor(
    protected activeModal: BsModalRef,
    protected _serviceLogsService: ServiceLogsService<AssessmentModel>,
    protected _notificationService: CustomNotificationService,
    protected _imageResizingService: ImageResizeService,
    protected _organizationCurrent: OrganizationCurrentService,
    protected _assetsService: AssetsService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected _eventService: EventService,
    protected _errorService: ErrorService
  ) {
    super(
      _notificationService,
      _serviceLogsService,
      router,
      activeRoute,
      '',
      _eventService,
      _errorService
    );
  }

  public ngOnInit() {
    this.isTemplateDriven = true;
    this.method = this.entityId ? 'update' : 'create';
    this.isLoading = true;
    this._serviceLogsService.service_type = 'assessments';
    this.loadModel().subscribe({
      next: () => {
        this.model.asset_id = this.asset.id;
        this.minDate = DateTime.fromISO(
          this.asset.installed_on || this.model.asset?.installed_on
        )
          .startOf('day')
          .toISO();
        this.isLoading = false;
      },
      error: (error) => {
        this._notificationService.apiError(error);
      },
    });
  }

  public loadModel(): Observable<AssessmentModel> {
    return new Observable<AssessmentModel>((observer) => {
      if (this.isCreate) {
        this.model = new AssessmentModel();
        this.model.completed_at = DateTime.now().toISO();
        observer.next(this.model);
        observer.complete();
      } else {
        // to be done should we reaplce this request with putting AssesmentModel via @Input
        this._serviceLogsService
          .getById(this.asset.id, this.entityId)
          .subscribe({
            next: (data) => {
              this.model = new AssessmentModel(data);
              observer.next(this.model);
              observer.complete();
            },
            error: (error) => {
              observer.error(error.error);
              observer.complete();
            },
          });
      }
    });
  }

  public afterSaveAction(res): void {
    this.onResult.next(res);
    this.activeModal.hide();
  }

  public cancel(): void {
    this.onClose.next('cancel');
    this.activeModal.hide();
  }
}
