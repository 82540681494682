<div class="modal-content__wrapper" [hidden]="status !== 'static'">
  <header>
    <h2 class="modal-content__header">{{ header }}</h2>
  </header>

  <form [formGroup]="form" (ngSubmit)="saveAction()">
    <ul class="modal-content__body modal-content__body_without-form">
      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="orderOptions"
      >
        <label class="form-block__label">Sorting by:</label>

        <app-select
          formControlName="order_by"
          id="order_by"
          name="order_by"
          optKey="type"
          optValue="title"
          [showPlaceholder]="false"
          [values]="orderOptions"
        >
        </app-select>
      </li>
      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="checkboxFieldName"
      >
        <label class="custom-checkbox">
          <input
            [formControlName]="checkboxFieldName"
            class="custom-checkbox__input"
            type="checkbox"
          />
          <span
            class="custom-checkbox__check custom-checkbox__check_labeled"
          ></span>
          {{ checkboxLabel }}
        </label>
      </li>
    </ul>

    <div class="buttons-block modal-content__footer">
      <button
        type="button"
        (click)="close()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>
      <button class="btn action-btn action-btn_success">
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Export</span>
      </button>
    </div>
  </form>
</div>

<app-export-modal
  *ngIf="status === 'in-progress'"
  [type]="type"
  [orgId]="orgId"
  [exportAddOns]="params"
  [successMessage]="successMessage"
>
</app-export-modal>
