import { DefaultDTO } from './default.dto';

export class FacilityCategoryDTO extends DefaultDTO {
  id: number;
  type: string;
  name: string;
  color_scheme: string;
  applicable_to: Array<number>;
  position: number;
  tags: Array<{ id: number; name: string }>;

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
    }
  }
}
