import { DefaultModel } from './default.model';
import { IntegrationApiDTO } from '../dto/integration-api.dto';

export class IntegrationApiModel extends DefaultModel<IntegrationApiDTO> {
  access_key_renewed_at: string;
  created_at: string;
  emails: Array<string>;
  id: number;
  name: string;
  updated_at: string;
  webhook: IntegrationApiWebhook;
  url?: string;
  from_date?: string;
  to_date?: string;
  tenant: boolean;
  skip_ae_validation: boolean;

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
    }
    this.webhook ||= {};
    this.emails ||= [];
  }

  public dto(): IntegrationApiDTO {
    return new IntegrationApiDTO(this);
  }

  public get entity_name(): string {
    return 'integration_api';
  }

  public toString(): string {
    return this.name;
  }
}

export class IntegrationApiWebhook {
  callback_url?: string;
}
