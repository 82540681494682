import { DefaultModel } from './default.model';
import { DefaultDTO } from '../dto/default.dto';
import { DateTime } from 'luxon';

export class AttachmentModel extends DefaultModel<DefaultDTO> {
  description?: string;
  id?: any;
  uploaded_s3key?: string;
  original_file_name?: string;
  original_file_size?: number;
  file_url?: string;
  _destroy?: number;
  created_at?: string;
  expires_at?: string;
  remind_in?: string | number;
  document_type?: string;
  created_by?: {
    id?: number;
    name?: string;
    is_active?: boolean;
  };
  user?: {
    id?: number;
    name?: string;
    is_active?: boolean;
  };
  uri?: string;
  updated_at?: Date;

  constructor(data?) {
    super();
    if (data) {
      Object.assign(this, data);
      this.expires_at = data.expires_at
        ? DateTime.fromISO(data.expires_at).toISO()
        : undefined;
    }
  }

  public get entity_name(): string {
    return 'attachment';
  }

  public toString(): string {
    return this.original_file_name;
  }

  public dto(): DefaultDTO {
    return null;
  }
}
