import { DefaultDTO } from './default.dto';
import { CsvColumn, FileImportModel } from '../models/file-import.model';

export class FileImportDTO extends DefaultDTO {
  system_ftp: boolean;
  name?: string;
  protocol?: string;
  host?: number;
  port?: number;
  paths?: {
    errors?: string;
    files?: string;
    processed?: string;
    new_assets?: string;
    images?: string;
    processed_images?: string;
    errors_images?: string;
  };
  test_connection?: number;
  test_connection_errors?: string;
  emails?: string[];
  import_at?: Date;
  checked_at?: Date;
  id?: number;
  user_name?: string;
  password?: string;
  reset_password?: boolean;
  selected_columns: {
    assets?: {
      selected_groups?: Array<string>;
      general_columns?: Array<CsvColumn>;
      risks?: Array<CsvColumn>;
      udfs?: Array<CsvColumn>;
    };
  };

  constructor(fileImport: FileImportModel) {
    super();
    if (fileImport) {
      Object.assign(this, fileImport);
      this.selected_columns = fileImport.csv_columns_list;
    }
  }
}
