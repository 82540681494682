import { Pipe, PipeTransform } from '@angular/core';
import { AddressModel } from '../../core/models/address.model';
import { COUNTRIES } from '../../core/constants/collections.constants';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  public countries = COUNTRIES;

  public transform(adr: AddressModel): string {
    const cAdr = [];
    if (!adr) {
      return '';
    }

    Object.keys(adr).forEach((key) => {
      if (!adr[key] || adr[key] === ', ') {
        delete adr[key];
      }
    });

    if (adr.address1 || adr.address) {
      cAdr.push(adr.address1 || adr.address);
    }
    if (adr.city) {
      cAdr.push(adr.city);
    }

    if (adr.state && adr.country === 'US') {
      cAdr.push(adr.state);
    }
    if (adr.zipcode) {
      cAdr.push(adr.zipcode);
    }

    if (adr.country) {
      this.countries.find((country: { name: string; value: string }) => {
        if (country.value === adr.country) {
          cAdr.push(country.name);
          return true;
        }
      });
    }

    return cAdr.join(', ');
  }
}
