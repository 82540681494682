import {
  AlloctedByOption,
  EnergySavingOption,
} from 'src/app/core/constants/enums';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { AutoUnsubscribeService } from '../../../core/services/auto-unsubscribe.service';
import { BaseReactiveForm } from '../../components/base-reactive-form';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ClientAssetTypesService } from 'src/app/core/services/client-asset-types.service';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { DateTime } from 'luxon';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { ProjectModel } from 'src/app/core/models/project.model';
import { ProjectsService } from 'src/app/core/services/projects.service';
import { dateRangeValidator } from '../../validators/date-range.validator';

@Component({
  templateUrl: './save-as-modal.component.html',
  styleUrls: ['./save-as-modal.component.less'],
})
export class SaveAsModalComponent extends BaseReactiveForm<any> {
  @Input() public header: string = `Save budget`;
  @Input() public isPublic: boolean;
  @Input() public message: string = 'Budget name';
  @Input() public name: string;
  @Input() public description: string;
  @Input() public nameLabel: string = 'Forecast name:';
  @Input() public submitText: string = 'Save';
  @Input() public showProjectEditOptions: boolean = false;
  @Input() public descriptionLabel: string = 'Project description';
  @Input() public projectStartDate: Date;
  @Input() public projectEndDate: Date;
  @Input() public cmmsStartDate: string;
  @Input() public selectedEnergySavingOption: string | null;
  @Input() public selectedAlloctedByOption: string | null;
  @Input() public selectedProjectPriorityOption: string | null;
  @Input() public projectPriorityOption: [];
  @Input() public selectedProjectTypeOption: string | null;
  @Input() public projectTypeOption: [];
  @Input() public accessName: string = 'Forecast Access';
  @Input() public is_occupied: boolean;
  @Input() public tag_ids: any = [];
  @Input() public project_id: string;

  public leafUrl: string = '../../../../assets/img/noun-leaf.svg';
  public characterCount: number = 0;
  public descriptionLength: number = 100;
  public maxProjectYear: Date = new Date(9999, 11, 31, 23, 59, 59);
  public body = document.querySelector('body');

  public energySavingOptions: { name: string; value: string }[] = [
    { name: EnergySavingOption.None, value: null },
    { name: EnergySavingOption.Low, value: 'low' },
    { name: EnergySavingOption.Medium, value: 'medium' },
    { name: EnergySavingOption.High, value: 'high' },
  ];

  public alloctedByOptions: { name: string; value: string }[] = [
    { name: AlloctedByOption.None, value: null },
    { name: AlloctedByOption.Quarterly, value: 'quarterly' },
    { name: AlloctedByOption.Annually, value: 'annually' },
  ];

  public next: boolean = false;
  public timeout: any;
  public items: any = [];

  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService,
    protected _destroy: AutoUnsubscribeService,
    protected _featureFlagsService: FeatureFlagsService,
    protected _clientAssetTypesService: ClientAssetTypesService,
    private _projectsService: ProjectsService<ProjectModel>
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }

  ngOnInit() {
    if (
      this._featureFlagsService.isFeatureEnabled('OR_12876_project_attributes')
    ) {
      this._clientAssetTypesService.getProjectAttributes().subscribe({
        next: (data) => {
          this.items = data;
        },
        error: (err) => this._notificationService.apiError(err),
      });
    }
    this.setupForm();
    this.addProjectClassToBody();
    this.updateProjectDateRange();
  }

  public saveAction(): void {
    if (this.isFormValid) {
      this.form.value.tag_ids = this.form.value.tag_ids.splice(
        0,
        this.items.length
      );
      const formData = {
        ...this.form.value,
      };

      formData.project_date_from = DateTime.fromISO(
        this.form.value.projectDate.project_date_from
      )
        .startOf('day')
        .toISO();
      formData.project_date_to = DateTime.fromISO(
        this.form.value.projectDate.project_date_to
      )
        .endOf('day')
        .toISO();
      this.result(formData);
    }
  }

  protected setupForm() {
    const targetLength = 9;
    while (this.tag_ids.length < targetLength) {
      this.tag_ids.push(null);
    }
    const tag_ids: FormControl[] = this.tag_ids.map(
      (val) => new FormControl(val)
    );
    this.form = this.formBuilder.group({
      name: [
        this.name,
        [
          Validators.required,
          Validators.maxLength(60),
          Validators.pattern(
            /^(?!.*([\_\s\-\(\)\[\]\{\}\.,])\1)[a-zA-Z0-9\-\(\)\[\]\{\}\s\.\_]+$/
          ),
        ],
      ],
      public: [!!this.isPublic, [Validators.required]],
      description: [
        this.description,
        [Validators.maxLength(this.descriptionLength)],
      ],
      is_occupied: [this.is_occupied],
      projectDate: this.formBuilder.group(
        {
          project_date_from: [this.projectStartDate],
          project_date_to: [this.projectEndDate],
        },
        { validator: dateRangeValidator(this.cmmsStartDate) }
      ),
      project_type: [this.selectedProjectTypeOption],
      project_risk: [this.selectedProjectPriorityOption],
      energy_saving: [this.selectedEnergySavingOption],
      allocated_by: [this.selectedAlloctedByOption],
      tag_ids: this.formBuilder.array(tag_ids),
    });
  }

  updateCharacterCount(): void {
    this.characterCount = this.form.get('description').value.length;
  }

  addProjectClassToBody() {
    this.body.classList.add('projectbuilder-modal-open');
  }

  removeProjectClassFromBody() {
    this.body.classList.remove('projectbuilder-modal-open');
  }

  checkSingleDateRange(): void {
    const projectDateFrom = this.form.get(
      'projectDate.project_date_from'
    ).value;
    const projectDateTo = this.form.get('projectDate.project_date_to').value;

    if (!projectDateTo) {
      this.form.patchValue({
        projectDate: {
          project_date_to: projectDateFrom,
        },
      });
    }
  }

  clearSingleDateRange(): void {
    this.form.patchValue({
      projectDate: {
        project_date_to: null,
        project_date_from: null,
      },
    });
  }

  public updateProjectDateRange() {
    let dateUpdated = false;

    this.form
      .get('projectDate.project_date_from')
      .valueChanges.subscribe(() => {
        if (!dateUpdated) {
          dateUpdated = true;
          this.form.get('projectDate.project_date_to').updateValueAndValidity();
          dateUpdated = false;
        }
      });

    this.form.get('projectDate.project_date_to').valueChanges.subscribe(() => {
      if (!dateUpdated) {
        dateUpdated = true;
        this.form.get('projectDate.project_date_from').updateValueAndValidity();
        dateUpdated = false;
      }
    });
  }

  ngOnDestroy() {
    this.removeProjectClassFromBody();
  }

  public searchProjectName(name) {
    if (this.showProjectEditOptions) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this._projectsService
          .verifyProjectName(name, this.project_id)
          .subscribe({
            next: () => {},
            error: (e) => {
              this.form
                .get('name')
                .setErrors({ api: e.error.errors[0].message });
            },
          });
      }, 1000);
    }
  }

  public handleNext() {
    if (this.isFormValid) {
      this._projectsService
        .verifyProjectName(this.form.value.name, this.project_id)
        .subscribe({
          next: () => {
            this.next = true;
          },
          error: (e) => {
            this.form.get('name').setErrors({ api: e.error.errors[0].message });
          },
        });
    }
    return false;
  }

  public addNoneOption(tags: any[]): any[] {
    const valuesWithNone = [{ id: null, name: 'None' }];
    return valuesWithNone.concat(tags);
  }

  getErrorText(field: string): string {
    const ERROR_MESSAGES = {
      INVALID_FORMAT: 'Invalid Format',
    };

    const control = this.form.get(field);
    if (!control) {
      return '';
    }

    if (field === 'name' && control.hasError('pattern')) {
      return ERROR_MESSAGES.INVALID_FORMAT;
    }

    return super.getErrorText(field);
  }
}
