import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { BudgetingAssetModel } from '../models/budgeting-asset.model';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import {
  ForecastModel,
  ForecastSimulationModel,
  IForecastFacilityTree,
} from '../models/forecast.model';
import { BudgetingLocationModel } from '../models/building-distribution.model';
import { UserModel } from '../models/user.model';
import { IVirtualAsset } from '../models/asset.model';
import { DefaultListModel } from '../models/default-list.model';
import { IBgJobModel } from '../models/bg-worker.model';
import { FeatureFlagsService } from './feature-flags.service';

export const forecastResourceName: InjectionToken<string> =
  new InjectionToken<string>('forecastResourceName', {
    factory: () => {
      return ForecastService.isOld ? 'x_forecast' : 'forecast';
    },
  });

@Injectable({
  providedIn: 'root',
})
export class ForecastService<M> extends BaseStoreService<M> {
  static isOld: boolean;
  private readonly versionType: string = 'v1';

  constructor(
    protected _requestService: RequestService,
    protected _featureFlagsService: FeatureFlagsService,
    @Inject(forecastResourceName) protected resourceName: string
  ) {
    super(_requestService, BudgetingAssetModel);
    if (_featureFlagsService.isFeatureEnabled('forecast_new_api_enabled')) {
      this.versionType = 'v3';
    }
  }
  public getItems(
    params?: any,
    resource: string = this.resourceName
  ): Observable<DefaultListModel<M>> {
    return super.getItems(params, `${this.versionType}/${resource}`);
  }

  public getById(id): Observable<M> {
    return super.getById(id, `${this.versionType}/${this.resourceName}`);
  }

  public delete(id): Observable<M> {
    return super.delete(id, `${this.versionType}/${this.resourceName}`);
  }

  public getForecastAssetCount(
    forecastId: number
  ): Observable<{ assets_count: number }> {
    return this._requestService.get(
      `/${this.versionType}/${this.resourceName}/${forecastId}/assets_count`
    );
  }

  public initForecast(params, queryParams): Observable<IBgJobModel> {
    return this._requestService.post(
      `/${this.versionType}/${this.resourceName}/init/async`,
      params,
      this.headers,
      false,
      queryParams
    );
  }

  public deleteVirtualAsset(forecastId: number, assetUUIDs: string[]) {
    return this._requestService.delete(
      `/${this.versionType}/${this.resourceName}/${forecastId}/assets/virtual`,
      { asset_uuids: assetUUIDs }
    );
  }

  public updateForecast(id, body): Observable<ForecastModel> {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${id}`,
      body
    );
  }

  public updateForecastAssetsData(
    id: number,
    queryParams
  ): Observable<ForecastModel> {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${id}/assets`,
      queryParams
    );
  }

  public duplicateForecast(id, forecast): Observable<ForecastModel> {
    return this._requestService.post(
      `/${this.versionType}/${this.resourceName}/${id}/clone`,
      forecast
    );
  }

  public refilterForecast(params, id, queryParams): Observable<ForecastModel> {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${id}/refilter`,
      params,
      this.headers,
      false,
      queryParams
    );
  }

  /**
   * Request info about (un)checked items
   * @param forecastId - forecast id
   * @param period
   * @param data - {assets: []} - list of changed assets
   * @returns {Promise.<TResult>|*}
   */
  public putCheckedItems(
    period,
    forecastId,
    data
  ): Observable<{
    general?: ForecastModel;
    assets?: Array<BudgetingAssetModel>;
    facilities?: Array<BudgetingLocationModel>;
  }> {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${forecastId}/period/${period}/`,
      data
    );
  }

  public distributeByTotalRisk(forecastId: number, params): Observable<any> {
    return this._requestService.post(
      `/${this.versionType}/${this.resourceName}/${forecastId}/budget_by_total_risk/async`,
      params
    );
  }

  public distribution(
    allocated: { [key: number]: { [key: number]: number } }[],
    id: number
  ): Observable<ForecastModel> {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${id}/distribute`,
      { budget: { allocated } }
    );
  }

  public distributionAsync(
    allocated: { [key: number]: { [key: number]: number } }[],
    id: number,
    distributionType: string,
    source_type?: string,
    current_fhi_score?: string,
    desired_fhi_score?: string
  ): Observable<any> {
    let forecast: Object;
    forecast = {
      budget: { allocated },
      distribution_type: distributionType,
    };
    if (source_type) {
      forecast = {
        budget: { allocated },
        distribution_type: distributionType,
        source_type,
        current_fhi_score,
        desired_fhi_score,
      };
    }
    return this._requestService.post(
      `/${this.versionType}/${this.resourceName}/${id}/distribute/async`,
      {
        forecast,
      }
    );
  }

  createAssetComment(comment, forecastId: number, assetId: number | string) {
    return this._requestService.post(
      `/${this.versionType}/${this.resourceName}/${forecastId}/assets/${assetId}/comment`,
      { comment }
    );
  }

  updateAssetComment(
    comment,
    forecastId: number,
    assetId: number | string,
    commentId: number
  ) {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${forecastId}/assets/${assetId}/comment/${commentId}`,
      { comment }
    );
  }

  deleteAssetComment(
    forecastId: number,
    assetId: number | string,
    commentId: number
  ) {
    return this._requestService.delete(
      `/${this.versionType}/${this.resourceName}/${forecastId}/assets/${assetId}/comment/${commentId}`
    );
  }

  updateForecastAssets(forecastId: number, params): Observable<any> {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${forecastId}/assets`,
      params
    );
  }

  discardForecast(forecastId: number) {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${forecastId}/discard`
    );
  }

  public getOwners(): Observable<UserModel> {
    return this._requestService.get(
      `/${this.versionType}/${this.resourceName}/owners`,
      { per_page: 9999, page: 1 }
    );
  }

  public createVirtualAsset(
    assets: IVirtualAsset,
    forecastId: number
  ): Observable<any> {
    return this._requestService.post(
      `/${this.versionType}/${this.resourceName}/${forecastId}/assets/virtual`,
      { assets }
    );
  }

  public getSubLevelByParentLocation(
    forecastId: number,
    level = 'location',
    ids: number[] = [],
    params: any = {}
  ): Observable<DefaultListModel<any>> {
    if (ids.length) {
      params.parent_ids = [ids];
    }
    return super.getItems(
      params,
      `${this.versionType}/${this.resourceName}/${forecastId}/snapshot/${level}s`
    );
  }

  public mergeForecast(targetId, sourceId) {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${sourceId}/merge_to/${targetId}`
    );
  }

  public getOwnersList(forecastId: number): Observable<Array<UserModel>> {
    return this._requestService.get(
      `/${this.versionType}/${this.resourceName}/${forecastId}/owners`
    );
  }

  public listOfCompatibleForecastsForMerge(
    forecastId: number,
    params
  ): Observable<Array<ForecastModel>> {
    return this._requestService.get(
      `/${this.versionType}/${this.resourceName}/${forecastId}/merge/compatible`,
      params
    );
  }

  public getReplacedAssetsAmount(forecastId: number): Observable<number[]> {
    return this._requestService.get(
      `/${this.versionType}/${this.resourceName}/${forecastId}/replaced_assets_count`
    );
  }

  public getFacilitiesTree(
    forecastId: number
  ): Observable<IForecastFacilityTree> {
    return this._requestService.get(
      `/${this.versionType}/${this.resourceName}/${forecastId}/facilities/tree`
    );
  }

  public getSimulationsList(
    forecastId: number
  ): Observable<ForecastSimulationModel[]> {
    return this._requestService.get(
      `/${this.versionType}/${this.resourceName}/${forecastId}/simulations`
    );
  }

  public createNewSimulation(
    forecastId: number,
    simulation: ForecastSimulationModel
  ): Observable<IBgJobModel> {
    return this._requestService.post(
      `/${this.versionType}/${this.resourceName}/${forecastId}/simulations/async`,
      { simulation }
    );
  }

  public deleteSimulations(
    forecastId: number,
    simulations_ids: number[]
  ): Observable<ForecastSimulationModel[]> {
    return this._requestService.delete(
      `/${this.versionType}/${this.resourceName}/${forecastId}/simulations`,
      { simulations_ids }
    );
  }

  public updateSimulation(
    forecastId: number,
    simulation: ForecastSimulationModel
  ): Observable<IBgJobModel> {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${forecastId}/simulations/${simulation.id}`,
      { simulation }
    );
  }

  public createForecastFromSimulation(
    forecastId: number,
    simulationId: number,
    body
  ): Observable<IBgJobModel> {
    return this._requestService.post(
      `/${this.versionType}/${this.resourceName}/${forecastId}/simulations/${simulationId}/create_forecast/async`,
      body
    );
  }

  public updateComparisonValue(body): Observable<any> {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/comparisons/update`,
      body
    );
  }

  public updateCharts(params): Observable<any> {
    return this._requestService.get(
      `/${this.versionType}/${this.resourceName}/comparisons`,
      params
    );
  }

  public getVirtualProjectAssetData(forecastId, assetUuid, period) {
    return this._requestService.get(
      `/${this.versionType}/${this.resourceName}/${forecastId}/assets/virtual/${assetUuid}/period/${period}`
    );
  }

  public updateVirtualAsset(
    assets: IVirtualAsset,
    forecastId: number,
    assetUuid
  ): Observable<any> {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${forecastId}/assets/virtual/${assetUuid}`,
      { assets }
    );
  }

  public getCostCenterBasedOnForecastId(forecastId: any): Observable<any> {
    return this._requestService.get(
      `/${this.versionType}/${this.resourceName}/${forecastId}/assets/cost_centers`
    );
  }

  public addToForecast(url, body, period, params?): Observable<M> {
    return this._requestService.put(
      `/${this.versionType}/${this.resourceName}/${url}/assets/append/${period}`,
      body,
      this.headers,
      false,
      params
    );
  }
}
