import { AddressModel } from '../models/address.model';
import { DefaultDTO } from './default.dto';

export class AddressDTO extends DefaultDTO {
  address1: string;
  city: string;
  country: string;
  state?: string;
  zipcode: string;
  id?: string;

  constructor(address: AddressModel) {
    super();
    this.address1 = address.address1;
    this.city = address.city;
    this.country = address.country;
    this.state = this.country === 'US' ? address.state : '';
    this.zipcode = address.zipcode;
    if (address.id) {
      this.id = address.id;
    }
  }
}
