export class AssetFieldsConstants {
  public static FIELD_GROUPS = ['general_columns', 'risks', 'udfs'];
  public static FIELD_TYPES = [
    {
      name: 'general_information',
      items: {
        asset_name: 'Asset Name',
        asset_number: 'Asset Number',
        quantity: 'Quantity',
        units: 'Units',
        parent_asset_number: 'Parent Asset Number',
        parent_asset_name: 'Parent Asset Name',
      },
    },
    {
      name: 'lifetime',
      items: {
        install_date: 'Install Date',
        fhie_score: 'FHIE Score',
        remaining_life_adjusted: 'Asset Remaining Life (adjusted)',
        remaining_life_straightline: 'Asset Remaining Life (straightline)',
        cii: 'Capital Improvement Investment',
      },
    },
    {
      name: 'labelling',
      items: {
        manufacturer: 'Manufacturer',
        model_number: 'Model Number',
        serial_number: 'Serial Number',
        code: 'Code',
      },
    },
    {
      name: 'maintenance',
      items: {
        pm_daily: 'Daily PM hrs',
        pm_weekly: 'Weekly PM hrs',
        pm_monthly: 'Monthly PM hrs',
        pm_quarterly: 'Quarterly PM hrs',
        pm_semi_annual: 'Semi-Annual PM hrs',
        pm_annual: 'Annual PM hrs',
        fh_pm_frequency: 'FHI PM Frequency',
      },
    },
    {
      name: 'location',
      items: {
        location: 'Location',
        building: 'Building',
        floor: 'Floor',
        room: 'Room',
        zone: 'Zone',
      },
    },
    {
      name: 'category',
      items: {
        asset_class: 'Asset Class',
        asset_type: 'Asset Type',
        group: 'Group',
        subgroup: 'Subgroup',
        trade: 'Trade',
        specialty: 'Specialty',
        compliance: 'Compliance',
        tier: 'Tier',
        funding_source: 'Funding Source',
      },
    },
    {
      name: 'costs',
      items: {
        fh_cost_estimate: 'Asset Cost',
        estimated_project_costs: 'Estimated Project Costs',
        asset_contingency: 'Asset Contingency',
        asset_fees: 'Asset Fees',
        asset_regional_value: 'Asset Regional Value',
        asset_replacement_cost: 'Asset Replacement Cost',
      },
    },
    {
      name: 'other',
      items: {
        description: 'Description',
        verify: 'Verify',
        verify_notes: 'Verify Notes',
        engineer_name: 'Engineer Name',
        asset_id: 'Asset ID',
        asset_uuid: 'Asset UUID',
      },
    },
    {
      name: 'risks',
      items: {
        area_served: 'Area Served',
      },
    },
  ];
}
