import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HelperService } from '../../../core/services/helper.service';
import { IBgJobModel } from '../../../core/models/bg-worker.model';
import { BgJobService } from '../../../core/services/bg-worker.service';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';

@Component({
  selector: 'app-loader-with-progress',
  templateUrl: './loader-with-progress.component.html',
  styleUrls: ['./loader-with-progress.component.less'],
})
export class LoaderWithProgressComponent {
  @Input() public bgJobModel: IBgJobModel;
  @Input() public orgId: string;

  @Output() public destroyLoader = new EventEmitter();

  public helper = HelperService;

  constructor(
    private _bgJobService: BgJobService<any>,
    private _notificationService: CustomNotificationService
  ) {}

  public cancelBgJob() {
    this.destroyLoader.emit();

    this._bgJobService
      .cancelBgJob(this.bgJobModel.job_uuid, this.orgId)
      .subscribe({
        next: () => {},
        error: (err) => this._notificationService.apiError(err),
      });
  }
}
