import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { NgForm } from '@angular/forms';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { OrganizationModel } from '../../../core/models/organization.model';
import { OrganizationStoreService } from '../../../core/services/organization-store.service';
import { Roles } from '../../../core/constants/roles.constant';
import { UserCurrentService } from '../../../core/services/user-current.service';
import { UserModel } from '../../../core/models/user.model';
import { UserStoreService } from '../../../core/services/user.store';

@Component({
  templateUrl: './user-create-modal.component.html',
})
export class UsersCreateModalComponent
  extends BaseModalComponent
  implements OnInit
{
  @ViewChild('userForm', { static: true }) declare tdForm: NgForm;
  @Input() public allowedRoles: Array<string>;
  @Input() public defaultRole: string;
  public organizations: Array<OrganizationModel> = [];
  public test_organizations: Array<OrganizationModel> = [];
  public roles: Array<any>;
  public model: UserModel;
  private org_id: string;
  sso: boolean = false;
  eam_users: any[] = [
    { role: 'tenant_admin', name: 'Tenant Admin' },
    { role: 'tenant_user', name: 'Tenant User' },
    { role: 'client_admin', name: 'Client Admin' },
    { role: 'contractor', name: 'Contractor' },
    { role: 'dispatcher', name: 'Dispatcher' },
    { role: 'technician', name: 'Technician' },
  ];
  test_orgs: {
    <S extends OrganizationModel>(
      predicate: (
        value: OrganizationModel,
        index: number,
        array: OrganizationModel[]
      ) => value is S,
      thisArg?: any
    ): S[];
    (
      predicate: (
        value: OrganizationModel,
        index: number,
        array: OrganizationModel[]
      ) => unknown,
      thisArg?: any
    ): OrganizationModel[];
  };

  constructor(
    protected activeModal: BsModalRef,
    private _userStore: UserStoreService,
    private _organizationCurrent: OrganizationCurrentService,
    private _organizationStore: OrganizationStoreService,
    private _userCurrent: UserCurrentService,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  public ngOnInit() {
    this.isTemplateDriven = true;
    this.org_id = this._organizationCurrent.organization?.id_hash;
    this.model = new UserModel();
    // Setup roles based on allowed roles
    this.roles = this.allowedRoles.map((key) => {
      const role = {
        key: key,
        name: Roles[key].name,
      };
      if (key === this.defaultRole) {
        this.model.role_name = key;
      }
      return role;
    });
    if (this._userCurrent.user?.role_name.includes('tenant')) {
      this._organizationStore.all().subscribe({
        next: (data) => {
          this.organizations = data.list;
          this.test_organizations = data.list;
        },
        error: (err) => this._notificationService.apiError(err),
      });
    }
  }

  /**
   * Sends invite for user
   */
  public sendInvite() {
    if (this.sso && this.org_id) {
      this.model.eam.organization_hash = this.org_id;
    }
    this._userCurrent.get().subscribe(() => {
      if (this.org_id) {
        this._userStore.invite(this.model).subscribe({
          next: () => {
            this.onResult.next(this.model);
            this.activeModal.hide();
          },
          error: (err) => this.parseErrors(err),
        });
      } else {
        const validRoleNames = [
          'tenant_engineer',
          'tenant_sale',
          'partner_admin',
          'partner_engineer',
        ];
        let orgs = null;

        if (validRoleNames.includes(this.model.role_name)) {
          orgs = this.model.organization_ids;
        }

        this._userStore.inviteTenantUser(this.model, orgs).subscribe({
          next: () => {
            this.onResult.next(this.model);
            this.activeModal.hide();
          },
          error: (err) => this.parseErrors(err),
        });
      }
    });
  }

  public submit(): void {
    this.hasSubmitted = true;
    if (
      this.tdForm.invalid ||
      (this.model.role_name === 'tenant_engineer' &&
        !this.model.organization_ids.length)
    ) {
      return;
    }
    this.sendInvite();
  }

  // switchRoleEam() {
  //   if (this.sso && !this.model.eam) {
  //     this.model.eam = {eam_role_name: null};
  //   }
  // }

  public filterOrganizationsByRole() {
    this.test_organizations =
      this.model?.role_name === 'tenant_sale'
        ? this.organizations.filter((key) => key.test_organization)
        : this.organizations;
  }

  public getModalContentLabel(): string {
    let label: string;

    switch (this.model?.role_name) {
      case 'tenant_engineer':
        label = 'Engineer Organizations';
        break;
      case 'partner_admin':
        label = 'Partner Admin Organizations';
        break;
      case 'tenant_sale':
        label = 'Solution Consultant Organizations';
        break;
      case 'partner_engineer':
        label = 'Partner Engineer Organizations';
        break;
    }

    return `${label}`;
  }
}
