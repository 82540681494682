import { SnotifyButton, SnotifyService } from 'ng-alt-snotify';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from '../../shared/modals/confirmation-modal/confirmation-modal.component';
import { ErrorsConstants } from '../constants/errors.constants';
import { FeatureFlagsService } from './feature-flags.service';
import { HelperService } from './helper.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomNotificationService {
  private timeout: number = 10000;

  constructor(
    protected _modalService: BsModalService,
    private _notificationService: SnotifyService,
    private _translateService: TranslateService,
    protected _featureFlagsService: FeatureFlagsService
  ) {}

  clearNotification(id?: number) {
    if (HelperService.isExist(id)) {
      this._notificationService.remove(id);
    } else {
      this._notificationService.clear();
    }
  }

  public confirm(
    body: string,
    title: string,
    buttons: SnotifyButton[],
    timeout?: number
  ) {
    this._notificationService.confirm(body, title, {
      timeout,
      buttons,
    });
  }

  public success(body: string, timeout: number = this.timeout) {
    this._notificationService.success(body, '', {
      showProgressBar: false,
      timeout,
    });
  }

  public showSuccessWithHTML(body: string, timeout: number = this.timeout) {
    const config = {
      timeout,
      showProgressBar: false,
    };

    this._notificationService.html(body, config);
  }

  public error(message: object | string, timeout: number = this.timeout): void {
    this._notificationService.error(
      HelperService.convertObjectToString(message),
      '',
      {
        bodyMaxLength: 400,
        showProgressBar: false,
        timeout,
      }
    );
  }

  public warn(message: string): void {
    this._notificationService.warning(message, '', {
      showProgressBar: false,
      timeout: this.timeout,
    });
  }

  public info(message: string, timeout?: number): void {
    this._notificationService.info(message, '', {
      showProgressBar: false,
      timeout: timeout || this.timeout,
      icon: './../../../assets/img/Info.svg',
      html: `<img class="info_icon" src="./../../../assets/img/Info.svg"><div class="info_message">${message}</div>`,
    });
  }

  public apiError(error: any, orgId?: string): void {
    const permanentError = error;
    let errorsShown = false;
    if (
      this._featureFlagsService.isFeatureEnabled('OR_12510_Auth0_Login') &&
      permanentError.status == '401'
    ) {
      return;
    }
    if (error.key) {
      this.apiError([error], orgId);
      return;
    }
    if (error.error) {
      error = error.error?.error || error.error?.errors || error.error || error;

      if (error.csv) {
        this.showMultipleErrors('CSV', error.csv, this.timeout);
        return;
      }
      if (error.row) {
        for (const key in error) {
          if (
            error.hasOwnProperty(key) &&
            key.match('row') &&
            error[key].length
          ) {
            errorsShown = true;
            error[key].forEach((errorObj) => {
              for (const errorKey in errorObj) {
                if (errorObj.hasOwnProperty(errorKey)) {
                  this.showMultipleErrors(
                    errorKey,
                    errorObj[errorKey],
                    this.timeout
                  );
                }
              }
            });
          }
        }
      }
      if (errorsShown) {
        return;
      }
    }
    if (Array.isArray(error)) {
      let showContinue, needToBeFixed, scopeId, assetId;
      const messagesBlock = [];

      this.parseErrorsWithCodes(error, orgId).forEach((msg) => {
        if (msg.showContinue) {
          showContinue = msg.showContinue;
        }
        if (msg.needToBeFixed) {
          needToBeFixed = msg.needToBeFixed;
        }
        if (msg.scopeId) {
          scopeId = msg.scopeId;
        }
        if (msg.assetId) {
          assetId = msg.assetId;
        }
        messagesBlock.push(msg.message);
      });
      if (!needToBeFixed && showContinue) {
        messagesBlock.push(
          this._translateService.instant('modals.proceed_question')
        );
      }
      if (showContinue || needToBeFixed || scopeId || assetId) {
        this.openModal(messagesBlock, showContinue);
      } else {
        this.showMultipleErrors('Error', messagesBlock, 5000);
      }
    } else if (HelperService.isExist(permanentError.status)) {
      this.error(
        `Status ${permanentError.status}: ${permanentError.statusText}`
      );
      return;
    } else {
      this.error(
        error.error?.error ||
          error.error?.errors ||
          error.error ||
          error.message ||
          error
      );
    }
  }

  public showMultipleErrors(title: string, errors: any[], timeout) {
    errors.forEach((err) => {
      if (Array.isArray(err)) {
        this.showMultipleErrors(title, err, timeout);
        return;
      }
      this.error(err, timeout);
    });
  }

  public parseErrorsWithCodes(error: any, orgId: string): any[] {
    const errorCodes = ErrorsConstants;
    const messages = [];
    let errorMessage = '';
    let errorContent =
      error.error?.errors || error.error?.error || error.errors || error;
    if (typeof errorContent === 'string') {
      messages.push({ message: errorContent });
    } else {
      errorContent = errorContent.filter((item) => !item.invalid_asset_ids);
      errorContent.forEach((value) => {
        const errorCode = value.code;
        if (errorCode && errorCode.length) {
          const sourceMessage =
            errorCodes[errorCode]?.message || value['message'];
          messages.push({
            showContinue: errorCodes[errorCode]?.showContinue,
            needToBeFixed: errorCodes[errorCode]?.needToBeFixed,
            customErrorText: errorCodes[errorCode]?.customErrorText,
            scopeId: value['scope_id'],
            assetId: value['asset_id'],
            message: this.addLinkToMessage(sourceMessage, orgId, value),
          });
        } else {
          errorMessage = HelperService.convertObjectToString(value, [
            '0:',
            'message:',
            '[',
            ']',
            '"',
            '"',
          ]);
          messages.push({ message: errorMessage });
        }
      });
    }
    return messages;
  }

  addLinkToMessage(sourceMessage, orgId, idObject) {
    let textLink;
    const splitMessage = sourceMessage.split(`%here%`);
    let result = '';
    switch (true) {
      case !!idObject?.asset_id:
        textLink = `<a class="link" href="/${orgId}/assets/${idObject.asset_id}" target="_blank">${idObject.asset_number || idObject.asset_uuid}</a>`;
        textLink = `Asset ${textLink}:`;
        if (splitMessage.length > 1) {
          result += `${splitMessage[0]} ${textLink} ${splitMessage[1]}.`;
        } else {
          result += `${textLink}\n${sourceMessage}.`;
        }
        return result;
      case !!idObject?.scope_id:
        textLink = `<a class="link" href="${this.assetsScopeLink(idObject.scope_id, orgId)}" target="_blank">here</a>`;
        if (splitMessage.length > 1) {
          result += `${splitMessage[0]} ${textLink}${splitMessage[1]}.`;
        } else {
          textLink = `You can view the list of such assets ${textLink}.`;
          result += `${sourceMessage}.\n${textLink}`;
        }
        return result;
      default:
        return sourceMessage;
    }
  }

  protected openModal(messagesArr, showContinue) {
    this._modalService.show(ConfirmationModalComponent, {
      class: 'modal-holder',
      animated: true,
      initialState: {
        header: 'Error',
        messagesArr,
        submitText: 'Continue',
        hideSubmit: !showContinue,
        cancelText: !showContinue ? 'Ok' : 'Cancel',
      },
    });
  }

  private assetsScopeLink(scopeId, orgId): string {
    if (scopeId && orgId) {
      return `/${orgId}/assets/scope/${scopeId}`;
    }
    return '';
  }
}
