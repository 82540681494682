<p>
  {{ showPasswordByState() }}
  <button
    (click)="showPassword = !showPassword"
    type="button"
    class="btn_iconed"
  >
    <app-svg [href]="'eye'" *ngIf="enablePassword"></app-svg>
  </button>
</p>
