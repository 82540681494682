import { CustomNotificationService } from '../../../../core/services/custom-notification.service';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoutesConstants } from '../../../../core/constants/routes.constants';
import {
  COUNTRIES,
  STATES,
} from '../../../../core/constants/collections.constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EventService } from '../../../../core/services/event.service';
import { ErrorService } from '../../../../core/services/error.service';
import { BaseReactiveForm } from '../../../../shared/components/base-reactive-form';
import { IUpdateDetailsModel } from '../../../../core/models/login.model';
import { UserCurrentService } from 'src/app/core/services/user-current.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { OrganizationCurrentService } from 'src/app/core/services/organization-current.service';

@Component({
  templateUrl: './update-details.component.html',
  styleUrls: ['./update-details.component.less'],
})
export class UpdateDetailsComponent extends BaseReactiveForm<IUpdateDetailsModel> {
  public states: Array<any> = STATES;
  public countries = COUNTRIES;

  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService,
    private router: Router,
    protected _userCurrent: UserCurrentService,
    private _authService: AuthService,
    private _organizationCurrent: OrganizationCurrentService,
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }

  public ngOnInit(): void {
    this.isLoading = true;
    if (!this._authService.getApiToken()) {
      this.router.navigate([RoutesConstants.AUTH.LOGIN], {
        replaceUrl: true,
      });
    } else {
      this._userCurrent
        .get()
        .pipe()
        .subscribe({
          next: (user) => {
            this._organizationCurrent.clientKey = user.organization?.id_hash;
            if (!user.first_login) {
              this.router.navigate([RoutesConstants.HOME], {
                replaceUrl: true,
              });
            } else {
              this.isLoading = false;
            }
          },
        });
    }
    this.setupForm();
  }

  public submit(): void {
    if (this.isFormValid) {
      const data = { ...this.form.value };
      this._userCurrent.clean();
      this._userCurrent.update(data).subscribe({
        next: () => {
          this.router.navigate([RoutesConstants.HOME], {
            replaceUrl: true,
          });
        },
        error: (err) => this._notificationService.apiError(err),
      });
    }
  }

  protected setupForm() {
    this.form = this.formBuilder.group({
      first_name: ['', [Validators.maxLength(25), Validators.required]],
      last_name: ['', [Validators.maxLength(25), Validators.required]],
      phone: ['', [Validators.maxLength(15), Validators.required]],
      address_attributes: this.formBuilder.group({
        country: ['', [Validators.required]],
        state: [null, []],
        city: ['', [Validators.maxLength(60), Validators.required]],
        address1: ['', [Validators.maxLength(150), Validators.required]],
        zipcode: ['', [Validators.maxLength(10), Validators.required]],
      }),
    });

    this.form
      .get('address_attributes')
      .get('country')
      .valueChanges.subscribe((val) =>{
        this.form.get('address_attributes.state').reset()
        if(val == 'US') {
          this.form.get('address_attributes.state').addValidators([Validators.required])
        } else {
          this.form.get('address_attributes.state').removeValidators([Validators.required])
        }
      }
      );
  }
}
