import { DefaultDTO } from '../dto/default.dto';
import { DefaultModel } from './default.model';
import { PmTemplateDTO } from '../dto/pm-template.dto';
import { DefaultIdNameModel } from './default-id-name.model';
import { UARAssetModel } from './UARAssetModel';

export class PmTaskModel extends DefaultModel<DefaultDTO> {
  id?: number;
  description?: string;
  position?: number;
  disable?: boolean;
  trade_ids?: number[];
  trades?: DefaultIdNameModel[];

  constructor(data?) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  public get entity_name(): string {
    return 'task';
  }

  public toString(): string {
    return this.description;
  }

  public dto(): DefaultDTO {
    return this;
  }
}

export class PmTemplateModel extends DefaultModel<DefaultDTO> {
  id?: number;
  name: string;
  frequency: number;
  schedule_type?: number;
  trade_id: number;
  trade?: DefaultIdNameModel;
  performance_window?: number;
  task_ids: Array<number>;
  tasks?: Array<PmTaskModel>;
  isOpened?: boolean;
  apiError?: string;
  assigned_asset_ids?: number[];
  asset_type?: Array<UARAssetModel>;
  uar_asset_type_id: number;
  skip_validation: boolean;

  constructor(data?) {
    super();
    if (data) {
      Object.assign(this, data);
      if (data.tasks) {
        this.tasks = data.tasks.map((item) =>
          typeof item === 'string' ? { description: item } : item
        );
      }
    }
  }

  public get entity_name(): string {
    return 'template';
  }

  public toString(): string {
    return this.name;
  }

  public dto(): PmTemplateDTO {
    return new PmTemplateDTO(this);
  }
}


