export enum ModulesEnum {
  fhi = 'fhi',
  fhim = 'fhim',
  fhir = 'fhir',
  fhie = 'fhie',
}

export enum FHIHistoryPeriod {
  rolling = 'r',
  lifetime = 'l',
  calendar = 'c',
}

export enum FilterType {
  assets = 'assets',
  assessments = 'assessments',
  events = 'events',
  reports = 'reports',
  buildings = 'buildings',
  buildingAttachments = 'buildingAttachments',
  templates = 'templates',
  work_orders = 'work_orders',
  engineer_orders = 'engineer_orders',
  project_buildings = 'project_buildings',
}

export enum ExportTypes {
  CSV = 'data',
  images = 'images',
  both = 'both',
}

export enum AssetListTypes {
  assets = '',
  archive = 'archived',
  general = 'general',
  incomplete = 'incomplete',
  errors = 'has_errors',
  empty_numbers = 'empty_numbers',
  empty_quantities = 'empty_quantities',
  scope = 'scope',
  wo_dates = 'wo-dates',
}

export enum LogType {
  asset = 'asset',
  universalRepository = 'universalRepository',
  clientRepository = 'clientRepository',
  template = 'template',
  template_repository = 'template_repository',
}

export enum GraphTypes {
  dashboard = 'dashboard',
  asset = 'asset',
}

export enum ProjectExpenses {
  demolition = 'Demolition',
  electrical_materials = 'Electrical Materials',
  electrical_labor = 'Electrical Labor',
  mechanical_materials = 'Mechanical Materials',
  mechanical_labor = 'Mechanical Labor',
  plumbing_materials = 'Plumbing Materials',
  plumbing_labor = 'Plumbing Labor',
  construction_materials = 'Construction Materials',
  construction_labor = 'Construction Labor',
  building_automation_controls = 'Building Automation Controls',
  mobilization = 'Mobilization',
  miscellaneous = 'Miscellaneous',
}

export enum ProjectFees {
  engineering = 'Architecture / Engineering',
  construction_management = 'Construction Management',
  commissioning = 'Commissioning',
  permits = 'Permits',
}

export enum ProjectAssetExpenses {
  demolition = 'Demolition',
  electrical_materials = 'Electrical Materials',
  electrical_labor = 'Electrical Labor',
  mechanical_materials = 'Mechanical Materials',
  mechanical_labor = 'Mechanical Labor',
  plumbing_materials = 'Plumbing Materials',
  plumbing_labor = 'Plumbing Labor',
  construction_materials = 'Construction Materials',
  construction_labor = 'Construction Labor',
  building_automation_controls = 'Building Automation Controls',
  mobilization = 'Mobilization',
  miscellaneous = 'Miscellaneous',
}

export enum ProjectSubtotals {
  demolition_total = 'Demolition',
  electrical_materials_total = 'Electrical Materials',
  electrical_labor_total = 'Electrical Labor',
  mechanical_materials_total = 'Mechanical Materials',
  mechanical_labor_total = 'Mechanical Labor',
  plumbing_materials_total = 'Plumbing Materials',
  plumbing_labor_total = 'Plumbing Labor',
  construction_materials_total = 'Construction Materials',
  construction_labor_total = 'Construction Labor',
  building_automation_controls_total = 'Building Automation Controls',
  mobilization_total = 'Mobilization',
  miscellaneous_total = 'Miscellaneous',
}

export enum ProjectTotals {
  demolition_total = 'Demolition',
  electrical_materials_total = 'Electrical Materials',
  electrical_labor_total = 'Electrical Labor',
  mechanical_materials_total = 'Mechanical Materials',
  mechanical_labor_total = 'Mechanical Labor',
  plumbing_materials_total = 'Plumbing Materials',
  plumbing_labor_total = 'Plumbing Labor',
  construction_materials_total = 'Construction Materials',
  construction_labor_total = 'Construction Labor',
  building_automation_controls_total = 'Building Automation Controls',
  mobilization_total = 'Mobilization',
  miscellaneous_total = 'Miscellaneous',
}
export enum EnergySavingOption {
  None = 'None',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum AlloctedByOption {
  None = 'None',
  Quarterly = 'Quarterly',
  Annually = 'Annually',
}

export const  MAX_LEVEL_TYPE = 'max_level';