import {
  Directive,
  EventEmitter,
  forwardRef,
  HostListener,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appFileUpload]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadDirective),
      multi: true,
    },
  ],
})
export class UploadDirective {
  @Output() filesDropped = new EventEmitter<any>();
  @Output() filesHovered = new EventEmitter();

  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();

    this.filesDropped.emit($event.dataTransfer.files);
    this.filesHovered.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    $event.preventDefault();

    this.filesHovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    $event.preventDefault();

    this.filesHovered.emit(false);
  }
}
