import { Injectable } from '@angular/core';
import { CustomNotificationService } from './custom-notification.service';
import { RequestService } from './request.service';
import { combineLatest, Observable, of, forkJoin } from 'rxjs';
import { retry, delay, mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DateTime } from 'luxon';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UploadResourceService {
  constructor(
    private http: HttpClient,
    private _notificationService: CustomNotificationService,
    private _requestService: RequestService
  ) {}

  private uploadFileToS3(data, file): Observable<any> {
    const formData = new FormData();
    for (const field in data.fields) {
      if (data.fields.hasOwnProperty(field)) {
        formData.append(field, data.fields[field]);
      }
    }
    formData.append('file', file, file.name);

    return new Observable((observer) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = function (progEvent: ProgressEvent) {
        if (progEvent.lengthComputable) {
          const uploadedSoFar = (progEvent.loaded / progEvent.total) * 100;

          observer.next(uploadedSoFar);
        }
      };
      xhr.open('POST', data.url, true);
      xhr.send(formData);

      xhr.onload = () => {
        observer.next(100);
        observer.complete();
      };

      xhr.onerror = () => {
        observer.error();
        observer.complete();
      };
    });
  }

  public uploadPartsToS3(data, file, organizationKey): Observable<any> {
    const CHUNK_SIZE = 50 * 1024 * 1024;
    const maxRetries = 3;
    const retryDelay = 1000;

    return new Observable<any>((observer) => {
      const upload: Observable<any>[] = [];
      for (let i = 0; i < data.urls.length; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);

        const headers = new HttpHeaders({ 'Content-Type': file.type });
        upload.push(
          this.http
            .put(data.urls[i].url, chunk, { headers })
            .pipe(retry(maxRetries), delay(retryDelay))
        );
      }
      forkJoin(upload).subscribe({
        next: () => {
          this.completeUploadPartsToS3(
            data.urls[0].upload_id,
            data.object_key,
            data.urls.length,
            organizationKey
          )
            .pipe(retry(maxRetries), delay(retryDelay))
            .subscribe({
              next: () => {
                observer.next(100);
                observer.complete();
              },
              error: () => {
                observer.error();
                observer.complete();
              },
            });
        },
        error: () => {
          observer.error();
          observer.complete();
        },
      });
    });
  }

  public completeUploadPartsToS3(
    upload_id,
    tmp_key,
    parts_count,
    organizationKey
  ): Observable<any> {
    const data = {
      upload_id,
      tmp_key,
      parts_count,
    };
    const headers = {
      [environment.tokens.org_header]: organizationKey,
    };
    return this._requestService.post(
      '/v1/complete_multipart_upload',
      data,
      organizationKey ? headers : null
    );
  }

  public uploadFile(
    file,
    prefix: string,
    organizationKey?: string
  ): Observable<any> {
    const options = {
        key: file.name,
        content_type: file.type,
        prefix: prefix,
      },
      headers = {
        [environment.tokens.org_header]: organizationKey,
      };
    return new Observable<any>((observer) => {
      const CHUNK_SIZE = 50 * 1024 * 1024;
      if (file.size > CHUNK_SIZE) {
        options['parts_count'] = Math.ceil(file.size / CHUNK_SIZE);
        this._requestService
          .get(
            `/v1/multipart_signature`,
            options,
            organizationKey ? headers : null
          )
          .pipe(
            mergeMap((res) =>
              combineLatest(
                of(res),
                this.uploadPartsToS3(res, file, organizationKey)
              )
            )
          )
          .subscribe({
            next: ([res, progress]) => {
              res.progress = progress;
              res.complete = res.progress === 100;
              if (progress === 100) {
                observer.next(res);
                observer.complete();
              }
            },
            error: (err) => {
              this._notificationService.apiError(err);
              observer.error(err.error.error);
              observer.complete();
            },
          });
      } else {
        this._requestService
          .get(`/v1/signature`, options, organizationKey ? headers : null)
          .pipe(
            mergeMap((res) =>
              combineLatest(of(res), this.uploadFileToS3(res, file))
            )
          )
          .subscribe({
            next: ([res, progress]) => {
              res.progress = progress;
              res.complete = res.progress === 100;
              if (progress === 100) {
                observer.next(res);
                observer.complete();
              }
            },
            error: (err) => {
              this._notificationService.apiError(err);
              observer.error(err.error.error);
              observer.complete();
            },
          });
      }
    });
  }

  public dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length,
      blob;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    blob = new Blob([u8arr], { type: mime });
    blob.name = filename;
    return blob;
  }

  public exportedDataToDownload(
    data,
    filePrefix,
    type = 'text/csv',
    extension = 'csv'
  ) {
    const a = document.createElement('a');
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);

    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = `${filePrefix}_${DateTime.now().toFormat('yyyy-MM-dd')}.${extension}`;
    a.click();
    window.URL.revokeObjectURL(url);
    setTimeout(() => a.parentNode.removeChild(a), 500);
  }
}
