import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '../../core/services/helper.service';

@Pipe({
  name: 'customPercent',
})
export class PercentPipe implements PipeTransform {
  public transform(
    value: number | string,
    returnZero = true,
    round: boolean = true
  ): string {
    if (!HelperService.isExist(value)) {
      return returnZero ? '0%' : '';
    }
    return (round ? Math.floor(+value) : HelperService.toFixed(value, 2)) + '%';
  }
}
