export const REGEX = {
  datepicker: /^(\d{2}-[A-Z][a-z]{2}-\d{4})?$/,
  email: /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i,
  phoneNumber: /^(\d{10})?$/,
  zipCode: /^(\d{4,5})?$/,
  notNone: /^(none.+|(?!none).*)$/,
  floatingNumbers: /^-?[0-9]+([.][0-9]+)?$/,
  floatingPositiveNumbers: /^[0-9]+([.][0-9]+)?$/,
  floatingPositiveNumbersNoZero: /^(?=.*[1-9])\d+([.]\d+)?$/,
  floatingNumbersNoZero: /^-?(?=.*[1-9])\d+([.]\d+)?$/,
  numbers: /^[0-9]+?$/,
  numbersNoZero: /^(?=.*[1-9])\d+?$/,
  numbersNegative: /^[-\d]+?$/,
  onlyLatin: /^((?=[\x00-\x7F])[^\s\/\\?%*:|"<>])*$/,
  onlyLatinWhiteSpaced: /^((?=[\x00-\x7F])[^\/\\?%*:|"<>])*$/,
  pmpe: /^((?=[\x00-\x7F])[^\s])*$/,
  regexIntegerNumber: /^\d+?$/,
  regexFloatNumber: /^\d+(\.\d+)?$/,
  regexUdfFormula: /^(\d+|(IF)|(COST)|[+-.^<>() \/*])*$/i,
  regexUdfFormulaOrUARBased: /^(\d+|(IF)|(COST)|[+-.^<>() \/*]|(UAR Based))*$/i,
  regexUarFormula: /^(\d+|(IF)|(QUANTITY)|[+-.^<>() \/*])*$/i,
  regexUriValidation:
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
  specialSymbols: /^(?!-|@|=|\+)./,
  onlyAlphabets: '[a-zA-Z ]*',
  aeValidation: '^(?:d+,)*d+$',
  urlValidation: /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/,
  noWhiteSpace: /^\S+$/,
  alphanumeric: /^(?!.*__)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*$/,
};
