import { DateTime } from 'luxon';

export class PresetModel {
  id: number;
  name: string;
  shared: boolean;
  user_id: number;
  preset: {
    presetId: number | number[];
    classification_id?: any;
    location_id?: any;
    building_id?: any;
    zone_id?: any;
    floor_id?: any;
    room_id?: any;
    assets_repository_id?: any;
    trade_id?: any;
    fhie_score_from?: any;
    fhie_score_to?: any;
    total_risk_rank_from?: any;
    total_risk_rank_to?: any;
    lifetime_end_regular_from?: string;
    lifetime_end_regular_to?: string;
    score_from?: any;
    score_to?: any;
    fhi_index_from?: any;
    fhi_index_to?: any;
    period?: any;
    fhim_r_index?: any;
  };
  default: boolean;

  constructor(model?) {
    if (model) {
      Object.assign(this, model);
      if (model.preset.lifetime_end_regular_from) {
        this.preset.lifetime_end_regular_from = DateTime.fromISO(
          model.preset.lifetime_end_regular_from
        ).toISO();
      }
      if (model.preset.lifetime_end_regular_to) {
        this.preset.lifetime_end_regular_to = DateTime.fromISO(
          model.preset.lifetime_end_regular_to
        ).toISO();
      }
    }
  }
}
