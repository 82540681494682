<td
  [attr.colspan]="colspan"
  *ngIf="isLoading || !items?.length"
  class="loader-align"
>
  <div *ngIf="!isLoading && items && !items.length" class="no-items">
    {{ message }}
  </div>

  <div *ngIf="isLoading || !items">
    <app-loader></app-loader>&nbsp;<span class="sr-only">Loading...</span>
  </div>
</td>
