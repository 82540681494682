import { Component, Input, OnInit } from '@angular/core';
import { RoutesConstants } from '../../../core/constants/routes.constants';
import { Router } from '@angular/router';
import { UserCurrentService } from '../../../core/services/user-current.service';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { UserStoreService } from '../../../core/services/user.store';
import { HelperService } from '../../../core/services/helper.service';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-link',
  templateUrl: './user-link.component.html',
  styleUrls: ['./user-link.component.less'],
})
export class UserLinkComponent implements OnInit {
  @Input() public userName: string = '';
  @Input() public userId: string | number;
  @Input() public isActive: boolean = true;
  @Input() public orgId?: string;
  @Input() public isBold?: boolean;
  @Input() isHighlightText: boolean;
  @Input() searchText$ = new BehaviorSubject('');
  public isSelectedTenant: boolean = true;
  public currentUserRole: string;

  constructor(
    protected _userCurrent: UserCurrentService,
    protected router: Router,
    protected _organizationCurrent: OrganizationCurrentService,
    protected _userStore: UserStoreService,
    private _notificationService: CustomNotificationService,
    public _featureFlagsService?: FeatureFlagsService
  ) {}

  ngOnInit() {
    this.currentUserRole = this._userCurrent.user?.role_name;
  }

  public userDetailsMode(id) {
    this.orgId ||= this._organizationCurrent.organization?.id_hash;
    if (!this.orgId) {
      this.navigateToUser(id);
      return;
    }
    this._userStore
      .getUsersByOrganization({ per_page: 9999, page: 1 }, this.orgId)
      .subscribe({
        next: (data) => {
          if (data.list.filter((item) => item.id === parseInt(id)).length) {
            this.isSelectedTenant = false;
          }
          this.navigateToUser(id);
        },
        error: (err) => this._notificationService.apiError(err),
      });
  }

  public navigateToUser(id: number | string) {
    let path: string = '';
    if (this._userCurrent.user?.id === id) {
      path = this.isSelectedTenant ? `/profile` : `/${this.orgId}/profile`;
    } else {
      path =
        this.orgId && !this.isSelectedTenant
          ? RoutesConstants.USERS.DETAILS(id, this.orgId)
          : RoutesConstants.TENANT_USERS.DETAILS(id);
    }
    this.router.navigate([path]);
  }

  public canViewLink() {
    let permission: string;

    if (this.isSelectedTenant) {
      permission = 'ta_tu';
    } else {
      permission = 'ta_tu_te_ca_cu_ce_pa_pe';
    }

    return HelperService.hasPermissions(permission, this.currentUserRole);
  }
}
