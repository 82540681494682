import { Component } from '@angular/core';
import { UserCurrentService } from '../../../core/services/user-current.service';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EventService } from '../../../core/services/event.service';
import { BaseReactiveForm } from '../../components/base-reactive-form';
import { ErrorService } from '../../../core/services/error.service';
import { AutoUnsubscribeService } from '../../../core/services/auto-unsubscribe.service';
import { takeUntil } from 'rxjs/operators';
import { isEqual } from '../../validators/is-equal';

@Component({
  templateUrl: './user-change-password.component.html',
})
export class UserChangePasswordComponent extends BaseReactiveForm<any> {
  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService,
    protected _destroy: AutoUnsubscribeService,
    private _userCurrent: UserCurrentService
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }

  public ngOnInit(): void {
    this.isLoading = false;
    this.setupForm();
  }

  protected setupForm(): void {
    const validatorsSet = [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(72),
    ];

    this.form = this.formBuilder.group(
      {
        current_password: ['', validatorsSet],
        new_password: ['', validatorsSet],
        new_password_confirmation: ['', validatorsSet],
      },
      {
        validator: isEqual('new_password', 'new_password_confirmation'),
      }
    );
  }

  public saveAction(): void {
    if (!this.isFormValid) {
      return;
    }

    this.isLoading = true;

    this._userCurrent
      .update(this.form.value)
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: () => {
          this._notificationService.success('Password has been changed');
          this.close();
        },
        error: (err) => {
          this.isLoading = false;
          this.setErrorToControl(err);
        },
      });
  }
}
