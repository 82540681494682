import { BudgetingService } from 'src/app/core/services/budgeting.service';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-checkbox-renderer',
  templateUrl: './custom-checkbox-renderer.component.html',
  styleUrls: ['./custom-checkbox-renderer.component.less'],
})
export class CustomCheckboxRendererComponent
  implements ICellRendererAngularComp
{
  params: any;
  status: boolean;
  headerCheckboxClicked: boolean;
  isTreeDataEnabledOnBudgeting: boolean = false;
  constructor(public _budgetingService: BudgetingService) {}

  agInit(params: any): void {
    this.params = params;
    this.isTreeDataEnabledOnBudgeting =
      this.params?.isTreeDataEnabledOnBudgeting;
    this.updateStatus();
  }

  refresh(params: any): boolean {
    this.params = params;
    this.updateStatus();
    return true;
  }

  updateStatus(): void {
    this._budgetingService.headerCheckboxClicked$.subscribe((value) => {
      this.headerCheckboxClicked = value;
    });

    if (this.headerCheckboxClicked) {
      this._budgetingService.updateHeaderCheckboxState$.subscribe((value) => {
        if (this.headerCheckboxClicked) {
          this.status = value;
        }
      });
    } else {
      this.status =
        this.params.listType === 'replacement'
          ? this.params.data.replacement_status
          : this.params.data.maintenance_status;
    }
  }

  onCheckboxChange(event: boolean): void {
    this._budgetingService.setHeaderCheckboxClicked(false);
    this.status = event;
    this.params.context.assetChecked(
      this.params.rowIndex,
      this.params.data,
      event
    );
  }
}
