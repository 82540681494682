export interface TableauDashboardModel {
  id?: number;
  name: string;
  available_for: Array<string>;
  organization_ids?: Array<number>;
  uri: string;
  position?: number;
  disable_drag?: boolean;
}

export class TableauSettingsModel {
  user_name?: string;
  password?: string;
  host?: string;
  port?: number;
  site?: string;
  dashboards?: Array<TableauDashboardModel>;

  constructor(model?) {
    if (model) {
      Object.assign(this, model);
    }
    this.dashboards ||= [];
  }

  public get entity_name(): string {
    return 'tableau_settings';
  }
}

