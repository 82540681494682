<section class="modal-content__wrapper">
  <header class="modal-content__header project-modal">
    {{ header }}
  </header>
  <form [formGroup]="form" (ngSubmit)="saveAction()">
    <div class="modal-content__body">
      <div>
        <label for="name" class="modal-content__label"
          >{{ nameLabel }}<span class="asterisk red">&ast;</span></label
        >
        <div class="form-block mrgn-b_sm">
          <input
            [ngClass]="{ 'form-block_error': hasError('name') }"
            formControlName="name"
            type="text"
            required
            placeholder="{{ placeholder }}"
            class="form-block__element"
          />
          <div *ngIf="formErrors['name']" class="error-message">
            {{ formErrors["name"] }}
          </div>
          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('name') && !formErrors['name']"
            [tooltip]="getErrorText('name')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </div>

      <ul>
        <li class="modal-content__item modal-content__item_full-width">
          <label class="modal-content__label" for="accessNameInput"
            >{{ accessName }}<span class="asterisk red">&ast;</span></label
          >
          <label
            class="custom-radiobutton custom-radiobutton_xs mrgn-b_sm xs-100"
          >
            <input
              [value]="true"
              formControlName="public"
              class="custom-radiobutton__input"
              type="radio"
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled"
            ></span>
            Public
          </label>
          <label
            class="custom-radiobutton custom-radiobutton_xs mrgn-b_sm xs-100"
          >
            <input
              [value]="false"
              formControlName="public"
              class="custom-radiobutton__input"
              type="radio"
            />
            <span
              class="custom-radiobutton__check custom-radiobutton__check_labeled"
            ></span>
            Private
          </label>
        </li>
      </ul>
    </div>

    <div class="buttons-block modal-content__footer">
      <button
        id="saveForecastCancelBtnId"
        type="button"
        (click)="close()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__title">{{ cancelText }}</span>
      </button>

      <button class="btn action-btn action-btn_success">
        <span class="action-btn__title">{{ submitText }}</span>
      </button>
    </div>
  </form>
</section>
