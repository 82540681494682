import { Component, Input } from '@angular/core';
import { IntegrationApiModel } from '../../../core/models/integration-api.model';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { IntegrationApiService } from '../../../core/services/integration-api.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ErrorService } from '../../../core/services/error.service';
import { BaseReactiveForm } from '../../components/base-reactive-form';
import { AutoUnsubscribeService } from '../../../core/services/auto-unsubscribe.service';
import { patternValidator } from '../../validators/custom-pattern.validator';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { OrganizationModel } from 'src/app/core/models/organization.model';
import { REGEX } from '../../../core/constants/regex.constants';
import { DateTime } from 'luxon';

@Component({
  templateUrl: './integration-api-edit-modal.component.html',
})
export class IntegrationApiEditModalComponent extends BaseReactiveForm<IntegrationApiModel> {
  @Input() public organization: OrganizationModel;
  public updateCompleted$ = new Subject<void>();

  public get isEdit(): boolean {
    return !!this.model.id;
  }

  public get minDate() {
    return DateTime.fromISO(this.formData.from_date) > DateTime.now()
      ? null
      : this.formData.from_date;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService,
    protected activeModal: BsModalService,
    protected _errorService: ErrorService,
    protected _destroy: AutoUnsubscribeService,
    protected _integrationApiService: IntegrationApiService,
    protected _featureFlagsService: FeatureFlagsService
  ) {
    super(
      formBuilder,
      _notificationService,
      _eventService,
      activeModal,
      _errorService
    );
  }

  public ngOnInit() {
    this.setupForm();
  }

  public addEmail(): void {
    this.getFormArray('emails').push(this.createEmailControl());
  }

  public removeEmail(index: number): void {
    this.getFormArray('emails').splice(index, 1);
  }

  public saveAction(): void {
    this.model.skip_ae_validation = true;
    if (!this.formData.from_date && this.formData.to_date) {
      this.form
        .get('from_date')
        .setErrors({ api: "From Date can't be blank." });
    } else if (this.formData.from_date && !this.formData.to_date) {
      this.form.get('to_date').setErrors({ api: "To Date can't be blank." });
    } else if (
      this.formData.url &&
      !this.formData.from_date &&
      !this.formData.to_date
    ) {
      this.form.get('from_date').reset();
      this.form.get('to_date').reset();
    }
    if (!this.isFormValid) {
      return;
    }
    this.setFormFieldsToModelProperty();
    if (this.model.url) {
      this.model.skip_ae_validation = false;
      this.model.url =
        this.model.url[this.model.url.length - 1] == '/'
          ? this.model.url.substring(0, this.model.url.length - 1)
          : this.model.url;
    }
    let method;
    if (this.isEdit) {
      method = this._integrationApiService.update(this.model);
    } else {
      method = this._integrationApiService.create(this.model);
    }

    method.pipe(takeUntil(this._destroy)).subscribe(
      () => {
        this.close();
        this.updateCompleted$.next();
      },
      (err) => this.setErrorToControl(err)
    );
  }

  public trackByIdx(index: number): any {
    return index;
  }

  protected setupForm(): void {
    const emails: FormControl[] = this.model.emails.map((email) =>
      this.createEmailControl(email)
    );
    let obj = {
      emails: this.formBuilder.array(emails),
      name: [this.model.name, [Validators.required, Validators.maxLength(80)]],
      webhook: [this.model.webhook?.callback_url],
    };
    if (
      this.organization.cmms_client_name == 'Asset Essentials' &&
      (this.model.url || !this.model.tenant) &&
      this._featureFlagsService.isFeatureEnabled('ae_cmms_changes')
    ) {
      obj['url'] = [
        this.model.url,
        [Validators.required, Validators.pattern(REGEX.urlValidation)],
      ];
      obj['from_date'] = [this.model.from_date];
      obj['to_date'] = [this.model.to_date];
    }
    this.form = this.formBuilder.group(obj);
  }

  private createEmailControl(email?): FormControl {
    return new FormControl(email, [
      Validators.required,
      Validators.maxLength(200),
      patternValidator('email'),
    ]);
  }

  private setFormFieldsToModelProperty(): void {
    Object.keys(this.formData).forEach(
      (key) => (this.model[key] = this.formData[key])
    );
  }
}
