<div class="board" [ngClass]="{ board_embeded: isEmbeded }">
  <header *ngIf="withHeader">
    <section class="header flex">
      <h2 class="header__title">{{ currentTypeDetails.name }}</h2>
    </section>
  </header>
  <table class="table table_striped" id="item_logs_list_table_id">
    <thead class="table__header">
      <tr>
        <th
          [ngClass]="getSortingCellClass('user_id')"
          (click)="setSortBy('user_id')"
          class="table__sorting-column"
        >
          User
        </th>
        <th
          [ngClass]="getSortingCellClass('created_at')"
          (click)="setSortBy('created_at')"
          class="table__sorting-column"
        >
          Date
        </th>
        <th>Event</th>

        <th>Logs</th>
      </tr>
    </thead>
    <tbody>
      <!--Loader-->
      <app-table-loader
        [colspan]="4"
        [isLoading]="isLoading"
        [items]="items"
      ></app-table-loader>
      <!--END Loader-->
      <ng-container *ngFor="let value of items">
        <tr>
          <td>
            <app-user-link
              [userId]="value.user_id"
              [userName]="value.user_name"
              [isActive]="true"
              [orgId]="orgId"
            ></app-user-link>
          </td>
          <td>
            {{
              value.frontend_date || value.created_at
                | dateFormat: 'MMM d, yyyy h:mm a'
            }}
          </td>
          <td>{{ value.event }}</td>
          <td>
            <button
              type="button"
              class="btn-add-attribute btn-add-attribute_blue"
              (click)="value.showDetails = !value.showDetails"
            >
              <app-svg
                *ngIf="value.showDetails"
                [href]="'minus-square'"
              ></app-svg>
              <app-svg
                *ngIf="!value.showDetails"
                [href]="'plus-square'"
              ></app-svg>
              <b>{{ value.showDetails ? ' Hide details' : ' Show details' }}</b>
            </button>
          </td>
        </tr>
        <tr [hidden]="!value.showDetails">
          <td colspan="4">
            <div class="log-details">
              <ngx-json-viewer
                [json]="value"
                [expanded]="false"
              ></ngx-json-viewer>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <footer
    class="content-footer el-in-a-row el-in-a-row_c el-in-a-row_lg flex_to-end"
  >
    <app-pagination-caption
      [start]="getStartPageItem()"
      [end]="getEndPageItem()"
      [total]="total"
    ></app-pagination-caption>
    <app-pagination
      [hasLeftDots]="hasLeftDots"
      [hasRightDots]="hasRightDots"
      [hasNextPage]="hasNextPage"
      [hasPrevPage]="hasPrevPage"
      [page]="page"
      [shouldPassPageNumber]="false"
      [pages]="pages"
      [totalPages]="totalPages"
      (onNextPage)="nextPage()"
      (onPrevPage)="prevPage()"
      (onSetPage)="setPage($event)"
    ></app-pagination>
  </footer>
</div>
