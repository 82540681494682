import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RoutesConstants } from '../../core/constants/routes.constants';
import { Observable } from 'rxjs';
import { OrganizationCurrentService } from '../../core/services/organization-current.service';
import { RoutingService } from '../../core/services/routing.service';
import { RoleType } from '../../core/constants/types.constants';
import { Settings } from 'luxon';

@Injectable()
export class ClientOrganizationGuard {
  constructor(
    private router: Router,
    private _organizationCurrent: OrganizationCurrentService,
    private _routingService: RoutingService
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean> | boolean {
    const org_id = next.params['org_id'];
    this._organizationCurrent.clientKey = org_id;
    // remove tenant creds
    this._organizationCurrent.tenantKey = null;
    this._organizationCurrent.cleanTenant();
    if (org_id) {
      return new Observable<boolean>((observe) => {
        this._organizationCurrent.get().subscribe(
          (organization) => {
            Settings.defaultZone = organization.time_zone;
            this._routingService.routeType = 'client' as RoleType;
            observe.next(true);
            observe.complete();
          },
          (error) => {
            if (error?.error === 'Not Found') {
              this.router.navigate([RoutesConstants.NOT_FOUND], {
                replaceUrl: true,
              });
            } else {
              this.router.navigate([RoutesConstants.HOME]);
            }
            observe.next(false);
            observe.complete();
          }
        );
      });
    } else {
      this._organizationCurrent.clean();
    }
    return false;
  }
}
