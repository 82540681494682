import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CustomValidationService {
  compareValidator(dateFrom: string, dateTo: string) {
    return (formGroup: FormGroup) => {
      const dateFromControl = formGroup.controls[dateFrom];
      const dateToControl = formGroup.controls[dateTo];

      if (!dateFromControl || !dateToControl) {
        return null;
      }
      if (
        dateToControl.errors &&
        dateFromControl.errors &&
        !dateToControl.errors.dateMismatch &&
        !dateFromControl.errors.dateMismatch
      ) {
        return null;
      }
      if (dateFromControl.value > dateToControl.value) {
        dateFromControl.setErrors({ dateMismatch: true });
        dateToControl.setErrors({ dateMismatch: true });
      } else {
        dateFromControl.setErrors(null);
        dateToControl.setErrors(null);
      }
    };
  }
}
