import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { LogModel } from '../models/log.model';
import { RequestService } from './request.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DefaultListModel } from '../models/default-list.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoggingService extends BaseStoreService<LogModel> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, LogModel);
    this.resourceName = 'v1/event_log';
  }

  public getUserActivity(
    params: any,
    id: number,
    org: string
  ): Observable<DefaultListModel<LogModel>> {
    this.headers = {
      [environment.tokens.org_header]: org,
    };
    return super.getItems(params, `v1/users/${id}/activities`).pipe(
      map((resp) => {
        this.headers = null;
        return resp;
      })
    );
  }

  public getItems(params, org?): Observable<DefaultListModel<LogModel>> {
    if (org) {
      this.headers = {
        [environment.tokens.org_header]: org,
      };
    } else {
      this.headers = null;
    }
    return super.getItems(params).pipe(
      map((resp) => {
        this.headers = null;
        return resp;
      })
    );
  }
}
