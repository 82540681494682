import { InjectionToken } from '@angular/core';
import { LicenseManager } from 'ag-grid-enterprise';

export const AG_GRID_LICENSE_TOKEN = new InjectionToken<string>(
  'AG_GRID_LICENSE'
);

/**
 * Function to set the license key (can be used in services or components)
 * @param licenseKey
 */
export function setAgGridLicense(licenseKey: string): void {
  LicenseManager.setLicenseKey(licenseKey);
}

export const provideAgGridLicense = (licenseKey: string) => [
  {
    provide: AG_GRID_LICENSE_TOKEN,
    useFactory: () => setAgGridLicense(licenseKey),
    multi: true,
  },
];
