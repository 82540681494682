import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { RequestService } from './request.service';
import { PresetModel } from '../models/preset.model';
import { Observable } from 'rxjs';

@Injectable()
export class PresetService<M> extends BaseStoreService<M> {
  constructor(protected _requestService: RequestService) {
    super(_requestService, PresetModel);
    this.resourceName = 'v1/filter_presets';
  }

  public create(params): Observable<M> {
    return this._requestService.post(
      `/${this.resourceName}`,
      params,
      this.headers
    );
  }

  public update(id: any, params): Observable<M> {
    return this._requestService.put(
      `/${this.resourceName}/${id}`,
      params,
      this.headers
    );
  }
}
