<div
  dropdown
  [autoClose]="true"
  [isDisabled]="disabled"
  [placement]="'bottom ' + placement"
  class="multiselect {{ widthClass }}"
>
  <button
    id="selectComponentBtnId"
    type="button"
    class="btn select multiselect__btn {{ heightClass }}"
    dropdownToggle
  >
    {{
      selectedOption?.[optValue]?.replace(removeFromOptValue, '') ||
        (showPlaceholder
          ? placeholder
          : values[0]?.[optValue]?.replace(removeFromOptValue, ''))
    }}
  </button>

  <ul
    *dropdownMenu
    class="multiselect__dropdown multiselect__options-list multiselect__dropdown_{{
      placement
    }}"
    role="menu"
  >
    <li
      role="menuitem"
      class="multiselect__option"
      *ngIf="showPlaceholder"
      [ngClass]="{ multiselect__option_active: !helper.isExist(model) }"
    >
      <button
        type="button"
        class="btn multiselect__label"
        (click)="onChange(null)"
      >
        {{ placeholder }}
      </button>
    </li>
    <li
      role="menuitemcheckbox"
      class="multiselect__option"
      *ngFor="let value of values; let index = index; trackBy: trackByIdx"
      [ngClass]="{
        multiselect__option_active: model === value[optKey],
        disabled: value[optDisabledWhenFieldTrue]
      }"
    >
      <button
        [disabled]="value[optDisabledWhenFieldTrue]"
        type="button"
        class="btn multiselect__label"
        (click)="onChange(value)"
      >
        {{ value[optValue] }}
      </button>
    </li>
  </ul>
</div>
