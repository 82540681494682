<section class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header project-modal">
      {{ header }}
    </h2>
  </header>

  <div class="text-center modal-content__body" *ngIf="isLoading">
    <p class="modal-content__message mrgn-b_sm">Please, wait...</p>
    <app-loader></app-loader>
  </div>

  <form
    [formGroup]="form"
    [hidden]="isLoading"
    (keydown.enter)="$event.preventDefault()"
  >
    <section class="modal-content__body" *ngIf="apiErrors.length">
      <p
        *ngFor="let error of apiErrors"
        class="modal-content__message mrgn-b_sm"
        [innerHTML]="error.message"
      ></p>
    </section>

    <section class="modal-content__body" *ngIf="message">
      <p class="modal-content__message" [innerHTML]="message"></p>
      <ng-container *ngIf="forecastData && forecastData.warnings">
        <p
          *ngFor="let warning of forecastData.warnings"
          class="modal-content__message error"
        >
          {{ warning.message }}
        </p>
      </ng-container>
    </section>

    <ul
      class="modal-content__list modal-content__body"
      *ngIf="!message && !apiErrors.length"
    >
      <li class="modal-content__item modal-content__item_full-width">
        <label for="projectPeriod" class="modal-content__label">{{
          'fiscal_year' | translate
        }}</label>
        <div class="form-block">
          <app-select
            formControlName="period"
            id="projectPeriod"
            [ngClass]="{ 'form-block_error': hasError('period') }"
            [showPlaceholder]="false"
            [values]="periodList"
          >
          </app-select>

          <button
            type="button"
            class="btn tooltip-btn_error"
            *ngIf="hasError('period')"
            [tooltip]="getErrorText('period')"
          >
            <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
          </button>
        </div>
      </li>
    </ul>

    <section
      class="buttons-block modal-content__footer"
      [ngClass]="{
        'buttons-block_to-center': this.needToBeFixed,
        'buttons-block_not-bordered': this.needToBeFixed
      }"
    >
      <button
        *ngIf="message || !this.needToBeFixed"
        class="btn action-btn action-btn_default"
        (click)="close()"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{ cancelText }}</span>
      </button>
      <button
        [disabled]="!form?.dirty"
        *ngIf="!message"
        (click)="submit()"
        class="btn action-btn action-btn_success"
      >
        <span class="action-btn__icon">
          <app-svg [href]="'check'"></app-svg>
        </span>
        <span class="action-btn__title">{{ submitText }}</span>
      </button>
    </section>
  </form>
</section>
