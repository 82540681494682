<section class="modal-content__wrapper">
  <header class="modal-content__header">Log {{ title }}</header>

  <form
    #eventForm="ngForm"
    (ngSubmit)="saveAction()"
    (keydown.enter)="$event.preventDefault()"
    appInvalidControlScroll
  >
    <fieldset
      class="modal-content__body modal-content__body_without-form"
      *ngIf="isLoading"
    >
      <app-loader></app-loader>
      <span class="sr-only">Loading...</span>
    </fieldset>
    <ul class="modal-content__body modal-content__list" *ngIf="model">
      <!-- Work Order Completion Date -->
      <li class="modal-content__item">
        <label for="work_order_completed_at" class="modal-content__label"
          >Completion Date<span class="asterisk">*</span></label
        >

        <mat-form-field
          class="datepicker-block datepicker-block__display-block"
          [ngClass]="{ 'form-block_error': hasError(work_order_completed_at) }"
        >
          <input
            type="text"
            id="work_order_completed_at"
            name="work_order_completed_at"
            placeholder="Date"
            matInput
            required
            (keydown.enter)="$event.target.blur()"
            #work_order_completed_at="ngModel"
            [disabled]="isLoading"
            [matDatepicker]="_work_order_completed_at"
            [(ngModel)]="model.work_order_completed_at"
            [min]="minDate"
            [max]="maxDate"
          />
          <mat-datepicker-toggle matSuffix [for]="_work_order_completed_at">
            <mat-icon matDatepickerToggleIcon>
              <app-svg [href]="'datepicker-calendar'"></app-svg>
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker
            #_work_order_completed_at
            [startAt]="model.work_order_completed_at"
          ></mat-datepicker>
        </mat-form-field>
        <p class="error" *ngIf="hasError(work_order_completed_at)">
          <ng-container *ngIf="work_order_completed_at.errors.required">
            {{ 'validation.required' | translate }}
          </ng-container>
          <ng-container *ngIf="work_order_completed_at.errors.date">
            {{ 'validation.bad_date' | translate }}
          </ng-container>
          <ng-container *ngIf="work_order_completed_at.errors.matDatepickerMin">
            {{ 'validation.min_date' | translate }}
          </ng-container>
          <ng-container *ngIf="work_order_completed_at.errors.matDatepickerMax">
            {{ 'validation.max_date' | translate }}
          </ng-container>
          <ng-container
            *ngIf="work_order_completed_at.errors.matDatepickerParse"
          >
            {{ 'validation.bad_date' | translate }}
          </ng-container>
        </p>
      </li>
      <!-- PM/PE Number -->
      <li
        class="modal-content__item"
        *ngIf="model?.entity_name === 'maintenance'"
      >
        <label for="pm_pe_number" class="modal-content__label"
          >PM/PE Number<span class="asterisk">*</span></label
        >
        <input
          type="text"
          name="pm_pe_number"
          id="pm_pe_number"
          #_pm_pe_number="ngModel"
          placeholder="Enter..."
          class="form-block__element"
          [ngClass]="{ 'form-block_error': hasError(_pm_pe_number) }"
          [disabled]="isLoading"
          [(ngModel)]="model.pm_pe_number"
          required
          [pattern]="regex.pmpe"
          [customMaxlength]="15"
        />

        <p class="error" *ngIf="hasError(_pm_pe_number)">
          <ng-container *ngIf="_pm_pe_number.errors.required">
            {{ 'validation.required' | translate }}
          </ng-container>
          <ng-container *ngIf="_pm_pe_number.errors.pattern">
            {{ 'validation.only_latin' | translate }}
          </ng-container>
          <ng-container *ngIf="_pm_pe_number.errors.maxlength">
            {{ 'validation.maxlength' | translate }}
          </ng-container>
        </p>
      </li>
      <!-- Work Order Number -->
      <li
        class="modal-content__item modal-content__item_last-in-row"
        [ngClass]="{
          'modal-content__item_two-third-width':
            model?.entity_name !== 'maintenance'
        }"
      >
        <label for="work_order_number" class="modal-content__label"
          >Work Order Number<span class="asterisk">*</span></label
        >

        <input
          type="text"
          class="form-block__element"
          name="work_order_number"
          id="work_order_number"
          placeholder="Enter..."
          #_work_order_number="ngModel"
          [ngClass]="{ 'form-block_error': hasError(_work_order_number) }"
          [(ngModel)]="model.work_order_number"
          required
          [disabled]="isLoading"
          [pattern]="regex.pmpe"
          [customMaxlength]="30"
        />
        <p class="error" *ngIf="hasError(_work_order_number)">
          <ng-container *ngIf="_work_order_number.errors.required">
            {{ 'validation.required' | translate }}
          </ng-container>
          <ng-container *ngIf="_work_order_number.errors.pattern">
            {{ 'validation.only_latin' | translate }}
          </ng-container>
          <ng-container *ngIf="_work_order_number.errors.maxlength">
            {{ 'validation.maxlength' | translate }}
          </ng-container>
          <ng-container *ngIf="_work_order_number.errors.api">
            {{ _work_order_number.errors.api }}
          </ng-container>
        </p>
      </li>
      <!-- Parts Cost -->
      <li class="modal-content__item">
        <label for="parts_cost" class="modal-content__label"
          >Parts Cost ($)</label
        >
        <input
          type="text"
          #_parts_cost="ngModel"
          name="parts_cost"
          id="parts_cost"
          placeholder="Enter..."
          class="form-block__element"
          [ngClass]="{ 'form-block_error': hasError(_parts_cost) }"
          [disabled]="isLoading"
          [(ngModel)]="model.parts_cost"
          [pattern]="regex.floatingNumbers"
          [customMax]="1e9 - 0.01"
          [appAfterDot]="2"
          prefix="$"
          mask="separator.0"
          thousandSeparator=","
        />
        <p class="error" *ngIf="hasError(_parts_cost)">
          <ng-container *ngIf="_parts_cost.errors.pattern">
            {{ 'validation.number' | translate }}
          </ng-container>
          <ng-container *ngIf="_parts_cost.errors.customMax">
            {{
              'validation.costMax'
                | translate: { name: 'Parts Cost', number: 1e9 - 0.01 }
            }}
          </ng-container>
          <ng-container *ngIf="_parts_cost.errors.afterdot">
            {{ 'validation.after_dot' | translate: { number: '2' } }}
          </ng-container>
        </p>
      </li>

      <!-- Time Charge -->
      <li class="modal-content__item">
        <label for="time_charge" class="modal-content__label"
          >Time Charge (hrs)<span class="asterisk">*</span></label
        >
        <div class="form-block__element flex_nowrap">
          <input
            type="number"
            class="form-block__input-small"
            [(ngModel)]="model.time_charge"
            (ngModelChange)="calculateLabourCost()"
            [ngClass]="{ 'form-block_error': hasError(_time_charge) }"
            required
            [disabled]="isLoading"
            [pattern]="regex.floatingPositiveNumbers"
            name="time_charge"
            id="time_charge"
            #_time_charge="ngModel"
            [max]="9999.99"
            [appAfterDot]="2"
            placeholder="Enter..."
          />

          <span class="form-block__input-label"
            >x&nbsp;${{ organization.labour_hour_rate }}/h</span
          >
        </div>
        <p class="error" *ngIf="hasError(_time_charge)">
          <ng-container *ngIf="_time_charge.errors.required">
            {{ 'validation.required' | translate }}</ng-container
          >
          <ng-container *ngIf="_time_charge.errors.pattern">{{
            'validation.number' | translate
          }}</ng-container>
          <ng-container *ngIf="_time_charge.errors.max">{{
            'validation.max'
              | translate: { Name: 'Time charge', number: '9999' }
          }}</ng-container>
          <ng-container *ngIf="_time_charge.errors.afterdot"
            >{{ 'validation.after_dot' | translate: { number: '2' } }}
          </ng-container>
        </p>
      </li>
      <!-- Labor Cost -->
      <li class="modal-content__item modal-content__item_last-in-row">
        <label for="labour_cost" class="modal-content__label"
          >Labor Cost ($)<span class="asterisk">*</span></label
        >
        <input
          id="labour_cost"
          name="labour_cost"
          #_labour_cost="ngModel"
          placeholder="Enter..."
          type="text"
          class="form-block__element"
          [(ngModel)]="model.labor_cost"
          [ngClass]="{ 'form-block_error': hasError(_labour_cost) }"
          [disabled]="isLoading"
          required
          [pattern]="regex.floatingNumbers"
          [customMax]="1e9 - 0.01"
          [appAfterDot]="2"
          prefix="$"
          mask="separator.0"
          thousandSeparator=","
        />

        <p class="error" *ngIf="hasError(_labour_cost)">
          <ng-container *ngIf="_labour_cost.errors.required">
            {{ 'validation.required' | translate }}
          </ng-container>
          <ng-container *ngIf="_labour_cost.errors.pattern">
            {{ 'validation.number' | translate }}
          </ng-container>
          <ng-container *ngIf="_labour_cost.errors.customMax">
            {{
              'validation.costMax'
                | translate: { name: 'Labor Cost', number: 1e9 - 0.01 }
            }}
          </ng-container>
          <ng-container *ngIf="_labour_cost.errors.afterdot"
            >{{ 'validation.after_dot' | translate: { number: '2' } }}
          </ng-container>
        </p>
      </li>

      <!-- Comment -->
      <li class="modal-content__item modal-content__item_full-width">
        <label for="comment" class="modal-content__label">Comment</label>

        <textarea
          class="form-block__element"
          [ngClass]="{ 'form-block_error': hasError(_comment) }"
          [(ngModel)]="model.comment"
          [disabled]="isLoading"
          id="comment"
          name="comment"
          rows="8"
          #_comment="ngModel"
          [customMaxlength]="2000"
        ></textarea>
        <p
          class="error"
          *ngIf="hasError(_comment) && _comment.errors.maxlength"
        >
          {{ 'validation.maxlength' | translate }}
        </p>
      </li>
    </ul>

    <!-- Buttons -->
    <footer class="buttons-block modal-content__footer">
      <button
        type="button"
        [disabled]="isSavingModel"
        (click)="cancel()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>

      <button
        [disabled]="isSavingModel"
        (click)="saveAction()"
        class="btn action-btn action-btn_success"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Submit</span>
      </button>
    </footer>
  </form>
</section>
