import { DefaultModel } from './default.model';
import { InvestmentDTO } from '../dto/investment.dto';
import { DateTime } from 'luxon';
import { IServiceLog } from './service-log.model';

export class InvestmentModel
  extends DefaultModel<InvestmentDTO>
  implements IServiceLog
{
  investment_date?: string;
  investment_amount: number;
  comment: string;
  asset: {
    id: number;
    name: string;
    asset_number: string;
    project_replacement_cost: number;
  };
  asset_id: number;
  asset_number: string;
  id: number;
  name: string;
  score: number;
  assessment?: {
    id?: number;
  };
  investment_percent: number;

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
    }
    this.investment_percent = model?.id ? null : 0.25;
    this.investment_date = this.investment_date
      ? DateTime.fromISO(this.investment_date).toISO()
      : DateTime.now().toISO();
  }
  public dto(): InvestmentDTO {
    return new InvestmentDTO(this);
  }

  public get entity_name(): string {
    return 'capital_improvement';
  }

  public toString(): string {
    return 'capital_improvement';
  }
}
