import { BaseStoreService } from './base-store.service';
import { FileImportModel } from '../models/file-import.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { AssetFieldsConstants } from '../constants/asset_fields.constants';

@Injectable({
  providedIn: 'root',
})
export class FileImportService<M> extends BaseStoreService<M> {
  protected resourceName = `v1/settings/integrations/ftp`;

  constructor(protected _requestService: RequestService) {
    super(_requestService, FileImportModel);
  }

  checkCredentials(model: FileImportModel): Observable<M> {
    return this._requestService.get(
      `/${this.resourceName}/${model.id}/test_connection`,
      this.headers
    );
  }

  getCsvColumnsList(): Observable<any> {
    return this._requestService.get(
      `/${this.resourceName}/default_csv_columns_list`,
      this.headers
    );
  }

  prepareColumnsList(
    modelColumns: any,
    onlyChecked: boolean = false
  ): Array<any> {
    const initialGroups = AssetFieldsConstants.FIELD_GROUPS;
    const groupedColumns = [];
    const allColumns = modelColumns;
    let count = 0;

    AssetFieldsConstants.FIELD_TYPES.forEach((group) => {
      const groupActiveItems = [];
      const groupColumns = Object.keys(group.items);
      const title = group.name
        .split(/(?=[A-Z])|_/)
        .join(' ')
        .capitalize();

      groupColumns.forEach((column) => {
        const groupName = group.items[column];
        groupActiveItems.push(
          ...allColumns['general_columns'].filter((item) => {
            let result;
            result = onlyChecked
              ? item.title === groupName && item.checked
              : item.title === groupName;
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            result && count++;
            return result;
          })
        );
      });
      if (groupActiveItems.length) {
        groupedColumns.push({
          name: group.name,
          title: title,
          items: groupActiveItems,
        });
        count++;
      }
      if (group.name === 'risks') {
        groupActiveItems.push(...allColumns['risks']);
        count = count + allColumns['risks'].length;
      }
    });
    initialGroups.forEach((initialGroup) => {
      if (initialGroup !== 'general_columns' && initialGroup !== 'risks') {
        const items = onlyChecked
          ? modelColumns[initialGroup].filter((item) => item.checked)
          : modelColumns[initialGroup];
        const groupsExist = groupedColumns.filter(
          (item) => item.name === initialGroup
        );
        if (items.length) {
          if (!groupsExist.length) {
            groupedColumns.push({
              name: initialGroup,
              title:
                initialGroup === 'udfs'
                  ? 'Custom Asset Attributes'
                  : initialGroup.capitalize(),
              items: items,
            });
            count += items.length + 1;
          } else {
            groupsExist[0].items.push(...items);
          }
        }
      }
    });
    const columns = [];
    for (let i = 0; i < Math.round(groupedColumns.length / 2); i++) {
      const categories = [groupedColumns[2 * i]];
      if (groupedColumns[2 * i + 1]) {
        categories.push(groupedColumns[2 * i + 1]);
      }
      columns.push(categories);
    }
    return columns;
  }
}
