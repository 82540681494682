import { Component, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'swagger-component',
  template:
    '<iframe #iframe style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"></iframe>',
})
export class SwaggerComponent {
  @ViewChild('iframe') iframe: ElementRef;

  constructor(private httpClient: HttpClient) {}

  public ngOnInit(): void {
    this.httpClient
      .get(`${environment.api.base}/swagger`, { responseType: 'text' })
      .subscribe({
        next: (htmlData) => {
          let doc =
            this.iframe.nativeElement.contentDocument ||
            this.iframe.nativeElement.contentWindow;
          doc.open();
          doc.write(htmlData);
          doc.close();
          this.iframe.nativeElement.onload = () => {
            const iframeDocument =
              this.iframe.nativeElement.contentWindow.document;

            const style = document.createElement('style');
            style.type = 'text/css';

            style.innerHTML = `
            .swagger-section #header #logo {
              pointer-events: none;
            }
          `;
            iframeDocument.head.appendChild(style);
          };
        },
        error: (error) => {
          console.error('Error fetching HTML data:', error.error.text);
        },
      });
  }
}
