<div class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header">{{ 'invite_new_user' | translate }}</h2>
  </header>

  <form
    #userForm="ngForm"
    novalidate
    (ngSubmit)="submit()"
    (keydown.enter)="$event.preventDefault()"
  >
    <ul class="modal-content__body modal-content__list">
      <li class="modal-content__item modal-content__item_full-width">
        <label for="email" class="modal-content__label">
          {{ 'email' | translate }}<span class="asterisk">*</span>
        </label>
        <input
          [(ngModel)]="model.email"
          #_email="ngModel"
          required
          [pattern]="regex.email"
          [ngClass]="{ 'form-block_error': hasError(_email) }"
          [customMaxlength]="40"
          class="form-block__element"
          placeholder="Email"
          name="email"
          id="email"
          type="email"
          appTrimOnBlur
        />
        <p class="error" *ngIf="hasError(_email)">
          <ng-container *ngIf="_email.errors.required">
            {{ 'validation.required' | translate }}
          </ng-container>
          <ng-container *ngIf="_email.errors.pattern">
            {{ 'validation.bad_email' | translate }}
          </ng-container>
          <ng-container *ngIf="_email.errors.maxlength">
            {{
              'validation.maxlength_detail'
                | translate: { number: '40', value: 'Email' }
            }}
          </ng-container>
          <ng-container *ngIf="_email.errors.api">
            {{ _email.errors.api }}
          </ng-container>
        </p>
      </li>
      <li class="modal-content__item modal-content__item_full-width">
        <label for="role" class="modal-content__label">Assign Role</label>
        <app-select
          name="role"
          id="role"
          [(ngModel)]="model.role_name"
          optKey="key"
          required
          [showPlaceholder]="false"
          [values]="roles"
          (ngModelChange)="filterOrganizationsByRole()"
        >
        </app-select>
      </li>

      <li
        class="modal-content__item modal-content__item_full-width"
        *ngIf="
          model.role_name === 'tenant_engineer' ||
          model.role_name === 'tenant_sale' ||
          model.role_name === 'partner_admin' ||
          model.role_name === 'partner_engineer'
        "
      >
        <h3 class="modal-content__label">
          {{ getModalContentLabel() | translate
          }}<span class="asterisk">*</span>
        </h3>
        <app-multiselect
          [required]="
            model.role_name === 'tenant_engineer' ||
            model.role_name === 'tenant_sale' ||
            model.role_name === 'partner_admin' ||
            model.role_name === 'partner_engineer'
          "
          [(ngModel)]="model.organization_ids"
          [isDisabled]="false"
          [key]="'id'"
          [as]="'name'"
          [placeholder]="'Select Organizations'"
          [values]="test_organizations"
          name="organizations"
        >
        </app-multiselect>
      </li>
      <!-- SSO login -->
    </ul>

    <div class="buttons-block modal-content__footer">
      <button
        type="button"
        (click)="cancel()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>
      <button class="btn action-btn action-btn_success">
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Invite</span>
      </button>
    </div>
  </form>
</div>
