import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import {
  TemplateModel,
  TemplateTestModel,
  TestOption,
} from '../../../core/models/template.model';

import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComplianceConstants } from '../../../core/constants/compliance.constants';
import { ComplianceService } from '../../../core/services/compliance.service';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';
import { HelperService } from '../../../core/services/helper.service';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  templateUrl: './template-modal.component.html',
})
export class TemplateModalComponent extends BaseModalComponent {
  @ViewChild('templateForm', { static: true }) declare tdForm: NgForm;
  @Input() public model: TemplateModel;
  @Input() public isDublicate: boolean;
  @Input() public isDefault: boolean;
  @Input() public data: any;
  public original_title: string;

  constructor(
    protected modalInstance: BsModalRef,
    protected _complianceService: ComplianceService<TemplateModel>,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(modalInstance, _notificationService, _eventService);
  }

  ngOnInit() {
    this.isTemplateDriven = true;
    this.original_title = this.model.title;
  }

  public checkTitle($el: FormControl) {
    if (this.original_title === this.model.title) {
      this.setError($el, 'title', 'true');
    }
  }

  public isValidForm(): boolean {
    return this.tdForm.valid;
  }

  public submit() {
    this.markFormGroupTouched();
    if (this.tdForm.invalid) {
      return;
    }

    if (this.isDublicate) {
      this.checkTitle(this.tdForm.controls['title'] as FormControl);
      if (!this.isValidForm()) {
        return;
      }
      this.duplicateTemplate();
    } else {
      this.updateDefaultForTemplates(this.data, 0).subscribe({
        next: () => {
          this.onResult.next(true);
          this.modalInstance.hide();
        },
        error: (error) => this._notificationService.apiError(error),
      });
    }
  }

  private duplicateTemplate() {
    if (this.isDefault) {
      this._complianceService
        .copyTemplate(this.model.id, this.model.title)
        .subscribe({
          next: (success) => {
            this.onResult.next(success);
            this.modalInstance.hide();
          },
          error: (error) => this.parseError(error.error),
        });
    } else {
      this._complianceService
        .createDuplicate(this.model, 'templates')
        .subscribe({
          next: (success) => {
            this.onResult.next(success);
            this.modalInstance.hide();
          },
          error: (error) => this.parseError(error.error),
        });
    }
  }

  private updateDefaultForTemplates(
    tempData: Array<any>,
    index: number,
    promise?: Subject<any>
  ): Observable<any> {
    if (!promise) {
      promise = new Subject<any>();
    }
    if (index >= tempData.length) {
      promise.next(true);
      promise.complete();
      return;
    }
    const item = tempData[index];
    let localIndex = index;
    item.template.assets_repository_ids[item.id] = false;
    this._complianceService
      .update(this.parseDataToTemplate(item.template), 'templates')
      .subscribe({
        next: () => {
          this.updateDefaultForTemplates(tempData, ++localIndex, promise);
        },
        error: (error) => {
          promise.error(error);
          promise.complete();
          return;
        },
      });
    return promise;
  }

  private parseError(error) {
    if (error.error) {
      for (const field in error.error) {
        if (error.error.hasOwnProperty(field)) {
          this.setError(
            this.tdForm.controls[field] as FormControl,
            'unique',
            'true'
          );
        }
      }
    } else {
      this.onClose.next(error);
      this.modalInstance.hide();
    }
  }

  private parseDataToTemplate(data) {
    const model = new TemplateModel();
    model.id = data.id;
    model.title = data.title;
    for (const id in data.assets_repository_ids) {
      if (data.assets_repository_ids.hasOwnProperty(id)) {
        model.types.push({
          type: parseInt(id),
          default: data.assets_repository_ids[id],
        });
      }
    }
    data.template.forEach((tests) => {
      const newTest: TemplateTestModel = {};
      let type;
      for (const name in ComplianceConstants.TEST_TYPES) {
        if (ComplianceConstants.TEST_TYPES.hasOwnProperty(name)) {
          const testType = ComplianceConstants.TEST_TYPES[name];

          if (testType.code === tests.type) {
            type = ComplianceConstants.TEST_TYPES[name];
          }
        }
      }
      newTest.type = type;
      newTest.description = tests.description;
      newTest.title = tests.title;
      newTest.options = [];
      if (tests.labels) {
        for (let i = 0; i < tests.labels.length; i++) {
          const option: TestOption = {};
          option.type = `${type.key}_option`;
          option.hint = tests.hints[i];
          option.label = tests.labels[i];
          option.description = tests.description;
          if (tests.validations[i]) {
            if (HelperService.isExist(tests.validations[i].min)) {
              option.min = tests.validations[i].min;
              option.max = tests.validations[i].max;
            } else if (HelperService.isExist(tests.validations[i].required)) {
              option.validation = tests.validations[i].required;
            } else {
              option.validation = null;
            }
          }
          newTest.options.push(option);
        }
      }
      model.tests.push(newTest);
    });
    return model;
  }
}
