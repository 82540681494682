import { Component, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../base-modal.component';
import { NgForm } from '@angular/forms';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';

@Component({
  templateUrl: './password-modal.component.html',
})
export class PasswordModalComponent extends BaseModalComponent {
  @ViewChild('passwordForm', { static: true }) public passwordForm: NgForm;
  @Input() public header: string = `Change password`;
  @Input() public message: string = 'New password';
  @Input() public submitText: string = 'Submit';
  @Input() public cancelText: string = 'Cancel';
  public password: string;

  constructor(
    public activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  public submit(): void {
    this.hasSubmitted = true;
    if (this.passwordForm.invalid) {
      return;
    }
    super.submit();
  }
}
