import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PictureModel } from '../models/picture.model';
import { RequestService } from './request.service';
import { CustomNotificationService } from './custom-notification.service';
import { Observable } from 'rxjs';

@Injectable()
export class ImageResizeService {
  private thumb_size: number = 200;
  private img_size: number = 1200;

  constructor(
    private _requestService: RequestService,
    protected _notificationService: CustomNotificationService
  ) {}
  /**
   * Builds and returns url for resized image
   * *
   * @param {{resource_id : number}} resource_id.
   * @param {{resource : string}} resource type to get image for (assets, organization, etc)
   * @param {size} size of resized image
   * @param {{org_id : string}} org_id - organization id
   * @param {params} params needs to be added for this url
   * @return {url}
   */

  public getResizedImageUrl(
    resource_id: any,
    resource: string,
    size: number,
    org_id?: string,
    params?: any
  ): Observable<string> {
    let url = `${environment.image_resize}/${resource}/${resource_id}`;
    if (!params) {
      params = {};
    }
    params.resize = size;
    if (org_id) {
      params.organization_key = org_id;
    }
    const serializedParams = this.serialize(params);
    url += (url.indexOf('?') === -1 ? '?' : '&') + serializedParams;
    return this._requestService.get(url);
  }

  /**
   * Builds and returns gallery with urls for thumb and img (fullsize)
   *
   * @param {{images : array}} images array for asset with id field
   * @param base_url
   * @param imgSize
   * @return {url}
   */

  public getResizedImages(
    images: Array<PictureModel> = [],
    base_url?: string,
    imgSize: number = this.thumb_size
  ): Array<any> {
    // if exists resized image (property dimensions) - get resized. else (resizing is in progress) - get original
    return images.map((image) => {
      if (!image.dimensions && base_url) {
        image.thumb = base_url + image.url;
        image.img = base_url + image.url;
        return image;
      }
      image.thumb = image.dimensions[imgSize] || image.dimensions['original'];
      image.img =
        image.dimensions[this.img_size] || image.dimensions['original'];
      return image;
    });
  }

  getPopoverImage(e) {
    const $submenuWrapper = e.target.previousElementSibling;
    const menuItemPos = offset(e.target);
    const el = e.target as HTMLElement;
    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight;
    const {
      bottom,
      top,
      left,
      right,
      width: widthEl,
    } = el.getBoundingClientRect();

    if (bottom + $submenuWrapper.clientHeight < height) {
      $submenuWrapper.style.top =
        menuItemPos.top + e.target.offsetWidth + 10 + 'px';
      $submenuWrapper.style.bottom = 'inherit';
      $submenuWrapper.style.left = menuItemPos.left + 'px';
      $submenuWrapper.style.transform = 'translateX(calc(-50% + 20px))';
    } else if (top - $submenuWrapper.clientWidth) {
      $submenuWrapper.style.top = 'inherit';
      $submenuWrapper.style.bottom = height + menuItemPos.top + 10 + 'px';
      $submenuWrapper.style.left = menuItemPos.left + 'px';
      $submenuWrapper.style.transform = 'translateX(calc(-50% + 20px))';
    } else if (right + $submenuWrapper.clientWidth < width) {
      $submenuWrapper.style.top = menuItemPos.top + 'px';
      $submenuWrapper.style.bottom = 'inherit';
      $submenuWrapper.style.left = menuItemPos.left + widthEl + 10 + 'px';
      $submenuWrapper.style.transform = 'translateX(calc(-50% + 20px))';
    } else if (left - $submenuWrapper.clientWidth) {
      $submenuWrapper.style.top = menuItemPos.top + 'px';
      $submenuWrapper.style.bottom = 'inherit';
      $submenuWrapper.style.left =
        menuItemPos.left - $submenuWrapper.clientWidth + 'px';
      $submenuWrapper.style.transform = 'translateX(calc(-50% + 20px))';
    } else {
      $submenuWrapper.style.top = menuItemPos.top + 'px';
      $submenuWrapper.style.bottom = 'inherit';
      $submenuWrapper.style.left = menuItemPos.left + 'px';
      $submenuWrapper.style.transform =
        'translate(calc(-50% + 20px), calc(-50% + 20px))';
    }

    function offset(element) {
      // replacement for jquery function offset() because positioning was changed to fixed
      const rect = element.getBoundingClientRect();
      return {
        top: rect.top,
        left: rect.left,
      };
    }
  }

  private serialize(obj: any): URLSearchParams {
    const params: URLSearchParams = new URLSearchParams();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const element = obj[key];
        params.set(key, element);
      }
    }
    return params;
  }
}
