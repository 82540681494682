import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { RequestService } from './request.service';
import {
  TableauDashboardModel,
  TableauSettingsModel,
} from '../models/tableau-settings.model';
import { Observable } from 'rxjs';
import { PreviewModalComponent } from '../../shared/modals/preview-modal/preview-modal.component';
import { Configs } from '../constants/configs.constants';
import { UserStoreService } from './user.store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from './custom-notification.service';

@Injectable()
export class TableauSettingsService extends BaseStoreService<TableauSettingsModel> {
  protected modalRef: BsModalRef;

  constructor(
    protected _requestService: RequestService,
    protected _userStoreService: UserStoreService,
    protected modalService: BsModalService,
    protected _notificationService: CustomNotificationService
  ) {
    super(_requestService, TableauSettingsModel);
    this.resourceName = '/v1/tableau/settings';
  }

  updateSettings(settings_general: TableauSettingsModel): Observable<any> {
    return this._requestService.put(
      `/${this.resourceName}/general`,
      { settings_general },
      this.headers
    );
  }

  getSettings(): Observable<TableauSettingsModel> {
    return this._requestService.get(`/${this.resourceName}/general`);
  }

  // endregion settings_general

  // region dashboards

  createDashboard(
    settings_dashboard: TableauDashboardModel
  ): Observable<TableauDashboardModel> {
    return this._requestService.post(
      `${this.resourceName}/dashboards`,
      { settings_dashboard },
      this.headers
    );
  }

  updateDashboard(
    dashboard: TableauDashboardModel
  ): Observable<TableauDashboardModel> {
    const url = `${this.resourceName}/dashboards/${dashboard.id}`,
      body = {
        settings_dashboard: dashboard,
      };
    return this._requestService.put(url, body, this.headers);
  }

  deleteDashboard(
    dashboard: TableauDashboardModel
  ): Observable<TableauDashboardModel> {
    const url = `${this.resourceName}/dashboards/${dashboard.id}`,
      body: any = {};
    return this._requestService.delete(url, body, this.headers);
  }

  getUserDashboards(orgId?): Observable<Array<TableauDashboardModel>> {
    const params = {};
    if (orgId) {
      params['organization_id'] = orgId;
    }
    return this._requestService.get(
      '/v1/tableau/dashboards',
      params,
      this.headers
    );
  }

  // endregion dashboards

  getTrustedTicket(dashboard: TableauDashboardModel) {
    this._userStoreService.getTableauTicket().subscribe({
      next: (ticket) => {
        let newUriWithTicket = dashboard.uri;
        if (newUriWithTicket.includes('/views')) {
          newUriWithTicket = newUriWithTicket.replace(
            '/views',
            `/trusted/${ticket}/views`
          );
        }
        this.showPreview(dashboard, newUriWithTicket);
      },
      error: (err) => this._notificationService.apiError(err),
    });
  }

  protected showPreview(
    dashboard: TableauDashboardModel,
    dashboardUri: string
  ) {
    this.modalRef = this.modalService.show(PreviewModalComponent, {
      class: Configs.MODAL_CLASS,
      animated: true,
      initialState: {
        header: dashboard.name,
        uri: dashboardUri,
      },
    });
  }
}
