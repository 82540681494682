import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function dateRangeValidator(cmmsStartDate: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startDate = control.get('project_date_from')?.value;
    const endDate = control.get('project_date_to')?.value;
    const maxYears = 5;
    const maxValidYear = 9999;
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const cmmsStartDateObj = new Date(cmmsStartDate);
    const maxEndDate = new Date(startDate);

    if (!startDate && !endDate) return null;

    // Check for invalid date range: Start date > End date
    if (startDateObj > endDateObj) return { projectDateRange: true };

    // Check for invalid date range: Date range > 9999 years
    if (
      startDateObj.getFullYear() > maxValidYear ||
      endDateObj.getFullYear() > maxValidYear
    ) {
      return { invalidProjectYear: true };
    }

    //Checks if start date is below the CMMS start date
    if (startDateObj < cmmsStartDateObj)
      return { projectStartDateBeyondCmms: true };

    //Check if project range goes beyond 5 year range
    maxEndDate.setFullYear(maxEndDate.getFullYear() + maxYears);
    if (endDate > maxEndDate) return { maxProjectDateRange: true };

    return null;
  };
}
