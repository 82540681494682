import { LocationModel } from './location.model';
import { AddressModel } from './address.model';
import { PictureModel } from './picture.model';
import { DefaultModel } from './default.model';
import { BuildingsDTO } from '../dto/buildings.dto';
import { HelperService } from '../services/helper.service';
import { FacilityCategoryModel } from './facility-category.model';

export class BuildingModel extends DefaultModel<BuildingsDTO> {
  name?: any;
  location?: LocationModel;
  location_id?: number;
  address?: AddressModel;
  id: number;
  square_footage?: number;
  pictures?: Array<PictureModel>;
  pictures_attributes?: Array<PictureModel>;
  built_at?: number;
  assets_count?: number;
  sprinkler_protected_modifier?: number;
  udfs?: any;
  categories?: Array<FacilityCategoryModel>;
  floors_count: number;
  tag_ids: number[];
  total_fte?: number;
  calculated_assets_per_sf?: number;
  assets_replacement_value?: number;
  last_assessment_made_at?: string;
  customAttributes?: any[];
  fte: number;
  total_maintenance_hours?: number;
  total_maintenance_cost?: number;
  primary_picture: PictureModel;
  attachments_attributes: any;
  documents_attributes: any;
  responsible_user_ids?: number[];
  value: number;
  occupancy_coefficients: any;
  value_reset: boolean;
  facility_condition_index: number;

  constructor(model?) {
    super();
    if (model) {
      Object.assign(this, model);
      this.sprinkler_protected_modifier = HelperService.convertFloatToPercent(
        this.sprinkler_protected_modifier
      );
      this.total_maintenance_hours =
        Math.round(this.total_maintenance_hours) || 0;
      this.total_maintenance_cost = HelperService.toFixed(
        this.total_maintenance_cost,
        2
      );
      this.customAttributes = [];
      if (model.categories) {
        model.categories.forEach((category) => {
          const [tagIds, tags] = this.prepareTagsList(category);
          this.customAttributes.push({
            name: category.name,
            id: category.id,
            parent_id: category.parent_id,
            color_scheme: category.color_scheme,
            position: category.position,
            tagIds,
            tags,
          });
        });
      }
    }

    this.address ||= { country: 'US' };
    this.pictures_attributes = [];
    this.udfs ||= {};
  }

  public get entity_name(): string {
    return 'building';
  }

  public dto(): BuildingsDTO {
    return new BuildingsDTO(this);
  }

  public toString(): string {
    return this.name;
  }

  prepareTagsList(category) {
    const tagIds = [];
    const tags = [];
    category.tags.forEach((tag) => {
      tagIds.push(Number(tag.id));
      tags.push({
        colorScheme: category.color_scheme,
        name: tag.name,
      });
    });
    return [tagIds, tags];
  }
}
