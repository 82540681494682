import { Injectable } from '@angular/core';
import { BaseStoreService } from './base-store.service';
import { RequestService } from './request.service';
import { Observable, throwError } from 'rxjs';
import { DefaultListModel } from '../models/default-list.model';
import { BaseModel } from '../models/base.model';
import { HelperService } from './helper.service';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ServiceLogsService<M> extends BaseStoreService<M> {
  public service_type: string;

  constructor(
    protected _requestService: RequestService,
    private _translateService: TranslateService
  ) {
    super(_requestService, BaseModel);
    this.resourceName = 'v1/assets';
    this.service_type = 'assessments';
  }

  public getById(id, resource): Observable<M> {
    return super.getById(
      id,
      `/${this.resourceName}/:id/${this.service_type}/${resource}`
    );
  }

  public getItems(
    params: any,
    resource?: string,
    ...rest: any[]
  ): Observable<DefaultListModel<M>> {
    const assetId = rest[0];
    const url =
      (assetId ? `${this.resourceName}/${assetId}/` : 'v1/') +
      `service_log/${resource}`;
    return super.getItems(params, url);
  }

  public create(model): Observable<M> {
    const url = `/${this.resourceName}/${model.asset_id}/${this.service_type}`,
      data = {};
    data[model.entity_name] = model.dto();
    return this._requestService.post(url, data, this.headers).pipe(
      map((res) => new this.model(res) as M),
      catchError((error) => throwError(() => error))
    );
  }

  public update(model: any): Observable<M> {
    const url = `/${this.resourceName}/${model.asset_id}/${this.service_type}/${model.id}`,
      data = {};
    data[model.entity_name] = model.dto();
    return this._requestService.put(url, data, this.headers).pipe(
      map((res) => new this.model(res) as M),
      catchError((error) => throwError(() => error))
    );
  }

  public delete(entityId, assetId, service_type): Observable<any> {
    const url = `/${this.resourceName}/${assetId}/${service_type}/${entityId}`;
    return this._requestService.delete(url);
  }

  /**
   * Create array with cost fields
   * @param item - service log item
   * @returns {Array}
   */
  public getRepairCost(item) {
    const descr = [];
    descr.push(
      this._translateService.instant('labor_cost') +
        ': $' +
        HelperService.parseMoneyInteger(item.labor_cost)
    );
    descr.push(
      this._translateService.instant('parts_cost') +
        ': $' +
        HelperService.parseMoneyInteger(item.parts_cost)
    );
    return descr;
  }
}
