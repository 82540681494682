import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { AreaServeRisksService } from './area-serve-risks.service';

@Injectable({
  providedIn: 'root',
})
export class AreaServeRisksUniversalService extends AreaServeRisksService {
  constructor(protected _requestService: RequestService) {
    super(_requestService);
    this.resourceName = 'v2/universal/area_served';
  }
}
