<section class="modal-content__wrapper">
  <header>
    <h2 class="modal-content__header">
      {{ isEdit ? "Edit" : "Create" }} {{ model.name }}
    </h2>
  </header>

  <form
    [formGroup]="form"
    (ngSubmit)="saveAction()"
    (keydown.enter)="$event.preventDefault()"
  >
    <ul class="modal-content__body">
      <!-- Name -->
      <li
        class="modal-content__item modal-content__item_full-width flex_nowrap"
      >
        <label for="name" class="xs-24 edit-list__title"
          >Name<span class="asterisk">*</span></label
        >

        <div class="edit-list__description">
          <div class="form-block">
            <input
              formControlName="name"
              id="name"
              type="text"
              class="form-block__element"
              [ngClass]="{ 'form-block_error': hasError('name') }"
              [customMaxlength]="80"
            />

            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('name')"
              [tooltip]="getErrorText('name')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
        </div>
      </li>
      <!--END Name -->

      <!-- Emails -->
      <li
        class="modal-content__item modal-content__item_full-width flex_nowrap"
      >
        <div class="xs-24 edit-list__title edit-list__title_flex">
          <label for="name">Emails<span class="asterisk">*</span></label>
          <button
            type="button"
            [tooltip]="'integration_api_email_tip' | translate"
            placement="right"
            class="tooltip-btn btn btn_iconed"
          >
            <app-svg [href]="'info-circle'" iconClass="icon icon_s"></app-svg>
          </button>
        </div>
        <div class="edit-list__description">
          <ul formArrayName="emails" class="edit-list__description">
            <li
              class="mrgn-b"
              *ngFor="
                let email of getFormArray('emails');
                let $index = index;
                trackBy: trackByIdx
              "
            >
              <div class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap">
                <label class="form-block xs-100">
                  <input
                    [formControlName]="$index"
                    type="email"
                    class="form-block__element"
                    [ngClass]="{
                      'form-block_error': hasError('emails.' + $index)
                    }"
                    appTrimOnBlur
                  />
                  <button
                    type="button"
                    class="btn tooltip-btn_error"
                    *ngIf="hasError('emails.' + $index)"
                    [tooltip]="getErrorText('emails.' + $index)"
                  >
                    <app-svg
                      [href]="'info-circle'"
                      iconClass="icon_s"
                    ></app-svg>
                  </button>
                </label>

                <button
                  type="button"
                  class="btn btn_iconed"
                  *ngIf="getFormArray('emails').length > 1"
                  (click)="removeEmail($index)"
                >
                  <app-svg [href]="'trash'"></app-svg>
                </button>
              </div>
            </li>
          </ul>

          <button
            type="button"
            class="btn-add-attribute btn-add-attribute_blue"
            (click)="addEmail()"
          >
            <app-svg [href]="'plus'"></app-svg>
            <span>Add Email</span>
          </button>
        </div>
      </li>
      <!--END Emails -->

      <!-- Webhook -->
      <li
        class="modal-content__item modal-content__item_full-width flex_nowrap"
      >
        <div class="xs-24 edit-list__title edit-list__title_flex">
          <label for="webhook">Callback</label>
          <button
            type="button"
            [tooltip]="'integration_api_webhook_tip' | translate"
            placement="right"
            class="tooltip-btn btn btn_iconed"
          >
            <app-svg [href]="'info-circle'" iconClass="icon icon_s"></app-svg>
          </button>
        </div>
        <div class="edit-list__description">
          <div class="form-block">
            <input
              formControlName="webhook"
              id="webhook"
              type="text"
              class="form-block__element"
              [ngClass]="{ 'form-block_error': hasError('webhook') }"
            />

            <button
              type="button"
              class="btn tooltip-btn_error"
              *ngIf="hasError('webhook')"
              [tooltip]="getErrorText('webhook')"
            >
              <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
            </button>
          </div>
        </div>
      </li>
      <!--END Webhook -->
      <ng-container
        *ngIf="
          organization.cmms_client_name == 'Asset Essentials' &&
          (model.url || !model.tenant) &&
          this._featureFlagsService.isFeatureEnabled('ae_cmms_changes')
        "
      >
        <li
          class="modal-content__item modal-content__item_full-width flex_nowrap"
        >
          <div class="xs-24 edit-list__title edit-list__title_flex">
            <label for="url">URL<span class="asterisk">*</span></label>
          </div>
          <div class="edit-list__description">
            <div class="form-block">
              <input
                formControlName="url"
                id="url"
                type="text"
                class="form-block__element"
                required
                [ngClass]="{ 'form-block_error': hasError('url') }"
              />
              <button
                type="button"
                class="btn tooltip-btn_error"
                *ngIf="hasError('url')"
                [tooltip]="getErrorText('url')"
              >
                <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
              </button>
            </div>
          </div>
        </li>
        <li
          class="modal-content__item modal-content__item_full-width flex_nowrap"
        >
          <div class="xs-24 edit-list__title edit-list__title_flex">
            <label>Date</label>
            <button
              type="button"
              [tooltip]="'integration_api_date' | translate"
              placement="right"
              class="tooltip-btn btn btn_iconed"
            >
              <app-svg [href]="'info-circle'" iconClass="icon icon_s"></app-svg>
            </button>
          </div>
          <div class="el-in-a-row el-in-a-row_nowrap el-in-a-row_c">
            <mat-form-field
              class="datepicker-block datepicker-split"
              [ngClass]="{ 'form-block_error': hasError('from_date') }"
            >
              <input
                type="text"
                name="fromDate"
                placeholder="From"
                formControlName="from_date"
                matInput
                [max]="'now' | dateFormat: 'ISO'"
                [matDatepicker]="_fromDate"
              />
              <button
                type="button"
                class="btn tooltip-btn_error"
                *ngIf="hasError('from_date')"
                [tooltip]="getErrorText('from_date', 'Start Date')"
              >
                <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
              </button>
              <mat-datepicker-toggle matSuffix [for]="_fromDate">
                <mat-icon matDatepickerToggleIcon>
                  <app-svg [href]="'datepicker-calendar'"></app-svg>
                </mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #_fromDate></mat-datepicker>
            </mat-form-field>
            <span>-</span>
            <mat-form-field
              class="datepicker-block datepicker-split"
              [ngClass]="{ 'form-block_error': hasError('to_date') }"
            >
              <input
                type="text"
                name="toDate"
                placeholder="To"
                formControlName="to_date"
                matInput
                [matDatepicker]="_toDate"
                [min]="minDate"
                [max]="'now' | dateFormat: 'ISO'"
              />
              <button
                type="button"
                class="btn tooltip-btn_error"
                *ngIf="hasError('to_date')"
                [tooltip]="getErrorText('to_date', 'End Date')"
              >
                <app-svg [href]="'info-circle'" iconClass="icon_s"></app-svg>
              </button>
              <mat-datepicker-toggle matSuffix [for]="_toDate">
                <mat-icon matDatepickerToggleIcon>
                  <app-svg [href]="'datepicker-calendar'"></app-svg>
                </mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #_toDate></mat-datepicker>
            </mat-form-field>
          </div>
        </li>
      </ng-container>
    </ul>
    <!--END Smart Form -->
    <div class="buttons-block">
      <button
        type="button"
        (click)="close()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          "buttons.cancel" | translate
        }}</span>
      </button>

      <button class="btn action-btn action-btn_success">
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Submit</span>
      </button>
    </div>
  </form>
</section>
