import { Component, Input, ViewChild } from '@angular/core';

import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { EventService } from 'src/app/core/services/event.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-new-delete-modal',
  templateUrl: './new-delete-modal.component.html',
  styleUrls: ['./new-delete-modal.component.less'],
})
export class NewDeleteModalComponent extends BaseModalComponent {
  @ViewChild('deleteForm', { static: true }) declare tdForm: NgForm;
  @Input() submitText: string = 'Delete';
  @Input() keyword: string = 'delete';
  @Input() withoutBullets: boolean = false;
  userInput: string;

  constructor(
    public activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  public submit(): void {
    this.hasSubmitted = true;
    if (this.tdForm.invalid) {
      return;
    }
    super.submit();
  }
}
