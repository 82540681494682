<section class="modal-content__wrapper">
  <header class="modal-content__header">{{ header }}</header>

  <div class="content-loading row" *ngIf="isLoading">
    <app-loader></app-loader>
  </div>
  <form
    #formulaForm="ngForm"
    novalidate
    [hidden]="isLoading"
    (ngSubmit)="submitForm()"
  >
    <div class="modal-content__body">
      <label for="formula" class="modal-content__label mrgn-t_sm"
        >Value($) or Formula</label
      >

      <div class="form-block mrgn-b">
        <input
          [maxlength]="60"
          required
          placeholder="Enter"
          type="text"
          name="formula"
          id="formula"
          class="form-block__element"
          #_formula="ngModel"
          [pattern]="regex.regexUdfFormula"
          [ngClass]="{ 'form-block_error': hasError(_formula) }"
          [(ngModel)]="formula"
        />
        <p class="error" *ngIf="hasError(_formula)">
          <ng-container *ngIf="_formula.errors.required">
            {{ 'validation.required' | translate }}
          </ng-container>
          <ng-container *ngIf="_formula.errors.maxlength">
            {{ 'validation.maxlength' | translate }}
          </ng-container>
          <ng-container *ngIf="_formula.errors.pattern">
            {{ 'udf_formula_tooltip' | translate }}
          </ng-container>
          <ng-container *ngIf="_formula.errors.api">
            {{ 'organization_formula_tooltip' | translate }}
          </ng-container>
        </p>
      </div>
      <label class="custom-radio modal-content__label mrgn-b_sm">
        <input
          [value]="'all'"
          [(ngModel)]="applyTo"
          class="custom-radio__input"
          name="radio_apply_to_all"
          type="radio"
        />
        <span class="custom-radio__check custom-radio__check_labeled"></span
        >Apply to All Asset Types
      </label>
      <label class="custom-radio modal-content__label mrgn-b_sm">
        <input
          [value]="'empty'"
          [(ngModel)]="applyTo"
          class="custom-radio__input"
          name="radio_apply_to_empty"
          type="radio"
        />
        <span class="custom-radio__check custom-radio__check_labeled"></span
        >Apply to Empty Asset Types
      </label>
    </div>
    <div class="buttons-block modal-content__footer">
      <button
        type="button"
        (click)="cancel()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{ cancelText }}</span>
      </button>
      <button class="btn action-btn action-btn_success">
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">{{ submitText }}</span>
      </button>
    </div>
  </form>
</section>
