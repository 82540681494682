import { Component, Input, ViewEncapsulation } from '@angular/core';

import { BaseChartComponent } from '../base-chart.component';

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ChartBarComponent extends BaseChartComponent {
  @Input() public chartTitle: string;
  @Input() declare randomColors?: boolean;
  @Input() declare filename: string;
  @Input()
  protected set chartValues(value) {
    this._chartValues = value;
    if (this.helper.isExist(value)) {
      this.createCharts();
    }
  }

  protected get chartValues(): any {
    return this._chartValues;
  }

  public maxY: number = 0;
  public randomColorsArray = [
    '#E14E20',
    '#337AB7',
    '#363636',
    '#739E73',
    '#FFB800',
    '#9A0325',
    '#F0A654',
  ];

  ngOnInit() {
    this.canvasId = 'barCanvas';
  }

  private createCharts(): void {
    const labels = this.getLabelsFromDataSets();
    this.setupChartColors(labels);
    this.chart = {
      type: 'bar',
      data: [
        {
          label: this.chartTitle,
          data: this.getValuesFromDataSets(),
          backgroundColor: this.chartColors,
          hoverBackgroundColor: this.chartColors,
          barPercentage: 0.9,
          barThickness: 'flex',
        },
      ],
      labels,
      options: this.setupOptions(),
      plugins: [
        {
          id: 'customChartColorBg',
          beforeDraw: (chart) => {
            const ctx = chart.ctx;
            ctx.save();
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          },
        },
      ],
    };
  }

  protected getValuesFromDataSets() {
    const values = super.getValuesFromDataSets();
    this.maxY = Math.max(...values) + 1;
    return values;
  }

  private setupOptions() {
    return {
      devicePixelRatio: 2,
      aspectRatio: 1.4,
      layout: {
        padding: 10,
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          padding: 10,
          font: {
            color: '#444444',
            weight: 'bolder',
          },
          text: this.chartTitle,
        },
      },
      backgroundColor: 'rgba(255, 255, 255, 1)',
      scales: {
        y: {
          type: 'linear',
          position: 'left',
          suggestedMax: this.maxY,
          beginAtZero: true,
          ticks: {
            text: 'Q-ty',
            precision: 0,
            font: {
              size: this.chartFontSize,
            },
          },
          title: {
            display: true,
            text: 'Q-ty',
            font: {
              size: this.chartFontSize,
            },
          },
        },
        x: {
          ticks: {
            beginAtZero: true,
            font: {
              size: this.chartFontSize,
            },
          },
          title: {
            display: true,
            text: 'Risk Score',
            font: {
              size: this.chartFontSize,
            },
          },
        },
      },
    };
  }

  protected setupChartColors(labels) {
    if (!this.chartColors) {
      super.setupChartColors(labels);
    } else if (this.chartColors.length < this.chartValues.length) {
      this.chartColors = labels.map(
        (_item, index) => this.chartColors[index] || '#337ab7'
      );
    }
  }
}
