<section class="modal-content__wrapper">
  <header class="modal-content__header">{{ header }}</header>
  <ng-container *ngIf="modalType === 'create'; else elseBlock">
    <ul class="modal-content__body modal-content__list">
      <li
        class="modal-content__item modal-content__item_full-width modal-content__item_last-in-row"
      >
        <label
          class="custom-radio modal-content__label mrgn-b_sm"
          [ngClass]="{ disabled: !canCreateSystemFtp }"
        >
          <input
            [value]="true"
            [(ngModel)]="systemFtp"
            class="custom-radio__input"
            name="system"
            [disabled]="!canCreateSystemFtp"
            type="radio"
          />
          <span class="custom-radio__check custom-radio__check_labeled"></span
          >Create a new Origin SFTP (recommended)
        </label>
      </li>
      <li
        class="modal-content__item modal-content__item_full-width modal-content__item_last-in-row"
      >
        <label class="custom-radio modal-content__label mrgn-b_sm">
          <input
            [value]="false"
            [(ngModel)]="systemFtp"
            class="custom-radio__input"
            name="custom"
            type="radio"
          />
          <span class="custom-radio__check custom-radio__check_labeled"></span
          >Set up an own (S)FTP server
        </label>
      </li>
    </ul>
    <footer class="buttons-block modal-content__footer">
      <button
        type="button"
        (click)="cancel()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>
      <button
        type="button"
        (click)="setType()"
        class="btn action-btn action-btn_success"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Create</span>
      </button>
    </footer>
  </ng-container>
  <ng-template #elseBlock>
    <ul class="modal-content__body modal-content__list">
      <ng-container *ngFor="let item of ftpCreds">
        <li class="modal-content__item">{{ item | translate }}:</li>
        <li
          class="modal-content__item modal-content__item_two-third-width modal-content__item_last-in-row text-break-words"
        >
          <app-password-show
            *ngIf="item === 'password'"
            [password]="model.password"
          ></app-password-show>
          <ng-container *ngIf="item !== 'password'">{{
            model[item]
          }}</ng-container>
        </li>
      </ng-container>
    </ul>

    <footer class="buttons-block modal-content__footer">
      <button
        type="button"
        (click)="cancel()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Close</span>
      </button>
      <button
        type="button"
        (click)="saveAsTxt()"
        class="btn action-btn action-btn_success"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'download'" iconClass="icon icon_s"></app-svg
        ></span>
        <span class="action-btn__title">Save as TXT</span>
      </button>
    </footer>
  </ng-template>
</section>
