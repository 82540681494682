import { DefaultDTO } from './default.dto';
import { RiskModel } from '../models/risk.model';
import { AssetTypeRiskModel } from '../models/asset-type-risk.model';
import { FrequencyBreakdownModel } from '../models/frequency-breakdown.model';

export class AssetTypeExtendedDTO extends DefaultDTO {
  aha_ashrae_life: number;
  asset_type: string;
  asset_type_risks_attributes: Array<AssetTypeRiskModel>;
  classification_id: number;
  compliance: boolean;
  cost: string;
  created_at: string;
  risks: RiskModel[];
  experiential_cost_estimate: string;
  experiential_life: number;
  experiential_pm_frequency: string;
  funding_source_id?: number;
  group_id: number;
  id: number;
  max_quantity: string;
  min_quantity: string;
  project_udfs?: any;
  pm_frequency_breakdown: FrequencyBreakdownModel;
  recommended_pm_frequency: string;
  rs_means_cost_estimate: string;
  rs_means_life: number;
  rs_means_pm_frequency: string;
  specialty_name: string;
  subgroup_id: number;
  subgroup_name: string;
  total_risk_rank: number;
  trade_name: string;
  tier?: number;
  units: string;
  updated_at: string;
  vendor_cost_estimate?: string;
  warnings?: Array<any>;

  constructor(model) {
    super();
    Object.assign(this, model);
    this.subgroup_id = model.subgroup.id;
    this.group_id = model.group.id;
    this.tier = model.tier?.id;
    this.specialty_name = model.specialty?.name;
    this.funding_source_id = model.funding_source?.id;
    this.classification_id = model.classification?.id;
    this.trade_name = model.trade?.name;
    this.asset_type_risks_attributes =
      this.asset_type_risks_attributes ?? this.risks;
    delete this.risks;
  }
}
