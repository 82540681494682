<ul class="pagination">
  <li (click)="prevPage()" class="pagination__el previous">
    <button
      type="button"
      class="pagination__btn"
      tabindex="0"
      [disabled]="!hasPrevPage"
    >
      Prev
    </button>
  </li>

  <li class="pagination__el" *ngIf="hasLeftDots">
    <button (click)="setPage(1)" type="button" class="pagination__btn">
      1
    </button>
  </li>

  <li class="pagination__el" *ngIf="hasLeftDots">
    <button type="button" class="pagination__btn" disabled>…</button>
  </li>

  <li
    *ngFor="let i of pages"
    [ngClass]="{ pagination__el_active: i === page }"
    class="pagination__el"
  >
    <button (click)="setPage(i)" type="button" class="pagination__btn">
      {{ i }}
    </button>
  </li>

  <li class="pagination__el" *ngIf="hasRightDots">
    <button type="button" class="pagination__btn" disabled>…</button>
  </li>

  <li class="pagination__el" *ngIf="hasRightDots">
    <button (click)="setPage(totalPages)" type="button" class="pagination__btn">
      {{ totalPages }}
    </button>
  </li>

  <li (click)="nextPage()" class="pagination__el next">
    <button
      type="button"
      tabindex="0"
      class="pagination__btn"
      [disabled]="!hasNextPage"
    >
      Next
    </button>
  </li>
</ul>
