import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import { WebsocketsService } from './websockets.service';
import {
  IWebsocketEventModel,
  IWebsocketMessageModel,
} from '../../models/websocket-event.model';

@Injectable()
export class ChatService {
  private messages: Observable<IWebsocketEventModel>;
  private messageSubject = new Subject<IWebsocketMessageModel>();
  private lastEvent: IWebsocketMessageModel;
  constructor(private wsService: WebsocketsService) {
    let lastEventJson = localStorage.getItem('lastWebSocketEvent');
    this.lastEvent = lastEventJson ? JSON.parse(lastEventJson) : null;
  }

  public messages$(event: string): Observable<IWebsocketMessageModel> {
    if (!this.messages) {
      this.connect();
    }

    return this.messages.pipe(
      filter((message) => message.message?.name === event),
      map((message) => message.message),
      finalize(() => this.disconnect())
    );
  }

  sendMessage(message: any): void {
    this.wsService.sendMessage(message);
  }

  connect(): void {
    this.messages = this.wsService.connect();
  }

  disconnect(): void {
    this.wsService.disconnect();
    this.messages = null;
  }

  public hasSuccessStatus(res: IWebsocketMessageModel): boolean {
    return res.status === 30 && res.success;
  }

  public hasErrorStatus(res: IWebsocketMessageModel): boolean {
    return !res.success && res.status === 30;
  }

  public hasInProgressStatus(res: IWebsocketMessageModel): boolean {
    return !res.success && res.status === 20;
  }

  public isCancellationStatus(res: IWebsocketMessageModel): boolean {
    return (
      (res.status_name === 'canceling' && res.status === 40) ||
      (res.status === 50 && res.success && res.status_name === 'canceled')
    );
  }

  public getMessageSubject(): Observable<IWebsocketMessageModel> {
    return this.messageSubject.asObservable();
  }

  public sendMessageToAppComponent(message: IWebsocketMessageModel) {
    this.messageSubject.next(message);
  }

  // Save the last WebSocket event to localStorage
  saveLastEvent(event: IWebsocketMessageModel) {
    this.lastEvent = event;
    localStorage.setItem('lastWebSocketEvent', JSON.stringify(event));
  }

  // Get the last WebSocket event
  getLastEvent(): IWebsocketMessageModel {
    return this.lastEvent;
  }
}
