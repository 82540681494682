import { Component } from '@angular/core';
import { Page404Component } from '../page-404/page-404.component';

@Component({
  templateUrl: '../page-404/page-404.component.html',
})
export class Page403Component extends Page404Component {
  public title: string = '403';
  public text: string = `You don't have permission to access this page`;
}
