export {};

declare global {
  // this is important to access it as global type String
  interface String {
    capitalize(this: string): string;
  }
}

String.prototype.capitalize = function (this: string) {
  if (!this || typeof this !== 'string') {
    return;
  }

  return this[0].toUpperCase() + this.slice(1);
};
