<section class="modal-content__wrapper">
  <header class="modal-content__header">Capital Improvement Investment</header>

  <fieldset class="modal-content__body text-center" *ngIf="isLoading">
    <app-loader></app-loader>
    <span class="sr-only">Loading...</span>
  </fieldset>

  <form #modalForm="ngForm" (ngSubmit)="saveAction()" appInvalidControlScroll>
    <ul class="modal-content__body modal-content__list" *ngIf="model">
      <li class="modal-content__item">
        <label for="date" class="modal-content__label">Date</label>
        <mat-form-field
          class="datepicker-block datepicker-block__display-block"
          [ngClass]="{ 'form-block_error': hasError(_date) }"
        >
          <input
            type="text"
            name="date"
            id="date"
            placeholder="Select date"
            matInput
            (keydown.enter)="$event.target.blur()"
            #_date="ngModel"
            [disabled]="isLoading"
            [matDatepicker]="date"
            [(ngModel)]="model.investment_date"
            [min]="asset.installed_on"
            [max]="maxDate"
            required
          />
          <mat-datepicker-toggle matSuffix [for]="date">
            <mat-icon matDatepickerToggleIcon>
              <app-svg [href]="'datepicker-calendar'"></app-svg>
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker
            #date
            [startAt]="model.investment_date"
          ></mat-datepicker>
        </mat-form-field>
        <p class="error" *ngIf="hasError(_date)">
          <ng-container *ngIf="_date.errors.required">
            {{ 'validation.required' | translate }}
          </ng-container>
          <ng-container *ngIf="_date.errors.matDatepickerMax">
            {{ 'validation.max_date' | translate }}
          </ng-container>
          <ng-container *ngIf="_date.errors.matDatepickerParse">
            {{ 'validation.bad_date' | translate }}
          </ng-container>
        </p>
      </li>

      <li class="modal-content__item">
        <label class="modal-content__label">Investment Amount</label>
        <ng-container *ngIf="ciiMode === 'dollars'">
          <input
            [disabled]="isLoading"
            [(ngModel)]="model.investment_amount"
            (change)="updateAmountPercentCorrelation('dollars')"
            #_amount="ngModel"
            name="investment_amount"
            required
            prefix="$"
            placeholder="$"
            mask="separator.0"
            thousandSeparator=","
            [pattern]="regex.floatingNumbersNoZero"
            class="form-block__element form-block__element_with-batch custom-input-p-width"
            [customMax]="customMax"
            [customMin]="0.01"
            [ngClass]="{ 'form-block_error': hasError(_amount) }"
            #dollarData
          />
          <p class="error custom-input-p-width" *ngIf="hasError(_amount)">
            <ng-container *ngIf="dollarData.value === '$'">
              {{ 'validation.required' | translate }}
            </ng-container>
            <ng-container
              *ngIf="_amount.errors.pattern && !_amount.errors.customMin"
            >
              {{ 'validation.number' | translate }}
            </ng-container>
            <ng-container
              *ngIf="dollarData.value !== '$' && _amount.errors.customMin"
            >
              {{ 'validation.investment_min' | translate }}
            </ng-container>
            <ng-container
              *ngIf="_amount.errors.customMax || _amount.errors.api"
            >
              {{
                'validation.investment_max'
                  | translate: { number: customMaxToString }
              }}
            </ng-container>
          </p>
        </ng-container>
        <ng-container *ngIf="ciiMode === 'percent'">
          <input
            [disabled]="isLoading"
            [ngModel]="model.investment_percent | percent"
            (change)="updateAmountPercentCorrelation('percent')"
            (ngModelChange)="model.investment_percent = $event / 100"
            #_amount="ngModel"
            name="investment_percent"
            required
            mask="percent"
            suffix="%"
            placeholder="%"
            class="form-block__element form-block__element_with-batch custom-input-p-width"
            [customMax]="100"
            [customMin]="0.01"
            [ngClass]="{ 'form-block_error': hasError(_amount) }"
          />
          <p class="error custom-input-p-width" *ngIf="hasError(_amount)">
            <ng-container *ngIf="_amount.errors.required">
              {{ 'validation.required' | translate }}
            </ng-container>
            <ng-container
              *ngIf="_amount.errors.pattern && !_amount.errors.customMin"
            >
              {{ 'validation.number' | translate }}
            </ng-container>
            <ng-container *ngIf="_amount.errors.customMin">
              {{ 'validation.investment_min' | translate }}
            </ng-container>
            <ng-container
              *ngIf="_amount.errors.customMax || _amount.errors.api"
            >
              {{
                'validation.investment_max'
                  | translate: { number: customMaxToString }
              }}
            </ng-container>
          </p>
        </ng-container>
      </li>
      <li class="modal-content__item modal-content__item_last-in-row">
        <app-select
          class="position-relative d-block"
          widthClass="multiselect_batch"
          [(ngModel)]="ciiMode"
          [keyAsValue]="true"
          [showPlaceholder]="false"
          [values]="['dollars', 'percent']"
        >
        </app-select>
      </li>
      <li
        class="modal-content__item modal-content__item_two-third-width"
        *ngIf="this.isCreate"
      >
        <label class="modal-content__label"
          >FHIE Score<span *ngIf="model.score === null" class="asterisk"
            >*</span
          ></label
        >
        <div class="stars-block">
          <app-star-rating
            name="score"
            required
            [hintVisible]="true"
            [(ngModel)]="model.score"
            #_score="ngModel"
          >
          </app-star-rating>
        </div>
        <p class="error" *ngIf="hasError(_score) && _score.errors.required">
          {{ 'validation.required' | translate }}
        </p>
      </li>

      <li class="xs-100 modal-content__item modal-content__item_wide">
        <label class="modal-content__label">Comment</label>
        <textarea
          [disabled]="isLoading"
          rows="8"
          [(ngModel)]="model.comment"
          #_comment="ngModel"
          name="comment"
          id="comment"
          class="form-block__element"
          placeholder="Note"
          [ngClass]="{ 'form-block_error': hasError(_comment) }"
          [customMaxlength]="500"
        ></textarea>

        <p
          class="error"
          *ngIf="hasError(_comment) && _comment.errors.maxlength"
        >
          {{
            'validation.maxlength_detail'
              | translate: { value: 'Comment', number: '500' }
          }}
        </p>
      </li>
    </ul>

    <!-- Buttons -->
    <footer class="buttons-block modal-content__footer">
      <button
        type="button"
        [disabled]="isSavingModel"
        (click)="cancel()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{
          'buttons.cancel' | translate
        }}</span>
      </button>

      <button
        [disabled]="isSavingModel"
        class="btn action-btn action-btn_success"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'check'"></app-svg
        ></span>
        <span class="action-btn__title">Submit</span>
      </button>
    </footer>
  </form>
</section>
