import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';

import { AssetFieldsConstants } from '../../../../core/constants/asset_fields.constants';
import { BaseEditComponent } from '../../../../shared/components/base-edit.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Configs } from '../../../../core/constants/configs.constants';
import { CustomNotificationService } from '../../../../core/services/custom-notification.service';
import { ErrorService } from '../../../../core/services/error.service';
import { EventService } from '../../../../core/services/event.service';
import { FileImportModel } from '../../../../core/models/file-import.model';
import { FileImportService } from '../../../../core/services/file-import.service';
import { FtpCreateModalComponent } from '../ftp-create-modal/ftp-create-modal.component';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { NgForm } from '@angular/forms';
import { OrganizationCurrentService } from '../../../../core/services/organization-current.service';
import { OrganizationModel } from '../../../../core/models/organization.model';
import { SelectedGroupItem } from '../../../../core/models/export-params.model';

@Component({
  templateUrl: './file-import-edit.component.html',
})
export class FileImportEditComponent extends BaseEditComponent<FileImportModel> {
  @ViewChild('fileImportForm', { static: true }) declare tdForm: NgForm;
  public configs = Configs;
  public organization: OrganizationModel;
  public passwordState: boolean;
  public changePasswordState: boolean = false;
  public resetPasswordApplied: boolean;
  public showPrivateKey: boolean;
  public isPrivateKeyExist: boolean;
  public columns: Array<any> = [];
  public protocolOptions = [
    { id: 'sftp', name: 'SFTP' },
    { id: 'ftp', name: 'FTP' },
    { id: 'ftps', name: 'FTPS' },
  ];
  public selectedGroups: SelectedGroupItem = {
    all: false,
    risks: false,
    udfs: false,
  };

  get isSystemFtp(): boolean {
    return !!this.model.system_ftp;
  }

  constructor(
    protected _fileImportService: FileImportService<FileImportModel>,
    protected _notificationService: CustomNotificationService,
    protected _organizationCurrent: OrganizationCurrentService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected _eventService: EventService,
    protected modalService: BsModalService,
    protected _errorService: ErrorService,
    protected _navigationService?: NavigationService
  ) {
    super(
      _notificationService,
      _fileImportService,
      router,
      activeRoute,
      'ftp_id',
      _eventService,
      _errorService
    );
  }

  ngOnInit() {
    this.organization = this._organizationCurrent.organization;
    this.isTemplateDriven = true;
    this.isLoading = true;
    this.loadModel().subscribe({
      next: () => {
        const systemFtp =
          this.activeRoute.snapshot.paramMap.get('systemFtp') == 'true';
        if (this.isCreate) {
          this.model.system_ftp = systemFtp;
          if (systemFtp) {
            this.model.paths = {
              errors: '/errors',
              files: '/files',
              processed: '/processed',
              new_assets: '/new_assets',
            };
          }
        }
        this.isLoading = false;
        this.isPrivateKeyExist = !!this.model.ssh_key;
        this.model.ssh_key = null;
        this.breadcrumbs = [
          {
            name: 'Settings',
          },
          {
            name: 'CMMS Integration',
            link: this.setPageInBreadcrumbs([
              '/',
              this.organization?.id_hash,
              'settings',
              'integrations',
              'ftp',
            ]),
          },
          {
            name: this.isCreate ? 'New' : this.model.name,
          },
        ];
        this._navigationService?.currentBreadcrumb(this.breadcrumbs);
        this.prepareColumnsList();
        this.checkGroupSelectors();
      },
      error: (err) => this._notificationService.apiError(err),
    });
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public removeEmail($index: number): void {
    this.model.emails.splice($index, 1);
  }

  public addEmail(): void {
    this.model.emails.push('');
  }

  public togglePasswordState(): void {
    this.passwordState = !this.passwordState;
  }

  public editPrivateKey() {
    this.showPrivateKey = !this.showPrivateKey;
    this.model.ssh_key = '';
  }

  public setType(): void {
    if (this.model.system_ftp) {
      this.model.paths = {
        errors: '/errors',
        files: '/files',
        processed: '/processed',
        new_assets: '/new_assets',
      };
    }
  }

  public resetPassword(): void {
    this.markFormGroupTouched();
    if (this.tdForm.invalid) {
      return;
    }
    this.model.reset_password = true;
    this.model.password = null;
    this.resetPasswordApplied = true;
  }

  public cancelEdit(): void {
    this.router.navigate([
      `/${this.organization?.id_hash}/settings/integrations/ftp/`,
    ]);
  }

  public afterSaveAction(model: FileImportModel): void {
    // saving to store service to avoid GET request from details component
    this.store.currentModel = model;

    // redirect
    this.router
      .navigate([
        '/',
        this.organization?.id_hash,
        'settings',
        'integrations',
        'ftp',
      ])
      .then(() => {
        if (this.isSystemFtp && this.resetPasswordApplied) {
          this.showPassword(model);
        }
      });
  }

  public selectAll(event): void {
    this.selectedGroups.all = event;
    this.selectAllItems(this.selectedGroups.all);
  }

  public selectGroup(event, group: string): void {
    this.selectedGroups[group] = event;
    if (event) {
      this.model.csv_columns_list.assets.selected_groups.push(group);
    } else {
      this.model.csv_columns_list.assets.selected_groups =
        this.model.csv_columns_list.assets.selected_groups.filter(
          (item) => item !== group
        );
    }
  }

  public checkItem(event, item, group: string = 'general_columns'): void {
    const index = this.model.csv_columns_list.assets[group].findIndex(
      (modelItem) => modelItem.title === item.title
    );
    this.model.csv_columns_list.assets[group][index].checked = event;
    this.checkIfAllSelected();
  }

  private prepareColumnsList(): void {
    this.columns = this._fileImportService.prepareColumnsList(
      this.model.csv_columns_list.assets
    );
  }

  private selectAllItems(shouldCheck: boolean): void {
    for (const assetsKey in this.model.csv_columns_list.assets
      .general_columns) {
      this.model.csv_columns_list.assets.general_columns[assetsKey].checked =
        shouldCheck;
    }
    for (const assetsKey in this.model.csv_columns_list.assets.risks) {
      this.model.csv_columns_list.assets.risks[assetsKey].checked = shouldCheck;
    }
    for (const assetsKey in this.model.csv_columns_list.assets.udfs) {
      this.model.csv_columns_list.assets.udfs[assetsKey].checked = shouldCheck;
    }
    this.prepareColumnsList();
  }

  private showPassword(model: any): void {
    this.modalRef = this.modalService.show(FtpCreateModalComponent, {
      class: 'modal-holder',
      animated: true,
      initialState: {
        modalType: 'password',
        model,
      },
    });
    this.subscriptions.add(
      this.modalRef.content.onClose.subscribe(() => {
        this.router.navigate([
          '/',
          this.organization?.id_hash,
          'settings',
          'integrations',
          'ftp',
          'new',
        ]);
      })
    );
  }

  private checkIfAllSelected(): void {
    let counter = 0,
      selected = 0;
    AssetFieldsConstants.FIELD_GROUPS.forEach((group) => {
      this.model.csv_columns_list.assets[group].forEach((col) => {
        counter++;
        col.checked && selected++;
      });
    });
    this.selectedGroups.all = counter === selected;
  }

  private checkGroupSelectors(): void {
    this.selectedGroups.udfs =
      !!this.model.csv_columns_list.assets.selected_groups.filter(
        (item) => item === 'udfs'
      ).length;
    this.selectedGroups.risks =
      !!this.model.csv_columns_list.assets.selected_groups.filter(
        (item) => item === 'risks'
      ).length;
    this.checkIfAllSelected();
  }
}
