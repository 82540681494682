import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EventService } from '../../../core/services/event.service';
import { Configs } from '../../../core/constants/configs.constants';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './o-gallery.component.html',
  styleUrls: ['./o-gallery.component.less'],
})
export class OGalleryComponent implements OnInit {
  @Input() public images: Array<any> = [];
  @Input() public hideOverflow: boolean;
  @Input() public isInline: boolean;
  @Input() public baseClass: string;
  @Input() public thumbClass: string;
  @Input() public thumbsNum: number;
  @Input() public withZoom: boolean = true;
  public index: number = 0;
  public thumbs_width: number = 0;
  public isOpened: boolean;
  public isLoading: boolean;
  public image;
  public description: string;

  private defaults = {
    baseClass: 'ng-gallery',
    thumbClass: 'ng-thumb',
    thumbsNum: 3,
  };

  private keys_codes = {
    enter: 13,
    esc: 27,
    left: 37,
    right: 39,
  };

  get helper() {
    return HelperService;
  }

  constructor(private _eventService: EventService) {}

  ngOnInit() {
    this.setScopeValues();
    if (this.thumbsNum >= 11) {
      this.thumbsNum = 11;
    }
    this._eventService.on(Configs.EVENTS.GALLERY_OPEN, (args) => {
      this.openGallery(args.index);
    });
  }

  @HostListener('keydown') onKeyDown(event) {
    if (!this.isOpened) {
      return;
    }
    const which = event.which;
    if (which === this.keys_codes.esc) {
      this.closeGallery();
    } else if (
      which === this.keys_codes.right ||
      which === this.keys_codes.enter
    ) {
      this.nextImage();
    } else if (which === this.keys_codes.left) {
      this.prevImage();
    }
  }

  private loadImage(i): Observable<any> {
    return new Observable<any>((observe) => {
      const image = new Image();
      image.onload = () => {
        this.isLoading = false;
        observe.next(image);
        observe.complete();
      };
      image.onerror = function () {
        observe.error();
        observe.complete();
      };
      image.src = this.images[i].img;
      this.isLoading = true;
    });
  }

  private showImage(i: number) {
    this.loadImage(this.index).subscribe((resp) => {
      this.image = resp.src;
      this.smartScroll(this.index);
    });
    this.description = this.images[i].description || '';
  }

  showImageDownloadButton(): boolean {
    if (!this.images[this.index] || !this.images[this.index].downloadSrc) {
      return false;
    }
    const image = this.images[this.index];
    return image.downloadSrc?.length;
  }

  getImageDownloadSrc() {
    if (!this.images[this.index] || !this.images[this.index].downloadSrc) {
      return;
    }
    return this.images[this.index].downloadSrc;
  }

  changeImage(i) {
    this.index = i;
    this.showImage(i);
  }

  public nextImage() {
    this.index += 1;
    if (this.index === this.images.length) {
      this.index = 0;
    }
    this.showImage(this.index);
  }

  public prevImage() {
    this.index -= 1;
    if (this.index < 0) {
      this.index = this.images.length - 1;
    }
    this.showImage(this.index);
  }

  public openGallery(index?) {
    this.thumbs_width = 0;
    if (index !== null) {
      this.index = index;
      this.showImage(this.index);
    }
    this.isOpened = true;
    document.body.classList.add('modal-open-fix');

    setTimeout(() => {
      this.setThumbsWidth();
      this.smartScroll(this.index);
    });
  }

  public closeGallery() {
    this.isOpened = false;
    document.body.classList.remove('modal-open-fix');
    if (this.hideOverflow) {
      document.body.style.overflow = '';
    }
  }

  private setThumbsWidth() {
    document
      .querySelectorAll('.ng-gallery__thumbnail-img')
      .forEach((thumb: HTMLImageElement, _index) => {
        const img = new window.Image();
        img.src = thumb.src;
        img.onload = () =>
          (this.thumbs_width += (Number(thumb.clientWidth) || 0) + 10);
      });
  }

  private smartScroll(index) {
    setTimeout(() => {
      const len = this.images.length,
        width = this.thumbs_width,
        item_scroll = parseInt((width / len).toString(), 10),
        i = index + 1,
        s = Math.ceil(len / i);

      const thumbwrapper = document.getElementsByClassName(
        'ng-gallery__thumbnails-wrapper'
      );
      thumbwrapper[0].scrollLeft = i * item_scroll - s * item_scroll;
    }, 100);
  }

  private setScopeValues() {
    this.baseClass = this.baseClass || this.defaults.baseClass;
    this.thumbClass = this.thumbClass || this.defaults.thumbClass;
    this.thumbsNum = this.thumbsNum || 3; // should be odd
  }
}
