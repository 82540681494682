import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UserModel } from '../../../core/models/user.model';
import { UserStoreService } from '../../../core/services/user.store';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { RequestService } from '../../../core/services/request.service';
import { FilterService } from '../../../core/services/filter.service';
import { SearchService } from '../../../core/services/search.service';
import { UserCurrentService } from '../../../core/services/user-current.service';
import { Roles } from '../../../core/constants/roles.constant';
import { Configs } from '../../../core/constants/configs.constants';
import { ImageResizeService } from '../../../core/services/image-resize.service';
import { BaseListComponent } from '../base-list.component';
import { Router } from '@angular/router';
import {
  PathConstants,
  RoutesConstants,
} from '../../../core/constants/routes.constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NavigationService } from '../../../core/services/navigation.service';
import { EventService } from '../../../core/services/event.service';
import { AuthService } from '../../../core/services/auth.service';
import { Store } from '@ngxs/store';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './default-user-list.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class BaseUsersListComponent
  extends BaseListComponent<UserModel>
  implements OnInit
{
  @Input() public canInvite: boolean;
  @Input() public title: string = 'Users';
  @Input() public isTenant: boolean;
  @Input() public org_id: string;
  @Input() public shouldPassPageNumber: boolean = true;
  @Input() public independentComponent: boolean = true;

  public get roles() {
    return Roles;
  }

  constructor(
    protected _userStore: UserStoreService,
    protected _userCurrent: UserCurrentService,
    protected _notificationService: CustomNotificationService,
    protected _requestService: RequestService,
    protected _filterService: FilterService,
    protected _searchService: SearchService,
    protected _imageResizingService: ImageResizeService,
    protected modalService: BsModalService,
    protected router: Router,
    protected _navigationService: NavigationService,
    protected _eventService: EventService,
    protected _authService: AuthService,
    protected appStore: Store
  ) {
    super(
      _requestService,
      _filterService,
      _notificationService,
      _searchService,
      _userStore,
      _navigationService,
      appStore
    );
  }

  public ngOnInit() {
    this.listId = 'base-user';
    this.keepKeywordAtPaths = this.isTenant
      ? [PathConstants.TENANT_USERS.LIST]
      : [PathConstants.USERS];
    this.setupComponent();
  }

  /**
   * Opens cancel invite popup for user in pending
   * @param value
   */
  public resetInvitation(value): void {
    this.modalRef = this.modalService.show(ConfirmationModalComponent, {
      class: Configs.MODAL_CLASS,
      animated: true,
      initialState: {
        header: 'Warning',
        message: `Invitation for email <b>${value.email}</b> will be revoked.`,
        submitText: 'Confirm',
        submitClass: 'danger',
      },
    });
    this.subscriptions.add(
      this.modalRef.content.onResult.subscribe(() => {
        this._userStore.resetInvitation(value.id).subscribe({
          next: () => {
            this._notificationService.success(
              `Invitation for email ${value.email} has been cancelled`
            );
            this.loadList();
          },
          error: (err) => this._notificationService.apiError(err),
        });
      })
    );
  }

  /**
   * Re-sends the invitation for user
   * @param user - user model
   */
  public resendInvitation(user: UserModel) {
    if (user.isResendingInvitation) {
      return;
    }
    user.isResendingInvitation = true;
    this.reSendInvite(user);
  }
  /**
   * Re-sends invite for user
   * @param user
   */
  public reSendInvite(user: UserModel) {
    this._userStore.reInvite(user.id, user.is_tenant).subscribe({
      next: () => {
        user.isResendingInvitation = false;
        this._notificationService.success(
          `User ${user.email} has been re-invited`
        );
        this.loadList();
      },
      error: (err) => {
        user.isResendingInvitation = false;
        this._notificationService.apiError(err);
      },
    });
  }

  getItems() {
    // to be done refactor it
    if (this.isTenant) {
      return this._userStore.getItems(this.requestParams());
    }
    return this._userStore.getUsersByOrganization(
      this.requestParams(),
      this.org_id
    );
  }

  public changeDetailsMode(id): string {
    if (this._userCurrent.user?.id === id) {
      return this.isTenant ? `/profile` : `/${this.org_id}/profile`;
    }

    return this.isTenant
      ? RoutesConstants.TENANT_USERS.DETAILS(id)
      : RoutesConstants.USERS.DETAILS(id, this.org_id);
  }

  protected setupComponent(): void {
    this._eventService.on(Configs.EVENTS.USER_CREATED, () => {
      this.loadList();
    });
    super.ngOnInit();
    this.loadList();
  }
}
