import { Input, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseFormComponent } from '../components/base-form.component';
import { CustomNotificationService } from '../../core/services/custom-notification.service';
import { EventService } from '../../core/services/event.service';
import { FormControl, NgControl } from '@angular/forms';

@Injectable()
export class BaseModalComponent extends BaseFormComponent {
  @Input() public header: string = `Are you sure?`;
  @Input() message: string = '';
  @Input() messagesArr: string[] = [];
  @Input() fieldList: string[];
  @Input() submitText: string = 'Submit';
  @Input() submitIcon: string = 'check';
  @Input() submitClass: string = 'success';
  @Input() cancelText: string = 'Cancel';
  @Input() cancelIcon: string = 'times';
  @Input() cancelClass: string = 'default';
  @Input() hideCancel: boolean = false;
  @Input() resetText: string = 'Reset';
  @Input() resetClass: string = 'default';
  @Input() hideReset: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() hideSubmit: boolean = false;

  @Input() public onResult: Subject<any> = new Subject<any>();
  @Input() public onClose: Subject<any> = new Subject<any>();
  @Input() public onReset: Subject<any> = new Subject<any>();

  constructor(
    protected activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(_notificationService, _eventService);
  }

  // to be done needs to extend parent method
  hasError($el: FormControl | NgControl): boolean {
    this.hasSubmitted = this.form?.submitted || this.hasSubmitted;
    return ($el?.touched || this.hasSubmitted) && $el?.invalid;
  }

  submit(result?: any): void {
    this.onResult.next(result);
    this.activeModal.hide();
  }

  cancel(): void {
    this.onClose.next('cancel');
    this.activeModal.hide();
  }

  reset(): void {
    this.onReset.next('reset');
    this.activeModal.hide();
  }
}
