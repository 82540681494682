import { Component, Input } from '@angular/core';
import { OrganizationModel } from '../../../core/models/organization.model';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { FHIModulesService } from '../../../core/services/fhi-modules.service';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '../../../core/services/event.service';
import { Configs } from '../../../core/constants/configs.constants';
import { AssetModel } from '../../../core/models/asset.model';
import { FHIHistoryPeriod, ModulesEnum } from '../../../core/constants/enums';
import { FHIScoreModel } from '../../../core/models/fhi-score.model';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-fhi-indexes',
  templateUrl: './fhi-indexes.component.html',
})
export class FhiIndexesComponent {
  @Input() public linked: boolean;
  @Input() public loadGraphOnInit: boolean;
  @Input() public fhiType: string;
  @Input() public generateByFilterParams: boolean;

  @Input() public set filterParams(value: { [key: string]: any }) {
    this._filterParams = value;
    if (value && this.generateByFilterParams && !this.asset) {
      this.updateOrganizationIndexes();
    }
  }

  @Input() public set asset(value: AssetModel) {
    this._asset = value;
    if (this._asset.complete && !this._asset.archived_at && this.indexes) {
      setTimeout(() => this.setModulesChartsForAsset());
    }
  }

  @Input() public set organization(value: OrganizationModel) {
    if (value) {
      this._organization = value;
      this.showModulesLinks = this.linked && this._organization.any_assets;
      if (!this.asset && !this.generateByFilterParams) {
        this.updateOrganizationIndexes();
      }
    }
  }

  @Input()
  public set period(value: string) {
    if (value) {
      this._period = FHIHistoryPeriod[value];
      this.updatePeriod();
    }
  }

  public helper = HelperService;
  public isLoading: boolean = true;
  public chartValues: { [key: string]: string } = {};
  public showModulesLinks: boolean;
  public indexes: {
    abbreviation: string;
    period: string;
    hasModule: boolean;
    name?: string;
  }[];

  protected asset_id: number;
  protected indicesMode: string;

  private _organization: OrganizationModel;
  private _period: string;
  private _asset: AssetModel;
  private _filterParams: { [key: string]: any };
  private _orgFHIIndexes;

  public get asset(): AssetModel {
    return this._asset;
  }

  public get filterParams(): { [key: string]: any } {
    return this._filterParams;
  }

  public get organization(): OrganizationModel {
    return this._organization;
  }

  public get period(): string {
    return this._period;
  }

  constructor(
    private _notificationService: CustomNotificationService,
    private _FHIModulesService: FHIModulesService<FHIScoreModel>,
    private activeRoute: ActivatedRoute,
    private _eventService: EventService,
    protected _organizationCurrent: OrganizationCurrentService
  ) {}

  public ngOnInit(): void {
    this.asset_id = this.asset
      ? this.activeRoute.snapshot.params['asset_id']
      : null;
    this.indexes = [
      {
        abbreviation: 'fhi',
        period: 'fhi_r_index',
        hasModule: true,
      },
      {
        abbreviation: 'fhim',
        period: 'fhim_r_index',
        hasModule: this.organization?.hasModule('fhim'),
        name: 'Maintenance',
      },
      {
        abbreviation: 'fhir',
        period: 'fhir_r_index',
        hasModule: this.organization?.hasModule('fhir'),
        name: 'Repair',
      },
      {
        abbreviation: 'fhie',
        period: 'fhi_r_index',
        hasModule: this.organization?.hasModule('fhie'),
        name: 'Engineering',
      },
    ];

    this._eventService.on(Configs.EVENTS.SERVICE_LOG_CHANGED, () =>
      this.updateOrganizationIndexes()
    );

    this.setupIndicesModeChange();
    if (this.loadGraphOnInit) {
      this.updateOrganizationIndexes();
    }
  }

  /**
   * Update module period
   */
  public updatePeriod() {
    if (!this.asset) {
      this.indicesMode = 'da';

      if (this._orgFHIIndexes) {
        this.getFHIValueFromOrg(this.fhiType, this._orgFHIIndexes);
      }
      return;
    }
    if (this.indexes) {
      this.setModulesChartsForAsset();
    }
  }

  /**
   * Sets asset's chart value for all modules and check values for null
   */
  public setModulesChartsForAsset() {
    this.indexes.forEach(({ hasModule, abbreviation: module }) => {
      if (hasModule) {
        let assetValue;
        switch (module) {
          case 'fhir':
            if (this.period === FHIHistoryPeriod.lifetime) {
              // get data from rolling
              assetValue =
                this.asset[
                  `${module}_${this.indicesMode}_${FHIHistoryPeriod.rolling}_index`
                ];
            } else {
              assetValue =
                this.asset[
                  `${module}_${this.indicesMode}_${this.period}_index`
                ];
            }
            break;
          case 'fhie':
            assetValue =
              this.asset[`${module}_${this.indicesMode}_index`] ??
              this.asset[`${module}_index`];
            break;
          case 'fhim':
            assetValue =
              this.asset[
                `${module}_${this.indicesMode}_${this.period}_index`
              ] ??
              this.asset[
                `${module}_${this.period === 'l' ? 'r' : this.period}_index`
              ];
            break;
          default:
            assetValue =
              this.asset[
                `${module}_${this.indicesMode}_${this.period}_index`
              ] ?? this.asset[`${module}_${this.period}_index`];
            break;
        }
        if (HelperService.isNumber(assetValue)) {
          this.chartValues[module] = String(Math.floor(assetValue));
        }
        this.isLoading = false;
      }
    });
  }
  /**
   * Get global module value for dashboard
   */
  public updateOrganizationIndexes(): void {
    this.isLoading = true;
    this.chartValues = {};
    this._FHIModulesService.getModuleChartsValues(this.filterParams).subscribe({
      next: (res) => {
        this._orgFHIIndexes = res;

        for (const fhiType of Object.keys(ModulesEnum)) {
          this.getFHIValueFromOrg(fhiType, res);
          this.isLoading = false;
        }
      },
      error: (err) => {
        this.isLoading = false;
        this._notificationService.apiError(err);
      },
    });
  }

  public generateEvent(title) {
    this._eventService.broadcast(Configs.EVENTS.NAVMENU_OPENED, {
      item: 'Modules',
      subitem: title,
    });
  }

  private getFHIValueFromOrg(fhiType: string, scope) {
    const pathToIndexes =
      scope[fhiType][`${fhiType}_${this.indicesMode}_score`] ||
      scope[fhiType][`${fhiType}_score`];
    const value =
      pathToIndexes[
        fhiType === 'fhie'
          ? fhiType
          : `${fhiType}_${this.period || FHIHistoryPeriod.rolling}`
      ];
    if (HelperService.isNumber(value)) {
      this.chartValues[fhiType] = String(Math.floor(+value));
    }
  }

  private setupIndicesModeChange() {
    // indices Straightline/Adjusted switcher
    this._organizationCurrent.modeSubj.subscribe({
      next: (mode) => {
        this.indicesMode = mode;
        this.updatePeriod();
      },
    });
  }
}
