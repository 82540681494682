<section class="content mrgn-b">
  <header>
    <header
      [ngClass]="{
        board__header: !independentComponent,
        header: independentComponent
      }"
    >
      <h2
        class="board__title"
        [ngClass]="{
          board__title: !independentComponent,
          header__title: independentComponent
        }"
      >
        Table of Users
      </h2>
    </header>

    <div class="toolbar__panel toolbar__panel_not-bordered flex">
      <app-table-search [keyword]="keyword" (onSearch)="search($event, listId)">
      </app-table-search>

      <app-per-page-selector
        [disabled]="!items?.length"
        [perPage]="perPage"
        (onUpdate)="updatePerPage($event)"
      >
      </app-per-page-selector>
    </div>
  </header>
  <div class="table-scroll">
    <table class="table table-striped table-hover">
      <thead class="table__header">
        <tr>
          <th
            class="id-column table__sorting-column"
            [ngClass]="getSortingCellClass('id')"
            (click)="setSortBy('id')"
          >
            ID
          </th>
          <th
            [ngClass]="getSortingCellClass('first_name')"
            (click)="setSortBy('first_name')"
            class="table__sorting-column"
          >
            {{ 'name' | translate }}
          </th>
          <th>Role</th>
          <th
            [ngClass]="getSortingCellClass('email')"
            (click)="setSortBy('email')"
            class="table__sorting-column"
          >
            {{ 'email' | translate }}
          </th>
          <th
            [ngClass]="getSortingCellClass('phone')"
            (click)="setSortBy('phone')"
            class="table__sorting-column"
          >
            {{ 'phone' | translate }}
          </th>
          <th>{{ 'labels.user.flags' | translate }}</th>
        </tr>
      </thead>

      <app-table-loader
        [colspan]="6"
        [isLoading]="isLoading"
        [items]="items"
      ></app-table-loader>

      <tbody>
        <tr
          *ngFor="let value of items"
          [ngClass]="value.is_inactive ? 'inactive' : ''"
        >
          <td>{{ value.id }}</td>
          <td>
            <a
              [routerLink]="[changeDetailsMode(value.id)]"
              [ngClass]="{ disabled: value.is_inactive || value.is_pending }"
            >
              <figure class="avatar-holder" appAlignScaledImage>
                <img
                  class="avatar-holder__image"
                  src="{{
                    value.is_pending
                      ? '../../../../../assets/img/no-avatar.png'
                      : value.get_avatar()
                  }}"
                  alt="{{ value.full_name }} image"
                />
                <figcaption class="text-semibold link">
                  {{ value.full_name }}
                </figcaption>
              </figure>
            </a>
          </td>
          <td>
            {{
              value?.sale_role_text
                ? value?.sale_role_text
                : value.role_name
                  ? roles[value.role_name].name
                  : ''
            }}
          </td>
          <td>{{ value.email }}</td>
          <td>{{ value.phone | phoneNumber }}</td>
          <td
            class="text-center"
            [ngClass]="{ 'full-opacity': value.is_inactive }"
          >
            <div class="record-actions">
              <!--Account Flags-->
              <span
                title="Account is blocked"
                *ngIf="value.blocked"
                class="record-actions__status"
              >
                <app-svg [href]="'lock'"></app-svg>
              </span>
              <span
                title="Account is pending"
                *ngIf="value.is_pending && !value.isResendingInvitation"
                class="record-actions__status"
              >
                <app-svg [href]="'hourglass'"></app-svg>
              </span>
              <span
                title="Resending invitation record-actions__status"
                *ngIf="value.is_pending && value.isResendingInvitation"
              >
                <app-loader></app-loader>
              </span>
              <!--END Account Flags-->

              <ul class="el-in-a-row el-in-a-row_c el-in-a-row_nowrap">
                <li *ngIf="value.is_pending && canInvite">
                  <button
                    type="button"
                    [attr.disabled]="value.isResendingInvitation"
                    (click)="resendInvitation(value)"
                    class="btn btn_iconed"
                    title="Resend invitation email"
                  >
                    <app-svg [href]="'refresh'"></app-svg>
                  </button>
                </li>
                <li *ngIf="value.is_pending && canInvite">
                  <button
                    type="button"
                    (click)="resetInvitation(value)"
                    class="btn btn_iconed"
                    title="Cancel invitation"
                  >
                    <app-svg [href]="'times'"></app-svg>
                  </button>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <footer
    class="content-footer el-in-a-row el-in-a-row_c el-in-a-row_lg flex_to-end"
  >
    <app-pagination-caption
      [start]="getStartPageItem()"
      [end]="getEndPageItem()"
      [total]="total"
    ></app-pagination-caption>
    <app-pagination
      [hasLeftDots]="hasLeftDots"
      [hasRightDots]="hasRightDots"
      [hasNextPage]="hasNextPage"
      [hasPrevPage]="hasPrevPage"
      [page]="page"
      [pages]="pages"
      [totalPages]="totalPages"
      [shouldPassPageNumber]="shouldPassPageNumber"
      (onNextPage)="nextPage()"
      (onPrevPage)="prevPage()"
      (onSetPage)="setPage($event)"
    ></app-pagination>
  </footer>
</section>
