import { BudgetingService } from 'src/app/core/services/budgeting.service';
import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-header-checkbox',
  templateUrl: './custom-header-checkbox.component.html',
  styleUrls: ['./custom-header-checkbox.component.less'],
})
export class CustomHeaderCheckboxComponent implements IHeaderAngularComp {
  constructor(public _budgetingService: BudgetingService) {}
  params: any;
  checkAll: boolean;

  agInit(params: any): void {
    this.params = params;
    this._budgetingService.updateHeaderCheckboxState$.subscribe((value) => {
      this.checkAll = value;
    });
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  onCheckboxChange(event: boolean): void {
    this._budgetingService.setHeaderCheckboxClicked(true);
    this._budgetingService.updateHeaderCheckboxState(event);
    this.params.context.assetsChecked(event);
    this.params.api.refreshCells({ force: true });
  }
}
