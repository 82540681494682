import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { EventService } from '../../core/services/event.service';

@Directive({
  selector: '[appAlignScaledImage]',
})
export class AlignScaledImageDirective implements OnInit {
  @Input('appAlignScaledImage') events: string;
  private parsedEvents: Array<string> = [];
  private $img;

  constructor(
    private el: ElementRef,
    private _eventService: EventService
  ) {}

  public ngOnInit() {
    this.$img = this.el.nativeElement.getElementsByClassName('scaled-img')[0];
    if (this.$img) {
      const img = new window.Image();
      img.src = this.$img.src;
      img.onload = () => this.calculateAlignment();
    }

    this.parsedEvents = this.events.split(',');
    this.parsedEvents.forEach((event) => {
      this._eventService.on(event, () => {
        this.calculateAlignment();
      });
    });
  }

  private calculateAlignment() {
    if (
      this.$img.clientWidth / this.$img.clientHeight >=
      this.el.nativeElement.clientWidth / this.el.nativeElement.clientHeight
    ) {
      this.el.nativeElement.classList.remove('align-scaled-v');
      this.el.nativeElement.classList.add('align-scaled-h');
    } else {
      this.el.nativeElement.classList.remove('align-scaled-h');
      this.el.nativeElement.classList.add('align-scaled-v');
    }
  }
}
