<section class="modal-content__wrapper">
  <header class="modal-content__header header">{{ header }}</header>

  <form
    #deleteForm="ngForm"
    (ngSubmit)="submit()"
    (keydown.enter)="$event.preventDefault()"
  >
    <fieldset class="modal-content__body">
      <ng-container *ngIf="isLoading">
        <app-loader></app-loader>
        <span class="sr-only">Loading...</span>
      </ng-container>

      <ng-container *ngIf="withoutBullets; else listItemTemplate">
        <p
          *ngFor="let msg of messagesArr"
          class="modal-content__message mrgn-b_sm"
          [innerHTML]="msg"
        ></p>
      </ng-container>

      <ng-template #listItemTemplate>
        <ul class="ul-container">
          <li
            *ngFor="let msg of messagesArr"
            class="modal-content__message modal-content__message-flex mrgn-b_sm"
          >
            <span class="modal-content__bullet">&#8226;</span>
            <span class="modal-content__text">{{ msg }}</span>
          </li>
        </ul>
      </ng-template>

      <p
        class="modal-content__message mrgn-b"
        *ngIf="message"
        [innerHTML]="message"
      ></p>

      <div class="modal_container">
        <label for="userInput" class="modal-content__message mrgn-t">
          To confirm, type '{{ keyword.toUpperCase() }}' in the field below
        </label>

        <div class="modal-content__item modal-content__action-input mrgn-b">
          <input
            [(ngModel)]="userInput"
            [ngClass]="{ 'form-block_error': hasError(_userInput) }"
            required
            [isUppercaseEqual]="keyword"
            placeholder="Enter value"
            class="form-block__element"
            name="userInput"
            id="userInput"
            #_userInput="ngModel"
            type="text"
          />
          <p class="error" *ngIf="hasError(_userInput)">
            <ng-container *ngIf="_userInput?.errors?.required">
              {{ "validation.required" | translate }}.
            </ng-container>
            <ng-container *ngIf="_userInput?.errors?.isNotEqual">
              {{ "validation.invalid_value" | translate }}.
            </ng-container>
          </p>
        </div>
      </div>
    </fieldset>

    <div class="buttons-block modal-content__footer">
      <button
        type="button"
        (click)="cancel()"
        class="btn action-btn action-btn_default"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'times'"></app-svg
        ></span>
        <span class="action-btn__title">{{ cancelText }}</span>
      </button>

      <button
        class="btn action-btn action-btn_danger"
        [disabled]="deleteForm.invalid"
      >
        <span class="action-btn__icon"
          ><app-svg [href]="'trash'" iconClass="icon icon_s"></app-svg
        ></span>
        <span class="action-btn__title">{{ submitText }}</span>
      </button>
    </div>
  </form>
</section>
