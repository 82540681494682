import { Injectable } from '@angular/core';
import { ProjectExpenses, ProjectFees } from '../constants/enums';
import { OrganizationCurrentService } from './organization-current.service';

@Injectable({
  providedIn: 'root',
})
export class CalculationErrorService {
  private parsedErrors: any = {};

  constructor(protected _organizationCurrent: OrganizationCurrentService) {}

  createFriendlyErrors(errors, keys: Array<string> = []): object {
    errors.forEach((item) => {
      let capitalizedKey = '';
      if (item.key) {
        capitalizedKey = item.key.replace('/_/gi', ' ').capitalize();
      }
      const newItem = {
        name: capitalizedKey,
        issues: [],
      };
      if (item.issues) {
        const issuesKeys = Object.keys(item.issues);

        issuesKeys.forEach((issue) => {
          const issueKeys = issue.split('.');
          let issueKey = issueKeys.at(-1);
          const projectexpensesKeys = Object.keys(ProjectExpenses);
          const projectFeesKeys = Object.keys(ProjectFees);
          const udf = (
            this._organizationCurrent.organization.project_udfs || []
          ).find((el) => el.id === issue);
          let source = '';
          switch (true) {
            case projectexpensesKeys.indexOf(issueKey) > -1:
              source = 'in Asset Type settings';
              break;
            case projectFeesKeys.indexOf(issueKey) > -1:
              source = 'in Organization settings';
              break;
            case issueKey.search('udf') >= 0:
              issueKey = udf?.name || issueKey;
              source = 'in Organization UDF settings';
              break;
            default:
          }
          const value = `${issueKey
            .split(/(?=[A-Z])|_/)
            .join(' ')
            .capitalize()} ${item.issues[issue]} ${source}`;
          newItem.issues.push(value);
        });
      }
      this.parsedErrors[item.key] = newItem;
    });
    let filteredErrors = {};
    if (keys.length) {
      keys.forEach((key) => {
        if (this.parsedErrors[key]) {
          filteredErrors[key] = this.parsedErrors[key];
        }
      });
    } else {
      filteredErrors = this.parsedErrors;
    }
    return filteredErrors;
  }

  getAssetErrorTooltip(value) {
    if (!value?.length) {
      return '';
    }

    this.createFriendlyErrors(value);

    let text = '';
    const errorKeys = Object.keys(this.parsedErrors);

    errorKeys.forEach((key) => {
      const error = this.parsedErrors[key];
      text += `${error.name} cannot be calculated`;

      error.issues.forEach((issue, index) => {
        if (index < error.issues.length - 1) {
          text += ';';
        }

        if (!index) {
          text += `\n(${issue.trim()}`;
        }

        if (index === error.issues.length - 1) {
          text += ') ';
        }
      });
    });
    return text;
  }
}
