import { ActivatedRoute, Router } from '@angular/router';
import {
  COUNTRIES,
  STATES,
} from '../../../core/constants/collections.constants';
import { Component, ViewChild } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { AutoUnsubscribeService } from '../../../core/services/auto-unsubscribe.service';
import { BaseEditComponent } from '../base-edit.component';
import { BreadcrumbItemModel } from '../../../core/models/breadcrumb-item.model';
import { BuildingModel } from '../../../core/models/building.model';
import { CropperModel } from '../../../core/models/cropper.model';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { ErrorService } from '../../../core/services/error.service';
import { EventService } from '../../../core/services/event.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { HelperService } from '../../../core/services/helper.service';
import { LocationModel } from '../../../core/models/location.model';
import { LocationService } from '../../../core/services/location.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { NgForm } from '@angular/forms';
import { OrganizationCurrentService } from '../../../core/services/organization-current.service';
import { OrganizationModel } from '../../../core/models/organization.model';
import { OrganizationStoreService } from '../../../core/services/organization-store.service';
import { Roles } from '../../../core/constants/roles.constant';
import { RoutesConstants } from '../../../core/constants/routes.constants';
import { UploadResourceService } from '../../../core/services/upload-resource.service';
import { UserCurrentService } from '../../../core/services/user-current.service';
import { UserModel } from '../../../core/models/user.model';
import { UserService } from '../../../core/services/user.service';
import { UserStoreService } from '../../../core/services/user.store';
import { environment } from '../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './user-edit.component.html',
})
export class UserEditComponent extends BaseEditComponent<UserModel> {
  @ViewChild('userForm', { static: true }) declare tdForm: NgForm;
  public org_id: string;
  public organizations: Array<OrganizationModel> = [];
  public test_organizations: Array<OrganizationModel> = [];
  public organizations_obj: { [key: string]: OrganizationModel } = {};
  public cropper_avatar: CropperModel = { image: {} };
  public cropper_signature: CropperModel = {};
  public title: string;
  public isTenant: boolean;
  public rolesEditAllowed: boolean;
  public temp_signature: string;
  public countries: Array<any> = COUNTRIES;
  public states: Array<any> = STATES;
  public locations: LocationModel[] = [];
  public buildingDropdowns: { [key: number]: BuildingModel[] } = {};

  public get isCurrent(): boolean {
    return this.model && this.model.id === this._userCurrent.user?.id;
  }

  public get roles() {
    return Roles;
  }

  constructor(
    protected _organizationStore: OrganizationStoreService,
    protected _userService: UserService,
    protected _userStore: UserStoreService,
    protected _notificationService: CustomNotificationService,
    protected _userCurrent: UserCurrentService,
    protected _uploadResourceService: UploadResourceService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected _organizationCurrent: OrganizationCurrentService,
    protected _eventService: EventService,
    protected _errorService: ErrorService,
    protected _locationService: LocationService,
    protected _destroy: AutoUnsubscribeService,
    protected _navigationService?: NavigationService,
    protected _featureFlagsService?: FeatureFlagsService
  ) {
    super(
      _notificationService,
      _userStore,
      router,
      activeRoute,
      'user_id',
      _eventService,
      _errorService
    );
  }
  //  This file could not read feature flag service
  get showDefaultFacilitySection(): boolean {
    return (
      this.org_id &&
      HelperService.hasPermissions(
        'ta_ca_pa',
        this._userCurrent.user?.role_name
      ) &&
      HelperService.hasPermissions('cu_ce', this.model?.role_name)
    );
  }

  ngOnInit() {
    this.isTemplateDriven = true;
    this.org_id = this._organizationCurrent.organization?.id_hash;
    if (this.org_id) {
      this.breadcrumbs.push({
        name: 'Settings',
      });
    }
    if (!HelperService.isExist(this.entityId)) {
      this.setupAsProfile();
    } else {
      this.setupAsUser();
      this.setupPaths();
    }
    this.loadUserInfo();
    this._navigationService?.currentBreadcrumb(this.breadcrumbs);
  }

  public afterEditRedirectPath(): string {
    return this.detailsPath;
  }

  protected setupAsProfile() {
    this.entityId = this._userCurrent.user?.id;
    this.rolesEditAllowed = false;
    this.isTenant = this._userCurrent.user.is_tenant;
    this.title = 'My Profile';
    this.listPath = RoutesConstants.HOME;
    this.detailsPath = RoutesConstants.PROFILE.DETAILS(this.org_id);
    this.breadcrumbs.push({
      icon: this.org_id ? 'user' : null,
      link: this.detailsPath,
      name: 'My Profile',
    });
  }

  protected setupAsUser() {
    this.rolesEditAllowed = true;
    this.isTenant = !this.activeRoute.snapshot.params['org_id'];
    this.title = this.isTenant ? 'Tenant Users' : 'Users';
    this.listPath = this.isTenant ? '/tenant_users' : `/${this.org_id}/users`;
    const breadcrumb: BreadcrumbItemModel = {
      link: this.setPageInBreadcrumbs([this.listPath]),
      name: this.title,
    };
    if (this.org_id) {
      breadcrumb.icon = 'user';
    }
    this.breadcrumbs.push(breadcrumb);
  }

  protected setupPaths() {
    this.listPath = this.isTenant
      ? RoutesConstants.TENANT_USERS.LIST
      : RoutesConstants.USERS.LIST(this.org_id);
    this.detailsPath = this.isTenant
      ? RoutesConstants.TENANT_USERS.DETAILS(this.entityId)
      : RoutesConstants.USERS.DETAILS(this.entityId, this.org_id);
  }

  public saveAction(): void {
    this.markFormGroupTouched();
    if (this.tdForm.invalid || this.isSavingModel) {
      return;
    }
    this.hasSubmitted = true;
    if (
      this.model.role_name !== 'tenant_engineer' &&
      this.model.role_name !== 'tenant_sale' &&
      this.model.role_name !== 'partner_admin' &&
      this.model.role_name !== 'partner_engineer'
    ) {
      delete this.model.organization_ids;
    }
    if (this.model.address.country !== 'US') {
      this.model.address.state = '';
    }
    this.isSavingModel = true;
    this.saveModel().subscribe({
      next: (res) => {
        this.afterSaveAction(res);
        this.isSavingModel = false;
      },
      error: (err) => {
        this.isSavingModel = false;
        this.hasSubmitted = false;
        this.parseErrors(err);
      },
    });
  }

  protected saveModel(): Observable<UserModel> {
    if (this.isCurrent) {
      return this._userCurrent.update(this.model);
    }
    return super.saveModel();
  }

  public afterSaveAction(model: UserModel): void {
    if (this.isCurrent) {
      this._userCurrent.updateCachedUser(model);
    }
    super.afterSaveAction(model);
  }

  /**
   * Loads user info
   */
  public loadUserInfo(): void {
    if (this.isTenant) {
      this._organizationStore.all().subscribe({
        next: (res) => {
          this.organizations = res.list;
          this.test_organizations = res.list;
        },
        error: (err) => this._notificationService.apiError(err),
      });
    }
    forkJoin([this._userCurrent.get(), this.loadModel()]).subscribe({
      next: (res) => {
        this.afterLoadAction(res);
        this.breadcrumbs.push({
          name: 'Edit',
        });
        if (this.showDefaultFacilitySection) {
          this._locationService.getSubLevelByParentLocation().subscribe({
            next: (res) => (this.locations = res.list),
            error: (err) => this._notificationService.apiError(err),
          });
          this.model.facilities_attributes = this.model.default_facilities;
          this.model.facilities_attributes.forEach((el) => {
            this.loadBuildings(el.location_id);
          });
        }
      },
      error: (err) => this._notificationService.apiError(err),
    });
  }

  public afterLoadAction(res): void {
    const currentRole = res[0].role_name,
      userRole = this.model.role_name;
    if (this.model.is_pending) {
      this.router.navigate([RoutesConstants.NOT_FOUND], { replaceUrl: true });
    }
    if (!this.isCurrent) {
      this.breadcrumbs.push({
        name: this.model.full_name,
        link: this.detailsPath,
      });
      // check for current user permission
      // client admin can't edit nobody excluding client user, client engineer
      // tenant engineer and client user cannot edit profiles
      if (
        (currentRole === 'client_admin' &&
          userRole !== 'client_user' &&
          userRole !== 'client_engineer') ||
        currentRole === 'client_user' ||
        currentRole === 'tenant_engineer'
      ) {
        this.router.navigate(['/403'], { replaceUrl: true });
      }
    }
    if (
      this.model.role_name.includes('tenant') ||
      this.model.role_name.includes('partner')
    ) {
      this._organizationStore.all().subscribe({
        next: (orgs) => {
          this.organizations = orgs.list;
          this.test_organizations = orgs.list;
          if (this.model.sale_role_text == 'Solution Consultant') {
            this.model.role_name = 'tenant_sale';
            this.filterOrganizationsByRole();
          }
          this.organizations_obj =
            HelperService.formatArrayToObject<OrganizationModel>(
              this.organizations,
              'id'
            );
        },
        error: (err) => this._notificationService.apiError(err),
      });
    }
  }

  // region Logo

  public updateUserPicture() {
    const file = this._uploadResourceService.dataURLtoFile(
      this.cropper_avatar.image,
      this.cropper_avatar.file_name
    );
    this.cropper_avatar.error = false;
    this._userStore.userAvatarChangedId = this.model.id;
    this._userService.uploadImage(this.model, file).subscribe({
      next: () => {
        this.model.isAvatarChanged = true;
        this.cancelUserAvatar();
      },
      error: (err) => this._notificationService.apiError(err),
    });
  }

  public cancelUserAvatar() {
    this.cropper_avatar = {
      image: null,
      file_name: null,
      error: null,
    };
  }

  public removeUserPicture() {
    this._userService.removeImage(this.model);
  }

  // endregion

  public updateUserSignature() {
    const file = this._uploadResourceService.dataURLtoFile(
      this.cropper_signature.image,
      this.cropper_signature.file_name
    );
    this._uploadResourceService
      .uploadFile(
        file,
        `images/signature`,
        this._organizationCurrent.organization.id_hash
      )
      .subscribe((success) => {
        if (success.complete) {
          this.temp_signature = `${success.url}/${success.fields.key}`;
          this.model.signature = success.fields.key;
          this.cancelUserSignature();
        }
      });
  }

  public cancelUserSignature() {
    this.cropper_signature = {
      image: null,
      file_name: null,
      error: null,
    };
  }

  public removeUserSignature() {
    this.model.signature = null;
  }

  public getUserSignatureUrl() {
    if (!this.model) {
      return;
    }
    if (this.temp_signature) {
      return this.temp_signature;
    }
    if (this.model.signature) {
      return this._organizationCurrent.organization
        ? `${environment.amazon.shared_bucket_url}/${this.model.signature}`
        : null;
    }
  }
  public addLocation() {
    this.model.facilities_attributes.push({
      location_id: null,
      building_ids: [],
    });
  }

  public loadBuildings($event: number, facility?) {
    if (facility) {
      facility.building_ids = [];
    }
    if (this.buildingDropdowns[$event]) {
      return this.buildingDropdowns[$event];
    }
    this._locationService
      .getSubLevelByParentLocation('building', 'location', [$event])
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: (res) => (this.buildingDropdowns[$event] = res.list),
        error: (err) => this._notificationService.apiError(err),
      });
  }

  public deleteFacility(index: number) {
    this.model.facilities_attributes.splice(index, 1);
  }

  public filterOrganizationsByRole() {
    this.test_organizations =
      this.model?.role_name === 'tenant_sale'
        ? this.organizations.filter((key) => key.test_organization)
        : this.organizations;
  }

  public getOrganizationLabelText(model): string {
    if (model.sale_role_text) {
      return 'Solution Consultant Organizations';
    } else {
      switch (model.role_name) {
        case 'partner_admin':
          return 'Partner Admin Organisations';
        case 'partner_engineer':
          return 'Partner Engineer Organisations';
        default:
          return 'Engineer Organization';
      }
    }
  }
}
