import { Component, Input } from '@angular/core';
import { BaseModalComponent } from '../base-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomNotificationService } from '../../../core/services/custom-notification.service';
import { EventService } from '../../../core/services/event.service';

@Component({
  templateUrl: './save-errors-extended-modal.component.html',
  styleUrls: [],
})
export class SaveErrorsExtendedModalComponent extends BaseModalComponent {
  @Input() public header = 'Import errors';
  @Input() public errorMessage =
    'Import aborted due to the errors listed below. Please correct the errors and import anew.';
  @Input() public warningMessage =
    'Import aborted due to the errors listed below. Please correct the errors and import anew.';
  @Input() public entityColumnName = 'Row';
  @Input() public set errors(value: Array<any>) {
    this._errors = value || [];
  }
  @Input() public set warnings(value: Array<any>) {
    this._warnings = value || [];
  }
  @Input() public warningsAfterImport: Array<any> = [];

  public get errors(): Array<any> {
    return this._errors;
  }

  public get warnings(): Array<any> {
    return this._warnings;
  }

  public activeTab = 'errors';
  protected _errors: Array<any>;
  protected _warnings: Array<any>;
  constructor(
    protected activeModal: BsModalRef,
    protected _notificationService: CustomNotificationService,
    protected _eventService: EventService
  ) {
    super(activeModal, _notificationService, _eventService);
  }

  ngOnInit() {
    if (!this.errors.length) {
      this.activeTab = 'warnings';
    }
  }
}
