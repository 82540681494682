import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HelperService } from '../../../core/services/helper.service';
import '../../../shared/prototypes/string.prototypes'
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: [
    './../o-multiselect/o-multiselect.component.less',
    './select.component.less',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor {
  @Input() disabled: boolean;
  @Input() heightClass: boolean;
  @Input() keyAsValue: boolean;
  @Input() optDisabledWhenFieldTrue: string = 'disabled';
  @Input() optKey: string = 'id';
  @Input() optValue: string = 'name';
  @Input() placeholder: string = 'Select Value';
  @Input() placement: string = 'left';
  @Input() removeFromOptValue: string;
  @Input() showPlaceholder: boolean = true;
  @Input() widthClass: string;
  @Input()
  public set values(arr: any[]) {
    if (
      arr?.length &&
      arr.every((el) => typeof el === 'string' || typeof el === 'number')
    ) {
      if (this.keyAsValue) {
        arr = arr.map((value: string) => ({
          [this.optKey]: value,
          [this.optValue]: String(value).capitalize(),
        }));
      } else {
        arr = arr.map((value: string, index: number) => ({
          [this.optKey]: index,
          [this.optValue]: value,
        }));
      }
    }
    this._values = arr;
    this.updateSelectedOption();
  }

  @Input()
  public set model(val: string | number) {
    this._model = val;

    this.updateSelectedOption(val);
  }

  public helper = HelperService;
  public selectedOption: any;

  private _model;
  private _values: any[];

  public get model() {
    return this._model;
  }

  public get values() {
    return this._values || [];
  }

  propagateChange = (_: any) => {};
  propagateTouch = () => {};

  writeValue(model: any) {
    this.model = model;
  }

  registerOnChange(fn): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  public onChange(option): void {
    this.selectedOption = option;
    this.model = this.selectedOption?.[this.optKey] ?? null;
    this.propagateChange(this.model);
  }

  public trackByIdx(index: number): any {
    return index;
  }

  private updateSelectedOption(val = this.model): void {
    this.selectedOption = this.values.find((el) => el[this.optKey] == val);
  }
}
