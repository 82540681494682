<app-select
  (ngModelChange)="updatePerPage($event)"
  [(ngModel)]="perPage"
  [disabled]="disabled"
  optKey="value"
  optValue="label"
  [showPlaceholder]="false"
  [values]="pageSizes"
>
</app-select>
